import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { StackedLineChart } from "@mui/icons-material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FFD353" : "#308fe8",
  },
}));
const ProgressBar = () => {
  return (
    <Box width={"100%"}>
      <Typography fontSize={{sm: "18px", xs: "15px"}} fontWeight={700} mb={1}>
        Boss Level
      </Typography>
      <BorderLinearProgress variant="determinate" value={80} />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography fontSize={{sm: "12px", xs: "10px"}} mt={1}>
          Current Level: Boss
        </Typography>
        <Typography fontSize={{sm: "12px", xs: "10px"}} textAlign={"right"} mt={1}>
          Next Level: Scholar
        </Typography>
      </Stack>
    </Box>
  );
};

export default ProgressBar;
