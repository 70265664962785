import React from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';


const WeeklyCard = () => {
  return (
    <div className='rounded-[6px] border border-[#BDBDBD] border-dashed p-[16px]'>
      <div className='flex justify-between'>
        <div className='flex flex-col gap-[16px]'>
          <h4 className='text-[#89898A] text-[12px]'>General</h4>
          <h3 className='text-[13px] text-[#1B1A17] font-medium'>Week 1 Trivia</h3>
        </div>
        <div className='cursor-pointer'>
          <MoreHorizIcon />
        </div>
      </div>
      <div className='mt-2'>
        <span className='text-[12px] text-[#89898A]'>August 8th,2024 - August 14th, 2024</span>
      </div>

      <div className='flex mt-[24px] items-center gap-8'>
        <div className='flex items-center gap-2'>
          <AccessTimeIcon style={{color: '#89898A'}} />
          <span className='text-[#89898A] text-[12px]'>7 days</span>
        </div>
        <div className='flex items-center gap-2'>
          <HelpOutlineOutlinedIcon style={{color: '#89898A'}} />
          <span className='text-[#89898A] text-[12px]'>5 questions</span>
        </div>
      </div>

    </div>
  )
}

export default WeeklyCard