import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Player from "../Video/Player";
import { Alert, Snackbar } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useEffect } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& > .MuiBackdrop-root": {
        backdropFilter: "blur(4px)",
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#FFD14A",
        height: "1px",
        borderRadius: "16.9px 16.9px 0px 0px",
        color: "#fff",
    },
    "& .MuiDialogContent-root": {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 15,
        paddingTop: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        // padding: theme.spacing(2),
        position: "relative",
    },
}));

export const VideoDialog = ({ open, setOpen, closeParent }) => {
    const [canClose, setCanClose] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false)
    const handleOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
        closeParent(false)
    };

    useEffect(() => {
        if (canClose) {
            setOpenAlert(true)
        }
    }, [canClose])
    return (
        <div>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{ sx: { borderRadius: "18px" } }}
                maxWidth={"xl"}
            >
                <DialogTitle
                    sx={{ m: 0, p: 1, textAlign: "center" }}
                    id="customized-dialog-title"
                ></DialogTitle>
                <DialogContent>
                    <Player canProceed={setCanClose} />
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <Button disabled={!canClose} variant={"contained"} onClick={handleCloseDialog} color="info">
                        Close Ad
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <Snackbar open={openAlert} anchorOrigin={{ horizontal: "center", vertical: "top" }} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
                <Alert icon={<InfoOutlined fontSize="inherit" />} onClose={() => setOpenAlert(false)} severity="success" sx={{ width: '100%' }}>
                    You now have 5 more free questions
                </Alert>
            </Snackbar>
        </div>
    );
}
