import { Box, Tab, Tabs, styled } from "@mui/material";
import React from "react";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid transparent",
  borderRadius: "14px",
  backgroundColor: "#1E2237",
  "& .MuiTabs-indicator": {
    backgroundColor: "#F8EF07",
    width: "40%",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#fff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      //   color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      //   color: "#1890ff",
      color: "#fff",
      // fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

export const LeaderboardTab = ({ activeTab, setActiveTab }) => {
  console.log(activeTab);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box display={"flex"} py={3} justifyContent={"center"}>
      <AntTabs
        centered
        value={activeTab}
        onChange={handleChange}
        aria-label="ant example"
      >
        <AntTab label="State" />
        <AntTab label="National" />
        <AntTab label="Global" />
      </AntTabs>
    </Box>
  );
};
