import moment from "moment";

/**
 * Adds to a comma to the input
 * @param{String} string (i) input has to be a "text"
 * @param{Boolean} boolean if true input value is returned as number else a string
 * @return {String || Number} string || number
 */
export const commmaToInput = (value, asNum) => {
  if (asNum) {
    return Number(value?.split(",")?.join(""));
  } else if (typeof value === "number") {
    return value.toLocaleString("en-US");
  } else {
    return Number(value?.split(",")?.join(""))?.toLocaleString("en-US");
  }
};

export const removeCommas = (value, asNum) => {
  const formatParam = value?.replaceAll(",", "");
  if (asNum) {
    return Number(formatParam);
  } else {
    return formatParam;
  }
};

/**
 * Checks if a given object is empty
 * @param {Object} object
 * @return {Boolean}
 */
const isEmptyObject = (object) => {
  if (isObject(object) && object !== undefined) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
  }
  return true;
};

/**
 * Check if a given value is an object
 * @param {*} value
 * @return {boolean}
 */
const isObject = (value) => {
  if (!Array.isArray(value) && value instanceof Object) {
    return true;
  }
  return false;
};

/**
 * Checks if a given array is empty
 * @param {Array} array
 * @return {Boolean}
 */
const isEmptyArray = (array) => {
  if (isArray(array) && array !== undefined && array.length === 0) {
    return true;
  }
  return false;
};

/**
 * Check if a given value is an array
 * @param {*} value
 * @return {boolean}
 */
const isArray = (value) => {
  if (Array.isArray(value) || value instanceof Array) {
    return true;
  }
  return false;
};

/**
 * Checks if two arrays are exactly equal
 * @param {Array} a
 * @param {Array} b
 * @returns
 */
const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

/**
 * Checks if a given string is empty
 * @param {String} string
 * @return {Boolean}
 */
const isEmptyString = (string) => {
  if (isString(string) && string !== undefined && string.length === 0) {
    return true;
  }
  return false;
};

/**
 * Check if a given value is a string
 * @param {*} value
 * @return {boolean}
 */
const isString = (value) => {
  if (typeof value === "string" || value instanceof String) {
    return true;
  }
  return false;
};

/**
 * Converts the first letter of a given string to uppercase
 * @param {string} string
 * @return {string}
 */
const ucfirst = (string) => {
  if (string === undefined) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Converts the first letter of a given string to lowercase
 * @param {string} string
 * @return {string}
 */
const lcfirst = (string) => {
  if (string === undefined) {
    return "";
  }
  return string.charAt(0).toLowerCase() + string.slice(1);
};

/**
 * Returns a random date and time relative to current date
 * @param {integer|null} max hours ahead
 * @param {integer|null} min hours behind
 * @return {string} date time string
 */
const randomDate = (max = null, min = null) => {
  let currentDate = new Date(Date.now());
  let maxHours = max ? max : 1;
  let minHours = min ? -min : 1;
  let totalHours = Math.floor(
    Math.random() * (maxHours - minHours + 1) + minHours
  );
  currentDate.setHours(currentDate.getHours() + totalHours);
  return new Date(currentDate).toLocaleString();
};

/**
 * Evaluates and returns password strength based on a 100% points scale
 * @param {string} string
 * @return {integer}
 */
const passwordStrengthMeter = (string) => {
  // Additions
  let additions = 0;
  let lengthScore = string.length * 4;
  let stringTypeScore = 0;
  let stringGroupScore = 0;
  let stringGroup = { uppercase: [], lowercase: [], numbers: [], specials: [] };

  string.split("").forEach(function (character) {
    // Reward uppercase
    if (character.match(/^[A-Z]+$/)) {
      stringGroup.uppercase.push(character);
      stringTypeScore = stringTypeScore + 2;
    }
    // Reward lowercase
    if (character.match(/^[a-z]+$/)) {
      stringGroup.lowercase.push(character);
      stringTypeScore = stringTypeScore + 2;
    }
    // Reward numbers
    if (character.match(/^[0-9]+$/)) {
      stringGroup.numbers.push(character);
      stringTypeScore = stringTypeScore + 4;
    }
    // Reward special characters
    if (character.match(/^[^a-zA-Z0-9]+/)) {
      stringGroup.specials.push(character);
      stringTypeScore = stringTypeScore + 6;
    }
    // Reward middle numbers and alphabets
    if (character.match(/^[f-rF-R4-7]+$/)) {
      additions = additions + 2;
    }
  });

  Object.keys(stringGroup).forEach((item, key) => {
    return (stringGroupScore =
      stringGroup[item].length > 0 ? stringGroupScore + 5 : stringGroupScore);
  });

  // Deductions
  let deductions = 0;

  string.split("").forEach(function (uno, index) {
    // Get the next character for the loop
    let duo = string[index + 1] !== undefined ? string[index + 1] : "";
    let trio = string[index + 2] !== undefined ? string[index + 2] : "";

    // Punish uppercase followed by uppercase
    if (isUpperCase(uno + duo)) {
      deductions = deductions + 2;
    }
    // Punish lowercase followed by lowercase
    if (isLowerCase(uno + duo)) {
      deductions = deductions + 2;
    }
    // Punish numbers followed by number
    if (Number.isInteger(uno + duo * 1)) {
      if (uno * 1 === duo * 1) {
        deductions = deductions + 2;
      }
    }
    // Punish sequential characters
    if (hasRepeatedLetters(uno + duo + trio)) {
      deductions = deductions + 3;
    }
    // Punish numerically sequential characters
    if (Number.isInteger(uno + duo + trio * 1)) {
      if (uno + duo + trio * 1 === uno + uno++ + (uno++ + 1) * 1) {
        deductions = deductions + 3;
      }
    }
  });

  return Math.round(
    ((additions +
      lengthScore +
      stringTypeScore +
      stringGroupScore -
      deductions) *
      100) /
      268
  );
};

/**
 * Checks if a single string character is an alphabet
 * @param {string} character
 * @return {boolean}
 */
const isLetter = (character) => {
  return character.match(/^[a-zA-Z]+$/);
};

/**
 * Checks if a string is completely uppercase alphabets
 * @param {string} string
 * @return {boolean}
 */
const isUpperCase = (string) => {
  return !isLetter(string) ? false : string === string.toUpperCase();
};

/**
 * Checks if a string is completely lowercase alphabets
 * @param {string} string
 * @return {boolean}
 */
const isLowerCase = (string) => {
  return !isLetter(string) ? false : string === string.toLowerCase();
};

/**
 * Checks if a string has repeated characters
 * @param {string} string
 * @return {boolean}
 */
const hasRepeatedLetters = (string) => {
  return /^([a-zA-Z0-9])\1{2,3}$/.test(string);
};

/**
 * Checks if a value is defined
 * @param {*} value
 * @return {boolean}
 */
const isDefined = (value) => {
  if (value === undefined) {
    return false;
  }
  return true;
};

/**
 * Check if a value is an empty string, an empty array, an empty object or is undefined
 * @param {*} value
 * @return {boolean}
 */
const isEmpty = (value) => {
  if (!isDefined(value)) {
    return true;
  }
  if (isString(value) && isEmptyString(value)) {
    return true;
  }
  if (isArray(value) && isEmptyArray(value)) {
    return true;
  }
  if (isObject(value) && isEmptyObject(value)) {
    return true;
  }

  return false;
};

/**
 * Ellipse long words and sentences
 * @param {string} string text to be eclipsed.
 * @param {integer} displayAreaWidth current display area width
 * @param {integer} ellipsesPercentage 0-100, percentage of string not to wrap with respect to display area width
 * @return {string}
 */
const autoEllipses = (string, displayAreaWidth, ellipsesPercentage = 60) => {
  let sliceLength =
    (parseInt(displayAreaWidth, 10) / 10) *
    (parseInt(ellipsesPercentage, 10) / 100);
  let suffix = sliceLength < string.length ? "..." : "";

  return string.slice(0, sliceLength) + suffix;
};

/**
 * Checks if a value contains only numerical string
 * @param {*} value
 * @return {boolean}
 */
const isNumeric = (value) => {
  if (!isArray(value)) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  return false;
};

/**
 * Checks if a give value is exactly a number and not numeric string
 * @param {*} value
 * @return {boolean}
 */
const isNumber = (value) => {
  return typeof value === "number";
};

/**
 * Create a FormData object with a given object
 * @param {object} object
 * @return {object}
 */
const objectToFormData = (object) => {
  const formData = new FormData();

  // Loop through the given object
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      // If the value is an array, loop through it again
      if (Array.isArray(object[key])) {
        object[key].forEach((element, index) => {
          for (const item in element) {
            formData.append(`${key}[${index}][${item}]`, element[item]);
          }
        });
      } else {
        formData.append(key, object[key]);
      }
    }
  }

  return formData;
};

/**
 * Compare environmental variables
 * For dotenv variables, Assumes `process.env` is available
 * As this is a hybrid function it stands as both a client and server side method
 * @param {string} applicationEnvironmentKey
 * @param {string} string
 * @param {boolean} compareDirectly
 * @return {boolean}
 */
const isEnv = (applicationEnvironmentKey, string, compareDirectly = false) => {
  let env = !compareDirectly
    ? process.env[applicationEnvironmentKey]
    : applicationEnvironmentKey;
  if (env === string) {
    return true;
  }

  return false;
};

/**
 * Generate a random string
 * Note this is neither collision free or unpredictable
 * @param {integer} length, max 999999, returned string length
 * @param {string} range, characters to be used for generation
 * @return {string}
 */
const randomString = (length = 8, range = null) => {
  length = length * 1 > 999999 ? 999999 : length * 1;

  if (isString(range)) {
    const chars = [...range];
    return [...Array(length)]
      .map((i) => chars[(Math.random() * chars.length) | 0])
      .join(``);
  } else {
    return [...Array(length)]
      .map((i) => ((Math.random() * 36) | 0).toString(36))
      .join(``);
  }
};

/**
 * Check if a given value is a boolean
 * @param {*} value
 * @return {boolean}
 */
const isBoolean = (value) => {
  if (typeof variable === "boolean" || typeof value === typeof true) {
    return true;
  }
  return false;
};

/**
 * Convert a form input file to base64
 * @param {file Object} file eg: e.target.files[0]
 * @param {function} callback
 * @return {string}
 */
const inputFileToBase64 = (file, callback) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    callback(reader.result);
  };
};

/**
 * Return the file name form a file path
 * @param {string} path
 * @return {string}
 */
const fileNameFromPath = (path) => {
  return path.split("\\").pop();
};

/**
 * Keys an array of objects by the given key
 * If multiple items have the same key, only the last one will appear
 * @param {array} arrayOfObjects
 * @param {string} key
 * @return {object}
 */
const keyBy = (arrayOfObjects, key) => {
  let bag = {};

  if (!isEmpty(arrayOfObjects)) {
    arrayOfObjects.map((item, index) => {
      bag[item[key]] = { ...item, index: index };
      return true;
    });
    return bag;
  }
  return false;
};

/**
 * Access an uncertain object and return a replacement if accessor fails
 * @param {object} object
 * @param {string} accessors eg:'fish.type.food' where fish is the object, 'type.food' would be accessor string
 * @param {*} replacement
 * @return {*}
 */
const tryOrReplace = (object, accessors, replacement = false) => {
  try {
    accessors = accessors.split(".");
    for (const key of accessors) {
      object = object[key];
    }
    return object;
  } catch (err) {
    return replacement;
  }
};

/**
 * List and return all numeric numbers between given numbers
 * @param {integer} startAt
 * @param {integer} endAt max:999
 * @param {integer} step min:1
 * @return {boolean|array}
 */
const numericRange = (startAt, endAt, step = 1) => {
  if (startAt > endAt || endAt > 999 || step < 1) {
    return false;
  }
  return Array(Math.ceil((endAt + 1 - startAt) / step))
    .fill(startAt)
    .map((x, y) => {
      return x + y * step;
    });
};

/**
 * List and return all characters between given characters
 * @param {string} startChar charset:UTF-8
 * @param {string} endChar charset:UTF-8
 * @return {boolean|string}
 */
const characterRange = (startChar, endChar) => {
  startChar = startChar.charCodeAt(0);
  endChar = endChar.charCodeAt(0);
  let numberOfChar = endChar - startChar + 1;

  if (startChar > endChar || numberOfChar < 0 || numberOfChar > 65535) {
    return false;
  }
  return String.fromCharCode(
    ...[...Array(numberOfChar).keys()].map((i) => i + startChar)
  );
};

/**
 * Returns current date with separators
 * @param {string} separator charset:UTF-8
 * @return {string}
 */

const getCurrentDate = (separator = "-") => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
};

/**
 * Paginate an array of objects and return objects for specified page
 * @param {array} arrayOfObjects
 * @param {integer} pageSize, number of objects per page
 * @param {integer} pageNumber, current page
 * @return {array}
 */
const paginateData = (arrayOfObjects, pageSize, pageNumber) => {
  return arrayOfObjects.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );
};

/**
 * Retrieves all the values for a given key in an array of objects
 * @param {array} arrayOfObjects
 * @param {string} key
 * @return {array}
 */
const pluck = (arrayOfObjects, key) => {
  let bag = [];

  if (!isEmpty(arrayOfObjects)) {
    arrayOfObjects.map((item) => {
      bag.push(item[key]);
      return true;
    });
    return bag;
  }
  return false;
};

/**
 * Converts hex color string to an rgba color string
 * @param {string} hex eg: #01e, #3e4b1c
 * @param {decimal} alpha min:0 max:1 eg: 0.2
 * @return {string}
 */
const hex2rgba = (hex, alpha = 1) => {
  hex = hex.replace("#", "");
  hex = hex.length === 3 ? hex + hex : hex;
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

/**
 * Groups an array of objects by a specified number
 * @param {array} arrayOfObjects
 * @param {integer} numberPerGroup min:1
 * @return {array}
 */
const sliceInToGroups = (arrayOfObjects, numberPerGroup = 1) => {
  let bag = [];
  let length = arrayOfObjects.length;
  let start = 0;
  let stop = numberPerGroup;

  do {
    bag.push(arrayOfObjects.slice(start, stop));
    start = start + numberPerGroup;
    stop = stop + numberPerGroup;
  } while (start < length);

  return bag;
};

/**
 * Restructure individual objects contained in an array
 * to conform with the given structure, also with the
 * option of merging in the original object.
 * @param {array} arrayOfObjects [{a:'1', b:'2', c:'3'}]
 * @param {object} structure {'a':'apple', 'b':'book'}; object properties with key of `a` to be replaced with key of `apple`
 * @param {boolean} merge
 * @returns {array} [{ apple: '1', book: '2' }]
 */
const mapAs = (arrayOfObjects, structure, merge = false) => {
  return arrayOfObjects.map((item) => {
    let set = {};
    for (const each in structure) {
      if (item[each]) {
        set = { ...set, [structure[each]]: item[each] };
      }
    }

    // Check if current object data should be merged in.
    return merge ? { ...item, ...set } : set;
  });
};

/**
 * Convert date string to simpler format
 *
 * @param {string} date
 * @param {string} yearType accepts: long, short, numeric
 * @param {string} monthType accepts: long, short, numeric
 * @param {string} day accepts: 2-digit numeric
 * @returns {string}
 */
const simpleDate = (
  date,
  yearType = "numeric",
  monthType = "short",
  dayType = "2-digit",
  hourType = "2-digit",
  minuteType = "2-digit",
  showTimeStamp = false
) => {
  if (new Date(date) === "Invalid Date" || isNaN(new Date(date)) || !date) {
    return date ? date : "";
  }

  if (date.includes(":")) {
    let dateString = new Date(date.split(" ").join("T")).toLocaleString(
      "en-us",
      { year: yearType, month: monthType, day: dayType }
    );
    let dateTimeString = new Date(date.split(" ").join("T")).toLocaleString(
      "en-us",
      {
        year: yearType,
        month: monthType,
        day: dayType,
        hour: hourType,
        minute: minuteType,
      }
    );
    return showTimeStamp ? dateTimeString : dateString;
  } else {
    let dateString = new Date(date).toLocaleString("en-us", {
      year: yearType,
      month: monthType,
      day: dayType,
    });
    let dateTimeString = new Date(date).toLocaleString("en-us", {
      year: yearType,
      month: monthType,
      day: dayType,
      hour: hourType,
      minute: minuteType,
    });
    return showTimeStamp ? dateTimeString : dateString;
  }
};

/**
 * Convert datetime string to day and time formatted
 *
 * @param {string} date
 * @returns {string}
 */

const formatDate = (dateString) => {
  const dateObj = new Date(dateString);

  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObj
  );
  return formattedDate;
};

const formatDate2 = (dateString) => {
  const date = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
  return formattedDate;
};

/**
 * Convert card number string to normal format
 *
 * @param {string} number
 * @returns {string}
 */
const parseCardNumber = (number) => {
  return number?.replace(/[^a-z0-9]+/gi, "").replace(/(.{4})/g, "$1 ");
};

/**
 * Truncate text and add ellipsis in middle
 *
 * @param {string} string
 * @param {number} lengthToShow
 * @param {string} seperator
 * @returns {string}
 */
const truncateText = (string, lengthToShow = 20, separator = "...") => {
  if (string.length <= lengthToShow) return string;

  const sepLen = separator.length;
  const charsToShow = lengthToShow - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    string.substr(0, frontChars) +
    separator +
    string.substr(string.length - backChars)
  );
};

/**
 * Copies text to device clipboard
 *
 * @param {string} text
 * @returns {string}
 */
const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

/**
 * Create a new function that calls func with thisArg and args.
 *
 * @param {callback} func callback to be triggered
 * @param {number} wait how long to wait before calling callback, in milliseconds
 * @returns {function}
 */
const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};

/**
 * Create a new function that limits calls to func to once every given time frame.
 *
 * @param {function} func callback to be triggered
 * @param {number} timeFrame how long to wait before calling callback, in milliseconds
 * @returns {function}
 */
function throttle(func, timeFrame) {
  var lastTime = 0;
  return function (...args) {
    var now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}

/**
 * Paginate an array of objects and return objects for specified page
 * along with additional properties
 *
 * @param {array} arrayOfObjects
 * @param {integer} pageSize, number of objects per page
 * @param {integer} pageNumber or current page
 * @return {object}
 */
const pagination = (arrayOfObjects, pageSize, pageNumber) => {
  // Guard against wrong value type
  arrayOfObjects = arrayOfObjects ?? [];

  pageNumber = pageNumber < 1 ? 1 : pageNumber;
  let data = arrayOfObjects.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );
  let totalItems = arrayOfObjects.length;
  let totalPages = Math.ceil(totalItems / pageSize);
  let nextPage =
    Math.round(totalPages) >= pageNumber + 1 ? pageNumber + 1 : null;
  let previousPage =
    Math.round(totalPages) > pageNumber - 1 ? pageNumber - 1 : null;

  return {
    pageNumber: pageNumber,
    pageSize: pageSize,
    totalItems: totalItems,
    totalPages: Math.round(totalPages),
    data: data,
    nextPage: nextPage,
    previousPage: previousPage,
    thereIsNextPage: nextPage !== null,
    thereIsPreviousPage: previousPage !== null && previousPage !== 0,
  };
};

/**
 * Get url parameters from string
 *
 * @param {string} url
 * @returns {object}
 */
const getUrlParameters = (url) => {
  const urlSearchParams = new URLSearchParams(url ?? "");
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

/**
 * Convert most common case types such as snake, kebab,
 * pascal and camel case string to title case string
 *
 * @param {string} string
 * @returns {string}
 */
const toTitleCase = (string) => {
  string = string.replace(/_/g, " "); // snake case
  string = string.replace(/-/g, " "); // kebab case
  string = string
    .split(/([A-Z][a-z]+)/)
    .filter(function (e) {
      return e;
    })
    .join(" "); // pascal case, camel case

  const splitString = string.toLowerCase().split(" ");

  for (var i = 0; i < splitString.length; i++) {
    splitString[i] =
      splitString[i].charAt(0).toUpperCase() + splitString[i].slice(1);
  }
  return splitString.join(" ").replace(/  +/g, " ");
};

/**
 * Mask a credit card number
 * @param {string|integer} cardNumber
 * @returns {string}
 */
const maskCardNumber = (cardNumber) => {
  return (
    cardNumber.slice(0, 4) +
    cardNumber.slice(4).slice(0, -4).replace(/[0-9]/gi, "*") +
    cardNumber.slice(-4)
  );
};

/**
 * Format a json string to defined sentence
 * @param {string} json
 * @returns {string}
 */
const formatJsonToString = (json) => {
  try {
    let parsedJson = JSON.parse(JSON.parse(json));
    let string = "";

    parsedJson &&
      Object.keys(parsedJson).map((value) => {
        return (string = string + value + ": " + parsedJson[value] + "\n");
      });

    return string;
  } catch (error) {
    return json;
  }
};
/**
 * Format a json string to defined sentence
 * @param {string} json
 * @returns {string}
 */
const isEmail = (email) => {
  let test = email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return test ? true : false;
};

const padQuestionNumber = (inputString) => {
  // Extract the number from the string
  const extractedNumber = parseInt(inputString?.match(/\d+/)[0], 10);

  // Remove the "Question " part from the string
  const questionNumber = inputString?.replace("Question ", "");

  // Pad the extracted number with a preceding zero
  const paddedNumber = extractedNumber?.toString().padStart(2, "0");

  return paddedNumber;
};

/**
 * Format a questions answer
 * @param {string} json
 * @returns {string}
 */
const convertAnswerToAlphabet = (answerCode) => {
  const alphabetMapping = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
    // Add more mappings if needed
  };
  const numericCode =
    typeof answerCode === "number" ? answerCode : parseInt(answerCode.trim());

  return alphabetMapping[numericCode] || "";
  // if (typeof answerCode === "string") {
  //   return alphabetMapping[answerCode.trim()] || ""; // Return an empty string if not found in the mapping
  // } else {
  //   return ""; // Return an empty string for non-string or undefined values
  // }
};

const arraysAreEqual = (arrayA, arrayB) => {
  // Check if both arrays have the same length
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  // Sort both arrays before comparing elements
  arrayA.sort();
  arrayB.sort();

  // Compare each element of the arrays
  for (let i = 0; i < arrayA.length; i++) {
    if (arrayA[i] !== arrayB[i]) {
      return false;
    }
  }

  // If all elements are the same, the arrays are equal
  return true;
};

/**
 * Grade Calculation
 * @param {string} json
 * @returns {string}
 */
const calculateGrade = (score, totalQuestions) => {
  const ratio = score / totalQuestions;

  if (ratio === 1) {
    return "Excellent 🌟";
  } else if (ratio >= 0.7) {
    return "High ✅";
  } else if (ratio >= 0.4) {
    return "Average 👌";
  } else {
    return "Low ❌";
  }
};

const greeting = (name) => {
  const date = new Date(Date.now());
  const hours = date.getHours();
  let timeOfDay;
  if (hours < 12) {
    timeOfDay = "Morning";
  } else if (hours >= 12 && hours < 16) {
    timeOfDay = "Afternoon";
  } else if (hours >= 16 && hours < 21) {
    timeOfDay = "Evening";
  } else {
    timeOfDay = "Night";
  }
  return (
    <>
      {timeOfDay === "Night"
        ? `Hi ${name}, burn the midnight candle!`
        : `Good ${timeOfDay} ${name}`}
    </>
  );
};

/**
 * Calculate Time Remaining
 * @param {string} json
 * @returns {string}
 */
function calculateTimeRemaining(targetDate) {
  const currentDate = new Date();
  const totalMilliseconds = targetDate - currentDate;
  const totalSeconds = Math.floor(totalMilliseconds / 1000);

  if (totalSeconds <= 0) {
    return {
      total: 0,
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
    };
  }
  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = totalSeconds % 60;

  // Format days, hours, minutes, and seconds with leading zeros
  const formattedDays = days.toString().padStart(2, "0");
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  return {
    total: totalMilliseconds,
    days: formattedDays,
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  };
}

// function to remove empty options from an array
function removeEmptyOptions(choices) {
  // Use the filter method to remove empty choices
  const filteredChoices = choices?.filter((choice) => {
    // You can modify this condition to check for emptiness
    return choice.option && choice.option.trim() !== "";
  });

  // If there are no empty choices, return the original array
  if (filteredChoices?.length === choices?.length) {
    return choices;
  }

  return filteredChoices;
}

//Format Currency
const formatCurrency = (value) => {
  return value?.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 0,
  });
};

// function to convert days to month
const convertDaysToMonths = (days) => {
  return Math.floor(days / 30);
};

/**
 * Convert Table to CSV
 * @param {array} Array
 */
const convertToCSV = (data) => {
  const csvRows = [];
  // Header row
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(","));

  // Data rows
  data.forEach((row) => {
    const values = headers.map((header) => {
      const escaped = ("" + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
};

/**
 * Handle CSV Export
 */
const handleExport = (data, name) => {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${name}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const isPastDate = (date) => {
  const today = new Date();
  const selectedDate = new Date(date);
  return selectedDate < today;
};

const formatDateString = (dateString) => {
  // debugger;
  // Check if the date string matches "YYYY-MM-DD HH:mm:ss.SSSSSSSSS" format
  const regexISO = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{9}$/;
  // Check if the date string matches "MMM DD YYYY h:mmA" format
  const regexTextual = /^[A-Z][a-z][a-z]{3} \d{2} \d{4}  \d{1,2}:\d{2}[AP]M$/;
  // Determine the format and format with Moment.js accordingly
  if (regexISO.test(dateString)) {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss.SSSSSSSSS").format(
      "MMM Do YYYY, h:mm A"
    );
  } else if (regexTextual.test(dateString)) {
    return moment(dateString, "MMM DD YYYY h:mmA").format(
      "MMM Do YYYY, h:mm A"
    );
  } else {
    return dateString;
  }
};
function getAgeBracket(dateOfBirth) {
  // Calculate age
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  // Determine age bracket
  if (age >= 15 && age <= 17) return "15-17";
  if (age >= 18 && age <= 24) return "18-24";
  if (age >= 25 && age <= 34) return "25-34";
  if (age >= 35 && age <= 44) return "35-44";
  if (age >= 45 && age <= 54) return "45-54";
  if (age >= 55 && age <= 64) return "55-64";
  if (age >= 65) return "65+";
  return "Under 15";
}

const isTimeInRange = (startHour, endHour) => {
  const now = new Date();
  const currentHour = now.getHours();

  return currentHour >= startHour && currentHour < endHour;
};

export {
  padQuestionNumber,
  isEmptyObject,
  isEmptyArray,
  ucfirst,
  randomDate,
  passwordStrengthMeter,
  isLetter,
  isLowerCase,
  isUpperCase,
  hasRepeatedLetters,
  isString,
  isEmptyString,
  isArray,
  isObject,
  isDefined,
  isEmpty,
  lcfirst,
  autoEllipses,
  isNumeric,
  isNumber,
  objectToFormData,
  isEnv,
  randomString,
  isBoolean,
  inputFileToBase64,
  fileNameFromPath,
  keyBy,
  tryOrReplace,
  numericRange,
  characterRange,
  paginateData,
  pluck,
  hex2rgba,
  sliceInToGroups,
  mapAs,
  getCurrentDate,
  simpleDate,
  parseCardNumber,
  truncateText,
  copyTextToClipboard,
  debounce,
  throttle,
  pagination,
  toTitleCase,
  maskCardNumber,
  getUrlParameters,
  formatJsonToString,
  isEmail,
  formatDate,
  convertAnswerToAlphabet,
  arraysAreEqual,
  calculateGrade,
  greeting,
  calculateTimeRemaining,
  removeEmptyOptions,
  formatCurrency,
  convertDaysToMonths,
  convertToCSV,
  handleExport,
  arrayEquals,
  formatDate2,
  isPastDate,
  formatDateString,
  getAgeBracket,
  isTimeInRange,
};
