import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAdminUser from "../../hooks/useAdminUser";
import { loginAdminUserAction } from "../../redux/adminAuth/adminAuthActions";
import { useNotifications } from "../notifications/notifications";
import GlobalThemeProvider from "../../GlobalThemeProvider";

const LoginButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  textTransform: "capitalize",
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CustomTextField = styled(TextField)({
  border: "1.2px solid #B1B1B1",
  borderRadius: "4px",
  "& .MuiInputBase-input": {
    backgroundColor: "#fff",
    "&:focus": {
      borderBottom: "1px solid #40499D",
      borderRadius: "4px",
      backgroundColor: "#fff",
    },
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
});

const StyledInputAdornment = styled(InputAdornment)({
  height: "100%",
  maxHeight: "100%",
  pr: 0,
  m: 0,
  borderRadius: "0 4px 4px 0",
});

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAdminUser();
  const [showPassword, setShowPassword] = useState(false);
  const [fail, setFail] = useState('');
  const [ToastState, setToastState] = useNotifications()

  const handlePasswordVisibilityToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user.token) {

      navigate("/admin/dashboard");
    }
  }, [user.token]);

  useEffect(() => {
    if (fail) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: fail
      })
    }
  }, [fail])

  const handleAdminLogin = () => {

    setFail("");
    dispatch(loginAdminUserAction(formValues, (res) => setFail(res.data.msg)));

  };

  return (
    <GlobalThemeProvider>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className="text-field" sx={{ mt: 3, width: "40ch" }}>
              <Typography variant="subtitle1" sx={{ color: "#263A43", mb: 1 }}>
                User name
              </Typography>
              <CustomTextField
                variant="filled"
                placeholder="Enter Username"
                value={formValues.email}
                onChange={(e) =>
                  setFormValues({ ...formValues, email: e.target.value })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="text-field" sx={{ width: "40ch" }}>
              <Typography variant="subtitle1" sx={{ color: "#263A43", mb: 1 }}>
                Password
              </Typography>
              <CustomTextField
                value={formValues.password}
                onChange={(e) =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
                variant="filled"
                placeholder="Type your password here"
                //   sx={{ background:"red" }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <StyledInputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibilityToggle}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </StyledInputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAdminLogin();
                  }
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <FormControlLabel
                  control={<Checkbox color="primary" size="small" />}
                  label={
                    <Typography sx={{ color: "#8E8E8E", fontSize: "14px" }}>
                      Remember me?
                    </Typography>
                  }
                />
                <Link
                  to="/adminForgotPassword"
                  style={{ fontSize: "14px", color: "#8E8E8E" }}
                >
                  Forgot Password?
                </Link>
              </Box>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              className="text-field"
              sx={{ width: "40ch" }}
              variant="standard"
            >
              <LoginButton
                type="button"
                className="login-button"
                sx={{
                  mb: 2,
                }}
                disableElevation
                size="large"
                variant="contained"
                onClick={handleAdminLogin}
                disabled={
                  user.isLoading || !formValues.email || !formValues.password
                }
              >
                {!user.isLoading ? (
                  "Login"
                ) : (
                  <DataUsageIcon size={24} className="animate-spin ml-4" />
                )}
              </LoginButton>
            </FormControl>
          </Grid>
        </Grid>
        <ToastState />
      </form>
    </GlobalThemeProvider>
  );
};

export default LoginForm;
