import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Grid, Skeleton, Stack, Typography, styled } from "@mui/material";
import React from "react";
import {
  BadgeIcon,
  CompletedStudyIcon,
  CompletedTestIcon,
  AverageIcon,
} from "../../../assets/icons/Icons";
import { useDispatch } from "react-redux";
import useDashboard from "../../../hooks/useDashboard";

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "24rem", 
    height: "14rem",
  },
  width: "100%",
  height: "10rem",
  margin: "auto",
  // justifyContent: "center",
  // alignItems: "center",
  position: "relative",
}));

const Line = styled("div")({
  position: "absolute",
  backgroundColor: "black",
  zIndex: 10,
});

const VerticalLineLongTop = styled(Line)({
  width: ".1px",
  height: "40%",
  left: "50%",
  transform: "translateX(-50%)",
});
const VerticalLineLongBottom = styled(Line)({
  width: ".1px",
  height: "40%",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",
});
const VerticalLineShort = styled(Line)({
  width: ".1px",
  height: "10%",
  left: "50%",
  top: "45%",
  transform: "translateX(-50%)",
});

const HorizontalLineLongLeft = styled(Line)({
  width: "40%",
  height: ".1px",
  top: "50%",
  transform: "translateY(-50%)",
});

const HorizontalLineLongRight = styled(Line)({
  width: "40%",
  height: ".1px",
  right: 0,
  top: "50%",
  transform: "translateY(-50%)",
});
const HorizontalLineShort = styled(Line)({
  width: "10%",
  height: ".1px",
  top: "50%",
  left: "45%",
  transform: "translateY(-50%)",
});

const IntersectingLine = styled(Line)({
  width: "100%",
  height: 1,
  //   top: '50%',
  transform: "translateY(-50%) rotate(45deg)",
  transformOrigin: "center",
});

function formatTime(seconds) {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return `${hours}h ${minutes}m`;
  } else {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}m`;
  }
}

const OverviewStats = () => {
  const dispatch = useDispatch();
  const { dashboardStat, isLoading } = useDashboard();
  return (
    <StyledGridContainer container spacing={0}>
      <VerticalLineShort />
      <HorizontalLineShort />
      <HorizontalLineLongLeft />
      <HorizontalLineLongRight />
      <VerticalLineLongTop />
      <VerticalLineLongBottom />
      <Grid item p pt={3} xs={6}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <CompletedStudyIcon sx={{width: {xs: 20}, height: {xs: 20}}}/>
          <Stack>
            {isLoading ? (
              <Skeleton variant="text" width={100} animation="wave" />
            ) : (
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{ color: "brandPrimary.main" , fontSize:{xs: "16px", sm: "24px"}}}
              >
                {dashboardStat?.completedStudies ?? 0}
              </Typography>
            )}
            <Typography sx={{fontSize:{xs: "13px", sm: "16px"}}}>Completed Studies</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item p pt={3} xs={6}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <CompletedTestIcon />
          <Stack>
            {isLoading ? (
              <Skeleton variant="text" width={100} animation="wave" />
            ) : (
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{ color: "brandPrimary.main", fontSize:{xs: "16px", sm: "24px"}}}
              >
                {dashboardStat?.completedTest ?? 0}
              </Typography>
            )}
            <Typography sx={{fontSize:{xs: "13px", sm: "16px"}}}>Tests Taken</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item p pt={3} xs={6}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <AverageIcon />
          <Stack>
            {isLoading ? (
              <Skeleton variant="text" width={100} animation="wave" />
            ) : (
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{ color: "brandPrimary.main", fontSize:{xs: "16px", sm: "24px"}}}
              >
                {formatTime(dashboardStat?.averageTestTime ?? "")}
              </Typography>
            )}
            <Typography sx={{fontSize:{xs: "13px", sm: "16px"}}}>Average Timed Test</Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item p pt={3} xs={6}>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <BadgeIcon />
          <Stack>
            {isLoading ? (
              <Skeleton variant="text" width={100} animation="wave" />
            ) : (
              <Typography
                variant="h5"
                fontWeight={"bold"}
                sx={{ color: "brandPrimary.main", fontSize:{xs: "16px", sm: "24px"}}}
              >
                {dashboardStat?.badges ?? 0}
              </Typography>
            )}
            <Typography sx={{fontSize:{xs: "13px", sm: "16px"}}}>Badges</Typography>
          </Stack>
        </Box>
      </Grid>
    </StyledGridContainer>
  );
};

export { OverviewStats };
