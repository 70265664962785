import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Select,
  FormControlLabel,
  Radio,
  Stack,
  MenuItem,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import useContentUpload from "../../../hooks/useContentUpload";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addExamAction,
  fetchExamYearsAction,
  fetchSubjectsAction,
  GetAllExamSeries,
  getALlExamType,
  GetExamByExamId,
  updateExamAction,
  getQuestionSectionByExamId,
} from "../../../redux/contentUpload/contentUploadActions";
import useAdminUser from "../../../hooks/useAdminUser";
import { AntSwitch } from "../../../components/Settings/Notifications";

const ContentUploadTestConfig = (props) => {
  const { input, setInput, setToastState, exam } = props;

  const {
    testYearId,
    testDescription,
    testCompleteHours,
    testCompleteMinutes,
    testCompleteSeconds,
    examType,
    examSeries,
    useCalculator,
  } = input;

  const {
    subjectData,
    examYearData,
    examTypeData,
    examSeriesData,
    singleExamData,
    loadingExam,
    error,
  } = useContentUpload();

  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();
  const location = useLocation();
  const subjectId = location?.state?.subjectId;

  useEffect(() => {
    dispatch(fetchExamYearsAction());
    dispatch(getALlExamType());
    dispatch(GetAllExamSeries());
  }, []);

  useEffect(() => {
    dispatch(fetchSubjectsAction());
  }, []);

  useEffect(() => {
    if (exam) {
      dispatch(GetExamByExamId(exam?.examId));
      dispatch(getQuestionSectionByExamId(exam?.examId));
    }
  }, []);
  useEffect(() => {
    if (exam && singleExamData) {
      convertSeconds(singleExamData?.timeAllowed);
    }
  }, [exam, singleExamData]);

  const onSuccess = (res) => {
    if (!exam) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: "Exam added successfully",
      });
    }
    if (exam) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: "Exam updated successfully",
      });
    }
    setTimeout(() => {
      setInput({
        ...input,
        progress: "question-manager",
        examId: res?.examId,
        examSetupState: 2,
      });
    }, 2000);
  };

  const onFailure = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res?.msg,
    });
  };

  const convertSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hourString = hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}` : "";
    const minuteString =
      minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";
    const secondString =
      remainingSeconds > 0
        ? `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
        : "";

    if (hours > 0) {
      setInput({
        ...input,
        testCompleteHours: hours,
        testCompleteMinutes: minutes,
        testCompleteSeconds: remainingSeconds,
        examType: singleExamData?.examType,
        examSeries: singleExamData?.seriesTypeId,
      });
      return `${hourString} : ${minuteString || "0 minute"} ${
        secondString && `: ${secondString}`
      }`;
    } else if (!hours && minutes > 0) {
      setInput({
        ...input,
        testCompleteMinutes: minutes,
        testCompleteSeconds: remainingSeconds,
        examType: singleExamData?.examType,
        examSeries: singleExamData?.seriesTypeId,
      });

      return `${minuteString} ${secondString && `: ${secondString}`}`;
    }

    setInput({
      ...input,
      testCompleteSeconds: remainingSeconds,
      examType: singleExamData?.examType,
      examSeries: singleExamData?.seriesTypeId,
    });
    return secondString;
  };

  const handleSubmit = () => {
    if (
      !examSeries ||
      !examType ||
      !testYearId ||
      !testDescription ||
      !subjectId
    ) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: "All fields are required",
      });
      return false;
    }
    let data = {
      examName:
        subjectData?.find((subject) => subject.subjectId === subjectId)
          ?.subjectName +
        " " +
        examSeriesData?.find((series) => series.SeriesTypeId == examSeries)
          ?.SeriesType,
      examYearId: testYearId,
      description: testDescription,
      timeAllowed:
        testCompleteHours * 3600 +
        testCompleteMinutes * 60 +
        testCompleteSeconds,
      subjectId: subjectId,
      profileId: userInfo?.data?.profileId,
      noOfQuestions: 100,
      seriesTypeId: parseInt(examSeries),
      examTypeId: parseInt(examType),
      useCalculator: useCalculator,
    };
    if (exam) {
      data = {
        ...data,
        examId: exam.examId,
        examInstruction: singleExamData?.examInstruction ?? "",
      };
    }
    if (exam) {
      dispatch(
        updateExamAction(
          data,
          (res) => onSuccess(res),
          (res) => onFailure(res)
        )
      );
    } else {
      dispatch(
        addExamAction(
          data,
          (res) => onSuccess(res),
          (res) => onFailure(res)
        )
      );
    }
  };


  return (
    <>
      <Box>
        <Typography
          sx={{
            color: "#313131",
            fontFamily: "Open Sans",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Test configuration -{" "}
          {exam
            ? singleExamData?.examName
            : subjectData?.find((subject) => subject.subjectId === subjectId)
                ?.subjectName}
        </Typography>
      </Box>

      <>
        <Box sx={{ mt: 2 }}>
          <Paper sx={{ minWidth: 275, borderRadius: "16px" }}>
            <CardContent>
              <Typography
                sx={{
                  mt: 3,
                  color: "#40499D",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22.4px",
                }}
              >
                Exam Series
              </Typography>

              <NativeSelect
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={examSeries}
                onChange={(e) =>
                  setInput({ ...input, examSeries: e.target.value })
                }
                fullWidth
              >
                <option value="" disabled>
                  Select
                </option>
                {examSeriesData?.map((series) => (
                  <option sx={{ ml: 3 }} value={series?.SeriesTypeId}>
                    {series?.SeriesType}
                  </option>
                ))}
              </NativeSelect>

              <Typography
                sx={{
                  mt: 3,
                  color: "#40499D",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22.4px",
                }}
              >
                Year
              </Typography>

              <NativeSelect
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={testYearId}
                onChange={(e) =>
                  setInput({ ...input, testYearId: e.target.value })
                }
                fullWidth
              >
                <option value="" disabled>
                  Select
                </option>
                {examYearData?.map((examYear) => (
                  <option sx={{ ml: 3 }} value={examYear.examYearId}>
                    {examYear.year}
                  </option>
                ))}
              </NativeSelect>

              <Typography
                sx={{
                  mt: 3,
                  mb: 1,
                  color: "#40499D",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22.4px",
                }}
              >
                Description
              </Typography>

              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                value={testDescription}
                onChange={(e) =>
                  setInput({ ...input, testDescription: e.target.value })
                }
                multiline={true}
                rows={3}
              />
              <Typography
                sx={{
                  mt: 1,
                  color: "#000",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16.8px",
                }}
              >
                Add test description{" "}
              </Typography>

              <Typography
                sx={{
                  mt: 3,
                  color: "#40499D",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22.4px",
                }}
              >
                Exam Type
              </Typography>

              <NativeSelect
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={examType}
                onChange={(e) =>
                  setInput({ ...input, examType: e.target.value })
                }
                fullWidth
              >
                <option value="" disabled>
                  Select
                </option>
                {examTypeData?.map((type) => (
                  <option sx={{ ml: 3 }} value={type.ExamTypeId}>
                    {type.ExamType}
                  </option>
                ))}
              </NativeSelect>
            </CardContent>
          </Paper>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Paper sx={{ borderRadius: "16px" }}>
            <CardContent>
              <Box>
                <Typography
                  sx={{
                    mt: 1,
                    color: "#000",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                  }}
                >
                  Test duration
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    color: "#000",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16.8px",
                  }}
                >
                  Select how the duration should be measured
                </Typography>
              </Box>

              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <FormControlLabel
                  sx={{
                    color: "#000",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "19.6px",
                  }}
                  value=""
                  control={<Radio checked={true} />}
                  label="Time to complete the test (hh:mm): "
                />
                <TextField
                  required
                  label="HH"
                  type="number"
                  id="outlined-basic"
                  variant="outlined"
                  value={testCompleteHours}
                  defaultValue="00"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setInput({ ...input, testCompleteHours: e.target.value })
                  }
                />
                <TextField
                  required
                  label="MM"
                  type="number"
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue="00"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={testCompleteMinutes}
                  onChange={(e) =>
                    setInput({ ...input, testCompleteMinutes: e.target.value })
                  }
                />
                <TextField
                  required
                  type="number"
                  label="SS"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={testCompleteSeconds}
                  onChange={(e) =>
                    setInput({ ...input, testCompleteSeconds: e.target.value })
                  }
                />
              </Stack>

              {/* <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <FormControlLabel sx={{
                  color: '#000', fontFamily: 'Open Sans',
                  fontSize: '14px', fontStyle: 'normal', fontWeight: 400,
                  lineHeight: '19.6px'
                }} value="time" control={<Radio />} label="Time to answer each question(mm:ss): " />

                <TextField
                  required
                  type="number"
                  label='HH'
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={questionAnswerHours}
                  onChange={(e) => setInput({ ...input, questionAnswerHours: e.target.value })}

                />
                <TextField
                  required
                  type="number"
                  label='MM'
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={questionAnswerMinutes}
                  onChange={(e) => setInput({ ...input, questionAnswerMinutes: e.target.value })}
                />
                <TextField
                  required
                  type="number"
                  label='SS'
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={questionAnswerSeconds}
                  onChange={(e) => setInput({ ...input, questionAnswerSeconds: e.target.value })}
                />
              </Stack> */}
            </CardContent>
          </Paper>
        </Box>
      </>

      {/* <Box sx={{ mt: 2 }}>
        <Paper sx={{ borderRadius: '16px' }}>
          <CardContent>

            <Box>
              <Typography sx={{
                mt: 1, color: '#40499d', fontFamily: 'Open Sans',
                fontSize: '16px', fontStyle: 'normal', fontWeight: 600,
                lineHeight: '22.4px'
              }}>Question Section</Typography>
              <Typography sx={{
                color: '#000', fontFamily: 'Open Sans',
                fontSize: '12px', fontWeight: 400,
                lineHeight: '16.8px'
              }}
              >
                Select how the duration should be measured</Typography>
            </Box>



          </CardContent>
        </Paper >

      </Box> ====This flow will be implemented afterwards */}

      <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button
          sx={{
            backgroundColor: "#40499D",
            borderRadius: 2,
            textTransform: "none",
            ml: 2,
          }}
          variant="contained"
          onClick={() => handleSubmit()}
        >
          Save and Continue
        </Button>
      </Box>
    </>
  );
};

export default ContentUploadTestConfig;
