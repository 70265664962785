import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Radio,
  Stack,
  FormControlLabel,
  FormControl,
  InputLabel,
  TextField,
  RadioGroup,
  NativeSelect,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  addExamQuestionAction,
  fetchExamQuestionsAction,
} from "../../../redux/contentUpload/contentUploadActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../../components/notifications/notifications";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { RTEditor } from "../../../components/RTEditor";
import MathQuillEditor from "../../../components/MathQuillEditor";

import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  createFromBlockArray,
  ContentState,
  createWithContent,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import useAdminUser from "../../../hooks/useAdminUser";
import useContentUpload from "../../../hooks/useContentUpload";
import OpenAI from "openai";
import QuestionSection from "../../../components/QuestionSection/QuestionSection";
import FlagIcon from "@mui/icons-material/Flag";
import CalculateIcon from "@mui/icons-material/Calculate";

const AlertCircleIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="alert-circle" clip-path="url(#clip0_2286_25905)">
      <path
        id="Vector"
        d="M7.99967 14.6666C11.6816 14.6666 14.6663 11.6818 14.6663 7.99992C14.6663 4.31802 11.6816 1.33325 7.99967 1.33325C4.31778 1.33325 1.33301 4.31802 1.33301 7.99992C1.33301 11.6818 4.31778 14.6666 7.99967 14.6666Z"
        stroke="#313131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M8 5.33325V7.99992"
        stroke="#313131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M8 10.6667H8.00667"
        stroke="#313131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2286_25905">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "AlertCircle"
);

const ContentUploadQuestionManagerMCQInputs = ({
  input,
  setInput,
  setToastState,
  exam,
}) => {
  const [question, setEditorStateQuestion] = React.useState("");
  const [answer1, setEditorStateAnswer1] = React.useState("");
  const [answer2, setEditorStateAnswer2] = React.useState("");
  const [answer3, setEditorStateAnswer3] = React.useState("");
  const [answer4, setEditorStateAnswer4] = React.useState("");
  const [answer5, setEditorStateAnswer5] = React.useState("");
  const [correctAnswerScore, setCorrectAnswerScore] = React.useState(0);
  const [inCorrectAnswerScore, setInCorrectAnswerScore] = React.useState(0);

  const [answer, setAnswer] = React.useState("0");

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };
  const [active, setActive] = React.useState(false);
  const [content, setContent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openEquation, setOpenEquation] = useState(false);
  const [flagReason, setFlagReason] = useState("");

  const [tooltipContent, setTooltipContent] = useState("Flag");
  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();
  const { loadingExam, questionSection } = useContentUpload();

  const onSuccess = (res) => {
    setInput({ ...input, examSetupState: 2 });
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Question added successfully",
    });
  };

  const handleClick = (event, prevActive) => {
    setActive((prevActive) => !prevActive);
    // setActive(prevActive);
    if (prevActive) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleMathChange = (latex) => {
    // Handle the change of mathematical content
    console.log("Math content:", latex);
  };

  const handleOpenEquation = (event, prevActive) => {
    // setActive((prevActive) => !prevActive);
    // setActive(prevActive);
    if (prevActive) {
      setOpenEquation(true);
    }
  };

  const handleCloseEquation = () => {
    setOpenEquation(false);
  };

  const handleCommentSubmit = (comment) => {
    setFlagReason(comment);

    setTooltipContent(flagReason);

    setOpenDialog(false);
  };
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const handleSubmit = () => {
    const data = {
      question: question,
      // question: draftToHtml(convertToRaw(question.getCurrentContent())),
      choices:
        "|||" +
        answer1 +
        "|||" +
        answer2 +
        "|||" +
        answer3 +
        "|||" +
        answer4 +
        (answer5 === "<p><br></p>" || answer5 === "" ? "" : "|||" + answer5),
      answer: answer,
      scoreWeight: correctAnswerScore,
      examId: exam ? exam?.examId : input?.examId,
      profileId: userInfo?.data?.profileId,
      sectionId: selectedSectionId,
      isActive: active,
      comment: flagReason,
    };
    dispatch(addExamQuestionAction(data, (res) => onSuccess(res)));
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Questions manager
        </Typography>
        <span
          className="e-study-primary underline cursor-pointer"
          onClick={() => setInput({ ...input, examSetupState: 2 })}
        >
          View all questions
        </span>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Paper sx={{ minWidth: 275, borderRadius: "16px" }}>
          <CardContent>
            <Box>
              <div
                // sx={{
                //   color: "#000",
                //   fontFamily: "Open Sans",
                //   fontSize: "16px",
                //   fontWeight: 400,
                //   lineHeight: "27.24px",
                //   flexDirection: "row",
                // }}
                className="flex space-x-4 items-center"
              >
                <KeyboardArrowLeftIcon
                  className="cursor-pointer"
                  onClick={() => setInput({ ...input, examSetupState: 2 })}
                />{" "}
                Question
                <Tooltip title={tooltipContent} arrow>
                  <ToggleButtonGroup
                    // orientation="vertical"
                    color="secondary"
                    value={active}
                    exclusive
                    onChange={handleClick}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value="active"
                      style={{ color: active ? "red" : "gray" }}
                    >
                      <FlagIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Tooltip>
                <Tooltip title={"Enter Expression"} arrow>
                  <ToggleButtonGroup
                    // orientation="vertical"
                    color="secondary"
                    exclusive
                    onChange={handleOpenEquation}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value="active"
                      // style={{ color: active ? "red" : "gray" }}
                    >
                      <CalculateIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Tooltip>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>Flag Comment</DialogTitle>
                  <DialogContent>
                    {/* Add input field for comment */}
                    <TextField
                      variant="outlined"
                      value={flagReason}
                      input
                      type="text"
                      placeholder="Enter your reason"
                      onChange={(e) => setFlagReason(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={() => handleCommentSubmit(flagReason)}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={openEquation} onClose={handleCloseEquation}>
                  <DialogTitle>Expression</DialogTitle>
                  <DialogContent>
                    <div className="flex items-center justify-center">
                      <MathQuillEditor
                        latex={content}
                        onChange={handleMathChange}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseEquation}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Box>
            <Box sx={{ mt: 2, width: "95%", mx: "auto" }}>
              <Box sx={{ mt: 2, mx: "auto" }}>
                {/* <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  editorState={question}
                  onEditorStateChange={onEditorStateChangeQuestion}
                  toolbar={{
                    image: {
                      // uploadCallback: handleImageUpload,
                      alt: { present: true, mandatory: true },
                      previewImage: true,
                    },
                  }}
                /> */}
                <RTEditor
                  theme={"snow"}
                  value={question}
                  key="questionAdd"
                  setValue={setEditorStateQuestion}
                />
              </Box>

              {/* <Box sx={{ mt: 2, width: "95%", mx: "auto" }}> */}
              {/* <Box sx={{ mt: 2, mx: "auto" }}>
                <RTEditor
                  theme={"snow"}
                  value={question}
                  key={"question"}
                  setValue={setEditorStateQuestion}
                />
              </Box> */}

              <Stack mb={3}>
                <NativeSelect
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedSectionId}
                  onChange={(e) => setSelectedSectionId(e.target.value)}
                  fullWidth
                >
                  <option value="0">Select</option>
                  {questionSection?.map((type) => (
                    <option
                      sx={{ ml: 3 }}
                      value={type.sectionId}
                      key={type?.sectionId}
                    >
                      {type?.sectionName}{" "}
                    </option>
                  ))}
                </NativeSelect>
              </Stack>
              {/* <Box sx={{ mt: 2 }}>
                <Paper sx={{ borderRadius: "16px" }}>
                  <CardContent>
                    <QuestionSection
                      // ExamId={exam ? exam.examId : input.examId}
                      questionSection={questionSection}
                    />
                  </CardContent>
                </Paper>
              </Box> */}

              <Box sx={{ mt: 5, width: "90%" }}>
                <Typography>Answers</Typography>
                <Typography>
                  After providing possible answers, select the correct one.
                </Typography>
              </Box>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={answer}
                  onChange={handleChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        {/* <Editor
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          editorState={answer1}
                          onEditorStateChange={onEditorStateChangeAnswer1}
                        /> */}

                        <RTEditor
                          theme={"snow"}
                          key={"answer1add"}
                          value={answer1}
                          setValue={setEditorStateAnswer1}
                        />
                        {/* <Delete /> */}
                      </Stack>
                    }
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        {/* <Editor
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          editorState={answer2}
                          onEditorStateChange={onEditorStateChangeAnswer2}
                        /> */}

                        <RTEditor
                          theme={"snow"}
                          key={"answer2add"}
                          value={answer2}
                          setValue={setEditorStateAnswer2}
                        />

                        {/* <Delete /> */}
                      </Stack>
                    }
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label={
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        {/* <Editor
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          editorState={answer3}
                          onEditorStateChange={onEditorStateChangeAnswer3}
                        /> */}

                        <RTEditor
                          theme={"snow"}
                          key={"answer3add"}
                          value={answer3}
                          setValue={setEditorStateAnswer3}
                        />

                        {/* <Delete /> */}
                      </Stack>
                    }
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio />}
                    label={
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        {/* <Editor
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          editorState={answer4}
                          onEditorStateChange={onEditorStateChangeAnswer4}
                        /> */}

                        <RTEditor
                          theme={"snow"}
                          key={"answer4add"}
                          value={answer4}
                          setValue={setEditorStateAnswer4}
                        />

                        {/* <Delete /> */}
                      </Stack>
                    }
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio />}
                    label={
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        {/* <Editor
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          editorState={answer5}
                          onEditorStateChange={onEditorStateChangeAnswer5}
                        /> */}

                        <RTEditor
                          theme={"snow"}
                          key={"answer5add"}
                          value={answer5}
                          setValue={setEditorStateAnswer5}
                        />

                        {/* <Delete /> */}
                      </Stack>
                    }
                  />
                </RadioGroup>
              </FormControl>

              {/* <div className='mt-3'>
                <Button startIcon={<Add />}> Add answer</Button>
              </div> */}

              <Box sx={{ mt: 5 }}>
                <Typography>Score setting</Typography>
                <Typography>
                  Specify the points for a correct answer. You can deduct points
                  for wrong answers or you can put 0 (zero) points for this
                  answer if you prefer not to assign any score.
                </Typography>
              </Box>

              <Box
                sx={{ mt: 5 }}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                  <InputLabel>Points for correct answer</InputLabel>
                  <TextField
                    type="number"
                    aria-describedby="my-helper-text"
                    value={correctAnswerScore}
                    onChange={(e) => setCorrectAnswerScore(e.target.value)}
                  />
                </Stack>
                <Button
                  sx={{
                    backgroundColor: "#40499D",
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {loadingExam ? (
                    <DataUsageIcon size={24} className="animate-spin ml-4" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Paper>
      </Box>
    </>
  );
};

export default ContentUploadQuestionManagerMCQInputs;
