import * as testActions from "./types";
import Axios from "../../connection/defaultClient";

export const fetchSubjectsByProfileId = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: testActions.FETCH_SUBJECT_BY_PROFILE_ID,
      payload: profileId,
    });
    Axios.get(`/Subject/GetSubjectsByProfileId?profileId=${profileId}`)
      .then((response) => {
        dispatch({
          type: testActions.FETCH_SUBJECT_BY_PROFILE_ID_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: testActions.FETCH_SUBJECT_BY_PROFILE_ID_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchExamsBySubjectId = (subjectId) => {
  return (dispatch) => {
    dispatch({
      type: testActions.FETCH_EXAM_BY_SUBJECT_ID,
      payload: subjectId,
    });

    Axios.get(`/Subject/GetExamBySubjectId?subjectId=${subjectId}`)
      .then((response) => {
        dispatch({
          type: testActions.FETCH_EXAM_BY_SUBJECT_ID_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: testActions.FETCH_EXAM_BY_SUBJECT_ID_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchTestQuestionByQuestionId = (questionId) => {
  return (dispatch) => {
    Axios.post(`/Exam/GetTestQuestionByQuestionId?questionId=${questionId}`)
      .then((response) => {
        dispatch({
          type: testActions.SET_EACH_EXAM_QUESTIONS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: testActions.SET_EACH_EXAM_QUESTIONS_FAILED,
          payload: err,
        });
      });
  };
};

export const sendTestToBack = (submitData) => {
  return (dispatch) => {
    Axios.post(`/Exam/SubmitCandidateObjectiveResponse`, submitData)
      .then((response) => {
        dispatch({
          type: testActions.STORE_EXAM_RESULT,
          payload: { questionAnswer: response.data.data, totalScore: response.data.totalScore },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setAllExamQuestions = ({ examId, examName, subjectName }) => {
  const testName = `${subjectName} - ${examName}`;
  return (dispatch) => {
    dispatch({
      type: testActions.LOADING_EXAM,
      // payload: { subjectId, examYearId, examType },
      payload: examId,
    });
    Axios.get(`/Exam/GetExamQuestionsByExamId?ExamId=${examId}`)
      .then((response) => {
        dispatch({
          type: testActions.SET_ALL_EXAM_QUESTION_ID,
          payload: [response.data, { testname: testName }],
        });
      })
      .catch((error) => {
        dispatch({
          type: testActions.LOADING_EXAM_FAIL,
          payload: error.message,
        });
      });
  };
};

export const resetQuestions = () => {
  return (dispatch) => {
    dispatch({
      type: testActions.RESET_QUESTION_ANSWERED,
    });
  };
};

export const resetExamQuestions = () => {
  return (dispatch) => {
    dispatch({
      type: testActions.RESET_EXAM_BY_SUBJECT_ID,
    });
  };
};

// Test Dashboard Statistics

export const fetchTestStatisticsByProfileId = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: testActions.FETCH_TEST_STATISTICS_BY_PROFILE_ID,
      payload: profileId,
    });
    Axios.get(`/Exam/GetTestStatisticsByProfileId?ProfileId=${profileId}`)
      .then((response) => {
        dispatch({
          type: testActions.FETCH_TEST_STATISTICS_BY_PROFILE_ID_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: testActions.FETCH_TEST_STATISTICS_BY_PROFILE_ID_FAILURE,
          payload: err,
        });
      });
  };
};

// Get Exam With Highest Score

export const fetchExamWithHighestScore = (profileId, subjectId) => {
  return (dispatch) => {
    dispatch({
      type: testActions.FETCH_EXAM_WITH_HIGHEST_SCORE,
      payload: { profileId, subjectId },
    });
    Axios.get(`/Exam/GetHighestScore?ProfileId=${profileId}&SubjectId=${subjectId}`)
      .then((response) => {
        dispatch({
          type: testActions.FETCH_EXAM_WITH_HIGHEST_SCORE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: testActions.FETCH_EXAM_WITH_HIGHEST_SCORE_FAILURE,
          payload: err,
        });
      });
  };
};

// Get Exam With Previous Score

export const fetchExamWithPreviousScore = (profileId, subjectId) => {
  return (dispatch) => {
    dispatch({
      type: testActions.FETCH_PREVIOUS_SCORE,
      payload: { profileId, subjectId },
    });
    Axios.get(`/Exam/GetExamsPreviousScore?ProfileId=${profileId}&SubjectId=${subjectId}`)
      .then((response) => {
        dispatch({
          type: testActions.FETCH_PREVIOUS_SCORE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: testActions.FETCH_PREVIOUS_SCORE_FAILURE,
          payload: err,
        });
      });
  };
};
