export const TestData = () => ({
  id: 1,
  name: "Mathematics(2018)",
  timeAllocated: 45, // time in minutes
  questions: questions,
});

const questions = [
  {
    id: 0,
    questionNumber: "Question 1",
    question: "What is the capital of France?",
    image: null,
    flagged: false,
    options: [
      {
        id: 0,
        optionValue: "Paris",
        selected: false,
      },
      {
        id: 1,
        optionValue: "Berlin",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Madrid",
        selected: false,
      },
      {
        id: 3,
        optionValue: "Rome",
        selected: false,
      },
    ],
  },
  {
    id: 1,
    questionNumber: "Question 2",
    question: "Which planet is known as the Red Planet?",
    image: null,
    flagged: true,
    options: [
      {
        id: 0,
        optionValue: "Mars",
        selected: false,
      },
      {
        id: 1,
        optionValue: "Venus",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Jupiter",
        selected: false,
      },
      {
        id: 3,
        optionValue: "Mercury",
        selected: false,
      },
    ],
  },
  {
    id: 2,
    questionNumber: "Question 3",
    question: "Which gas do plants primarily absorb from the atmosphere?",
    image: null,
    flagged: false,
    options: [
      {
        id: 0,
        optionValue: "Oxygen",
        selected: false,
      },
      {
        id: 1,
        optionValue: "Carbon Dioxide",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Nitrogen",
        selected: false,
      },
      {
        id: 3,
        optionValue: "Helium",
        selected: false,
      },
    ],
  },
  {
    id: 3,
    questionNumber: "Question 4",
    question: "What is the largest mammal on Earth?",
    image: null,
    flagged: true,
    options: [
      {
        id: 0,
        optionValue: "Elephant",
        selected: false,
      },
      {
        id: 1,
        optionValue: "Blue Whale",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Giraffe",
        selected: false,
      },
      {
        id: 3,
        optionValue: "Hippopotamus",
        selected: false,
      },
    ],
  },
  {
    id: 4,
    questionNumber: "Question 5",
    question: "Which famous scientist developed the theory of relativity?",
    image: null,
    flagged: false,
    options: [
      {
        id: 0,
        optionValue: "Isaac Newton",
        selected: false,
      },
      {
        id: 1,
        optionValue: "Albert Einstein",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Galileo Galilei",
        selected: false,
      },
      {
        id: 3,
        optionValue: "Nikola Tesla",
        selected: false,
      },
    ],
  },
  {
    id: 5,
    questionNumber: "Question 6",
    question: "Which famous play was written by William Shakespeare?",
    image: null,
    flagged: true,
    options: [
      {
        id: 0,
        optionValue: "Romeo and Juliet",
        selected: false,
      },
      {
        id: 1,
        optionValue: "War and Peace",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Pride and Prejudice",
        selected: false,
      },
      {
        id: 3,
        optionValue: "To Kill a Mockingbird",
        selected: false,
      },
    ],
  },
  {
    id: 6,
    questionNumber: "Question 7",
    question: "What is the chemical symbol for the element gold?",
    image: null,
    flagged: false,
    options: [
      {
        id: 0,
        optionValue: "Go",
        selected: false,
      },
      {
        id: 1,
        optionValue: "Au",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Ag",
        selected: false,
      },
      {
        id: 3,
        optionValue: "Go",
        selected: false,
      },
    ],
  },
  {
    id: 7,
    questionNumber: "Question 8",
    question: "Which famous scientist formulated the laws of motion and universal gravitation?",
    image: null,
    flagged: true,
    options: [
      {
        id: 0,
        optionValue: "Isaac Newton",
        selected: false,
      },
      {
        id: 1,
        optionValue: "Albert Einstein",
        selected: false,
      },
      {
        id: 2,
        optionValue: "Galileo Galilei",
        selected: false,
      },
      {
        id: 3,
        optionValue: "Nikola Tesla",
        selected: false,
      },
    ],
  },
  // Add more questions here
];
