import {
  ALL_EXAM_YEARS_START,
  ALL_EXAM_YEARS_SUCCESS,
  ALL_EXAM_YEARS_FAIL,
  ADD_EXAM_START,
  ADD_EXAM_SUCCESS,
  ADD_EXAM_FAIL,
  GET_EXAM_BY_SUBJECT_ID_START,
  GET_EXAM_BY_SUBJECT_ID_SUCCESS,
  GET_EXAM_BY_SUBJECT_ID_FAIL,
  ADD_SUBJECT_FAIL,
  ADD_SUBJECT_SUCCESS,
  ADD_SUBJECT_START,
  ALL_SUBJECTS,
  ALL_SUBJECTS_SUCCESS,
  ALL_SUBJECTS_FAIL,
  ALL_SUBJECTS_START,
  GET_SUBJECT_BY_ID_START,
  GET_SUBJECT_BY_ID_SUCCESS,
  GET_SUBJECT_BY_ID_FAIL,
  UPDATE_SUBJECT_START,
  UPDATE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FAIL,
  ALL_SUBJECT_EXAMS_SUCCESS,
  ALL_SUBJECT_EXAMS_FAIL,
  ALL_SUBJECT_EXAMS_START,
  ADD_EXAM_QUESTION_START,
  ADD_EXAM_QUESTION_SUCCESS,
  ADD_EXAM_QUESTION_FAIL,
  GET_EXAM_QUESTIONS_START,
  GET_EXAM_QUESTIONS_SUCCESS,
  GET_EXAM_QUESTIONS_FAIL,
  GET_ALL_EXAM_TYPE,
  GET_ALL_EXAM_SERIES,
  GET_EXAM_BY_EXAMID,
  LOAD_EXAM,
  ALL_SUBJECT_PATHS,
  ADD_AI_SOLUTIONS_SUCCESS,
  ADD_AI_SOLUTIONS_FAIL,
  GET_QUESTION_SECTION_BY_EXAMID,
  GET_QUESTION_SECTION_BY_EXAMID_SUCCESS,
  GET_QUESTION_SECTION_BY_EXAMID_FAIL,
  ADD_NEWSECTION_SUCCESS,
  ADD_NEWSECTION_FAIL,
  UPDATE_QUESTIONSECTION_SUCCESS,
  UPDATE_QUESTIONSECTION_FAIL,
  TRADE_SUBJECTS_SUCCESS,
  TRADE_SUBJECTS_FAIL,
  ACADEMIC_SUBJECTS_SUCCESS,
  ACADEMIC_SUBJECTS_FAIL,
  GET_TOPIC_BY_SUBJECTID,
  GET_TOPIC_BY_SUBJECTID_FAILED,
  ADD_TOPIC_LOADING,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILED
} from "./types";

const initialState = {
  loading: false,
  examData: null,
  error: null,
  subjectData: null,
  tradeSubjectData: null,
  academicSubjectData: null,
  addSubject: {},
  examTypeData: [],
  examSeriesData: [],
  singleExamData: {},
  loadingExam: false,
  singleSubject: {},
  subjectPaths: [],
  questionSection: [],
  addSection: {},
  updateSection: {},
  topics: []
};

const contentUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_EXAM_YEARS_START:
      return { ...state, loading: true };

    case ALL_EXAM_YEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        examYearData: action.payload.data,
        error: null,
      };

    case ALL_EXAM_YEARS_FAIL:
      return {
        ...state,
        loading: false,
        examYearData: null,
        error: action.payload,
      };

    case ADD_EXAM_START:
      return { ...state, loading: true };
    case ADD_EXAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_EXAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_EXAM_QUESTION_START:
      return { ...state, loading: true };
    case ADD_EXAM_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_EXAM_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_AI_SOLUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_AI_SOLUTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_EXAM_BY_SUBJECT_ID_START:
      return { ...state, loading: true };
    case GET_EXAM_BY_SUBJECT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        examData: action.payload.data,
        error: null,
      };
    case ADD_EXAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_SUBJECT_START:
      return { ...state, loading: true };
    case ADD_SUBJECT_SUCCESS:
      return { ...state, loading: false, addSubject: action.payLoad.data };
    case ADD_SUBJECT_FAIL:
      return { ...state, loading: false, addSubject: {} };
    case ALL_SUBJECTS:
      return { ...state, loading: true };
    case ALL_SUBJECTS_START:
      return { ...state, loading: true };
    case ALL_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjectData: action.payload.data,
        error: null,
      };

    case ALL_SUBJECTS_FAIL:
      return {
        ...state,
        loading: false,
        subjectData: null,
        error: action.payload,
      };
    case TRADE_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tradeSubjectData: action.payload.data,
        error: null,
      };

    case TRADE_SUBJECTS_FAIL:
      return {
        ...state,
        loading: false,
        tradeSubjectData: null,
        error: action.payload,
      };
    case ACADEMIC_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        academicSubjectData: action.payload.data,
        error: null,
      };

    case ACADEMIC_SUBJECTS_FAIL:
      return {
        ...state,
        loading: false,
        academicSubjectData: null,
        error: action.payload,
      };

    case GET_SUBJECT_BY_ID_START:
      return { ...state, loading: true };
    case GET_SUBJECT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSubject: action.payload.data,
        error: null,
      };
    case GET_SUBJECT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        singleSubject: null,
        error: action.payload,
      };
    case UPDATE_SUBJECT_START:
      return { ...state, loading: true };
    case UPDATE_SUBJECT_SUCCESS:
      return { ...state, loading: false, subjectData: action.payLoad };
    case UPDATE_SUBJECT_FAIL:
      return { ...state, loading: false, error: action.error };

    case ALL_SUBJECT_EXAMS_START:
      return { ...state, loading: true };
    case ALL_SUBJECT_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjectData: action.payload.data,
        error: null,
      };
    case ALL_SUBJECT_EXAMS_FAIL:
      return {
        ...state,
        loading: false,
        subjectData: null,
        error: action.payload,
      };

    case GET_EXAM_QUESTIONS_START:
      return { ...state, loading: true };
    case GET_EXAM_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        examQuestionData: action.payload.data,
        error: null,
      };

    case GET_EXAM_QUESTIONS_FAIL:
      return {
        ...state,
        loading: false,
        examQuestionData: null,
        error: action.payload,
      };

    case GET_ALL_EXAM_TYPE:
      return {
        ...state,
        examTypeData: action.payload.data,
      };
    case GET_ALL_EXAM_SERIES:
      return {
        ...state,
        examSeriesData: action.payload.data,
      };

    case GET_EXAM_BY_EXAMID:
      return {
        ...state,
        singleExamData: action.payLoad.data,
      };

    case LOAD_EXAM:
      return {
        ...state,
        loadingExam: action.payLoad,
      };

    case ALL_SUBJECT_PATHS:
      return {
        ...state,
        subjectPaths: action.payLoad.data,
      };
    case GET_QUESTION_SECTION_BY_EXAMID:
      return {
        ...state,
        questionSection: action.payload.data,
      };
    case GET_QUESTION_SECTION_BY_EXAMID_SUCCESS:
      return {
        ...state,
        loading: false,
        questionSection: action.payload.data,
        error: null,
      };
    case GET_QUESTION_SECTION_BY_EXAMID_FAIL:
      return {
        ...state,
        loading: false,
        questionSection: [],
        error: action.payload,
      };

    case ADD_NEWSECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        addSection: action.payload.data,
        error: action.payload,
      };
    case ADD_NEWSECTION_FAIL:
      return {
        ...state,
        loading: false,
        addSection: null,
        error: action.payload,
      };

    case UPDATE_QUESTIONSECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSection: action.payload.data,
        error: action.payload,
      };

    case UPDATE_QUESTIONSECTION_FAIL:
      return {
        ...state,
        loading: false,
        updateSection: null,
        error: action.payload,
      };
    case GET_TOPIC_BY_SUBJECTID:
      return {
        ...state,
        loading: false,
        topics: action.payload.data,
        error: null,
      };

    case GET_TOPIC_BY_SUBJECTID_FAILED:
      return {
        ...state,
        loading: false,
        topics: [],
        error: action.payload,
      };
    
    case ADD_TOPIC_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADD_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    
    case ADD_TOPIC_FAILED:
      return {
        ...state,
        loading: false,
      };
    
    default:
      return state;
  }
};
export default contentUploadReducer;
