import { Box, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Logo, Sidmach } from "../../assets/images";
import Button from "../buttons/Button";
import {
  BookIcon,
  DashboardIcon,
  LeaderBoardIcon,
} from "../../assets/icons/Icons";
import PremiumBox from "../Premium/PremiumBox";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import useUser from "../../hooks/useUser";
import WeeklyTriviaBox from "../Premium/WeeklyTriviaBox";
import useSubscription from "../../hooks/useSubscription";
import SponsorBox from "../Premium/SponsorBox";
// import sidmach from "../../assets/images/sidmachlogo.svg";
import { CustomImage } from "../landingPage/CTAJumbotron";
import SuperSchoolBox from "../Box/SuperSchoolBox";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

const Sidebar = ({ open, setOpen, openTrialModal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, setOpened] = useState(null);

  const [activeLink, setActiveLink] = useState(1);
  const [subActiveLink, setSubActiveLink] = useState(0);
  const { userInfo } = useUser();
  const { checkSub, isTrial } = useSubscription();

  const links = [
    {
      label: "Dashboard",
      path: "/dashboard/home",
      icon: <DashboardIcon color={activeLink == 0 ? "white" : "#40499D"} />,
      subItems: [],
    },
    {
      label: "My Learning",
      path: "/dashboard/learning/study",
      icon: <BookIcon color={activeLink == 1 ? "white" : "#40499D"} />,
      subItems: [
        { name: "Study", path: "/dashboard/learning/study" },
        { name: "Mock Examination", path: "/dashboard/learning/subjects" },
      ],
    },
    {
      label: "Leaderboard",
      path: "leaderboard",
      icon: (
        <LeaderBoardIcon
          size={24}
          color={activeLink == 0 ? "white" : "#40499D"}
        />
      ),
      subItems: [],
    },
    {
      label: "Achievements",
      path: "/dashboard/achievements",
      icon: <EmojiEventsIcon color={activeLink == 2 ? "white" : "#40499D"} />,
      subItems: [],
    },
  ];

  const handleListDisplay = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    if (location.pathname == "/dashboard/home") {
      setActiveLink(0);
    }
    if (location.pathname.startsWith("/dashboard/learning")) {
      setActiveLink(1);
    }
  }, [location.pathname]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      className="shadow-xl"
    >
      <div className="flex gap-y-20 flex-col min-h-screen justify-between overflow-x-hidden overflow-y-auto px-6">
        <div>
          <DrawerHeader>
            <img src={Logo} alt="Logo" style={{ maxWidth: "90%" }} />
          </DrawerHeader>

          <ul style={{ zIndex: 9999 }}>
            {links.map((link, index) => (
              <li
                key={index}
                onClick={() => {
                  setActiveLink(index);
                }}
                className="flex flex-col justify-center mt-3"
              >
                <Button.Blue
                  className={`cursor-pointer e-study-primary ${
                    index === activeLink
                      ? "e-study-normal-text-three e-study-button-blue"
                      : "text-black"
                  }`}
                  onClick={() => {
                    // navigate(
                    //   checkSub?.msg !== "InActive" ? link.path : "/dashboard/home"
                    // );
                    // if (checkSub?.msg === "InActive" && index != 0) {
                    //   openTrialModal(true);
                    // }
                    navigate(link.path);
                    link.subItems.length < 1 || opened == index
                      ? (setOpened(false), setSubActiveLink(0))
                      : handleListDisplay();
                  }}
                  title={
                    <div className="flex items-center space-x-3">
                      {link.icon}
                      <span>{link.label}</span>
                      <span>
                        {link.subItems.length > 0 &&
                        index == activeLink &&
                        opened ? (
                          <ExpandMore />
                        ) : (link.subItems.length > 0 && index != activeLink) ||
                          (link.subItems.length > 0 &&
                            index == activeLink &&
                            !opened) ? (
                          <ExpandLess />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  }
                />

                {link.subItems.length > 0 && index == activeLink && opened && (
                  <ul className="flex flex-col self-center justify-center pl-4 mt-3 space-y-3 list-disc">
                    {link.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`cursor-pointer ${
                          subIndex == subActiveLink
                            ? "e-study-primary"
                            : "e-study-primary-blur"
                        }`}
                        // onClick={() => {
                        //   setSubActiveLink(subIndex);
                        //   if (checkSub?.msg === "InActive") {
                        //     openTrialModal(true);
                        //   }
                        // }}
                      >
                        <NavLink
                          // to={
                          //   checkSub?.msg !== "InActive"
                          //     ? subItem.path
                          //     : "/dashboard/home"
                          // }
                          // to={subItem.name === "Mock Examination" && isTrial ? "/dashboard/settings?tab=3" : subItem.path}
                          to={subItem.path}
                        >
                          {subItem.name}{" "}
                          {/* {subItem.name === "Mock Examination" && isTrial && <span className="p-[1px] text-red-700 bg-red-300 rounded">Trial</span>} */}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div
          className={`flex flex-col mb-6 ${
            userInfo?.isSuperSchoolAdmin ? "gap-10" : "gap-6"
          }`}
        >
          {checkSub?.msg === "InActive" ? (
            <div className="mt-auto">
              <PremiumBox />
            </div>
          ) : (
            ""
          )}
          {userInfo?.studentSponsorName !== null ? (
            <div className="mt-auto">
              <SponsorBox />
            </div>
          ) : (
            ""
          )}
          {userInfo?.isSuperSchoolAdmin && (
            <SuperSchoolBox
              title="Super School Plan"
              text={"All subjects are available for study"}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
