import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import UserMetricsStat from "./userMetricsStat";
import UserMetricsTable from "./userMetricsTable";
import { useDispatch } from "react-redux";
import useUserMetrics from "../../../hooks/useUserMetrics";
import { fetchUserMetrics } from "../../../redux/userMetrics";
import { useNotifications } from "../../../components/notifications/notifications";
import Filter from "../../../components/TransactionLog/Filter";
import {
  getAllTransactions,
  getTransactionById,
} from "../../../redux/payment/paymentActions";

const UserMetrics = () => {
  const [ToastState, setToastState] = useNotifications();
  const [filters, setFilters] = useState({
    DateReg: "",
    UserTypeId: "",
    UserSubId: "",
    TakenTest: false,
    NotTakenTest: false,
    OpenedStudy: false,
    NotOpenedStudy: false,
  });
  const dispatch = useDispatch();
  const userMetricss = useUserMetrics();
  const { userMetricsData, subscription } = userMetricss;

  const onSuccess = (res) => {
    // setToastState({
    //   isOpen: true,
    //   severity: "success",
    //   message: res.msg,
    // });
  };

  const onFail = (res) => {
    // setToastState({
    //   isOpen: true,
    //   severity: "error",
    //   message: res.msg,
    // });
  };

  const on500Fail = (error) => {
    // setToastState({
    //   isOpen: true,
    //   severity: "error",
    //   message: error.statusText,
    // });
  };

  const handleReset = () => {
    setFilters({
      ...filters,
      DateReg: "",
      UserTypeId: "",
      UserSubId: "",
      TakenTest: false,
      NotTakenTest: false,
      OpenedStudy: false,
      NotOpenedStudy: false,
    });
    dispatch(
      fetchUserMetrics({ filters: filters }, onSuccess, onFail, on500Fail)
    );
  };

  useEffect(() => {
    dispatch(
      fetchUserMetrics({ filters: filters }, onSuccess, onFail, on500Fail)
    );
  }, [filters]);

  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClick = (event, id, queryData) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedId(id);
    setShowQuery(queryData);
    dispatch(getTransactionById(id));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  // const payment = usePayment();

  const options = [
    // {
    //   type: "dropdown",
    //   name: "demographic",
    //   group: "Demographic",
    //   label: "Demographic",
    //   options: [
    //     { label: "Age", value: "age" },
    //     { label: "Under 18", value: "under 18" },
    //     { label: "18 and Above", value: "18 and above" },
    //   ],
    // },
    // {
    //   type: "dropdown",
    //   name: "gender",
    //   group: "Gender",
    //   label: "Gender",
    //   options: [
    //     { label: "Male", value: "male" },
    //     { label: "Female", value: "female" },
    //   ],
    // },
    {
      type: "dropdown",
      name: "usertype",
      group: "User Type",
      label: "User Type",
      options: [
        { label: "Student", value: "026f1450-f8de-4887-87ad-acd8edabe8fd" },
        { label: "School", value: "ec518f80-d514-47d6-acbd-fe1e9f3f1ec1" },
        { label: "Sponsor", value: "36e0ad06-523e-43f5-a7a0-5eff83d19c3e" },
      ],
    },
    // {
    //   type: "dropdown",
    //   name: "paymentStatus",
    //   group: "Payment Status",
    //   label: "Payment Status",
    //   options: [
    //     { label: "Approved", value: "approved" },
    //     { label: "Initiated", value: "initiated" },
    //     { label: "Declined", value: "declined" },
    //   ],
    // },
    {
      type: "dropdown",
      name: "location",
      group: "Location",
      label: "Location",
      options: [
        { label: "Nigeria", value: 13 },
        { label: "Others", value: "others" },
      ],
    },
    // {
    //   type: "dropdown",
    //   name: "User plan",
    //   group: "User plan",
    //   label: "User plan",
    //   options: [
    //     { label: "Monthly", value: "monthly" },
    //     { label: "Quaterly", value: "quarterly" },
    //     { label: "Yearly", value: "yearly" },
    //   ],
    // },
    {
      type: "dropdown",
      name: "engagement",
      group: "Engagement",
      label: "Engagement",
      options: [
        { label: "Taken test", value: "Taken test" },
        { label: "Not taken test", value: "Not taken test" },
        { label: "Open study", value: "Open study" },
        { label: "Not Open study", value: "Not Open study" },
      ],
    },
    {
      type: "startDate",
      name: "startDate",
      group: "Start Date",
      label: "Start Date",
    },
    {
      type: "endDate",
      name: "endDate",
      group: "End Date",
      label: "End Date",
    },

    // {
    //   type: "dropdown",
    //   name: "Date",
    //   group: "Date",
    //   label: "Date",
    //   options: [
    //     { label: "All", value: "All" },
    //     { label: "Last 7 days", value: "Last 7 days" },
    //     { label: "This Month", value: "This Month" },
    //     { label: "This year", value: "This year" },
    //     { label: "Custom Date", value: "Custom Date" },
    //   ],
    // },
  ];

  const handleFilterClick = (event) => {
    if (event !== 0) {
      // setLoading(true);
      setFilters(event);
      setFilterOptions(event);
      // dispatch(getAllTransactions(event));
    }
  };

  const handleResets = (event) => {
    handleReset();
    const isExactCurrency =
      Object.keys(filterOptions).length === 1 && filterOptions.currency === 566;
    if (!isExactCurrency) {
      setFilterOptions({});
      setFilters({ currency: 566 });
      // dispatch(getAllTransactions({ currency: 566 }));
    }
  };

  return (
    <>
      <Box>
        <Grid container spacing={1} justify="space-between">
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={"bold"}>
              User Metrics
            </Typography>
            <Typography
              sx={{
                color: "#4D4D4D",
                fontFamily: "Open Sans",
                fontSize: ".9em",
                fontWeight: 400,
                lineHeight: "140%",
              }}
            >
              Welcome to the admin dashboard - Your overview awaits!
            </Typography>
            <Grid>
              <UserMetricsStat userMetricsData={userMetricsData} />
            </Grid>

            <Grid>
              <Filter
                options={options}
                filters={filters}
                onClick={handleFilterClick}
                reset={handleResets}
              />
            </Grid>

            <Grid>
              <UserMetricsTable
                userMetricsData={userMetricsData}
                subscriptionType={subscription}
                filters={filters}
                // setFilters={setFilters}
                // handleReset={handleReset}
                setToastState={setToastState}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ToastState />
    </>
  );
};

export default UserMetrics;
