import {
  ALL_SUBJECTS,
  ALL_SUBJECTS_FAIL,
  ALL_SUBJECTS_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_COUNT,
  GET_QUESTION_FAIL,
  LOAD_QUESTION,
  EXAM_TYPE,
  GET_PRACTICE_QUESTION_COUNT,
  GET_PRACTICE_QUESTION_COUNT_FAIL,
  GET_PRACTICE_QUESTION,
  GET_PRACTICE_QUESTION_FAIL,
  LOAD_PRACTICE_QUESTION,
  ALL_EXAM,
  ALL_EXAM_FAIL,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ACTIVE_SUBSCRIPTION_FAIL,
  SEND_POINTS,
  SUBMIT_STUDY_TIME,
  GET_QUESTION_SOLUTION_LOADING,
  GET_QUESTION_SOLUTION_SUCCESS,
  GET_QUESTION_SOLUTION_FAILURE,
  SUBMIT_LAST_VIEWED_QUESTION_NUMBER,
  GET_LAST_VIEWED_QUESTION_NUMBER,
  GET_LAST_VIEWED_QUESTION_NUMBER_FAIL,
  ALL_EXAM_LOADING,
} from "./types";

const initialState = {
  loading: false,
  subjectData: null,
  examTypeData: null,
  examCount: null,
  practiceQuestions: null,
  practiceQuestionCount: null,
  points: 0,
  studyTime: 0,
  allExam: null,
  subUser: null,
  examQuestion: null,
  error: null,
  loadingQuestion: false,
  loadPracticeQuestion: false,
  loadQuestionSolution: false,
  questionSolution: null,
  questionsLastViewed: null,
  questionsViewed: null,
};

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_SUBJECTS:
      return { ...state, loading: true };
    case ALL_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjectData: action.payload.data,
        error: null,
      };
    case ALL_SUBJECTS_FAIL:
      return {
        ...state,
        loading: false,
        subjectData: null,
        error: action.payload,
      };
    case ALL_EXAM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ALL_EXAM:
      return {
        ...state,
        loading: false,
        allExam: action.payload.data,
      };
    case EXAM_TYPE:
      return {
        ...state,
        loading: false,
        examTypeData: action.payload.data,
        error: action.payload,
      };

    case GET_QUESTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_QUESTION_COUNT:
      return {
        ...state,
        examCount: action.payload.data,
      };
    case GET_QUESTION:
      return {
        ...state,
        loading: false,
        examQuestion: action.payload.data,
      };
    case LOAD_QUESTION:
      return {
        ...state,
        loadingQuestion: action.payload,
      };
    case GET_PRACTICE_QUESTION_COUNT:
      return {
        ...state,
        practiceQuestionCount: action.payload,
      };
    case GET_PRACTICE_QUESTION:
      return {
        ...state,
        practiceQuestions: action.payload,
      };
    case GET_PRACTICE_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOAD_PRACTICE_QUESTION:
      return {
        ...state,
        loadPracticeQuestion: action.payload,
      };
    case GET_ACTIVE_SUBSCRIPTION: //reducer for acive subscription
      return {
        ...state,
        subUser: action.payload,
      };
    case SEND_POINTS: //reducer for sending points
      return {
        ...state,
        points: action.payload,
      };
    case SUBMIT_STUDY_TIME: //reducer for submit study time
      return {
        ...state,
        studyTime: action.payload,
      };
    case GET_QUESTION_SOLUTION_LOADING:
      return {
        ...state,
        loadQuestionSolution: action.payload,
      };
    case GET_QUESTION_SOLUTION_SUCCESS:
      return {
        ...state,
        questionSolution: action.payload,
      };
    case GET_QUESTION_SOLUTION_FAILURE:
      return {
        ...state,
        questionSolution: null,
      };
    case SUBMIT_LAST_VIEWED_QUESTION_NUMBER:
      return {
        ...state,
        questionsLastViewed: action.payload,
      };
    case GET_LAST_VIEWED_QUESTION_NUMBER:
      return {
        ...state,
        questionsViewed: action.payload.data,
      };
    case GET_LAST_VIEWED_QUESTION_NUMBER_FAIL:
      return {
        ...state,
        questionsViewed: null,
      };

    default:
      return state;
  }
};
export default subjectReducer;
