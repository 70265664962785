import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider, IconButton, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import SearchBox from "./SearchBox";
import emptyBook from "../../assets/images/emptyBook.gif";
import emptyBook2 from "../../assets/images/emptyBook2.gif";
import SubjectPanel from "./SubjectPanel";
import useTest from "../../hooks/useTest";
import useUser from "../../hooks/useUser";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& button": {
    textTransform: "capitalize",
    whiteSpace: "nowrap",
  },

  "& button:active": {
    background: "#40499D",
    color: "#FFFFFF",
    borderRadius: "10px",
  },
  "& button:hover": {
    background: "#E9EBFF",
    borderRadius: "10px",
    color: "#40499D",
  },
  "& button.Mui-selected": {
    background: "#E9EBFF",
    borderRadius: "5px",
    borderBottom: "none",
  },
  "&.MuiTabs-root": {
    width: 287,
    flexShrink: 0,
    padding: "10px",
    border: "none",
    borderRadius: "15px",
  },
  "  .MuiTabs-indicator": {
    display: "none",
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  alignItems: "baseline",
  position: "relative",
  color: 'black',

  '&[aria-selected="true"]::before': {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: "#E9EBFF",
    zIndex: -1, // Adjusted zIndex to place it behind the content
  },

  "& > .MuiTab-wrapper": {
    // background: "#fff",
    // height: "100%",
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DesktopView = ({
  showSubjectPicker,
  open,
  handleChange,
  value,
  handleClose,
  handleCloseModal,
  subjects,
  examData,
  handleClickOpen,
  openModal,
  highestExamLoaded,
  highestExamLoading,
  highestExam,
}) => {
  const theme = useTheme();
  const test = useTest();
  const { userInfo } = useUser();
  const { isSuperSchoolAdmin } = userInfo;

  const { subjectExams, subjectExamsLoaded } = test;
  const [searchValue, setSearchValue] = React.useState("");
  const [isSearched, setIsSearched] = React.useState(false);
  const [searchData, setSearchData] = React.useState(subjects);

  React.useEffect(() => {
    setSearchData(subjects);
  }, [subjects]);
  const handleSearch = (value) => {
    setSearchValue(value);

    if (value === "") {
      setSearchData(subjects);
    } else {
      const filteredItems = subjects.filter((item) =>
        isSuperSchoolAdmin ? item?.subjectName?.toLowerCase()?.includes(value.toLowerCase()) :
          item?.SubjectName?.toLowerCase()?.includes(value.toLowerCase())
      );
      setSearchData(filteredItems);
    }
  };

  //   React.useEffect(() => {}, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Box display={"flex"} alignItems={"center"} gap={2} mb={3}>
        {/* <Link to="/dashboard/learning/test">
          <IconButton>
            <KeyboardBackspaceIcon sx={{ fontSize: "35px" }} />
          </IconButton>
        </Link> */}
        <Typography variant="h5">Mock Examination</Typography>
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          display: "flex",
          //   gap: 2,
          height: "fit-content",
          width: "100%",
          borderRadius: "15px 0px 0px 0px",
          border: "1px solid #e7e7e7",
        }}
      >
        <Box
          sx={{
            pt: 2,
            // border: (theme) => `1px solid ${theme.palette.primary.main}`,
            borderRadius: "15px 0px 0px 0px",
            height: "100vh",
            borderRight: "1px solid #e7e7e7",
          }}
        >
          <Box pl={3} pb={1}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#313131", fontWeight: 600 }}
            >
              My Subjects
            </Typography>
          </Box>
          <Divider />
          {/*  <Box pr={2} pl={2} mt={2} pt={1} pb={1}>
            <SearchBox placeholder="subjects..." hoverwidth={"150px"} onChange={(e) => handleSearch(e.target.value)} value={searchValue} />
          </Box> */}
          <Box

          >
            <CustomTabs
              orientation="vertical"
              variant="scrollable"
              value={0}
              // onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                width: "100%",
                display: "none",
              }}
            >
              <CustomTab label={""} {...a11yProps(0)} />
            </CustomTabs>
          </Box>

          <CustomTabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              width: "100%",
              maxHeight: '800px',
              overflowY: 'auto'
            }}
          >
            {searchData?.map((item) =>
              <CustomTab
                key={
                  isSuperSchoolAdmin ? item?.subjectId :
                    item?.SubjectId
                }
                label={
                  isSuperSchoolAdmin ? item?.subjectName :
                    item?.SubjectName
                }
                className=""
                {...a11yProps(
                  isSuperSchoolAdmin ? item?.subjectId :
                    item?.SubjectId
                )}
              />
            )
            }
          </CustomTabs>
        </Box>
        {showSubjectPicker === false
          ? searchData?.map((item, i) => (
            <SubjectPanel
              key={
                isSuperSchoolAdmin ? item?.subjectId :
                  item?.SubjectId
              }
              value={value}
              i={i}
              item={item}
              examData={examData}
              subjects={subjects}
              handleClickOpen={handleClickOpen}
              open={openModal}
              handleClose={handleClose}
              handleCloseModal={handleCloseModal}
              highestExamLoaded={highestExamLoaded}
              highestExamLoading={highestExamLoading}
              highestExam={highestExam}
            />
          ))
          : ""}
        {showSubjectPicker === true && (
          <Box
            sx={{
              borderRadius: "0px 20px 0px 0px",
              width: "100%",
              background: "#fff",
            }}
            p={3}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <img
              src={emptyBook2}
              alt="Pick a Subject"
              style={{ width: "auto", height: "250px" }}
              draggable={false}
            />
            <Typography variant="h6" sx={{ fontSize: "20px" }}>
              Pick a subject to see all examination
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DesktopView;
