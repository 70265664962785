import { Box, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ADMIN_ICON, Logo, Sidmach } from "../../assets/images";
import Button from "../buttons/Button";
import {
  AdminDashboardIcon,
  AuditTailIcon,
  BookIcon,
  ContentUploadIcon,
  DashboardIcon,
  PermissionIcon,
  TransactionIcon,
  UserMetricsIcon,
  NairaIcon,
} from "../../assets/icons/Icons";
import useAdminUser from "../../hooks/useAdminUser";
import { useDispatch } from "react-redux";
import usePermissions from "../../hooks/usePermissions";
import { getAllSitePagesByRoleId } from "../../redux/permissions/permissionsActions";
import IconRenderer from "../Dropdowns/IconRenderer";
import { CustomImage } from "../landingPage/CTAJumbotron";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  marginTop: "5px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // justifyContent: 'center',
}));

const AdminSidebar = ({ open, setOpen }) => {
  const adminUser = useAdminUser();
  const dispatch = useDispatch();
  const userInfo = adminUser?.userInfo?.data;
  const { roleSitePages } = usePermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, setOpened] = useState(null);

  const [activeLink, setActiveLink] = useState(0);
  const [subActiveLink, setSubActiveLink] = useState(0);

  useEffect(() => {
    if (
      userInfo?.roleId != "00000000-0000-0000-0000-000000000000" ||
      !userInfo?.roleId
    ) {
      dispatch(getAllSitePagesByRoleId(userInfo?.roleId));
    }
  }, []);

  useEffect(() => {
    if (roleSitePages?.length > 0) {
      let activeSitePages = roleSitePages?.filter(
        (pages) => pages.Status === true
      );
      for (let i = 0; i < activeSitePages.length; i++) {
        if (
          location.pathname ==
          `/admin/${activeSitePages[i].PageName?.toLowerCase().replace(
            /\s/g,
            ""
          )}`
        ) {
          setActiveLink(i);
        }
      }
      if (
        !isPathInPages(location.pathname, activeSitePages) &&
        location.pathname != "/admin/settings"
      ) {
        navigate("/admin/dashboard");
      }
    }
    if (location.pathname == "/admin/settings") {
      setActiveLink(null);
    }
  }, [location.pathname]);

  const isPathInPages = (pathname, pages) => {
    return pages.some((page) =>
      pathname.includes(
        `/admin/${page.PageName?.toLowerCase().replace(/\s/g, "")}`
      )
    );
  };

  const handleListDisplay = () => {
    setOpened(!opened);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      className="shadow-xl"
    >
      <div className="flex flex-col justify-between min-h-screen px-6">
        <div>
          <DrawerHeader>
            <img src={Logo} alt="Logo" />
            {/* <div className="flex flex-col space-y-2 ml-3 justify-center items-center">
            <h3 className="e-study-heading-three">{userInfo?.firstname}</h3>
            <h5 className="e-study-heading-three">{userInfo?.surname}</h5>
          </div> */}
          </DrawerHeader>

          <ul>
            {roleSitePages
              ?.filter((page) => page.Status === true)
              .map((link, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setActiveLink(index);
                  }}
                  className="flex justify-start items-start w-full flex-col mt-3"
                >
                  <Button.Blue
                    className={`cursor-pointer e-study-primary ${
                      index === activeLink
                        ? " w-full e-study-normal-text-three e-study-button-blue"
                        : "text-black"
                    }`}
                    onClick={() => {
                      navigate(
                        `/admin/${link.PageName?.toLowerCase().replace(
                          /\s/g,
                          ""
                        )}`
                      );
                      link?.subItems &&
                        (link?.subItems?.length < 1 || opened == index
                          ? (setOpened(false), setSubActiveLink(0))
                          : handleListDisplay());
                    }}
                    title={
                      <div
                        className={`flex space-x-3 justify-start items-center  ${
                          index === activeLink ? "text-white" : "e-study-black"
                        }`}
                      >
                        <IconRenderer
                          iconName={link.PageFolderIcon}
                          color={index === activeLink}
                        />
                        <span>{link.PageName}</span>
                        <span>
                          {link?.subItems?.length > 0 &&
                          index == activeLink &&
                          opened ? (
                            <ExpandMore />
                          ) : (link?.subItems?.length > 0 &&
                              index != activeLink) ||
                            (link?.subItems?.length > 0 &&
                              index == activeLink &&
                              !opened) ? (
                            <ExpandLess />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    }
                  />

                  {link?.subItems?.length > 0 &&
                    index == activeLink &&
                    opened && (
                      <ul className="pl-4 mt-3 list-disc  flex flex-col justify-center self-center space-y-3">
                        {link.subItems.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className={`cursor-pointer ${
                              subIndex == subActiveLink
                                ? "e-study-primary"
                                : "e-study-primary-blur"
                            }`}
                            onClick={() => setSubActiveLink(subIndex)}
                          >
                            <NavLink to={subItem.path}>{subItem.name}</NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
          </ul>
        </div>

        <div>
          <Box display={"flex"} gap={0.5} alignItems={"center"}>
            <Typography
              textAlign={{ xs: "center", md: "start" }}
              fontSize={{ xs: 13, md: 16 }}
              color={"black"}
              variant="p"
            >
              Powered By
            </Typography>
            <CustomImage
              src={Sidmach}
              sx={{ display: "inline", height: "4rem" }}
            />
          </Box>
        </div>
      </div>
    </Drawer>
  );
};

export default AdminSidebar;
