import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import InfoCard from "../../../components/dashboards/admin/InfoCard";
import { DashBoardData } from "../dashboardData";
import { styled } from "@mui/material/styles";
import UserMetricsInfoCard from "./userMetricsInfoCard";
import { UserMetricsData } from "./userMetricsData";
import SearchBox from "../../../components/Test/SearchBox";
import FilterPopover from "./filterModal";
import UserMetricsDataTable from "./userMetricDataTable";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserMetricsTable = ({
  userMetricsData,
  filters,
  setFilters,
  subscriptionType,
  handleReset,
  setToastState,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);

    // if (value === "") {
    //   setSearchData(subjectExams);
    // } else {
    //   const filteredItems = subjectExams.filter((item) =>
    //     item?.examName?.toLowerCase()?.includes(value.toLowerCase())
    //   );
    //   setSearchData(filteredItems);
    // }
  };

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: 1 }}
      >
        {/* <Grid item xs={5}>
          <Item>
            <SearchBox
              placeholder={"Search users..."}
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
              width="100%"
              hoverwidth={"35em"}
            />
          </Item>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}
        >
          <Item>
          <FilterPopover
            filters={filters}
            setFilters={setFilters}
            subscriptionType={subscriptionType}
            handleReset={handleReset}
          />
          </Item>
        </Grid> */}
        <Grid item xs={12}>
          <UserMetricsDataTable
            userMetricsData={userMetricsData?.profiles}
            filters={filters}
            setToastState={setToastState}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserMetricsTable;
