import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";
import WeeklyMissionBg from "../../../assets/images/WeeklyMissionBg.svg";
import { ReactComponent as CancelIcon } from "../../../assets/images/cancel.svg";
import TitleBar from "./TitleBar";
import WeeklyTasks from "./WeeklyTasks";
import PointsAndLevelSection from "./PointsAndLevelSection";
import MissionSound from "./MissionSound";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    overflowX: "none",
    boxSizing: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-root':{
    backgroundColor:'#40499d'
  }
}));

export default function WeeklyMissionDialog({ open, handleClose, data, timeRemaining }) {
  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundImage: `url(${WeeklyMissionBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            overflowX: "none",
            padding: "0px 10px",
            backgroundColor:'#40499d'
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "-10px",
            right: "0",
            zIndex: 1330,
            width:'50px',
            height:'50px'
          }}
        >
          <CancelIcon />
        </IconButton>
        <DialogTitle
          sx={{ m: 0, pr: 2, pl: 2, pt: 0 }}
          id="customized-dialog-title"
        >
          <TitleBar timeRemaining={timeRemaining}/>
        </DialogTitle>
        <DialogContent>
          <MissionSound/>
          <Box
            sx={{
              color: "#fff",
              borderRadius: "8px",
              background: "#2B3596",
              boxSizing: "border-box",
              p: 2,
            }}
          >
            <PointsAndLevelSection />
            {Array.isArray(data) &&
              data?.map((item) => <WeeklyTasks key={item?.task} item={item} />)}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
