import { useEffect, useState } from "react";
import Axios from "../connection/defaultClient";

export const usePricingModels = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    const fetchModels = async () => {
      await Axios.get("/Subscription/GetAllSubscriptionPackages")
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchModels();
  }, []);

  return { data, isLoading, error };
};
