import React from 'react';
import { Box, OutlinedInput,
  InputAdornment, IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { isArray, isEmpty } from '../../../helpers/helper';
import { TableFooter } from '../../../components/tables/TableFooter';
import { MoreVert } from '@mui/icons-material';
import ProfileIcon from '../../../components/ProfileIcon';


const PermissionTable = (props) => {
  const {tableData, setRoleId, setSelectedRowAdmin, handleClickMenu2, setCurrentPage, currentPage, search, setSearch, loading} = props
  return (
    <>
      <Box>
        <OutlinedInput
          id="outlined-adornment-password"
          //type={showPassword ? 'text' : 'password'}
          startAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="filter"
                //onClick={handleClickShowPassword}
                //onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          value={search}
          onChange={(e)=>setSearch(e.target.value)}
          placeholder='Search users by name or role'
          size="xs"
          fullWidth
        />

      </Box>
      <Box sx={{ width: '100%', mt: 3 }}>

        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-sm text-left p-3">
            <thead className="e-study-subtitle-one e-study-text-grey uppercase bg-white">
              <tr className='border-b py-3'>

                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  User ID
                </th> */}
                <th scope="col" className="px-6 py-3">
                  Role
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ?(
              <tr>
                <td colSpan="2" className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="animate-pulse h-4 w-4 bg-gray-300 rounded mr-2"></div>
                    <div className="animate-pulse h-4 w-32 bg-gray-300 rounded"></div>
                  </div>
                </td>
                <td colSpan="2" className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="animate-pulse h-4 w-4 bg-gray-300 rounded mr-2"></div>
                    <div className="animate-pulse h-4 w-32 bg-gray-300 rounded"></div>
                  </div>
                </td>            
              </tr>
            ) :
               ( isArray(tableData?.data) && tableData?.data?.map((adminUser, i) => (
                <tr className="bg-white border-b">

                  <td scope="row" className="flex items-center px-6 py-4 whitespace-nowrap">
                    <div className='flex space-x-3 items-center'>
                      <div>
                        <ProfileIcon firstName={adminUser.Firstname} lastName={adminUser.Surname} />
                      </div>
                      <div>
                        <div className="text-base font-semibold" style={{
                          color: '#313131',
                          fontSize: '16px',
                          fontWeight: 600,
                          fontFamily: 'Open Sans',
                          lineHeight: '16px'
                        }}>
                          {adminUser.FullName}
                        </div>
                        <div className="font-normal">{adminUser.Email}</div>
                      </div>
                    </div>
                  </td>
                  {/* <td className="px-6 py-4">
                    {adminUser.ProfileId}
                  </td> */}
                  <td className="px-6 py-4">
                    {adminUser?.RoleName}
                  </td>
                  <td className="px-6 py-4">
                    <div className='flex'>
                      <div className="flex items-center rounded-lg p-3 space-x-3" style={{ backgroundColor: `${adminUser.IsActive ? '#ECFEF5' : '#FFF2EB'}` }}>
                        <div className={`h-2.5 w-2.5 rounded-full mr-2 ${adminUser.IsActive ? 'e-study-bg-active-green' : 'estudy-bg-inactive-red'}`} /> <span className={`${adminUser.IsActive ? 'e-study-active-green' : 'estudy-inactive-red'}`}>{adminUser.IsActive ? 'Active' : 'InActive'}</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <MoreVert className='cursor-pointer' onClick={(e) => { setRoleId(adminUser.RoleId); setSelectedRowAdmin(adminUser); handleClickMenu2(e) }} />
                  </td>
                </tr>
              )))}
            </tbody>
          </table>
          <div className="mt-3">
            <TableFooter
              empty={isEmpty(tableData.data)}
              loading={loading}
              pageNumber={currentPage}
              totalPages={tableData.totalPages}
              prevOnClick={() => setCurrentPage(tableData.previousPage)}
              nextOnClick={() => setCurrentPage(tableData.nextPage)}
              thereIsPreviousPage={tableData.thereIsPreviousPage}
              thereIsNextPage={tableData.thereIsNextPage}
            />
          </div>
        </div>
      </Box>
    </>
  )
}

export default PermissionTable