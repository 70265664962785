import React from 'react';
import "./Loader.css";


const Loader = () => {
    return (
        <div className="landing-page-cover">
            <div className="home-loader"></div>
        </div>
    )
}

export default Loader