import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const StatusGeneratorButton = styled(Button)({
  textAlign: "center",
  borderRadius: 10,
  minWidth: "70px",
  fontSize: "13px",
  textTransform: "capitalize",
  fontWeight: 600,
});

const statusTypesDict = {
  redFilled: {
    border: "none",
    backgroundColor: "rgba(255, 72, 66, 0.16)",
    color: "#B72136",
  },
  redOutlined: {
    border: "2px solid #FCE7E7",
    backgroundColor: "transparent",
    color: "#E79191",
  },
  yellowFilled: {
    border: "none",
    backgroundColor: "#FBF9C4",
    color: "#FBBC05",
  },
  yellowOutlined: {
    border: "2px solid #FBF9C4",
    backgroundColor: "transparent",
    color: "#FBBC05",
  },
  greenFilled: {
    border: "none",
    backgroundColor: "#E4F2EB",
    color: "#4AB58E",
  },
  greenOutlined: {
    border: "2px solid #D7FAD9",
    backgroundColor: "transparent",
    color: "#05A80B",
  },
  orangeFilled: {
    backgroundColor: "#FFE2AD",
    color: "#CC8500",
    border: "none",
  },
  orangeOutlined: {
    backgroundColor: "transparent",
    color: "#CC8500",
    border: "2px solid #FFB833",
  },
  greyFilled: {
    backgroundColor: "#EAEAEA",
    color: "#808080",
    border: "2px solid #FFB833",
  },
};

const StatusGenerator = ({ statusText, variant }) => {
  /*
    variant types: [redFilled, redOutlined, yellowFilled, yellowOutlined, greenFilled, greenOutlined, ]
  */

  return (
    <StatusGeneratorButton
      disableElevation
      disableRipple
      disableTouchRipple
      disableFocusRipple
      sx={{
        backgroundColor: statusTypesDict[variant].backgroundColor,
        color: statusTypesDict[variant].color,
        border: statusTypesDict[variant].border,
        "&:hover": {
          backgroundColor: statusTypesDict[variant].backgroundColor,
        },
      }}
    >
      {statusText}
    </StatusGeneratorButton>
  );
};

export default StatusGenerator;
