import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Select,
  FormControlLabel,
  Radio,
  Stack,
  MenuItem,
  Divider,
  NativeSelect,
  IconButton,

  Delete,
  CardContent,
} from "@mui/material";
import Button from "@mui/material/Button";

import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AntSwitch } from "../Settings/Notifications";
import QuestionModal from "./QuestionModal";
import { IconBase } from "react-icons/lib";
import useContentUpload from "../../hooks/useContentUpload";
import QuestionModalTable from "./QuestionModalTable";




const QuestionSection = ({ exam, questionSection, onChildData, selectedSectionId, input }) => {


  const [open, setOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  // const { questionSection } = useContentUpload();




  const handleOpenSection = () => {
    setOpen(true);


  };

  const handleEditOpen = () => {
    setIsEditMode(true)
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditMode(false);

  };



  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={handleOpenSection}
        >
          Add Section
        </Button>
      </Box>

      <Box>
        <Paper>
          <CardContent>

            <Stack
              direction="row"
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >

              <Typography
                sx={{
                  mt: 1,
                  color: "#000",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "22.4px",
                }}
              >
                Question section
              </Typography>
              {/* <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
              // onChange={item?.onChange}
              /> */}
            </Stack>

            <QuestionModalTable questionSection={questionSection} open={open} handleClose={handleClose} isEditMode={isEditMode} handleEditOpen={handleEditOpen} exam={exam} setIsEditMode={setIsEditMode} setOpen={setOpen} input={input} />

          </CardContent>
        </Paper>



      </Box>
    </>

  );
};

export default QuestionSection;
