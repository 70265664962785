import * as React from "react";
import { ChemistryIcon } from "../../../../assets/icons/Icons";
import { Container } from "@mui/material";
import useResponsive from "../../../../hooks/useMediaQuery";
import MobileView from "../../../../components/Test/MobileView";
import DesktopView from "../../../../components/Test/DesktopView";
import { useDispatch } from "react-redux";
import {
  fetchSubjectsByProfileId,
  fetchExamsBySubjectId,
  resetExamQuestions,
  fetchExamWithHighestScore,
  fetchExamWithPreviousScore,
} from "../../../../redux/test/testActions";
import useUser from "../../../../hooks/useUser";
import useTest from "../../../../hooks/useTest";
import useSubscription from "../../../../hooks/useSubscription";
import { Navigate } from "react-router-dom";
import { fetchSubjectsAction } from "../../../../redux/contentUpload/contentUploadActions";
import useContentUpload from "../../../../hooks/useContentUpload";

export default function TestSubject() {
  const [value, setValue] = React.useState(false);
  const [showSubjectPicker, setShowSubjectPicker] = React.useState(true);
  const [examData, setExamData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [subjects, setSubjects] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(0);
  const [showFilters, setShowFilters] = React.useState(false);
  const isDesktop = useResponsive("up", "lg");
  const dispatch = useDispatch();
  const user = useUser();
  const test = useTest();
  const { userInfo } = user;
  const { isTrial } = useSubscription()
  const {
    subjectsData,
    subjectLoaded,
    subjectExams,
    subjectExamsLoaded,
    examWithHighestScoreLoaded,
    examWithHighestScoreLoading,
    examWithHighestScore,
  } = test;
  const { subjectData } = useContentUpload();

  const handleFilters = () => {
    setShowFilters(!showFilters);
  };
  const handleClickOpen = (item) => {
    if (Object.keys(item)?.length !== 0) {
      setExamData([
        {
          /*    examYearId: item?.examYearId,
        subjectId: item?.subjectId, */
          // examType: 1,
          subjectName: item?.SubjectName,
          // year: item?.year,
          examName: item?.ExamName,
          examId: item?.ExamId,
        },
      ]);
      setOpenModal(true);
    }
  };
  const [expanded, setExpanded] = React.useState(false);

  // if (isTrial) return <Navigate to={"/dashboard/settings?tab=3"} replace />

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSelectedId(panel);
    dispatch(resetExamQuestions());
    setExamData([]);
  };

  React.useEffect(() => {
    debugger
    if (userInfo?.isSuperSchoolAdmin) {
      dispatch(fetchSubjectsAction())
    } else {
      dispatch(fetchSubjectsByProfileId(userInfo?.profileId));
    }
  }, []);

  React.useEffect(() => {
    if (userInfo?.isSuperSchoolAdmin) {
      if (subjectData?.length >= 1) {
        setSubjects(subjectData)
      }
    } else {
      if (subjectsData?.length >= 1) {
        setSubjects(subjectsData);
      }
    }

  }, [userInfo?.isSuperSchoolAdmin, subjectsData, subjectData])

  React.useEffect(() => {
    if (subjectExams?.length >= 1) {
      setExamData(subjectExams);
    }
  }, [subjectExams]);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowSubjectPicker(false);
    setSelectedId(subjectsData[newValue]?.SubjectId);
    dispatch(resetExamQuestions());
    setExamData([]);
  };

  const handleClick = (event, newValue) => { };

  React.useEffect(() => {
    if (selectedId !== 0) {
      // dispatch(fetchExamsBySubjectId(selectedId));
      dispatch(fetchExamWithHighestScore(userInfo?.profileId, selectedId))
      dispatch(fetchExamWithPreviousScore(userInfo?.profileId, selectedId))
    }
  }, [selectedId, userInfo?.profileId]);

  return (
    <Container sx={{ height: "100vh", padding: { xs: "0" } }}>
      {/* {isDesktop && (
        <DesktopView
          subjects={subjects}
          showSubjectPicker={showSubjectPicker}
          open={open}
          handleChange={handleChange}
          value={value}
          handleClose={handleClose}
          examData={examData}
          handleClickOpen={handleClickOpen}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          highestExamLoaded={examWithHighestScoreLoaded}
          highestExamLoading={examWithHighestScoreLoading}
          highestExam={examWithHighestScore}
        />
      )}
      {!isDesktop && ( */}
      <MobileView
        subjects={subjects}
        expanded={expanded}
        handleChange={handleExpandChange}
        onClick={handleClickOpen}
        data={subjectExams}
        examData={examData}
        loaded={subjectExamsLoaded}
        onFilter={handleFilters}
        show={showFilters}
        open={openModal}
        handleCloseModal={handleCloseModal}
      />
      {/* )} */}
    </Container>
  );
}

// feature to be added later {
//   id: 0,
//   name: "",
// },
// {
//   id: 1,
//   name: "English Language",
//   highestScore: 42 / 60,
//   grade: "Average",
//   year: 2015,
//   image: <ChemistryIcon width={"50"} height={"50"} />,
// },
// {
//   id: 2,
//   name: "Mathematics",
//   highestScore: 69 / 60,
//   grade: "Excellent",
//   year: 2018,
//   image: <ChemistryIcon width={"50"} height={"50"} />,
// },
// {
//   id: 3,
//   name: "Civic Education",
//   highestScore: 12 / 60,
//   grade: "Poor",
//   year: 2010,
//   image: <ChemistryIcon width={"50"} height={"50"} />,
// },
// ]; */
