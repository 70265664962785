import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getCountries,
  getStatesByCountryId,
} from "../../../redux/settings/settingsActions";
import useSettings from "../../../hooks/useSettings";
import { useDispatch } from "react-redux";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function CountryAsynchronous({
  disabled,
  updateValue,
  countryHandler,
  values,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { countries, countriesLoaded, isCountryLoading } = useSettings();
  const loading = open && options.length === 0;
  const [countrySelect, setCountrySelect] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!countriesLoaded) {
      dispatch(getCountries());
    }
  }, [countriesLoaded]);

  // This effect populates the country select options
  React.useEffect(() => {
    const createCountrySelect = () => {
      let countryArray = [];

      Array.isArray(countries) && countries?.map((country) => {
        let option = { value: country?.countryId, label: country?.countryName };
        return countryArray?.push(option);
      });
      setCountrySelect(countryArray);
    };
    countries?.length !== 0 && createCountrySelect();
  }, [countries]);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);
      if (active) {
        setOptions(countrySelect);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <Autocomplete
        onKeyDown={handleKeyDown}
        autoHighlight
        id="asynchronous-demo"
        readOnly={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={values || ""}
        onChange={(event, value) => {
          if (value) {
            countryHandler(event, value);
            updateValue(value);
          } else {
            updateValue({ label: "", value: 0 })
          }
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option?.label || ""}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            InputProps={{
              ...params.InputProps,
              endAdornment: !disabled ? (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ) : (
                ""
              ),
            }}
          />
        )}
      />
    </>
  );
}
