import React, { useState, useEffect } from "react";
import "./Timer.css";
import { Box } from "@mui/material";

function Timer({handleNextQuestion, totalDuration, timeLeft, handleTimeLeft}) {
 

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        handleTimeLeft(timeLeft);
      }else{
        handleNextQuestion(timeLeft);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  // Calculate the width of the yellow bar based on the remaining time
  const yellowWidth = (timeLeft / totalDuration) * 100;

  // Calculate the background color based on the remaining time
  const getBackgroundColor = () => {
    if (timeLeft <= 20) {
      return "red";
    } else if (timeLeft <= 23) {
      return "orange";
    } else if (timeLeft <= 25) {
      return "#FF8C00";
    } else if (timeLeft <= 27) {
      return "#FFEF00";
    } else {
      return "yellow";
    }
  };

  const getColor = () => {
    if (timeLeft === 0) {
      return "red";
    } else if (timeLeft <= 5) {
      const redValue = 192 + (5 - timeLeft) * 13;
      return `rgb(${redValue}, 0, 0)`;
    } else if (timeLeft <= 8) {
      const redValue = 160 + (8 - timeLeft) * 13;
      return `rgb(${redValue}, 0, 0)`;
    } else if (timeLeft <= 10) {
      return "coral";
    } else if (timeLeft <= 15) {
      return "orange";
    } else {
      return "yellow";
    }
  };



  const getTextColor = () => {
    if (timeLeft >= 15) {
      return "#40499D";
    } else {
      return "white";
    }
  };
  return (
    <Box
      className="timer-container"
      style={{ backgroundColor: getBackgroundColor() }}
    >
      <Box className="timer-bar">
        <Box
          className="yellow-bar"
          style={{ width: `${yellowWidth}%`, backgroundColor: getColor() }}
        ></Box>
        <Box className="text" style={{color:getTextColor()}}>
          <span className="time-label">Time:</span>
          {`${Math.floor(timeLeft / 60)}:${(timeLeft % 60)
            .toString()
            .padStart(2, "0")}`}
        </Box>
      </Box>
    </Box>
  );
}

export default Timer;
