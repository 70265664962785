import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
  CardContent,
  CardMedia,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled, useTheme } from "@mui/material/styles";
import CountryAsynchronous from "../../../components/Settings/Autocomplete/CountryAsynchronous";
import StateAsynchronous from "../../../components/Settings/Autocomplete/StateAsynchronous";
import {
  getStatesByCountryId,
  getAllCountries,
  getSponsorTypes,
} from "../../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import { useNotifications } from "../../../components/notifications/notifications";
import CustomSelect from "../../admin/usermetrics/customSelectForm";
import useUser from "../../../hooks/useUser";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Avatar from "@mui/material/Avatar";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1.5),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "transparent", // Hide the placeholder text
  },
  "& .MuiInputBase-input:focus::placeholder": {
    color: "#555", // Style the label text color when focused
  },
}));

const SponsorOnboarding = (props) => {
  const { sponsorType, setSponsorType } = useState("");

  const handleChangeSponsor = (event) => {
    setInput({ ...input, sponsorTypeId: parseInt(event.target.value) });
  };

  const [checked, setChecked] = React.useState(true);

  const handleSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const { input, setInput } = props;
  const initialFormValues = {
    schoolName: "",
    schoolAddress: "",
    countryId: 0,
    stateId: 0,
    sponsorTitle: "",
    sponsorFirstName: "",
    sponsorLastName: "",
    organisationName: "",
    profilePic: "",
    isGiftAnonymous: false,
  };

  const [formValues, setFormValues] = React.useState({ ...initialFormValues });
  const dispatch = useDispatch();
  const [values, setValues] = useState({ label: "", value: 0 });
  const [stateValues, setStateValues] = useState({ label: "", value: 0 });
  const [Toast, setToast] = useNotifications();

  const user = useUser();
  const { countries, statesByCountryId, isLoading } = user;

  const [sponsorTypes, setSponsorTypes] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
  };

  const handleToggleChange = (e) => {
    setInput({
      ...input,
      isGiftAnonymous: e.target.checked,
    });
  };

  const [uploading, setUploading] = useState(false);
  const [uploadedPicture, setUploadedPicture] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploading(true);

    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedPicture({
        file: file,
        base64String: reader.result,
      });
      setInput({
        ...input,
        profilePic: reader.result,
      });
      setUploading(false);
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getSponsorTypes());
  }, []);
  useEffect(() => {
    setSponsorTypes(user?.sponsorTypes);
  }, [user?.sponsorTypes]);

  return (
    <div>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 528,
          },
        }}
      >
        <Paper sx={{ borderRadius: 1, border: "1px solid #E5E5E5", p: 3 }}>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Tell us about this sponsorship
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Sponsor type *</Typography>
              <CustomSelect
                handleChange={handleChangeSponsor}
                value={input.sponsorTypeId}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select Sponsor Type"}
                Seloptions={sponsorTypes}
              />
            </Grid>

            {input.sponsorTypeId == "1" && (
              <>
                <Grid item xs={12}>
                  <InputLabel htmlFor="">Title *</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Eg Senator"
                      required="required"
                      size="small"
                      name="title"
                      value={input.title}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel htmlFor="firstName">
                    Sponsor Firstname *
                  </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Eg John"
                      required="required"
                      size="small"
                      name="firstName"
                      value={input.firstName}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel htmlFor="lastName">Sponsor Lastname *</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Eg Smith"
                      required="required"
                      size="small"
                      name="lastName"
                      value={input.lastName}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} mb={3}>
                  <InputLabel htmlFor="profilePicture" mb={4}>
                    Upload Profile Picture *
                  </InputLabel>
                  <FormControl fullWidth>
                    {!uploadedPicture && (
                      <Box
                        component="label"
                        sx={{
                          border: "1.5px dashed #E2E2E2",
                          borderSpacing: "20px",
                          borderRadius: "8px",
                          padding: "16px 12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#F2F4FE ", // Light purple color on hover
                          },
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #ccc",
                          }}
                        />
                        <Grid>
                          <Typography sx={{ fontSize: "16px" }}>
                            {uploading ? (
                              "Uploading..."
                            ) : (
                              <>
                                {" "}
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    color: "#40499D",
                                    fontWeight: 500,
                                  }}
                                >
                                  Select a profile picture
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "12px", color: "#919191" }}
                                >
                                  Supports: JPG, JPEG and PNG
                                </Typography>
                              </>
                            )}
                          </Typography>
                        </Grid>

                        <VisuallyHiddenInput
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          style={{ display: "none" }} // Hide the input visually
                          onChange={handleFileChange}
                          disabled={uploading}
                        />
                      </Box>
                    )}
                    {uploadedPicture && (
                      <Grid
                        component="label"
                        sx={{
                          border: "2px dashed #E2E2E2",
                          borderSpacing: "20px",
                          borderRadius: "8px",
                          padding: "16px 12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <Avatar
                          src={uploadedPicture.base64String}
                          alt="Uploaded"
                          sx={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #ccc",
                          }}
                        />

                        {/* <Grid item>
                          <Typography>{uploadedPicture.file.name}</Typography>
                        </Grid> */}
                        <Grid>
                          <Typography sx={{ fontSize: "16px" }}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "#40499D",
                                fontWeight: "bold",
                                paddingBottom: "4px ",
                              }}
                            >
                              {uploadedPicture.file.name}
                            </Typography>
                            <Button
                              variant="outlined"
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleFileChange}
                              disabled={uploading}
                              sx={{ fontSize: "10px", color: "#919191" }}
                            >
                              Change
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}

            {input.sponsorTypeId == "2" && (
              <>
                <Grid item xs={12}>
                  <InputLabel htmlFor="organisationName">
                    Organisation name *
                  </InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Eg Company xyz"
                      required="required"
                      size="small"
                      name="organisationName"
                      value={input.organisationName}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} mb={3}>
                  <InputLabel htmlFor="profilePicture" mb={4}>
                    Upload Profile Picture *
                  </InputLabel>
                  <FormControl fullWidth>
                    {!uploadedPicture && (
                      <Box
                        component="label"
                        sx={{
                          border: "1.5px dashed #E2E2E2",
                          borderSpacing: "20px",
                          borderRadius: "8px",
                          padding: "16px 12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#F2F4FE ", // Light purple color on hover
                          },
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #ccc",
                          }}
                        />
                        <Grid>
                          <Typography sx={{ fontSize: "16px" }}>
                            {uploading ? (
                              "Uploading..."
                            ) : (
                              <>
                                {" "}
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    color: "#40499D",
                                    fontWeight: 500,
                                  }}
                                >
                                  Select a profile picture
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "12px", color: "#919191" }}
                                >
                                  Supports: JPG, JPEG and PNG
                                </Typography>
                              </>
                            )}
                          </Typography>
                        </Grid>

                        <VisuallyHiddenInput
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          style={{ display: "none" }} // Hide the input visually
                          onChange={handleFileChange}
                          disabled={uploading}
                        />
                      </Box>
                    )}
                    {uploadedPicture && (
                      <Box
                        component="label"
                        sx={{
                          border: "1.5px dashed #E2E2E2",
                          borderSpacing: "20px",
                          borderRadius: "8px",
                          padding: "16px 12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          cursor: "pointer",
                          backgroundColor: "#F2F4FE ", // Light purple color on hover
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "48px",
                            height: "48px",
                            border: "1px solid #ccc",
                          }}
                          src={uploadedPicture.base64String}
                          alt="Uploaded"
                        />
                        <Grid>
                          <Typography sx={{ fontSize: "16px" }}>
                            {uploading ? (
                              "Uploading..."
                            ) : (
                              <>
                                {" "}
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    color: "#40499D",
                                    fontWeight: 500,
                                  }}
                                >
                                  {uploadedPicture.file.name}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "12px", color: "#919191" }}
                                >
                                  Change
                                </Typography>
                              </>
                            )}
                          </Typography>
                        </Grid>

                        <VisuallyHiddenInput
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          style={{ display: "none" }} // Hide the input visually
                          onChange={handleFileChange}
                          disabled={uploading}
                        />
                      </Box>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  value={input.isGiftAnonymous}
                  control={
                    <Switch
                      color="primary"
                      checked={input.isGiftAnonymous}
                      onChange={handleToggleChange}
                    />
                  }
                  label="Gift anonymously"
                  labelPlacement="Gift anonymously"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {/* </Paper> */}
      <Toast />
    </div>
  );
};

export default SponsorOnboarding;
