export const FECTCH_CLASS_LEVEL = "FECTCH_CLASS_LEVEL";
export const FECTCH_CLASS_LEVEL_FAIL = "FECTCH_CLASS_LEVEL_FAIL";
export const ADD_NEW_STUDENT = "ADD_NEW_STUDENT";
export const LOAD_STUDENT = "LOAD_STUDENT";
export const GET_ALL_STUDENT = " GET_ALL_STUDENT";
export const GET_ALL_SCHOOL_BY_SPONSOR_ID = "GET_ALL_SCHOOL_BY_SPONSOR_ID";
export const GET_ALL_SCHOOL_BY_SPONSOR_ID_FAIL = "GET_ALL_SCHOOL_BY_SPONSOR_ID_FAIL";
export const CHECK_USER_SUB = "CHECK_USER_SUB";
export const CHECK_USER_SUB_SUCCESS = "CHECK_USER_SUB_SUCCESS";
export const CHECK_USER_SUB_FAILURE = "CHECK_USER_SUB_FAILURE";
export const GET_ALL_SUBSCRIPTIONS = " GET_ALL_SUBSCRIPTIONS";
export const ALL_SUBSCRIPTION_PLAN = "ALL_SUBSCRIPTION_PLAN";
export const ALL_SUBSCRIPTION_PLAN_LOADING = "ALL_SUBSCRIPTION_PLAN_LOADING";
export const ALL_SUBSCRIPTION_PLAN_FAILURE = "ALL_SUBSCRIPTION_PLAN_FAILURE";
export const ALL_PAYMENT_SUMMARY = "ALL_PAYMENT_SUMMARY";
export const ALL_PAYMENT_SUMMARY_FAILURE = "ALL_PAYMENT_SUMMARY_FAILURE";
export const FETCH_SPONSOR_SUBSCRIPTIONS = "FETCH_SPONSOR_SUBSCRIPTIONS";
export const FETCH_SPONSOR_SUBSCRIPTIONS_SUCCESS = "FETCH_SPONSOR_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SPONSOR_SUBSCRIPTIONS_FAIL ="FETCH_SPONSOR_SUBSCRIPTIONS_FAIL";
export const FETCH_SCHOOL_SUBSCRIPTIONS ="FETCH_SCHOOL_SUBSCRIPTIONS";
export const FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS ="FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SCHOOL_SUBSCRIPTIONS_FAIL ="FETCH_SCHOOL_SUBSCRIPTIONS_FAIL";
export const FETCH_STUDENT_SUBSCRIPTIONS ="FETCH_STUDENT_SUBSCRIPTIONS";
export const FETCH_STUDENT_SUBSCRIPTIONS_SUCCESS ="FETCH_STUDENT_SUBSCRIPTIONS_SUCCESS";
export const FETCH_STUDENT_SUBSCRIPTIONS_FAIL ="FETCH_STUDENT_SUBSCRIPTIONS_FAIL";
export const FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_SUCCESS = "FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_FAIL = "FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_FAIL";
export const UPDATE_AMOUNT = "UPDATE_AMOUNT";
export const UPDATE_AMOUNT_SUCCESS = "UPDATE_AMOUNT_SUCCESS";
export const UPDATE_AMOUNT_FAILURE = "UPDATE_AMOUNT_FAILURE";
