import * as auditTrailActions from "./types";
import Axios from "../../connection/defaultClient";

export const loadAdminAudit = ({
  pageNumber,
  selectedUser,
  activitiesType,
  startDate,
  endDate,
  search,
  // payload
}) => {
  return (dispatch) => {
    dispatch({
      type: auditTrailActions.ALL_ADMIN_AUDIT_TRAIL_STATUS,
      payload: true,
    });

    // let queryString = "";
    // if (payload && Object.keys(payload).length > 0) {
    //   const params = new URLSearchParams(payload);
    //   queryString = `?${params.toString()}`;
    // }
    Axios.post(
      // `/Audit/ViewAuditPagination${queryString}`
      `/Audit/ViewAuditPagination?pageNumber=${pageNumber}&pageSize=${10}&AuditType=${activitiesType}&StartDate=${startDate}&EndDate=${endDate}&ProfileId=${
        selectedUser?.ProfileId
      }&Search=${search}`
    )
      .then((response) => {
        dispatch({
          type: auditTrailActions.ALL_ADMIN_AUDIT_TRAIL,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: auditTrailActions.ALL_ADMIN_AUDIT_TRAIL_FAIL,
          payload: error.message,
        });
      });
  };
};
