import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchCreateSession,
  fetchManageSession,
  fetchUpdateSession,
} from "../../../redux/session";
import { useDispatch } from "react-redux";
import { useNotifications } from "../../../components/notifications/notifications";
import useSession from "../../../hooks/useSession";
import DataUsageIcon from "@mui/icons-material/DataUsage";

const BootstrapDialog = styled(Dialog)(({ theme, width, fullScreen }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& > .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  "& .MuiDialog-paper": {
    borderRadius: fullScreen ? "0px" : "12px",
    ...(width &&
      !fullScreen && {
        width: width,
      }),
    boxShadow: "rgba(62, 80, 96, 0.2) 0px 4px 20px",
  },
}));

export default function SessionModal({
  open,
  closeModal,
  mode,
  tableMeta,
  setToastState,
}) {
  const [sessionName, setSessionName] = useState("");
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const session = useSession();
  const { loadingSession } = session;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    dispatch(fetchManageSession());
  }, []);

  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: res.msg,
    });
    closeModal(true);
    dispatch(fetchManageSession());
    setTimeout(() => {
      // navigate('/dashboard/home')
    }, 2000);
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
    setTimeout(() => {
      // navigate('/dashboard/home')
    }, 3000);
  };

  const on500Fail = (error) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: error?.statusText,
    });
    setTimeout(() => {
      // navigate('/dashboard/home')
    }, 2000);
  };

  useEffect(() => {
    if (mode === "edit" && tableMeta.rowData) {
      const [name, start, end, IsActive] = tableMeta.rowData;
      setSessionName(name);
      const startDates = new Date(start).toISOString().split("T")[0];
      setStartDate(startDates);
      const endDates = new Date(end).toISOString().split("T")[0];
      setEndDate(endDates);
      setChecked(IsActive === "true");
    }
  }, [mode, tableMeta]);

  //functionality to  create session
  const handleSubmitSession = () => {
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    // Check if endDate is less than startDate
    if (endDateObject < startDateObject) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: "End date can not be greater than the Start date",
      });
      setTimeout(() => {
        // navigate('/dashboard/home')
      }, 3000);
      // };
      return; // Exit the function early
    }

    let payload = {};
    if (mode === "add") {
      payload = {
        sessionName: sessionName,
        startDate: startDate,
        endDate: endDate,
        isActive: checked,
      };
    } else {
      const [name, start, end, IsActive, SessionId] = tableMeta.rowData;
      payload = {
        sessionName: sessionName,
        startDate: startDate,
        endDate: endDate,
        isActive: checked,
        sessionId: SessionId,
      };
    }

    if (mode === "add") {
      dispatch(fetchCreateSession(payload, onSuccess, onFail));
    } else {
      dispatch(fetchUpdateSession(payload, onSuccess, onFail));
    }
    dispatch(fetchManageSession());
  };

  return (
    <>
      <div>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={open}
          width={"md"}
          PaperProps={{
            elevation: 0,
          }}
        >
          <Grid display="flex" justifyContent="space-between">
            <DialogTitle>
              {mode === "add" ? "Add Session" : "Edit Session"}
            </DialogTitle>
            <IconButton aria-label="close" onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <DialogContent>
            <TextField
              autoFocus
              id="name"
              margin="dense"
              name="session"
              label="Session Name"
              value={sessionName}
              fullWidth
              onChange={(e) => {
                setSessionName(e.target.value);
              }}
              variant="outlined"
            />
            <Grid
              container
              rowSpacing={1}
              marginTop={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  label="Start Date"
                  type="Date"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // autoFocus
                  // required
                  margin="dense"
                  // id="name"
                  // name="End Date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  label="End Date"
                  type="Date"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item={6} marginTop={2}>
              <FormGroup>
                <FormControlLabel
                  required
                  checked={checked}
                  onChange={handleCheckboxChange}
                  control={<Checkbox />}
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitSession} variant="contained">
              {loadingSession ? (
                <DataUsageIcon size={24} className="animate-spin ml-4" />
              ) : mode === "add" ? (
                "Save"
              ) : (
                "Update"
              )}
            </Button>
          </DialogActions>
          {/* </Dialog> */}
        </BootstrapDialog>
      </div>
    </>
  );
}
