export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";

export const FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID =
  "FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID";
export const FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_SUCCESS =
  "FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_SUCCESS";
export const FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_FAILURE =
  "FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_FAILURE";

export const FETCH_USER_PROFILE_DETAILS = "FETCH_USER_PROFILE_DETAILS";
export const FETCH_USER_PROFILE_DETAILS_SUCCESS =
  "FETCH_USER_PROFILE_DETAILS_SUCCESS";
export const FETCH_USER_PROFILE_DETAILS_FAILURE =
  "FETCH_USER_PROFILE_DETAILS_FAILURE";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";
export const RESET_UPDATE_USER_PROFILE = "RESET_UPDATE_USER_PROFILE";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_STATES_BY_COUNTRY_ID = "GET_STATES_BY_COUNTRY_ID";
export const GET_STATES_BY_COUNTRY_ID_SUCCESS =
  "GET_STATES_BY_COUNTRY_ID_SUCCESS";
export const GET_STATES_BY_COUNTRY_ID_FAILURE =
  "GET_STATES_BY_COUNTRY_ID_FAILURE";

export const GET_LGA_BY_STATE_ID = "GET_LGA_BY_STATE_ID";
export const GET_LGA_BY_STATE_ID_SUCCESS = "GET_LGA_BY_STATE_ID_SUCCESS";
export const GET_LGA_BY_STATE_ID_FAILURE = "GET_LGA_BY_STATE_ID_FAILURE";

export const GET_WAEC_APPROVED_SCHOOLS = "GET_WAEC_APPROVED_SCHOOLS";
export const GET_WAEC_APPROVED_SCHOOLS_SUCCESS =
  "GET_WAEC_APPROVED_SCHOOLS_SUCCESS";
export const GET_WAEC_APPROVED_SCHOOLS_FAILURE =
  "GET_WAEC_APPROVED_SCHOOLS_FAILURE";

export const UPDATE_THEME = "UPDATE_THEME";

export const FETCH_PROFILE_LEARNING_STATS = "FETCH_PROFILE_LEARNING_STATS";
export const FETCH_PROFILE_LEARNING_STATS_SUCCESS =
  "FETCH_PROFILE_LEARNING_STATS_SUCCESS";
export const FETCH_PROFILE_LEARNING_STATS_FAILURE =
  "FETCH_PROFILE_LEARNING_STATS_FAILURE";

export const RESET_PASSWORD_SUPER_SCHOOL_USER =
  "RESET_PASSWORD_SUPER_SCHOOL_USER";
export const RESET_PASSWORD_SUPER_SCHOOL_USER_SUCCESS =
  "RESET_PASSWORD_SUPER_SCHOOL_USER_SUCCESS";
export const RESET_PASSWORD_SUPER_SCHOOL_USER_FAILURE =
  "RESET_PASSWORD_SUPER_SCHOOL_USER_FAILURE";

export const LOGOUT_SUPER_USER = "LOGOUT_SUPER_USER";
export const LOGGING_OUT_SUPER_USER = "LOGGING_OUT_SUPER_USER";
