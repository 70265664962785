import {
    Box,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import React from "react";
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Icon,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
} from "@mui/material";
import ApplogoDark from "../../../../assets/images/ApplogoDark.png";
import Applogo from "../../../../assets/images/Applogo.png";
import { Link } from "react-router-dom";
import BookIcon from "../../../../assets/images/bookIcon.png";
import { DebitCardIcon } from "../../../../assets/icons/Icons";
import PackageName from "./packageName";
import { convertDaysToMonths } from "../../../../helpers/helper";


const CustomListItem = styled(ListItem)`
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #40499d;
        margin-right: 10px;
      }
    `;

const styles = {
    container: {
        backgroundColor: "#F3F5F8",
        borderRadius: "0px 0px 20px 20px",
        width: "100%",
        padding: "50px 20px",
        position: "relative",
    },
    list: {
        marginLeft: "20px",
    },
};

const radioLabelStyles = {
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #b4b4b4",
    borderRadius: "0px",
    //   padding: "10px 0px 10px 0px",
    cursor: "pointer",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    "& .MuiTypography-root": {
        width: "100%",
    },
};
const innerLabelStyle = {
    display: "flex",
    width: "100%",
    //   backgroundColor:'red',
    alignItems: "center",
    //   padding: "10px 20px 10px 0px",
    cursor: "pointer",
    justifyContent: "space-between",
};

const PlanPackageBox = ({ selectedSubscription }) => {

    return (
        <Box
            sx={{
                // background: "#40499d",
                // paddingTop: "20px",
                textAlign: "center",
                color: "#fff",
                display: "flex",
                // justifyContent:'center',
                flexDirection: "column",
                alignItems: "center",
                // gap: 3,
                borderRadius: "20px",
                position: "relative"
            }}
        >
            <Box
                gap={3}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                sx={{
                    bgcolor: "#40499d",
                    width: "100%",
                    padding: "30px 30px 100px 30px",
                    height: "50%",
                    borderRadius: "20px 20px 0px 0px",
                    position: "relative",
                }}
            >
                <Box>
                    <img
                        src={Applogo}
                        alt="WAEC Study Up"
                        style={{ width: "auto", height: "30px" }}
                    />
                </Box>
                <Box>
                    <Typography variant="body2" fontSize={"18px"}>
                        Subscribe and start learning to <br /> expand your world of
                        knowledge!
                    </Typography>
                </Box>
            </Box>

            <PackageName
                selectedSubscription={selectedSubscription} />
            <Paper style={styles.container}>
                <List style={styles.list} component="ul">
                    <CustomListItem component="li">
                        <ListItemText
                            primary={`Access to all subjects for ${convertDaysToMonths(selectedSubscription?.DaysAllowed)} ${convertDaysToMonths(selectedSubscription?.DaysAllowed) === 1 ? "month" : "months"
                                }`} />
                    </CustomListItem>
                    <CustomListItem component="li">
                        <ListItemText primary="Access to all subject years" />
                    </CustomListItem>
                    <CustomListItem component="li">
                        <ListItemText primary="Performance tracking metrics" />
                    </CustomListItem>
                </List>
            </Paper>
        </Box>
    );
};

export default PlanPackageBox;
