
import React, { useEffect, useState } from 'react'
import DataTable from '../../../components/DataTable/DataTable'
import AvatarWithText from '../../../components/Avatar/AvatarWithText';
import { loadAdminAudit } from '../../../redux/auditTrail/auditTrailActions';
import { useDispatch } from 'react-redux';
import useAuditTrail from '../../../hooks/useAuditTrail';
import moment from 'moment';
import StatusGenerator from '../../../components/TransactionLog/StatusGenerator';


const AuditTable = ({ filterData, handlePageChange, pagination }) => {

    const dispatch = useDispatch()
    const auditTrails = useAuditTrail();
    const { allAudits } = auditTrails;


    const columns = [
        {
            name: "S/N",
            label: "S/N",
            options: {
                setCellProps: () => ({ style: { padding: "20px" } }),
                customBodyRender: (value, tableMeta, updateValue) => {

                    const val = (pagination.pageNumber - 1) * pagination.pageSize + 1;
                    const rsn = val + tableMeta.rowIndex
                    return rsn;
                },
            },
        },
        {
            name: "createdBy",
            label: "User",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const email = tableMeta.rowData[2];
                    return <AvatarWithText text={value} email={email} />;
                },
            },
        },
        {
            name: "emailAddress",
            label: "Email",
            options: {
                display: false,
                setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
            },
        },

        {
            name: "auditType",
            label: "Activity Type",
            options: {
                setCellProps: () => ({ style: { minWidth: "180px", padding: "20px", padding: "dense", } }),
                customBodyRender: (value, tableMeta) => {
                    const lowerCaseValue =
                        typeof value === "string" ? value?.toLowerCase() : value;
                    switch (lowerCaseValue) {
                        case "create":
                            return (
                                <StatusGenerator statusText="CREATE" variant="greenFilled" />
                            );
                        case "delete":
                            return (
                                <StatusGenerator statusText="DELETE" variant="redFilled" />
                            );
                        case "update":
                            return (
                                <StatusGenerator
                                    statusText="UPDATE"
                                    variant="yellowFilled"
                                />
                            );
                        default:
                            return "no status";
                    }
                },
            },
            // customBodyRender: (value, tableMeta) => {
            //     console.log(value)
            //     switch (value) {
            //         case "Create":
            //             return <Chip label="Success" color="success" />;
            //         case "Delete":
            //             return <Chip label="Delete" color="error" />;
            //         case "Update":
            //             return <Chip label="Update" color="warning" />;
            //         default:
            //             return "no status";
            //     }
            // },
        },
        {
            name: "auditInfo",
            label: "Acitivity Details",
            options: {
                setCellProps: () => ({ style: { minWidth: "180px", padding: "10px", padding: "dense", } }),
            },
        },
        {
            name: "dateCreated",
            label: "Date",
            options: {
                setCellProps: () => ({ style: { minWidth: "180px", padding: "10px", padding: "dense", } }),
                customBodyRender: (value, tableMeta) =>
                    value === undefined || value === ""
                        ? "not updated"
                        : moment(value).format("MMM DD YYYY")
            },
        },


    ]


    return (
        <DataTable
            data={filterData}
            serverSide={true}
            columns={columns}
            changePage={handlePageChange}
            // page={pagination.page}
            count={allAudits?.recordsFiltered
            }
            download={true}

        />

    )
}

export default AuditTable