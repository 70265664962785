import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import InProgressStatus from "../../../assets/images/InProgressStatus.svg";
import CompletedStatus from "../../../assets/images/CompletedStatus.svg";
import NotStartedStatus from "../../../assets/images/NotStartedStatus.svg";

const StatusGeneratorButton = styled(Button)({
  textAlign: "center",
  borderRadius: 10,
  minWidth: "70px",
  fontSize: "16px",
  textTransform: "capitalize",
  fontWeight: 700,
  padding: "10px 40px",
  cursor: "text",
});

const statusTypesDict = {
  yellowFilled: {
    borderRadius: "8px",
    background: `url(${InProgressStatus})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#40499d",
  },
  greenFilled: {
    borderRadius: "8px",
    background: `url(${CompletedStatus})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  },
  redFilled: {
    borderRadius: "8px",
    background: `url(${NotStartedStatus})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  },
};

const StatusGenerator = ({ statusText, variant }) => {
  return (
    <StatusGeneratorButton
      disableElevation
      disableRipple
      disableTouchRipple
      disableFocusRipple
      sx={{
        background: statusTypesDict[variant].background,
        backgroundSize: statusTypesDict[variant].backgroundSize,
        backgroundRepeat: statusTypesDict[variant].backgroundRepeat,
        color: statusTypesDict[variant].color,
        "&:hover": {
          background: statusTypesDict[variant].background,
          backgroundSize: statusTypesDict[variant].backgroundSize,
          backgroundRepeat: statusTypesDict[variant].backgroundRepeat,
        },
      }}
    >
      {statusText}
    </StatusGeneratorButton>
  );
};

export default StatusGenerator;
