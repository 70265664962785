import React from 'react';
import {
  Grid, Typography, Box, Paper, Menu, MenuItem, MenuList, ListItemIcon,
  ListItemText, Divider, Radio, Stack, FormControlLabel, FormControl, InputLabel, OutlinedInput, TextField
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import useContentUpload from '../../../hooks/useContentUpload';
import { addExamAction, fetchExamYearsAction, fetchSubjectsAction, GetAllExamSeries, getALlExamType, GetExamByExamId, updateExamAction, addExamQuestionSolutionsAction } from "../../../redux/contentUpload/contentUploadActions";
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  createFromBlockArray,
  ContentState,
  createWithContent,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import useAdminUser from '../../../hooks/useAdminUser';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchQuestionSolutionByQuestionId } from '../../../redux/learning/learningActions';
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CancelIcon } from "../../../assets/images/cancel.svg";
import { RTEditor } from "../../../components/RTEditor";




const ContentUploadMCQSolutionEdit = (props) => {
  const { setToastState, examQuestion, setEditSolution } = props;


  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();
  const location = useLocation();



  const [editorState, setEditorState] = useState("");
  const [solution, setSolution] = useState('')


  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    dispatch(fetchQuestionSolutionByQuestionId(examQuestion?.examQuestionId, (res) => setSolution(res?.solution)));
  }, []);


  useEffect(() => {
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(solution)
    );
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  }, [solution])

  const handleImageUpload = (file) => {
    // Simulate image upload logic
    const imageUrl = URL.createObjectURL(file);

    const contentStateWithImage = ContentState.createFromBlockArray([
      {
        type: 'atomic',
        text: ' ',
        entityRanges: [
          {
            key: 'image',
            length: 1,
            offset: 0,
          },
        ],
      },
    ], {
      image: {
        src: imageUrl,
      },
    });

    setEditorState(EditorState.createWithContent(contentStateWithImage));
  };

  const onSuccess = async (res) => {
    
    setEditSolution(false);
    setToastState({ isOpen: true, severity: "success", message: "Question solution successfully updated" });
  }

  const handleClose = () => {
    setEditSolution(false);
  }

  const handleSubmit = () => {

    const data = {
      profileId: userInfo?.data?.profileId,
      examQuestionId: examQuestion?.examQuestionId,
      solution: solution,
      examId: examQuestion?.examId,
    }

    dispatch(addExamQuestionSolutionsAction(data, (res)=> onSuccess(res)))      
  }

  return (
    <>

      <Box sx={{ mt: 2 }}>

        <Paper sx={{ minWidth: 275, borderRadius: '16px' }}>
          <CardContent>


            <Box sx={{ mt: 2, width: '95%', mx: 'auto' }}>

              <div className='flex justify-between'>
                <Box>
                  <Typography sx={{
                    color: '#000', fontFamily: 'Open Sans',
                    fontSize: '16px', fontWeight: 600,
                    lineHeight: '27.24px'
                  }}>Update Solution</Typography>
                  <Typography sx={{
                    color: '#000', fontFamily: 'Open Sans',
                    fontSize: '12px', fontWeight: 400,
                    lineHeight: '27.24px'
                  }}>Provide detailed explanation or solution to question to aid student's study</Typography>
                </Box>

                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: '50px',
                    height: '50px'
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>

              <Box sx={{ mt: 2, mx: 'auto' }}>
              <RTEditor
              theme={"snow"}
              value={solution}
              key="questionAdd"
              setValue={setSolution}
            />

              </Box>


              <Box m={1} sx={{ mt: 2 }}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >

                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>

                  <Button sx={{ backgroundColor: '#40499D', borderRadius: 2, textTransform: 'none' }} variant="contained" onClick={handleSubmit}>Update</Button>
                </Stack>
              </Box>

            </Box>
          </CardContent>
        </Paper>

      </Box>
    </>
  )
}

export default ContentUploadMCQSolutionEdit;