import React from 'react'

import { Box, Button, Container, Typography } from "@mui/material";
import NavTabs from "../../../components/Settings/NavTabs";
import General from "../../../components/Settings/General";
import Security from "../../../components/Settings/Security";
import Notifications from "../../../components/Settings/Notifications";
import Subscription from "../../../components/Settings/Subscription";
import Support from "../../../components/Settings/Support";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AdminGeneral from '../../../components/Settings/AdminGeneral';




const tabs = [
    {
        label: "General",
        content: <AdminGeneral />,
        icon: <PersonIcon />
    },
    {
        label: "Security",
        content: <Security />,
        icon: <LockIcon />

    },
    {
        label: "Notifications",
        content: <Notifications />,
        icon: <NotificationsActiveIcon />

    },
    // {
    //     label: "Subscription",
    //     content: <Subscription />,
    //     icon: <CreditCardIcon />

    // },
    {
        label: "Support",
        content: <Support />,
        icon: <SupportAgentIcon />

    },
];
const AdminSettings = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Container>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h5" fontWeight={900} color={"brandPrimary.black"}>
                    Settings
                </Typography>
                {/* <Button>Search</Button> */}
            </Box>
            <Box pt={4}>
                <NavTabs value={value} handleChange={handleChange} tabs={tabs} />
            </Box>
        </Container>
    );
};

export default AdminSettings;




