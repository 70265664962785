export const LOADING_EXAM = "LOADING_EXAM"; //loading the exam_questions_ids
export const LOADING_EXAM_FAIL = "LOADING_EXAM_FAIL";
export const SET_ALL_EXAM_QUESTION_ID = "SET_ALL_EXAM_QUESTION_ID";
export const SET_EACH_EXAM_QUESTIONS = "SET_EACH_EXAM_QUESTIONS";
export const SET_EACH_EXAM_QUESTIONS_FAILED = "SET_EACH_EXAM_QUESTIONS_FAILED";
export const UPDATE_EXAM_COUNTER = "UPDATE_EXAM_COUNTER";
export const FLAG_QUESTION = "FLAG_QUESTION";
export const UNFLAG_QUESTION = "UNFLAG_QUESTION";
export const UPDATE_TIMER = "UPDATE_TIMER";
// export const UPDATE_COUNTER_PREV = "UPDATE_COUNTER_PREV";
export const SET_QUESTION_ANSWERED = "SET_QUESTION_ANSWERED";
export const SET_SELECTED_OPTION = "SET_SELECTED_OPTION";

//get subject by profile id
export const FETCH_SUBJECT_BY_PROFILE_ID = "FETCH_SUBJECT_BY_PROFILE_ID";
export const FETCH_SUBJECT_BY_PROFILE_ID_SUCCESS = "FETCH_SUBJECT_BY_PROFILE_ID_SUCCESS";
export const FETCH_SUBJECT_BY_PROFILE_ID_ERROR = "FETCH_SUBJECT_BY_PROFILE_ID_ERROR";

//get subject by profile id
export const FETCH_EXAM_BY_SUBJECT_ID = "FETCH_EXAM_BY_SUBJECT_ID";
export const FETCH_EXAM_BY_SUBJECT_ID_SUCCESS = "FETCH_EXAM_BY_SUBJECT_ID_SUCCESS";
export const FETCH_EXAM_BY_SUBJECT_ID_ERROR = "FETCH_EXAM_BY_SUBJECT_ID_ERROR";

//resetQuestions
export const RESET_QUESTION_ANSWERED = "RESET_QUESTION_ANSWERED";

export const ACTIVATE_TEST = "ACTIVATE_TEST";
export const DEACTIVATE_TEST = "DEACTIVATE_TEST";
export const RESET_EXAM_BY_SUBJECT_ID = "RESET_EXAM_BY_SUBJECT_ID";

// submit exam
export const STORE_SUBMIT_DATA = "STORE_SUBMIT_DATA";
export const STORE_EXAM_RESULT = "STORE_EXAM_RESULT"


// test dashboard
export const FETCH_TEST_STATISTICS_BY_PROFILE_ID = "FETCH_TEST_STATISTICS_BY_PROFILE_ID"
export const FETCH_TEST_STATISTICS_BY_PROFILE_ID_SUCCESS = "FETCH_TEST_STATISTICS_BY_PROFILE_ID_SUCCESS"
export const FETCH_TEST_STATISTICS_BY_PROFILE_ID_FAILURE = "FETCH_TEST_STATISTICS_BY_PROFILE_ID_FAILURE"


// Get Exam with highest score
export const FETCH_EXAM_WITH_HIGHEST_SCORE = "FETCH_EXAM_WITH_HIGHEST_SCORE"
export const FETCH_EXAM_WITH_HIGHEST_SCORE_SUCCESS = "FETCH_EXAM_WITH_HIGHEST_SCORE_SUCCESS"
export const FETCH_EXAM_WITH_HIGHEST_SCORE_FAILURE = "FETCH_EXAM_WITH_HIGHEST_SCORE_FAILURE"

export const RESET_TEST = "RESET_QUIZ"

export const FETCH_PREVIOUS_SCORE =  "FETCH_PREVIOUS_SCORE"
export const FETCH_PREVIOUS_SCORE_SUCCESS =  "FETCH_PREVIOUS_SCORE_SUCCESS"
export const FETCH_PREVIOUS_SCORE_FAILURE =  "FETCH_PREVIOUS_SCORE_FAILURE"

export const UPDATE_SECTION_ID = "UPDATE_SECTION_ID"