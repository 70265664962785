import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`?.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name, size) {
  const bgColor = stringToColor(name);
  const lightBgColor = lightenColor(bgColor, 0.7); // You can adjust the lightness factor as needed

  return {
    sx: {
      bgcolor: lightBgColor,
      color: "#000000", // Very dark text color
      ...(size !== "large" && {
        width: 30,
        height: 30,
        fontSize: "13px",
      }),
      ...(size === "xlarge" && {
        width: 64,
        height: 64,
        fontSize: "20px",
        fontWeight: 600,
        border: "1.5px solid #fff",
      }),
    },
    children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
  };
}

// Function to lighten a color by a given factor
function lightenColor(color, factor) {
  const hex = color.replace(/^#/, "");
  const num = parseInt(hex, 16);
  const r = (num >> 16) + factor * 255 * (1 - (num >> 16) / 255);
  const g =
    ((num >> 8) & 0x00ff) + factor * 255 * (1 - ((num >> 8) & 0x00ff) / 255);
  const b = (num & 0x0000ff) + factor * 255 * (1 - (num & 0x0000ff) / 255);
  const newColor = (
    (1 << 24) +
    (Math.round(r) << 16) +
    (Math.round(g) << 8) +
    Math.round(b)
  )
    .toString(16)
    .slice(1);
  return `#${newColor}`;
}

export default function AvatarImg({ text, size }) {
  return (
    <Stack direction="row" spacing={2}>
      <Avatar {...stringAvatar(text ?? "No Text", size)} />
    </Stack>
  );
}
