import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Card, Button, Typography, Grid } from "@mui/material";
import Trophy from "../../../../assets/images/Trophy.gif";
import FloatingFlowers from "../../../../assets/images/floatingFlowers.gif";
import {
  fetchPracticeQuestion,
  fetchPracticeQuestionsByQuestionId,
  fetchSubmitPendingStatus,
} from "../../../../redux/learning";
import useLearning from "../../../../hooks/useLearning";
import { useDispatch } from "react-redux";
import { useNotifications } from "../../../../components/notifications/notifications";

function FreeFiveQuestions(props) {
  const [examPractQuestionId, setExamPractQuestionId] = useState(0);
  const learning = useLearning();
  const { practiceQuestions, practiceQuestionCount } = learning;
  const dispatch = useDispatch();
  const { input } = props;
  const [ToastState, setToastState] = useNotifications();

  const handleClose = () => {
    props.close(false);
  };

  return (
    <>
      <Dialog
        open={props.open}
        PaperProps={{
          style: {
            // backgroundColor: "#40499D",
            width: "400px",
            height: "300px",
          },
        }}
      >
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          component={DialogContent}
        >
          <img
            src={Trophy}
            alt="Image"
            style={{
              height: 100,
              width: 100,
              display: "block", // Change display to "block"
              margin: "0 auto",
              marginTop: "0rem",
            }}
          />
          {/* <img
            src={FloatingFlowers}
            alt="Image"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 100,
              height: "auto",
              marginTop: "4rem",
            }}
          /> */}
          <Typography
            class="text-2xl font-extrabold ..."
            style={{
              color: "#40499d",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            Congratulations!
            {/* Let's test your knowledge with 5 practice */}
          </Typography>

          <Grid>
            {" "}
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "2rem",
                px: 2,
                "& > *": {
                  m: 1,
                },
              }}
            >
              <Typography
                style={{
                  color: "#919191",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                You've earned 5 more questions
              </Typography>
            </Grid>
            <Button
              onClick={() => {
                handleClose();
              }}
              variant="outlined"
              style={{
                display: "flex",
                display: "block", // Change display to "block"
                margin: "0 auto",
                color: "white",
                backgroundColor: "#40499d",
                // borderColor: "white",
              }}
            >
              Continue
            </Button>
          </Grid>
        </Box>
      </Dialog>
      <ToastState />
    </>
  );
}
export default FreeFiveQuestions;
