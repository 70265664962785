import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme, size, bg }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #CFD3D4",
  backgroundColor: bg??"#fff",
  "&:hover": {
    border: `1.7px solid ${theme.palette.primary.dark}`,
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: size ? size : "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme, hoverwidth, width }) => ({
  fontSize: "12px",
  color: "#686868",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: width ? width : "25ch",
      "&:focus": {
        width: hoverwidth ? hoverwidth : "30ch",
      },
    },
  },
}));

export default function SearchBox({
  width,
  hoverwidth,
  onChange,
  placeholder,
  value,
  size,
  bg,
  searchIconColor
}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Search size={size} bg={bg}>
        <SearchIconWrapper>
          <SearchIcon sx={{ color: searchIconColor? searchIconColor : "#979797" }} />
        </SearchIconWrapper>
        <StyledInputBase
          width={width}
          hoverwidth={hoverwidth}
          placeholder={placeholder ? `Search for ${placeholder}` : "Search…"}
          inputProps={{ "aria-label": "search" }}
          onChange={onChange}
          value={value ? value : ""}
        />
      </Search>
    </Box>
  );
}
