import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, Skeleton, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import FaceIcon from "@mui/icons-material/Face";
import AvatarImageUpload from "./AvatarImageUpload";
import ProfileUpload from "./ProfileUpload";
import NavTabs from "../NavTabs";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import useSettings from "../../../hooks/useSettings";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    borderRadius: "30px",
  },
  "& .MuiDialogActions-root": {
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  "&.Mui-Paper": {
    border: `1px solid ${theme.palette.brandPrimary.main}`,
  },
}));



function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ImageUploadDialog({selectedImage,value, handleChange, open, onClose, isPremium, activeId, handleAvatarClick, updatedImgToSend,handleUpdateProfileImage, isLoading, handleImageUpload }) {
  

  const { isProfileUpdatedFired } =
    useSettings();
 

  const tabs = [
    {
      label: "Avatar",
      content: (
        <AvatarImageUpload
          isPremium={isPremium}
          handleAvatarClick={handleAvatarClick}
          activeId={activeId}
        />
      ),
      icon: <FaceIcon />,
    },
    {
      label: "Browse Photos",
      content: (
        <ProfileUpload
          selectedImage={selectedImage}
          isLoading={isLoading}
          handleImageUpload={handleImageUpload}
        />
      ),
      icon: <CameraAltIcon />,
    },
  ];

 
  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        PaperProps={{
          sx: { width: "800px", height: "600px", overflowX: "hidden" },
        }}
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <NavTabs value={value} handleChange={handleChange} tabs={tabs} />
        </DialogContent>
        <DialogActions>
          <Button
            color="brandPrimary"
            variant="outlined"
            autoFocus
            onClick={onClose}
            sx={{ textTransform: "capitalize", borderRadius: "10px" }}
          >
            Cancel
          </Button>
          <Button
            disabled={isProfileUpdatedFired}
            color="brandPrimary"
            variant="contained"
            autoFocus
            onClick={handleUpdateProfileImage}
            sx={{ textTransform: "capitalize", borderRadius: "10px" }}
          >
             {isProfileUpdatedFired && isProfileUpdatedFired ? (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            ) : (
              "Update Profile Image"
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
