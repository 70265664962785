import {
  LOADING_AUTH,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER,
  USER_CATEGORY,
  USER_PATH_BY_CATEGORY,
  DESIRED_GRADE,
  ABOUT_US_ONBOARDING,
  ONBOARD_USER,
  SET_DATA_FROM_LOCAL_STORAGE,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_SUCCESS,
  FORGOT_PASSWORD,
  GET_COUNTRIES,
  GET_STATES_BY_COUNTRY_ID,
  GET_SPONSOR_TYPES,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  LOADING_LOGOUT,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_SUCCESS,
} from "./types";

const initialState = {
  isLoading: false,
  loadingLogout: false,
  isProfileLoading: false,
  user: {},
  userCategory: [],
  userPathByCategory: [],
  desiredGrade: [],
  onboardUserData: {},
  onboardLocalUserData: null,
  aboutUs: [],
  countries: [],
  statesByCountryId: [],
  forgotpwd: {},
  token: "",
  refresh_token: "",
  sponsorTypes: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOGIN_USER:
      return {
        ...state,
        user: action.payLoad.data.data,
        token: action.payLoad.data.loginToken,
      };
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        isProfileLoading: action.payLoad,
      };

    case GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payLoad.data.data,
      };

      case LOADING_LOGOUT:
      return {
        ...state,
        loadingLogout: action.payLoad,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: {},
        token: "",
      };

    case REGISTER_USER:
      return {
        ...state,
        user: {},
      };

    case USER_CATEGORY:
      return {
        ...state,
        userCategory: action.payLoad.data.data,
      };

    case USER_PATH_BY_CATEGORY:
      return {
        ...state,
        userPathByCategory: action.payLoad.data.data,
      };

    case DESIRED_GRADE:
      return {
        ...state,
        desiredGrade: action.payLoad.data.data,
      };

    case ABOUT_US_ONBOARDING:
      return {
        ...state,
        aboutUs: action.payLoad.data.data,
      };

    case ONBOARD_USER:
      return {
        ...state,
        onboardUserData: action.payLoad.data.data,
        // token: action.payLoad.data.loginToken,
      };

    case SET_DATA_FROM_LOCAL_STORAGE:
      return {
        ...state,
        onboardLocalUserData: action.payLoad,
      };

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
      };
    case VERIFY_ACCOUNT:
      return {
        ...state,
        user: {},
      };

    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        user: {},
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotpwd: action.payLoad,
      };

    case GET_COUNTRIES:
      return {
        ...state,
        countries: action?.payLoad?.data?.data,
      };

    case GET_STATES_BY_COUNTRY_ID:
      return {
        ...state,
        statesByCountryId: action.payLoad.data.data,
      };

    case GET_SPONSOR_TYPES:
      return {
        ...state,
        sponsorTypes: action.payLoad.data.data,
      };

    default:
      return state;
  }
}
