import * as settingsActions from "./types";
import { TestData } from "../../TestDummy";

const InitialState = {
  passwordUpdated: false,
  isLoading: false,
  candidateSub: [],
  candidateSubLoaded: false,
  successState: null,
  successMsg: "",
  userProfileLoaded: false,
  userProfile: {},
  isProfileUpdated: false,
  profileUpdatedSuccessMsg: "",
  isProfileUpdatedFired: false,
  updateProfileState: 0,
  countries: [],
  countriesLoaded: false,
  isCountryLoading: false,
  statesData: [],
  statesLoaded: false,
  statesLoading: false,
  lgaData: [],
  lgaLoaded: false,
  lgaLoading: false,
  waecSchoolsData: [],
  waecSchoolsLoaded: false,
  waecSchoolsLoading: false,
  darkMode: false,
  learningStatistics: {},
  learningStatisticsLoading: false,
  learningStatisticsLoaded: false,
  resetSuperUserPassword: false,
  logOutSuperUserDevices: {},
};

export function settingsReducer(state = InitialState, action) {
  switch (action.type) {
    case settingsActions.CHANGE_PASSWORD:
      return {
        ...state,
        passwordUpdated: false,
        isLoading: true,
        successState: null,
        successMsg: "",
      };
    case settingsActions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordUpdated: true,
        isLoading: false,
        successState: action.payload?.state,
        successMsg: action.payload?.msg,
      };
    case settingsActions.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        passwordUpdated: false,
        isLoading: false,
        successState: action.payload?.state,
        successMsg: action.payload?.msg,
      };
    case settingsActions.RESET_CHANGE_PASSWORD:
      return {
        ...state,
        passwordUpdated: false,
        isLoading: false,
        successState: null,
        successMsg: "",
      };

    case settingsActions.FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID:
      return {
        ...state,
        candidateSub: [],
        candidateSubLoaded: false,
      };
    case settingsActions.FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_SUCCESS:
      return {
        ...state,
        candidateSub: action.payload,
        candidateSubLoaded: false,
      };
    case settingsActions.FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_FAILURE:
      return {
        ...state,
        candidateSub: action.payload,
        candidateSubLoaded: false,
      };

    case settingsActions.FETCH_USER_PROFILE_DETAILS:
      return {
        ...state,
        userProfileLoaded: false,
        userProfile: {},
      };

    case settingsActions.FETCH_USER_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        userProfileLoaded: true,
        userProfile: action.payload,
      };

    case settingsActions.FETCH_USER_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        userProfileLoaded: false,
        userProfile: {},
      };
    case settingsActions.UPDATE_USER_PROFILE:
      return {
        ...state,
        isProfileUpdated: false,
        profileUpdatedSuccessMsg: "",
        isProfileUpdatedFired: true,
      };
    case settingsActions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isProfileUpdated: true,
        profileUpdatedSuccessMsg: action.payload?.msg,
        isProfileUpdatedFired: false,
        updateProfileState: action.payload?.state,
      };
    case settingsActions.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        isProfileUpdated: false,
        profileUpdatedSuccessMsg: action.payload?.msg,
        isProfileUpdatedFired: false,
        updateProfileState: action.payload?.state,
      };
    case settingsActions.RESET_UPDATE_USER_PROFILE:
      return {
        ...state,
        isProfileUpdated: false,
        profileUpdatedSuccessMsg: "",
        isProfileUpdatedFired: false,
        updateProfileState: 0,
      };
    case settingsActions.GET_COUNTRIES:
      return {
        ...state,
        countries: [],
        countriesLoaded: false,
        isCountryLoading: true,
      };
    case settingsActions.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        countriesLoaded: true,
        isCountryLoading: false,
      };
    case settingsActions.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        countries: [],
        countriesLoaded: false,
        isCountryLoading: false,
      };
    case settingsActions.GET_STATES_BY_COUNTRY_ID:
      return {
        ...state,
        statesData: [],
        statesLoaded: false,
        statesLoading: true,
      };
    case settingsActions.GET_STATES_BY_COUNTRY_ID_SUCCESS:
      return {
        ...state,
        statesData: action.payload,
        statesLoaded: true,
        statesLoading: false,
      };
    case settingsActions.GET_STATES_BY_COUNTRY_ID_FAILURE:
      return {
        ...state,
        statesData: [],
        statesLoaded: false,
        statesLoading: false,
      };
    case settingsActions.GET_LGA_BY_STATE_ID:
      return {
        ...state,
        lgaData: [],
        lgaLoaded: false,
        lgaLoading: true,
      };
    case settingsActions.GET_LGA_BY_STATE_ID_SUCCESS:
      return {
        ...state,
        lgaData: action.payload,
        lgaLoaded: true,
        lgaLoading: false,
      };
    case settingsActions.GET_LGA_BY_STATE_ID_FAILURE:
      return {
        ...state,
        lgaData: [],
        lgaLoaded: false,
        lgaLoading: false,
      };
    case settingsActions.GET_WAEC_APPROVED_SCHOOLS:
      return {
        ...state,
        waecSchoolsData: [],
        waecSchoolsLoaded: false,
        waecSchoolsLoading: true,
      };
    case settingsActions.GET_WAEC_APPROVED_SCHOOLS_SUCCESS:
      return {
        ...state,
        waecSchoolsData: action.payload,
        waecSchoolsLoaded: true,
        waecSchoolsLoading: false,
      };
    case settingsActions.GET_WAEC_APPROVED_SCHOOLS_FAILURE:
      return {
        ...state,
        waecSchoolsData: [],
        waecSchoolsLoaded: false,
        waecSchoolsLoading: false,
      };
    case settingsActions.UPDATE_THEME:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case settingsActions.FETCH_PROFILE_LEARNING_STATS:
      return {
        ...state,
        learningStatistics: {},
        learningStatisticsLoading: true,
        learningStatisticsLoaded: false,
      };
    case settingsActions.FETCH_PROFILE_LEARNING_STATS_SUCCESS:
      return {
        ...state,
        learningStatistics: action.payload,
        learningStatisticsLoading: false,
        learningStatisticsLoaded: true,
      };
    case settingsActions.FETCH_PROFILE_LEARNING_STATS_FAILURE:
      return {
        ...state,
        learningStatistics: {},
        learningStatisticsLoading: false,
        learningStatisticsLoaded: false,
      };
    case settingsActions.RESET_PASSWORD_SUPER_SCHOOL_USER:
      return {
        ...state,
        resetSuperUserPassword: false,
        isLoading: true,
        successState: null,
        successMsg: "",
      };
    case settingsActions.RESET_PASSWORD_SUPER_SCHOOL_USER_SUCCESS:
      return {
        ...state,
        resetSuperUserPassword: false,
        isLoading: true,
        successState: null,
        successMsg: "",
      };
    case settingsActions.RESET_PASSWORD_SUPER_SCHOOL_USER_FAILURE:
      return {
        ...state,
        resetSuperUserPassword: false,
        isLoading: true,
        successState: null,
        successMsg: "",
      };
    case settingsActions.LOGOUT_SUPER_USER:
      return {
        ...state,
        logOutSuperUserDevices: {},
        token: "",
      };
    case settingsActions.LOGGING_OUT_SUPER_USER:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    default:
      return state;
  }
}
