import { Box, CircularProgress, Divider, IconButton, RadioGroup, Stack, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { OptionRadio } from './Options';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import FlagIcon from '@mui/icons-material/Flag';
import useTest from '../../hooks/useTest';
import { SectionDialog } from "./SectionDialog"
import { useDispatch } from 'react-redux';
import { RTEViewer } from '../RTEViewer';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow placement={"right"} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.brandPrimary.light,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#525FB8",
        color: "#fff",
        // color: theme.palette.brandPrimary.main,
        // boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}));

const SingleQuestionInterface = ({ children }) => {

    const dispatch = useDispatch()
    const { currentQuestion, questions } = useTest()


    // const [flagged, setFlagged] = useState(questions[currentQuestion].flagged)

    const handleFlag = () => {
        if (!questions[currentQuestion]?.flagged) {
            dispatch({
                type: "FLAG_QUESTION",
                payload: questions[currentQuestion]?.id
            })
        } else {
            dispatch({
                type: "UNFLAG_QUESTION",
                payload: questions[currentQuestion]?.id
            })
        }
    }

    const selectOption = (optionId) => {
        dispatch({
            type: "SET_SELECTED_OPTION",
            payload: {
                optionId: optionId,
                questionId: questions[currentQuestion]?.examQuestionId
            }
        })
    }

    return (
        <>
            <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 6 }} bgcolor={"#eeeeee3d"} maxHeight={{ md: "79vh" }} minHeight={{ md: "79vh" }} sx={{ overflowY: "scroll" }}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant='h6'>
                        {questions[currentQuestion]?.questionNumber}
                    </Typography>

                    <Stack direction={"row"}>
                        <LightTooltip title={!questions[currentQuestion]?.flagged ? "Flag question" : "Unflag question"} arrow placement='bottom' color='brandPrimary'>
                            <IconButton onClick={() => handleFlag()} aria-label="flag question" color={questions[currentQuestion]?.flagged ? "error" : "danger"}>
                                {/* <EmojiFlagsIcon /> */}
                                {questions[currentQuestion]?.flagged ? <FlagIcon /> : <EmojiFlagsIcon />}
                            </IconButton>
                        </LightTooltip>
                        {children}
                    </Stack>
                </Stack>
                <Divider sx={{ my: 1 }} />
                {questions[currentQuestion]?.question ?
                    <>

                        <Box display={"flex"} flexDirection={"column"}>
                            <SectionDialog hidden={questions[currentQuestion].sectionId === null} />
                            <Typography variant='body1' sx={{ flex: 1 }} mt={2}>{questions[currentQuestion]?.question && <RTEViewer value={questions[currentQuestion]?.question} />}</Typography>

                        </Box>
                        <RadioGroup
                            aria-labelledby="demo-customized-radios"
                            name="questionOptions"
                            sx={{
                                minWidth: { xs: "100%", md: "45%" },
                                mx: { xs: "auto", md: 0 },
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: 2
                            }}
                        >
                            {questions[currentQuestion]?.options?.map((option, index) => (<OptionRadio key={index} onClick={() => selectOption(option?.optionNo)} selected={option.selected}
                                // value={option.option.replace(/<[^>]+>|&nbsp;/g, "")}
                                value={<div className='flex items-center'>{String.fromCharCode(65 + index)}. <RTEViewer value={option.option} /></div>}
                            // value={`${String.fromCharCode(65 + index)}. ${option.option.replace(/<[^>]+>|&nbsp;/g, "")}`}
                            />))}
                        </RadioGroup>
                    </> :
                    <div className="h-[62vh] w-full flex justify-center items-center">
                        <CircularProgress color='brandPrimary' />
                    </div>
                }

            </Box>

        </>
    )
}

export default SingleQuestionInterface