import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import AdminDashboardLayout from "./layouts/AdminDashboardLayout";
import SchoolDashboardLayout from "./layouts/SchoolDashboardLayout";
import { Outlet } from "react-router-dom/dist";
import Onboarding from "./pages/auth/onboarding";
import Home from "./pages/Home";
import StudentHome from "./pages/student";
import AdminHome from "./pages/admin";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import AdminLogin from "./pages/auth/AdminLogin";
import ProtectedRoute from "./ProtectedRoute";
import AdminProtectedRoute from "./AdminProtectedRoute";
import TestHome from "./pages/student/learning/test";
import ContentUpload from "./pages/admin/contentupload/ContentUpload";
import ContentUploadExam from "./pages/admin/contentupload/ContentUploadExam";
import ContentUploadExamSetUp from "./pages/admin/contentupload/contentUploadExamSetup";
import { MainTestView } from "./components/Test";
import TestSubject from "./pages/student/learning/test/TestSubject";
import TestDashboard from "./pages/student/learning/test/TestDashboard";
import Study from "./pages/student/learning/study/study";
import Permisssion from "./pages/admin/permissions/Permisssion";
import ManageSession from "./pages/admin/ManageSession/session";
import ReviewTest from "./pages/student/learning/test/ReviewTest";
import VerifyNewUser from "./pages/auth/VerifyAccount";
import Pricing from "./pages/Pricing";
import UserMetrics from "./pages/admin/usermetrics/usermetrics";
import Payment from "./pages/student/payment/Payment";
import Settings from "./pages/student/settings/Settings";
import TempDashboard from "./pages/student/Temp";
import Achievements from "./pages/student/achievements/Achievements";
import Leaderboard from "./pages/student/leaderboard";
import SchoolDashboard from "./pages/school";
import SchoolManageStudents from "./pages/school/managestudents";
import SchoolPayment from "./pages/school/payment";
import SponsorDashboardLayout from "./layouts/SponsorDashboardLayout";
import SponsorDashboard from "./pages/sponsor";
import SponsorManageStudents from "./pages/sponsor/managestudents";
import SponsorManageSchools from "./pages/sponsor/manageschools";
import SponsorPayment from "./pages/sponsor/payment";
import ResetPassword from "./pages/auth/ResetPassword";
import PaymentStatus from "./pages/student/payment/PaymentStatus";
import SponsorSettings from "./pages/sponsor/settings/Settings";
import SchoolSettings from "./pages/school/settings/Settings";
import ManageAmount from "./pages/admin/manageAmount";
import TransactionLog from "./pages/admin/transactionLogs";
import AuditTrail from "./pages/admin/AuditTrail";
import WAEC from "./pages/WAEC";
import Privacy from "./pages/Privacy";
import AdminSettings from "./pages/admin/settings/Settings";
import AdminForgotPassword from "./pages/auth/AdminForgotPassword";
import AdminResetPassword from "./pages/auth/AdminResetPassword";
import ContentUploadTopicQuestions from "./pages/admin/contentupload/ContentUploadTopicQuestions";
import WeeklyTrivia from "./components/Modal/WeeklyTrivia/WeeklyTrivia";
import Gamification from "./pages/admin/gamification";
import ResendMail from "./pages/auth/ResendMail";
import Revenue from "./pages/admin/Revenue";
//import Role from './pages/admin/permissions/Role';

export default () => (
  <BrowserRouter>
    <Routes>
      {/* add routes not needing authentication */}
      <Route path="/VerifyAccount" exact element={<VerifyNewUser />} />
      <Route
        exact
        path="auth"
        element={
          <AuthLayout>
            <Outlet />{" "}
          </AuthLayout>
        }
      >
        <Route path="login" exact element={<Login />} />
        <Route path="signup" exact element={<Signup />} />
        <Route path="resetPassword" exact element={<ResetPassword />} />

        <Route path="forgot-password" exact element={<ForgotPassword />} />
        <Route path="resendmail" exact element={<ResendMail />} />
        <Route path="/auth" element={<Navigate to="/auth/login" replace />} />
      </Route>
      <Route path="/onboarding" exact element={<Onboarding />} />
      <Route path="/adminlogin" exact element={<AdminLogin />} />
      <Route
        path="/adminForgotPassword"
        exact
        element={<AdminForgotPassword />}
      />
      <Route
        path="/adminResetPassword"
        exact
        element={<AdminResetPassword />}
      />
      <Route path="/home" exact element={<Home />} />
      <Route path="pricing" exact element={<Pricing />} />
      <Route path="WAEC" exact element={<WAEC />} />
      <Route path="/" exact element={<Home />} />
      <Route path="/privacy-policy" exact element={<Privacy />} />
      <Route path="*" element={<Navigate to="/home" replace />} />

      {/* Add routes needing authentication */}
      <Route
        path="dashboard"
        element={
          <DashboardLayout>
            <ProtectedRoute />
          </DashboardLayout>
        }
      >
        <Route exact path="home" element={<StudentHome />} />
        <Route exact path="Dashboard1" element={<TempDashboard />} />
        <Route path="learning">
          <Route exact path="study" element={<Study />} />
          <Route path="test" element={<TestSubject />} />
          <Route path="subjects" element={<TestSubject />} />
          {/* <Route path="mockTest" element={<TestStartPage />} /> */}
          <Route path="mockTest" element={<TestHome />} />
          <Route path="reviewtest" element={<ReviewTest />} />
        </Route>
        <Route
          path="/dashboard"
          element={<Navigate to="/dashboard/home" replace />}
        />
        <Route path="settings" element={<Settings />} />
        <Route path="achievements" element={<Achievements />} />
        <Route path="leaderboard" element={<Leaderboard />} />
      </Route>
      {/* <Route path="mockTest" element={<TestStartPage />} /> */}
      <Route path="test" element={<ProtectedRoute />}>
        <Route path="start" element={<MainTestView />} />
        {/* <Route path="review" element={<ReviewTest />} /> */}
      </Route>

      {/* Route for the payment status page */}
      <Route path="payment" element={<ProtectedRoute />}>
        <Route path="makePayment" element={<Payment />} />
        <Route path="paymentstatus" element={<PaymentStatus />} />
      </Route>

      <Route
        path="school"
        element={
          <SchoolDashboardLayout>
            <ProtectedRoute />
          </SchoolDashboardLayout>
        }
      >
        <Route exact path="dashboard" element={<SchoolDashboard />} />
        <Route exact path="students" element={<SchoolManageStudents />} />
        <Route exact path="payment" element={<SchoolPayment />} />
        <Route path="settings" element={<SchoolSettings />} />
      </Route>

      <Route
        path="sponsor"
        element={
          <SponsorDashboardLayout>
            <ProtectedRoute />
          </SponsorDashboardLayout>
        }
      >
        <Route exact path="dashboard" element={<SponsorDashboard />} />
        <Route exact path="students" element={<SponsorManageStudents />} />
        <Route exact path="schools" element={<SponsorManageSchools />} />
        <Route exact path="payment" element={<SponsorPayment />} />
        <Route path="settings" element={<SponsorSettings />} />
      </Route>

      <Route
        path="admin"
        element={
          <AdminDashboardLayout>
            <AdminProtectedRoute />
          </AdminDashboardLayout>
        }
      >
        <Route exact path="dashboard" element={<AdminHome />} />
        <Route path="contentupload">
          <Route exact path="" element={<ContentUpload />} />
          <Route exact path="exam/:id" element={<ContentUploadExam />} />
          <Route exact path="examsetup" element={<ContentUploadExamSetUp />} />
          <Route
            exact
            path="subject/topic/:id"
            element={<ContentUploadTopicQuestions />}
          />
        </Route>

        <Route path="permissions">
          <Route path="" element={<Permisssion />} />
        </Route>
        <Route path="gamifications">
          <Route path="" element={<Gamification />} />
        </Route>
        <Route path="managesession">
          <Route path="" element={<ManageSession />} />
        </Route>
        <Route path="manageamount">
          <Route path="" element={<ManageAmount />} />
        </Route>
        <Route path="transactionlogs">
          <Route path="" element={<TransactionLog />} />
        </Route>
        <Route path="revenue">
          <Route path="" element={<Revenue />} />
        </Route>

        <Route path="usermetrics">
          <Route path="" element={<UserMetrics />} />
        </Route>
        <Route path="audittrail">
          <Route path="" element={<AuditTrail />} />
        </Route>
        <Route path="settings">
          <Route path="" element={<AdminSettings />} />
        </Route>

        <Route path="/admin" element={<Navigate to="/admin/home" replace />} />
        <Route
          path="/admin"
          element={<Navigate to="/admin/dashboard" replace />}
        />
      </Route>
    </Routes>
  </BrowserRouter>
);
