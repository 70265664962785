import Axios from "../../connection/defaultClient";
import { persistLastLogin, destroyLastLogin } from "../../helpers/session";
import {
  LOADING_AUTHE,
  LOGIN_ADMIN_USER,
  LOGOUT_ADMIN_USER,
  LOADING_ADMIN,
  LOADING_ADMIN_USER,
  LOADING_UPDATE_ADMIN,
  LOADING_UPDATE_ADMIN_USER,
  LOADING_ADMIN_ERROR,
} from "./types";

export const loginAdminUserAction = (payLoad, onFail, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTHE, payLoad: true });
    Axios.post(`/Access/AdminLogin`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTHE, payLoad: false });
        dispatch({ type: LOGIN_ADMIN_USER, payLoad: res });
        if (res.data.state == 1) {
          onSuccess(res);
        } else {
          onFail(res);
        }
        persistLastLogin(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTHE, payLoad: false });
        // onFail(error);
      });
  };
};

export const logoutAdminUserAction = (
  payLoad,
  onSuccess = () => {},
  onFail = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTHE, payLoad: true });
    Axios.post(`/Access/SignOut?Token=${payLoad}`)
      .then((res) => {
        dispatch({ type: LOADING_AUTHE, payLoad: false });
        dispatch({ type: LOGOUT_ADMIN_USER, payLoad: {} });
        if (res?.data?.state == 1) {
          onSuccess(res);
          destroyLastLogin();
        }

        // window.location.reload();
      })
      .catch((error) => {
        debugger;
        dispatch({ type: LOADING_AUTHE, payLoad: false });
        onFail(error);
      });
  };
};

export const manageAdminByProfileId = (profileId) => {
  return (dispatch) => {
    dispatch({ type: LOADING_ADMIN, payLoad: profileId });
    Axios.get(`/ManageAdmin/LoadAdmin/${profileId}`)
      .then((res) => {
        // dispatch({ type: LOADING_ADMIN, payLoad: false });
        dispatch({ type: LOADING_ADMIN_USER, payLoad: res.data });
        // onSuccess(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_ADMIN_ERROR, payLoad: false });
        // onFail(error);
      });
  };
};
export const updateAdminByProfileId = (
  body,
  onSuccess = () => {}
  // onFail = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: LOADING_UPDATE_ADMIN, payLoad: true });
    Axios.post(`/AdminProfile/UpdateProfile`, body)
      .then((res) => {
        // dispatch({ type: LOADING_ADMIN, payLoad: false });
        dispatch({ type: LOADING_UPDATE_ADMIN_USER, payLoad: res.data });
        onSuccess(res.data);
      })
      .catch((error) => {
        dispatch({ type: LOADING_UPDATE_ADMIN, payLoad: false });
        // onFail(error);
      });
  };
};
