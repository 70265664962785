import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ManageUserModal from "./manageUserModal";
import UserMetricsModal from "./userMetricsModal";
import DeleteUserModal from "./deleteUserModal";

export default function UserMetricsActionButton({
  profileId,
  filters,
  setToastState,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div className="">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <div className="relative">
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="absolute p-3 shadow-lg right-1 top-2"
        >
          <ManageUserModal
            profileId={profileId}
            handleClick={handleClick}
            filters={filters}
            setToastState={setToastState}
          />
          <UserMetricsModal />
          {/* <DeleteUserModal /> */}
        </Menu>
      </div>
    </div>
  );
}
