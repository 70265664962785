import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Logo from "../../assets/images/logo.svg";
import adminLoginImg from "../../assets/images/adminLoginImg.png";
import LoginForm from "../../components/AdminLogin/LoginForm";
import customTheme from "../../components/AuthReuseables/customTheme";
import GlobalThemeProvider from "../../GlobalThemeProvider";

const RootContainer = styled("div")({
  minHeight: "100vh",
  display: "flex",
  "@media (max-width: 600px)": {
    flexDirection: "column-reverse",
    alignItems: "center",
  },
});

const BackgroundImageContainer = styled("div")(({ theme }) => ({
  flex: "1",
  background: `url(${Logo}) no-repeat top left, url('${adminLoginImg}') center/cover`,
  [theme.breakpoints.down("sm")]: {
    background: "none",
  },
}));

const LoginFormContainer = styled("div")(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const CustomBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(8),
    background: "none",
    boxShadow: "none",
  },
}));

const LogoImage = styled("img")(({ theme }) => ({
  // maxWidth: "40%",
  margin: "0 auto",
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const AdminLogin = () => {
  return (
    <GlobalThemeProvider>
      <RootContainer>
        <BackgroundImageContainer />
        <LoginFormContainer>
          <Container component="main" maxWidth="xs">
            <CustomBox elevation={3}>
              <LogoImage src={Logo} alt="Logo" className="object-contain" />
              <Typography variant="h6" component="h1" fontSize={"18px"}>
                Login to Admin Management Module
              </Typography>
              <LoginForm />
            </CustomBox>
          </Container>
        </LoginFormContainer>
      </RootContainer>
    </GlobalThemeProvider>
  );
};

export default AdminLogin;
