import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Button,
  InputBase,
  Paper,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PageviewIcon from "@mui/icons-material/Pageview";
import { pink } from "@mui/material/colors";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import FilterButton from "../../../../components/TestReview/FilterButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TestScoreModal from "../../../../components/Modal/TestScoreModal";
import useTest from "../../../../hooks/useTest";
import useUser from "../../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import SearchBox from "../../../../components/Test/SearchBox";
import { TableFooter } from "../../../../components/tables/TableFooter";
import { isEmpty, pagination } from "../../../../helpers/helper";
import { RTEViewer } from "../../../../components/RTEViewer";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #BBB",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#BBB"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));


const optionMapping = (answer) => {
  if (answer.includes(1)) {
    return "a"
  } else if (answer.includes(2)) {
    return "b"
  } else if (answer.includes(3)) {
    return "c"
  } else if (answer.includes(4)) {
    return "d"
  } else {
    return "e"
  }
}


const optionMappings = (selectedAnswer) => {
  if (selectedAnswer === 0) {
    return " No Answer Selected"
  } else if (selectedAnswer === 1) {
    return "a"
  } else if (selectedAnswer === 2) {
    return "b"
  } else if (selectedAnswer === 3) {
    return "c"
  } else if (selectedAnswer === 4) {
    return "d"
  } else
    return "e"
}


const ReviewTest = () => {
  const navigate = useNavigate()
  const anchorRef = useRef(null);
  const [open, setOpen] = useState({});
  const [arrowOpen, setArrowOpen] = useState({});
  const [scoreResultModal, setScoreResultModal] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState('');
  const { examResults, totalScore, testName } = useTest()
  const { userInfo: { firstName } } = useUser()
  const [filteredResults, setFilteredResults] = React.useState([])
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);



  React.useEffect(() => {
    setTableData(pagination(filteredResults, 10, currentPage));

    // eslint-disable-next-line
  }, [currentPage, filteredResults]);





  useEffect(() => {
    setFilteredResults(filteredResults)
  }, [filteredResults])
  useEffect(() => {
    if (search === '') {
      setSearch('')
    }
  }, [])

  const handleToggle = (index) => {
    setOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    setArrowOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleCloseScoreResult = () => {
    setScoreResultModal(false)
  }

  useEffect(() => {
    const passed = examResults.filter(result => result.isCorrect === true);
    const failed = examResults.filter(result => result.isCorrect === false);
    switch (selectedFilterOption) {
      case "Incorrect":
        setFilteredResults(failed)
        break
      case "Correct":
        setFilteredResults(passed)
        break
      default:
        setFilteredResults(examResults);

    }

    setCurrentPage(1);
  }, [selectedFilterOption, examResults])

  useEffect(() => {
    setScoreResultModal(true)
  }, [])


  const handleSearch = (value) => {
    setSearch(value);

    if (value === "") {
      setFilteredResults(examResults);
    } else {
      setSearch(value);
      setFilteredResults(examResults?.filter(exam => exam?.question?.toLowerCase()?.includes(value)))
    }
  }


  return (
    <>{examResults ? <>
      <Container>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          {/* <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography variant="h4" component="h1">
              Mathematics Assessment
              {testName}
            </Typography>
            <Avatar sx={{ bgcolor: pink[500] }}>
              <PageviewIcon />
            </Avatar>
          </Stack> */}
          <Typography variant="subtitle1">Your score: {totalScore}/{examResults?.length}</Typography>
        </Stack>
        <Paper
          variant="outlined"
          sx={{
            padding: 2,
            borderColor: "#40499D",
            borderRadius: 5,
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="body1">
            Hello {firstName}, you scored a total of{" "}
            <span style={{ fontWeight: 600 }}>{totalScore}/{examResults?.length}</span>. Below are the test
            questions and answers.
          </Typography>
        </Paper>
        <Box>
          <Paper sx={{ padding: 3, borderRadius: 5, mt: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>All Questions and Answers</Typography>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search for questions…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => handleSearch(e.target.value)}
                  value={search}
                />
              </Search>
              {/* <SearchBox
              placeholder={"questions"}
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            /> */}
              <FilterButton selectedFilterOption={selectedFilterOption} setSelectedFilterOption={setSelectedFilterOption} />
            </Stack>
            <Box>
              {tableData?.data?.map((question, index) => (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid #BBB",
                    py: 3,
                    px: 1,
                    borderRadius: 3,
                    mt: 3,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                      <Typography>
                        {question.questionNumber}. <RTEViewer value={question.question} />
                      </Typography>
                      <Typography sx={{ pl: 3, pt: 1 }}>
                        <div
                        // style={{ display: "flex" }}
                        >
                          {question.choices.map((option, optionIndex) => (
                            <div
                              key={optionIndex}
                              style={{ marginRight: "10px" }}
                            >
                              {String.fromCharCode(65 + optionIndex)}. {option.option.replace(/<[^>]+>|&nbsp;/g, "")}
                            </div>
                          ))}
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      {question.isCorrect ? <Typography
                        sx={{
                          background: "#CEFFEE",
                          color: "#035545",
                          padding: 1,
                          borderRadius: 5,
                          fontSize: "12px",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        you got this right
                      </Typography> :
                        <Typography
                          sx={{
                            background: "#FFE4E4",
                            color: "#E43334",
                            padding: 1,
                            borderRadius: 5,
                            fontSize: "12px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          you got this wrong
                        </Typography>}
                    </Grid>
                    <Grid item xs={6} sm={1} style={{ textAlign: "right" }}>
                      <IconButton onClick={() => handleToggle(index)}>
                        {open[index] ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  {open[index] && (
                    <Box
                      sx={{
                        bgcolor: "#F4F5FF",
                        borderRadius: "10px",
                        p: 2,
                        mt: 2,
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", pb: 0.5 }}>
                        ANSWER
                      </Typography>
                      <div
                        style={{
                          borderLeft: "2px #0FA379 solid",
                          paddingLeft: "10px",
                        }}
                      >
                        {/* <Hstack>? */}
                        <Typography sx={{ pt: 0.5, pb: 0.5 }}>
                          Correct Answer: {" "}
                          <span style={{ fontWeight: "600", textTransform: "uppercase" }}>
                            {optionMapping(question.answer)}

                          </span>
                        </Typography>
                        <Typography sx={{ pt: 0.5, pb: 0.5 }}>
                          Selected Answer:{" "}
                          <span style={{ fontWeight: "600", textTransform: "uppercase" }}>
                            {optionMappings(question.selectedAnswer)}
                          </span>
                        </Typography>
                        {/* </Hstack> */}

                      </div>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>


            <div className="mt-3">
              <TableFooter
                empty={isEmpty(tableData.data)}
                // loading={invoice.isLoading}
                pageNumber={currentPage}
                totalPages={tableData.totalPages}
                prevOnClick={() => setCurrentPage(tableData.previousPage)}
                nextOnClick={() => setCurrentPage(tableData.nextPage)}
                thereIsPreviousPage={tableData.thereIsPreviousPage}
                thereIsNextPage={tableData.thereIsNextPage}
              />
            </div>
            <Button sx={{ width: "100%", mt: 3 }} variant="contained" color="brandPrimary" onClick={() => navigate("/dashboard/learning/subjects")}>Return to Test Home</Button>
          </Paper>

        </Box>
      </Container>
      <TestScoreModal examResult={{ examResults, totalScore }} open={scoreResultModal} handleClose={handleCloseScoreResult} />
    </> :
      <div className="h-[62vh] w-full flex justify-center items-center">
        <CircularProgress color='brandPrimary' />
      </div>
    }
    </>
  );
};

export default ReviewTest;
