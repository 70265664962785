import {
  LOADING_AUTHE,
  LOGIN_ADMIN_USER,
  LOGOUT_ADMIN_USER,
  LOADING_ADMIN,
  LOADING_ADMIN_USER,
  LOADING_UPDATE_ADMIN_USER,
  LOADING_UPDATE_ADMIN,
  LOADING_ADMIN_ERROR,
} from "./types";

const initialState = {
  isLoading: false,
  user: {},
  token: "",
  refresh_token: "",
  adminUserByProfileId: {},
  adminLoading: false,
  updateAdminProfile: {},
  updateLoading: false,
};

export default function adminAuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_AUTHE:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case LOGIN_ADMIN_USER:
      return {
        ...state,
        user: action.payLoad.data.data,
        token: action.payLoad.data.loginToken,
      };

    case LOGOUT_ADMIN_USER:
      return {
        ...state,
        user: {},
        token: "",
      };
    case LOADING_ADMIN:
      return {
        ...state,
        adminUserByProfileId: {},
        adminLoading: false,
      };
    case LOADING_ADMIN_USER:
      return {
        ...state,
        adminUserByProfileId: action.payLoad.data,
        adminLoading: true,
      };
    case LOADING_UPDATE_ADMIN:
      return {
        ...state,
        updateLoading: false,
        updateAdminProfile: {},
      };
    case LOADING_UPDATE_ADMIN_USER:
      return {
        ...state,
        updateLoading: false,
        updateAdminProfile: action.payLoad.data,
      };
    // case LOADING_ADMIN_ERROR:
    //   return {
    //     ...state,
    //     updateLoading: false,
    //     updateAdminProfile: {},
    //   };

    default:
      return state;
  }
}
