export const Color = [
  "yellow",
  "red",
  "green",
  "blue",
  "magenta",
  "purple",
  "#9d4049",
  "#e715bd",
  "#e81515",
  "#eb7575",
  "#eef20a",
  "#c9c9bb",
  "#e0391f",
  "#c9c9bb",
  "#1a0201",
  "#0a0404",
  "#964e4b",
  "#402c2b",
  "#0f0f0f",
  "#f25816",
  "#61260d",
  //  "#ecedf5",
  "#FFEFE2",
  "#EFDACA",
  "#FBEFFD",
  "#E9F8FF",
  "#E7EBD3",
];
export const SvgColor = {
  info: "#40499d",
  error: "#c81912",
  success: "#055816",
  warning: "#c37602",
};
export const SvgTitle = {
  info: "Hi there",
  error: "Error!",
  success: "Success",
  warning: "Warning",
};
// export const SvgTitle = {
//   info: {
//     title: "Hi there",
//     severity: "info",
//   },
//   error: {
//     title: "Error!",
//     severity: "error",
//   },
//   success: {
//     severity: "sucess",
//     title: "Success!",
//   },
//   warning: {
//     severity: "warning",
//     title: "Warning!",
//   },
// };
