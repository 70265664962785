import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, Paper, Button, IconButton, createMuiTheme, Dialog, DialogContent, DialogActions } from "@mui/material";
import MUIDataTable from "mui-datatables"
import { Edit, Delete } from "@mui/icons-material";
import QuestionModal from "./QuestionModal";
import { DeleteQuestionSection, getQuestionSectionByExamId } from "../../redux/contentUpload/contentUploadActions";
import { useDispatch } from "react-redux";
import { useNotifications } from "../notifications/notifications";
import useAdminUser from "../../hooks/useAdminUser";
import useContentUpload from "../../hooks/useContentUpload";
import { RTEViewer } from "../RTEViewer";

const QuestionModalTable = ({ open, handleClose, isEditMode, handleEditOpen, exam, setIsEditMode, setOpen, questionSection, input }) => {

    const [sectionId, setSectionId] = useState(null);
    const dispatch = useDispatch();
    const [deleteSectionModal, setDeleteSectionModal] = useState(false);
    const { userInfo, } = useAdminUser();
    const [ToastState, setToastState] = useNotifications();
    // const { questionSection } = useContentUpload()


    useEffect(() => {
        dispatch(getQuestionSectionByExamId(exam ? exam?.examId : input?.examId))
    }, [])



    const handleEditModal = (value) => {
        handleEditOpen()
        setSectionId(value)
        setDeleteSectionModal(false);
    }

    const onSuccess = () => {
        setToastState({ isOpen: true, severity: "success", message: " Question Section deleted successfully" });
        // setLoadSubject(true)
        dispatch(getQuestionSectionByExamId(exam?.examId))
    }


    const handleDeleteSection = (value) => {
        setSectionId(value)
        setDeleteSectionModal(false);

        const data = {
            ProfileId: userInfo?.data?.profileId,
            SectionId: sectionId
        }
        dispatch(DeleteQuestionSection(data, (res) => onSuccess(res)));

    }

    const columns = [
        {
            name: "S/N",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return <span>{tableMeta.rowIndex + 1}</span>;
                },
            },
        },
        {
            name: "sectionName",
            label: "Name",
            options: {
                filter: true,
                sort: true,

            },

        },
        {
            name: "sectionInstruction",
            label: "Instructions",
            options: {

                filter: true,
                sort: false,
                customBodyRender: (value) => (
                    <div><RTEViewer value={value} /></div>
                ),
            },
        },


        {
            name: "sectionId",
            label: "ACTION",
            options: {
                filter: false,
                sort: true,

                customBodyRender: (value, tableMeta) => {
                    return (
                        <>
                            <IconButton onClick={() => { handleEditModal(value) }}>
                                <Edit style={{ fontSize: '17px', tooltip: "Edit", color: "#40499d" }}

                                />
                            </IconButton>
                            <IconButton onClick={() => {
                                setSectionId(value)
                                setDeleteSectionModal(true)
                            }}>
                                <Delete style={{ fontSize: '17px', tooltip: "Delete Section", color: "red" }} />
                            </IconButton>
                        </>

                    )
                },
            },
        },
    ];

    const options = {
        filterType: "none",
        responsive: "standard",
        selectableRows: "none",

    };



    return (
        <>

            <Grid
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ mt: 1 }}
                xs={12}
                fontSize={10}
            >
                <MUIDataTable
                    // title={"Sections"}
                    data={questionSection}
                    columns={columns}
                    options={options}
                />

                <QuestionModal
                    open={open}
                    handleClose={handleClose}
                    isEditMode={isEditMode}
                    questionSection={questionSection}
                    selectedSectionId={sectionId}
                    exam={exam}
                    handleEditOpen={handleEditOpen}
                    setIsEditMode={setIsEditMode}
                    setOpen={setOpen}
                    input={input}
                />
            </Grid>

            <Dialog
                open={deleteSectionModal}
                onClose={() => setDeleteSectionModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Paper sx={{ p: 1 }}>
                        <h3 className="e-study-heading-three">Are you sure you want to delete this Question Section?</h3>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <div className="flex items-center justify-between space-x-3 lg:space-x-9">
                        <span className="cursor-pointer e-study-primary" onClick={() => setDeleteModal(false)}>No</span>
                        <button onClick={() => handleDeleteSection()} style={{ backgroundColor: '#FA7E77' }} class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
                            Yes
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            <ToastState />

        </>
    );
};

export default QuestionModalTable;
