
import React, { useEffect, useState } from 'react';

import {

  Box,

  Typography,

  Button,

  Card,

  CardContent,

  Grid,

  Container,
  Paper,
  Divider,
  capitalize,
  Modal,
  Dialog,
  DialogContent,
  DialogActions,

} from '@mui/material';

import LaptopIcon from '@mui/icons-material/Laptop';

import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';

import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import DevicesIcon from '@mui/icons-material/Devices';
import useUser from '../../hooks/useUser';
import PasswordReset from "../Settings/ResetPassword";
import { fetchAllDevices } from '../../redux/school';
import useSchool from '../../hooks/useSchool';
import { useDispatch } from 'react-redux';
import { logoutAdminUserAction } from '../../redux/adminAuth/adminAuthActions';
import { useNotifications } from '../notifications/notifications';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import { SignOutAllDevices } from '../../redux/settings/settingsActions';
import moment from 'moment';
import utc from 'dayjs/plugin/utc';
import dayjs from "dayjs";
dayjs.extend(utc);



// Map of device types to icons

const deviceIcons = {

  'PC': LaptopIcon,

  'Android Tablet': TabletAndroidIcon,

  'Android Phone': PhoneAndroidIcon,

  // Add more mappings as needed

};

const DeviceCard = ({ deviceType, deviceName, time, token, browser, setToastState, email, }) => {
  const IconComponent = deviceIcons[deviceType] || DevicesIcon;
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const [modalSignOut, setModalSignOut] = useState(false);
  // const [formValues, setFormValues] = React.useState({ ...initialFormValues });

  const SchoolID = userInfo?.schoolId;


  const onSuccess = () => {
    setToastState({
      isOpen: true,
      message: "Device Signed Out Successfully",
      severity: "success",
    });
    setModalSignOut(false);
    dispatch(fetchAllDevices(SchoolID));
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      message: res?.msg ?? 'Device failed to sign out',
      severity: "error",
    });
  };


  const handleSingleSignout = () => {

    dispatch(logoutAdminUserAction(token, onSuccess, onFail))
  }




  return (

    <div className='border border-[#DFDFDF] rounded-[8px] bg-[#E8EAFF] p-[16px] bg-opacity-10'>

      <Grid container justifyContent="space-between">

        <Grid item xs={8}>

          <Box display="flex" alignItems="center">

            <IconComponent />

            <Typography variant="subtitle1" component="div" sx={{ ml: 1, textTransform: 'capitalize' }}>


              {deviceName + ' ' + deviceType + ' ' + browser}

            </Typography>

          </Box>

          <Box display="flex" alignItems="center" sx={{ mt: 1 }}>

            <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />

            <Typography variant="body2" color="text.secondary">

              {moment(time)?.utc().format('DD/MM/YYYY HH:mm:ss A')}

            </Typography>

          </Box>
          <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {email}
            </Typography>

          </Box>

        </Grid>

        <Grid item>

          <Button variant="outlined" size="small"

            onClick={() => setModalSignOut(true)}
          >
            Sign Out
          </Button>
        </Grid>
        <Dialog
          open={modalSignOut}
          onClose={() => setModalSignOut(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Paper sx={{ p: 1 }}>
              <h3 className="e-study-heading-three">
                Are you sure you want to delete this device
              </h3>
            </Paper>
          </DialogContent>
          <DialogActions>
            <div className="flex justify-between items-center space-x-3 lg:space-x-9">
              <span
                className="e-study-primary cursor-pointer"
                onClick={() => setModalSignOut(false)}
              >
                No
              </span>
              <button
                onClick={handleSingleSignout}
                style={{ backgroundColor: "#FA7E77" }}
                class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                Yes
              </button>
            </div>
          </DialogActions>
        </Dialog>

      </Grid>
    </div>
  );

};

const Devices = () => {
  const { userInfo } = useUser();
  const [openModal, setOpenModal] = useState(false);
  const { getAllDevices, token } = useSchool();
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useDispatch()
  const [ToastState, setToastState] = useNotifications();
  const [modalSignOutAllDevices, setModalSignOutAllDevices] = useState(false)


  const SchoolID = userInfo?.schoolId;
  useEffect(() => {
    dispatch(fetchAllDevices(SchoolID));
  }, []);


  const handleCloseModal = () => {
    setOpenModal(false);
    setPassword("")
    setNewPassword("");
  }


  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      message: res?.msg ?? "All devices Signed Out Successfully",
      severity: "success",
    });
    setModalSignOutAllDevices(false)
    dispatch(fetchAllDevices(SchoolID));
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      message: res?.msg ?? 'Devices failed to sign out',
      severity: "error",
    });
  };
  const handleSignALlDevices = () => {
    // setModalSignOut(true);
    dispatch(SignOutAllDevices(SchoolID, onSuccess, onFail))
  }
  const devices = [

    { type: 'PC', name: 'PC Chrome - Web Browser', time: '30/07/2024, 14:20 GMT+1' },

    { type: 'PC', name: 'PC Chrome - Web Browser', time: '30/07/2024, 12:20 GMT+1' },

    { type: 'Android Tablet', name: 'Android Tablet Chrome - Web Browser', time: '30/07/2024, 13:20 GMT+1' },

    { type: 'Android Phone', name: 'Android Phone Chrome - Web Browser', time: '30/07/2024, 12:20 GMT+1' },

  ];

  return (
    <>
      <div className='mt-[36px]'>

        <div className='bg-white border border-[#F2F2F2]'>
          <Box sx={{ my: 4 }}>

            <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2, px: 3 }} >

              <Grid item>

                <Typography variant="h6" component="h1" gutterBottom fontWeight={500} fontSize={"14px"} color={"#313131"}>

                  Manage Access and Devices

                </Typography>

              </Grid>

              <Grid item>

                <Button variant="outlined" color="primary" onClick={() => { setOpenModal(true) }}>

                  Reset Password

                </Button>

              </Grid>

            </Grid>

            <Typography sx={{ mb: 4, px: 3 }} fontWeight={400} fontSize={"14px"} color={"#313131"} padding={"4"}>

              Here's a list of devices that are recently using your Super School account. If any look

              unfamiliar, you can sign out of them or reset your password to keep your account secure.

            </Typography>
            <Divider />
            {getAllDevices?.length > 0 ? (
              <>
                <div className='mt-5 grid gap-[25px] lg:grid-cols-2 px-[33px] py-[27px]'>
                  {getAllDevices?.map((device, index) => (
                    <DeviceCard
                      key={index}
                      deviceType={device.platform}
                      deviceName={device?.os}
                      browser={device?.browser}
                      email={device?.email}
                      time={device?.lastAccessDate}
                      token={device?.token}
                      setToastState={setToastState}
                    />
                  ))}
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Button variant="outlined" color="error"
                    onClick={() => { setModalSignOutAllDevices(true) }}>
                    Sign Out of All Devices
                  </Button>
                </Box>
              </>
            )
              :
              (
                <div className='mt-5 flex justify-center items-center gap-4 flex-col w-full p-5 rounded-[10px] mx-8' style={{ height: 'calc(100vh - 400px)' }}>
                  <PhonelinkIcon className='text-[#B4B4B4] h-[80.63px] w-[106.88px]' style={{ height: '80.63px', width: '106.88px' }} />
                  <p className='text-[#919191] text-[14px] md:text-[20px]'>
                    No device is currently logged in
                  </p>
                </div>
              )}
          </Box>
        </div>

        <Dialog
          open={modalSignOutAllDevices}
          onClose={() => setModalSignOutAllDevices(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Paper sx={{ p: 1 }}>
              <h3 className="e-study-heading-three">
                Are you sure you want to delete this device
              </h3>
            </Paper>
          </DialogContent>
          <DialogActions>
            <div className="flex justify-between items-center space-x-3 lg:space-x-9">
              <span
                className="e-study-primary cursor-pointer"
                onClick={() => setModalSignOutAllDevices(false)}
              >
                No
              </span>
              <button
                onClick={handleSignALlDevices}
                style={{ backgroundColor: "#FA7E77" }}
                class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                Yes
              </button>
            </div>
          </DialogActions>
        </Dialog>

      </div>
      <PasswordReset
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setPassword={setPassword}
        setNewPassword={setNewPassword}
        password={password}
        newPassword={newPassword}
        setToastState={setToastState}
      />

      <ToastState />
    </>
  );

};

export default Devices;

