import React, { useState, useEffect } from "react";
import { FilterIcon, PlusIcon } from "../../../assets/icons/Icons";
import { Box, Button, Grid, Typography } from "@mui/material";
import SearchBox from "../../../components/Test/SearchBox";
import CustomizedDialogs from "../../../components/StudentManagement/Dialog";
import SponsorStudentsTable from "./SponsorStudentTable";
import BulkImportDialog from "../../../components/StudentManagement/BulkImportDialog";
import Enroll from "../../../components/StudentManagement/Enroll";
import { useNotifications } from "../../../components/notifications/notifications";
import { useDispatch } from "react-redux";
import useSchool from "../../../hooks/useSchool";
import { fetchGetStudentBySponsorId } from "../../../redux/school";
import useUser from "../../../hooks/useUser";
import BusyOverlay from "../../../components/BusyOverlay";

const SponsorManageStudents = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showFilters, setShowFilters] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { sponsorId } = userInfo;
  const { allStudentBySponorById } = useSchool();
  const [increaseModalSize, setIncreaseModalSize] = useState(false);
  const [addSingleStudent, setAddSingleStudent] = useState(false);
  const [addBulkImport, setAddBulkImport] = useState(false);
  const [bulkActions, setBulkActions] = useState({ isUploaded: false });
  const [ToastState, setToastState] = useNotifications();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(fetchGetStudentBySponsorId(sponsorId));
  }, []);

  // useEffect(() => {
  //   dispatch(fetchSponsorSubscriptions(sponsorId));
  // }, [sponsorId]);

  useEffect(() => {
    // debugger;
    // setFilteredData(
    allStudentBySponorById?.sort(function (a, b) {
      return a.FullName - b.FullName;
    });
    // );
  }, [allStudentBySponorById]);

  useEffect(() => {
    if (searchValue != "") {
      // setFilteredData(
      allStudentBySponorById
        ?.sort(function (a, b) {
          return a.FullName - b.FullName;
        })
        ?.filter((sub) =>
          sub.FullName.toLowerCase()?.includes(searchValue.toLowerCase())
        );
      // );
    } else {
      // setFilteredData(
      allStudentBySponorById?.sort(function (a, b) {
        return a.FullName - b.FullName;
      });
      // );
    }
  }, [searchValue]);

  function handleAddStudent() {
    setAddSingleStudent(true);
    setAddBulkImport(false);
    setIncreaseModalSize(false);
  }

  function handleBulkImport() {
    setAddBulkImport(true);
    setAddSingleStudent(false);
    setOpen(false);
  }
  const handleReduceModalSize = () => {
    setIncreaseModalSize(false);
  };
  const handleOpen = () => {
    setOpen(true);
    setIncreaseModalSize(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAddBulkImport(false);
    setAddSingleStudent(false);
  };
  const modalContent = {
    title: "Enrol Student",
    content: (
      <Enroll
        addSingleStudent={addSingleStudent}
        addBulkImport={addBulkImport}
        handleAddStudent={handleAddStudent}
        handleBulkImport={handleBulkImport}
        closeModal={handleClose}
        setToastState={setToastState}
      />
    ),
  };
  const handleSearch = (value) => {
    setSearchValue(value);

    if (value === "") {
    } else {
    }
  };
  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const fetchOverlay = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const totalStudentSponsored = allStudentBySponorById?.length ?? 0;

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center space-x-4">
          <h2 className="e-study-heading-four e-study-school-black">
            Manage Students
          </h2>
          <span className="e-study-button-lilac rounded-[22px] py-2 px-3">
            {totalStudentSponsored}
          </span>
        </div>
        {/* <p className="e-study-school-black e-study-normal-text-three">
          See the list of all students enrolled in the e-Study application
        </p> */}

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#40499D",
            borderRadius: 2,
            textTransform: "none",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#333a7d",
            },
          }}
          onClick={handleOpen}
          startIcon={<PlusIcon />}
        >
          Enrol Student(s)
        </Button>
      </div>

      {/* <div className="mt-6">
        <Grid container xs={12} spacing={1}>
          <Grid item xs={8}>
            <SearchBox
              placeholder={"students"}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              searchIconColor={"#1347F5"}
              bg={"rgba(244, 247, 254, 1)"}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "#40499d",
                  backgroundColor: "#F5F6FF",
                  border: "none",
                  padding: "15px 20px",
                  "&:hover": {
                    border: "none",
                  },
                }}
                startIcon={<FilterIcon />}
                endIcon={
                  <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "#EAECFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Box>
                }
              >
                Filter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div> */}

      <div className="overflow-x-hidden">
        <SponsorStudentsTable
          open={open}
          handleClose={handleClose}
          modalContent={modalContent}
          allStudentBySponorById={allStudentBySponorById}
          setToastState={setToastState}
        />
      </div>
      <CustomizedDialogs
        open={open}
        handleClose={handleClose}
        content={modalContent}
        width={"900px"}
        maxWidth={"md"}
      />
      <BulkImportDialog
        open={addBulkImport}
        handleClose={handleClose}
        title={{ right: "Import Student data from Excel" }}
        actions={bulkActions}
        fetchOverlay={fetchOverlay}
      />
      <BusyOverlay busy={loading} />
      <ToastState />
    </div>
  );
};

export default SponsorManageStudents;
