import React from "react";
import {
  Button,
  Typography,
  Box,
  Divider,
  Select,
  FormControlLabel,
  Radio,
  Stack,
  MenuItem,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { createSvgIcon } from "@mui/material/utils";
import { Link, useNavigate } from "react-router-dom";
import { Question_Manager } from "../../assets/images";
import useContentUpload from "../../hooks/useContentUpload";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetExamByExamId } from "../../redux/contentUpload/contentUploadActions";
import { useState } from "react";

const SettingIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15Z"
      stroke="#313131"
      strokeWidth="1.5"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.8041 19.969 5.48558 19.5553 5.35435 19.0697C5.22311 18.5841 5.28988 18.0663 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
      stroke="#313131"
      stroke-miterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Setting"
);
const QuestionMangerIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconsax/Linear/setting3">
      <path
        id="Vector"
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#313131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M15.5701 18.5V14.6M15.5701 7.45V5.5M15.5701 7.45C15.9115 7.45 16.2496 7.51725 16.5651 7.64791C16.8805 7.77858 17.1671 7.97009 17.4086 8.21152C17.65 8.45295 17.8415 8.73958 17.9722 9.05502C18.1028 9.37047 18.1701 9.70856 18.1701 10.05C18.1701 10.3914 18.1028 10.7295 17.9722 11.045C17.8415 11.3604 17.65 11.647 17.4086 11.8885C17.1671 12.1299 16.8805 12.3214 16.5651 12.4521C16.2496 12.5827 15.9115 12.65 15.5701 12.65C14.8805 12.65 14.2192 12.3761 13.7316 11.8885C13.244 11.4009 12.9701 10.7396 12.9701 10.05C12.9701 9.36044 13.244 8.69912 13.7316 8.21152C14.2192 7.72393 14.8805 7.45 15.5701 7.45ZM8.43008 18.5V16.55M8.43008 16.55C8.77152 16.55 9.10961 16.4827 9.42506 16.3521C9.7405 16.2214 10.0271 16.0299 10.2686 15.7885C10.51 15.547 10.7015 15.2604 10.8322 14.945C10.9628 14.6295 11.0301 14.2914 11.0301 13.95C11.0301 13.6086 10.9628 13.2705 10.8322 12.955C10.7015 12.6396 10.51 12.353 10.2686 12.1115C10.0271 11.8701 9.7405 11.6786 9.42506 11.5479C9.10961 11.4173 8.77152 11.35 8.43008 11.35C7.74052 11.35 7.07919 11.6239 6.5916 12.1115C6.10401 12.5991 5.83008 13.2604 5.83008 13.95C5.83008 14.6396 6.10401 15.3009 6.5916 15.7885C7.07919 16.2761 7.74052 16.55 8.43008 16.55ZM8.43008 9.4V5.5"
        stroke="#313131"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>,
  "QuestionManger"
);
const TestHomeIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18.0001V15.0001M10.07 2.82009L3.14002 8.37009C2.36002 8.99009 1.86002 10.3001 2.03002 11.2801L3.36002 19.2401C3.60002 20.6601 4.96002 21.8101 6.40002 21.8101H17.6C19.03 21.8101 20.4 20.6501 20.64 19.2401L21.97 11.2801C22.13 10.3001 21.63 8.99009 20.86 8.37009L13.93 2.83009C12.86 1.97009 11.13 1.97009 10.07 2.82009Z"
      stroke="#313131"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "TestHome"
);
const PerformanceEvaluationIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconsax/Linear/archivebook">
      <path
        id="Vector"
        d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
        stroke="#313131"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M13.25 14H17.5M9 18H17.5M15.5 2V9.86C15.5 10.3 14.98 10.52 14.66 10.23L12.34 8.09C12.248 8.00337 12.1264 7.95513 12 7.95513C11.8736 7.95513 11.752 8.00337 11.66 8.09L9.34 10.23C9.02 10.52 8.5 10.3 8.5 9.86V2H15.5Z"
        stroke="#313131"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>,
  "PerformanceEvaluation"
);

const TestSideBar = ({ input, setInput, exam }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);
  const dispatch = useDispatch();

  const { singleExamData, loadingExam } = useContentUpload();
  const [completion, setCompletion] = useState("");

  useEffect(() => {
    if (exam) {
      dispatch(GetExamByExamId(exam.examId));
    }
  }, []);

  useEffect(() => {
    let completion = "";
    if (!exam) {
      if (input?.examSetupState === 1) {
        completion = 0;
      }
      if (input?.examSetupState === 2) {
        completion = 25;
      }
      if (input?.examSetupState === 3) {
        completion = 50;
      }
      if (input?.examSetupState === 4) {
        completion = 75;
      }
    }
    if (exam) {
      if (exam?.examStatus < 1) {
        completion = 0;
      }
      if (exam?.examStatus == 2) {
        completion = 50;
      }
      if (exam?.examStatus == 1) {
        completion = 25;
      }
      if (exam?.examStatus > 1 && singleExamData?.examInstruction) {
        completion = 75;
      }
    }
    setCompletion(completion);
  }, [loadingExam]);

  return (
    <>
      <Typography
        sx={{
          color: "#313131",
          fontFamily: "Open Sans",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "33.6px",
        }}
      >
        Test Settings
      </Typography>
      <div className="flex lg:flex-col lg:space-y-3 mt-3 w-full justify-start ">
        <Button
          startIcon={<SettingIcon />}
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: "start",
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "19.6px",
            textTransform: "none",
            backgroundColor: `${input?.examSetupState === 1 ? "#fff" : ""}`,
          }}
          onClick={() => setInput({ ...input, examSetupState: 1 })}
        >
          Test configuration
        </Button>

        <Button
          startIcon={<PerformanceEvaluationIcon />}
          sx={{
            color: "#000",
            fontFamily: "Open Sans",
            display: "flex",
            justifyContent: "start",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "19.6px",
            textTransform: "none",
            backgroundColor: `${input?.examSetupState === 7 ? "#fff" : ""}`,
          }}
          onClick={() => setInput({ ...input, examSetupState: 7 })}
        >
          Question Section
        </Button>
        <Button
          startIcon={<TestHomeIcon />}
          sx={{
            color: "#000",
            fontFamily: "Open Sans",
            display: "flex",
            justifyContent: "start",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "19.6px",
            textTransform: "none",
            backgroundColor: `${input?.examSetupState === 6 ? "#fff" : ""}`,
          }}
          onClick={() => setInput({ ...input, examSetupState: 6 })}
        >
          Test Start Instruction
        </Button>

        <Button
          startIcon={<img src={Question_Manager} alt="question icon" />}
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: "start",
            fontFamily: "Open Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "19.6px",
            textTransform: "none",
            backgroundColor: `${
              input?.examSetupState > 1 && input?.examSetupState <= 3
                ? "#fff"
                : ""
            }`,
          }}
          onClick={() => setInput({ ...input, examSetupState: 2 })}
        >
          Question Manager
        </Button>
      </div>
    </>
  );
};

export default TestSideBar;
