import { Visibility, VisibilityOff } from "@mui/icons-material";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { Button, Container, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import GlobalThemeProvider from "../../GlobalThemeProvider";
import AuthContainer from "../../components/AuthReuseables/AuthContainer";
import { useNotifications } from "../../components/notifications/notifications";
import { useGoogleAnalytics } from "../../hooks/usePageTracking";
import useUser from "../../hooks/useUser";
import { clearLocalStorageAction, loginUserAction } from "../../redux/auth/authActions";
import Bowser from "bowser";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [ToastState, setToastState] = useNotifications();
  const [msg, setMsg] = useState("");
  const [fail, setFail] = useState("");
  const { trackPage, trackSpecificEvent } = useGoogleAnalytics();
  trackPage();

  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSuccess = () => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Login Successful",
    });
  };

  useEffect(() => {
    dispatch(clearLocalStorageAction());
  }, []);

  useEffect(() => {
    if (fail) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: fail,
      });
    }
  }, [fail]);

  useEffect(() => {
    if (user.token && user?.userInfo?.isOnboardComplete && user?.userInfo?.isSchoolAdmin) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      navigate("/school/dashboard");
    }

    if (user.token && user?.userInfo?.isOnboardComplete && user?.userInfo?.isSponsor) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      navigate("/sponsor/dashboard");
    }

    if (user.token && user?.userInfo?.isOnboardComplete && !user?.userInfo?.isSchoolAdmin && !user?.userInfo?.isSponsor) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      navigate("/dashboard/home");
    }
    if (user.token && user?.userInfo?.isOnboardComplete == false && user?.userInfo?.profileId) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      navigate("/onboarding", {
        state: { email: input.email, password: input.password },
      });
    }
  }, [user.token, user?.userInfo?.isOnboardComplete, user?.userInfo?.isSchoolAdmin]);

  const deviceInfo = {
    browser: Bowser.getParser(window.navigator.userAgent).getBrowserName(),
    os: Bowser.getParser(window.navigator.userAgent).getOSName(),
    platform: Bowser.getParser(window.navigator.userAgent).getPlatformType(),
    userAgent: navigator.userAgent,
  };

  const handleLogin = () => {
    const data = {
      email: input.email,
      password: input.password,
      browser: deviceInfo?.browser,
      os: deviceInfo?.os,
      platform: deviceInfo?.platform,
      useragent: deviceInfo?.userAgent
    }
    setFail("");
    setMsg("");
    dispatch(
      loginUserAction(
        data,
        (res) => setMsg(res.data.msg),
        (res) => setFail(res.data.msg)
      )
    );
  };

  return (
    <GlobalThemeProvider>
      <AuthContainer>
        <Container maxWidth="xs">
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", mb: 3, textAlign: "center" }}>
            Login
          </Typography>
          <form name="user_login">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={input.email}
                  helperText={isEmailValid ? "" : "Please provide a valid email"}
                  onChange={(e) => {
                    const emailValue = e.target.value;
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                    setIsEmailValid(emailRegex.test(emailValue));
                    setInput({ ...input, email: emailValue });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={input.password}
                  onChange={(e) => setInput({ ...input, password: e.target.value })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
              </Grid>
            </Grid>
            <div className="mt-2 text-[14px] text-[#5F5F5F] flex justify-between items-center">
            <Link to="/auth/resendmail" style={{ color: "#5F5F5F", textDecoration: "none" }}>
                Resend Verification Link
              </Link>
              <Link to="/auth/forgot-password" style={{ color: "#5F5F5F", textDecoration: "none" }}>
                Forgot your password?
              </Link>
            </div>
            <Button
              type="button"
              fullWidth
              variant="contained"
              className="disabled:e-study-bg-primary"
              color="primary"
              sx={{
                mt: 2,
                py: 2,
                borderRadius: "8px",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={handleLogin}
              disabled={user.isLoading || !input.email || !input.password || !isEmailValid}
            >
              {!user.isLoading ? "Login" : <DataUsageIcon size={24} className="animate-spin" />}
            </Button>
          </form>

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "20px 0",
            }}
          >
            <hr style={{ width: "25%", borderTop: "1px solid #5F5F5F" }} />
            <Typography
              component="span"
              sx={{ fontSize: "12px", color: "#5F5F5F" }}
            >
              or continue with
            </Typography>
            <hr style={{ width: "25%", borderTop: "1px solid #5F5F5F" }} />
          </div>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            sx={{
              mt: 1.5,
              py: 1.5,
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            <Avatar
              src={GoogleLogo}
              alt="GoogleLogo"
              sx={{ width: 20, height: 20, marginRight: 1 }}
            />
            Login
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            sx={{
              mt: 2.5,
              py: 1.5,
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            <Avatar
              src={FacebookLogo}
              alt="FacebookLogo"
              sx={{ width: 20, height: 20, marginRight: 1 }}
            />
            Login
          </Button> */}
          <Typography
            sx={{
              mt: 2,
              textAlign: "center",
              fontSize: "15px",
              color: "#5F5F5F",
            }}
          >
            Already have an account?{" "}
            <Link to="/auth/signup" style={{ color: "#40499D", textDecoration: "none" }}>
              Signup
            </Link>
          </Typography>
        </Container>
      </AuthContainer>
      <ToastState />
    </GlobalThemeProvider>
  );
};

export default Login;
