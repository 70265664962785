import Axios from "../../connection/defaultClient";

import * as actionTypes from "./types";

// api for subjectbyprofile
export const fetchSubjectByProfileID = (payload) => {
  return (dispatch) => {
    // dispatch({ type: ALL_SUBJECTS });
    Axios.get(`/Subject/GetSubjectsByProfileId?profileId=${payload}`)

      .then((response) => {
        dispatch({
          type: actionTypes.ALL_SUBJECTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_SUBJECTS_FAIL,
          payload: error.message,
        });
      });
  };
};
// api that displays the number of exams
export const fetchExamBySubjectID = (payload) => {
  return (dispatch) => {
    // dispatch({ type: ALL_SUBJECTS });
    dispatch({
      type: actionTypes.ALL_EXAM_LOADING,
      payload: true,
    });
    Axios.get(`/Subject/GetStudyExamBySubjectId?subjectId=${payload}`)

      .then((response) => {
        dispatch({
          type: actionTypes.ALL_EXAM,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_EXAM_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchExamType = () => {
  return (dispatch) => {
    // dispatch({ type: ALL_SUBJECTS_SUCCESS, paylaod: true });
    Axios.get(`/Exam/GetAllExamType`)
      .then((response) => {
        dispatch({
          type: actionTypes.EXAM_TYPE,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_SUBJECTS_FAIL,
          payload: error.message,
        });
      });
  };
};

//api to get all questionfor study
export const fetchExamSubject = (payload, onSuccess) => {
  return (dispatch) => {
    // dispatch({ type: ALL_SUBJECTS_SUCCESS, paylaod: true });
    const { ExamId } = payload;
    Axios.get(`/Exam/GetExamQuestionsByExamId?ExamId=${ExamId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_QUESTION_COUNT,
          payload: response.data,
        });
        onSuccess(response.data.data);
        // console.log(response.data, "count");
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_QUESTION_COUNT_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchQuestionsByTopicId = (payload, onSuccess) => {
  return (dispatch) => {
    // dispatch({ type: ALL_SUBJECTS_SUCCESS, paylaod: true });
    const { TopicId } = payload;
    Axios.get(`/ManageExam/GetExamQuestionsByTopicId?TopicId=${TopicId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_QUESTION_COUNT,
          payload: response.data,
        });
        onSuccess(response.data.data);
        // console.log(response.data, "count");
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_QUESTION_COUNT_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchQuestionById = (payload, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_QUESTION, payload: true });
    Axios.post(`/Exam/GetQuestionByQuestionId?questionId=${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_QUESTION,
          payload: response.data,
        });
        onSuccess(response.data.data);
        dispatch({ type: actionTypes.LOAD_QUESTION, payload: false });
        // console.log(response.data, "exam question");
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_QUESTION_FAIL,
          payload: error.message,
        });
        dispatch({ type: actionTypes.LOAD_QUESTION, payload: false });
      });
  };
};

export const fetchSubmitStudies = (
  payLoad,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {},
  callBack = () => {}
) => {
  return (dispatch) => {
    //
    // dispatch({ type: LOAD_QUESTION, payLoad: true });
    Axios.post(`Studies/SubmitStudies`, { ...payLoad.input })
      .then((res) => {
        // dispatch({ type: LOAD_QUESTION, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res.data);
        } else if (res.data.state == -1) {
          onFail(res.data);
        }
      })
      .catch((error) => {
        // dispatch({ type: LOAD_QUESTION, payLoad: false });
        on500Fail(error.response);
        console.error(error);
      });
  };
};

// API THAT DISPLAYS THE PRACTICE QUESTIONS
export const fetchPracticeQuestion = (payload, onSuccess) => {
  return (dispatch) => {
    const { ExamId } = payload;
    Axios.get(`/Exam/GetPracticeQuestionsByExamId?ExamId=${ExamId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_PRACTICE_QUESTION_COUNT,
          payload: response.data,
        });
        onSuccess(response.data);
        // console.log(response.data, "count");
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_PRACTICE_QUESTION_COUNT_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchPracticeQuestionsByQuestionId = (payload, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_PRACTICE_QUESTION, payload: true });
    Axios.post(`/Exam/GetPracticeQuestionByQuestionId?questionId=${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_PRACTICE_QUESTION,
          payload: response.data,
        });
        onSuccess(response.data);
        dispatch({ type: actionTypes.LOAD_PRACTICE_QUESTION, payload: false });
        // console.log(response.data, "exam question");
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_PRACTICE_QUESTION_FAIL,
          payload: error.message,
        });
        dispatch({ type: actionTypes.LOAD_PRACTICE_QUESTION, payload: false });
      });
  };
};

export const fetchQuestionSolutionByQuestionId = (
  payload,
  onSuccess = () => {}
) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_QUESTION_SOLUTION_LOADING,
      payload: true,
    });
    Axios.post(`/Exam/GetSolutionByQuestionId?questionId=${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_QUESTION_SOLUTION_LOADING,
          payload: false,
        });
        dispatch({
          type: actionTypes.GET_QUESTION_SOLUTION_SUCCESS,
          payload: response.data.data,
        });
        if (response.data.state === 1) {
          onSuccess(response.data.data);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_QUESTION_SOLUTION_LOADING,
          payload: false,
        });
        dispatch({
          type: actionTypes.GET_QUESTION_SOLUTION_FAILURE,
          payload: error.message,
        });
      });
  };
};

// api that post the  student pending studies

export const fetchSubmitPendingStatus = (
  payLoad,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {},
  setSubjectIdforTime,
  callBack = () => {}
) => {
  return () => {
    Axios.post(`Studies/PendingStudies`, { ...payLoad })
      .then((res) => {
        if (res.data.state == 1) {
          onSuccess(res.data);
          setSubjectIdforTime(res.data.data);
        } else if (res.data.state == -1) {
          onFail(res.data);
        }
      })
      .catch((error) => {
        // dispatch({ type: LOAD_QUESTION, payLoad: false });
        on500Fail(error.response);
        console.error(error);
      });
  };
};

//api the sends the last viewed question to the backend to be displayed on login of the user
export const fetchSendLastViewedQuestion = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {},
  callBack = () => {}
) => {
  return (dispatch) => {
    Axios.post(`/Studies/UpdateLastViewedQuestionNumber`, { ...payload })
      .then((response) => {
        dispatch({
          type: actionTypes.SUBMIT_LAST_VIEWED_QUESTION_NUMBER,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        on500Fail(error.response);
        console.error(error);
      });
  };
};

//api that gets lat viewed question
export const fetchGetLastViewedQuestion = (payload) => {
  return (dispatch) => {
    // const { ExamId } = payload;
    Axios.get(
      `/Studies/GetLastViewdQuestion?ProfileId=${payload.ProfileId}&ExamId=${payload.ExamId}`
    )
      .then((response) => {
        dispatch({
          type: actionTypes.GET_LAST_VIEWED_QUESTION_NUMBER,
          payload: response.data,
        });
        // onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_LAST_VIEWED_QUESTION_NUMBER_FAIL,
          payload: error.message,
        });
      });
  };
};

// api that checks if a user has subscribed
export const fetchCandidateActiveSubscription = (payload) => {
  return (dispatch) => {
    // const { ExamId } = payload;
    Axios.get(`/Subscription/GetCandidateActiveSubscription/${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ACTIVE_SUBSCRIPTION,
          payload: response.data,
        });
        // onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ACTIVE_SUBSCRIPTION_FAIL,
          payload: error.message,
        });
      });
  };
};

// api to submit practice question points
export const fetchSubmitPoints = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {},
  callBack = () => {}
) => {
  return (dispatch) => {
    Axios.post(`Gamification/SubmitPoints`, { ...payload })
      .then((response) => {
        dispatch({
          type: actionTypes.SEND_POINTS,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        on500Fail(error.response);
        console.error(error);
      });
  };
};

// api to submit study time
export const fetchSubmitStudyTime = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {},
  callBack = () => {}
) => {
  return (dispatch) => {
    Axios.post(`Studies/UpdateStudyTime`, payload)
      .then((response) => {
        // dispatch({
        //   type: actionTypes.SUBMIT_STUDY_TIME,
        //   payload: response.data,
        // });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        on500Fail(error.response);
        console.error(error);
      });
  };
};
