import React, { useState, useEffect } from "react";
import { FilterIcon, PlusIcon } from "../../../assets/icons/Icons";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Badge,
  Typography,
  Fab,
} from "@mui/material";

import SearchBox from "../../../components/Test/SearchBox";
import StudentsTable from "./StudentsTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import CustomizedDialogs from "../../../components/StudentManagement/Dialog";
import Enroll from "../../../components/StudentManagement/Enroll";
import BulkImportDialog from "../../../components/StudentManagement/BulkImportDialog";
import { useDispatch } from "react-redux";
import {
  fetchGetStudent,
  fetchGetSchoolSponsorsBySchoolId,
} from "../../../redux/school";
import useSchool from "../../../hooks/useSchool";
import useUser from "../../../hooks/useUser";
import { useNotifications } from "../../../components/notifications/notifications";
import BusyOverlay from "../../../components/BusyOverlay";

const SchoolManageStudents = () => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const school = useSchool();
  const [showFilters, setShowFilters] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [increaseModalSize, setIncreaseModalSize] = useState(false);
  const [addSingleStudent, setAddSingleStudent] = useState(false);
  const [addBulkImport, setAddBulkImport] = useState(false);
  const [bulkActions, setBulkActions] = useState({ isUploaded: false });
  const { allStudent } = school;
  const { userInfo } = useUser();
  const { schoolId } = userInfo;
  const [ToastState, setToastState] = useNotifications();
  const [refreshPage, setRefreshPage] = useState(false);

  const refreshParent = () => {
    // Toggle the refresh flag to trigger a re-render of the parent component
    setRefreshPage(!refreshPage);
    //alert("Hi, I'm refreshing");
  };

  useEffect(() => {
    dispatch(fetchGetStudent(schoolId));
  }, []);

  function handleAddStudent() {
    setAddSingleStudent(true);
    setAddBulkImport(false);
    setIncreaseModalSize(false);
  }

  function handleBulkImport() {
    setAddBulkImport(true);
    setAddSingleStudent(false);
    setOpen(false);
  }
  const handleReduceModalSize = () => {
    setIncreaseModalSize(false);
  };
  const handleOpen = () => {
    setOpen(true);
    setIncreaseModalSize(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAddBulkImport(false);
    setAddSingleStudent(false);
  };
  const modalContent = {
    title: "Enrol Student",
    content: (
      <Enroll
        addSingleStudent={addSingleStudent}
        addBulkImport={addBulkImport}
        handleAddStudent={handleAddStudent}
        handleBulkImport={handleBulkImport}
        closeModal={handleClose}
        setToastState={setToastState}
        refreshParent={refreshParent}
      />
    ),
  };
  const handleSearch = (value) => {
    setSearchValue(value);

    if (value === "") {
    } else {
    }
  };
  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };
  const totalStudent = allStudent?.length || 0;

  // useEffect(()=>{

  // },[])
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center space-x-4">
          <h2 className="e-study-heading-four e-study-school-black">
            Manage Students
          </h2>
          <span className="e-study-button-lilac rounded-[22px] py-2 px-3">
            {totalStudent}
          </span>
        </div>
        {/* <p className="e-study-school-black e-study-normal-text-three">
          See the list of all students enrolled in the e-Study application
        </p> */}

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#40499D",
            borderRadius: 2,
            textTransform: "none",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#333a7d",
            },
          }}
          onClick={handleOpen}
          startIcon={<PlusIcon />}
        >
          Enrol Student(s)
        </Button>
      </div>
      {/* <div className="mt-6">
        <Grid container xs={12} spacing={1}>
          <Grid item xs={8}>
            <SearchBox
              placeholder={"students"}
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
              searchIconColor={"#1347F5"}
              bg={"rgba(244, 247, 254, 1)"}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "#40499d",
                  backgroundColor: "#F5F6FF",
                  border: "none",
                  padding: "15px 20px",
                  "&:hover": {
                    border: "none",
                  },
                }}
                startIcon={<FilterIcon />}
                endIcon={
                  <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "#EAECFF",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWe fontSize={"10px"}>
                      4
                    </Typography>
                  </Box>
                }
              >
                Filter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div> */}

      <div className="mt-6 overflow-x-hidden">
        <StudentsTable
          open={open}
          handleClose={handleClose}
          modalContent={modalContent}
          student={allStudent}
          setToastState={setToastState}
        />
      </div>
      <CustomizedDialogs
        open={open}
        handleClose={handleClose}
        content={modalContent}
        width={"900px"}
        maxWidth={increaseModalSize ? "md" : "sm"}
      />
      <BulkImportDialog
        open={addBulkImport}
        handleClose={handleClose}
        title={{ right: "Import Student data from Excel" }}
        actions={bulkActions}
        setToastState={setToastState}
        refreshParent={refreshParent}
        BusyOverlay={BusyOverlay}
      />
      <ToastState />
    </div>
  );
};

export default SchoolManageStudents;
