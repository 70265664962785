import { Box, Button, Paper, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { SubjectsChart } from "./SubjectsChart";
import AddCoreSubjectsDialog from "./AddCoreSubjectsDialog";
import { useState } from "react";
import useDashboard from "../../../hooks/useDashboard";
import coreSubjectsBg from "../../../assets/images/CoreSubjectsBg.png"
import useTest from "../../../hooks/useTest";
import { CoreSubjectsDemoImg } from "../../../assets/images";

const CoreSubjectsCard = ({ subjectModal }) => {

  const [openAddCoreSubjectsDialog, setOpenAddCoreSubjectsDialog] =
    useState(false);

  const handleOpenCoreSubjectsDialog = () => {
    setOpenAddCoreSubjectsDialog(true);
  };

  const handleCloseCoreSubjectsDialog = () => {
    setOpenAddCoreSubjectsDialog(false);
  };

  const { dashboardStat, isLoading } = useDashboard();
  const { subjectsData, subjectLoading } = useTest();

  return (
    <div>
      <Box>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: "flex",
            borderRadius: "10px",
            flexDirection: "column",
            border: "1px solid var(--Lightbg, #EAECFF)",
            maxHeight: "auto"
          }}
        >
          <div style={{ backgroundColor: "#F2F4F7", borderRadius: "5px", padding: "24px" }}>
            <img src={CoreSubjectsDemoImg} alt="Demo Image" />
          </div>
          <Typography sx={{ textAlign: "center", color: "#101828", fontSize: "14px", fontWeight: 700, paddingTop: "15px" }}>Customize your subjects</Typography>
          <Typography sx={{ textAlign: "center", color: "#475467", fontSize: "10px", paddingBottom: "15px" }}>Did you know you can customize your subjects for a tailored learning experience? Click to see subjects.</Typography>
          <Button
            variant="contained"
            onClick={handleOpenCoreSubjectsDialog}
            sx={{
              borderRadius: "5px",
              fontSize: "11px",
              textTransform: "inherit",
              p: "5px 5px",
            }}
          >
            {subjectLoading ?
              <Skeleton variant="text" animation="wave" width={"60px"} /> :
              (subjectsData?.length <= 3 ? "Add Subjects" : subjectsData?.length < 9 ? "Update Subjects" : "View Subjects")
            }
          </Button>
        </Paper>
      </Box>
      <AddCoreSubjectsDialog
        openAddCoreSubjectsDialog={openAddCoreSubjectsDialog}
        handleCloseCoreSubjectsDialog={handleCloseCoreSubjectsDialog}
        subjectModal={subjectModal}
      />
    </div>
  );
};

export default CoreSubjectsCard;
