import React, { useEffect, useRef, useState } from 'react';
import "./NewBadgeModal.css"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import {ReactComponent as Level1Badge} from '../../assets/images/Level1Badge.svg'
import {ReactComponent as Level2Badge} from '../../assets/images/Level2Badge.svg'
import {ReactComponent as Level3Badge} from '../../assets/images/Level3Badge.svg'
import {ReactComponent as Level4Badge} from '../../assets/images/Level4Badge.svg'
import {ReactComponent as Level5Badge} from '../../assets/images/Level5Badge.svg'
import {ReactComponent as Level6Badge} from '../../assets/images/Level6Badge.svg'
import {ReactComponent as Level7Badge} from '../../assets/images/Level7Badge.svg'
import {ReactComponent as Level8Badge} from '../../assets/images/Level8Badge.svg'
import {ReactComponent as Level9Badge} from '../../assets/images/Level9Badge.svg'
import {ReactComponent as Level10Badge} from '../../assets/images/Level10Badge.svg'
import Level1BadgeBg from '../../assets/images/Leve1BadgeBg.svg'
import Level2BadgeBg from '../../assets/images/Leve2BadgeBg.svg'
import Level3BadgeBg from '../../assets/images/Level3BadgeBg.svg'
import Level4BadgeBg from '../../assets/images/Level4BadgeBg.svg'
import Level5BadgeBg from '../../assets/images/Level5BadgeBg.svg'
import Level6BadgeBg from '../../assets/images/Level6BadgeBg.svg'
import Level7BadgeBg from '../../assets/images/Level7BadgeBg.svg'
import Level8BadgeBg from '../../assets/images/Level8BadgeBg.svg'
import Level9BadgeBg from '../../assets/images/Level9BadgeBg.svg'
import Level10BadgeBg from '../../assets/images/Level10BadgeBg.svg'
import LevelBadgeFlare from '../../assets/images/LevelBadgeFlare.svg'
import { Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import  Diamond  from "../../assets/images/Diamond.gif";
import BadgeSound from './BadgeSound';


const NewBadgeModal = ({ open, level, onClose }) => {
    const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
 
  const [animateBadge, setAnimateBadge] = useState(false);
  const [animateFlare, setAnimateFlare] = useState(false);
  const [animateSound, setAnimateSound] = useState(false);
  const [animateContent, setAnimateContent] = useState(false);

  useEffect(() => {
    if (open) {
      
      setTimeout(() => {
        setAnimateBadge(true);
      }, 300);

      setTimeout(() => {
        setAnimateFlare(true);
      }, 1500);

      setTimeout(() => {
        setAnimateSound(true);
      }, 1700); 

      setTimeout(() => {
        setAnimateContent(true);
      }, 2500);
    } else {
   
      setAnimateBadge(false);
      setAnimateFlare(false);
      setAnimateSound(false);
      setAnimateContent(false);

    }
  }, [open]);

  const getLevelData = (level) => {
    let badge, diamonds, backgroundImage, levelNo, levelName;

    switch (level) {
      case 1:
        badge = <Level1Badge />;
        diamonds = 2;
        backgroundImage = Level1BadgeBg ;
        levelNo = 1;
        levelName = 'Newbie';
        break;
      case 2:
        badge = <Level2Badge />;
        diamonds = 4;
        backgroundImage = Level2BadgeBg ;
        levelNo = 2;
        levelName = 'Eager Learner';
        break;
      case 3:
        badge = <Level3Badge />;
        diamonds = 6;
        backgroundImage = Level3BadgeBg ;
        levelNo = 3;
        levelName = 'Intermediate Explorer';
        break;
      case 4:
        badge = <Level4Badge />;
        diamonds = 8;
        backgroundImage = Level4BadgeBg ;
        levelNo = 4;
        levelName = 'Skilled Learner';
        break;
      case 5:
        badge = <Level5Badge />;
        diamonds = 10;
        backgroundImage = Level5BadgeBg ;
        levelNo = 5;
        levelName = 'Advanced Achiever';
        break;
      case 6:
        badge = <Level6Badge />;
        diamonds = 12;
        backgroundImage = Level6BadgeBg ;
        levelNo = 6;
        levelName = 'Expert Enthusiast';
        break;
      case 7:
        badge = <Level7Badge />;
        diamonds = 14;
        backgroundImage = Level7BadgeBg ;
        levelNo = 7;
        levelName = 'Boss of Knowledge';
        break;
      case 8:
        badge = <Level8Badge />;
        diamonds = 16;
        backgroundImage = Level8BadgeBg ;
        levelNo = 8;
        levelName = 'Scholarly Sage';
        break;
      case 9:
        badge = <Level9Badge />;
        diamonds = 18;
        backgroundImage = Level9BadgeBg ;
        levelNo = 9;
        levelName = 'Prodigy of Wisdom';
        break;
      case 10:
        badge = <Level10Badge />;
        diamonds = 20;
        backgroundImage = Level10BadgeBg ;
        levelNo = 10;
        levelName = 'Genius';
        break;
      
      default:
        badge = <Level1Badge />;
        diamonds = 2;
        backgroundImage = Level1BadgeBg ;
        levelNo = 1;
        levelName = 'Newbie';
        break;
    }

    return { badge, diamonds, backgroundImage, levelNo, levelName };
  };

  // Calculate level-specific properties.
  const { badge, diamonds, backgroundImage, levelNo, levelName } = getLevelData(level);



  return (
    <Dialog open={open} onClose={onClose}  fullWidth={true}
    fullScreen={fullScreen} aria-labelledby='new-badge-dialog'
    PaperProps={{
      style: {
        borderRadius: "32px",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center', 
      },
    }}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: "white", pt: 0, pb: 0, mt: 0, mb: 0}}>
        <BadgeSound className={animateSound ? 'show-sound' : 'hide-sound'}/>
        <Typography className={animateContent ? 'show-content' : 'hide-content'} variant="h6" sx={{fontFamily: "Orbitron, sans-serif", fontSize: "30px"}}>Level {levelNo < 10 ? `0${levelNo}` : levelNo} - {levelName}</Typography>
        <Typography className={animateContent ? 'show-content' : 'hide-content'}  variant="subtitle1" sx={{fontFamily: "Open Sans, sans-serif", fontWeight: '600', fontSize: "24px"}}>New Badge Unlocked</Typography>
        <div style={{position: "relative"}}>
        <img className={animateFlare ? 'show-badge' : 'hide-badge'} src={LevelBadgeFlare} alt='flare' style={{zIndex: 99}} />
          <div className={animateBadge ? 'show-badge' : 'hide-badge'} style={{position: "absolute", zindex: 9999, top: 0, left: 0}}>{badge}</div>
        </div>
        <Typography className={animateContent ? 'show-content2' : 'hide-content2'} variant="subtitle2" sx={{fontSize:"16px"}} mb={0.5}>You've earned {diamonds} diamonds</Typography>
        <img className={animateContent ? 'show-content2' : 'hide-content2'} src={Diamond} alt='Diamonds' style={{ width: "auto", height: "30px"}}/>
        <Button
        className={animateContent ? 'show-content2' : 'hide-content2'} 
                variant="contained"
                sx={{
                  color: "white",
                  borderRadius: "10px",
                  px: 4,
                  border: "2px solid #fff",
                  background: "#DEAB28", 
                  textTransform: 'capitalize',
                  fontSize: "12px",
                  mt:1
                }}
                onClick={onClose}
              >
                Continue
              </Button>
      </DialogContent>
    </Dialog>
  );
};

export default NewBadgeModal;
