 import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Card, Button, Typography, Grid, Slide } from "@mui/material";
import StudyIcon from "../../../../assets/images/studyIcon.svg";
import {
  fetchPracticeQuestion,
  fetchPracticeQuestionsByQuestionId,
  fetchSubmitPendingStatus,
} from "../../../../redux/learning";
import useLearning from "../../../../hooks/useLearning";
import { useDispatch } from "react-redux";
import { useNotifications } from "../../../../components/notifications/notifications";
import Flowers from "../../../../assets/images/floatingFlowers.gif";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

function PracticeModal(props) {
  const [examPractQuestionId, setExamPractQuestionId] = useState(0);
  const learning = useLearning();
  const { practiceQuestions, practiceQuestionCount } = learning;
  const dispatch = useDispatch();
  const { input } = props;
  const [ToastState, setToastState] = useNotifications();

  const handleShowPracQuest = () => {
    props.handleShowPracQuestion();
    //props.getPracticeQuestion();
  };

  // console.log(props.examTypeId)

  return (
      <Dialog
        open={props?.openModal}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            backgroundColor: "#40499D",
            width: "1000px",
            height: "400px",
            display:'flex',
            justifyContent:"center",
            alignItems:"center",
            borderRadius: "10px"
          },
        }}
      >
          <Box
          justifyContent={"center"}
          alignItems={"center"}
          display="flex"
          component={DialogContent}
          flexDirection={"column"}
        >
          <img
            src={Flowers}
            alt="Image"
            style={{
              height: 400,
              width: 1000,
              display: "flex", // Change display to "block"
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "0rem",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <Typography
            class="text-3xl font-extrabold ..."
            style={{
              color: "white",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Congratulations! Study Completed
            {/* Let's test your knowledge with 5 practice */}
          </Typography>
          {/*<Typography
            class="text-3xl font-extrabold ..."
            style={{
              color: "#deab28",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            You have earned 10 points
          </Typography>*/}
          {props.examType === 1 ? (
            <Grid>
              {" "}
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "3rem",
                  px: 2,
                  "& > *": {
                    m: 1,
                  },
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Let's test your knowledge with 5 practice questions
                </Typography>
              </Grid>
              <Button
                onClick={() => {
                  handleShowPracQuest();
                }}
                variant="outlined"
                style={{
                  display: "flex",
                  display: "block", // Change display to "block"
                  margin: "0 auto",
                  color: "white",
                  backgroundColor: "#deab28",
                  borderColor: "white",
                }}
              >
                Start Quiz
              </Button>
            </Grid>
          ) : (
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2em",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  display: "flex",
                  display: "block", // Change display to "block"
                  margin: "0 auto",
                  color: "white",
                  backgroundColor: "#deab28",
                }}
                href="/dashboard"
              >
                Go to Dashboard
              </Button>
            </Grid>
          )}
        </Box>
      </Dialog>
  );
}
export default PracticeModal;
