  import React  from "react";
import ReactApexChart from "react-apexcharts";


const UserTypeChart = (props) => {
  const { adminDashboardChart } = props;

  const options = {
    chart: {
      type: 'pie',
    },
    legend: {
      position: 'bottom',
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
    },
    labels: [`Paid Users   ${adminDashboardChart?.paidUsers}`, `Unpaid Users  ${adminDashboardChart?.unPaidUsers}`,],
    dataLabels: {
      enabled: false
    },

    colors: ['#40499d', '#e0c6fd']
  };

  const series = [adminDashboardChart?.paidUsers ?? 0, adminDashboardChart?.unPaidUsers ?? 0];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type='pie'
      height={300}
    // width="100%"
    />
  )
}

export default UserTypeChart;