import React, { useEffect, useRef, useState } from 'react';
import {
  Grid, Typography, Box, Tabs, Tab, OutlinedInput,
  InputAdornment, IconButton, Chip, Menu, MenuItem,
  Button, Card, Dialog, FormControl, CardContent,
  MenuList, ListItemIcon, ListItemText, DialogTitle, InputLabel, DialogContent, Paper, Avatar, DialogActions,
} from '@mui/material';
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import usePermissions from '../../../hooks/usePermissions';
import { DataUsage } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getAllSitePages, updateSitePages } from '../../../redux/permissions/permissionsActions';
import useAdminUser from '../../../hooks/useAdminUser';
import IconDropdown from '../../../components/Dropdowns/Icondropdown';

const EditPageDialog = (props) => {

  const { openEditPageDialog, handleClosePage, selectedRowPage, setToastState } = props

  const dispatch = useDispatch();
  const { loading } = usePermissions();
  const { userInfo } = useAdminUser();

  const [pageName, setPageName] = useState('');
  const [pageId, setPageId] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const [pageIcon, setPageIcon] = useState('');


  useEffect(() => {
    setPageName(selectedRowPage.PageName);
    setPageDescription(selectedRowPage?.Description);
    setPageId(selectedRowPage.PageId);
    setPageIcon(selectedRowPage?.PageFolderIcon);

  }, [selectedRowPage])

  const onUpdateSuccess = (res) => {
    handleClosePage();
    dispatch(getAllSitePages())
    setToastState({ isOpen: true, severity: "success", message: "Page updated successfully" });
  }

  const onFail = (res) => {
    handleClosePage();
    setToastState({ isOpen: true, severity: "warning", message: res });
  }


  const handleUpdatePage = () => {
    if (!pageName || !pageDescription || !pageId ) return;
    const data ={
      pageId: pageId,
      pageName: pageName,
      description: pageDescription,
      // "parentFolder": "string",
      pageFolderIcon: pageIcon,
      profileId: userInfo?.data?.profileId
    }
    dispatch(updateSitePages(data, onUpdateSuccess, onFail))
  }

  return (
    <Dialog
      open={openEditPageDialog}
      onClose={handleClosePage}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="alert-dialog-title"
      >
        <Typography sx={{
          color: '#313131',
          fontSize: '16px', fontWeight: 600,
          lineHeight: '16px'
        }}>Edit Page</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClosePage}
          style={{
            position: "absolute",
            top: "1px",
            right: "1px",
            justifyContent: "flex-end"
          }}

        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>


        <Grid sx={{ mt: 3 }} container spacing={1} className='flex justify-center'>
          <Grid item xs={12}>
            <label>Page Name</label>
            <input className='input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder="Enter Page Name" onChange={(e) => setPageName(e.target.value)} value={pageName} />
          </Grid>
        </Grid>

        <Grid sx={{ mt: 3 }} container spacing={1} className='flex justify-center'>
          <Grid item xs={12}>
            <label>Page Description</label>
            <textarea className='input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' rows={8} placeholder="Enter Page Description" onChange={(e) => setPageDescription(e.target.value)} value={pageDescription} />
          </Grid>
        </Grid>

        <Grid sx={{ mt: 3 }} container spacing={1} className='flex justify-center'>
          <Grid item xs={12}>
            <label>Page Icon</label>
            <IconDropdown pageIcon={pageIcon} setPageIcon={setPageIcon} />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <button onClick={() => { handleUpdatePage() }} style={{ backgroundColor: '#40499D' }} className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" disabled={loading}>
          {!loading ? (
            <Typography sx={{
              color: '#fff',
              fontSize: '12px', fontWeight: 600,
              lineHeight: '16.8px',
              fontFamily: 'Open Sans'
            }}>Edit Page</Typography>
          ) : (
            <DataUsage size={24} className="animate-spin ml-4" />
          )}

        </button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPageDialog