import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Paper,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NavMenu from "../components/NavMenu";
import { PricingLine } from "../components/landingPage/Line";
import SubOccurenceOption from "../components/pricing/PaymentOccurenceRadio";
import PricingCard, {
  PopularPricingCard,
  RegularPricingCard,
} from "../components/pricing/PricingCard";
import LandingPageFooter from "../components/landingPage/LandingPageFooter";
import { usePricingModels } from "../hooks/usePricing";
import Payment from "./student/payment/Payment";
import { useNavigate } from "react-router-dom";
import Loader from "../components/AuthReuseables/Preloader/Loader";
import PricingTabs from "../components/pricing/PricingTabs";
import NewPricingCards from "../components/pricing/NewPricingCards";
import "./Home.css";
import AuthNavbar from "../components/navbars/AuthNavbar";
import { useGoogleAnalytics } from "../hooks/usePageTracking";

const Pricing = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [paymentOccurence, setPaymentOccurence] = useState(1);
  const [loading, setLoading] = useState(true);
  const { data, isLoading, error } = usePricingModels();
  const [paymentModal, setPaymentModal] = useState(false);
  const targetAreaRef = useRef(null);
  const ScrollIntoView = () => {
    targetAreaRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const { trackPage } = useGoogleAnalytics()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  trackPage()
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const tabs = [
    {
      label: "Student",
      content: <NewPricingCards cards={data?.data?.studentPackages} />,
    },
    {
      label: "School",
      content: <NewPricingCards cards={data?.data?.schoolPackages} />,
    },

    {
      label: "Sponsor",
      content: <NewPricingCards cards={data?.data?.sponsorPackages} />,
    },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Box bgcolor={"#40499d"}>
            <AuthNavbar />
            <Container maxWidth="xl">
              <Box
                width={"100%"}
                component={"section"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Stack
                  mt={4}
                  mb={11}
                  width={"65%"}
                  gap={3}
                  alignItems={"center"}
                  color={"white"}
                >
                  <Typography fontFamily={"Montserrat"} variant="h6">
                    Pricing
                  </Typography>
                  {/* <Typography variant='h4' component='span' fontWeight={"bold"}>Study without <Typography color={"#F8EF07"}>Limits</Typography></Typography> */}
                  <Typography
                    fontFamily={"Montserrat"}
                    fontSize={{ xs: "1.75rem", sm: "2rem" }}
                    textAlign={{ xs: "center", md: "start" }}
                    display={"inline"}
                    fontWeight={"700"}
                    variant="span"
                    color={"white"}
                  >
                    Study without
                    <Typography
                      fontFamily={"Montserrat"}
                      variant="span"
                      display={"inline"}
                      fontWeight={"700"}
                      position={"relative"}
                      color={"#F8EF07"}
                    >
                      {" "}
                      <PricingLine />
                      Limits!
                    </Typography>
                  </Typography>
                  <Typography
                    fontFamily={"Montserrat"}
                    variant="body1"
                    textAlign={"center"}
                    px={{ md: 10 }}
                  >
                    Unlock Your Success with eStudy. Choose the Perfect Plan to
                    Elevate Your Performance and Achieve Your Goals.
                  </Typography>
                  <Stack gap={{ xs: 1, md: 4 }} direction={{ md: "row" }}>
                    <Button
                      onClick={() => navigate("/auth/signup")}
                      size="large"
                      sx={{
                        color: "white",
                        bgcolor: "#ffffff21",
                        fontFamily: "Montserrat",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#ffffff61",
                          fontFamily: "Montserrat",
                        },
                      }}
                    >
                      GET STARTED
                    </Button>
                    <Button
                      onClick={ScrollIntoView}
                      size="large"
                      sx={{
                        color: "#000",
                        bgcolor: "#ffffff",
                        fontFamily: "Montserrat",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#ccc",
                          fontFamily: "Montserrat",
                        },
                      }}
                    >
                      VIEW PLANS
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Container>
          </Box>
          <Box component="section" id="#prices" p={4}>
            <Container maxWidth="lg" ref={targetAreaRef}>
              <Box
                width={{ md: "100%" }}
                mx={"auto"}
                id="#prices"
                component={"section"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography
                  fontFamily={"Montserrat"}
                  mb={2}
                  variant="h5"
                  textAlign={"center"}
                  fontWeight={"bold"}
                >
                  Subscription Plans Pricing & Benefits
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent={"center"}
                  gap={2}
                >
                  {isLoading || error ? (
                    <>
                      <Skeleton
                        variant="rounded"
                        width={200}
                        height={400}
                        animation={"wave"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={200}
                        height={400}
                        animation={"wave"}
                      />
                      <Skeleton
                        variant="rounded"
                        width={200}
                        height={400}
                        animation={"wave"}
                      />
                    </>
                  ) : (
                    <Box pt={4}>
                      <PricingTabs
                        value={value}
                        handleChange={handleChange}
                        tabs={tabs}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>
          <LandingPageFooter />

          <Dialog
            fullScreen={true}
            open={paymentModal}
            onClose={() => setPaymentModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Payment setPaymentModal={setPaymentModal} />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default Pricing;
