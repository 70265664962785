import React from 'react';

const ProfileIcon = ({ firstName, lastName }) => {
  // Extracting initials from first name and last name
  const initials = lastName.charAt(0).toUpperCase() + firstName.charAt(0).toUpperCase();

  return (
    <div className="h-12 w-12 flex items-center justify-center estudy-text-profile estudy-bg-profile rounded-full text-lg font-bold">
      {initials}
    </div>
  );
};

export default ProfileIcon;
