import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import img5 from "../../assets/images/JumbotronImage.png";
import user1 from "../../assets/images/user1.jpg";
import user2 from "../../assets/images/user2.jpg";
import user3 from "../../assets/images/user3.jpg";
import memoji from "../../assets/images/MemojiBoy.svg";
import waecLogo from "../../assets/images/waeclogo.svg";
import sidmachLogo from "../../assets/images/sidmachlogo.svg";
import Line from "./Line";
import { HackBreak } from "./Features";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const CustomImage = styled("img")``;

const CTAJumbotron = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  return (
    <Box
      display={"flex"}
      maxWidth={"100vw"}
      flexDirection={{ xs: "column", md: "row" }}
      minHeight={"fit-content"}
      justifyContent={"stretch"}
    >
      <Box
        display={"flex"}
        gap={3}
        my={{ xs: 6, md: 0 }}
        flex={1}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={{ xs: "center", md: "start" }}
      >
        <Typography
          fontSize={{ xs: "2rem", sm: "3.75rem" }}
          textAlign={{ xs: "center", md: "start" }}
          display={"inline"}
          fontWeight={"700"}
          variant="span"
          color={"white"}
        >
          Ace your
          <Typography
            variant="span"
            display={"inline"}
            fontWeight={"700"}
            position={"relative"}
            color={"#F8EF07"}
          >
            {" "}
            <Line />
            WASSCE
          </Typography>
          <Typography
            variant="span"
            display={"inline"}
            fontWeight={"700"}
            position={"relative"}
            color={"#FFF"}
          >
            {" "}
            in one sitting!
            <CustomImage
              src={memoji}
              sx={{ display: "inline", marginLeft: "10", height: "3.75rem" }}
            />
          </Typography>
        </Typography>
        <Typography
          textAlign={{ xs: "center", md: "start" }}
          fontSize={{ xs: 15, md: 20 }}
          color={"white"}
          variant="p"
        >
          Interactive, fun and self-paced learning, in preparation for
          WASSCE(SC/PC) with mock test and answers.
        </Typography>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column-reverse", md: "row" }}
          gap={4}
          alignItems={"center"}
        >
          <Button
            sx={{
              color: "#40499d",
              bgcolor: "#ffffff21",
              padding: "16px 30px",
              textTransform: "capitalize",
              fontSize: "14px",
              fontFamily: "Montserrat",
              backgroundColor: "#F8EF07",
              transition: "0.25s ease-in-out all",
              "&:hover": {
                backgroundColor: "#FFF850",
                padding: "16px 40px 16px 35px",
                fontWeight: 800,
                fontFamily: "Montserrat",
              },
            }}
            onClick={() => navigate("/auth/signup")}
            size="large"
          >
            Start Studying
          </Button>
          <Button
            sx={{
              color: "#fff",
              border: "solid 1px #fff",
              backgroundColor: "#ffffff21",
              padding: "16px 30px",
              textTransform: "capitalize",
              fontSize: "14px",
              fontFamily: "Montserrat",
              transition: "0.25s ease-in-out all",
              "&:hover": {
                padding: "16px 40px 16px 35px",
                fontWeight: 800,
                fontFamily: "Montserrat",
              },
            }}
            onClick={() => navigate("/WAEC")}
            size="large"
          >
              Learn more about WAEC
          </Button>
          {/* <Box display={"flex"} gap={1} alignItems={"center"}>
            <AvatarGroup spacing={"small"}>
              <Avatar alt="SS2 Student" src={user1} />
              <Avatar alt="SS3 Student" src={user3} />
            </AvatarGroup>
            <Typography variant="body" color="white">
              Join other students to learn
            </Typography>
          </Box> */}
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography
            textAlign={{ xs: "center", md: "start" }}
            fontSize={{ xs: 13, md: 16 }}
            color={"white"}
            variant="p"
          >
            Powered By
          </Typography>
          <CustomImage
            src={sidmachLogo}
            sx={{ display: "inline", height: "3rem" }}
          />
        </Box>
      </Box>
      <Box display={"flex"} maxWidth={{ md: "50%" }} mx={"auto"} flexShrink={1}>
        <Skeleton
          variant="circular"
          width={500}
          height={"100%"}
          sx={{ display: loaded ? "none" : "block" }}
        />
        <CustomImage
          onLoad={() => setLoaded(true)}
          src={img5}
          draggable={false}
          style={{
            height: "100%",
            display: loaded ? "block" : "none",
          }}
        />
      </Box>
    </Box>
  );
};

export default CTAJumbotron;
