import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled, useTheme } from "@mui/material/styles";
import CountryAsynchronous from "../../../components/Settings/Autocomplete/CountryAsynchronous";
import StateAsynchronous from "../../../components/Settings/Autocomplete/StateAsynchronous";
import { getAllCountries } from "../../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import { useNotifications } from "../../../components/notifications/notifications";
import CustomSelect from "../../admin/usermetrics/customSelectForm";
import useUser from "../../../hooks/useUser";
import LgaAsynchronous from "../../../components/Settings/Autocomplete/LgaAsynchronous";
import SchoolAsynchronous from "../../../components/Settings/Autocomplete/SchoolAsynchronous";
import useSettings from "../../../hooks/useSettings";
import {
  getLgaByStateId,
  getStatesByCountryId,
  getWaecApprovedSchools,
} from "../../../redux/settings/settingsActions";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1.5),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "transparent", // Hide the placeholder text
  },
  "& .MuiInputBase-input:focus::placeholder": {
    color: "#555", // Style the label text color when focused
  },
}));

const SchoolOnBoarding = (props) => {
  const { input, setInput } = props;
  const initialFormValues = {
    schoolName: "",
    schoolAddress: "",
    countryId: 0,
    stateId: 0,
    lgaId: 0,
    schoolId: 0,
  };

  const [formValues, setFormValues] = React.useState({ ...initialFormValues });
  const dispatch = useDispatch();
  const [values, setValues] = useState({ label: "", value: null });
  const [stateValues, setStateValues] = useState({ label: "", value: 0 });
  const [lgaValues, setLgaValues] = useState({ label: "", value: 0 });
  const [schoolValues, setSchoolValues] = useState({ label: "", value: 0 });
  const [Toast, setToast] = useNotifications();

  const user = useUser();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, schoolName: value });
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleChanges = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, schoolAddress: value });
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const countryHandler = (event, val) => {
    if (event) {
      setStateValues({ label: "", value: 0 });
      setInput({ ...input, countryId: val.value });
      setFormValues({
        ...formValues,
        countryId: val.value,
      });

      dispatch(getStatesByCountryId(val?.value, stateByCountryFailure));
    }
  };

  const updateValue = (value) => {
    setFormValues({
      ...formValues,
      countryId: value.value,
    });
    setInput({ ...input, countryId: value.value });
    setValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };

  const stateHandler = (event, val) => {
    if (event) {
      setLgaValues({ label: "", value: 0 });
      setFormValues({
        ...formValues,
        stateId: val.value,
      });

      dispatch(getLgaByStateId(val?.value, lgaByStateFailure));
    }
  };

  const updateStateValue = (value) => {
    setFormValues({
      ...formValues,
      stateId: value.value,
    });
    setInput({ ...input, stateId: value.value });
    setStateValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };
  const lgaHandler = (event, val) => {
    if (event) {
      setSchoolValues({ label: "", value: 0 });
      setFormValues({
        ...formValues,
        lgaId: val.value,
      });

      dispatch(
        getWaecApprovedSchools(
          formValues?.countryId,
          formValues?.stateId,
          val?.value,
          schoolFailure
        )
      );
    }
  };

  const updateLgaValue = (value) => {
    setFormValues({
      ...formValues,
      lgaId: value.value,
    });
    setInput({ ...input, lgaId: value.value });
    setLgaValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };
  const schoolHandler = (event, val) => {
    if (event) {
      setFormValues({
        ...formValues,
        schoolId: val.value,
      });
    }
  };

  const updateSchoolValue = (value) => {
    setFormValues({
      ...formValues,
      WAECSchoolID: value.value,
    });
    setInput({ ...input, WAECSchoolID: value.value });
    setSchoolValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };
  const stateByCountryFailure = (res) => {
    if (res !== 1) {
      setToast({
        isOpen: true,
        message: "An error occured!",
        severity: "error",
      });
    }
  };

  const lgaByStateFailure = (res) => {
    if (res !== 1) {
      setToast({
        isOpen: true,
        message: "An error occured!",
        severity: "error",
      });
    }
  };

  const schoolFailure = (res) => {
    if (res !== 1) {
      setToast({
        isOpen: true,
        message: "An error occured!",
        severity: "error",
      });
    }
  };

  // const handleChangeCountry = (event) => {
  //   const value = event.target.value;
  //   setInput({ ...input, countryId: value });
  //   dispatch(getStatesByCountryId(value));
  // };

  // const handleChangeState = (event) => {
  //   const value = event.target.value;
  //   setInput({ ...input, stateId: value });
  // };

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  return (
    <div>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 528,
            height: 380,
          },
        }}
      >
        <Paper sx={{ borderRadius: 1, border: "1px solid #E5E5E5", p: 3 }}>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Tell us about your school</Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <InputLabel htmlFor="schoolName">School Name *</InputLabel>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Eg John Doe High School"
                  required="required"
                  size="small"
                  name="schoolName"
                  value={input.schoolName}
                  onChange={handleChange}
                  // error={isError}  // Pass the isError prop
                  // helperText={isError && "This field is required"}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="schoolAddress">School Address *</InputLabel>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  placeholder="E.g Street or Block"
                  variant="outlined"
                  name="schoolAddress"
                  size="small"
                  value={input.schoolAddress}
                  onChange={handleChanges}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography>Select Country</Typography>
              <CustomSelect
                handleChange={handleChangeCountry}
                value={input.countryId}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select Country"}
                Seloptions={countries}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography>Select State</Typography>
              <CustomSelect
                handleChange={handleChangeState}
                value={input.stateId}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select State"}
                Seloptions={statesByCountryId}
              />
            </Grid> */}
            {input.countryId === 13 ? (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <CountryAsynchronous
                      updateValue={updateValue}
                      countryHandler={countryHandler}
                      // values={input.countryId}
                      values={values}
                      size="small"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <StateAsynchronous
                      updateStateValue={updateStateValue}
                      // updateLgaValue={updateLgaValue}
                      stateHandler={stateHandler}
                      stateValues={stateValues}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <LgaAsynchronous
                      updateLgaValue={updateLgaValue}
                      lgaHandler={lgaHandler}
                      lgaValues={lgaValues}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <SchoolAsynchronous
                      updateSchoolValue={updateSchoolValue}
                      schoolHandler={schoolHandler}
                      schoolValues={schoolValues}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="School Address"
                      id="outlined-basic"
                      placeholder="E.g Street or Block"
                      variant="outlined"
                      name="schoolAddress"
                      // size="small"
                      value={input.schoolAddress}
                      onChange={handleChanges}
                    />
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <CountryAsynchronous
                      updateValue={updateValue}
                      countryHandler={countryHandler}
                      values={values}
                      sx={{ height: "10px" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <StateAsynchronous
                      updateStateValue={updateStateValue}
                      stateHandler={stateHandler}
                      stateValues={stateValues}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* <InputLabel htmlFor="schoolName">School Name *</InputLabel> */}
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Eg John Doe High School"
                      required="required"
                      name="schoolName"
                      label="schoolName"
                      value={input.schoolName}
                      onChange={handleChange}
                      // error={isError}  // Pass the isError prop
                      // helperText={isError && "This field is required"}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
              <FormControl fullWidth>
                <SchoolAsynchronous
                  updateSchoolValue={updateSchoolValue}
                  schoolHandler={schoolHandler}
                  schoolValues={schoolValues}
                />
              </FormControl>
            </Grid> */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="School Address"
                      id="outlined-basic"
                      placeholder="E.g Street or Block"
                      variant="outlined"
                      name="schoolAddress"
                      // size="small"
                      value={input.schoolAddress}
                      onChange={handleChanges}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {/* <Grid item xs={6}>
              <InputLabel htmlFor="country">Country *</InputLabel>
              <FormControl fullWidth>
                <CountryAsynchronous
                  size="small"
                  updateValue={updateValue}
                  countryHandler={countryHandler}
                  values={input.countryId}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor="state">State *</InputLabel>
              <FormControl fullWidth>
                <StateAsynchronous
                  size="small"
                  updateStateValue={updateStateValue}
                  stateHandler={stateHandler}
                  stateValues={input.stateId}
                />
              </FormControl>
            </Grid> */}
          </Grid>
        </Paper>
      </Box>
      {/* </Paper> */}
      <Toast />
    </div>
  );
};

export default SchoolOnBoarding;
