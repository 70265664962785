import { ArrowForwardIos, Cancel, CloseRounded, WarningAmber } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AppBar, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, Stack, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { padQuestionNumber } from '../../helpers/helper';
import useTest from '../../hooks/useTest';
import useUser from '../../hooks/useUser';
import { DEACTIVATE_TEST } from '../../redux/test/types';
import CountdownTimer from './CountdownTimer';
import { QuestionListDrawer } from './QuestionListDrawer';
import SingleQuestionInterface from './SingleQuestionInterface';
import { TestNumber } from './TestNumber';
import { useNotifications } from '../notifications/notifications';
import { BsArrowsFullscreen } from "react-icons/bs";
import { sendTestToBack } from '../../redux/test/testActions';
import { AdDialog } from "./BlockerModal"
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import Calculator from '../Calculator';
import CalculateIcon from '@mui/icons-material/Calculate';
import Calculator from '../calculator';
import useSubscription from '../../hooks/useSubscription';
import { useGoogleAnalytics } from '../../hooks/usePageTracking';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow placement={"right"} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.brandPrimary.light,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#525FB8",
    color: "#fff",
    // color: theme.palette.brandPrimary.main,
    // boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const MainTestView = () => {
  const dispatch = useDispatch()
  const router = useNavigate()
  const { isTrial } = useSubscription()
  const [questionDrawer, setQuestionDrawer] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false)
  const [unanswered, setUnanswered] = useState(0);
  const { userInfo } = useUser()
  const [Toast, setToast] = useNotifications()
  const [adModal, setAdModal] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [calculatorModal, setCalculatorModal] = useState(false)
  const [freeQuestions] = useState([0, 1, 2, 3, 4])
  const { trackSpecificEvent } = useGoogleAnalytics()

  const handle = useFullScreenHandle()

  const { currentQuestion, questions, testName, allocatedTime } = useTest()

  const handleNext = () => {
    dispatch({ type: "UPDATE_EXAM_COUNTER", payload: currentQuestion + 1 })
  }
  const handlePrev = () => {
    dispatch({ type: "UPDATE_EXAM_COUNTER", payload: currentQuestion - 1 })
  }

  const handleMoveToQuestion = (quesNo) => {
    dispatch({ type: "UPDATE_EXAM_COUNTER", payload: quesNo })
  }

  // get unanswered questions
  const findUnanswered = () => {
    const unansweredArray = questions.map((questionData, index) => {
      const unanswered = questions[index]?.options?.filter((option) => option.selected);
      return unanswered
    })
    return unansweredArray
  }

  // arrange the luggages(payload) for api trip
  const compileAnswersForSubmission = () => {
    try {
      const examId = questions[0].examId;
      const userProfileId = userInfo.profileId;
      const timeSpent = allocatedTime.toString();
      const timeLeft = allocatedTime.toString();
      const userChoices = [...questions.map((question) => {
        return {
          questionId: question.examQuestionId,
          choice: question.options.find((option) => option.selected)?.optionNo ?? 0
        }
      })]

      return {
        examId,
        userProfileId,
        // timeLeft,
        timeSpent,
        userChoices,
      }
    } catch (e) {
      console.log(e)
      setToast({
        isOpen: true,
        message: "Failed to submit test",
        severity: "error"
      })
      return false
    }
  }
  const areQuestionsStillLoading = () => {
    const isLoading = questions?.find((question) => question.question === null || question.question === undefined)
    return Boolean(isLoading)
  }

  const initiatePostTest = () => {
    setSubmitDialog(true)
  }

  const endTest = () => {
    if (!areQuestionsStillLoading()) {
      if (compileAnswersForSubmission()) {
        dispatch(sendTestToBack(compileAnswersForSubmission()))
        trackSpecificEvent("mock_exams_completed", {
          test_name: testName
        })
        dispatch({ type: "RESET_QUIZ" })
        router("/dashboard/learning/reviewTest")
      } else {
        setToast({
          isOpen: true,
          severity: "warning",
          message: "Please try again"
        })
      }
    } else {
      setToast({
        isOpen: true,
        severity: "warning",
        message: "Test can't be submitted at this moment"
      })
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Modern browsers require a non-empty string
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // document.removeEventListener('fullscreenchange', onFullscreenChange);
    }
  }, [])

  useEffect(() => {
    if (isFullscreen) {
      document.requestFullscreen()
      handle.enter()
    }
  }, [isFullscreen])

  useEffect(() => {
    setUnanswered(findUnanswered().filter((option) => option?.length === 0))
  }, [questions])


  const questionObject = questions[currentQuestion]

  useEffect(() => {
    if (isTrial) {
      if (!freeQuestions.includes(currentQuestion)) {
        setAdModal(true)
        handleMoveToQuestion(freeQuestions[4])
      };
    }
  }, [currentQuestion])

  if (questionObject) {
    return (
      <>
        <FullScreen handle={handle}>
          <Box display={"flex"} width={"100%"} minHeight={"99vh"} flexDirection={"column"}>
            <Box display={{ md: "flex" }} width={"100%"}>
              <Box width={{ md: "75%" }}>
                <AppBar elevation={0} sx={{ bgcolor: "#B8BEF13D", px: { xs: 1, md: 3 }, py: 2, color: "#313131", top: 0, flexDirection: "row", justifyContent: "space-between", fontWeight: 600 }} position='sticky'>
                  <Stack direction={{ xs: "column", md: "row" }} sx={{ justifyContent: "space-between", width: "100%" }}>
                    <Typography component={"span"} fontWeight={"500"} variant="h5">Online Examination - {testName}</Typography>
                    <Button color='error' variant='contained' sx={{ width: "fit-content" }} onClick={() => initiatePostTest()}>
                      End Test
                    </Button>
                  </Stack>
                  <Button size='small' type='button' sx={{ display: { md: "none" }, width: "fit-content", gap: "0px" }} color='brandPrimary' onClick={() => setQuestionDrawer(true)} endIcon={<ArrowBackIosNewIcon />}>Q{currentQuestion + 1}/Q{questions?.length}</Button>
                </AppBar>
              </Box>



              <Box width={{ md: "25%" }} position={"sticky"}>
                <AppBar elevation={0} sx={{ bgcolor: allocatedTime > 600 ? "#B8BEF1" : allocatedTime < 300 ? "#F9EDCE" : allocatedTime < 100 ? "#F9C1BB" : "", px: 3, py: { xs: 1, md: 2 }, color: "black" }} position='sticky'>
                  <Typography variant="h5" textAlign={"center"} sx={{ color: "brandPrimary.main" }}>Time Left</Typography>
                </AppBar>
              </Box>
              <Box color={"#848484"} my={2} mx={5} justifyContent={"space-between"} display={{ xs: "flex", md: "none" }}>
                <CountdownTimer endTest={() => endTest()} initialTimeInSeconds={allocatedTime} key={'lg'} />
              </Box>
            </Box>

            <Box display={{ md: "flex" }} flexGrow={1} width={"100%"}>
              <Stack width={{ md: "75%" }} overflow={"clip"}>
                <SingleQuestionInterface>
                  <IconButton onClick={async () => await handle.enter()}>
                    <BsArrowsFullscreen />
                  </IconButton>
                  <LightTooltip title={"Open Calculator"} arrow placement='bottom' color=''>
                    <IconButton onClick={() => setCalculatorModal(true)} aria-label="Show calculator" color={"info"}>
                      <CalculateIcon />
                    </IconButton>
                  </LightTooltip>
                </SingleQuestionInterface>
                {/* Prev-Next start */}
                <Box position={{ xs: "sticky", md: "relative" }} width={"100%"} pt={3} pb={1} bottom={0} px={2} bgcolor={"#fff"} display={"flex"} justifyContent={"end"}>
                  <ButtonGroup disableElevation sx={{ gap: 1, borderRadius: 3, width: { xs: "100%", md: currentQuestion === (questions.length - 1) ? "35%" : "25%" } }} size='large' variant="contained" aria-label="outlined primary button group">
                    <Button onClick={handlePrev} disabled={currentQuestion <= 0} sx={{ borderRadius: 3 }} startIcon={<ArrowBackIosNewIcon />} fullWidth color='brandPrimary'>Previous</Button>
                    {currentQuestion !== questions.length - 1 && <Button onClick={handleNext} disabled={currentQuestion === questions.length - 1} fullWidth sx={{ borderRadius: 3 }} endIcon={<ArrowForwardIos />} color='brandPrimary'>Next</Button>}
                    {currentQuestion === questions.length - 1 && <Button onClick={() => initiatePostTest(true)} style={{ wordWrap: "nowrap" }} className='whitespace-nowrap' disabled={currentQuestion !== questions.length - 1} fullWidth sx={{ borderRadius: 3, px: { md: 8 } }} endIcon={<ArrowForwardIos />} color='yellowColor'>Submit Test</Button>}
                  </ButtonGroup>
                </Box>
                {/* Prev-Next ends */}
              </Stack>

              <Box borderLeft={"0.5px solid #ddd"} width={{ md: "25%" }} display={{ xs: "none", md: "block" }}>
                {/* Counter lg Starts */}
                <Box color={"#848484"} py={2} px={5} justifyContent={"space-between"} display={{ xs: "none", md: "flex" }}>
                  <CountdownTimer endTest={() => endTest()} initialTimeInSeconds={allocatedTime} />
                </Box>
                {/* Counter lg ends */}

                {/* Questions lg Pointer Start */}
                <Box bgcolor={"#EFEFEF"} py textAlign={"center"}>
                  <Typography variant='h6'>Questions</Typography>
                </Box>
                <Box gap={2} flexWrap={'wrap'} display={"flex"} maxHeight={"70vh"} sx={{ overflowY: "scroll" }} p={3}>
                  {questions.map((questionData, index) => {
                    const answered = questions[index]?.options?.filter((option) => option.selected);
                    if (isTrial && !freeQuestions.includes(index)) {
                      return (<TestNumber active={questions[currentQuestion]?.id === questionData.id} flagged={questionData.flagged} key={index} answered={answered?.length > 0} className={"opacity-75"} onClick={() => setAdModal(true)}>{padQuestionNumber(questionData.questionNumber)}</TestNumber>)
                    }
                    return (<TestNumber active={questions[currentQuestion]?.id === questionData.id} flagged={questionData.flagged} key={index} answered={answered?.length > 0} onClick={() => handleMoveToQuestion(index)}>{padQuestionNumber(questionData.questionNumber)}</TestNumber>)
                  })}
                </Box>
                {/* Question Pointer Ends */}
              </Box>
            </Box>
          </Box>

          {/* Question Pointer sm */}
          <QuestionListDrawer active={questionDrawer} setDrawer={() => setQuestionDrawer(false)}>
            <Box bgcolor={"#EFEFEF"} py textAlign={"center"} display={"flex"} alignItems={"center"}>
              <Tooltip title="close">
                <IconButton onClick={() => setQuestionDrawer(false)} sx={{ flexShrink: 1 }} color='error'><Cancel /></IconButton>
              </Tooltip>
              <Typography sx={{ flexGrow: 1 }} variant='h6'>Questions</Typography>
            </Box>
            <Box gap={2} flexWrap={'wrap'} display={"flex"} p={3}>
              {questions.map((questionData, index) => {
                const answered = questions[index]?.options?.filter((option) => option.selected);
                if (isTrial && !freeQuestions.includes(index)) {
                  return (<TestNumber active={questions[currentQuestion]?.id === questionData.id} flagged={questionData.flagged} key={index} answered={answered?.length > 0} className={"opacity-75"} onClick={() => setAdModal(true)}>{padQuestionNumber(questionData.questionNumber)}</TestNumber>)
                }
                return (<TestNumber active={questions[currentQuestion]?.id === questionData.id} flagged={questionData.flagged} key={index} answered={answered?.length > 0} onClick={() => { handleMoveToQuestion(index); setQuestionDrawer(false) }}>{padQuestionNumber(questionData.questionNumber)}</TestNumber>)
              })}
            </Box>
          </QuestionListDrawer>

          {/* Submit Dialog */}
          <Dialog
            open={submitDialog}
            fullWidth
            maxWidth={"xs"}
            onClose={() => setSubmitDialog(false)}
            aria-labelledby="submit-dialog-title"
            aria-describedby="submit-dialog-description"
            disablePortal
            container={() => document.getElementById('fullscreen-root')}
          >
            <DialogTitle display={"flex"} id="submit-dialog-title">
              <Typography component={"p"} variant='h5' fontWeight={"bold"}>You are about to end this test</Typography>
              <IconButton sx={{ justifySelf: "end", ml: "auto" }} onClick={() => setSubmitDialog(false)}><CloseRounded /></IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 1 }}>
              <Box p={1} sx={{ borderRadius: "50%", backgroundColor: "#F9F8DE" }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <WarningAmber fontSize='large' color='warning' />
              </Box>
              <Typography component={"p"} variant='h6' mx={"auto"} fontWeight={"bold"} alignSelf={"baseline"}>This test will be submitted</Typography>
              <Typography display={unanswered.length > 0 ? "block" : "none"}>{unanswered.length} question(s) has not been answered</Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-around" }}>
              <Button color='brandPrimary' variant='outlined' size='large' onClick={() => setSubmitDialog(false)}>Go back to test</Button>
              <Button color={"brandPrimary"} variant='contained' size='large' onClick={() => endTest()} autoFocus>
                Submit Test
              </Button>
            </DialogActions>
          </Dialog>
          <Toast />
          <AdDialog open={adModal} setOpen={setAdModal} />

          {/* Calculator Dialog */}
          <Drawer
            anchor='right'
            open={calculatorModal}
            onClose={() => setCalculatorModal(false)}
            ModalProps={{
              // Disable portal to ensure Drawer is within FullScreen
              disablePortal: true
            }}
            sx={{
              '& .MuiDrawer-paper': {
                width: '300px', // Adjust width as needed
                boxSizing: 'border-box',
                zIndex: 1300 // Ensure it's above other elements
              }
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <DialogTitle display={"flex"} className="items-center justify-between">
                <Typography variant="h6" component="div">
                  Calculator
                </Typography>
                <IconButton onClick={() => setCalculatorModal(false)}>
                  <CloseRounded color="error" />
                </IconButton>
              </DialogTitle>
              <Box sx={{ flexGrow: 1, p: 2 }}>
                <Calculator />
              </Box>
            </Box>
          </Drawer>
        </FullScreen>
      </>
    )
  } else {
    return (<Navigate to={"/dashboard/learning/mocktest"} />)
  }

}

export { MainTestView };
