export const dragdropActive ={
    padding: "20px",
    borderRadius: "10px",
    border: "2px dashed #016c34",
    alignItems: "center",
    backgroundColor: "#eef3f0",
  }
  
  export const dragdropZone= {
    padding: "20px",
    borderRadius: "2px",
    border: "1.7px dashed #C6C6C8",
    alignItems: "center",
    display:'flex',
    justifyContent:'center',
    color:'#ACB2B7'
  }
  
  
  export const dragdropText= {
    color: "#7899D9",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      textDecorationThickness: "2px",
    },
  }
  
  export const filesUploadedZone= {
    backgroundColor: "#EEEFFD",
    marginTop: "20px",
    textAlign: "left",
    fontWeight: "600px",
    padding: "20px 10px 20px 20px",
    borderRadius: "10px",
    fontSize:'14px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    color:'#57636F'
  }
  export const fileNameSection={
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap:'10px',
  }
  
  export const errorColor= {
    color: "red",
  }

  export const TemplateZone= {
    backgroundColor: "#F7F8FD",
    marginTop: "20px",
    textAlign: "left",
    fontWeight: "600px",
    padding: "20px 30px",
    borderRadius: "10px",
    fontSize:'12.5px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color:'#1a1a1a',
    gap:'5px'
  }
  export const TemplateNameSection ={
    display: "flex",
    alignItems: "center",
    gap:'10px',
  }

  export const DropActiveText ={fontSize:'14px', marginBottom:'10px', marginTop:'10px', fontWeight:500, color:'#016c34'}


  export const DropZoneText ={fontSize:'13px', marginBottom:'10px', marginTop:'10px', fontWeight:500 }

  export const DropZoneFooter ={display:'flex', justifyContent:'space-between', fontSize:'12px', marginTop:'10px', color:'#B7BBC1'}