import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Divider,
  Radio,
  Stack,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  RadioGroup,
  FormLabel,
  NativeSelect,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { addTheoryExamQuestionAction } from "../../../redux/contentUpload/contentUploadActions";
import { useDispatch, useSelector } from "react-redux";
import { RTEditor } from "../../../components/RTEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useAdminUser from "../../../hooks/useAdminUser";
import useContentUpload from "../../../hooks/useContentUpload";

const ContentUploadQuestionManagerTheoryInputs = ({
  input,
  setInput,
  setToastState,
  exam,
}) => {
  const [question, setEditorStateQuestion] = React.useState("");
  const [answer1, setEditorStateAnswer1] = React.useState("");
  const { loadingExam, questionSection } = useContentUpload();
  const [selectedSectionId, setSelectedSectionId] = useState(null);

  const [correctAnswerScore, setCorrectAnswerScore] = React.useState(0);

  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();

  const onEditorStateChangeQuestion = (editorState) => {
    setEditorStateQuestion(editorState);
  };

  const onEditorStateChangeAnswer1 = (editorState) => {
    setEditorStateAnswer1(editorState);
  };

  const onSuccess = (res) => {
    setInput({ ...input, examSetupState: 2 });
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Question added successfully",
    });
  };

  const handleSubmit = () => {
    const data = {
      question: question,
      // choices: "|||" + draftToHtml(convertToRaw(answer1.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer2.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer3.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer4.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer5.getCurrentContent())),
      answer: answer1,
      scoreWeight: correctAnswerScore,
      examId: exam ? exam?.examId : input?.examId,
      profileId: userInfo?.data?.profileId,
    };
    dispatch(addTheoryExamQuestionAction(data, (res) => onSuccess(res)));
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Questions manager
        </Typography>
        <span
          className="e-study-primary underline cursor-pointer"
          onClick={() => setInput({ ...input, examSetupState: 2 })}
        >
          View all questions
        </span>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Paper sx={{ minWidth: 275, borderRadius: "16px" }}>
          <CardContent>
            <Box>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "27.24px",
                }}
              >
                <KeyboardArrowLeftIcon
                  className="cursor-pointer"
                  onClick={() => setInput({ ...input, examSetupState: 2 })}
                />{" "}
                Question
              </Typography>
            </Box>
            <Box sx={{ mt: 2, width: "95%", mx: "auto" }}>
              <Box sx={{ mt: 2, mx: "auto" }}>
                <RTEditor
                  theme={"snow"}
                  value={question}
                  key="questionAdd"
                  setValue={setEditorStateQuestion}
                />
              </Box>

              <Stack mb={3}>
                <NativeSelect
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedSectionId}
                  onChange={(e) => setSelectedSectionId(e.target.value)}
                  fullWidth
                >
                  <option value="0">Select</option>
                  {questionSection?.map((type) => (
                    <option
                      sx={{ ml: 3 }}
                      value={type.sectionId}
                      key={type?.sectionId}
                    >
                      {type?.sectionName}{" "}
                    </option>
                  ))}
                </NativeSelect>
              </Stack>
              <Box sx={{ mt: 5, width: "90%" }}>
                <Typography>Answers</Typography>
                <Typography>
                  Enter all possible correct answers for the theory question
                </Typography>
              </Box>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <RTEditor
                  theme={"snow"}
                  key={"answer1add"}
                  value={answer1}
                  setValue={setEditorStateAnswer1}
                />
              </Stack>

              <Box sx={{ mt: 5 }}>
                <Typography>Score setting</Typography>
                <Typography>
                  Specify the points for a correct answer.{" "}
                </Typography>
              </Box>

              <Box
                sx={{ mt: 5 }}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                  <InputLabel>Points for correct answer</InputLabel>
                  <TextField
                    type="number"
                    aria-describedby="my-helper-text"
                    value={correctAnswerScore}
                    onChange={(e) => setCorrectAnswerScore(e.target.value)}
                  />
                </Stack>
                <Button
                  sx={{
                    backgroundColor: "#40499D",
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Paper>
      </Box>
    </>
  );
};

export default ContentUploadQuestionManagerTheoryInputs;
