import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchBox from "./SearchBox";
import { Box, Button, IconButton, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMobile from "./FilterMobile";
import TestModal from "./TestModal";
import { calculateGrade, isArray } from "../../helpers/helper";
import useUser from "../../hooks/useUser";

export default function MobileView({
  subjects,
  expanded,
  handleChange,
  data,
  loaded,
  show,
  onFilter,
  onClick,
  open,
  handleCloseModal,
  examData,
}) {

  const {userInfo} = useUser();
  const { isSuperSchoolAdmin } = userInfo;

  const isEmpty = data?.length === 0 && !loaded;

  const [searchedSubject, setSearchedSubject] = React.useState(subjects);
  const [searchedValue, setSearchedValue] = React.useState("");

  React.useEffect(() => {
    if (subjects?.length !== 0) {
      setSearchedSubject(subjects);
    }
  }, [subjects]);

  const handleSubjectsSearch = (value) => {
    setSearchedValue(value);
    if (value === "") {
      setSearchedSubject(subjects);
    } else {
      const filteredSubjects = subjects?.filter((item) =>
        isSuperSchoolAdmin ? item?.subjectName?.toLowerCase()?.includes(value.toLowerCase()) :
          
        item?.SubjectName?.toLowerCase()?.includes(value.toLowerCase())
      );
      setSearchedSubject(filteredSubjects);
    }
  };
  return (
    <div>
      <Box display={"flex"} alignItems={"center"} gap={0} mb={3}>
        {/* <Link to="/dashboard/learning/test">
          <IconButton>
            <KeyboardBackspaceIcon sx={{ fontSize: "25px" }} />
          </IconButton>
        </Link> */}
        <Typography
          variant="h5"
          sx={{ fontSize: { xs: "18px", fontWeight: 700 } }}
        >
          Mock Examination
        </Typography>
      </Box>
      <Box
        sx={{
          boxShadow: "0px 4px 13px 0px rgb(173 172 172 / 25%)",
          border: "2px solid #dddddd",
          borderRadius: "15px",
          p: 1,
        }}
      >
        <Box display="flex" gap={2} mb={2}>
          <SearchBox
            placeholder="subjects..."
            bg={"#f4f4f4"}
            onChange={(e) => handleSubjectsSearch(e.target.value)}
            value={searchedValue}
          />
          {/* <Button
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          endIcon={show ? <FilterListIcon /> : <FilterListOffIcon />}
        >
          Filter
        </Button> */}
        </Box>
        {/* <FilterMobile loaded={loaded} /> */}
        {searchedSubject?.length !== 0 &&
          searchedSubject?.map((item) => (
            <Accordion
              key={
                isSuperSchoolAdmin ? item?.subjectId :
                  item?.SubjectId
              }
              expanded={expanded === (
                isSuperSchoolAdmin ? item?.subjectId :
                  item?.SubjectId)
              }
              onChange={handleChange(
                isSuperSchoolAdmin ? item?.subjectId :
                  item?.SubjectId)
              }
              elevation={0}
            // sx={{ boxShadow: "0px 4px 13px 0px rgba(122, 122, 122, 0.25)" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ flex: 1 }}
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0, fontSize: { xs: "14px" } }}
                >
                  {
                    isSuperSchoolAdmin ? item?.subjectName :
                      item?.SubjectName
                  }
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  ...(!isEmpty && { backgroundColor: "#F2F3FD" }),
                  borderBottom: "1px solid #c6c6c6",
                  padding: "0px",
                }}
              >
                {isEmpty ? (
                  <Skeleton
                    variant="rectangular"
                    height={60}
                    animation="wave"
                  />
                ) : (
                  isArray(data) &&
                  data?.map((item, index) => (
                    <Box
                      key={item?.subjectId}
                      sx={{
                        borderBottom: "1px solid #a9a9a9",
                        padding: "10px 0px",
                        "&:last-of-type": {
                          borderBottom: "none",
                        },
                      }}
                      onClick={() => onClick(item)}
                    >
                      <Box
                        sx={{
                          "&:last-of-type": {
                            borderBottom: "none",
                          },
                          padding: "10px 15px",
                        }}
                      >
                        {!item && loaded ? (
                          <Typography sx={{ fontSize: { xs: "13px" } }}>
                            No Data here
                          </Typography>
                        ) : (
                          <>
                            <Typography sx={{ fontSize: { xs: "13px" } }}>
                              {/*  SSCE {item?.year} */}
                              {item?.ExamName}
                            </Typography>
                            <Typography sx={{ fontSize: { xs: "10px" } }}>
                              <b>Previous score:</b>   {(item?.PreviousScore
                                ? item?.PreviousScore + "/" + item?.QuestionCount
                                : "none") ?? "none"} <b>Grade:</b>   {item?.PreviousScore !== null
                                  ? calculateGrade(
                                    item[0]?.PreviousScore,
                                    item[0]?.QuestionCount
                                  )
                                  : "none"}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  ))
                )}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      <TestModal
        open={open}
        // handleClose={handleClose}
        examData={examData}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}

