import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import { EditIcon, FilterIcon, MoreIcon } from "../../../assets/icons/Icons";
import Cards from "../../../components/TransactionLog/Card";
import StatusGenerator from "../../../components/TransactionLog/StatusGenerator";
import SearchBox from "../../../components/Test/SearchBox";
import FilterButton from "../../../components/TransactionLog/FilterButton";
import { useDispatch } from "react-redux";
import {
  getAllTransactions,
  getTransactionById,
} from "../../../redux/payment/paymentActions";
import usePayment from "../../../hooks/usePayment";
import { ReactComponent as MoneyBagGreen } from "../../../assets/images/moneyBagGreen.svg";
import { ReactComponent as MoneyBagRed } from "../../../assets/images/moneyBagRed.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplayIcon from "@mui/icons-material/Replay";
import RefreshIcon from "@mui/icons-material/Refresh";
import ViewTransactionDialog from "../../../components/TransactionLog/ViewTransactionDialog";
import { formatDate } from "../../../helpers/helper";
import TransactionStatusDialog from "../../../components/TransactionLog/TransactionStatusDialog";
// import Filter from "../../../components/TransactionLog/Filter";
import RevenueFilter from "../../../components/TransactionLog/RevenueFilter";

const Revenue = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openViewTransDialog, setOpenViewTransDialog] = useState(false);
  const [openQueryDialog, setOpenQueryDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [showQuery, setShowQuery] = useState(false);

  const [filters, setFilters] = useState({currency: 566, paymentStatus: 'approved'
  });
  const [filterOptions, setFilterOptions] = useState({
    currency: 566,
    paymentStatus: 'approved'
  });
  const [loading, setLoading] = useState(false);

  const handleClick = (event, id, queryData) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedId(id);
    setShowQuery(queryData);
    dispatch(getTransactionById(id));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const dispatch = useDispatch();
  const payment = usePayment();

  const handleOpenViewTransDialog = () => {
    setOpenViewTransDialog(true);
    // dispatch(getTransactionById(selectedId));
  };

  const handleCloseViewTransDialog = () => {
    setOpenViewTransDialog(false);
    setOpen(false);
  };
  const handleOpenQueryDialog = () => {
    setOpenQueryDialog(true);
  };

  const handleCloseQueryDialog = () => {
    setOpenQueryDialog(false);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllTransactions(filterOptions));
  }, []);

  const handleFilterClick = (event) => {
    if (event !== 0) {
      // setLoading(true);
      setFilters(event);
      setFilterOptions(event);
      dispatch(getAllTransactions(event));
    }
  };

  const handleReset = (event) => {
    const isExactCurrency = (
      Object.keys(filterOptions).length === 1 &&
      filterOptions.currency === 566
    );
    if (!isExactCurrency) {
      setFilterOptions({ currency: 566, paymentStatus: 'approved' });
      setFilters({currency: 566, paymentStatus: 'approved' });
      dispatch(getAllTransactions({ currency: 566, paymentStatus: 'approved' }));
    }
  };

  // const handleSearch = (value) => {
  //   setSearchValue(value);

  //   if (value === "") {
  //     setSearchData(transactionData);
  //   } else {
  //     const filteredItems = transactionData.filter(
  //       (item) =>
  //         item?.FullName?.toLowerCase()?.includes(value.toLowerCase()) ||
  //         item?.UPTransId?.toLowerCase()?.includes(value.toLowerCase()) ||
  //         item?.Email?.toLowerCase()?.includes(value.toLowerCase())
  //     );
  //     setSearchData(filteredItems);
  //   }
  // };

  const ColumnTextStyle = {
    textTransform: "capitalize",
    textAlign: "left",
    color: "#212b36",
    fontSize: "0.875rem",
    fontWeight: 600,
  };

  const formattedRevenue = new Intl.NumberFormat().format(
    payment?.transactions?.totalRevenue ?? 0
  );

  let cards = [
    {
      icon: <MoneyBagGreen />,
      text: "Total Revenue",
      amount: payment.loading ? <Skeleton variant="text" width={100} animation="wave" /> :
        (filterOptions?.currency === 566
          ? `₦${formattedRevenue}`
          : filterOptions?.currency === 840
          ? `$${formattedRevenue}`
          : formattedRevenue),
    },
  ];

  const columns = [
    {
      name: "S/N",
      label: "S/N",
      options: {
        setCellProps: () => ({ style: { padding: "20px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return <span>{tableMeta.rowIndex + 1} </span>;
        },
      },
    },
    {
      name: "FullName",
      label: "Name",
      options: {
        // setCellProps: () => ({ style: { minWidth: "200px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const email = tableMeta.rowData[2];
          return (
            <Box>
              <Typography fontSize={"14px"} fontWeight={500}>
                {value}
              </Typography>
              <Typography fontSize={"12px"}>{email}</Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        display: false,
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    {
      name: "Currency",
      label: "Currency",
      options: {
        display: false,
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    {
      name: "CategoryName",
      label: "User Type",
      options: {
        // setCellProps: () => ({ style: { minWidth: "100px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
      },
    },
    {
      name: "Name",
      label: "Plan",
      options: {
        // setCellProps: () => ({ style: { minWidth: "100px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
      },
    },
    {
      name: "Slots",
      label: "License",
      options: {
        // setCellProps: () => ({ style: { minWidth: "100px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
      },
    },
    {
      name: "Trans_Amt",
      label: "Amount",
      options: {
        // setCellProps: () => ({ style: { minWidth: "130px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
        customBodyRender: (value, tableMeta) => {
          let formattedNumber = new Intl.NumberFormat().format(value);
          const currency = tableMeta.rowData[3];
          return currency == 566
            ? `₦${formattedNumber}`
            : `$${formattedNumber}`;
        },
      },
    },
    {
      name: "UPTransId",
      label: "Transaction Id",
      options: {
        // setCellProps: () => ({ style: { minWidth: "150px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
      },
    },
    // {
    //   name: "paymentGateway",
    //   label: "Payment Gateway",
    //   options: {
    //     setCellProps: () => ({ style: { minWidth: "200px" } }),
    //     customHeadLabelRender: ({ index, ...column }) => {
    //       return (
    //         <Typography sx={ColumnTextStyle} key={index}>
    //           {column.label}
    //         </Typography>
    //       );
    //     },
    //   }
    // },
    {
      name: "trans_Date",
      label: "Date & Time",
      options: {
        // setCellProps: () => ({ style: { minWidth: "180px" } }),
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <Typography sx={ColumnTextStyle} key={index}>
              {column.label}
            </Typography>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return value ? formatDate(value) : "";
        },
      },
    },
    // {
    //   name: "UPStatus",
    //   label: "Status",
    //   options: {
    //     // sort: false,
    //     customHeadLabelRender: ({ index, ...column }) => {
    //       return (
    //         <Typography sx={ColumnTextStyle} key={index}>
    //           {column.label}
    //         </Typography>
    //       );
    //     },
    //     customBodyRender: (value, tableMeta) => {
    //       const lowerCaseValue =
    //         typeof value === "string" ? value?.toLowerCase() : value;
    //       switch (lowerCaseValue) {
    //         case "approved":
    //           return (
    //             <StatusGenerator statusText="Approved" variant="greenFilled" />
    //           );
    //         case "declined":
    //           return (
    //             <StatusGenerator statusText="Declined" variant="redFilled" />
    //           );
    //         case "initiated":
    //           return (
    //             <StatusGenerator
    //               statusText="Initiated"
    //               variant="yellowFilled"
    //             />
    //           );
    //         default:
    //           return "no status";
    //       }
    //     },
    //     // filter: true,
    //   },
    // },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          // const rowData = payment.transactions.allTransData[tableMeta.rowIndex];
          const id = value?.UPTransId;
          const queryData = value?.UPStatus?.toLowerCase() === "initiated";
          return (
            <>
              <Tooltip title={"More"}>
                <IconButton
                  size="small"
                  onClick={(event) => handleClick(event, id, queryData)}
                >
                  <MoreIcon color="#40499D" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = [
    {
      type: "dropdown",
      name: "currency",
      group: "Currency",
      label: "Currency",
      options: [
        { label: "Naira (₦)", value: 566 },
        { label: "Dollar ($)", value: 840 },
      ],
    },
    // {
    //   type: "dropdown",
    //   name: "paymentStatus",
    //   group: "Payment Status",
    //   label: "Payment Status",
    //   options: [
    //     { label: "Approved", value: "approved" },
    //     { label: "Initiated", value: "initiated" },
    //     { label: "Declined", value: "declined" },
    //   ],
    // },
    {
      type: "dropdown",
      name: "usertype",
      group: "User Type",
      label: "User Type",
      options: [
        { label: "Student", value: "026f1450-f8de-4887-87ad-acd8edabe8fd" },
        { label: "School", value: "ec518f80-d514-47d6-acbd-fe1e9f3f1ec1" },
        { label: "Sponsor", value: "36e0ad06-523e-43f5-a7a0-5eff83d19c3e" },
      ],
    },
    {
      type: "startDate",
      name: "startDate",
      group: "Start Date",
      label: "Start Date",
    },
    {
      type: "endDate",
      name: "endDate",
      group: "End Date",
      label: "End Date",
    },
  ];

  const dispatchActions = () => {
    dispatch(getAllTransactions(filterOptions));
  };

  useEffect(() => {
    if (
      Array.isArray(payment.transactions.allTransData) &&
      payment.transactions.allTransData.length > 0
    ) {
      const createTableData = () => {
        let TableData = payment.transactions.allTransData.map((val) => [
          "",
          val.FullName,
          val.Email,
          val.Currency,
          val.CategoryName,
          val.Name,
          val.Slots,
          val.Trans_Amt,
          val.UPTransId,
          // val.paymentGateway,
          val.trans_Date,
          val.UPStatus,
          val,
        ]);
        return TableData;
      };
      setTransactionData(createTableData());
      const formatData = payment.transactions.allTransData?.map((data) => 
        ({
         FullName: data.FullName,
         Email: data.Email,
         Currency: data.Currency == 566 ? 'NGN' : 'USD',
         CategoryName: data.CategoryName,
         Name: data.Name,
         Slots: data.Slots,
         TransactionAmount: data.Trans_Amt,
         GatewayTransactionId: data.UPTransId,
         // Email: data.paymentGateway,
         TransactionDate: data.trans_Date,
         TransactionStatus: data.UPStatus,
       }
     ));
     setFormattedData(formatData);
    } else {
      setTransactionData([]);
    }
  }, [filterOptions, payment]);

  return (
    <>
      <Typography variant="h6" fontWeight={"bold"} pb={2}>
        Revenue
      </Typography>
      <Box mb={2}>
        <RevenueFilter
          options={options}
          filters={filters}
          onClick={handleFilterClick}
          reset={handleReset}
        />
      </Box>

      <Cards cards={cards} />

      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <DataTable
          data={transactionData}
          columns={columns}
          topMargin={true}
          tableName={"Transactions"}
          // download={true}
          search={true}
          serverSide={false}
          loading={payment.loading}
          formatData={formattedData}
          fileName={'Revenue'}
        />
      </Box>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            width: 180,
          },
        }}
      >
        <MenuItem onClick={handleOpenViewTransDialog}>
          <VisibilityIcon color="primary" sx={{ marginRight: "10px" }} /> View
          Payment
        </MenuItem>
        {showQuery ? (
          <MenuItem onClick={handleOpenQueryDialog}>
            <RefreshIcon sx={{ marginRight: "10px", color: "#ffd84d" }} /> Query
            Payment
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
      <ViewTransactionDialog
        handleClose={handleCloseViewTransDialog}
        open={openViewTransDialog}
        transaction={payment?.transaction}
        loading={payment?.oneLoading}
      />
      <TransactionStatusDialog
        handleClose={handleCloseQueryDialog}
        open={openQueryDialog}
        dispatchActions={dispatchActions}
        selectedId={selectedId}
      />
    </>
  );
};

export default Revenue;
