import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import DataTable from "../../../components/DataTable/DataTable";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { MoreIcon } from "../../../assets/icons/Icons";
import AvatarWithText from "../../../components/Avatar/AvatarWithText";
import CustomizedDialogs from "../../../components/StudentManagement/Dialog";

export default function SponsorSchoolTable({
  open,
  handleClose,
  handleOpen,
  data,
  modalContent,
  allSchoolsBySponsor,
}) {
  const columns = [
    {
      name: "School Name",
      label: "School",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <AvatarWithText text={value} />;
        },
      },
    },
    {
      name: "Paid Students",
      label: "Path",
    },
    {
      name: "Verified Students",
      label: "Phone Number",
      options: {
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          // ;
          return (
            <Tooltip title={"View more details"}>
              <IconButton size="small">
                <MoreIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];
  return (
    <>
      <DataTable data={allSchoolsBySponsor} columns={columns} />
    </>
  );
}
