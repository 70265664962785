import { useSelector } from "react-redux";

const useSchool = () => {
  const school = useSelector((state) => state.school);

  const {
    loading,
    classLevel,
    newStudent,
    allStudent,
    allSchoolsBySponsor,
    allStudentBySponorById,
    allSponsorsBySchool,
    studentById,
    resendStudMail,
    getAllDevices,
  } = school;

  return {
    loading,
    classLevel,
    newStudent,
    allStudent,
    allSchoolsBySponsor,
    allStudentBySponorById,
    allSponsorsBySchool,
    studentById,
    resendStudMail,
    getAllDevices,
  };
};

export default useSchool;
