import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import logo from "../assets/images/Applogo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import SettingsIcon from "@mui/icons-material/Settings";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import InputOutlinedIcon from "@mui/icons-material/InputOutlined";
import { Stack } from "@mui/material";
import waecLogo from "../assets/images/waeclogo.svg";
import "../pages/Home.css";
import { isEmptyObject } from "../helpers/helper";

const pages = ["Features", "FAQ", "Pricing", "WAEC", "Sign Up", "Login"];

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const settings = [
  {
    label: "Dashboard",
    icon: <HelpOutlineIcon />,
    linkTo: "/dashboard/home",
  },
  {
    label: "Profile",
    icon: <PermIdentityIcon />,
    linkTo: "/dashboard/settings",
  },
  {
    label: "Settings",
    icon: <SettingsIcon />,
    linkTo: "/dashboard/settings",
  },
  // {
  //   label: "Logout",
  //   icon: <HelpOutlineIcon />,
  //   linkTo: "#",
  // },
];

export const LogoImage = styled("img")(({ theme }) => ({
  mr: 2,
  fontFamily: "monospace",
  fontWeight: 700,
  letterSpacing: ".3rem",
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
  height: "80px",
}));

function NavMenu({ ScrollIntoView }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigate = useNavigate();
  const { userInfo } = useUser();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isLoggedIn = !isEmptyObject(userInfo);

  const handleScrollIntoView = (page) => {
    if (page === "Features") {
      ScrollIntoView("featureRef");
    }
    if (page === "FAQ") {
      ScrollIntoView("faqRef");
    }
  };

  const handleMenuClick = (page) => {
    handleCloseNavMenu();
    if (page === "Login" || page === "Sign Up") {
      navigate(`/auth/${page.toLowerCase().replace(/\s/g, "")}`);
    } else if (page === "Pricing" || page === "WAEC") {
      navigate(`/${page.toLowerCase()}`);
    } else {
      handleScrollIntoView(page);
    }
  };
  return (
    <div
      className="landingPageFont"
      position="static"
      sx={{ backgroundColor: "transparent", boxShadow: "none", color: "white" }}
    >
      <Container maxWidth="xl">
        <div className="flex justify-between pt-3 items-center">
          <LogoImage
            onClick={() => navigate("/")}
            // sx={{ display: { xs: "none", md: "flex" } }}
            className="hidden md:flex"
            src={logo}
          />

          {/* Mobile Nav */}
          <LogoImage
            onClick={() => navigate("/")}
            sx={{ display: { xs: "flex", md: "none" } }}
            src={logo}
          />
          <div
            // sx={{ display: { xs: "flex", md: "none" } }}
            className="flex md:hidden ml-auto"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="ml-auto"
            >
              {/* <MenuIcon /> */}
              {!Boolean(anchorElNav) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="4" x2="20" y1="12" y2="12" />
                  <line x1="4" x2="20" y1="6" y2="6" />
                  <line x1="4" x2="20" y1="18" y2="18" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="lucide lucide-x"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                color: "black",
                width: "100%",
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleMenuClick(page)}>
                  <Typography color="black" textAlign="center">
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </div>

          {/* Desktop Nav */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              color: "white",
              gap: 2,
            }}
          >
            {pages.map((page) => {
              return (
                <React.Fragment key={page}>
                  {(page === "Features" || page === "FAQ") && (
                    <Button
                      LinkComponent={"a"}
                      onClick={() => handleScrollIntoView(page)}
                      sx={{
                        my: 2,
                        color: "white",
                        display: "block",
                        fontFamily: "Montserrat",
                        "&:hover": { color: "#f8ef07" },
                      }}
                    >
                      {page}
                    </Button>
                  )}
                  {(page === "Pricing" || page === "WAEC") && (
                    <Button
                      onClick={() => navigate(`/${page}`)}
                      sx={{
                        my: 2,
                        color: "white",
                        display: "block",
                        fontFamily: "Montserrat",
                        "&:hover": { color: "#f8ef07" },
                      }}
                    >
                      {page}
                    </Button>
                  )}
                </React.Fragment>
              );
            })}
          </Box>

          {/* UserCard */}
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "block" },
              // justifyContent: "center",
            }}
          >
            <Stack
              direction="row"
              gap={3}
              sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}
            >
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "transparent",
                  border: 1,
                  borderColor: "white",
                  fontFamily: "Montserrat",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#40499D",
                  },
                }}
                onClick={() => navigate("/auth/signup")}
              >
                Sign Up
              </Button>
              <Button
                sx={{
                  color: "white",
                  fontFamily: "Montserrat",
                  backgroundColor: "transparent",
                  border: 1,
                  borderColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#40499D",
                  },
                }}
                onClick={() => navigate("/auth/login")}
              >
                Login
              </Button>
            </Stack>
            {/* <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                  <Link to={setting?.linkTo}>
                    <Typography fontFamily={"Montserrat"} textAlign="center">
                      {setting?.label}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </div>
      </Container>
    </div>
  );
}
export default NavMenu;
