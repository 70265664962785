import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserCategory } from "../../../redux/auth/authActions";
import useUser from "../../../hooks/useUser";
import CustomizedTabs from "../../../components/adminManageAmount/CustomizedTabs";
import { fetchSubPlans } from "../../../redux/subscriptions";
import useSubscription from "../../../hooks/useSubscription";
import DataTable from "../../../components/DataTable/DataTable";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { EditIcon } from "../../../assets/icons/Icons";
import EditAmountDialog from "../../../components/adminManageAmount/EditAmountDialog";
import { useNotifications } from "../../../components/notifications/notifications";
import { formatCurrency } from "../../../helpers/helper";

const ManageAmount = () => {
  const dispatch = useDispatch();
  const { userCategory } = useUser();
  const { subscriptionPackageLoader, allSubPlan } = useSubscription();
  const [value, setValue] = useState(0);
  const [firstCategoryId, setFirstCategoryId] = useState(null);
  const [amountTableData, setAmountTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [ToastState, setToastState] = useNotifications();

  useEffect(() => {
    dispatch(getUserCategory());
  }, []);

  const mainCategories = userCategory?.filter(
    (category) =>
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() !== "teacher" &&
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() != "parent"
  );

  useEffect(() => {
    if (
      mainCategories.length > 0 &&
      firstCategoryId !== mainCategories[0]?.CategoryID
    ) {
      const catId = mainCategories[0]?.CategoryID;
      dispatch(fetchSubPlans(catId));
      setFirstCategoryId(catId);
    }
  }, [dispatch, mainCategories, firstCategoryId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedCategoryId = mainCategories[newValue]?.CategoryID;
    dispatch(fetchSubPlans(selectedCategoryId));
  };

  useEffect(() => {
    if (allSubPlan?.data?.length > 0) {
      const createTableData = () => {
        let TableData = allSubPlan?.data?.map((val) => [
          "",
          val.PackageName,
          formatCurrency(val.Amount),
          val,
        ]);
        return TableData;
      };
      setAmountTableData(createTableData());
    }
  }, [allSubPlan]);

  const handleOpenDialog = (value, event) => {
    setSelectedRowData(value);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const columns = [
    {
      name: "S/N",
      label: "S/N",
      options: {
        setCellProps: () => ({ style: { padding: "20px" } }),
        //   customHeadLabelRender: ({ index, ...column }) => {
        //   return (
        //     <Typography sx={ColumnTextStyle} key={index}>
        //       {column.label}
        //     </Typography>
        //   );
        // },
        customBodyRender: (value, tableMeta) => {
          return <span>{tableMeta.rowIndex + 1} </span>;
        },
      },
    },
    {
      name: "packageName",
      label: "Package Name",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, event) => {
          // const rowData = amountTableData[tableMeta.rowIndex];
          console.log(value, "rowData")
          return (
            <>
              <Tooltip title={"Edit Amount"}>
                <IconButton
                  size="small"
                  onClick={() => handleOpenDialog(value, event)}
                >
                  <EditIcon color="#C4B151" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  return (
    <>
      <Typography variant="h6" fontWeight={"bold"}>
        Manage Amount
      </Typography>
      <br />
      <CustomizedTabs
        value={value}
        label={mainCategories?.map((category) => category?.CategoryName)}
        handleChange={handleChange}
      />
      <DataTable
        data={amountTableData}
        columns={columns}
        loading={subscriptionPackageLoader}
        topMargin={true}
      />
      {selectedRowData && (
        <EditAmountDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          selectedRowData={selectedRowData}
          setToastState={setToastState}
        />
      )}
      <ToastState />
    </>
  );
};

export default ManageAmount;
