import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  Dialog,
  CardContent,
  ListItem,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  TextField,
  DialogContent,
  Paper,
  IconButton,
  NativeSelect,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { createSvgIcon } from "@mui/material/utils";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import useContentUpload from "../../../hooks/useContentUpload";
import {
  AddSubjectTopics,
  DeleteSubjectTopics,
  UpdateSubjectTopics,
  getTopicsBySubjectId,
  updateSubjectAction,
} from "../../../redux/contentUpload/contentUploadActions";
import { SubjectBookIcon, SubjectIcon } from "../../../assets/icons/Icons";
import { Color } from "../../../assets/themes/Color";
import { useNotifications } from "../../../components/notifications/notifications";
import { isArray } from "../../../helpers/helper";
import useAdminUser from "../../../hooks/useAdminUser";
import DataUsageIcon from "@mui/icons-material/DataUsage";


const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

const SubjectTopics = ({ subjectId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ToastState, setToastState] = useNotifications();

  const [open, setOpen] = React.useState(false);
  const [openEditMenu, setOpenEditMenu] = React.useState(false);
  const [topicName, setTopicName] = React.useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [topicId, setTopicIdEditMenu] = React.useState("");
  const [topicNameEdit, setTopicNameEdit] = React.useState("");
  const [loadTopics, setLoadTopics] = useState(false);


  const { subjectData, topics, loading } = useContentUpload();


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();

  const [openBM, setOpenBM] = React.useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (subjectId) {
      dispatch(getTopicsBySubjectId(subjectId));
    }
  }, [loadTopics]);


  useEffect(() => {
    setLoadTopics(false);
    setFilteredData(topics);
  }, [loadTopics, topics]);

  useEffect(() => {
    if (search != "") {
      setFilteredData(
        topics
          ?.filter((sub) =>
            sub?.TopicName.toLowerCase()?.includes(search.toLowerCase())
          )
      );
    } else {
      setFilteredData(topics);
    }
  }, [search]);

  const handleClickOpenBM = () => {
    setOpenBM(true);
  };
  const handleCloseBM = () => {
    setOpenBM(false);
  };

  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (e, topic) => {
    setAnchorEl(e.currentTarget);
    setTopicNameEdit(topic.TopicName);
    setTopicIdEditMenu(topic.TopicId);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSuccess = () => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Topic added successfully",
    });
    setLoadTopics(true);
  };

  const onSuccessEdit = () => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Topic updated successfully",
    });
    setLoadTopics(true);
  };

  const onSuccessDeactivate = () => {
    setDeleteModal(false);
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Topic deleted successfully",
    });
    setLoadTopics(true);
  };

  const onFailDeleteTopics = (res) => {
    setDeleteModal(false);
    setToastState({
      isOpen: true,
      severity: "error",
      message: "Topic failed to delete",
    });
  };

  const handleSubmit = () => {
    handleCloseBM();
    dispatch(
      AddSubjectTopics(
        {
          userId: userInfo?.data?.profileId,
          topicName: topicName,
          subjectId: subjectId,
        },
        onSuccess
      )
    );
  };

  const handleEdit = () => {
    dispatch(
      UpdateSubjectTopics(
        {
          subjectId: subjectId,
          topicName: topicNameEdit,
          topicId: topicId,
          userId: userInfo?.data?.profileId,
        },
        onSuccessEdit
      )
    );
    setAnchorEl(null);
    setOpenEditMenu(false);
  };

  const handleDeactivate = () => {
    dispatch(
      DeleteSubjectTopics(
        { topicId: topicId },
        onSuccessDeactivate,
        onFailDeleteTopics
      )
    );
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleEditTopic = (topic) => {
    setAnchorEl(null);
    setOpenEditMenu(true);
  };

  return (
    <>
      <Box sx={{ my: 3 }}>
        <Card variant="outlined">
          <CardContent sx={{ pb: 1 }}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={6}>
                <FormControl sx={{ mt: 1 }}>
                  <OutlinedInput
                    id="outlined-adornment-search"
                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    size="small"
                    placeholder="search exam topic..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <div className="flex space-x-4 items-center justify-end">
                  <div className="flex"
                  >
                    <Button variant="outlined" sx={{ textTransform: 'none', color: '#000', borderColor: '#000', backgroundColor: '#F6F6F6' }} startIcon={<FilterListIcon />}>
                      Filters
                    </Button>
                  </div>
                  <div className="flex"
                  >
                    <Button sx={{ backgroundColor: '#40499D', borderRadius: 2, textTransform: 'none' }} variant="contained" startIcon={<PlusIcon />} onClick={() => handleClickOpenBM()}>Add Topic</Button>
                  </div>

                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Box>

        <Box>
          {(Array.isArray(filteredData) && filteredData?.length > 0) ?
            filteredData
              ?.map((topic, i) => (
                <Card variant="outlined" sx={{ mt: 1 }} key={i}>
                  <CardContent>
                    <div className='flex items-center justify-between'>
                      <div sx={{ pl: 5 }}  className='basis-10/12'>
                        <Box sx={{ pl: 3 }}>
                          <Typography
                            sx={{
                              color: "#000",
                              fontFamily: "DM Sans",
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "26.04px",
                            }}
                          >
                            {topic?.TopicName}
                          </Typography>

                        </Box>
                      </div>
                      <div className='basis-1/12'>
                        <Box
                          m={1}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <ArrowRightAltIcon
                            onClick={() => {
                              navigate(
                                `/admin/contentupload/subject/topic/${topic?.TopicId}`
                              )
                            }}
                            sx={{ cursor: "pointer" }}
                          />
                          <MoreVertIcon
                            onClick={(e) => handleClickMenu(e, topic)}
                            sx={{ cursor: "pointer" }}
                          />

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuList>
                              <MenuItem
                                onClick={() => handleEditTopic(topic)}
                              >
                                <ListItemIcon>
                                  <BorderColorOutlinedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    color: "#313131",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "14px",
                                  }}
                                >
                                  Edit Topic
                                </ListItemText>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setDeleteModal(true);
                                }}
                              >
                                <ListItemIcon>
                                  <PowerSettingsNewIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    color: "#313131",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "14px",
                                  }}
                                >
                                  Delete Topic
                                </ListItemText>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))
            :
            <Card variant="outlined" sx={{ borderRadius: '5px', marginBottom: 14 }}>
              <CardContent>
                <Typography>
                  <div className="text-center font-bold">
                    <h3 className="e-study-heading-two">
                      No topics available
                    </h3>
                  </div>
                </Typography>
              </CardContent>
            </Card>
          }
        </Box>
      </Box>

      <Dialog
        open={openEditMenu}
        onClose={() => setOpenEditMenu(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={'xl'}
      >
        <DialogTitle id="alert-dialog-title">
          Edit Topic
          <Typography
            sx={{
              color: "#919191",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "14px",
            }}
          >
            Edit topic below
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEditMenu(false)}
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <Grid container spacing={1}>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    mt: 1,
                    color: "#40499D",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                  }}
                >
                  Topic
                </Typography>

                <input
                  style={{ marginTop: ".3rem" }}
                  className="btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={topicNameEdit}
                  onChange={(e) => setTopicNameEdit(e.target.value)}
                ></input>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleEdit}
            style={{ backgroundColor: "#40499D" }}
            class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-fit mx-auto disabled:cursor-not-allowed disabled:e-study-bg-primary"
          >
            {!loading ? (
              "Update Topic"
            ) : (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            )}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBM}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={'xl'}
      >
        <DialogTitle id="alert-dialog-title">
          Add New Topic
          <Typography
            sx={{
              color: "#919191",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "14px",
            }}
          >
            Set up topic below
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseBM}
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <Grid container spacing={1}>

              <Grid item xs={12}>
              <Typography
                  sx={{
                    mt: 1,
                    color: "#40499D",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                  }}
                >
                  Topic
                </Typography>
                <input
                  style={{ marginTop: ".3rem" }}
                  className="btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Enter Topic Name"
                  onChange={(e) => setTopicName(e.target.value)}
                ></input>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => handleSubmit()}
            disabled={!topicName}
            style={{ backgroundColor: "#40499D" }}
            className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-fit mx-auto disabled:cursor-not-allowed disabled:e-study-bg-primary"
          >
            {!loading ? (
              "Submit"
            ) : (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            )}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteModal}
        onClose={() => setDeleteModal(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Paper sx={{ p: 1 }}>
            <h3 className="e-study-heading-three">
              Are you sure you want to delete this topic?
            </h3>
          </Paper>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between items-center space-x-3 lg:space-x-9">
            <span
              className="e-study-primary cursor-pointer"
              onClick={() => setDeleteModal(false)}
            >
              No
            </span>
            <button
              onClick={() => handleDeactivate()}
              style={{ backgroundColor: "#FA7E77" }}
              class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            >
              Yes
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <ToastState />
    </>
  );
};

export default SubjectTopics;
