import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import DataUsageIcon from "@mui/icons-material/DataUsage";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(19px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(19px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#40499D",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const pushNotifications = [
  {
    title: "Email push notifications",
    subTitle: "Get email to find out what’s going on when you are not online.",
    /* onChange: () => alert("Is it working?"), */
  },
  {
    title: "Desktop push notifications",
    subTitle:
      "Get push notification in-app to find out what’s going on when on online.",
    /* onChange: () => alert("Is it working?"), */
  },
  {
    title: "Mobile push notifications",
    subTitle:
      "Get push notification in-app to find out what’s going on when online.",
    /* onChange: () => alert("Is it working?"), */
  },
];
const Notifications = () => {
  return (
    <Box p={2}>
      <Typography variant="h6" fontWeight={600} color={"#313131"}>
        Coming soon.....
      </Typography>
    </Box>


    // <Grid container spacing={3}>
    //   <Grid item xs={12}>
    //     <Paper>
    //       <Box p={2}>
    //         <Typography variant="h6" fontWeight={600} color={"#313131"}>
    //           Notifications
    //         </Typography>
    //       </Box>
    //       <Divider />
    //       <Box p={3}>
    //         <Typography fontWeight={600}>Notify me when...</Typography>
    //         <FormGroup sx={{ color: "#A1A1A1" }}>
    //           <FormControlLabel
    //             control={<Checkbox defaultChecked />}
    //             label="Daily Productivity Update"
    //           />
    //           <FormControlLabel
    //             control={<Checkbox />}
    //             label="New event created"
    //           />
    //           <FormControlLabel
    //             control={<Checkbox />}
    //             label="New past question uploaded"
    //           />
    //         </FormGroup>
    //       </Box>

    //       {pushNotifications?.map((item) => (
    //         <Stack
    //           key={item?.title}
    //           direction="row"
    //           p={3}
    //           justifyContent={"space-between"}
    //         >
    //           <Box>
    //             <Typography fontWeight={600}>{item?.title}</Typography>
    //             <Typography color="#A1A1A1">{item?.subTitle}</Typography>
    //           </Box>
    //           <AntSwitch
    //             inputProps={{ "aria-label": "ant design" }}
    //             onChange={item?.onChange}
    //           />
    //         </Stack>
    //       ))}

    //       <Box p={3}>
    //         <Divider sx={{ mt: 2 }} />
    //         <Box m="15px 0px" display={"flex"} justifyContent={"end"}>
    //           <Button
    //             variant="contained"
    //             color="primary"
    //             sx={{ borderRadius: "5px", textTransform: "inherit" }}
    //           >
    //             Save Settings
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Paper>
    //   </Grid>
    // </Grid>
  );
};

export default Notifications;
