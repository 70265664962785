import React from 'react'

const CalculatorButton = ({onClick, value, children, key}) => {
  return (
    <button onClick={onClick} key={key} className='inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50  h-10 px-4 py-2 bg-primary text-white hover:bg-primary/90
'>
      {children}
    </button>
  )
}

export default CalculatorButton