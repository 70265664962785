import buttons, { Button, Label, BtnType } from "../constants/buttons";

import * as operate from "./operate";

function selectButton(label) {
  const button = buttons.find((btn) => btn.label === label);
  if (!button) throw new Error("Invalid button label");
  return button;
}

const handleMap = {
  clear: "handleClear",
  number: "handleNum",
  operation: "handleOp",
  decimal: "handleDecimal",
  equals: "evaluateExp",
  ext: "handleExt",
  paren: "handleParen",
};

export default function handleInput(label, state) {
  const button = selectButton(label);
  const func = handleMap[button?.type];
  return operate[func]({ label, state });
}
