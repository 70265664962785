// material
import { styled } from "@mui/material/styles";
import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(2),
  color: theme.palette.warning.darker,
  backgroundColor: "#fff",
  borderRadius: "16px",
  border: "1px solid rgba(233, 235, 254, 0.50)",
  gap: theme.spacing(3),
  filter: "drop-shadow(0px 4px 14px rgba(179, 179, 179, 0.25))",
  border: `1px solid ${theme.palette.grey[200]}`,
  cursor: "text",
  userSelect: "none",
  maxHeight: "125px",
}));

export default function Cards({ cards }) {
  return (
    <Grid container spacing={1}>
      {cards &&
        cards?.map((card) => (
          <Grid key={card.text} item xs={12} sm={6} md>
            <>
              {card?.loading ? (
                <Skeleton variant="rounded" width="max-width" height="96px" />
              ) : (
                <RootStyle>
                  {card?.icon}
                  <Box pt={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{ opacity: 0.72, fontSize: "13px" }}
                    >
                      {card?.text}
                    </Typography>
                    <Typography
                      variant="h4"
                      fontWeight={700}
                      fontSize={"18px"}
                      color={"#45464E"}
                    >
                      {card?.total ?? 0}
                    </Typography>
                  </Box>
                </RootStyle>
              )}
            </>
          </Grid>
        ))}
    </Grid>
  );
}
