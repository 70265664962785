import React from 'react'
import AvatarImg from './AvatarImg'
import { Box } from '@mui/material'

const AvatarWithText = ({ text, size, email }) => {
    return (
        <Box display="flex" alignItems="center" gap={1}><AvatarImg text={text} size={size} /><Box><Box sx={{color:"#313131", fontWeight:700, fontSize:"14px"}}>{text ?? ''}</Box><Box sx={{color:"#919191", fontSize:"11px"}}>{email ?? ""}</Box></Box></Box>
    )
}

export default AvatarWithText