import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Carousel from "./Carousel";
import useResponsive from "../../hooks/useMediaQuery";
import {
  InsightsIcon,
  LibraryIcon,
  MguidesIcon,
  MockTestIcon,
  SimulateIcon,
  StudyStreakIcon,
  TailorIcon,
} from "../../assets/icons/Icons";
import Carousel2 from "./Carousel2";

const images = [
  {
    label: "Student",
    title: (
      <Typography
        fontFamily={"Montserrat"}
        color="#30312C"
        fontWeight={600}
        sx={{ fontSize: { xs: "28px", md: "43.488px" } }}
      >
        <span style={{ color: "#B9B30F" }}>Prep </span> at your convenience.
      </Typography>
    ),
    items: [
      {
        text: (
          <p style={{ fontFamily: "Montserrat" }}>
            <b style={{ color: "#696984", fontFamily: "Montserrat" }}>
              WAEC library
            </b>
            : Access WAEC official past questions covering various subjects from
            past and recent years.
          </p>
        ),
        icon: <LibraryIcon />,
      },
      {
        text: (
          <p style={{ fontFamily: "Montserrat" }}>
            <b style={{ color: "#696984", fontFamily: "Montserrat" }}>
              Mock Test and Theories
            </b>
            : Easily prep for examination with unlimited mock test including
            theory. 
          </p>
        ),
        icon: <MockTestIcon />,
      },
      {
        text: (
          <p style={{ fontFamily: "Montserrat" }}>
            <b style={{ color: "#696984", fontFamily: "Montserrat" }}>
              Marking guides
            </b>
            : Make studying enjoyable and effective with direct marking
            guidelines sourced and credited from WAEC .
          </p>
        ),
        icon: <MguidesIcon />,
      },
      {
        text: (
          <p style={{ fontFamily: "Montserrat" }}>
            <b style={{ color: "#696984", fontFamily: "Montserrat" }}>
              Study streaks
            </b>
            : Get real-time access to study progress and activities.   
          </p>
        ),
        icon: <StudyStreakIcon />,
      },
    ],
  },
];
const Packages = ({ refs }) => {
  const isMobile = useResponsive("down", "md");
  return (
    <Box
      ref={refs}
      bgcolor={"#fff"}
      padding={isMobile ? "60px 20px" : "30px 40px 80px 40px"}
      boxSizing={"border-box"}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: 6,
        }}
      >
        {images?.map((step, index) => (
          <div key={step.label} style={{ width: "100%" }}>
            <Grid container spacing={4} alignItems={"center"}>
              <Grid item md={6} xs={12} sx={{ order: { xs: 2, md: 1 } }}>
                <Carousel2 />
              </Grid>
              <Grid item md={6} xs={12} sx={{ order: { xs: 1, md: 2 } }}>
                <Box>
                  {step?.title}
                  <List>
                    {step?.items?.map((item) => (
                      <ListItem
                        key={item?.text}
                        sx={{
                          display: { xs: "block", md: "flex" },
                          fontFamily: "Montserrat",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            borderRadius: "50%",
                            p: 2,
                            mr: 3,
                            backgroundColor: "#FBFBFB",
                            boxShadow:
                              "0px 13.143px 38.552px rgba(13, 15, 28, 0.12)",
                          }}
                        >
                          {item?.icon}
                        </ListItemIcon>
                        <ListItemText
                          sx={{ fontFamily: "Montserrat" }}
                          primary={item?.text}
                          color="#696984"
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </div>
        ))}
      </Container>
    </Box>
  );
};

export default Packages;
