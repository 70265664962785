import React, { useEffect, useState } from "react";
import SchoolPricingTable from "./schoolPricingTable";
import { Box, Button, Typography } from "@mui/material";
import { FreemiumIcon, InfoIcon } from "../../../../../assets/icons/Icons";
import { ReactComponent as TotalIcon } from "../../../../../assets/images/TotalIcon.svg";
import { ReactComponent as UpArrowIcon } from "../../../../../assets/images/UpArrow.svg";
import { ReactComponent as DownArrowIcon } from "../../../../../assets/images/DownArrow.svg";
import { Link } from "react-router-dom";
import useUser from "../../../../../hooks/useUser";
import useSettings from "../../../../../hooks/useSettings";
import Support from "../../../../Settings/Support";
import Subscription from "../../../../Settings/Subscription";
import { Notifications, Security } from "@mui/icons-material";
import General from "../../../../Settings/General";
import NavTabs from "../../../../Settings/NavTabs";
import PreSub from "./preSub";
import { formatCurrency } from "../../../../../helpers/helper";

const PostSub = ({ newSubscription, packages }) => {
  const { userInfo: { profileId, categoryName }, } = useUser();
  const [value, setValue] = useState(0);
  const [schoolSub, setSchoolSub] = useState([]);
  const [sponsorSchoolSub, setSponsorSchoolSub] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (packages?.allSubsData && Array.isArray(packages?.allSubsData)) {
      const activeSubscriptions = packages?.allSubsData.filter(
        (subscription) => subscription.SubscriptionStatus === "Active"
      );

      const isSponsored = activeSubscriptions.filter(
        (subscription) => subscription.IsSchoolSponsored === true
      );
      setSponsorSchoolSub(isSponsored);

      const isSchoolSub = activeSubscriptions.filter(
        (subscription) => subscription.IsSchoolSponsored != true
      );
      setSchoolSub(isSchoolSub);
    }
  }, [packages]);

  const tabs = [
    {
      label: "My Active Subscriptions",
      content: <PreSub subDetails={schoolSub} />,
    },
    {
      label: "Sponsored Subscriptions",
      content: <PreSub subDetails={sponsorSchoolSub} />,
    },
  ];
  return (
    <>
      <Box
        sx={{
          borderRadius: "15px",
          border: "1px solid #DFDFDF",
          bgcolor: "white",
          mb: 2,
        }}
      >
        <Box
          p={2}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography sx={{ color: "#919191", fontSize: "14px" }}>
              Total amount paid (₦)
            </Typography>
            <Typography
              sx={{
                color: "#313131",
                fontSize: "28px",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              {formatCurrency(packages?.totalSubscriptionsAmount)}
            </Typography>
          </Box>
          <Box display={"flex"} gap={3} alignItems={"center"}>
            <Button
              variant="contained"
              sx={{
                textTransform: "inherit",
                borderColor: "#656565",
                color: "#fff",
                paddingX: "20px",
              }}
              onClick={newSubscription}
            >
              Add New Subscription
            </Button>
          </Box>
        </Box>

        <Box pl={2} pb={2} display={"flex"} gap={4} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <TotalIcon />
            <Typography sx={{ fontWeight: 600 }}>
              {packages.totalLicenseNo} Total Licenses
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <UpArrowIcon />
            <Typography sx={{ fontWeight: 600 }}>
              {packages.totalAllocatedNo} Total Allocated Licenses
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <DownArrowIcon />
            <Typography sx={{ fontWeight: 600 }}>
              {packages.totalUnallocatedNo} Total Unallocated Licenses
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "15px",
          border: "1px solid #DFDFDF",
          bgcolor: "white",
          mb: 2,
        }}
      >
        <Box pt={4}>
          <NavTabs value={value} handleChange={handleChange} tabs={tabs} />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          borderRadius: "15px",
          border: "1px solid #DFDFDF",
          bgcolor: "white",
        }}
      >
        <SchoolPricingTable sub={packages} />
      </Box>
    </>
  );
};

export default PostSub;
