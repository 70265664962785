import { Avatar, Box, Paper, Typography } from '@mui/material'
import React from 'react'
import GoldMedal1 from "../../assets/images/GoldMedal1.png"
import GoldMedal2 from "../../assets/images/GoldMedal2.png"
import GoldMedal3 from "../../assets/images/GoldMedal3.png"
import LeadeboardImg from "../../assets/images/LeaderboardImg.png"
import LeadeboardImg2 from "../../assets/images/LeaderboardImg2.png"
import LeadeboardImg3 from "../../assets/images/LeaderboardImg3.png"
import LeadeboardImg4 from "../../assets/images/LeaderboardImg4.png"

const LeaderboardCard = () => {
    const winners = [
        {
            profileImg: LeadeboardImg2,
          name: 'Emmanuel Zinny',
          subject: 'Science',
          medalImage: GoldMedal1,
        },
        {
            profileImg: LeadeboardImg3,
          name: 'Damilola Peter',
          subject: 'Arts',
          medalImage: GoldMedal2,
        },
        {
            profileImg: LeadeboardImg4,
          name: 'Amaka Ugochi',
          subject: 'Commercial',
          medalImage: GoldMedal3,
        },
       
      ];
  return (
    <div>
        <Paper sx={{backgroundColor: "#40499D", p: 2, mb: 2, borderRadius: 2}}>
            <Typography sx={{pb: 1.5, color: "#fff"}}>Leaderboard</Typography>
            {winners.map((winner, index) => ( 
            <Paper sx={{display: "flex", justifyContent: "space-between", alignItems: "center", p: 1, backgroundColor: "#fff", mb: 2}}>
                <Box sx={{display: "flex", alignItems: "center"}}>
<Avatar src={winner.profileImg} />
<Box sx={{pl: 2}}>
    <Typography sx={{fontSize: "15px",}}>{winner.name}</Typography>
    <Typography sx={{fontSize: "13px", color: "#b4b4b4"}}>{winner.subject}</Typography>
</Box>
</Box>
<Box>
<img src={winner.medalImage} alt={`Medal ${index}`} width={30} height={"auto"}/>
</Box>
            </Paper>
            ))}
        </Paper>
    </div>
  )
}

export default LeaderboardCard