import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Box, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import ApplogoDark from "../../../../../assets/images/ApplogoDark.png"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { formatCurrency } from "../../../../../helpers/helper";
import useSubscription from "../../../../../hooks/useSubscription";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& > .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  // "& .MuiDialogTitle-root": {
  //   backgroundColor: "#FFD14A",
  //   height: "1px",
  //   borderRadius: "16.9px 16.9px 0px 0px",
  //   color: "#fff",
  // },
  "& .MuiDialogContent-root": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    position: "relative",
  },
}));
function createData(name, protein) {
  return { name, protein };
}


export default function ConfirmPayment({ open, handleClose, handleConfirmProceed, studentsCount, subtotal, selectedSubscription }) {
  const subscription = useSubscription();
  const { paymentSumary } = subscription;

  const rows = [
    createData("Subscription Plan:", paymentSumary?.SubscriptionName),
    createData("Start Date:", moment(paymentSumary?.StartDate).format("MMM DD YYYY")),
    createData("Expiry Date:", moment(paymentSumary?.ExpiryDate).format("MMM DD YYYY")),
    createData("Qty:", `${paymentSumary?.Quantity}`),
    createData("Subtotal:", formatCurrency(paymentSumary?.TotalCost)),
    // createData("Platform fee:", formatCurrency(paymentSumary?.PlatformFee)),
    createData("Total Amount:", formatCurrency(paymentSumary?.TotalPayment)),
  ];

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "18px", width:"35%" } }}
        maxWidth={"xl"}
      >
        <DialogTitle
          sx={{ m: 0, p: 1, textAlign: "center" }}
          id="customized-dialog-title"
        ></DialogTitle>
        <DialogContent>
        <Box
        display={"flex"}
        justifyContent={"center"}
        marginTop={3}
      >
        <img
          src={ApplogoDark}
          alt="WAEC e-Study"
          style={{ width: "auto", height: "25px" }}
        />
        </Box>
        <Typography variant="h7" fontWeight={700} >
            Payment Summary
          </Typography>
          <FormControl fullWidth>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell
                        sx={{ color: "#313131", fontWeight: 900 }}
                        align="right"
                      >
                        {/* {index === 1 ? (
                          <span>
                            <Button
                              onClick={handleClose}
                              startIcon={<DriveFileRenameOutlineIcon fontSize={"small"}/>}
                              sx={{ marginRight: 2, textTransform:"capitalize" }}
                            >
                              Edit
                            </Button>
                             {row.protein}
                          </span>
                        ) : ( */}
                          {row.protein}
                        {/* )} */}
                      </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
      <FormControl fullWidth>
        <Button
          onClick={handleConfirmProceed}
          sx={{
            borderRadius: "5px",
            p: 1.5,
            textTransform: "inherit",
            border: "1.5px solid var(--Primary-700, #0052D7)"
          }}
          variant="contained"
          color="primary"
        >
          Proceed to payment
        </Button>
      </FormControl>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#b4b4b4",
              m: 3,
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
