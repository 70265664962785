import { Visibility, VisibilityOff } from "@mui/icons-material";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { Button, Checkbox, Container, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import GlobalThemeProvider from "../../GlobalThemeProvider";
import AuthContainer from "../../components/AuthReuseables/AuthContainer";
import ConfirmationMessage from "../../components/AuthReuseables/ConfirmationMsg";
import PrivacyModal from "../../components/PrivacyPolicy/PrivacyModal";
import { useNotifications } from "../../components/notifications/notifications";
import { useGoogleAnalytics } from "../../hooks/usePageTracking";
import useUser from "../../hooks/useUser";
import { registerUserAction } from "../../redux/auth/authActions";

const Signup = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState("");
  const [msg, setMsg] = useState("");
  const [fail, setFail] = useState("");
  const [ToastState, setToastState] = useNotifications();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const { trackPage, trackSpecificEvent } = useGoogleAnalytics();
  trackPage();

  const getReferralCode = new URLSearchParams(location.search);
  const referralCode = getReferralCode.get("referral_code");

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    referral: referralCode ?? "",
    password: "",
    privacy: false,
    confirmPassword: "",
  });

  const passwordHandler = (e) => {
    const passwordValue = e.target.value;
    const minLength = 6;

    if (passwordValue.length < minLength && passwordValue !== "") {
      setPasswordHelperText(`Password must be at least ${minLength} characters`);
    } else {
      setPasswordHelperText("");
    }

    setFormValues({
      ...formValues,
      password: passwordValue,
    });
  };

  const confirmPasswordHandler = (e) => {
    const confirmPasswordValue = e.target.value;
    const passwordValue = formValues.password;

    if (confirmPasswordValue !== passwordValue) {
      setConfirmPasswordHelperText("Password and confirm Password are not a match");
    } else {
      setConfirmPasswordHelperText("");
    }

    setFormValues({
      ...formValues,
      confirmPassword: confirmPasswordValue,
    });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setAccepted(!accepted);
  };

  useEffect(() => {
    if (msg) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      setTimeout(() => {
        setShowConfirmation(true);
      }, 2000);
    }
  }, [msg]);

  useEffect(() => {
    if (fail) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: fail,
      });
    }
  }, [fail]);

  const handleSubmit = () => {
    let params = {
      Firstname: formValues.firstName,
      Surname: formValues.lastName,
      Email: formValues.email,
      Phone: formValues.phone,
      Referral: formValues.referral,
      Password: formValues.password,
      ConfirmPassword: formValues.confirmPassword,
    };
    trackSpecificEvent("Sign Up", "User created account!");
    dispatch(
      registerUserAction(
        params,
        (res) => setMsg(res.data.msg),
        (res) => setFail(res.data.msg)
      )
    );
  };

  const disabled =
    !isEmailValid ||
    user.isLoading ||
    !formValues.email ||
    !formValues.phone ||
    !formValues.password ||
    !formValues.confirmPassword ||
    !formValues.firstName ||
    !formValues.lastName ||
    (!isChecked && !accepted) ||
    formValues.password !== formValues.confirmPassword;

  return (
    <GlobalThemeProvider>
      <AuthContainer>
        {showConfirmation ? (
          <ConfirmationMessage email={formValues?.email} />
        ) : (
          <Container component="main" maxWidth="xs">
            <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", textAlign: "center" }}>
              Sign Up
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontSize: "15px",
                mb: 3,
                textAlign: "center",
                color: "#5F5F5F",
              }}
            >
              Sign up first to onboard as a <strong> Student</strong>, <strong> School</strong>, or <strong> Sponsor</strong>
            </Typography>
            <form name="user_signup">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="given-name"
                    value={formValues.firstName}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        firstName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    value={formValues.lastName}
                    onChange={(e) => setFormValues({ ...formValues, lastName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formValues.email}
                    error={!isEmailValid}
                    helperText={isEmailValid ? "" : "Please provide a valid email"}
                    onChange={(e) => {
                      const emailValue = e.target.value;
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                      setIsEmailValid(emailRegex.test(emailValue));
                      setFormValues({ ...formValues, email: emailValue });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    autoComplete="phone"
                    value={formValues.phone}
                    error={!isPhoneValid}
                    helperText={isPhoneValid ? "" : "Please provide a valid phone number"}
                    onChange={(e) => {
                      const phoneValue = e.target.value;
                      const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/;

                      setIsPhoneValid(phoneRegex.test(phoneValue));
                      setFormValues({ ...formValues, phone: phoneValue });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="referral"
                    label="Referral (optional)"
                    name="referral"
                    autoComplete="referral"
                    value={formValues.referral}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        referral: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    value={formValues.password}
                    helperText={passwordHelperText}
                    error={passwordHelperText?.length > 0}
                    onChange={(e) => passwordHandler(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    autoComplete="confirm-password"
                    helperText={confirmPasswordHelperText}
                    error={confirmPasswordHelperText?.length > 0}
                    value={formValues.confirmPassword}
                    onChange={(e) => confirmPasswordHandler(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle confirm password visibility" onClick={toggleConfirmPasswordVisibility} edge="end">
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <div classname="flex items-center gap-4 mt-4">
                <Checkbox className="text-slate-400 rounded-[4px] border-slate-300" checked={isChecked || accepted} onChange={handleCheckboxChange} />
                <label
                  variant="body1"
                  sx={{
                    mt: 2,
                    fontSize: "12px",
                    textAlign: "center",
                    color: "#5F5F5F",
                  }}
                >
                  Accept{" "}
                  <Link onClick={handleOpenModal} style={{ fontWeight: "600" }}>
                    Terms
                  </Link>{" "}
                  and{" "}
                  <Link onClick={handleOpenModal} style={{ fontWeight: "600" }}>
                    Privacy Policy
                  </Link>
                  .
                </label>
              </div>

              <PrivacyModal accepted={accepted} setAccepted={setAccepted} open={modalOpen} handleClose={handleCloseModal} />

              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                sx={{
                  mt: 2,
                  py: 2,
                  borderRadius: "8px",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              >
                {!user.isLoading ? "Create Account" : <DataUsageIcon size={24} className="ml-4 animate-spin" />}
              </Button>
            </form>

            <Typography
              sx={{
                mt: 2,
                textAlign: "center",
                fontSize: "15px",
                color: "#5F5F5F",
              }}
            >
              Already have an account?{" "}
              <Link to="/auth/login" style={{ color: "#40499D", textDecoration: "none" }}>
                Login
              </Link>
            </Typography>

            <form classname="flex gap-2"></form>
          </Container>
        )}
        {/* )} */}
      </AuthContainer>
      <ToastState />
    </GlobalThemeProvider>
  );
};

export default Signup;
