import React, { useState } from "react";
import {
  Button,
  Popover,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Stack,
  Paper,
  colors,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Autocomplete,
  TextField,
  OutlinedInput,
} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { FilterIcon } from "../../assets/icons/Icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const RevenueFilter = ({ options, onClick, reset, filters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState({
    startDate: null,
    endDate: null,
    currency: 566,
    paymentStatus: "approved",
    usertype: "all",
  });
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [startDateHelperText, setStartDateHelperText] = useState("");
  const [endDateHelperText, setEndDateHelperText] = useState("");

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleRadioGroup = (event, name) => {
    setValue({
      ...value,
      [name]: event.target.value,
    });
  };

  const handleChange = (event, name) => {
    event.preventDefault();
    setValue({
      ...value,
      [name]: event.target.value,
    });
  };

  const handleStartDateChange = (newValue) => {
    if (!newValue) return; // Handle null or undefined newValue gracefully

    const formattedValue = dayjs(newValue);
    const formattedEndDate = dayjs(value?.endDate);
    if (formattedEndDate && formattedValue?.isAfter(formattedEndDate)) {
      setStartDateError(true);
      setStartDateHelperText("Start Date should be lesser than End Date");
    } else {
      setValue({
        ...value,
        startDate: formattedValue,
      });
      setStartDateError(false);
      setStartDateHelperText("");
    }
  };

  const handleEndDateChange = (newValue) => {
    if (!newValue) return; // Handle null or undefined newValue gracefully

    const formattedValue = dayjs(newValue);
    const formattedStartDate = dayjs(value?.startDate);
    if (formattedStartDate && formattedValue?.isBefore(formattedStartDate)) {
      setEndDateError(true);
      setEndDateHelperText("End Date should be greater than Start Date");
    } else {
      setValue({
        ...value,
        endDate: formattedValue,
      });
      setEndDateError(false);
      setEndDateHelperText("");
    }
  };

  const handleSubmit = (event) => {
    // onClick(value);
    if (value?.startDate && !value?.endDate) {
      setEndDateError(true);
      setEndDateHelperText("End Date is required");
      return;
    }

    if (!value?.startDate && value?.endDate) {
      setStartDateError(true);
      setStartDateHelperText("Start Date is required");
      return;
    }

    let data = value;
    if (value?.startDate) {
      // data.startDate = data?.startDate?.format('YYYY-MM-DD');
    }
    if (value?.endDate) {
      // data.endDate = data?.endDate?.format('YYYY-MM-DD');
    }

    const filteredValue = Object.fromEntries(
      Object.entries(data).filter(([key, val]) => val !== "" && val != "all" && val !== null)
    );

    onClick(filteredValue);
  };

  const handleReset = (event) => {
    reset();
    setValue({
      startDate: null,
      endDate: null,
      currency: 566,
      paymentStatus: "all",
      usertype: "all",
    });
    setStartDateError(false);
    setStartDateHelperText("");
    setEndDateError(false);
    setEndDateHelperText("");
  };
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex justify-between">
        <div className="flex gap-1">
          <FilterIcon />
          <p>Filter by:</p>
        </div>

        <div className="flex gap-4">
          <Button
            onClick={handleReset}
            sx={{
              borderRadius: "7px",
              textTransform: "initial",
              color: colors.grey[500],
              borderColor: colors.grey[500],
              "&:hover": { borderColor: colors.grey[500] },
            }}
            size="small"
            variant="outlined"
          >
            Clear Filters
          </Button>
          <Button
            disableElevation
            onClick={handleSubmit}
            sx={{
              borderRadius: "7px",
              textTransform: "initial",
            }}
            size="small"
            variant="contained"
          >
            Apply Filters
          </Button>
        </div>
      </div>

      <div className="w-full flex gap-4 px-6 py-6 bg-white rounded-[15px]">
        {options?.map((option, index) => (
          <div className="">
            {option.type === "dropdown" ? (
              <form key={index} className="flex flex-col gap-1 ">
                <label>{option.group}</label>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name={option.name}
                  value={value[option.name] || 0}
                  onChange={(e) => handleChange(e, option.name)}
                  input={<OutlinedInput />}
                  className="w-[150px]"
                >
                  {option.name !== "currency" && (
                    <MenuItem key="all" value="all">
                      All
                    </MenuItem>
                  )}
                  {option?.options?.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            ) : option.type === "radio" ? (
              <form key={index} className="flex flex-col gap-1 ">
                <label>{option.group}</label>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name={option.name}
                  value={value[option.name] || 0}
                  onChange={(e) => handleChange(e, option.name)}
                  input={<OutlinedInput />}
                  className="w-[150px]"
                  lable="All"
                >
                  {option.name !== "currency" && (
                    <MenuItem key="all" value="all">
                      All
                    </MenuItem>
                  )}
                  {option?.options?.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </form>
            ) : option.type === "startDate" ? (
              <form key={index} className="flex flex-col gap-1 ">
                <label>Start Date</label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    views={["year", "month", "day"]}
                    inputFormat="YYYY-MM-DD"
                    value={value?.startDate}
                    onChange={(newValue) => handleStartDateChange(newValue)}
                    slotProps={{
                      textField: {
                        sx: {
                          "::-moz-placeholder": "Date",
                        },
                        helperText: startDateHelperText,
                        error: startDateError,
                        field: { clearable: true },
                      },
                    }}
                  />
                </LocalizationProvider>
              </form>
            ) : option.type === "endDate" ? (
              <form key={index} className="flex flex-col gap-1 ">
                <label>End Date</label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    openTo="year"
                    views={["year", "month", "day"]}
                    inputFormat="YYYY-MM-DD"
                    value={value?.endDate}
                    onChange={(newValue) => handleEndDateChange(newValue)}
                    slotProps={{
                      textField: {
                        sx: {
                          "::-moz-placeholder": "Date",
                        },
                        helperText: endDateHelperText,
                        error: endDateError,
                        field: { clearable: true },
                      },
                    }}
                  />
                </LocalizationProvider>
              </form>
            ) : null}
          </div>
        ))}
      </div>

      {/* <React.Fragment>
        <Box width={"200px"} display={"flex"} gap={1} alignItems={"center"}>
        <FilterIcon />
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#202020",
            marginBottom: "0px",
          }}
          variant="body1"
          gutterBottom
        >
          Filter by:
        </Typography>
      </Box>
        <Box bgcolor={"#F7F7FB"} borderRadius={"10px"}>
          <Box sx={{ padding: "15px 15px" }}>
            <Box
              display="flex"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
              sx={{ padding: "5px 5px" }}
            >
              <Box sx={{ padding: "10px 0px" }}>
                <Grid container spacing={2}>
                  {options.map((option, index) => (
                    <Grid
                      key={index}
                      item
                      xs={4}
                      md={2.4}
                      sx={{ textAlign: "left", paddingLeft: "0px" }}
                    >
                      <div className="flex gap-2">
                        {option.type === "radio" ? (
                          <FormControl fullWidth size="small">
                            <FormLabel
                              sx={{ textAlign: "left" }}
                              id="demo-row-radio-buttons-group-label"
                            >
                              {option.group}
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={option.name}
                              value={value[option.name] || ""}
                              onChange={(e) => {
                                handleRadioGroup(e, option.name);
                              }}
                            >
                              {option.options.map((item, index) => (
                                <FormControlLabel
                                  sx={{
                                    "& svg": {
                                      width: "0.8em",
                                      height: "0.8em",
                                    },
                                  }}
                                  key={index}
                                  value={item.value}
                                  control={
                                    <Radio
                                      key={index}
                                      sx={{
                                        ontSize: "1px",
                                      }}
                                    />
                                  }
                                  label={item.label}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        ) : option.type === "dropdown" ? (
                          <>
                            <label
                              style={{
                                marginBottom: "10px",
                                marginTop: "20px",

                                fontSize: "14px",
                                fontFamily: Typography.secondaryFont,
                              }}
                              for={option.group}
                            >
                              {option.group}
                            </label>
                            <FormControl
                              key={index}
                              fullWidth
                              variant="standard"
                            >
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                name={option.name}
                                value={value[option.name] || 0}
                                onChange={(e) => handleChange(e, option.name)}
                                input={<OutlinedInput />}
                                // label={option.label}
                              >
                                {option.name !== "currency" && (
                                  <MenuItem key="all" value="all">
                                    All
                                  </MenuItem>
                                )}
                                {option.options.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </>
                        ) : option.type === "startDate" ? (
                          <>
                            <label
                              style={{
                                marginBottom: "10px",
                                marginTop: "20px",
                                // marginLeft: "5px",
                                fontSize: "14px",
                                fontFamily: Typography.secondaryFont,
                              }}
                              for="startdate"
                            >
                              Start Date
                            </label>
                            <FormControl
                              key={index}
                              variant="standard"
                              sx={{ m: 1, minWidth: 130 }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture
                                  openTo="year"
                                  views={["year", "month", "day"]}
                                  inputFormat="YYYY-MM-DD"
                                  value={value.startDate}
                                  onChange={(newValue) =>
                                    handleStartDateChange(newValue)
                                  }
                                  slotProps={{
                                    textField: {
                                      sx: {
                                        "::-moz-placeholder": "Date",
                                      },
                                      helperText: startDateHelperText,
                                      error: startDateError,
                                      field: { clearable: true },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </>
                        ) : option.type === "endDate" ? (
                          <div className="flex flex-col">
                            <label
                              style={{
                                marginBottom: "10px",
                                marginTop: "20px",
                                fontSize: "14px",
                                fontFamily: Typography.secondaryFont,
                              }}
                              for="enddate"
                            >
                              End Date
                            </label>
                            <FormControl
                              key={index}
                              variant="standard"

                              sx={{ m: 1, minWidth: 130 }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  className="width-[20px]"
                                  disableFuture
                                  openTo="year"
                                  views={["year", "month", "day"]}
                                  inputFormat="YYYY-MM-DD"
                                  value={value.endDate}
                                  onChange={(newValue) =>
                                    handleEndDateChange(newValue)
                                  }
                                  slotProps={{
                                    textField: {
                                      helperText: endDateHelperText,
                                      error: endDateError,
                                      field: { clearable: true },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box display="flex" gap={1} justifyContent={"flex-end"} pb={2}>
              <Button
                onClick={handleReset}
                sx={{
                  borderRadius: "7px",
                  textTransform: "initial",
                  color: colors.grey[500],
                  borderColor: colors.grey[500],
                  "&:hover": { borderColor: colors.grey[500] },
                }}
                size="small"
                variant="outlined"
              >
                Clear Filters
              </Button>
              <Button
                disableElevation
                onClick={handleSubmit}
                sx={{
                  borderRadius: "7px",
                  textTransform: "initial",
                }}
                size="small"
                variant="contained"
              >
                Apply Filters
              </Button>
            </Box>
          </Box>
        </Box>
      </React.Fragment> */}
    </div>
  );
};

export default RevenueFilter;
