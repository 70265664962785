import React from "react";
import PremiumKids from "../../../assets/images/PremiumKids.svg";
import GoPremiumBg from "../../../assets/images/GoPremiumBg.png";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const GoPremium = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: "-20px",
          right: { md: "4%", xs: "0%" },
        }}
      >
        <img src={PremiumKids} alt="premium kids" />
      </Box>
      <Box
        sx={{
          background: `url(${GoPremiumBg}) center/cover`,
          borderRadius: "20px",
          color: "#f4f4f4",
        }}
      >
        <Box padding={"30px 30px"}>
          <Typography
            variant="h6"
            fontWeight={700}
            sx={{ fontSize: { xs: "14px", sm: "28px" }, pb: 1 }}
          >
            Go Premium
          </Typography>
          <Typography
            variant="body2"
            maxWidth={{ sm: "75%", xs: "65%" }}
            sx={{ fontSize: { xs: "12px", sm: "18px", pb: 2 } }}
          >
            Get an unlimited experience. Access to all questions <br /> when you
            subscribe to a premium plan
          </Typography>
          <Button
            sx={{
              fontWeight: 800,
              bgcolor: "#fff",
              color: "#40499D",
              textTransform: "inherit",
              borderRadius: "7px",
              fontSize: { xs: "12px", sm: "16px" },
              mt: "10px",
              "&:hover": { bgcolor: "#fff" },
            }}
            onClick={() => navigate("/dashboard/settings?tab=3")}
          >
            Subscribe
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GoPremium;
