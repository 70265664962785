import { Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { UPDATE_TIMER } from '../../redux/test/types';

const CountdownTimer = ({ initialTimeInSeconds, endTest }) => {
    const [remainingTime, setRemainingTime] = useState(initialTimeInSeconds);
    const dispatch = useDispatch()

    useEffect(() => {
        if (remainingTime <= 0) {
            // Timer has reached 0, perform actions here
            // For example, show a message that time's up
            endTest()
        }

        const timer = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1);
        }, 1000); // Decrease the time every second

        return () => {
            clearInterval(timer);

            dispatch({
                type: UPDATE_TIMER,
                payload: remainingTime
            })
        }; // Clean up the interval on component unmount
    }, [remainingTime]);
    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        // return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return {
            hours: hours.toString().padStart(2, '0'),
            minutes: minutes.toString().padStart(2, '0'),
            seconds: seconds.toString().padStart(2, '0')
        }
    };

    return (
        <>
            <Stack textAlign={"center"}>
                <Typography fontWeight={"bolder"} color={"#111"} variant='h6'>{formatTime(remainingTime).hours}</Typography>
                <Typography variant='body1'>hours</Typography>
            </Stack>
            <Stack textAlign={"center"}>
                <Typography fontWeight={"bolder"} color={"#111"} variant='h6'>{formatTime(remainingTime).minutes}</Typography>
                <Typography variant='body1'>minutes</Typography>
            </Stack>
            <Stack textAlign={"center"}>
                <Typography fontWeight={"bolder"} color={"#111"} variant='h6'>{formatTime(remainingTime).seconds}</Typography>
                <Typography variant='body1'>seconds</Typography>
            </Stack>
        </>
    );
};

export default CountdownTimer;
