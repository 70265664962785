import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";

export default function SubjectFilters({ filterArray, loaded }) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Grid container spacing={3}>
      {filterArray?.map((item) =>
        item?.type === "Exam Year" ? (
          <Grid item xs={3} key={item?.type}>
            {loaded ? (
              <Autocomplete
                sx={{
                  backgroundColor: "#fff",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderRadius: "40px",
                      border: "1px solid #919191",
                    },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "0",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "40px",
                    border: "1px solid #919191",
                  },
                }}
                disablePortal
                id="combo-box-demo"
                options={dummyData}
                size="small"
                renderInput={(params) => (
                  <TextField
                    sx={{ padding: "0px", border: "none" }}
                    color="brandPrimary"
                    {...params}
                    label={item?.type}
                  />
                )}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                sx={{ borderRadius: "20px" }}
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={3} key={item?.type}>
            {loaded ? (
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  {item?.type}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label={item?.type}
                  onChange={handleChange}
                  size="small"
                  color="brandPrimary"
                  sx={{
                    color: "white",
                    bgcolor: "white",
                    width: "15ch",
                    borderRadius: "40px",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#919191",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#919191",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#919191",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#919191 !important",
                    },
                    padding: "0px",
                  }}
                >
                  {/* <MenuItem value={10}>Ten</MenuItem> */}
                </Select>
              </FormControl>
            ) : (
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                sx={{ borderRadius: "20px" }}
              />
            )}
          </Grid>
        )
      )}
    </Grid>
  );
}

const dummyData = [
  { label: "Dummy Data", year: 1994 },
  { label: "Dummy Data", year: 1972 },
];
