import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { ReactComponent as AdvicePackImg } from "../../../assets/images/advicePackImg.svg";
import AdvicePackDialog from "../../../components/Achievements/AdvicePackDialog";
import { useState } from "react";
import LeaderboardCard from "../../../components/Achievements/LeaderboardCard";
import WeeklyMissionCard from "../../../components/Achievements/WeeklyMissionCard";
import LevelCircularProgress from "../../../components/Achievements/LevelCircularProgress";
import NewBadgeModal from "../../../components/Achievements/NewBadgeModal";
import CustomButton from "../../../components/buttons/CustomButton";

const Achievements = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
    setShowImage(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setShowImage(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLevelUp = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const dummyPoints = 150;

  return (
    <div>
      <Box
        component={"section"}
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        gap={3}
      >
        <Box width={{ xs: "100%", md: "65%" }}>
          <Box
            width={{ md: "100%", xl: "60%" }}
            sx={{
              backgroundColor: "#40499D",
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              borderRadius: "15px",
              mb: 4,
            }}
          >
            <Box sx={{ color: "#fff" }}>
              <Typography variant="h6">Start Learning</Typography>
              <Typography variant="subtitle2" fontWeight={400}>
                Begin your study path to ace your examination
              </Typography>
            </Box>
            <Button
              onClick={handleDialogOpen}
              sx={{
                backgroundColor: "#F8EF07",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#F8EF07",
                },
              }}
            >
              <AdvicePackImg />
              Advice Pack
            </Button>
          </Box>
          <Box>
            <LevelCircularProgress points={dummyPoints} />
          </Box>
        </Box>
        <Box width={{ xs: "100%", md: "35%" }}>
          <LeaderboardCard />
          <WeeklyMissionCard />
          <Button onClick={handleLevelUp}>Level Up</Button>
        </Box>
      </Box>

      <NewBadgeModal open={dialogOpen} level={4} onClose={handleCloseDialog} />

      <AdvicePackDialog
        open={openDialog}
        onClose={handleDialogClose}
        showImage={showImage}
      />
      <Dialog
      // open={showSubscriptionModal}
      // onClose={handleCloseSubscriptionModal}
      >
        <DialogTitle>Subscription Required</DialogTitle>
        <DialogContent>
          <Stack
            mr={4}
            mb={4}
            position={"fixed"}
            right={0}
            bottom={0}
            bgcolor={"#1d1d1d"}
            color={"white"}
            width="475px"
            height={"264px"}
            borderRadius={"20px"}
            justifyContent={"center"}
            p={"30px"}
            gap={"12px"}
          >
            <Typography
              fontSize={"30px"}
              fontWeight={700}
              lineHeight={"42px"}
              letterSpacing={"-0.64px"}
            >
              Get{" "}
            </Typography>
            <Typography
              fontSize={"16px"}
              color={"#E7EDF2"}
              lineHeight={"28px"}
              fontWeight={400}
              letterSpacing={"-0.32px"}
            >
              Enjoy one year full of learning when you subscribe to an annual
              essential plan. Best for students who want to explore all e-Study
              has to offer.
            </Typography>
            <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
              <CustomButton
                // onClick={handleCloseSubscriptionModal}
                disableRipple
                text="Close"
                sx={{
                  bgcolor: "transparent",
                  color: "white",
                  "&:hover": { bgcolor: "transparent" },
                }}
              />
              <CustomButton
                text="Choose a Plan"
                sx={{ bgcolor: "#2F54EB", color: "white" }}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Achievements;
