import { useSelector } from "react-redux";

const useSettings = () => {
  const settings = useSelector((state) => state.settings);

  const {
    passwordUpdated,
    isLoading,
    candidateSub,
    candidateSubLoaded,
    successState,
    successMsg,
    userProfileLoaded,
    userProfile,
    isProfileUpdated,
    profileUpdatedSuccessMsg,
    isProfileUpdatedFired,
    updateProfileState,
    countries,
    countriesLoaded,
    isCountryLoading,
    statesData,
    statesLoaded,
    statesLoading,
    lgaData,
    lgaLoaded,
    lgaLoading,
    waecSchoolsData,
    waecSchoolsLoaded,
    waecSchoolsLoading,
    darkMode,
    learningStatistics,
    learningStatisticsLoading,
    learningStatisticsLoaded,
    subscriptionPackageLoader,
    resetSuperUserPassword,
    logOutSuperUserDevices,
  } = settings;

  return {
    passwordUpdated,
    isLoading,
    candidateSub,
    candidateSubLoaded,
    successState,
    successMsg,
    userProfileLoaded,
    userProfile,
    isProfileUpdated,
    profileUpdatedSuccessMsg,
    isProfileUpdatedFired,
    updateProfileState,
    countries,
    countriesLoaded,
    isCountryLoading,
    statesData,
    statesLoaded,
    statesLoading,
    lgaData,
    lgaLoaded,
    lgaLoading,
    waecSchoolsData,
    waecSchoolsLoaded,
    waecSchoolsLoading,
    darkMode,
    learningStatistics,
    learningStatisticsLoading,
    learningStatisticsLoaded,
    subscriptionPackageLoader,
    resetSuperUserPassword,
    logOutSuperUserDevices,
  };
};

export default useSettings;
