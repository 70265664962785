import { useSelector } from "react-redux";

const useUserMetrics = () => {
  const userMetrics = useSelector((state) => state.userMetrics);

  const { loading, userMetricsData, userMetricById, subscription } =
    userMetrics;

  return {
    loading,
    userMetricsData,
    userMetricById,
    subscription,
  };
};

export default useUserMetrics;
