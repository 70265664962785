import React, { useState, useEffect, useRef } from "react";
import { Outlet, Navigate } from "react-router-dom";
import useAdminUser from "./hooks/useAdminUser";
import { logoutAdminUserAction } from "./redux/adminAuth/adminAuthActions";
import { useDispatch } from "react-redux";


const LogoutTimer = () => {
  const user = useAdminUser()

  const dispatch = useDispatch()
  const logoutTimeout = useRef(null);
  const lastActivityTime = useRef(Date.now());

  const resetTimer = () => {
    if (logoutTimeout.current) {
      clearTimeout(logoutTimeout.current);
    }
    logoutTimeout.current = setTimeout(logoutUser, 60 * 60 * 1000); // 1 hour
  };

  const handleActivity = () => {
    lastActivityTime.current = Date.now();
    resetTimer();
  };

  const logoutUser = () => {
    // Implement logout logic here
    dispatch(logoutAdminUserAction(user?.token))
  };

  const handleClick = () => {
    handleActivity();
  };

  const handleScroll = () => {
    handleActivity();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('scroll', handleScroll);

    resetTimer();

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('scroll', handleScroll);
      clearTimeout(logoutTimeout.current);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastActivityTime.current > 60 * 60 * 1000) { // 30 minutes
        logoutUser();
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  return null; // This component doesn't render anything visible
};

const ProtectedRoute = () => {
  // Check for user login token and use that to set isAuthenticated field

  const user = useAdminUser();

  if (user.token) {
    return (
      <>
        <Outlet />
        <LogoutTimer />
      </>
    )
  } else {
    return <Navigate to="/adminlogin" />;
  }
};

export default ProtectedRoute;
