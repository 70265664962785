import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { ReactComponent as CancelIcon } from "../../../assets/images/cancel.svg";
import TitleBar from "./TitleBar";
import WeeklyMissionBg from "../../../assets/images/WeeklyMissionBg.svg";
import blackboard from "../../../assets/images/blackboard.png";
import points from "../../../assets/images/points.gif";
import Diamond from "../../../assets/images/Diamond.gif";
import Hint from "../../../assets/images/Hint.gif";
import timex2 from "../../../assets/images/timex2.gif";
import AnswerOptions from "./AnswerOptions";
import Timer from "./Timer";
import TickingSound from "./TickingSound";
import CorrectSound from "./CorrectSound";
import WrongSound from "./WrongSound";
import TimerSound from "./TimerSound";
import { useDiamonds } from "../../../redux/gamification/gamificationActions";
import { useDispatch } from "react-redux";
import useGamification from "../../../hooks/useGamification";
import useDashboard from "../../../hooks/useDashboard";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    overflowX: "none",
    boxSizing: "none",
    height: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const WeeklyTriviaDialog = ({
  open,
  handleClose,
  testData,
  handleAnswerSelect,
  handleNextQuestion,
  options,
  selectedButton,
  currentNum,
  updateOptions,
  handleSubmit,
  timeLeft,
  totalDuration,
  handleTimeLeft,
  audioRef,
  correctRef,
  wrongRef,
  timerx2Ref,
  isSubmitted,
  handleTimerx2,
  totalTrivaNumber,
  triviapoints,
  wrongAnswers,
  showWrongAnswer,
  availableDiamonds,
  setAvailableDiamonds,
  hintActive,
  setHintActive,
  hintOptions,
  setHintOptions,
  profileId
}) => {
  /* const [loadingImg, setLoadingImg] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoadingImg(false);
    }, 4000);
  }, []); */

  const dispatch = useDispatch()
  const { useDiamond } = useGamification();
  const letterToIndex = (letter) => letter.charCodeAt(0) - 65;
  const { weeklyTrivia } = useDashboard()

  // Helper function to get the correct choice based on letter answer
  const getCorrectChoice = (question) => {
    const correctIndex = letterToIndex(question.answer);
    return question.choices[correctIndex];
  };


  // Function to handle hint button click
  const handleHintClick = async () => {

    if (availableDiamonds > 0 && !isSubmitted) {
      const currentQuestion = testData[currentNum];

      try {

        dispatch(useDiamonds(profileId, 1));

        // Check for success in response and proceed if state is 1
        if (useDiamond === 1) {

          const correctChoice = getCorrectChoice(currentQuestion);

          // Filter out wrong choices and pick one at random
          const wrongChoices = currentQuestion.choices.filter(
            (choice, index) => index !== letterToIndex(currentQuestion.answer)
          );

          if (wrongChoices.length > 0) {
            const randomWrongChoice = wrongChoices[Math.floor(Math.random() * wrongChoices.length)];

            // Create array with one correct and one incorrect option
            const hintOptionsArray = [
              {
                index: correctChoice.optionNo - 1,
                optionNo: correctChoice.optionNo,
                optionText: correctChoice.option
              },
              {
                index: randomWrongChoice.optionNo - 1,
                optionNo: randomWrongChoice.optionNo,
                optionText: randomWrongChoice.option
              }
            ].sort(() => Math.random() - 0.5);

            // Set the hint options to display
            setHintOptions(hintOptionsArray);

            // Subtract one diamond and set hint as active
            setAvailableDiamonds((prev) => prev - 1);
            setHintActive(true);
          } else {
            console.error("No wrong choices available to display in hint.");
          }
        } else {
          console.error("Failed to deduct diamond.");
        }
      } catch (error) {
        console.error("Error during diamond deduction:", error);
      }
    }
  };


  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundImage: `url(${WeeklyMissionBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            overflowX: "none",
            backgroundColor: "#40499d",
            height: "100vh",
            // padding: "0px 10px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "-10px",
            right: "0",
            zIndex: 9999999999,
          }}
        >
          <CancelIcon />
        </IconButton>
        <DialogTitle
          sx={{ m: 0, pr: 2, pl: 2, pt: 0, pb: 0 }}
          id="customized-dialog-title"
        >
          <TitleBar
            weeklyTrivia={weeklyTrivia} />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ opacity: 0 }}>
            {" "}
            <TickingSound
              audioRef={audioRef}
              timeLeft={timeLeft}
              isSubmitted={isSubmitted}
            />
            <CorrectSound audioRef={correctRef} />
            <WrongSound audioRef={wrongRef} />
            <TimerSound audioRef={timerx2Ref} />
          </Box>
          <Box
            sx={{
              display: "flex",
              // bgcolor: "pink",
              gap: 2,
              height: "fit-content",
              overflow: "hidden",
            }}
          >
            <Stack
              sx={{
                // bgcolor: "green",
                justifyContent: "flex-start",
                position: "relative",
                // width:"168px",
                // padding: "0px 10px",
                pt: 20,
              }}
            >
              <Stack
                direction={"row"}
                sx={{
                  position: "absolute",
                  top: "28%",
                  zIndex: 1,
                  right: "-10px",
                }}
              >
                <img
                  src={Diamond}
                  alt="Diamonds"
                  style={{
                    width: "auto",
                    height: "20px",
                    zIndex: 2,
                    marginRight: "-10px",
                  }}
                />
                <Stack
                  direction="row"
                  bgcolor={"brandSecondary.main"}
                  sx={{ padding: "0px 15px", borderRadius: "4px" }}
                >
                  <Typography
                    color="brandPrimary.black"
                    fontWeight={700}
                    fontSize={"12px"}
                  >
                    1
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction={"row"}
                sx={{
                  position: "absolute",
                  top: "39%",
                  zIndex: 1,
                  right: "-10px",
                }}
              >
                <img
                  src={Diamond}
                  alt="Diamonds"
                  style={{
                    width: "auto",
                    height: "20px",
                    zIndex: 2,
                    marginRight: "-10px",
                  }}
                />
                <Stack
                  direction="row"
                  bgcolor={"brandSecondary.main"}
                  sx={{ padding: "0px 15px", borderRadius: "4px" }}
                >
                  <Typography
                    color="brandPrimary.black"
                    fontWeight={700}
                    fontSize={"12px"}
                  >
                    1
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "126px",
                  height: "136px",
                  flexShrink: 0,
                  borderRadius: "24px",
                  background: "#30387B",
                  padding: "20px 10px",
                  fontWeight: 900,
                  color: "#fff",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack
                  direction={"row"}
                  gap={1}
                  width={"100%"}
                  alignItems={"center"}
                // bgcolor={"red"}
                >
                  <Button
                    onClick={handleHintClick}
                    disabled={availableDiamonds === 0 || isSubmitted}
                    sx={{
                      m: 0,
                      justifyContent: "start",
                      color: availableDiamonds === 0 ? "gray" : "#fff",
                      textTransform: "inherit",
                      gap: 1,
                      width: "100%",
                      p: 0,
                      boxSizing: "border-box",
                      opacity: availableDiamonds === 0 ? 0.5 : 1,
                      '&:disabled': {
                        color: 'gray',
                      }
                    }}
                  >
                    <img
                      src={Hint}
                      alt="Hint"
                      style={{ width: "auto", height: "30px" }}
                    />
                    <Typography>Hint</Typography>
                  </Button>
                </Stack>
                <Stack sx={{ width: "100%" }}>
                  <Divider sx={{ borderWidth: "1px", bgcolor: "#fff" }} />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={1}
                  width={"100%"}
                  alignItems={"center"}
                >
                  <Button
                    onClick={handleTimerx2}
                    disabled={availableDiamonds === 0 || isSubmitted}
                    sx={{
                      m: 0,
                      justifyContent: "start",
                      color: availableDiamonds === 0 ? "gray" : "#fff",
                      textTransform: "inherit",
                      gap: 1,
                      width: "100%",
                      p: 0,
                      boxSizing: "border-box",
                      opacity: availableDiamonds === 0 ? 0.5 : 1,
                      '&:disabled': {
                        color: 'gray',
                      }
                    }}
                  >
                    <img
                      src={timex2}
                      alt="Timerx2"
                      style={{ width: "auto", height: "30px" }}
                    />
                    <Typography>Time x2</Typography>
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Box
              sx={{
                /* bgcolor: "blue", */ flex: 2,
                width: "500px",
                height: "fit-content",
              }}
            >
              <Stack
                direction={"row"}
                gap={3}
                // bgcolor={"lightcoral"}
                color="#fff"
                fontWeight={900}
                height="50px"
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <img
                    src={points}
                    alt="Points"
                    style={{ width: "auto", height: "20px" }}
                  />
                  <Typography>{triviapoints}</Typography>
                </Stack>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <img
                    src={Diamond}
                    alt="Diamonds"
                    style={{ width: "auto", height: "20px" }}
                  />
                  <Typography>{availableDiamonds} Diamonds</Typography>
                </Stack>
              </Stack>
              <Box
                sx={{
                  bgcolor: "#000",
                  width: "100%",
                  position: "relative",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: "18px", // Adjust the font size as needed
                    textAlign: "center",
                    padding: "10px",
                    maxHeight: "100%", // Limit the text height to the container's height
                  }}
                >
                  <Box
                    sx={{
                      height: "300px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      fontSize={
                        testData[currentNum]?.question?.length < 100
                          ? "40px"
                          : testData[currentNum]?.question?.length <= 130
                            ? "25px"
                            : testData[currentNum]?.question?.length <= 150
                              ? "30px"
                              : "15px" // Default font size
                      }
                      fontWeight="900"
                      maxWidth="450px"
                      sx={{ overflowWrap: "break-word", color: "white" }}
                    >
                      <span style={{ color: "white" }}>
                        {window.HTMLReactParser(
                          (testData[currentNum]?.question || "").replace(/color:\s*black;/g, "color: white;")
                        )}
                      </span>

                      {/* {testData[currentNum]?.question} */}
                    </Typography>
                  </Box>
                </Box>
                <img
                  src={blackboard}
                  alt="Background"
                  style={{
                    width: "100%",
                    minHeight: "326.11px",
                    display: "block",
                    opacity: 1,
                  }}
                />
              </Box>

              <AnswerOptions
                testData={testData}
                options={options}
                selectedButton={selectedButton}
                currentNum={currentNum}
                handleAnswerSelect={handleAnswerSelect}
                handleNextQuestion={handleNextQuestion}
                updateOptions={updateOptions}
                handleSubmit={handleSubmit}
                totalTrivaNumber={totalTrivaNumber}
                wrongAnswers={wrongAnswers}
                showWrongAnswer={showWrongAnswer}
              />
            </Box>
            <Stack
              justifyContent={"flex-start"}
              pt={25}
              boxSizing={"border-box"}
            >
              <Timer
                handleNextQuestion={handleNextQuestion}
                timeLeft={timeLeft}
                totalDuration={totalDuration}
                handleTimeLeft={handleTimeLeft}
              />
            </Stack>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default WeeklyTriviaDialog;
