import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import EastIcon from "@mui/icons-material/East";
import { Box, Button, Paper, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ResumeSvgIcon } from "../../../../assets/icons/Icons";
import { ReactComponent as NoAvgPerf } from "../../../../assets/images/NoAvgPerf.svg";
import { Link } from "react-router-dom";
import OneTestPaper from "./OneTestPaper";
import { useDispatch } from "react-redux";
import useUser from "../../../../hooks/useUser";
import useDashboard from "../../../../hooks/useDashboard";
import { fetchTestDetails } from "../../../../redux/dashboard/dashboardActions";
import useSubscription from "../../../../hooks/useSubscription";

export default function TestScores({ data, loading }) {
  const navigate = useNavigate();

  const { isTrial } = useSubscription()

  return (
    <Box
      component={Paper}
      maxHeight={"auto"}
      elevation={0}
      p={2}
      width={{ xs: "100%", md: "50%" }}
      borderRadius={"14px"}
      sx={{
        border: "1px solid #E8E8E8",
        boxShadow: "0px 8.6px 25.8px 0px rgba(171, 171, 171, 0.40)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          fontWeight={500}
          pb={1.5}
          ml={1}
          sx={{ fontSize: { xs: "14px", sm: "18px" }, color: "#191635" }}
        >
          My test scores
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            marginRight: "10px",
          }}
          onClick={() => navigate(isTrial ? "/dashboard/settings?tab=3" : "/dashboard/learning/test")}
        >
          Go To Test
        </Typography>
      </Box>

      <Box
        height={"12rem"}
        sx={{
          overflow: "auto",
        }}
      >
        {loading ? (
          <Skeleton variant="text" height={200} fullwidth animation="wave" />
        ) : (
          <>
            {Array.isArray(data) && data?.length > 0 ? (
              data?.map((item, index) => <OneTestPaper key={index} {...item} />)
            ) : (
              // <div
              //   className="flex justify-center items-center"
              //   style={{ height: "100%" }}
              // >
              //   <h4 style={{ color: "#c0c0c0" }}>No data available</h4>
              // </div>
              <Box
                sx={{
                  display: "flex",
                  mt: 0,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <NoAvgPerf />
                <h4 style={{ color: "#c0c0c0", fontSize: "14px" }}>
                  You haven't taken any test yet!
                </h4>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
