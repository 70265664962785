import { Box, Typography } from "@mui/material";
import React from "react";
import BookIcon from "../../../../../assets/images/bookIcon.png";
import { formatCurrency } from "../../../../../helpers/helper";

const PackageName = ({ isMobile, selectedSubscription }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        bgcolor: "#fff",
        borderRadius: "20px",
        ...(!isMobile && {
          p: 2,
          zIndex: 9999,
          position: "absolute",
          top: "195px",
        }),
        ...(isMobile && {
          marginBottom: "30px",
          borderRadius: "16.122px",
          border: "1px solid #D6D6D6",
          background: "#F3F5F8",
          boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.25)",
          p: 2,
        }),
      }}
    >
      <Box>
        <img
          src={BookIcon}
          alt="Book"
          style={{ width: "auto", height: "50px" }}
        />
      </Box>
      <Box sx={{ textAlign: "left" }}>
        <Typography sx={{ color: "#B4B4B4", fontSize: "16px" }}>
          {selectedSubscription?.PackageName} Plan
        </Typography>
        <Typography fontWeight={900} color="brandPrimary.dark">
          {formatCurrency(selectedSubscription?.Amount)} / student
        </Typography>
      </Box>
    </Box>
  );
};

export default PackageName;
