

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, TextField, Typography, useMediaQuery, Button } from "@mui/material";
import useUser from "../../../hooks/useUser";
// import { ChangePassword } from "../../../redux/settings/settingsActions";
import { useDispatch } from "react-redux";
import { ResestSuperSchoolUserPassword } from "../../../redux/settings/settingsActions";

// import LoadButton from "../buttons/LoadButton";
// import CustomButton from "../buttons/CustomButton";

const BootstrapDialog = styled(Dialog)(({ theme, width, fullScreen }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
    "& > .MuiBackdrop-root": {
        backdropFilter: "blur(4px)",
    },
    "& .MuiDialog-paper": {
        borderRadius: fullScreen ? "0px" : "12px",
        ...(width &&
            !fullScreen && {
            width: width,
        }),
        boxShadow: 'rgba(62, 80, 96, 0.2) 0px 4px 20px'
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, px: 4, fontWeight: 900 }} {...other}>
            <Typography sx={{ fontWeight: 600, }}>
                Reset Password
            </Typography>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 10,
                    color: (theme) => theme.palette.grey[500],
                    backgroundColor: (theme) => theme.palette?.closeIcon?.main,
                    borderRadius: "5px",
                    "&:hover": {
                        color: (theme) => theme.palette.error.main,
                    },
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function PasswordReset({
    openModal,
    handleCloseModal,
    setPassword,
    setNewPassword,
    password,
    newPassword,
    setToastState,

}) {
    const theme = useTheme();
    const { userInfo } = useUser();
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [error, setError] = useState("");
    const dispatch = useDispatch()

    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        // Check if passwords match and are not empty
        if (password && newPassword && password === newPassword) {
            setIsPasswordValid(true);
            setError('');
        } else {
            setIsPasswordValid(false);
            if (password !== newPassword) {
                setError('Passwords do not match');
            } else {
                setError('');
            }
        }
    }, [password, newPassword]);



    const onSuccess = (res) => {
        setToastState({
            isOpen: true,
            message: res?.msg ?? "Password Reset Successful",
            severity: "success",
        });
        handleCloseModal(false);
    };

    const onFail = (res) => {
        setToastState({
            isOpen: true,
            message: res?.msg ?? 'Fail to Rest Password ',
            severity: "error",
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
            profileId: userInfo.profileId,
            newPassword: password,
            confirmPassword: newPassword
        }
        if (isPasswordValid) {
            dispatch(ResestSuperSchoolUserPassword(body, onSuccess, onFail));
            // Proceed with form submission or further actions

        }
    };



    return (
        <div>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={openModal}
                fullScreen={fullScreen}
                fullWidth={true}
                PaperProps={{
                    elevation: 0,
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseModal}
                >
                </BootstrapDialogTitle>

                <DialogContent>
                    <Typography sx={{ px: 2, fontSize: "12" }}>
                        Kindly fill in the new password for your users
                    </Typography>
                </DialogContent>

                <div className="flex flex-col gap-[24px] px-9 mb-8">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="New Password"
                        size="small"
                        type="password"
                        name="password"
                        sx={{ backgroundColor: "#f4f7fe" }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                </div>
                <div className="flex flex-col gap-[24px] px-9 mb-8 ">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Confirm New Password"
                        size="small"
                        type="password"
                        name="newPassword"
                        sx={{ backgroundColor: "#f4f7fe" }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />

                </div>
                <div className="flex flex-col gap-[24px] px-9">
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>

                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={!isPasswordValid}
                        onClick={handleSubmit}
                    >
                        Update Password
                    </Button>

                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
