import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  ParentIcon,
  ProspectIcon,
  SchoolIcon,
  SponsorIcon,
  StudentIcon,
  TeacherIcon,
} from "../../assets/icons/Icons";
import getDashBoardData from "./dashboardData";

import InfoCard from "../../components/dashboards/admin/InfoCard";
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";
import useDashboard from "../../hooks/useDashboard";
import {
  fetchAdminDashboardChart,
  fetchAdminDashboardRegisterUserChart,
  fetchAdminDashboardStat,
  loadAdmittedYear,
} from "../../redux/dashboard/dashboardActions";
import { useEffect } from "react";
import UserTypeChart from "../../components/dashboards/admin/UserTypeChart";
import RevenueChart from "../../components/dashboards/admin/RevenueChart";
import useAdminUser from "../../hooks/useAdminUser";
import StudyChart from "../../components/dashboards/students/StudyChart";

const AdminHome = () => {
  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();
  const {
    isLoad,
    adminDashboardStat,
    isChartLoad,
    adminDashboardChart,
    admittedYear,
    adminDashboardRegUsersChart,
  } = useDashboard();

  const [usersChart, setUsersChart] = useState("2024");

  const [yearFilter, setYearFilter] = useState("2024");
  const [usersCurrency, setUsersCurrency] = useState("NG");

  const currencyValues = {
    NG: "566",
    USD: "840",
  };

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const handleChartChangeYear = (e) => {
    setYearFilter(e.target.value);
    dispatch(fetchAdminDashboardRegisterUserChart(e.target.value));
  };

  const handleSalesChartChange = (e) => {
    setUsersChart(e.target.value);
    dispatch(
      fetchAdminDashboardChart(e.target.value, currencyValues[usersCurrency])
    );
  };
  const handleChartChangeCurrency = (e) => {
    // const currencyCode = e.target.value;
    // setUsersCurrency(currencyCode);
    const currencyCode = e.target.value;
    setUsersCurrency(currencyCode);

    // Get the corresponding value for the selected currency code
    const currencyValue = currencyValues[currencyCode];

    // setUsersCurrency(e.target.value);
    dispatch(fetchAdminDashboardChart(usersChart, currencyValue));
  };
  useEffect(() => {
    const userProfileId = userInfo?.data.profileId;
    if (userProfileId) {
      dispatch(fetchAdminDashboardStat());
      dispatch(
        fetchAdminDashboardChart(yearFilter, currencyValues[usersCurrency])
      );
      dispatch(fetchAdminDashboardRegisterUserChart(usersChart));
      dispatch(loadAdmittedYear());
    }
  }, [userInfo]);

  const useDashBoardData = getDashBoardData(adminDashboardStat, isLoad);

  return (
    <Container>
      <Box component={Stack} width={"100%"} gap={2} p>
        <Stack>
          <Typography variant="h6" fontWeight={"bold"}>
            Dashboard
          </Typography>
          <Typography variant="body1" color={"#aaa"}>
            Welcome to the admin dashboard - Your overview awaits
          </Typography>
        </Stack>

        <Box component={"section"} width={"100%"}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 lg:gap-2">
            <div className="col-span-1">
              <Box
                component={Paper}
                elevation={0}
                p={1}
                borderRadius={"12px"}
                bgcolor={"#fff"}
                border={"2px solid #eee"}
                display={"flex"}
                gap
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack>
                  <Typography variant="body2" color={"#aaa"}>
                    All Users
                  </Typography>
                  <Typography variant="h5" fontWeight="bold">
                    {isLoad ? (
                      <Skeleton variant="text" width={100} animation="wave" />
                    ) : (
                      adminDashboardStat?.activeUsers
                    )}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    backgroundColor: "brandPrimary.main",
                    padding: 2,
                    borderRadius: "9999px",
                  }}
                  justifyContent={"center"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <ParentIcon sx={{ fontSize: { md: 20 }, color: "white" }} />
                </Box>
              </Box>
            </div>
            <div className="col-span-1">
              <Box
                component={Paper}
                elevation={0}
                p={1}
                borderRadius={"12px"}
                bgcolor={"#fff"}
                border={"2px solid #eee"}
                display={"flex"}
                gap
                justifyContent={"space-between"}
              >
                <Stack>
                  <Typography variant="body2" color={"#aaa"}>
                    Students
                  </Typography>
                  {isLoad ? (
                    <Skeleton variant="text" width={100} animation="wave" />
                  ) : (
                    <Typography variant="h5" fontWeight={"bold"}>
                      {adminDashboardStat?.studentUsers}
                    </Typography>
                  )}
                </Stack>
                <Box
                  sx={{
                    backgroundColor: "#EB6946",
                    padding: 2,
                    borderRadius: "9999px",
                  }}
                  justifyContent={"center"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <StudentIcon sx={{ fontSize: { md: 20 }, color: "white" }} />
                </Box>
              </Box>
            </div>
            <div className="col-span-1">
              <Box
                component={Paper}
                elevation={0}
                p={1}
                borderRadius={"12px"}
                bgcolor={"#fff"}
                border={"2px solid #eee"}
                display={"flex"}
                gap
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack justifyContent={"space-around"} direction={"column"}>
                  <Typography variant="body2" color={"#aaa"}>
                    Schools
                  </Typography>
                  {isLoad ? (
                    <Skeleton variant="text" width={100} animation="wave" />
                  ) : (
                    <Typography variant="h5" fontWeight={"bold"}>
                      {adminDashboardStat?.schoolUsers}
                    </Typography>
                  )}
                </Stack>
                <Box
                  sx={{
                    backgroundColor: "#2AAC7E",
                    padding: 2,
                    borderRadius: "9999px",
                  }}
                  justifyContent={"space-between"}
                  display={"flex"}
                >
                  <SchoolIcon sx={{ fontSize: { md: 20 }, color: "white" }} />
                </Box>
              </Box>
            </div>
            <div className="col-span">
              <Box
                component={Paper}
                elevation={0}
                p={1}
                borderRadius={"12px"}
                bgcolor={"#fff"}
                border={"2px solid #eee"}
                display={"flex"}
                gap
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack>
                  <Typography variant="body2" color={"#aaa"}>
                    Sponsors
                  </Typography>
                  {isLoad ? (
                    <Skeleton variant="text" width={100} animation="wave" />
                  ) : (
                    <Typography variant="h5" fontWeight={"bold"}>
                      {adminDashboardStat?.sponsorUsers}
                    </Typography>
                  )}
                </Stack>
                <Box
                  sx={{
                    backgroundColor: "#DEAB28",
                    padding: 2,
                    borderRadius: "9999px",
                  }}
                  justifyContent={"center"}
                  display={"flex"}
                  alignItems={"space-between"}
                >
                  <SponsorIcon sx={{ fontSize: { md: 20 }, color: "white" }} />
                </Box>
              </Box>
            </div>
            <div className="col-span-1">
              <Box
                component={Paper}
                elevation={0}
                p={1}
                borderRadius={"12px"}
                bgcolor={"#fff"}
                border={"2px solid #eee"}
                display={"flex"}
                gap
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack justifyContent={"space-around"} direction={"column"}>
                  <Typography variant="body2" color={"#aaa"}>
                    Prospects
                  </Typography>
                  {isLoad ? (
                    <Skeleton variant="text" width={100} animation="wave" />
                  ) : (
                    <Typography variant="h5" fontWeight={"bold"}>
                      {adminDashboardStat?.UnonboardedUsers}
                    </Typography>
                  )}
                </Stack>
                <Box
                  sx={{
                    backgroundColor: "#2AAC7E",
                    padding: 2,
                    borderRadius: "9999px",
                  }}
                  justifyContent={"space-between"}
                  display={"flex"}
                >
                  <ProspectIcon sx={{ fontSize: { md: 20 }, color: "white" }} />
                </Box>
              </Box>
            </div>
          </div>
        </Box>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 place-items-stretch">
          <div className="col-span-1 lg:col-span-3 bg-white rounded-lg p-3">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontWeight={"bold"}>Revenue</Typography>

              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={2}
              >
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    size="small"
                    // displayEmpty
                    value={usersChart}
                    onChange={handleSalesChartChange}
                    sx={{
                      borderRadius: "12px",
                    }}
                  >
                    {/* <MenuItem value={"2023"}>2023</MenuItem>
                    <MenuItem value={"2024"}>2024</MenuItem> */}
                    {admittedYear?.result?.map((year, o) => (
                      <MenuItem key={o} value={year.Years}>
                        {year.Years}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    size="small"
                    // displayEmpty
                    value={usersCurrency}
                    onChange={handleChartChangeCurrency}
                    sx={{
                      borderRadius: "12px",
                    }}
                  >
                    <MenuItem value={"NG"}>NG</MenuItem>
                    <MenuItem value={"USD"}>USD</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <RevenueChart
              adminDashboardChart={adminDashboardChart}
              usersCurrency={usersCurrency}
            />
          </div>
          <div className="col-span-1 lg:col-span-1 bg-white rounded-lg border-2 border-[#eee]">
            <Box component={Paper} width={"100%"} p={2} elevation={0}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <p className="text-[14px] font-semibold">
                  Total Users (Paid and Unpaid)
                </p>
              </Stack>
              {isChartLoad ? (
                <Skeleton
                  variant="circular"
                  width={200}
                  height={200}
                  animation="wave"
                />
              ) : (
                <UserTypeChart adminDashboardChart={adminDashboardChart} />
              )}

              <div className="text-center mt-2">
                <h3 className="text-[#615E83]">Total Count:</h3>
                <h2 className="font-bold text-[#1E1B39] text-3xl">
                  {adminDashboardChart?.totalCount}
                </h2>
              </div>
              {/* <Divider />
              {adminDashboardChart.} */}
            </Box>
          </div>
        </div>

        <div className="mt-3">
          <Box
            width={"100%"}
            component={Paper}
            p={2}
            borderRadius={"24px"}
            elevation={0}
            border={"2px solid #eee"}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontWeight={"bold"}>Registered Users</Typography>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  size="small"
                  // displayEmpty
                  value={yearFilter}
                  onChange={handleChartChangeYear}
                  sx={{
                    borderRadius: "12px",
                  }}
                >
                  {admittedYear?.result?.map((year, y) => (
                    <MenuItem key={y} value={year.Years}>
                      {year.Years}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <StudyChart
              isChartLoad={isChartLoad}
              adminDashboardRegUsersChart={adminDashboardRegUsersChart}
            />
          </Box>
        </div>
      </Box>
    </Container>
  );
};

export default AdminHome;
