import React from 'react'

const QuestionButtons = ({active, questions, onClick, clickedQuestions}) => {
  return (
    <div className="flex flex-wrap gap-[0.4] items-center">
      {questions?.map((question, index) => (
        <button
          className={`px-4 py-2 m-2 focus:outline-none w-[39px] h-[40px] rounded-[5px] flex justify-center items-center ${(active===index) ? 'bg-e-study-bg-primary text-white' : clickedQuestions?.includes(questions[index]?.ExamQuestionId) ? 'bg-[#606AAD] text-white': 'border border-[#B4B4B4] text-[#B4B4B4] '} `}
          onClick={() => onClick(question?.ExamQuestionId)}
          key={index}
        >
          {index + 1}
        </button>

      ))}
    </div>
  )
}

export default QuestionButtons