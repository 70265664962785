import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import {
    Box,
    Button,
    DialogActions,
    Divider,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import Tracy from "../../../assets/images/Tracy.svg";
import {
    FailedRibbon,
    // RubbyImage

} from "../../../assets/icons/Icons";
import Ruby from "../../../assets/icons/Ruby.svg";
import Diamond from "../../../assets/images/Diamond.gif";

import Erick from "../../../assets/images/Erick.png";

//import FailedTrivia from "../../../assets/images/FailedTrivia.svg"
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
        overflowX: "none",
        boxSizing: "none",
        height: "100%",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const PostTriviaFail = ({ open, handleClose, handleCloseFix, wrongAnswers, triviapoints }) => {
    const theme = useTheme();
    return (
        <div>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        overflowX: "none",
                        backgroundColor: "#40499d",
                        width: "500px",
                        borderRadius: "10px",
                    },
                }}
            >
                <DialogContent>
                    <Stack justifyContent={"center"} alignItems={"center"} gap={3} p={2}>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <FailedRibbon
                                sx={{ zIndex: 1, width: "179px", height: "50px" }}
                            />

                            <Box sx={{ position: 'relative' }}>
                                {/* <img
                                    src={Diamond}
                                    alt="Diamonds"
                                    style={{ width: "auto", height: "20px", zIndex: 4, position: 'absolute', right: -20, transform: 'rotate(25deg)', top: 35 }}
                                /> */}
                                {/* <img
                                    src={Rubby}
                                    alt="Timerx2"
                                    style={{ width: "auto", height: "50px", zIndex: 3, position: 'absolute', right: -20, transform: 'rotate(25deg)' }}
                                /> */}
                                <img
                                    src={Ruby}
                                    alt="Adanna"
                                    style={{ width: "auto", height: "115px", marginTop: "-25px" }}
                                />
                            </Box>
                        </Stack>
                        <Typography
                            color="white"
                            fontWeight={600}
                            textAlign={"center"}
                            fontSize={"18px"}
                        >
                            Congrats! you <br />
                            completed your trivia<br />
                            in time!
                        </Typography>
                        <Stack gap={1}>
                            <Box sx={{ position: "relative" }}>
                                {/* <Stack
                                    direction={"row"}
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        zIndex: 1,
                                        right: "-10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Diamond}
                                        alt="Diamonds"
                                        style={{
                                            width: "auto",
                                            height: "15px",
                                            zIndex: 2,
                                            marginRight: "-10px",
                                        }}
                                    />
                                    <Stack
                                        direction="row"
                                        bgcolor={"brandSecondary.main"}
                                        sx={{ borderRadius: "4px", padding: "0px 10px" }}
                                    >
                                        <Typography
                                            color="brandPrimary.black"
                                            fontWeight={700}
                                            fontSize={"10px"}
                                        >
                                            2
                                        </Typography>
                                    </Stack>
                                </Stack> */}
                                <Button
                                    // onClick={handleClose}
                                    variant="contained"
                                    sx={{
                                        bgcolor: (theme) => theme.palette.brandSecondary.yellow,
                                        border: "2px solid white",
                                        textTransform: "inherit",
                                        width: "100px",
                                        p: 0.5,
                                        borderRadius: "10px",
                                    }}
                                >
                                    You Earn {""}{triviapoints} {""} points
                                </Button>
                            </Box>
                            {/* <Button
                                variant="contained"
                                onClick={handleCloseFix}
                                sx={{
                                    bgcolor: (theme) => theme.palette.redColor.main,
                                    border: "2px solid white",
                                    textTransform: "inherit",
                                    width: "100px",
                                    p: 0.5,
                                    borderRadius: "10px",
                                }}
                            >
                                Continue
                            </Button> */}
                        </Stack>
                    </Stack>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
};

export default PostTriviaFail;
