import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Button } from '@mui/material';

export const PaymentOccurenceRadio = () => {
    return (
        <div>PaymentOccurenceRadio</div>
    )
}

const StyledFormControlLabel = styled((props) => <FormControlLabel  {...props} />)(
    ({ theme, checked }) => ({
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.primary.main,
        },
        margin: 0,
        padding: 0,
    }),
);

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
}

const BpIcon = styled(Button)(({ theme }) => ({
    // borderRadius: '50%',
    // width: 16,
    // height: 16,
    // borderColor: theme.palette.brandPrimary.main,
    color: "#111",
    p: 0,
    borderRadius: 0,
    // boxShadow:
    //     theme.palette.mode === 'dark'
    //         ? '0 0 0 1px rgb(16 22 26 / 40%)'
    //         : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    border: "0px solid",
    borderColor: theme.palette.brandPrimary.main,
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebfaf5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.common.black,
    borderRadius: 0,
    border: "0px solid",
    borderColor: theme.palette.brandPrimary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    // '&:before': {
    //     display: 'block',
    //     width: 16,
    //     height: 16,
    //     backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    //     content: '""',
    // },
    'input:hover ~ &': {
        // backgroundColor: '#106ba3',
        backgroundColor: '#333',
    },
    color: theme.palette.common.white
}));

function BpRadio(props) {
    return (
        <Radio
            disableRipple
            sx={{ m: 0, p: 0 }}
            color="brandPrimary"
            checkedIcon={<BpCheckedIcon size='small'>{props.children}</BpCheckedIcon>}
            icon={<BpIcon size='small'>{props.children}</BpIcon>}
            {...props}
        />
    );
}

MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
};

export default function SubOccurenceOption({ label, ...props }) {
    return (<MyFormControlLabel {...props} control={<BpRadio>{label}</BpRadio>} />);
}
