import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  FormLabel,
  NativeSelect,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import { createSvgIcon } from "@mui/material/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  addExamQuestionAction,
  fetchExamQuestionsAction,
  updateExamQuestionAction,
} from "../../../redux/contentUpload/contentUploadActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../../components/notifications/notifications";
import { RTEditor } from "../../../components/RTEditor";

import DataUsageIcon from "@mui/icons-material/DataUsage";
import { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useAdminUser from "../../../hooks/useAdminUser";
import { fetchQuestionSolutionByQuestionId } from "../../../redux/learning/learningActions";
import OpenAI from "openai";
import useContentUpload from "../../../hooks/useContentUpload";
import QuestionSection from "../../../components/QuestionSection/QuestionSection";
import FlagIcon from "@mui/icons-material/Flag";
import CalculateIcon from "@mui/icons-material/Calculate";
import MathQuillEditor from "../../../components/MathQuillEditor";

const ContentUploadMCQInputEdit = ({
  examQuestion,
  setEditMCQ,
  setToastState,
  active,
  setActive,
}) => {
  const splitStringtoArray = (contentString) => {
    let contentArray;
    contentArray = contentString?.split("|||");
    contentArray?.shift();
    return contentArray;
  };

  const [question, setEditorStateQuestion] = React.useState(
    examQuestion?.question
  );

  const [answer1, setEditorStateAnswer1] = React.useState(
    splitStringtoArray(examQuestion?.choice)[0] ?? ""
  );
  const [answer2, setEditorStateAnswer2] = React.useState(
    splitStringtoArray(examQuestion?.choice)[1] ?? ""
  );
  const [answer3, setEditorStateAnswer3] = React.useState(
    splitStringtoArray(examQuestion?.choice)[2] ?? ""
  );
  const [answer4, setEditorStateAnswer4] = React.useState(
    splitStringtoArray(examQuestion?.choice)[3] ?? ""
  );
  const [answer5, setEditorStateAnswer5] = React.useState(
    splitStringtoArray(examQuestion?.choice)[4] ?? ""
  );
  const [correctAnswerScore, setCorrectAnswerScore] = React.useState(0);

  const [answer, setAnswer] = React.useState("0");
  const [solution, setSolution] = useState("");
  const [openEquation, setOpenEquation] = useState(false);
  const [content, setContent] = useState("");

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleMathChange = (latex) => {
    // Handle the change of mathematical content
    console.log("Math content:", latex);
  };

  const handleOpenEquation = (event, prevActive) => {
    // setActive((prevActive) => !prevActive);
    // setActive(prevActive);
    if (prevActive) {
      setOpenEquation(true);
    }
  };

  const handleCloseEquation = () => {
    setOpenEquation(false);
  };

  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();
  const { loadingExam, questionSection } = useContentUpload();

  useEffect(() => {
    dispatch(
      fetchQuestionSolutionByQuestionId(examQuestion?.examQuestionId, (res) =>
        setSolution(res?.solution)
      )
    );
  }, []);

  useEffect(() => {
    setCorrectAnswerScore(parseInt(examQuestion?.scoreWeight));
    setAnswer(examQuestion?.answer?.charAt(0));
  }, []);

  const onEditorStateChangeQuestion = (editorState) => {
    setEditorStateQuestion(editorState);
  };

  const onEditorStateChangeAnswer1 = (editorState) => {
    setEditorStateAnswer1(editorState);
  };

  const onEditorStateChangeAnswer2 = (editorState) => {
    setEditorStateAnswer2(editorState);
  };

  const onSuccess = () => {
    setEditMCQ(false);
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Question updated successfully",
    });
  };

  const [selectedSectionId, setSelectedSectionId] = useState(
    examQuestion?.sectionId
  );

  const [activeFlag, setActiveFlag] = useState(examQuestion?.isActive);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [flagReason, setFlagReason] = useState(examQuestion?.comment);

  const handleSelectChange = (e) => {
    setSelectedSectionId(e.target.value);
    // localStorage.setItem("selectedSectionId", selectedId);
  };

  // Clear the localStorage when the component is unmounted
  useEffect(() => {
    return () => {
      localStorage.removeItem("selectedSectionId");
    };
  }, []);

  const handleClick = (event) => {
    if (activeFlag) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
      setActiveFlag((prevActive) => !prevActive);
      setOpenDialog(true);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCommentSubmit = (comment) => {
    // Handle the comment submission logic here
    setFlagReason(comment);

    setOpenDialog(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditComment = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleSubmit = () => {
    const data = {
      question: question,
      choices:
        "|||" +
        answer1 +
        "|||" +
        answer2 +
        "|||" +
        answer3 +
        "|||" +
        answer4 +
        (answer5 === "<p><br></p>" || answer5 === "" ? "" : "|||" + answer5),
      answer: answer,
      scoreWeight: correctAnswerScore,
      examId: examQuestion?.examId,
      profileId: userInfo?.data?.profileId,
      examQuestionId: examQuestion?.examQuestionId,
      sectionId: selectedSectionId,
      isActive: activeFlag,
      comment: flagReason,
    };
    dispatch(updateExamQuestionAction(data, (res) => onSuccess(res)));
  };

  const handleUnflag = () => {
    // Handle unflag logic here
    setActiveFlag(false);
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Questions manager
        </Typography>
        <span
          className="e-study-primary underline cursor-pointer"
          onClick={() => setEditMCQ(false)}
        >
          View all questions
        </span>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Paper sx={{ minWidth: 275, borderRadius: "16px" }}>
          <CardContent>
            <Box>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "27.24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Question {examQuestion?.questionNumber}
                <div className="flex space-x-3">
                  <Tooltip title={"Enter Expression"} arrow>
                    <ToggleButtonGroup
                      // orientation="vertical"
                      color="secondary"
                      exclusive
                      onChange={handleOpenEquation}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        value="active"
                        // style={{ color: active ? "red" : "gray" }}
                      >
                        <CalculateIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                  <ToggleButtonGroup
                    // orientation="vertical"
                    color="secondary"
                    //style={{ color: "red" }}
                    value={activeFlag}
                    exclusive
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value="activeFlag"
                      style={{ color: activeFlag ? "red" : "gray" }}
                      onClick={handleClick}
                    >
                      <FlagIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleUnflag}>
                    <DeleteIcon />
                    Unflag
                  </MenuItem>
                  <MenuItem onClick={handleEditComment}>
                    <EditIcon />
                    Edit Comment
                  </MenuItem>
                </Menu>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>Flag Comment</DialogTitle>
                  <DialogContent>
                    {/* Add input field for comment */}
                    <TextField
                      variant="outlined"
                      value={flagReason}
                      input
                      type="text"
                      placeholder="Enter your reason"
                      onChange={(e) => setFlagReason(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={() => handleCommentSubmit(flagReason)}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={openEquation} onClose={handleCloseEquation}>
                  <DialogTitle>Expression</DialogTitle>
                  <DialogContent>
                    <div className="flex items-center justify-center">
                      <MathQuillEditor
                        latex={content}
                        onChange={handleMathChange}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseEquation}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              </Typography>
            </Box>

            <Box sx={{ mt: 2, width: "95%", mx: "auto" }}>
              <Box sx={{ mt: 2, mx: "auto" }}>
                <RTEditor
                  theme={"snow"}
                  value={question}
                  key={"question"}
                  setValue={setEditorStateQuestion}
                />
              </Box>
              <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    mt: 1,
                    color: "#000",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                  }}
                >
                  Question section
                </Typography>
              </Stack>
              <Stack mb={3}>
                <NativeSelect
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedSectionId}
                  onChange={handleSelectChange}
                  fullWidth
                >
                  <option value="0">Select</option>
                  {questionSection?.map((type) => (
                    <option
                      sx={{ ml: 3 }}
                      value={type.sectionId}
                      key={type?.sectionId}
                    >
                      {type?.sectionName}{" "}
                    </option>
                  ))}
                </NativeSelect>
              </Stack>

              <Box sx={{ mt: 5, width: "90%" }}>
                <Typography>Answers</Typography>
                <Typography>
                  After providing possible answers, select the correct one.
                </Typography>
              </Box>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={answer}
                  onChange={handleChange}
                  name="radio-buttons-group"
                >
                  <Stack direction="row" sx={{ mt: 9 }}>
                    <FormControlLabel label="" value="1" control={<Radio />} />
                    <RTEditor
                      theme={"snow"}
                      setValue={setEditorStateAnswer1}
                      value={answer1}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ mt: 9 }}>
                    <FormControlLabel label="" value="2" control={<Radio />} />
                    <RTEditor
                      theme={"snow"}
                      setValue={setEditorStateAnswer2}
                      value={answer2}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ mt: 9 }}>
                    <FormControlLabel label="" value="3" control={<Radio />} />
                    <RTEditor
                      theme={"snow"}
                      setValue={setEditorStateAnswer3}
                      value={answer3}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ mt: 9 }}>
                    <FormControlLabel label="" value="4" control={<Radio />} />
                    <RTEditor
                      theme={"snow"}
                      setValue={setEditorStateAnswer4}
                      value={answer4}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ mt: 9 }}>
                    <FormControlLabel label="" value="5" control={<Radio />} />
                    <RTEditor
                      theme={"snow"}
                      setValue={setEditorStateAnswer5}
                      value={answer5}
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>

              <Box sx={{ mt: 5 }}>
                <Typography>Score setting</Typography>
                <Typography>
                  Specify the points for a correct answer. You can deduct points
                  for wrong answers or you can put 0 (zero) points for this
                  answer if you prefer not to assign any score.
                </Typography>
              </Box>

              <Box
                sx={{ mt: 5 }}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                  <InputLabel>Points for correct answer</InputLabel>
                  <TextField
                    type="number"
                    aria-describedby="my-helper-text"
                    value={correctAnswerScore}
                    onChange={(e) => setCorrectAnswerScore(e.target.value)}
                  />
                </Stack>
                <Button
                  sx={{
                    backgroundColor: "#40499D",
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {loadingExam ? (
                    <DataUsageIcon size={24} className="animate-spin ml-4" />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Paper>
      </Box>
    </>
  );
};

export default ContentUploadMCQInputEdit;
