import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { LogoImage } from "../NavMenu";
import logo from "../../assets/images/Applogo.png";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../assets/icons/Icons";
import { styled } from "@mui/material/styles";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";

const pages = [
  // {
  //   title: "Legal",
  //   link: "",
  // },
  {
    title: "Terms of Use",
    link: "/privacy-policy",
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    title: "WAEC",
    link: "/Waec",
  },
];

const StyledIcon = styled(IconButton)({
  backgroundColor: "#FFF",
  filter: "drop-shadow(0px 4.937px 17.279px rgba(0, 0, 0, 0.15))",
  marginRight: "10px",
  "&:hover": { backgroundColor: "#EAECFF" },
});

const LandingPageFooter = () => {
  const navigate = useNavigate();
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: { md: 4 },
        // bgcolor: "#EAECFF",
        bgcolor: "#40499d",
        mt: "auto",
        color: "#FFF",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: "50px 40px 30px 40px",
          gap: 6,
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            flexWrap: { xs: "wrap" },
            gap: { xs: 2, sm: 2, md: 20 },
            boxSizing: "border-box",
            overflowX: "hidden",
          }}
        >
          <Box ml={3}>
            <LogoImage
              sx={{ display: { xs: "flex", md: "flex" } }}
              src={logo}
            />
            <Typography
              fontFamily={"Montserrat"}
              color="#ECECEC"
              mt={3}
              fontWeight={"400"}
              sx={{ fontSize: { xs: "12px", md: "15px" } }}
            >
              eStudy redefines examination preparation, giving <br /> access to WAEC
              past exams, marking guides, <br />
              and study packs for students and schools.{" "}
            </Typography>
            {/* <Typography color={"#ECECEC"} fontSize='15px' fontWeight={"400"}>@lorem.lk digital agency </Typography> */}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              flexDirection: "column",
              display: { md: "flex" },
              justifyContent: "start",
              color: "white",
              gap: 2,
              ml: 3,
            }}
          >
            <Typography fontSize={"20px"} fontFamily={"Montserrat"}>
              Quick Links{" "}
            </Typography>
            {pages.map((page) => (
              <Button
                disableFocusRipple
                disableRipple
                disableTouchRipple
                key={page}
                onClick={() => navigate(page.link)}
                sx={{
                  color: "#ECECEC",
                  fontFamily: "Montserrat",
                  fontSize: { xs: "12px", md: "15px" },
                  fontWeight: "400",
                  justifyContent: "flex-start",
                  textTransform: "capitalize",
                  "&:hover": { color: "#F8EF07" },
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              flexDirection: "column",
              display: { md: "flex" },
              justifyContent: "start",
              color: "white",
              gap: 2,
              ml: 3,
            }}
          >
            <Typography fontSize={"20px"} fontFamily={"Montserrat"}>
              Connect with Us{" "}
            </Typography>
            <Typography
              color="#ECECEC"
              width={"300px"}
              fontFamily={"Montserrat"}
              sx={{ fontSize: { xs: "12px", md: "15px" } }}
            >
              +234-908-719-7603,
            </Typography>
            <Typography
              fontFamily={"Montserrat"}
              sx={{ fontSize: { xs: "12px", md: "15px" } }}
            >
              +234-908-719-7604{" "}
            </Typography>
            <Typography
              fontFamily={"Montserrat"}
              sx={{ fontSize: { xs: "12px", md: "15px" } }}
            >
              support@estudy.ng{" "}
            </Typography>
            <Box
              className="socialmedia-links"
              sx={{ mt: { xs: "15px", md: "0px" } }}
            >
              <a href="https://www.facebook.com/officialestudy/">
                <StyledIcon>
                  <FacebookIcon
                    sx={{ width: { xs: "10px", md: "20px" }, height: "auto" }}
                  />
                </StyledIcon>
              </a>
              <a href="https://www.instagram.com/estudy_ng/">
                <StyledIcon>
                  <InstagramIcon
                    sx={{ width: { xs: "10px", md: "20px" }, height: "auto" }}
                  />
                </StyledIcon>
              </a>
              <a href="https://twitter.com/eStudy_ng">
                <StyledIcon>
                  <TwitterIcon
                    sx={{ width: { xs: "10px", md: "20px" }, height: "auto" }}
                  />
                </StyledIcon>
              </a>
              <a href="https://www.linkedin.com/company/estudyng/">
                {" "}
                <StyledIcon>
                  <LinkedinIcon
                    sx={{ width: { xs: "10px", md: "20px" }, height: "auto" }}
                  />
                </StyledIcon>
              </a>
            </Box>
          </Box>
        </Stack>
        <Box
          padding={"20px 0px 0px 0px"}
          sx={{ padding: { xs: "50px 0px 0px 0px", md: "20px 0px 0px 0px" } }}
        >
          <Typography
            fontFamily={"Montserrat"}
            variant="body1"
            textAlign={"center"}
            sx={{ fontSize: { xs: "12px", md: "14px" } }}
          >
            &copy; {new Date().getFullYear()} Sidmach Technologies Nigeria
            Limited. ALL RIGHTS RESERVED.
          </Typography>
        </Box>
        {/* <Copyright /> */}
      </Container>
    </Box>
  );
};

export default LandingPageFooter;
