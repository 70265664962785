import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import avatar1 from "../../assets/images/avatar1.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAllExamQuestions } from "../../redux/test/testActions";
import useTest from "../../hooks/useTest";
import useUser from "../../hooks/useUser";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import avatarWoman from "../../assets/images/avatarWoman.gif";
import ada from "../../assets/images/ada.gif"


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    borderRadius: "30px",
  },
  "& .MuiDialogActions-root": {
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  "&.Mui-Paper": {
    border: `1px solid ${theme.palette.brandPrimary.main}`,
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TestModal({
  open,
  handleClose,
  examData,
  handleCloseModal,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const test = useTest();
  const user = useUser();
  const { isLoading, questions, isLoaded } = test;
  const [isClicked, setIsClicked] = React.useState(false);
  const { userInfo } = user;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTakeTest = (examData) => {
    if (examData) {
      dispatch(setAllExamQuestions(examData[0]));
      setIsClicked(true);
    }
  };

  React.useEffect(() => {
    if (isLoaded && !isLoading && questions?.length !== 0 && isClicked) {
      navigate("/dashboard/learning/mockTest");
    }
  }, [isLoading, questions, isLoaded, isClicked]);


  return (
    <div>
      <BootstrapDialog
        onClose={handleCloseModal}
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            ...(!fullScreen) && {
              border: "2px solid #40499D",
              borderRadius: "30px",
            },
          }
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseModal}
        />
        <DialogContent>
          <Box display={!fullScreen && "flex"} alignItems={"center"} padding={!fullScreen && "30px 0px 30px 150px"}>
            {!fullScreen ?
              <img
                src={ada}
                alt="Avatar"
                draggable="false"
                style={{ width: "auto", height: "200px", position: 'absolute', left: 0, bottom: 0, zIndex: 1 }}
              /> : ""}
            <Typography variant="subtitle2" fontSize={17} sx={{ zIndex: 2 }}>
              Hello, {userInfo?.firstName}. Nice to meet you! I hope you are
              very well prepared for the test. Carefully read the instructions
              before proceeding to start. I’ll see you once you’re done
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading && isLoading ? true : false}
            color="brandPrimary"
            variant="contained"
            autoFocus
            onClick={() => handleTakeTest(examData ?? null)}
            sx={{ textTransform: "capitalize" }}
          >
            {isLoading && isLoading ? (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            ) : (
              "Continue"
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
