import { Stack, Typography } from "@mui/material";
import React from "react";
import { Level2Badge } from "../../../assets/icons/Icons";
import points from "../../../assets/images/points.gif";

const PointsAndLevelSection = () => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <img
          src={points}
          alt="Points earned"
          style={{ width: "auto", height: "15px" }}
        />
        <Typography fontSize={"12px"}>Points earned this week: 15</Typography>
      </Stack>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Level2Badge />
        <Typography fontSize={"12px"} fontFamily="Orbitron, sans-serif">
          Level 02 - Eager Learner
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PointsAndLevelSection;
