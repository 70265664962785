import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import useLearning from "../../../../hooks/useLearning";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import KatexRenderer from "../../../../components/KatexRenderer";

function PracticeChoices(props) {
  const learning = useLearning();
  const { practiceQuestions, practiceQuestionCount } = learning;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedOption, setSelectedOption] = useState("");
  // useEffect(() => {
  //   const decodeHtml = (html) => {
  //     let txt = document.createElement("textarea");
  //     txt.innerHTML = html;
  //     return txt.value;
  //   };

  //   const infoHtml = decodeHtml(practiceQuestions);
  //   const blocksFromHTML = convertFromHTML(infoHtml);
  //   const state = ContentState.createFromBlockArray(
  //     blocksFromHTML.contentBlocks,
  //     blocksFromHTML.entityMap
  //   );
  //   setEditorState(
  //     practiceQuestionCount.length > 0
  //       ? EditorState.createWithContent(state)
  //       : EditorState.createEmpty()
  //   );
  // }, [practiceQuestions]);

  //   const handleOptionChange = (value) => {
  //     setSelectedOption(value);
  //   };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          "& > :not(style)": {},
        }}
      >
        <Paper elevation={0} sx={{ px: 6, py: 4, borderRadius: 3 }}>
          <FormControl component="fieldset">
            {/* <FormLabel id="demo-controlled-radio-buttons-group">
              Options
            </FormLabel> */}
            <ol style={{ listStyle: "upper-alpha", marginTop: 4 }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={props.selectedOption}
                onChange={(e) => props.handleOptionChange(e.target.value)}
              >
                {practiceQuestions?.data.choices.map((subject, index) => (
                  <li key={index} className="pl-3 ml-6">
                    <FormControlLabel
                      key={index}
                      value={subject.optionNo}
                      disabled={props.open}
                      control={<Radio />}
                      label={window.HTMLReactParser(subject?.option || "")}
                    />
                  </li>
                ))}
              </RadioGroup>
            </ol>
          </FormControl>
        </Paper>
      </Grid>
      {/* <Grid>
        <Box sx={{ width: "100%" }}>
          <Button
            disabled={open}
            variant="outlined"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              handleCheck();
            }}
          >
            Check
          </Button>
          <Collapse in={open}>
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert
                icon={
                  practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                  selectedOption?.replace(/\s/g, "") ? (
                    <img src={WrongIcon} alt="Wrong" />
                  ) : (
                    <img src={practicetestmark} alt="Correct" />
                  )
                }
                severity={
                  practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                  selectedOption?.replace(/\s/g, "")
                    ? "error"
                    : "success"
                }
              >
                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid xs={3}>
                    <AlertTitle>
                      {practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                      selectedOption?.replace(/\s/g, "") ? (
                        <Grid>
                          <Typography
                            style={{ fontWeight: "bold", color: "#F92F60" }}
                          >
                            That's Wrong
                          </Typography>
                          <Typography style={{ color: "#000" }}>
                            The correct answer is{" "}
                            {convertAnswerToAlphabet(
                              practiceQuestions?.data.answer?.replace(/\s/g, "")
                            )}
                          </Typography>
                        </Grid>
                      ) : (
                        "Great Job!"
                      )}
                    </AlertTitle>
                  </Grid>
                  <Grid style={{ marginLeft: "auto" }}>
                    <Button
                      sx={practiceButton}
                      endIcon={<ArrowForwardIcon />}
                      style={{
                        background:
                          practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                          selectedOption?.replace(/\s/g, "")
                            ? "#F92F60"
                            : "#05765F",
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Alert>
            </Stack>
          </Collapse>
        </Box>
      </Grid> */}
    </>
  );
}
export default PracticeChoices;
