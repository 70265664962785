import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import WeeklyMissionBg from "../../../../assets/images/WeeklyMissionBg.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/images/cancel.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useLearning from "../../../../hooks/useLearning";
import { styled } from "@mui/material/styles";
import { fetchQuestionSolutionByQuestionId } from "../../../../redux/learning/learningActions";
import KatexRenderer from "../../../../components/KatexRenderer";
import { RTEViewer } from "../../../../components/RTEViewer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    overflowX: "none",
    boxSizing: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-root": {
    backgroundColor: "#40499d",
  },
}));

export default function ExamAnswer(props) {
  const { questionSolution, loadQuestionSolution } = useLearning();
  const dispatch = useDispatch();
  const { examCount, open, setOpenAnswer, examQuestion, examType, isTheory } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [solution, setSolution] = useState("");

  useEffect(() => {
    const decodeHtml = (html) => {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    };

    const infoHtml = decodeHtml(examQuestion?.answer);
    const blocksFromHTML = convertFromHTML(infoHtml);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(examCount?.length > 0 ? EditorState.createWithContent(state) : EditorState.createEmpty());
  }, [examQuestion]);

  useEffect(() => {
    dispatch(fetchQuestionSolutionByQuestionId(examQuestion?.examQuestionId, (res) => setSolution(res?.solution)));
  }, []);

  const handleClose = () => {
    setOpenAnswer(false);
  };

  const baseClassName = "mt-6 text-1xl";
  const fontClass = isTheory ? "font-medium" : "font-extrabold"; //to remove the bold font for theory questions
  return (
    <div>
      <Stack direction="row" spacing={1} alignItems="start">
        <Chip label="Answer:" color="success" />
        <p className={`${baseClassName} ${fontClass}`} style={{ marginTop: ".3em" }}>
          {/* {examQuestion?.answer?.replace(/<[^>]+>|&nbsp;/g, "") || ""} */}
          {/* {window.HTMLReactParser(examQuestion?.answer || "")} */}
          <RTEViewer value={examQuestion?.answer || ""} style={{ height: "fit-content" }} />
        </p>
      </Stack>
      {loadQuestionSolution ? (
        <div className="flex flex-col items-center justify-center">
          <div>
            <Skeleton variant="rounded" width={200} animation={"wave"} />
            <Skeleton variant="rounded" width={200} animation={"wave"} />
          </div>
          <p className="text-xs italic">Loading Solution to Question</p>
        </div>
      ) : examType === 2 || examType === 3 ? null : (
        <p className="py-3 text-lg leading-relaxed" style={{ whiteSpace: "pre-line" }}>
          {/* {window.HTMLReactParser(solution || "No explanation or solution available for this question")} */}
          <RTEViewer value={solution || "No explanation or solution available for this question"} />
        </p>
      )}
    </div>
  );
}
