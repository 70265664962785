import { useSelector } from "react-redux";

const useSession = () => {
  const session = useSelector((state) => state.session);

  const { createSession, allSession, sessionUpdate, loadingSession } = session;
  return {
    createSession,
    allSession,
    sessionUpdate,
    loadingSession,
  };
};
export default useSession;
// 6596,
