import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Col, Row } from "reactstrap";
import { DragnDropIcon, PlusIcon } from "../../assets/icons/Icons";
import TemplateArea from "./TemplateSection";
import Table from "./Table/Table";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetSchoolSponsorsBySchoolId,
  fetchGetStudent,
  fetchGetStudentBySponsorId,
} from "../../redux/school";
import {
  fetchSponsorSubscriptions,
  fetchSchoolSubscriptions,
  fetchSchoolSubscriptionsBySponsorId,
} from "../../redux/subscriptions";
import { addBulkStudents } from "../../redux/school";
import useUser from "../../hooks/useUser";
import Axios from "../../connection/defaultClient";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import BusyOverlay from "../OverLay/BusyOverLay";
import useSubscription from "../../hooks/useSubscription";
import useSchool from "../../hooks/useSchool";

function DropZone({
  handleCloseModal,
  setToastState,
  refreshParent,
  fetchOverlay,
  // BusyOverlay,
}) {
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { schoolId, sponsorId } = userInfo;
  const subscription = useSubscription();
  const { schoolSub, schoolSponsorSubscriptions, sponsorSub } = subscription;
  const school = useSchool();
  const { allSponsorsBySchool } = school;
  const [accepted, setAccepted] = React.useState([]);
  const [rejected, setRejected] = React.useState([]);
  const [extension, setExtension] = React.useState("");
  const [errMsg, setErrMsg] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [failedexcelData, setFailedExcelData] = useState([]);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const [selectedSponsorId, setSelectedSponsorId] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(
    "schoolSubscriptions"
  );
  const [selectedSponsor, setSelectedSponsor] = useState("00000000-0000-0000-0000-000000000000");
  const [isLoading, setIsLoading] = useState(false);

  const [apiMsg, setApiMsg] = useState("");
  const { loading } = subscription;

  const initialBulkFileValues = {
    name: "",
    path: "",
    size: "",
    display: false,
    error: false,
    emptyError: false,
  };
  const [bulkFile, setBulkFile] = useState(initialBulkFileValues);
  const handleClose = () => {
    setAccepted([]);
    setRejected([]);
  };
  const checkFileSize = (event) => {
    let files = event[0];
    let size = 1000000;
    let err = "";

    if (files.size > size) {
      err = `${
        files.name
      } is too large. Please pick a smaller file (max size: ${Math.round(
        size / (1024 * 1024)
      )} MB).`;
      setErrMsg(err);
      return false;
    }
    return true;
  };

  const handleFileRead = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    // Use the setImage function or remove if unnecessary
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const rowData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const updatedRowData = rowData.slice(1);
    setExcelData(updatedRowData);
  };
  const convertBase64 = (event) => {
    let file = event[0];
    if (file && checkFileSize(event)) {
      const reader = new FileReader();
      reader.onload = handleFileRead.bind(this);
      let fileEx = file.name.split(".").pop().toLowerCase();
      reader.readAsBinaryString(file);

      // Handle different file types
      if (fileEx === "csv" || fileEx === "xlsx" || fileEx === "xls") {
        setExtension(fileEx);
      } else {
        // Handle unsupported file types
        console.error("Unsupported file type");
      }
    }
  };

  useEffect(() => {
    dispatch(
      userInfo?.schoolId == null
        ? fetchSponsorSubscriptions(userInfo?.sponsorId)
        : fetchSchoolSubscriptions(userInfo?.schoolId)
    );
  }, []);

  const handleChange = (event) => {
    const [subscriptionId, sponsorId] = event.target.value.split(",");
    setSelectedSubscriptionId(subscriptionId);
    setSelectedSponsorId(sponsorId);
  };

  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: res.msg,
    });
    setTimeout(() => {
      // navigate('/dashboard/home')
    }, 3000);
    //alert("Alerted from child component");
    handleCloseModal(true);
    setIsLoading(false);
    refreshParent();
    fetchOverlay();
    // BusyOverlay();
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
    setApiMsg(res.msg);
    setFailedExcelData(res.data.studentEmails);
    setTimeout(() => {
      // navigate('/dashboard/home')
    }, 3000);

    // handleCloseModal(true);
    setIsLoading(false);
    refreshParent();
    // fetchOverlay();
  };

  const on500Fail = (error) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: error?.statusText,
    });
    handleCloseModal(true);
    setIsLoading(false);
    refreshParent();
    fetchOverlay();
  };

  const handleBulkEnrolClick = () => {
    setIsLoading(true);
    // fetchOverlay();

    const subscriptionId = selectedSubscriptionId;
    const emailColumnIndex = 3; // Index of the column containing emails
    const firstNameColumnIndex = 1; // Index of the column containing first names
    const lastNameColumnIndex = 2; // Index of the column containing last names
    // const otherNameColumnIndex = 2; // Index of the column containing other names
    // const classColumnIndex = 5; // Index of the column containing class names

    const studentEmails = excelData.map((row) => ({
      studentEmail: row[emailColumnIndex],
      firstName: row[firstNameColumnIndex],
      lastName: row[lastNameColumnIndex],
      // otherName: row[otherNameColumnIndex],
      // classLevel: row[classColumnIndex],
    }));

    const payload = {
      subscriptionId: selectedSubscriptionId,
      // sponsorId: selectedSponsor,
      sponsorId:
        userInfo?.sponsorId !== null ? userInfo.sponsorId : selectedSponsor,
      // selectedSponsorId !== undefined && selectedSponsorId !== ""
      //   ? selectedSponsorId
      //   : "00000000-0000-0000-0000-000000000000",
      schoolId:
        userInfo?.schoolId !== null
          ? userInfo.schoolId
          : "00000000-0000-0000-0000-000000000000",
      studentEmails: studentEmails,
    };
    if (userInfo?.schoolId !== null) {
      dispatch(addBulkStudents(payload, onSuccess, onFail));
      dispatch(fetchGetStudent(schoolId));
    } else {
      dispatch(addBulkStudents(payload, onSuccess, onFail));
      dispatch(fetchGetStudentBySponsorId(sponsorId));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(fetchGetSchoolSponsorsBySchoolId(userInfo?.schoolId));
  }, []);

  const onSelectChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedSponsor(selectedValue);
    const sponsorId = selectedValue;

    dispatch(
      fetchSchoolSubscriptionsBySponsorId(userInfo?.schoolId, sponsorId)
    );
  };

  const handleSponsorSubscriptionChange = (event) => {
    setSelectedSubscriptionId(event.target.value);
    setSelectedSponsorId(userInfo?.sponsorId);
  };

  const handleSubscriptionTypeChange = (event) => {
    setSubscriptionType(event.target.value);
    dispatch(fetchGetSchoolSponsorsBySchoolId(userInfo?.schoolId));
  };

  return (
    <div>
      <Paper className="dropzone" elevation={2}>
        {accepted?.length === 0 && (
          <div className="dropzone-card">
            <div className="dropzone">
              {rejected?.length > 0 && errMsg !== "" && (
                <p style={{ color: "red" }}>{errMsg}</p>
              )}

              <Dropzone
                style={{
                  textTransform: "initial",
                  border: `2.918px dashed ${
                    rejected?.length > 0 ? "#FF3B30" : "#CBD0DC"
                  }`,
                  borderRadius: "18.967px",
                  width: "95%",
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                minSize={0}
                maxSize={1000000}
                accept=".csv, .xlsx, .xls"
                onDrop={(accepted, rejected) => {
                  setAccepted(accepted);
                  setRejected(rejected);
                  convertBase64(accepted);
                  convertBase64(rejected);
                }}
              >
                <div className="justify-content-between">
                  <DragnDropIcon variant="contained" color="primary" />
                </div>
                <Stack alignItems="center" gap={3}>
                  <p className="mt-4 text-center">
                    <span
                      style={{
                        color: "#292D32",
                        fontWeight: 500,
                        fontSize: "22px",
                      }}
                    >
                      Choose a file or drag & drop it here
                    </span>{" "}
                    <br />
                    <span
                      style={{
                        color: "#45464E",
                        fontSize: "13px",
                        fontWeight: 400,
                      }}
                    >
                      .csv, .xlsx and .xls files supported
                    </span>
                  </p>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      border: "1.152px solid #CBD0DC",
                      borderRadius: "10px",
                      color: "#54575C",
                    }}
                  >
                    Browse Files
                  </Button>
                </Stack>
              </Dropzone>
            </div>
          </div>
        )}
        <TemplateArea file={accepted} onClose={handleClose} />
        {accepted?.length > 0 && (
          <>
            {userInfo?.schoolId !== null ? (
              <Box sx={{ mt: 3 }}>
                <RadioGroup
                  row
                  value={subscriptionType}
                  onChange={handleSubscriptionTypeChange}
                  sx={{ paddingLeft: "1.1rem" }}
                >
                  <FormControlLabel
                    value="schoolSubscriptions"
                    control={<Radio size="medium" disabled={isLoading} />}
                    label="School's Subscriptions"
                  />
                  <FormControlLabel
                    value="sponsorSubscriptions"
                    control={<Radio size="medium" disabled={isLoading} />}
                    label="Sponsors' Subscriptions"
                  />
                </RadioGroup>

                {subscriptionType === "schoolSubscriptions" && (
                  <div className="flex items-center w-full px-12 mt-5">
                    <select
                      name="subscription"
                      id="subscription"
                      className="min-w-full h-[46px] bg-[#f4f7fe] rounded-[10px] font-normal text-[#8e9ab9] text-base px-4"
                      value={selectedSubscriptionId}
                      onChange={handleChange}
                      disabled={isLoading}
                    >
                      <option value="">Active Subscription Packages*</option>
                      {schoolSub?.allSubsData &&
                      schoolSub?.allSubsData.length > 0 ? (
                        schoolSub?.allSubsData.map((subscription, index) => {
                          if (
                            !subscription.IsSchoolSponsored && // Check if not school sponsored
                            subscription.SubscriptionStatus === "Active" && // Check if subscription is active
                            subscription.UnAllocatedLicenses > 0 // Check if there are available licenses
                          ) {
                            return (
                              <option
                                key={index}
                                value={subscription.SubscriptionId}
                              >
                                {`${subscription.SubscriptionName} (${subscription.UnAllocatedLicenses} Slots Left)`}
                              </option>
                            );
                          }
                          return null; // Render nothing if conditions are not met
                        })
                      ) : (
                        <option value="">No Active Subscriptions</option>
                      )}
                    </select>
                  </div>
                )}
                {subscriptionType === "sponsorSubscriptions" && (
                  <>
                    <div className="flex items-center w-full px-12 mt-5">
                      <select
                        name="sponsor"
                        id="sponsor"
                        className="min-w-full h-[46px] bg-[#f4f7fe] rounded-[10px] font-normal text-[#8e9ab9] text-base px-4"
                        value={selectedSponsor}
                        onChange={onSelectChange}
                        disabled={isLoading}
                      >
                        <option value="">Select Sponsor</option>
                        {allSponsorsBySchool?.length === 0 ? (
                          <option value="" disabled>
                            No sponsors
                          </option>
                        ) : (
                          allSponsorsBySchool?.map((sponsor, index) => (
                            <option key={index} value={sponsor.SponsorId}>
                              {sponsor.SponsorFullName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                    {selectedSponsor && (
                      <div className="flex items-center w-full px-12">
                        <select
                          name="subscription"
                          id="subscription"
                          className="min-w-full h-[58px] bg-[#f4f7fe] rounded-[10px] font-normal text-[#8e9ab9] text-base px-4 mt-3"
                          value={selectedSubscriptionId}
                          onChange={handleChange}
                          disabled={isLoading}
                        >
                          <option value="">
                            Active Subscription Packages*
                          </option>
                          {schoolSponsorSubscriptions &&
                          schoolSponsorSubscriptions?.length > 0 ? (
                            schoolSponsorSubscriptions
                              ?.filter(
                                (subscription) =>
                                  subscription.SubscriptionStatus ===
                                    "Active" && subscription.SlotsLeft > 0
                              )
                              .map((subscription, index) => (
                                <option
                                  key={index}
                                  value={
                                    subscription.SlotsLeft < 1
                                      ? ""
                                      : subscription.subscriptionId
                                  }
                                  disabled={subscription.SlotsLeft === 0}
                                >
                                  {subscription.SlotsLeft < 1
                                    ? "0 Slots Left"
                                    : `${subscription.SubscriptionName} (${subscription.SlotsLeft} Slots Left)`}
                                </option>
                              ))
                          ) : (
                            <option value="" disabled>
                              No Active Subscriptions
                            </option>
                          )}
                        </select>
                      </div>
                    )}
                  </>
                )}

                <Table
                  excelData={excelData}
                  sx={{ mt: 0 }}
                  apiMsg={apiMsg}
                  failedexcelData={failedexcelData}
                />
              </Box>
            ) : (
              <Box sx={{ mt: 5 }}>
                <select
                  name="subscription"
                  id="subscription"
                  className="min-w-full h-[46px] bg-[#f4f7fe] rounded-[10px] font-normal text-[#8e9ab9] text-base px-4"
                  onChange={handleSponsorSubscriptionChange}
                  value={selectedSubscriptionId}
                >
                  <option value="">Active Subscription Packages*</option>
                  {sponsorSub?.allSubsData &&
                  sponsorSub?.allSubsData.length > 0 ? (
                    sponsorSub?.allSubsData
                      ?.filter(
                        (subscription) =>
                          subscription.SubscriptionStatus === "Active" &&
                          subscription.UnAllocatedLicenses > 0
                      )
                      .map((subscription, index) => (
                        <option key={index} value={subscription.SubscriptionId}>
                          {`${subscription.SubscriptionName} (${subscription.UnAllocatedLicenses} Slots Left)`}
                        </option>
                      ))
                  ) : (
                    <option value="">No Active Subscriptions</option>
                  )}
                </select>

                <Table
                  excelData={excelData}
                  apiMsg={apiMsg}
                  sx={{ mt: 0 }}
                  failedexcelData={failedexcelData}
                />
              </Box>
            )}
            {isLoading && <BusyOverlay busy={isLoading} />}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#40499D",
                  borderRadius: 2,
                  textTransform: "none",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#333a7d",
                  },
                }}
                onClick={handleBulkEnrolClick}
                startIcon={<PlusIcon />}
                disabled={!selectedSubscriptionId || isLoading}
              >
                Enrol
              </Button>
            </Box>{" "}
          </>
        )}
      </Paper>
    </div>
  );
}

export default DropZone;
