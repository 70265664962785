import React from "react";
import { Container, Typography, Button, Link } from "@mui/material";
import emailGIF from "./emailCheck.gif";
import { useDispatch } from "react-redux";
import { resendMail } from "../../redux/auth/authActions";
import { useNotifications } from "../notifications/notifications";

const ConfirmationMessage = ({email}) => {
  const dispatch = useDispatch();
  const [ToastState, setToastState] = useNotifications();
  const onSuccess = (res) => {
    setToastState({ isOpen: true, severity: "success", message:res.msg });
  }
  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
  };

  const handleResendMail = () =>{
    
    dispatch(resendMail(email, onSuccess, onFail))
  }
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emailGIF} alt="Confirmation GIF" style={{ width: "30%" }} />

      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          mb: 8,
          color: "#1f1f1f",
          textAlign: "center",
        }}
      >
        We have sent a confirmation link to{" "}
        <span style={{ fontWeight: 600 }}>{email}</span>. You may close this
        page.
      </Typography>
      <Typography>  
        Didn't get the mail? <Link onClick={handleResendMail} sx={{color:"#40499d", textDecoration:"underline", cursor:"pointer", '&:hover':{color:"darkblue"}}}>Click to resend Link</Link>
      </Typography>
    </Container>
  );
};

export default ConfirmationMessage;
