import React, { useEffect, useRef, useState } from "react";
import WeeklyTriviaDialog from "./WeeklyTriviaDialog";
import PostTrivia from "../TriviaDialogs/PostTrivia";
import useDashboard from "../../../hooks/useDashboard";
import { convertAnswerToAlphabet } from "../../../helpers/helper";
import PostTriviaFail from "../TriviaDialogs/PostTriviaFail";
import PostCompleteTrivia from "../TriviaDialogs/PostCompleteTrivia";
import { FetchUserPointandDiamonds, SubmitTriviaPoints, useDiamonds } from "../../../redux/gamification/gamificationActions";

import useUser from "../../../hooks/useUser";
import { useDispatch } from "react-redux";
import useGamification from "../../../hooks/useGamification";


const WeeklyTrivia = ({
  open,
  handleClose,
  goBack,
}) => {
  const [optionss, setOptions] = useState([]);
  const { userPointDiamond, useDiamond } = useGamification();
  const { Diamond, Points, Levels } = userPointDiamond?.[0] || {};
  const [selectedButton, setSelectedButton] = useState(null);
  const [currentNum, setCurrentNum] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [secondChance, setSecondChance] = useState(false);
  const [points, setPoints] = useState(0)
  const [openPostTriviaDialog, setOpenPostTriviaDialog] = useState(false);
  const { weeklyTrivia } = useDashboard()
  const [testData, setTestData] = useState([]);
  const [openFailedTrivia, setOpenFailedTrivia] = useState(false)
  const [showWrongAnswer, setShowWrongAnswer] = useState(false);
  const [reviewWrongAnswers, setReviewWrongAnswers] = useState([]);
  const [openCompleteTriviaDialog, setOpenCompleteTriviaDialog] = useState(false);
  const { userInfo: { profileId }, } = useUser();
  const dispatch = useDispatch();
  const [availableDiamonds, setAvailableDiamonds] = useState(Diamond)

  const [hintActive, setHintActive] = useState(false);
  const [hintOptions, setHintOptions] = useState([])



  const totalDuration = 28;

  // const duration = 15;
  const audioRef = useRef(null);
  const correctRef = useRef(null);
  const wrongRef = useRef(null);
  const timerx2Ref = useRef(null);


  useEffect(() => {
    dispatch(FetchUserPointandDiamonds(profileId))
    if (weeklyTrivia?.data?.length > 0) {
      if (showWrongAnswer) {
        // Filter only wrong questions based on wrongAnswers array
        const wrongData = weeklyTrivia?.data.filter((_, index) => wrongAnswers?.includes(index));

        // Update test data with only wrong questions
        setTestData(wrongData);

        // Reset the current number to start from the first wrong answer
        setCurrentNum(0);
        console.log(wrongData)
      } else {
        // Set test data to all questions when not reviewing wrong answers
        setTestData(weeklyTrivia?.data);
      }
    }
  }, [weeklyTrivia, showWrongAnswer, wrongAnswers, availableDiamonds]);

  const totalTrivaNumber = testData.length;

  const handleClosePostTriviaDialog = () => {
    setOpenPostTriviaDialog(false)
  }

  const handleCloseCompleteTriviaDialog = () => {
    setOpenCompleteTriviaDialog(false);
  }

  const handleSubmit = () => {
    setIsSubmitted(true);

    const payload = {
      profileId: profileId,
      points: points,
    }

    // Check if we're in review mode (showWrongAnswer)
    if (showWrongAnswer) {
      // If reviewWrongAnswers has 2 or more wrong answers, open the fail trivia dialog
      if (reviewWrongAnswers.length >= 2) {
        handleClose()
        setOpenFailedTrivia(true);
        dispatch(SubmitTriviaPoints(payload))
      }
      else {
        // Else open FailDialog
        handleClose()
        setOpenCompleteTriviaDialog(true);
        dispatch(SubmitTriviaPoints(payload))
      }
    } else {
      // Regular mode logic
      if (wrongAnswers.length >= 1) {
        setOpenPostTriviaDialog(true);

      } else {
        handleClose()
        setOpenCompleteTriviaDialog(true);
        dispatch(SubmitTriviaPoints(payload))
      }
    }
  };


  // const handleSubmit = () => {
  //   setIsSubmitted(true);
  //   // if (showWrongAnswer && wrongAnswers.length >= 1) {
  //   //   setOpenFailedTrivia(true)
  //   // } else {
  //   //   setOpenFailedTrivia(true)
  //   // }

  //   if (wrongAnswers.length >= 1) {
  //     setOpenPostTriviaDialog(true)
  //     // setOpenFailedTrivia(true)
  //   } else {
  //     // setOpenPostTriviaDialog(true)
  //     setOpenFailedTrivia(true)
  //   }
  // }

  // data[0].choices[0].option
  // useEffect(() => {
  //   ;
  //   if (secondChance) {
  //     const wrongQuestionsData = theTestData
  //       .slice(1)
  //       .filter((_, index) => wrongAnswers.includes(index + 1));
  //     setTestData(wrongQuestionsData);
  //   }
  // }, [secondChance]);

  // useEffect(() => {

  //   if (
  //     testData.length > 0 &&
  //     testData[0]?.totalTrivaNumber &&
  //     currentNum === testData[0].totalTrivaNumber - 1 &&
  //     timeLeft <= 0
  //   ) {
  //     // handleSubmit();
  //   }
  // }, [currentNum, testData, timeLeft]);

  const handlePlayButtonClick = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      // Pause the audio if it's currently playing
      if (!audioElement.paused) {
        audioElement.pause();
      }
      // Set the currentTime to 0 to start from the beginning
      audioElement.currentTime = 0;
      audioElement.play();
    }
  };

  const handleStopTickSound = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };
  const handleCorrectClick = () => {
    const audioElement = correctRef.current;
    if (audioElement) {
      audioElement.play();
    }
  };

  const handleWrongClick = () => {
    const audioElement = wrongRef.current;
    if (audioElement) {
      audioElement.play();
    }
  };

  const handleTimerx2 = () => {
    if (availableDiamonds > 0 && !isSubmitted) {
      // Play timerx2 audio and double time
      const audioElement = timerx2Ref.current;
      if (audioElement) {
        audioElement.play();
        audioElement.currentTime = 0;
      }
      setTimeLeft(timeLeft => timeLeft * 2);

      // Deduct diamond via API
      dispatch(useDiamonds(profileId, 1));

      if (useDiamond === 1) {
        setAvailableDiamonds(prev => prev - 1); // Update diamonds locally after API success
      } else {
        console.error('Failed to deduct diamond for Timer x2.');
      }
    }
    // const audioElement = timerx2Ref.current;
    // if (audioElement) {
    //   audioElement.play();
    // }
    // audioElement.currentTime = 0;
    // setTimeLeft((timeLeft) => timeLeft * 2);
  };
  const updateOptions = (newArray) => {
    setOptions(newArray);
  };
  const handleTimeLeft = (timeLeft) => {
    setTimeLeft(timeLeft - 1);
  };


  // Assuming testData is an array where each item contains a 'choices' array
  const options = testData?.[currentNum]?.choices?.map((choice, index) => {
    return {
      optionText: choice?.option, // The actual option text or HTML
      optionNo: choice?.optionNo,  // The option number (1, 2, 3, etc.)
      index: index,
      bgcolor: null,              // To track the index of the option
    };
  });


  const handleAnswerSelect = (selectedOption, index) => {
    if (selectedButton === null) {
      const correctAnswer = testData[currentNum]?.answer;
      const convertedAnswer = convertAnswerToAlphabet(selectedOption);
      const isCorrect = convertedAnswer === correctAnswer;

      const modifiedOptions = [...options];
      if (isCorrect) {
        modifiedOptions[index].bgcolor = "#008000"
      } else
        modifiedOptions[index].bgcolor = "#FF0000"
      // options.map((option, i) => ({
      //   // ...option,
      //   bgcolor: i === index ? (isCorrect ? "#008000" : "#FF0000") : (option.bgcolor || null)
      // }));

      if (!isCorrect) {
        if (!showWrongAnswer) {
          setWrongAnswers(prev => [...prev, currentNum]);
        } else {
          // Review mode, add to reviewWrongAnswers
          setReviewWrongAnswers(prev => [...prev, currentNum]);
        }
        handleWrongClick();
      } else {
        setPoints(prevPoints => prevPoints + 1)
        handleCorrectClick();
      }

      setOptions(modifiedOptions);
      setSelectedButton(index);

      setTestData(prevData => {
        const newData = [...prevData];
        newData[currentNum] = {
          ...newData[currentNum],
          selectedIndex: index,
          selectedOption: selectedOption
        };
        return newData;
      });
    }
  };

  const handleCloseFix = () => {
    const payload = {
      profileId: profileId,
      points: points,
    }

    if (showWrongAnswer) {
      // If reviewWrongAnswers has 2 or more wrong answers, open TotalDialog
      if (reviewWrongAnswers.length >= 2) {
        setOpenFailedTrivia(true);
        handleClose()
      } else {
        // Else open FailDialog

        setOpenCompleteTriviaDialog(true);
        handleClose()
      }
    } else {
      // Regular mode logic
      if (wrongAnswers.length >= 1) {
        handleClose()
        setOpenPostTriviaDialog(false)
        setOpenFailedTrivia(true);
        dispatch(SubmitTriviaPoints(payload))

      } else {
        handleClose()
        setOpenPostTriviaDialog(false)
        setOpenCompleteTriviaDialog(true);

      }
    }

  }


  const handleNextQuestion = () => {
    if (currentNum < testData.length) {
      setCurrentNum((prevNum) => prevNum + 1);
      setSelectedButton(null);
      setTimeLeft(30);
      setHintActive(false);
      setHintOptions([])
    } else {
      // Handle the end of the quiz
      console.log("Quiz completed");
    }
  };

  // functionality to return all wrongAnswers on click of go back
  const handleGoBack = () => {
    // setOptions([]); 
    dispatch(useDiamonds(profileId, 1));
    dispatch(FetchUserPointandDiamonds(profileId));
    handleClosePostTriviaDialog();
    setTimeLeft(30);
    setShowWrongAnswer(true);
    setSelectedButton(null);
    setIsSubmitted(false);
    setHintOptions([])
    setCurrentNum(0);
  };

  return (
    <>
      <WeeklyTriviaDialog
        open={!openPostTriviaDialog ? open : goBack}
        handleClose={handleClose}
        totalTrivaNumber={totalTrivaNumber}
        testData={testData}
        options={hintActive ? hintOptions : optionss}
        selectedButton={selectedButton}
        currentNum={currentNum}
        handleAnswerSelect={handleAnswerSelect}
        handleNextQuestion={handleNextQuestion}
        updateOptions={updateOptions}
        handleSubmit={handleSubmit}
        timeLeft={timeLeft}
        totalDuration={totalDuration}
        handleTimeLeft={handleTimeLeft}
        audioRef={audioRef}
        correctRef={correctRef}
        wrongRef={wrongRef}
        timerx2Ref={timerx2Ref}
        isSubmitted={isSubmitted}
        handleTimerx2={handleTimerx2}
        triviapoints={points}
        availableDiamonds={availableDiamonds}
        setAvailableDiamonds={setAvailableDiamonds}
        hintActive={hintActive}
        setHintActive={setHintActive}
        hintOptions={hintOptions}
        setHintOptions={setHintOptions}
        profileId={profileId}
      />
      <PostTrivia
        open={openPostTriviaDialog}
        handleClose={handleGoBack}
        handleCloseFix={handleCloseFix}
        wrongAnswers={wrongAnswers}
        availableDiamonds={availableDiamonds}
      />
      <PostTriviaFail
        open={openFailedTrivia}
        triviapoints={points}
        handleClose={handleGoBack}
        wrongAnswers={wrongAnswers}
      />
      <PostCompleteTrivia
        open={openCompleteTriviaDialog}
        triviapoints={points}
      />
    </>
  );
};

export default WeeklyTrivia;
