// MY STUDY
export const ALL_SUBJECTS = "ALL_SUBJECTS";
export const ALL_SUBJECTS_START = "ALL_SUBJECTS_START";
export const ALL_SUBJECTS_SUCCESS = "ALL_SUBJECTS_SUCCESS";
export const ALL_SUBJECTS_FAIL = "ALL_SUBJECTS_FAIL";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAIL = "GET_QUESTION_FAIL";
export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTION_COUNT_SUCCESS = "GET_QUESTION_COUNT_SUCCESS";
export const GET_QUESTION_COUNT_FAIL = "GET_QUESTION_COUNT_FAIL";
export const GET_QUESTION_COUNT = "GET_QUESTION_COUNT";
export const LOAD_QUESTION = "LOAD_QUESTION";
export const EXAM_TYPE = "EXAM_TYPE";
export const EXAM_TYPE_SUCCESS = "EXAM_TYPE_SUCCESS";
export const SUBMIT_STUDIES = "SUBMIT_STUDIES";
export const GET_PRACTICE_QUESTION_COUNT = "GET_PRACTICE_QUESTION_COUNT";
export const GET_PRACTICE_QUESTION_COUNT_FAIL =
  "GET_PRACTICE_QUESTION_COUNT_FAIL";
export const GET_PRACTICE_QUESTION = "GET_PRACTICE_QUESTION";
export const GET_PRACTICE_QUESTION_FAIL = "GET_PRACTICE_QUESTION_FAIL";
export const LOAD_PRACTICE_QUESTION = "LOAD_PRACTICE_QUESTION";
export const GET_QUESTION_SOLUTION_LOADING = "GET_QUESTION_SOLUTION_LOADING";
export const GET_QUESTION_SOLUTION_SUCCESS = "GET_QUESTION_SOLUTION_SUCCESS";
export const GET_QUESTION_SOLUTION_FAILURE = "GET_QUESTION_SOLUTION_FAILURE";

// FOR EXAM BY SUBJECT ID
export const ALL_EXAM_LOADING = "ALL_EXAM_LOADING";
export const ALL_EXAM = "ALL_EXAM";
export const ALL_EXAM_FAIL = "ALL_EXAM_FAIL";

// SUBSCRIBED USERS
export const GET_ACTIVE_SUBSCRIPTION = "GET_ACTIVE_SUBSCRIPTION";
export const GET_ACTIVE_SUBSCRIPTION_FAIL = "GET_ACTIVE_SUBSCRIPTION_FAIL";

// POINTS
export const SEND_POINTS = "SEND_POINTS";

// STUDY TIME
export const SUBMIT_STUDY_TIME = "SUBMIT_STUDY_TIME";
export const SUBMIT_LAST_VIEWED_QUESTION_NUMBER =
  "SUBMIT_LAST_VIEWED_QUESTION_NUMBER";
export const GET_LAST_VIEWED_QUESTION_NUMBER =
  "GET_LAST_VIEWED_QUESTION_NUMBER";
export const GET_LAST_VIEWED_QUESTION_NUMBER_FAIL =
  "GET_LAST_VIEWED_QUESTION_NUMBER_FAIL";
