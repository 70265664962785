import { useSelector } from "react-redux";

const useGamification = () => {
  const gamification = useSelector((state) => state.gamification);

  const {
    loading,
    gamificationData,
    error,
    triviaLoading,
    userPointDiamond,
    diamondLoading,
    useDiamond,
  } = gamification;

  return {
    loading,
    gamificationData,
    error,
    triviaLoading,
    userPointDiamond,
    diamondLoading,
    useDiamond,
  };
};

export default useGamification;
