import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FreemiumIcon,
  InfoIcon,
  PopularStar,
} from "../../../assets/icons/Icons";
import EastIcon from "@mui/icons-material/East";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as SchoolPaymentIcon } from "../../../assets/images/SchoolPaymentIcon.svg";
import { ReactComponent as TotalIcon } from "../../../assets/images/TotalIcon.svg";
import { ReactComponent as UpArrowIcon } from "../../../assets/images/UpArrow.svg";
import { ReactComponent as DownArrowIcon } from "../../../assets/images/DownArrow.svg";
import { Link } from "react-router-dom";
import SchoolPricingTable from "../../../components/dashboards/school/payment/subscription/schoolPricingTable";
import useUser from "../../../hooks/useUser";
import { useDispatch } from "react-redux";
import useSettings from "../../../hooks/useSettings";
import moment from "moment";
import PreSub from "../../../components/dashboards/school/payment/subscription/preSub";
import PostSub from "../../../components/dashboards/school/payment/subscription/postSub";
import Payment from "./Pay";
import AllSubscriptionCards from "../../../components/SubPlans";
import useSubscription from "../../../hooks/useSubscription";
import { fetchSchoolSubscriptions, fetchSubPlans } from "../../../redux/subscriptions";
import { school } from "../../../assest/images";
import BusyOverlay from "../../../components/OverLay/BusyOverLay";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const SchoolPayment = ({ }) => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { userInfo } = useUser();
  const dispatch = useDispatch();
  const subscription = useSubscription();
  const { allSubPlan, schoolSub, loading } = subscription;
  const { categoryId, schoolId } = userInfo;
  const [showSchoolPackage, setShowSchoolPackage] = useState(false);




  useEffect(() => {
    // if (schoolSub?.allSubsData?.length > 0) {
    dispatch(fetchSchoolSubscriptions(schoolId));

  }, [schoolId])

  useEffect(() => {
    dispatch(fetchSubPlans(categoryId));
  }, [categoryId]);


  const handleNewSubscription = () => {
    setShowSchoolPackage(true);
  }

  const handleGoBack = () => {
    setShowSchoolPackage(false);
  }

  const subscriptionCards = [
    {
      planName: "Quarterly",
      month: "3",
      amount: "₦5,900",
      loading: false,
      sessionName: ""
    },
    {
      planName: "Bi-annual",
      month: "6",
      amount: "9,900",
      loading: false,
      sessionName: ""
    },
    {
      planName: "Session",
      month: "12",
      amount: "₦16,900",
      loading: false,
      sessionName: "(Sept 2023 - Aug 2024)"
    }
  ]


  return (
    <>
      {loading && <BusyOverlay busy={loading} />}
      <Container sx={{ padding: { xs: "0px" } }}>
        <Box component={Stack} gap={3} padding={0} bgcolor={"#FAFAFB"}>
          <Box>
            <Typography color={"#45464E"} fontSize={"16px"} fontWeight={600}>
              Subscription and Payment
            </Typography>
            <Typography color={"#45464E"} fontSize={"14px"}>
              Proceed with the school subscription plan and make payment
              seamlessly
            </Typography>
          </Box>

          {showSchoolPackage && (
            <Button
              onClick={handleGoBack}
              className="flex justify-start w-fit"
              startIcon={<KeyboardBackspaceIcon color="#313131" />}
              sx={{ textTransform: "inherit", color: "#313131", paddingBottom: "10px" }}
            >
              Go Back
            </Button>)}

          {/* <PreSub onClick={() => setPaymentModal(true)}/> */}
          {(schoolSub?.allSubsData?.length === 0 || schoolSub?.allSubsData?.length === null || showSchoolPackage) &&
            <AllSubscriptionCards subCards={allSubPlan}
              onClick={(subscription) => {
                setSelectedSubscription(subscription);
                setPaymentModal(true);
              }} />
          }
          {
            (schoolSub?.allSubsData?.length > 0 && !showSchoolPackage) &&

            <PostSub newSubscription={handleNewSubscription} packages={schoolSub} />
          }
        </Box>
        <Dialog
          fullScreen={true}
          open={paymentModal}
          onClose={() => setPaymentModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Payment setPaymentModal={setPaymentModal}
              selectedSubscription={selectedSubscription}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default SchoolPayment;
