import React from "react";
import { Box, Container, CssBaseline, Paper } from "@mui/material";
import darkbg from "../../assets/images/darkauthbg.png"

const AuthContainer = ({ children }) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Paper
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "92.9vh",
          backgroundImage: `url(${darkbg})`,
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#40499D",
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            backdropFilter: "blur(4px)",
            WebkitBackdropFilter: "blur(4px)",
            borderRadius: 4,
            py: 3,
            boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.25)",
            backgroundColor: "white",
            maxWidth: "500px",
            my: 10,
          }}
        >
          <Container
            component="main"
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px !important",
            }}
          >
            {children}
          </Container>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default AuthContainer;
