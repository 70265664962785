import React, { useRef } from 'react';
import timerx2sound from "../../../assets/audios/timerx2sound.mp3";

function TimerSound({ audioRef }) {
  return (
    <div>
      <audio ref={audioRef} controls={false}>
        <source src={timerx2sound} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

    </div>
  );
}

export default TimerSound;
