import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import AccordionExpand from "./AccordionExpand";
import {
  PermissionIcon,
  ContentUploadIcon,
} from "../../../../assets/icons/Icons";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CustomButton from "../../../buttons/CustomButton";
import memoji from "../../../../assets/images/MemojiBoySmall.svg";
import styled from "@emotion/styled";
import useUser from "../../../../hooks/useUser";
import { ReactComponent as PlayIcon } from "../../../../assets/images/PlayIcon.svg";
import Payment from "../../../../pages/student/payment/Payment";
import Subscription from "../../../Settings/Subscription";
import { useDispatch } from "react-redux";
import { fetchSubPlans } from "../../../../redux/subscriptions";
import { getCandidateSubscriptionsByProfileId } from "../../../../redux/settings/settingsActions";

export const CustomImage = styled("img")``;

const DashboardOnboarding = ({ paymentModalValue }) => {
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(true);
  const [paymentModal, setPaymentModal] = useState(false);

  useEffect(() => {
    dispatch(fetchSubPlans(userInfo?.categoryId));
  }, []);



  useEffect(() => {
    dispatch(getCandidateSubscriptionsByProfileId(userInfo?.profileId));
  }, []);

  const handleVideoPlaying = () => {
    setVideoPlaying(true);
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const handleProgress = () => {
    const duration = videoRef.current.duration;
    const currentTime = videoRef.current.currentTime;
    const progress = (currentTime / duration) * 100;
    setProgress(progress);
  };
  return (
    <Stack gap={2} sx={{ width: "100%" }}>
      {(!paymentModal && !paymentModalValue) && (
        <>
          <div
            className="md:w-[90%] sm:w-[100%] "
            style={{
              margin: "auto",
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                display={"flex"}
                gap={1}
                variant="h6"
                fontWeight={700}
                fontSize={"20px"}
              >
                Welcome {userInfo?.firstName}
                <CustomImage
                  src={memoji}
                  sx={{ display: "inline", marginLeft: "3" }}
                />{" "}
              </Typography>
              <Typography variant="body2" fontSize={"14px"}>
                Subscribe to our annual plan to have access to your learning
              </Typography>
            </Box>

            <Button
              variant="contained"
              sx={{
                mt: 3,
                color: "white",
                borderColor: "white",
                textTransform: "capitalize",
                paddingX: "40px",
                paddingY: "10px",
                borderRadius: "5px",
              }}
              endIcon={<EastIcon />}
              onClick={() => setPaymentModal(true)}
            >
              Subscribe
            </Button>
          </div>

          {/* <Stack direction={"row"} justifyContent={"space-between"} bgcolor={"#F2F6FF"} border={"1px solid #CBD6FB"} padding={"10px 30px 10px 15px"} alignItems={"center"} gap={3} borderRadius={"5px"}>
        <Typography fontWeight={500} color="primary" fontSize={"14px"} variant='body1' flex={0.9}>Unlock a world of enhanced learning and get exclusive access to more tests and a richer educational experience.
          Explore e-Study Premium by choosing to subscribe today.</Typography>
        <CustomButton onClick={() => navigate("/pricing")} text="Subscribe" />
      </Stack> */}

          <div className="md:w-[90%] sm:w-[100%] " style={{ margin: "auto" }}>
            <div style={{ position: "relative" }}>
              <video
                onTimeUpdate={handleProgress}
                // onPlaying={handleVideoPlaying}
                autoPlay
                ref={videoRef}
                width="100%"
                height="auto"
                className="w-full"
                style={{ borderRadius: "10px" }}
                controls={true}
              >
                {/* <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4" type="video/mp4" /> */}
                <source
                  src="https://waecstudystore.blob.core.windows.net/video/eStudyAds.mp4"
                  type="video/mp4"
                />
              </video>
            </div>

            <div>
              <progress value={progress} max="100" className="hidden" />
              <div className="mb-5 h-2 rounded-full bg-gray-200">
                <div
                  className="h-2 rounded-full bg-orange-500"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        </>
      )}
      {(paymentModal || paymentModalValue) && (
        <>
          <div
            className="md:w-[90%] sm:w-[100%] "
            style={{
              margin: "auto",
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button
                variant="none"
                sx={{
                  mt: 3,
                  color: "black",
                  borderColor: "white",
                  textTransform: "capitalize",
                  paddingY: "10px",
                  borderRadius: "5px",
                }}
                endIcon={<WestIcon />}
                onClick={() => {
                  setPaymentModal(false);
                  navigate('/dashboard/home');
                }}
              >
                Back
              </Button>
              <Typography
                display={"flex"}
                gap={1}
                variant="h6"
                fontWeight={700}
                fontSize={"20px"}
              >
                Subscription and Payment
              </Typography>
              <Typography variant="body2" fontSize={"14px"}>
                Choose a subscription plan and make payment seamlessly
              </Typography>
            </Box>
          </div>

          <Subscription />
        </>
      )}
    </Stack>
  );
};

export default DashboardOnboarding;
