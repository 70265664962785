import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as TotalIcon } from "../../../../../assets/images/TotalIcon.svg"
import { ReactComponent as UpArrowIcon } from "../../../../../assets/images/UpArrow.svg"
import { ReactComponent as DownArrowIcon } from "../../../../../assets/images/DownArrow.svg"
import moment from 'moment'
import NoItem from "../../../../../assets/images/noItem.png"
import { formatCurrency } from '../../../../../helpers/helper'

const PaidSubCards = ({ subDetails }) => {
  return (
    <>
    <Box sx={{
        overflowY: 'auto',
        maxHeight: '200px',
        paddingRight: "10px"
      }}>
      {(subDetails?.length > 0) ? (
      <Grid container spacing={2} >
        {subDetails?.map((subDetail, index) => (
          <Grid item xs={12} sm={12} md={6} key={index}>
            <Box sx={{
              borderRadius: "10px",
              border: "1px solid #DFDFDF",
              position: "relative",
              padding: "20px 15px"
            }}>

              <Box>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"end"}>
                  <Typography sx={{ fontWeight: 700, fontSize: "18px", bgcolor: "#EAECFF", borderRadius: "5px", padding: "2px 10px" }}>{subDetail?.SubscriptionName}</Typography>
                  <Typography sx={{
                    color: "#313131",
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }}>{formatCurrency(subDetail?.Trans_Amt)}
                  </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} pt={0.5}>
                  <Typography sx={{ fontSize: "14px", color: "#B4B4B4" }}>{formatCurrency(subDetail?.Trans_Amt)} / {subDetail?.DaysAllowed / 30} Months / {subDetail?.Licenses} Students</Typography>
                  <Typography sx={{
                    color: "#B4B4B4",
                    fontSize: "14px",
                    //  fontWeight: 700,
                    lineHeight: "normal",
                  }}>Expires {moment(subDetail?.ExpiryDate)?.format("MMM Do, YYYY")}
                  </Typography>
                </Box>
              </Box>

              <Box pt={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <TotalIcon />
                  <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>{subDetail?.Licenses} Licenses</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <UpArrowIcon />
                  <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>{subDetail?.AllocatedLicenses} Allocated Licenses</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <DownArrowIcon />
                  <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>{subDetail?.UnAllocatedLicenses} Unallocated Licenses</Typography>
                </Box>
              </Box>
              {subDetail?.Gifted === "true" && (
                <Box sx={{ position: "absolute", top: -12, right: 0, bgcolor: "#F8EF07", padding: "2px 15px", borderRadius: "20px", display: "flex", alignItems: "center", gap: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography sx={{ fontSize: "12px", color: "#919191" }}>School Name:</Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "12px", color: "#313131" }}>{subDetail?.SchoolName}</Typography>
                  </Box>
                  {/* <Box sx={{ display:"flex", alignItems:"center", gap: 1}}>
                    <Typography sx={{fontSize:"12px", color:"#919191"}}>Sponsored by:</Typography>
                    <Typography sx={{fontWeight: 700, fontSize:"12px", color:"#313131"}}>Senator Adedolapo</Typography>
                  </Box> */}
                </Box>)}
            </Box>
          </Grid>
        ))}
      </Grid>
      ) : (
       <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-[#A3AED0]" style={{paddingTop:"10px"}}>
                 <img src={NoItem} alt="" width={"100px"} height={"auto"}/>
              No subscription available
             </p>
     )}
    </Box>
    </>
  )
}

export default PaidSubCards