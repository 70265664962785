import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import {
  Box,
  Grid,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  Paper,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { fetchSubjectsByProfileId } from "../../../redux/test/testActions";
import { fetchAcademicSubjectsAction, fetchSubjectsAction, fetchTradeSubjectsAction } from "../../../redux/contentUpload/contentUploadActions";
import useUser from "../../../hooks/useUser";
import useTest from "../../../hooks/useTest";
import useContentUpload from "../../../hooks/useContentUpload";
import { addSubjectsByProfileId, updateSubjectsByProfileId } from "../../../redux/dashboard/dashboardActions";
import { arraysAreEqual } from "../../../helpers/helper";
import { useNotifications } from "../../notifications/notifications";
import { useNavigate } from "react-router-dom";
import useDashboard from "../../../hooks/useDashboard";

const GreyOutlinedButton = styled(Button)(({ theme }) => ({
  color: "#334756",
  backgroundColor: "transparent",
  textTransform: "inherit",
  padding: "5px 20px",
  border: ` 1px solid #C8CCD8 `,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCoreSubjectsDialog(props) {
  const { openAddCoreSubjectsDialog, handleCloseCoreSubjectsDialog, subjectModal } = props;
  const [selectedSubjectsPreviewOpen, setSelectedSubjectsPreviewOpen] = useState(false);
  const [ToastState, setToastState] = useNotifications();
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [tradeSubjects, setTradeSubjects] = useState([]);
  const [pathSubjects, setPathSubjects] = useState([]);
  const [addedSubjectIds, setAddedSubjectIds] = useState([]);
  const [saveSubject, setSaveSubject] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const user = useUser();
  const test = useTest();
  const { userInfo } = user;
  const { subjectsData } = test;
  const { subjectData, tradeSubjectData, academicSubjectData } = useContentUpload();
  const { coreSubjectsLoading } = useDashboard();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    handleClearSelection();
    handleCloseCoreSubjectsDialog();
    navigate('/dashboard/home');
  };

  useEffect(() => {
    dispatch(fetchSubjectsAction());
  }, [saveSubject]);

  useEffect(() => {
    dispatch(fetchTradeSubjectsAction());
  }, [saveSubject]);

  useEffect(() => {
    dispatch(fetchAcademicSubjectsAction(userInfo?.profileId));
  }, [saveSubject]);

  useEffect(() => {
    dispatch(fetchSubjectsByProfileId(userInfo?.profileId));
  }, [saveSubject]);

  useEffect(() => {
    if (subjectsData) {
      setPathSubjects(subjectsData);
    }
  }, [subjectsData]);
  // console.log(subjectsData, "subjectsData")
  // console.log(subjectData, "subjectData")
  // console.log(tradeSubjectData, "tradeSubjectData")
  // console.log(academicSubjectData, "academicSubjectData")

  useEffect(() => {
    if (academicSubjectData && Array.isArray(academicSubjectData)) {
      if (subjectsData) {
        const updatedAvailableSubjects = academicSubjectData?.filter(
          (allSubject) =>
            !subjectsData?.some(
              (studsubject) => allSubject.SubjectId === studsubject.SubjectId

            )
        );
        setAvailableSubjects(updatedAvailableSubjects);
      }
    }
  }, [subjectsData]);

  useEffect(() => {
    if (tradeSubjectData && Array.isArray(tradeSubjectData)) {
      if (subjectData) {
        const updatedTradeSubjects = tradeSubjectData?.filter(
          (allTradeSubject) =>
            !subjectsData?.some(
              (studsubject) => allTradeSubject.SubjectId === studsubject.SubjectId
            )
        );
        setTradeSubjects(updatedTradeSubjects);
      }

    }
  }, [subjectsData]);

  const isRemovable = (subject) => {
    const isSubjectInData = subjectsData?.some(
      (dataSubject) => dataSubject.SubjectId === subject.SubjectId
    );

    return isSubjectInData;
  };

  const handleAddSubject = (subjectToAdd) => {
    // Check if a trade subject is already selected
    if (subjectToAdd.IsTrade && pathSubjects.some(subject => subject.IsTrade)) {
      setToastState({
        isOpen: true,
        severity: "info",
        message: "You can only select one trade subject",
      });
      return;
    }

    // Check if the maximum number of academic subjects (5) is reached
    if (subjectToAdd.IsAcademic && pathSubjects.filter(subject => !subject.IsTrade && !subject.IsDefaultCore).length >= 5) {
      setToastState({
        isOpen: true,
        severity: "info",
        message: "You can only select up to 5 academic subjects",
      });
      return;
    }


    //   let updatedTradeSubjects = tradeSubjectData?.filter(
    //     (subject) => subject.SubjectId !== subjectToAdd.SubjectId
    // );
    //   setTradeSubjects(updatedTradeSubjects);

    //   let updatedAvailableSubjects = availableSubjects.filter(
    //     (subject) => subject.SubjectId !== subjectToAdd.SubjectId
    //   );
    //   setAvailableSubjects(updatedAvailableSubjects);

    // setPathSubjects((prevSubjects) => {
    //   const updatedSelectedSubjects = [...prevSubjects];
    //   setAddedSubjectIds((prevIds) => [...prevIds, subjectToAdd.subjectId]);
    //   updatedSelectedSubjects.push({ ...subjectToAdd, IsSelected: true });
    //   const existingSubject = updatedSelectedSubjects.find(
    //     (subject) =>
    //       (subject.subjectId ? subject.subjectId : subject.SubjectId) ===
    //       (subjectToAdd.subjectId ? subject.subjectId : subject.SubjectId)
    //   );
    //   if (existingSubject) {
    //     existingSubject.IsSelected = true;
    //   } else {
    //     updatedSelectedSubjects.push({ ...subjectToAdd, IsSelected: true });
    //   }
    //   return updatedSelectedSubjects;
    // });

    setPathSubjects((prevSubjects) => {
      const updatedSelectedSubjects = [...prevSubjects];
      const isSubjectAlreadyAdded = updatedSelectedSubjects.some(
        (subject) => subject.SubjectId === subjectToAdd.SubjectId
      );
      if (!isSubjectAlreadyAdded) {
        updatedSelectedSubjects.push({ ...subjectToAdd, IsSelected: true });
        setAddedSubjectIds((prevIds) => [...prevIds, subjectToAdd.SubjectId]);
      }
      return updatedSelectedSubjects;
    });

    // Remove the subjectToAdd from availableSubjects
    setAvailableSubjects(prevAvailableSubjects =>
      prevAvailableSubjects.filter(subject => subject.SubjectId !== subjectToAdd.SubjectId)
    );

    // Remove the subjectToAdd from tradeSubjectData if it's a trade subject
    if (subjectToAdd.IsTrade) {
      setTradeSubjects(prevTradeSubjects =>
        prevTradeSubjects.filter(subject => subject.SubjectId !== subjectToAdd.SubjectId)
      );
    }

  };

  const handleRemoveSubject = (subjectToRemove) => {
    let updatedSelectedSubjects = pathSubjects?.filter(
      (subject) => subject.SubjectId !== subjectToRemove.SubjectId
    );
    setPathSubjects(updatedSelectedSubjects);
    setAddedSubjectIds((prevIds) =>
      prevIds.filter((id) =>
        id !== subjectToRemove.SubjectId
      )
    );

    if (subjectToRemove.IsAcademic) {
      let updatedAvailableSubjects = [...availableSubjects, subjectToRemove];
      setAvailableSubjects(updatedAvailableSubjects);
    }

    if (subjectToRemove.IsTrade) {
      let updatedTradeSubjects = [...tradeSubjectData, subjectToRemove];
      setTradeSubjects(updatedTradeSubjects);
    }
  };

  const handleClearSelection = () => {
    setPathSubjects(subjectsData);
    const updatedAvailableSubjects = academicSubjectData?.filter(
      (allSubject) =>
        !subjectsData?.some(
          (studsubject) => allSubject.SubjectId === studsubject.SubjectId
        )
    );
    setAvailableSubjects(updatedAvailableSubjects);
    const updatedTradeSubjects = tradeSubjectData?.filter(
      (allSubject) =>
        !subjectsData?.some(
          (studsubject) => allSubject.SubjectId === studsubject.SubjectId
        )
    );
    setTradeSubjects(updatedTradeSubjects);
  };

  const sortedPathSubjects = [...pathSubjects].sort((a, b) => {
    // Check if both a and b are not present in subjectsData
    const aNotInSubjectsData = !subjectsData?.some(subject => subject.SubjectId === a.SubjectId);
    const bNotInSubjectsData = !subjectsData?.some(subject => subject.SubjectId === b.SubjectId);

    // If both a and b are not in subjectsData, prioritize them
    if (aNotInSubjectsData && bNotInSubjectsData) {
      return 0; // Keep their relative order unchanged
    }

    // If only a is not in subjectsData, prioritize it
    if (aNotInSubjectsData) {
      return -1; // a should come before b
    }

    // If only b is not in subjectsData, prioritize it
    if (bNotInSubjectsData) {
      return 1; // b should come before a
    }

    // If both a and b are in subjectsData, apply the existing sorting logic
    if (a.IsDefaultCore && !b.IsDefaultCore) {
      return -1;
    } else if (!a.IsDefaultCore && b.IsDefaultCore) {
      return 1;
    } else {
      return 0;
    }
  });


  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      severity: res.state == 1 ? "success" : "warning",
      message: res.msg,
    });
    setSaveSubject(true);
    setSelectedSubjectsPreviewOpen(false);
    handleCloseCoreSubjectsDialog();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const handleAddSubjectsClick = () => {
    // const filteredPathSubjects = pathSubjects?.filter(
    //   (subject) => !subject.IsDefaultCore
    // );
    const filteredPathSubjects = pathSubjects?.filter(subject =>
      !subjectsData?.some(dataSubject =>
        dataSubject.SubjectId === subject.SubjectId
      )
    );

    const selectedSubjectIds = filteredPathSubjects?.map((subject) => ({
      subjectId: subject.SubjectId
    }));

    const payload = {
      profileId: userInfo?.profileId,
      subjectIds: selectedSubjectIds,
    };

    dispatch(updateSubjectsByProfileId(payload, onSuccess));
  };

  const handleSaveAndPreview = () => {
    setSelectedSubjectsPreviewOpen(true);
    // Update selectedSubjects state with the current list of selected subjects
    setSelectedSubjects(pathSubjects);
  };


  return (
    <>
      <Dialog
        fullWidth={true}
        fullScreen={fullScreen}
        maxWidth={"md"}
        open={openAddCoreSubjectsDialog || subjectModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: { borderRadius: 20, padding: 10 },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          id="responsive-dialog-title"
        >
          <Box>
            <Typography sx={{
              fontSize: "20px",
              fontWeight: 700,
            }}>
              {subjectsData?.length === 9 ? `My Selected Subjects (${subjectsData?.length})` :
                "Choose Your Subjects"}
            </Typography>
            {subjectsData?.length !== 9 && (
              <Typography sx={{ fontSize: "16px", pt: 0, color: "#919191" }}>
                Maximum of <span style={{ fontWeight: 700 }}>9</span> subjects
              </Typography>)}
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            padding: "0px 10px",
            textTransform: "capitalize",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {subjectsData?.length === 9 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                height: "100%",
                maxHeight: "340px",
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  pt: 1,
                }}
              >
                {sortedPathSubjects?.map((eachSubject, i) => (
                  <Chip
                    key={i}
                    label={
                      eachSubject?.SubjectName
                        ? eachSubject?.SubjectName
                        : eachSubject?.subjectName
                    }
                    sx={
                      // eachSubject.IsDefaultCore
                      isRemovable(eachSubject)
                        ? {
                          marginBottom: "10px",
                          backgroundColor: "#fff",
                          display: "flex",
                          color: "#919191",
                          justifyContent: "space-between",
                          border: "1px solid #CBD1D9",
                        }
                        : {
                          marginBottom: "10px",
                          backgroundColor: "#EEEEF6",
                          color: "#313131",
                          display: "flex",
                          justifyContent: "space-between",
                        }
                    }
                    onDelete={
                      // eachSubject.IsDefaultCore
                      isRemovable(eachSubject)
                        ? undefined
                        : () => handleRemoveSubject(eachSubject)
                    }
                    deleteIcon={
                      // eachSubject.IsDefaultCore
                      isRemovable(eachSubject)
                        ? undefined : (
                          <ClearIcon fontSize="small" color="#313131" />
                        )
                    }
                  />
                ))}
              </Box>
            </Box>
          )}

          {subjectsData?.length !== 9 && (
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #C8CCD8",
                    borderRadius: "5px",
                    padding: "10px",
                    height: "100%",
                    maxHeight: "340px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "5px",
                      padding: "10px",
                      height: "100%",
                      maxHeight: "155px",
                    }}
                  >
                    <Typography variant="subtitle1" pb={0.5} fontWeight={500}>
                      Trade Subjects
                    </Typography>

                    {tradeSubjects.length === 0 && (
                      <div
                        className="flex justify-center items-center"
                        style={{ height: "100%" }}
                      >
                        <h3 style={{ color: "#c0c0c0", fontStyle: "italic" }}>
                          No trade subject
                        </h3>
                      </div>
                    )}
                    {tradeSubjects.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          pt: 1,
                          overflowY: "auto",
                        }}
                      >
                        {tradeSubjects?.map((subject, i) => (
                          <Chip
                            key={i}
                            label={
                              subject?.SubjectName
                                ? subject?.SubjectName
                                : subject?.subjectName
                            }
                            variant="outlined"
                            onClick={() => handleAddSubject(subject)}
                            sx={{
                              cursor: "pointer",
                              pr: 2,
                              "& .MuiChip-icon": {
                                order: 1,
                              },
                              "& .MuiChip-label": {
                                pr: 0,
                              },
                              "&&:hover": {
                                backgroundColor: "#40499D",
                                color: '#fff',
                                '.adds': {
                                  color: '#fff'
                                }
                              },
                            }}
                            icon={<AddIcon fontSize="small" className="adds" />}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "5px",
                      padding: "10px",
                      mt: "10px",
                      height: "100%",
                      maxHeight: "155px",
                    }}
                  >
                    <Typography variant="subtitle1" pb={0.5} fontWeight={500}>
                      Academic Subjects
                    </Typography>

                    {availableSubjects.length === 0 && (
                      <div
                        className="flex justify-center items-center"
                        style={{ height: "100%" }}
                      >
                        <h3 style={{ color: "#c0c0c0", fontStyle: "italic" }}>
                          No available subject
                        </h3>
                      </div>
                    )}
                    {availableSubjects.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          pt: 1,
                          overflowY: "auto",

                        }}
                      >
                        {availableSubjects?.map((subject, i) => (
                          <Chip
                            key={i}
                            label={
                              subject?.SubjectName
                                ? subject?.SubjectName
                                : subject?.subjectName
                            }
                            variant="outlined"
                            onClick={() => handleAddSubject(subject)}
                            sx={{
                              cursor: "pointer",
                              pr: 2,
                              "& .MuiChip-icon": {
                                order: 1,
                              },
                              "& .MuiChip-label": {
                                pr: 0,
                              },
                              "&&:hover": {
                                backgroundColor: "#40499D",
                                color: '#fff',
                                '.adds': {
                                  color: '#fff'
                                }
                              },
                            }}
                            icon={<AddIcon fontSize="small" className="adds" />}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #C8CCD8",
                    borderRadius: "5px",
                    padding: "10px",
                    height: "100%",
                    maxHeight: "340px",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Selected Subjects ({pathSubjects?.length})
                  </Typography>
                  <Box
                    sx={{
                      pt: 1,
                    }}
                  >
                    {sortedPathSubjects?.map((eachSubject, i) => (
                      <Chip
                        key={i}
                        label={
                          eachSubject?.SubjectName
                            ? eachSubject?.SubjectName
                            : eachSubject?.subjectName
                        }
                        sx={
                          // eachSubject.IsDefaultCore
                          isRemovable(eachSubject)
                            ? {
                              marginBottom: "10px",
                              backgroundColor: "#fff",
                              display: "flex",
                              color: "#919191",
                              justifyContent: "space-between",
                              border: "1px solid #CBD1D9",
                            }
                            : {
                              marginBottom: "10px",
                              backgroundColor: "#EEEEF6",
                              color: "#313131",
                              display: "flex",
                              justifyContent: "space-between",
                            }
                        }
                        onDelete={
                          // eachSubject.IsDefaultCore
                          isRemovable(eachSubject)
                            ? undefined
                            : () => handleRemoveSubject(eachSubject)
                        }
                        deleteIcon={
                          // eachSubject.IsDefaultCore
                          isRemovable(eachSubject)
                            ? undefined : (
                              <ClearIcon fontSize="small" color="#313131" />
                            )
                        }
                      />
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions
          sx={{
            textTransform: "capitalize",
            p: 3,
            justifyContent: subjectsData && subjectsData?.length !== 9 ? "space-between" : "flex-end",
          }}
        >
          {subjectsData?.length !== 9 && (
            <GreyOutlinedButton
              sx={{ padding: "5px 15px" }}
              autoFocus
              onClick={handleClearSelection}
            >
              <Typography>Clear Selection</Typography>
            </GreyOutlinedButton>)}

          {subjectsData?.length === 9 ?
            <Button onClick={handleCloseCoreSubjectsDialog} sx={{
              padding: "5px 15px",
              color: "#334756",
              backgroundColor: "#fff",
              textTransform: "inherit",
              "&:hover": {
                backgroundColor: "#fff",
              },
              border: "1px solid #C8CCD8"
            }}>
              Close
            </Button>
            :
            (availableSubjects.length > 0 && (
              <Button
                sx={{
                  padding: "5px 15px",
                  color: "#fff",
                  backgroundColor: "#40499D",
                  textTransform: "inherit",
                  "&:hover": {
                    backgroundColor: "#333D95",
                  },
                  "&:disabled": {
                    backgroundColor: "#E4E1E1",
                    color: "#ccc",
                  },
                }}
                autoFocus
                // onClick={handleAddSubjectsClick}
                onClick={handleSaveAndPreview}
                disabled={
                  // availableSubjects.length === 0 ||
                  arraysAreEqual(subjectsData, sortedPathSubjects)
                }
              >
                Continue
              </Button>
            )
            )}
        </DialogActions>
      </Dialog>


      <Dialog
        open={selectedSubjectsPreviewOpen}
        onClose={() => setSelectedSubjectsPreviewOpen(false)}
        aria-labelledby="preview-dialog-title"
        PaperProps={{
          style: { borderRadius: 10, padding: 10 },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          id="preview-dialog-title"
        >
          <Box>
            <Typography sx={{
              fontSize: "18px",
              fontWeight: 600,
            }}>
              Preview Subjects
            </Typography>
            <Typography sx={{ fontSize: "16px", pt: 0, color: "#919191" }}>
              <span style={{ fontWeight: 700 }}>{selectedSubjects?.length}</span> subjects selected
            </Typography>
          </Box>
          <IconButton onClick={() => setSelectedSubjectsPreviewOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{
            border: "1px solid #C4C4C4",
            padding: "10px",
            borderRadius: "10px"
          }}>
            <Box
              sx={{
                backgroundColor: "#F7F7F7",
                borderRadius: "5px",
                padding: "20px 10px 10px 10px",
                // mt:"10px",

              }}
            >
              {selectedSubjects.map((subject) => (
                <Chip key={subject.SubjectId}
                  label={subject?.SubjectName
                    ? subject?.SubjectName
                    : subject?.subjectName}
                  sx={{
                    marginBottom: "10px",
                    marginRight: "5px",
                    backgroundColor: "#fff",
                    // display: "flex",
                    color: "#313131",
                    // justifyContent: "space-between",
                    border: "1px solid #CBD1D9",
                  }} />
              ))}

            </Box>
            <Typography sx={{ color: "#919191", fontSize: "16px", mt: 1, pl: 1 }}>
              Please confirm your subject selections before proceeding. Once you click on the <span style={{ color: "#40499D", fontWeight: 700 }}>Save</span> button, changes cannot be made.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={() => setSelectedSubjectsPreviewOpen(false)} sx={{
            padding: "5px 15px",
            color: "#334756",
            backgroundColor: "#fff",
            textTransform: "inherit",
            "&:hover": {
              backgroundColor: "#fff",
            },
            border: "1px solid #C8CCD8"
          }}>
            Go Back
          </Button>


          <Button onClick={handleAddSubjectsClick}
            sx={{
              padding: "5px 15px",
              color: "#fff",
              backgroundColor: "#40499D",
              textTransform: "inherit",
              "&:hover": {
                backgroundColor: "#333D95",
              },
              "&:disabled": {
                backgroundColor: "#E4E1E1",
                color: "#ccc",
              },
            }}
            autoFocus
            disabled={coreSubjectsLoading}
          >
            {!coreSubjectsLoading ? (
              "Save"
            ) : (
              <DataUsageIcon size={24} className="animate-spin " />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <ToastState />
    </>
  );
}
