import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Section from "../../assets/images/Section.png";
import useResponsive from "../../hooks/useMediaQuery";
import CommunityMobile from "./CommunityMobile";

const Community = () => {
  const isMobile = useResponsive("down", "md");

  return (
    <Box
      component={"div"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      textAlign={"center"}
      bgcolor={"#fff"}
      padding={isMobile ? "40px 20px" : "60px 40px"}
      justifyContent={"center"}
      boxSizing={"border-box"}
      width={"100%"}
    >
      {isMobile ? (
        <CommunityMobile />
      ) : (
        <div style={{ position: "relative", textAlign: "center" }}>
          <img
            src={Section}
            alt="Join Community"
            height={"150px"}
            style={{ position: "relative" }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box color="#fff">
              <Typography
                fontWeight={"800"}
                fontSize={"45px"}
                fontFamily={"Montserrat"}
              >
                Prep for one-shot success!
              </Typography>
              <Typography
                width={"600px"}
                fontSize={"20px"}
                fontWeight={400}
                lineHeight={"180%"}
                fontFamily={"Montserrat"}
              >
                Ready to go into your WASSCE (SC/PC) examination with confidence
                of success? Join thousands of schoolers like you on eStudy.
              </Typography>
              <Link to="/auth/signup">
                <Button
                  variant="contained"
                  color="brandSecondary"
                  sx={{
                    textTransform: "capitalize",
                    color: "#40499d",
                    fontWeight: "bold",
                    fontSize: "24px",
                    padding: "20px 40px",
                    marginTop: "25px",
                    fontFamily: "Montserrat",
                    "&:hover": {
                      backgroundColor: "#e1da11",
                    },
                  }}
                >
                  Sign Up Now
                </Button>
              </Link>
            </Box>
          </div>
        </div>
      )}
    </Box>
  );
};

export default Community;
