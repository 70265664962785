import { useSelector } from "react-redux";

const useAdminUser = () => {
  const adminUser = useSelector((state) => state.adminAuth);

  const {
    isLoading,
    user: adminUserData,
    token,
    refresh_token,
    adminUserByProfileId,
    adminLoading,
    updateLoading,
    updateAdminProfile,
  } = adminUser;

  return {
    isLoading,
    token,
    refresh_token,
    userInfo: adminUserData,
    adminUserByProfileId,
    adminLoading,
    updateLoading,
    updateAdminProfile,
  };
};

export default useAdminUser;
