import React from "react";
import "./Preloader.css";
import preloader from "../../assets/images/preloader2.gif";

const Preloader = () => {
  return (
    <div className="page-cover">
      <div className="inner-page-div">
        <div className="loader-img-div">
          <img
            className="loader-img"
            src={preloader}
            alt="Loading...(Designed by Lottie Files)"
          />
        </div>
        <div className="random-text-div">
          <p>The future depends on what you do today.</p>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
