import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, TextField, Typography, useMediaQuery } from '@mui/material';
import bgImg from "../../../assets/images/schoolBackground.png";
import special_offer from "../../../assets/images/Special_Offer.png";
import { useDispatch } from 'react-redux';
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { SuperSchoolClaimBonus } from '../../../redux/school/schoolActions';
import useUser from '../../../hooks/useUser';





const BootstrapDialog = styled(Dialog)(({ theme, width, fullScreen }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
    "& > .MuiBackdrop-root": {
        backdropFilter: "blur(4px)",
    },
    "& .MuiDialog-paper": {
        borderRadius: fullScreen ? "0px" : "12px",
        ...(width &&
            !fullScreen && {
            width: width,
        }),
        boxShadow: 'rgba(62, 80, 96, 0.2) 0px 4px 20px'
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, px: 4, fontWeight: 900 }} {...other}>
            <Typography sx={{ fontWeight: 600, }}>
                Enter Your Email
            </Typography>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 10,
                    color: (theme) => theme.palette.grey[500],
                    backgroundColor: (theme) => theme.palette?.closeIcon?.main,
                    borderRadius: "5px",
                    "&:hover": {
                        color: (theme) => theme.palette.error.main,
                    },
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export const SpecialOffercardBonusCard = ({ setToastState }) => {
    const theme = useTheme();
    const [openModal, setOpenModal] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    // const HandleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setOpenModal(false);
    const {
        userInfo: { firstName, surname, email, schoolName, profileId },
    } = useUser();




    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
        // setInput({ ...input, email: event.target.value });
    };

    const isEmailValid = (email) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };



    const onSuccess = (res) => {
        setToastState({
            isOpen: true,
            message: res?.msg == "" ? "Login detials has been sent to this mail" : "",
            severity: "success",
        });
        handleCloseModal(false);
    };

    const onFail = (res) => {
        setToastState({
            isOpen: true,
            message: res?.msg ?? 'Failed to send login details to this mail ',
            severity: "error",
        });
    };


    const HandleSendMail = () => {
        if (userEmail === email) {
            setToastState({
                isOpen: true,
                message: "The email provided is the same as your registered email.Please use a different email address for this action",
                severity: "error",
            });

            return;
        }

        if (!isEmailValid(userEmail)) {
            setToastState({
                isOpen: true,
                message: "Email not valid",
                severity: "error",
            });
            return;
        }
        dispatch(SuperSchoolClaimBonus(profileId, onSuccess, onFail));
    };
    return (
        <>
            <div className="flex flex-col lg:flex-row gap-5 justify-between lg:items-center rounded-[10px] bg-cover bg-no-repeat text-white py-3 px-6"
                style={{ backgroundImage: `url(${bgImg})` }}
            >
                <div className="flex gap-[27px] items-center">
                    <img
                        src={special_offer}
                        alt="offer pics"
                        className="object-cover w-[50px] h-[50px]"
                    />
                    <div className="flex gap-[9px] flex-col relative">
                        <div className="flex gap-3 items-center">
                            <h2 className="font-medium text-[18px]">Congratulations!</h2>

                        </div>
                        <div className="flex gap-[1px] flex-col text-[14px] font-medium">
                            <h3>You have been gifted the Super School Subscription Package where you have access to all subjects and can log in.</h3>
                            <h3>with up to 10 devices simultaneously. Kindly claim bonus to start benefitting from this package</h3>
                        </div>
                    </div>

                </div>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: "#fff",
                        color: "#40499D",
                        borderRadius: "5px",
                        whiteSpace: 'nowrap',
                    }}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    Claim Bonus

                </Button>

            </div>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={openModal}
                fullScreen={fullScreen}
                fullWidth={true}
                PaperProps={{
                    elevation: 0,
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseModal}
                >
                </BootstrapDialogTitle>

                <DialogContent>
                    <Typography sx={{ px: 2, fontSize: "12" }}>
                        Fill in the email you would like to use to login for this plan
                    </Typography>
                </DialogContent>

                <div className="flex flex-col gap-[24px] px-9 mb-8">
                    <FormControl variant="standard" fullWidth>
                        <TextField
                            placeholder="Doejohn@gmail.com"
                            value={userEmail}
                            onChange={handleEmailChange}
                        />

                    </FormControl>
                </div>


                <DialogActions>
                    <Button
                        onClick={HandleSendMail}
                        sx={{
                            borderRadius: "10px",
                            p: 2,
                            textTransform: "inherit",
                        }}
                        variant="contained"
                        color="primary"
                        disabled={!isEmailValid(userEmail)}
                    >
                        Send
                    </Button>

                </DialogActions>
            </BootstrapDialog>

        </>

    )
}
