import React, { useState, useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import {
  TemplateNameSection,
  TemplateZone,
  fileNameSection,
  filesUploadedZone,
} from "./@DropZoneStyles";
import { ExcelIcon } from "../../assets/icons/Icons";
import { Link } from "react-router-dom";
// import template from "../../assets/files/Template_student.xlsx"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TemplateArea(props) {
  const { file, onClose } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const initialFormValues = {
    name: "",
  };
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [loadingButton, setLoadingButton] = useState(false);
  const uploadedItemRef = useRef(null);
  const [value, setValue] = React.useState(0);
  const [bulkFileUpload, setBulkFileUpload] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload = (e) => {
    e.preventDefault();
    // let dataBlob = SkillSetExcelFile;
    let sliceSize = 1024;
    // let byteCharacters = atob(SkillSetExcelFile);
    let bytelength = byteCharacters.length;
    let sliceCount = Math.ceil(bytelength / sliceSize);
    let byteArrays = new Array(sliceCount);
    for (let sliceIndex = 0; sliceIndex < sliceCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytelength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    let blob = new Blob(byteArrays, { type: "application/vnd.ms-excel" });
    FileSaver.saveAs(new Blob([blob], {}), "Skill Upload Template.xlsx");
  };
  function resetForm() {
    setFormValues({
      ...formValues,
      name: "",
    });
    setBulkFile(initialBulkFileValues);
    setBulkFileUpload(null);
  }

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { pt: 2, pb: 0, pl: 2 },
        textTransform: "initial",
      }}
      noValidate
      autoComplete="off"
    >
      <Box>
        {file?.length > 0 ? (
          file.map((item) => (
            <div style={filesUploadedZone} ref={uploadedItemRef}>
              <div style={fileNameSection}>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "7px",
                    border: "1.3px solid #e0e0e0",
                    borderRadius: "7pX",
                    width: "fit-content",
                  }}
                >
                  <ExcelIcon />
                </div>
                <div>
                  <p style={{ fontWeight: 600 }}>{item?.name}</p>
                  <p style={{ marginTop: "0px", fontWeight: 400 }}>
                    {Math.round(item?.size / 1024)} KB
                  </p>
                </div>
              </div>
              <>
                <IconButton
                  size="small"
                  sx={{ marginTop: "-15px" }}
                  onClick={onClose}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </>
            </div>
          ))
        ) : (
          <div style={TemplateZone} ref={uploadedItemRef}>
            <div>
              <div style={TemplateNameSection}>
                <div>
                  {" "}
                  <ExcelIcon />
                </div>

                <div>
                  <p style={{ fontWeight: 600 }}>Student Enrolment Template</p>
                </div>
              </div>
              <div>
                <p
                  style={{
                    marginTop: "10px",
                    fontWeight: 400,
                    color: "9DA3AA",
                    width: "90%",
                  }}
                >
                  Download the sample template by clicking on the download
                  button. Add students data to the template file and upload the
                  file for submission.
                </p>
              </div>
            </div>
            <>
              <Link
                to="/assests/files/Template.xlsx"
                target="_blank"
                download
                sx={{ color: "blue" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#40499D",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#40499D", // Change to desired hover color
                      color: "white", // Change to desired hover text color
                    },
                  }}
                >
                  Download
                </Button>
              </Link>
            </>
          </div>
        )}
      </Box>
    </Box>
  );
}
