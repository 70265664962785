import React from 'react'
import PathChart from './Chart'
import { Box, Button, Typography } from '@mui/material'
import { AddUserIcon, CircleIcon, PlusIcon } from '../../../../assets/icons/Icons'
import { useNavigate } from 'react-router-dom'
import NoItem from "../../../../assets/images/noItem.png"


const SchoolPath = ({studentsPath}) => {
const navigate = useNavigate();


const scienceCount = (Array.isArray(studentsPath)) ? (studentsPath?.length > 0 ? studentsPath[0]?.Science : 0) : 0;
    const artCount = (Array.isArray(studentsPath)) ? (studentsPath?.length > 0 ? studentsPath[0]?.Art : 0) : 0;
    const commercialCount = (Array.isArray(studentsPath)) ? (studentsPath?.length > 0 ? studentsPath[0]?.Commercial : 0) : 0;

  return (
    <Box p={2} bgcolor={"#fff"} borderRadius={"20px"} border={"1px solid #F3F4FE"}>
    <Box mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Typography sx={{fontSize:"16px", fontWeight:700, color:"#2B3674"}}>Student Path</Typography>
        <Button variant='outlined' startIcon={<AddUserIcon />} onClick={() => navigate("/sponsor/students")} sx={{textTransform: "capitalize", fontSize:"12px", bgcolor: "#fff", color: "#40499D", borderRadius: "20px", "&:hover": {
          color: "#fff", bgcolor: "#40499D"
        }, }}>Manage Students</Button>
    </Box>
    {(scienceCount === 0 && artCount === 0 && commercialCount === 0) ? 
           <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-dark-gray" style={{paddingTop:"20px"}}>
           <img src={NoItem} alt="" width={"100px"} height={"auto"}/>
       </p> :
    <PathChart scienceCount={scienceCount} artCount={artCount} commercialCount={commercialCount}/>
}
    <Box sx={{boxShadow:"0px 18px 40px 0px rgba(112, 144, 176, 0.12)", padding:"20px", bgcolor:"#fff", borderRadius:"15px", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
      <Box display={"flex"} alignItems={"flex-start"} >
        <Box sx={{marginTop:"5px", marginRight:"5px"}}>
      <CircleIcon
          size={8}
          color={"#40499D"}
        />
        </Box>
        <Box>
          <Typography sx={{color:"#A3AED0", fontSize:"14px", fontWeight:500}}>Science</Typography>
          <Typography sx={{color:"#2B3674", fontSize:"18px", fontWeight:700}}>{scienceCount}</Typography>
        </Box>
      </Box>
      <Box sx={{borderLeft: "2px solid #F4F7FE", height:"3rem"}}></Box>
      <Box display={"flex"} alignItems={"flex-start"} >
        <Box sx={{marginTop:"5px", marginRight:"5px"}}>
      <CircleIcon
          size={8}
          color={"#DBEBFB"}
        />
        </Box>
        <Box>
          <Typography sx={{color:"#A3AED0", fontSize:"14px", fontWeight:500}}>Art</Typography>
          <Typography sx={{color:"#2B3674", fontSize:"18px", fontWeight:700}}>{artCount}</Typography>
        </Box>
      </Box>
      <Box sx={{borderLeft: "2px solid #F4F7FE", height:"3rem"}}></Box>
      <Box display={"flex"} alignItems={"flex-start"} >
        <Box sx={{marginTop:"5px", marginRight:"5px"}}>
      <CircleIcon
          size={8}
          color={"#DEAB28"}
        />
        </Box>
        <Box>
          <Typography sx={{color:"#A3AED0", fontSize:"14px", fontWeight:500}}>Commercial</Typography>
          <Typography sx={{color:"#2B3674", fontSize:"18px", fontWeight:700}}>{commercialCount}</Typography>
        </Box>
      </Box>
    </Box>
</Box>
  )
}

export default SchoolPath