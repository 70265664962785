import { useSelector } from "react-redux";

const useDashboard = () => {
  const dashboard = useSelector((state) => state.dashboard);

  const {
    coreSubjects,
    theCoreSubjects,
    coreSubjectsLoading,
    coreLoaded,
    dashboardStat,
    dashboardTestStat,
    adminDashboardStat,
    dashboardStudyIPStat,
    dashboardStudyTime,
    loadingOn,
    isLoading,
    isLoadingg,
    loadingg,
    isLoad,
    isLoadingLearning,
    dashboardStudentLearning,
    dashboardTestDetails,
    isLoadingTest,
    schoolDashboardStat,
    isLoadingSchStat,
    topPerformingStudents,
    isLoadingTopStudents,
    studentsPath,
    isLoadingStudentsPath,
    schoolStudyTime,
    isLoadingSchStudyTime,
    studentsSubjects,
    isLoadingStudentsSubjects,
    amountDetails,
    isLoadingAmountDetails,
    sponsorDashboardStat,
    isLoadingSponsorStat,
    sponsorStudentsPath,
    isLoadingSponsorStudentsPath,
    sponsorBeneficiaries,
    isLoadingSponsorBeneficiaries,
    isChartLoad,
    adminDashboardChart,
    admittedYear,
    adminDashboardRegUsersChart,

    //Leaderboard
    leaderboardLadder,
    isLeaderboardLadderLoading,
    weeklyTrivia,
  } = dashboard;

  return {
    coreSubjects,
    theCoreSubjects,
    coreSubjectsLoading,
    coreLoaded,
    dashboardStat,
    dashboardStudyIPStat,
    dashboardStudyTime,
    loadingOn,
    dashboardTestStat,
    adminDashboardStat,
    isLoading,
    isLoadingg,
    loadingg,
    isLoad,
    isLoadingLearning,
    dashboardStudentLearning,
    dashboardTestDetails,
    isLoadingTest,
    schoolDashboardStat,
    isLoadingSchStat,
    topPerformingStudents,
    isLoadingTopStudents,
    studentsPath,
    isLoadingStudentsPath,
    schoolStudyTime,
    isLoadingSchStudyTime,
    studentsSubjects,
    isLoadingStudentsSubjects,
    amountDetails,
    isLoadingAmountDetails,
    sponsorDashboardStat,
    isLoadingSponsorStat,
    sponsorStudentsPath,
    isLoadingSponsorStudentsPath,
    sponsorBeneficiaries,
    isLoadingSponsorBeneficiaries,
    isChartLoad,
    adminDashboardChart,
    admittedYear,
    adminDashboardRegUsersChart,

    //Leaderboard
    leaderboardLadder,
    isLeaderboardLadderLoading,
    weeklyTrivia,
  };
};

export default useDashboard;
