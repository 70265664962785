import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Link } from "react-router-dom";
import Gemstone from "../../assets/images/Gemstone.svg";
import clapperboard from "../../assets/images/clapperboard.svg";
import useTest from "../../hooks/useTest";
import { VideoDialog } from "./VideoAdModal";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& > .MuiBackdrop-root": {
        backdropFilter: "blur(4px)",
    },
    "& .MuiDialogTitle-root": {
        backgroundColor: "#FFD14A",
        height: "1px",
        borderRadius: "16.9px 16.9px 0px 0px",
        color: "#fff",
    },
    "& .MuiDialogContent-root": {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: 15,
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
        position: "relative",
    },
}));

export const AdDialog = ({ open, setOpen }) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const { currentQuestion } = useTest()
    const handleOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleCloseAd = () => {
        setOpenDialog(false)
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleCloseDialog}
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{ sx: { borderRadius: "18px" } }}
                maxWidth={"xl"}
            >
                <DialogTitle
                    sx={{ m: 0, p: 1, textAlign: "center" }}
                    id="customized-dialog-title"
                ></DialogTitle>
                <DialogContent>
                    <Typography variant="h4" fontWeight={900} marginTop={5}>
                        Subscribe
                    </Typography>
                    <Typography variant="h6" color="#777">
                        Unlock a world of enhanced learning and get exclusive 
                        <br className="hidden md:block" />
                        access to more tests and a richer educational experience
                    </Typography>
                    <Box
                        sx={{
                            webkitUserDrag: "none",
                            userSelect: "none",
                            khtmlUserDrag: "none",
                            mozUserDrag: "none",
                            oUserDrag: "none",
                            userDrag: "none",
                        }}
                    >
                        <img
                            src={Gemstone}
                            alt="Premium package"
                            draggable="false"
                            style={{
                                webkitUserDrag: "none",
                                userSelect: "none",
                                khtmlUserDrag: "none",
                                mozUserDrag: "none",
                                oUserDrag: "none",
                                userDrag: "none",
                            }}
                        />
                    </Box>
                    <Box>
                        <Button
                            color="brandPrimary"
                            variant="outlined"
                            hidden={currentQuestion !== 4}
                            // disableRipple
                            // disableFocusRipple
                            // disableTouchRipple
                            sx={{
                                textTransform: "inherit",
                                fontWeight: 800,
                                borderRadius: "30px",
                                display: currentQuestion === 5 ? "flex" : "none",
                                padding: "10px 20px",
                                cursor: "pointer",
                            }}
                            onClick={() => setOpenDialog(true)}
                            startIcon={<img src={clapperboard} alt="Ads Icon" />}
                        >
                            Watch a video to get more questions
                        </Button>
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#b4b4b4",
                            m: 3,
                            p: 0,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <Box
                        gap={{ xs: 2, md: 4 }}
                        p={{ xs: 1, md: 3 }}
                        bgcolor="#1A1C52"
                        borderRadius="10px"
                        flexDirection={{ xs: "column", md: "row" }}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography color="white" textAlign={{ xs: "center", md: "start" }}>
                            Switch to a premium plan now and enjoy a discount of up to <br className="hidden md:block" />{" "}
                            10% on our highly favored annual subscription.
                        </Typography>
                        <Link to="/pricing">
                            <Button
                                sx={{
                                    color: "#fff",
                                    padding: "10px",
                                    textTransform: "inherit",
                                    bgcolor: "#5458C7",
                                    "&:hover": { bgcolor: "#5458C7" },
                                }}
                            >
                                Subscribe
                            </Button>
                        </Link>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
            <VideoDialog open={openDialog} closeParent={setOpen} setOpen={handleCloseAd} />
        </div>
    );
}
