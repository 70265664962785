import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import { Link } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import PremiumDialog from "../Modal/PremiumDialog";
import useAdminUser from "../../hooks/useAdminUser";
// import ConfirmPayment from "../dashboards/school/payment/paymentDetails/ConfirmPaymentDialog";
import { formatCurrency } from "../../helpers/helper";
import { fetchPaymentSummary } from "../../redux/subscriptions";
import ConfirmPayment from "./ConfirmPaymentDialog";
import { Seerbitlogo, UPlogo } from "../../assets/images";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#b4b4b4" : "#2D3843",
    fontSize: 16,
    // width: 'auto',
    // marginBottom: "20px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //   borderColor: theme.palette.primary.main,
    },
    cursor: "auto",
  },
}));

export default function PaymentDetails({
  interswitchPay,
  input,
  setInput,
  selectedSubscription,
}) {
  const user = useUser();
  const dispatch = useDispatch();

  const adminUser = useAdminUser();
  const userInfo = user.token ? user?.userInfo : adminUser?.userInfo?.data;
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);

  React.useEffect(() => {
    setInput({ ...input, amount: parseFloat(selectedSubscription?.Amount) })
  }, [])

  const handleCurrencyChange = (value) => {
    if (value === "840") {
      setInput({ ...input, currencyValue: value, amount: 10, currency: "USD" });
    } else {
      setInput({
        ...input,
        currencyValue: value,
        amount: 10000,
        currency: "NGN",
      });
    }
  };

  const handlePaymentGatewayChange = (value) => {
    setInput({
      ...input,
      paymentGateway: value
    })
  }

  const [open, setOpen] = React.useState(false);

  const createData = (name, protein) => {
    return { name, protein };
  };

  // const rows = [
  //   createData("Subtotal:", input.amount),
  //   createData("Platform fee:", input.fee),
  //   createData("Total Amount:", (input.currency + " " +(parseFloat(input.amount) + parseFloat(input.fee)).toFixed(2))),
  // ];
  const categoryID = selectedSubscription?.SubscriptionModelId;

  const handleProceedClick = () => {
    setOpenConfirmationDialog(true);
    dispatch(fetchPaymentSummary(categoryID, 1));
  };

  const handleConfirmProceed = () => {
    setOpenConfirmationDialog(false);
    interswitchPay();
  };

  const handleCancelProceed = () => {
    setOpenConfirmationDialog(false);
  };

  const rows = [
    createData("Subtotal:", formatCurrency(selectedSubscription?.Amount)),
    // createData("Platform fee:", "₦422"),
    // createData(
    //   "Total Amount:",
    //   formatCurrency(selectedSubscription?.amount + 422)
    // ),
  ];

  return (
    <Box
      component="form"
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        /*     display: 'grid',
          gridTemplateColumns: { sm: '1fr 1fr' },
        gap: 2, */
      }}
    >
      <FormControl variant="standard" fullWidth>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{ fontWeight: 800, color: "#313131" }}
        >
          Full Name
        </InputLabel>
        <BootstrapInput
          defaultValue={userInfo.firstName + " " + userInfo.surname}
          id="bootstrap-input"
          readOnly
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{ fontWeight: 800, color: "#313131" }}
        >
          Email Address
        </InputLabel>
        <BootstrapInput
          defaultValue={userInfo.email}
          id="bootstrap-input"
          readOnly
        />
      </FormControl>
      <div className="mt-4 grid grid-cols-2 gap-2 place-items-center">

        <div className={`py-4 px-3 col-span-1 w-full flex items-center justify-between rounded-md cursor-pointer border ${input.paymentGateway == 2 ? 'border-[#40499D]' : 'border-[#CBD5E1]'}`} onClick={() => handlePaymentGatewayChange(2)}>
          <div className="flex space-x-2 items-center">
            <img src={Seerbitlogo} alt="Seerbit logo" />
            <span>Seerbit</span>
          </div>
          {
            input.paymentGateway == 2 ? (
              <CheckCircleIcon style={{ color: "#40499D" }} />
            ) : (
              <RadioButtonUncheckedIcon style={{ color: "#40499D" }} />
            )
          }

        </div>
        <div className={`py-4 px-3 col-span-1 w-full flex items-center justify-between rounded-md cursor-pointer border ${input.paymentGateway == 1 ? 'border-[#40499D]' : 'border-[#CBD5E1]'} `} onClick={() => handlePaymentGatewayChange(1)}>
          <div className="flex space-x-2 items-center">
            <img src={UPlogo} alt="Unified Payments logo" />
            <span>Unified Payments</span>
          </div>
          {
            input.paymentGateway == 1 ? (
              <CheckCircleIcon style={{ color: "#40499D" }} />
            ) : (
              <RadioButtonUncheckedIcon style={{ color: "#40499D" }} />
            )
          }
        </div>
      </div>

      <Box>
        <Box>
          {/* <FormControl variant="standard" fullWidth>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ fontWeight: 800, color: "#313131" }}
            >
              Selected Plan
            </InputLabel>
            <BootstrapInputAdornment
              readOnly
              defaultValue="Essentials"
              id="bootstrap-input"
              endAdornment={
                <InputAdornment position="end">
                  <Link to="">
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "capitalize",
                        "&:hover": {
                          textDecoration: "underline",
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      Change Plan
                    </Button>
                  </Link>
                </InputAdornment>
              }
            />
          </FormControl> */}
        </Box>
      </Box>
      <FormControl fullWidth>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{ color: "#313131", fontWeight: 900 }}
                    align="right"
                  >
                    {row.protein}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
      <FormControl fullWidth>
        <Button
          onClick={handleProceedClick}
          sx={{
            borderRadius: "10px",
            p: 2,
            textTransform: "inherit",
          }}
          variant="contained"
          color="primary"
        >
          Proceed to payment
        </Button>
      </FormControl>
      <ConfirmPayment
        open={openConfirmationDialog}
        handleClose={handleCancelProceed}
        handleConfirmProceed={handleConfirmProceed}
        onClick={interswitchPay}
      />
    </Box>
  );
}
