import {
  ALL_NEW_SESSIONS,
  ALL_SESSIONS_FAIL,
  ALL_SESSIONS,
  UPDATE_SESSIONS,
  LOAD_SESSION,
} from "./types";

const initialState = {
  createSession: null,
  allSession: [],
  loading: false,
  sessionUpdate: [],
  loadingSession: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_NEW_SESSIONS: {
      return {
        ...state,
        loading: false,
        createSession: action.payload.data,
      };
    }
    case LOAD_SESSION: {
      return {
        ...state,

        loadingSession: action.payload,
      };
    }
    case ALL_SESSIONS: {
      return {
        ...state,
        loading: false,
        allSession: action.payload.data,
      };
    }
    case ALL_SESSIONS_FAIL: {
      return {
        ...state,
        loading: true,
        allSession: null,
      };
    }
    case UPDATE_SESSIONS: {
      return {
        ...state,
        loading: true,
        sessionUpdate: action.payload.data,
      };
    }
    default:
      return state;
  }
};
export default sessionReducer;
