import { useState, useEffect, useRef } from "react";

const useFitText = (maxFontSize, minFontSize, containerRef, textRef) => {
  const [fontSize, setFontSize] = useState(maxFontSize);
  const resizeObserver = useRef(null);

  useEffect(() => {
    const adjustFontSize = () => {
      if (containerRef.current && textRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const textWidth = textRef.current.scrollWidth;

        if (textWidth > containerWidth && fontSize > minFontSize) {
          setFontSize((prev) => Math.max(prev - 1, minFontSize));
        } else if (textWidth < containerWidth && fontSize < maxFontSize) {
          setFontSize((prev) => Math.min(prev + 1, maxFontSize));
        }
      }
    };

    adjustFontSize();

    // Initialize ResizeObserver if available
    if (typeof ResizeObserver !== "undefined") {
      resizeObserver.current = new ResizeObserver(adjustFontSize);
      if (containerRef.current) {
        resizeObserver.current.observe(containerRef.current);
      }

      // Cleanup on unmount
      return () => {
        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }
      };
    }
  }, [fontSize, containerRef, textRef, maxFontSize, minFontSize]);

  return fontSize;
};

export default useFitText;
