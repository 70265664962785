import {
  GET_USER_METRICS,
  GET_USER_METRICS_SUCCESS,
  GET_USER_METRICS_FAIL,
  GET_USER_METRICS_BY_PROFILEID,
  GET_USER_METRICS_BY_PROFILEID_FAIL,
  GET_USER_SUBSCRIPTIONS,
  GET_USER_SUBSCRIPTIONS_FAIL,
} from "./types";

const initialState = {
  loading: false,
  userMetricsData: null,
  userMetricById: null,
  subscription: null,
};

const userMetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_METRICS:
      return { ...state, loading: true };
    case GET_USER_METRICS_SUCCESS:
      return {
        ...state,
        loading: false,
        userMetricsData: action.payload.data,
        error: null,
      };
    case GET_USER_METRICS_FAIL:
      return {
        ...state,
        loading: false,
        userMetricsData: null,
        error: action.payload,
      };
    case GET_USER_METRICS_BY_PROFILEID:
      return {
        ...state,
        loading: false,
        userMetricById: action.payload.data,
        error: null,
      };
    case GET_USER_METRICS_BY_PROFILEID_FAIL:
      return {
        ...state,
        loading: false,
        userMetricById: null,
        error: null,
      };

    case GET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        loading: false,
        subscription: action.payload.data,
        error: null,
      };

    case GET_USER_METRICS_BY_PROFILEID_FAIL:
      return {
        ...state,
        loading: false,
        subscription: null,
        error: null,
      };
    default:
      return state;
  }
};
export default userMetricsReducer;
