import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, CircularProgress } from "@mui/material";

const ProfileUpload = ({selectedImage, isLoading, handleImageUpload}) => {
  return (
    <Paper
      elevation={3}
      style={{ display: "flex", alignItems: "center", gap: 10 }}
    >
      <div>
        {isLoading ? (
          <CircularProgress style={{ color: "grey" }} />
        ) : selectedImage ? (
          <img
            src={selectedImage}
            alt="Uploaded"
            style={{
              maxWidth: "100px",
              height: "auto",
              borderRadius: "50%",
            }}
          />
        ) : (
          <div
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: "grey",
              borderRadius: "50%",
              margin: "0 auto",
            }}
          ></div>
        )}
      </div>
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
      <label htmlFor="photo-upload">
        <Button
          sx={{ textTransform: "inherit" }}
          variant="outlined"
          component="span"
          fullWidth
        >
          Upload Picture
        </Button>
      </label>
    </Paper>
  );
};

export default ProfileUpload;
