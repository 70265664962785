import { Button, CircularProgress } from "@mui/material";
import React from "react";

const CustomButton = (props) => {
  return (
    <Button
      {...props}
      disableElevation
      variant={props?.variant ?? "contained"}
      sx={{
        ...props.style,
        textTransform: "capitalize",
        borderRadius: "5px",
        borderColor: props?.bordercolor,
        color: props?.color,
        "&:hover": {
          backgroundColor: props?.hovercolor ?? (props?.backgroundColor || props?.color),
        },
        ...(props?.backgroundcolor && {
          backgroundColor: props?.backgroundcolor,
        }),
        ...(props?.boxshadow && {
          boxShadow: props?.boxshadow,
        }),
        ...(props?.variant === "outlined" && {
          borderWidth: "1.5px",
          ":hover": {
            borderWidth: "1.5px",
          },
        }),
      }}
    >
      {props?.loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <span>{props?.text}</span>
      )}
    </Button>
  );
};

export default CustomButton;
