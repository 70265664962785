import React from 'react'
import DataUsageIcon from '@mui/icons-material/DataUsage';


const TransparentButtton = ({ children }) => <>{children}</>;

TransparentButtton.Base = ({
  title,
  type = "button",
  style,
  className,
  disabled,
  loading,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={className+" e-study-normal-text-three  py-2 px-4 flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap"}>
      {title ?? "Title"}{" "}
      {loading && <DataUsageIcon size={24} className='animate-spin ml-4' />}
    </button>
  );
}

export default TransparentButtton