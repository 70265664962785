import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import {
  friendsorfamily,
  schoolbus,
  websearchonb,
  socialmedia,
  blogarticle,
  othersonb,
} from "../../../assest/images/index";
import { useDispatch } from "react-redux";

import useUser from "../../../hooks/useUser";
import { getAboutUs } from "../../../redux/auth/authActions";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const ColoredPaper = styled(Paper)(({ theme, selected }) => ({
  backgroundColor: selected ? "#EAECFF" : theme.palette.background.default,
  borderRadius: "20px",
  border: "1px solid #E5E5E5",
  boxShadow: "0px 4px 4px 0px #E5E5E5",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function SchoolAboutUs(props) {
  const { input, setInput } = props;
  const theme = useTheme();

  const desiredgradegrid = {
    marginTop: "7rem",
    marginBottom: "2rem",

    [theme.breakpoints.up("md")]: {
      marginTop: "5rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "3rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: "1rem",
      marginBottom: "0rem",
    },
  };

  const desiredgradetext = {
    fontSize: "30px",
    flexGrow: 0,
    paddingRight: "30px",

    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      flexGrow: 0,
      paddingRight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  };

  const paperCardGrid = {
    maxWidth: "60%",
    mx: "auto",

    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
      mx: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      mx: "auto",
    },
  };

  const paperCardBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "2rem",
    "& > :not(style)": {
      m: 2,
      width: 200,
      height: 200,
    },
  };

  const [selectedValue, setSelectedValue] = React.useState("");

  const dispatch = useDispatch();
  const user = useUser();
  const { aboutUs, isLoading } = user;

  useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  const handleChange = (event, param1) => {
    setSelectedValue(event.target.value);
    setInput({ ...input, awarenessId: param1, awareness: event.target.value });
  };

  const picturess = [
    websearchonb,
    schoolbus,
    othersonb,
    socialmedia,
    blogarticle,
    friendsorfamily,
  ];

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        sx={desiredgradegrid}
      >
        <Grid item xs={12}>
          <Typography variant="h6" sx={desiredgradetext}>
            How did you hear about us?
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={paperCardGrid}>
          {aboutUs
            // .filter((item, i) => i < 3)
            .map((item, i) => (
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ColoredPaper
                  elevation={3}
                  selected={selectedValue === item.AwarenessName}
                  sx={{ mr: 2, p: 2, borderRadius: 6 }}
                >
                  {" "}
                  <Radio
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    disableRipple
                    checked={selectedValue === item.AwarenessName}
                    onChange={(event) => handleChange(event, item.AwarenessId)}
                    value={item.AwarenessName}
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    {...props}
                  />
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid item>
                      {item.AwarenessName === "School" ? (
                        <img
                          src={picturess[i]}
                          alt={`about${item.AwarenessName}`}
                          width={150}
                          height={90}
                        />
                      ) : (
                        <img
                          src={picturess[i]}
                          alt={`about${item.AwarenessName}`}
                          width={90}
                          // height={80}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">{item.AwarenessName}</Typography>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                </ColoredPaper>
              </Grid>
            ))}
        </Grid>
      </Box>
    </div>
  );
}
