import React, { useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import IconRenderer from './IconRenderer';

const IconDropdown = ({pageIcon, setPageIcon}) => {
  const [searchDropDown, setSearchDropDown] = useState(false);

  const handleIconChange = (iconName) => {
    setPageIcon(iconName);
  };

  const icons = [
    {
      iconName: "AdminDashboardIcon",
    },
    {
      iconName: "AuditTailIcon",
    },
    {
      iconName: "ContentUploadIcon",
    },
    {
      iconName: "PermissionIcon",
    },
    {
      iconName: "TransactionIcon",
    },
    {
      iconName: "UserMetricsIcon",
    },
    {
      iconName: "NairaIcon",
    },
    {
      iconName: "ReceiptIcon",
    },
    {
      iconName: "AuditIcon",
    },
  ]

  return (
    <div className="dropdown">
      {
        !searchDropDown ?
          <button className="flex items-center rounded p-2 border justify-between relative w-full" onClick={() => setSearchDropDown(!searchDropDown)}>
            <div className="flex gap-3 e-study-grey-dark">
            {pageIcon ?  <IconRenderer iconName={pageIcon} /> : "Select Icon"}
            </div><ArrowDropDown size={'20px'} className="cursor-pointer" color="#000000" />
          </button>
          :
          <div>
            <div
              className="flex flex-col gap-2 p-2 top-2 e-study-grey-dark border relative overflow-auto" style={{ height: '100px' }}
            >
              {icons.map((field, index) =>
                <div className='flex gap-3 justify-center items-center cursor-pointer border-b border-gray-100' key={index} onClick={() => { handleIconChange(field.iconName); setSearchDropDown(!searchDropDown); }}>
                  <IconRenderer iconName={field.iconName}  />
                </div>)}
            </div>
          </div>
      }
    </div>
  );
};

export default IconDropdown;
