import React, { useRef } from 'react';
import rightanswer from "../../../assets/audios/rightanswer.mp3";

function CorrectSound({ audioRef }) {
  return (
    <div>
      {/* Hide the UI controls */}
      <audio ref={audioRef} controls={false}>
        <source src={rightanswer} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

    </div>
  );
}

export default CorrectSound;
