import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button, Link, PaginationItem } from "@mui/material";
import { useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { removeEmptyOptions } from "../../../../helpers/helper";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useLearning from "../../../../hooks/useLearning";

import { useNotifications } from "../../../../components/notifications/notifications";
import { fetchQuestionById } from "../../../../redux/learning";

import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import KatexRenderer from "../../../../components/KatexRenderer";
import { RTEViewer } from "../../../../components/RTEViewer";

export default function ExamChoices(props) {
  const { examQuestion } = useLearning();
  const { examCount } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [filteredChoices, setFilteredChoices] = useState([]);

  useEffect(() => {
    const decodeHtml = (html) => {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    };

    const infoHtml = decodeHtml(examQuestion);
    const blocksFromHTML = convertFromHTML(infoHtml);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(examCount?.length > 0 ? EditorState.createWithContent(state) : EditorState.createEmpty());
    setFilteredChoices(removeEmptyOptions(examQuestion?.choices));
  }, [examQuestion]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",

          "& > :not(style)": {
            // m: 1,
            // width: 900,
            // height: 200,
          },
        }}
      >
        <Paper elevation={0} sx={{ mr: 2, px: 2, borderRadius: 3 }}>
          <ol style={{ listStyle: "upper-alpha", marginTop: 4 }}>
            {filteredChoices?.map((subject, index) => (
              <li key={index} className="pl-3 ml-6">
                {/* {window.HTMLReactParser(subject.option ? subject.option : "")} */}
                <RTEViewer value={subject.option ? subject.option : ""} style={{ height: "fit-content", overflowY: "auto" }} />
              </li>
            ))}
          </ol>
        </Paper>
      </Box>

      {/* <ToastState /> */}
    </>
  );
}
