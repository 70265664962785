import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDispatch } from "react-redux";
import useDashboard from "../../../hooks/useDashboard";

const SubjectsChart = () => {
  const { dashboardStat, isLoading } = useDashboard();

  const data1 = [
    { label: "Subjects Completed", value: dashboardStat?.noOfCoreSubjectStudiesCompleted, color: "#FFD353" },
    { label: "Subjects Not Completed", value: dashboardStat?.numberOfCoreSubjects - dashboardStat?.noOfCoreSubjectStudiesCompleted , color: "#F0F2F5" },
  ];
  
  const data2 = [
    { label: "Subjects Started", value: dashboardStat?.noOfCoreSubjectStudiesInprogress, color: "#40499D" },
    { label: "Subjects Not Started", value: dashboardStat?.numberOfCoreSubjects - dashboardStat?.noOfCoreSubjectStudiesInprogress , color: "#F0F2F5" },
  ];

  

  return (
    <PieChart
      series={[
        {
          innerRadius: 25,
          outerRadius: 15,
          data: data1,
        },
        {
          innerRadius: 35,
          outerRadius: 50,
          data: data2,
          cornerRadius: 25,
        },
      ]}
      width={200}
      height={100}
      legend={{ hidden: true }}
    />
  );
};

export { SubjectsChart };
