import * as dashboardActions from "./types";

const InitialState = {
  coreSubjects: [],
  theCoreSubjects: [],
  coreSubjectsLoading: false,
  dashboardTestStat: [],
  dashboardStudyIPStat: [],
  dashboardStudyTime: {},
  loadingOn: false,
  loadingg: false,
  isLoadingg: false,
  coreLoaded: false,
  dashboardStat: {},
  adminDashboardStat: {},
  isLoading: false,
  isLoad: false,
  isChartLoad: false,
  adminDashboardChart: null,
  adminDashboardRegUser: null,
  isLoadingLearning: false,
  dashboardStudentLearning: [],
  dashboardTestDetails: [],
  isLoadingTest: false,
  schoolDashboardStat: {},
  isLoadingSchStat: false,
  topPerformingStudents: [],
  isLoadingTopStudents: false,
  studentsPath: [],
  isLoadingStudentsPath: false,
  schoolStudyTime: [],
  isLoadingSponsorStudentStudyTime: false,
  sponsorStudentStudyTime: [],
  isLoadingSchStudyTime: false,
  studentsSubjects: [],
  isLoadingStudentsSubjects: false,
  amountDetails: [],
  isLoadingAmountDetails: false,
  sponsorDashboardStat: {},
  isLoadingSponsorStat: false,
  sponsorStudentsPath: [],
  isLoadingSponsorStudentsPath: false,
  sponsorBeneficiaries: [],
  isLoadingSponsorBeneficiaries: false,
  admittedYear: {},
  leaderboardLadder: [],
  isLeaderboardLadderLoading: false,
  weeklyTrivia: [],
};

export function dashboardReducer(state = InitialState, action) {
  switch (action.type) {
    case dashboardActions.ADD_SUBJECT_BY_PROFILE_ID:
      return {
        ...state,
        coreSubjects: [],
        coreLoaded: false,
      };
    case dashboardActions.ADD_SUBJECT_BY_PROFILE_ID_SUCCESS:
      return {
        ...state,
        coreSubjects: action.payload,
        coreLoaded: true,
      };
    case dashboardActions.ADD_SUBJECT_BY_PROFILE_ID_ERROR:
      return {
        ...state,
        coreSubjects: action.payload,
        coreLoaded: false,
      };
    case dashboardActions.UPDATE_SUBJECT_BY_PROFILE_ID:
      return {
        ...state,
        theCoreSubjects: [],
        coreSubjectsLoading: true,
      };
    case dashboardActions.UPDATE_SUBJECT_BY_PROFILE_ID_SUCCESS:
      return {
        ...state,
        theCoreSubjects: action.payload,
        coreSubjectsLoading: false,
      };
    case dashboardActions.UPDATE_SUBJECT_BY_PROFILE_ID_ERROR:
      return {
        ...state,
        theCoreSubjects: action.payload,
        coreSubjectsLoading: false,
      };
    case dashboardActions.FETCH_STUDENT_DASHBOARD_STAT:
      return {
        ...state,
        dashboardStat: {},
        isLoading: true,
      };
    case dashboardActions.FETCH_STUDENT_DASHBOARD_STAT_SUCCESS:
      return {
        ...state,
        dashboardStat: action.payload,
        isLoading: false,
      };
    case dashboardActions.FETCH_STUDENT_DASHBOARD_STAT_ERROR:
      return {
        ...state,
        dashboardStat: action.payload,
        isLoading: false,
      };
    case dashboardActions.FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT:
      return {
        ...state,
        dashboardTestStat: [],
        isLoadingg: true,
      };
    case dashboardActions.FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_SUCCESS:
      return {
        ...state,
        dashboardTestStat: action.payload,
        isLoadingg: false,
      };
    case dashboardActions.FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_ERROR:
      return {
        ...state,
        dashboardTestStat: action.payload,
        isLoadingg: false,
      };
    case dashboardActions.FETCH_STUDIES_IN_PROGRESS_STAT:
      return {
        ...state,
        dashboardStudyIPStat: [],
        loadingg: true,
      };
    case dashboardActions.FETCH_STUDIES_IN_PROGRESS_STAT_SUCCESS:
      return {
        ...state,
        dashboardStudyIPStat: action.payload,
        loadingg: false,
      };
    case dashboardActions.FETCH_STUDIES_IN_PROGRESS_STAT_ERROR:
      return {
        ...state,
        dashboardStudyIPStat: action.payload,
        loadingg: false,
      };
    case dashboardActions.FETCH_STUDY_TIME:
      return {
        ...state,
        dashboardStudyTime: {},
        loadingOn: true,
      };
    case dashboardActions.FETCH_STUDY_TIME_SUCCESS:
      return {
        ...state,
        dashboardStudyTime: action.payload,
        loadingOn: false,
      };
    case dashboardActions.FETCH_STUDY_TIME_ERROR:
      return {
        ...state,
        dashboardStudyTime: action.payload,
        loadingOn: false,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_STAT:
      return {
        ...state,
        adminDashboardStat: {},
        isLoad: true,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_STAT_SUCCESS:
      return {
        ...state,
        adminDashboardStat: action.payload,
        isLoad: false,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_STAT_ERROR:
      return {
        ...state,
        adminDashboardStat: action.payload,
        isLoad: false,
      };
    case dashboardActions.ALL_AMITTED_YEAR:
      return {
        ...state,
        admittedYear: {},
        isChartLoad: true,
      };
    case dashboardActions.ALL_AMITTED_YEAR_SUCCESS:
      return {
        ...state,
        admittedYear: action.payload,
        isChartLoad: false,
      };
    case dashboardActions.ALL_AMITTED_YEAR_FAIL:
      return {
        ...state,
        admittedYear: {},
        isChartLoad: false,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_CHART:
      return {
        ...state,
        adminDashboardChart: {},
        isChartLoad: true,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_CHART_SUCCESS:
      return {
        ...state,
        adminDashboardChart: action.payload,
        isChartLoad: false,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_CHART_ERROR:
      return {
        ...state,
        adminDashboardChart: {},
        isChartLoad: false,
      };

    case dashboardActions.FETCH_ADMIN_DASHBOARD_REG_USER_CHART:
      return {
        ...state,
        adminDashboardRegUsersChart: action.payload,
        isChartLoad: true,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_REG_USER_CHART_SUCCESS:
      return {
        ...state,
        adminDashboardRegUsersChart: action.payload,
        isChartLoad: false,
      };
    case dashboardActions.FETCH_ADMIN_DASHBOARD_REG_USER_CHART_ERROR:
      return {
        ...state,
        adminDashboardRegUsersChart: null,
        isChartLoad: false,
      };

    case dashboardActions.FETCH_STUDENT_LEARNING:
      return {
        ...state,
        dashboardStudentLearning: [],
        isLoadingLearning: true,
      };
    case dashboardActions.FETCH_STUDENT_LEARNING_SUCCESS:
      return {
        ...state,
        dashboardStudentLearning: action.payload,
        isLoadingLearning: false,
      };
    case dashboardActions.FETCH_STUDENT_LEARNING_ERROR:
      return {
        ...state,
        dashboardStudentLearning: action.payload,
        isLoadingLearning: false,
      };
    case dashboardActions.FETCH_TEST_DETAILS:
      return {
        ...state,
        dashboardTestDetails: [],
        isLoadingTest: true,
      };
    case dashboardActions.FETCH_TEST_DETAILS_SUCCESS:
      return {
        ...state,
        dashboardTestDetails: action.payload,
        isLoadingTest: false,
      };
    case dashboardActions.FETCH_TEST_DETAILS_ERROR:
      return {
        ...state,
        dashboardTestDetails: [],
        isLoadingTest: false,
      };
    case dashboardActions.FETCH_SCHOOL_DASHBOARD_STAT:
      return {
        ...state,
        schoolDashboardStat: {},
        isLoadingSchStat: true,
      };
    case dashboardActions.FETCH_SCHOOL_DASHBOARD_STAT_SUCCESS:
      return {
        ...state,
        schoolDashboardStat: action.payload,
        isLoadingSchStat: false,
      };
    case dashboardActions.FETCH_SCHOOL_DASHBOARD_STAT_ERROR:
      return {
        ...state,
        schoolDashboardStat: action.payload,
        isLoadingSchStat: false,
      };
    case dashboardActions.FETCH_TOP_STUDENTS:
      return {
        ...state,
        topPerformingStudents: [],
        isLoadingTopStudents: true,
      };
    case dashboardActions.FETCH_TOP_STUDENTS_SUCCESS:
      return {
        ...state,
        topPerformingStudents: action.payload,
        isLoadingTopStudents: false,
      };
    case dashboardActions.FETCH_TOP_STUDENTS_ERROR:
      return {
        ...state,
        topPerformingStudents: action.payload,
        isLoadingTopStudents: false,
      };
    case dashboardActions.FETCH_STUDENTS_PATH:
      return {
        ...state,
        studentsPath: [],
        isLoadingStudentsPath: true,
      };
    case dashboardActions.FETCH_STUDENTS_PATH_SUCCESS:
      return {
        ...state,
        studentsPath: action.payload,
        isLoadingStudentsPath: false,
      };
    case dashboardActions.FETCH_STUDENTS_PATH_ERROR:
      return {
        ...state,
        studentsPath: action.payload,
        isLoadingStudentsPath: false,
      };
    case dashboardActions.FETCH_STUDENTS_SUBJECTS:
      return {
        ...state,
        studentsSubjects: [],
        isLoadingStudentsSubjects: true,
      };
    case dashboardActions.FETCH_STUDENTS_SUBJECTS_SUCCESS:
      return {
        ...state,
        studentsSubjects: action.payload.data,
        isLoadingStudentsSubjects: false,
      };
    case dashboardActions.FETCH_STUDENTS_SUBJECTS_ERROR:
      return {
        ...state,
        studentsSubjects: action.payload,
        isLoadingStudentsSubjects: false,
      };
    case dashboardActions.FETCH_SCHOOL_STUDY_TIME:
      return {
        ...state,
        schoolStudyTime: [],
        isLoadingSchStudyTime: true,
      };
    case dashboardActions.FETCH_SCHOOL_STUDY_TIME_SUCCESS:
      return {
        ...state,
        schoolStudyTime: action.payload,
        isLoadingSchStudyTime: false,
      };
    case dashboardActions.FETCH_SCHOOL_STUDY_TIME_ERROR:
      return {
        ...state,
        schoolStudyTime: action.payload,
        isLoadingSchStudyTime: false,
      };
    case dashboardActions.FETCH_SPONSOR_STUDENT_STUDY_TIME:
      return {
        ...state,
        sponsorStudentStudyTime: [],
        isLoadingSponsorStudentStudyTime: true,
      };
    case dashboardActions.FETCH_SPONSOR_STUDENT_STUDY_TIME_SUCCESS:
      return {
        ...state,
        sponsorStudentStudyTime: [],
        isLoadingSponsorStudentStudyTime: false,
      };
    case dashboardActions.FETCH_SPONSOR_STUDENT_STUDY_TIME_ERROR:
      return {
        ...state,
        sponsorStudentStudyTime: [],
        isLoadingSponsorStudentStudyTime: false,
      };
    case dashboardActions.FETCH_AMOUNT_DETAILS:
      return {
        ...state,
        amountDetails: [],
        isLoadingAmountDetails: true,
      };
    case dashboardActions.FETCH_AMOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        amountDetails: action.payload,
        isLoadingAmountDetails: false,
      };
    case dashboardActions.FETCH_AMOUNT_DETAILS_ERROR:
      return {
        ...state,
        amountDetails: action.payload,
        isLoadingAmountDetails: false,
      };
    case dashboardActions.FETCH_SPONSOR_DASHBOARD_STAT:
      return {
        ...state,
        sponsorDashboardStat: {},
        isLoadingSponsorStat: true,
      };
    case dashboardActions.FETCH_SPONSOR_DASHBOARD_STAT_SUCCESS:
      return {
        ...state,
        sponsorDashboardStat: action.payload,
        isLoadingSponsorStat: false,
      };
    case dashboardActions.FETCH_SPONSOR_DASHBOARD_STAT_ERROR:
      return {
        ...state,
        sponsorDashboardStat: action.payload,
        isLoadingSponsorStat: false,
      };
    case dashboardActions.FETCH_SPONSOR_STUDENTS_PATH:
      return {
        ...state,
        sponsorStudentsPath: [],
        isLoadingSponsorStudentsPath: true,
      };
    case dashboardActions.FETCH_SPONSOR_STUDENTS_PATH_SUCCESS:
      return {
        ...state,
        sponsorStudentsPath: action.payload,
        isLoadingSponsorStudentsPath: false,
      };
    case dashboardActions.FETCH_SPONSOR_STUDENTS_PATH_ERROR:
      return {
        ...state,
        sponsorStudentsPath: action.payload,
        isLoadingSponsorStudentsPath: false,
      };
    case dashboardActions.FETCH_SPONSOR_BENEFICIARIES:
      return {
        ...state,
        sponsorBeneficiaries: [],
        isLoadingSponsorBeneficiaries: true,
      };
    case dashboardActions.FETCH_SPONSOR_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        sponsorBeneficiaries: action.payload.data,
        isLoadingSponsorBeneficiaries: false,
      };
    case dashboardActions.FETCH_SPONSOR_BENEFICIARIES_ERROR:
      return {
        ...state,
        sponsorBeneficiaries: action.payload,
        isLoadingSponsorBeneficiaries: false,
      };

    //Leaderboard Reducer
    case dashboardActions.FETCH_LEADERBOARD_LADDER:
      return {
        ...state,
        leaderboardLadder: [],
        isLeaderboardLadderLoading: true,
      };
    case dashboardActions.FETCH_LEADERBOARD_LADDER_SUCCESS:
      return {
        ...state,
        leaderboardLadder: action.payload,
        isLeaderboardLadderLoading: false,
      };
    case dashboardActions.FETCH_LEADERBOARD_LADDER_ERROR:
      return {
        ...state,
        leaderboardLadder: action.payload,
        isLeaderboardLadderLoading: false,
      };
    case dashboardActions.GET_WEEKLY_TRIVIA:
      return {
        ...state,
        weeklyTrivia: action.payload,
      };
    default:
      return state;
  }
}
