export const CONFIRM_TRANSACTION_STATUS = "CONFIRM_TRANSACTION_STATUS";
export const CONFIRM_TRANSACTION_STATUS_SUCCESS = "CONFIRM_TRANSACTION_STATUS_SUCCESS";
export const CONFIRM_TRANSACTION_STATUS_ERROR = "CONFIRM_TRANSACTION_STATUS_ERROR";

export const INITIATE_TRANSACTION_START = "INITIATE_TRANSACTION_START";
export const INITIATE_TRANSACTION_SUCCESS = "INITIATE_TRANSACTION_SUCCESS";
export const INITIATE_TRANSACTION_FAILURE = "INITIATE_TRANSACTION_FAILURE";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const GET_TRANSACTION_BY_ID = "GET_TRANSACTION_BY_ID";
export const GET_TRANSACTION_BY_ID_SUCCESS = "GET_TRANSACTION_BY_ID_SUCCESS";
export const GET_TRANSACTION_BY_ID_FAILURE = "GET_TRANSACTION_BY_ID_FAILURE";
