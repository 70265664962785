import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useSettings from "../../../hooks/useSettings";
import { useDispatch } from "react-redux";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function SchoolAsynchronous({
  disabled,
  updateSchoolValue,
  schoolHandler,
  schoolValues,
  schOnboardedOptions = false,
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { waecSchoolsData, waecSchoolsLoading } =
    useSettings();

  // This effect populates the waec school select options
  useEffect(() => {
    const createSchoolsSelect = () => {
      let schoolsArray = [];
      let schoolsOnboardedArray = [];

      const filteredWaecSchools = waecSchoolsData?.filter(
        (school) => school.IsSchOnboarded
      );

      filteredWaecSchools?.map((school) => {
        let option = { value: school?.WAECSchoolID, label: school?.SchoolName };
        return schoolsOnboardedArray?.push(option);
      });

      waecSchoolsData?.map((school) => {
        let option = { value: school?.WAECSchoolID, label: school?.SchoolName };
        return schoolsArray?.push(option);
      });
      const updatedSchoolSelect = schOnboardedOptions
        ? schoolsOnboardedArray
        : schoolsArray;
      setOptions(updatedSchoolSelect);
    };
    waecSchoolsData?.length !== 0 && createSchoolsSelect();
  }, [waecSchoolsData, schOnboardedOptions]);



  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <Autocomplete
        onKeyDown={handleKeyDown}
        autoHighlight
        id="asynchronous-demo"
        readOnly={disabled}
        open={open}
        disabled={waecSchoolsLoading}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={schoolValues || ""}
        onChange={(event, value) => {
          if (value) {
            schoolHandler(event, value);
            updateSchoolValue(value);
          }
          else {
            updateSchoolValue({ label: "", value: 0 })
          }
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option?.label || ""}
        options={options}
        loading={waecSchoolsLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="School"
            InputProps={{
              ...params.InputProps,
              endAdornment: !disabled ? (
                <React.Fragment>
                  {waecSchoolsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ) : (
                ""
              ),
            }}
          />
        )}
      />
    </>
  );
}
