import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import React from "react";
import featureImg from "../../assets/images/featuresImg.svg";
import book from "../../assets/images/books.svg";
import A from "../../assets/images/A+.png";
import chart from "../../assets/images/barChart.svg";
import RunningCard from "../../assets/images/runningCard.svg";
import { CustomImage } from "./CTAJumbotron";
import { FeatureCard } from "./FeatureCard";
import feature1 from "../../assets/images/feature1.png";
import feature2 from "../../assets/images/feature2.png";
import feature3 from "../../assets/images/feature3.png";
import { ArrowIcon } from "../../assets/icons/Icons";
import useResponsive from "../../hooks/useMediaQuery";
import "../../pages/Home.css";

export const HackBreak = styled("br")``;

const features = [
    // {
    //     image: feature1,
    //     title: "e-Study for Parents",
    //     description: "Stay involved in your child's education journey. e-Study provides insights into your child's progress, helping you support their academic success."
    // },
    {
        image: feature3,
        title: "eStudy for Schools",
        description: "Empower your students with a powerful examination preparation tool. eStudy offers schools a comprehensive solution for effective and engaging learning."
    },
    {
        image: feature2,
        title: "eStudy for Students",
        description: "Prepare for exams with interactive past questions, personalized study plans, and real-time performance analytics. Customize your learning to succeed in every subject."
    },
    {
        image: feature1,
        title: "eStudy for Sponsors",
        description: "Support student's academic success with an examination tool that offers comprehensive resource directly from WAEC."
    },
]

const Features = () => {
  const isMobile = useResponsive("down", "md");

  return (
    <Box
      className="landingPageFont"
      component={"div"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      textAlign={"center"}
      bgcolor={"#fff"}
      padding={isMobile ? "60px 20px" : "60px 40px 90px 40px"}
      justifyContent={"center"}
      boxSizing={"border-box"}
      width={"100%"}
    >
      <Stack gap={5}>
        <Stack
          gap={3}
          textAlign={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            fontFamily={"Montserrat"}
            variant="h5"
            fontWeight={"bold"}
            fontSize={isMobile ? "28px" : "40px"}
          >
            {" "}
            eStudy for everyone 
          </Typography>
          <Typography
            fontFamily={"Montserrat"}
            variant="body1"
            maxWidth={"580px"}
          >
            {" "}
            eStudy offers three core modules, each specially customized with
            intuitiveness and ease of use.
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          gap={3}
          flexWrap={"wrap"}
          flexShrink={"inherit"}
        >
          {features?.map((item) => (
            <Stack
              textAlign={"left"}
              border={
                item?.title === "eStudy for Students" && "1px solid #40499D"
              }
              gap={3}
              item={item?.title}
              sx={{
                bgcolor:
                  item?.title === "eStudy for Students" ? "#EAECFF" : "#FAFAFA",
                height: "500px",
                width: isMobile ? "100%" : "378px",
                padding: "37px 25px",
                borderRadius: "30px",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url('${item?.image}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  ...(isMobile && { backgroundPosition: "center" }),
                }}
                borderRadius={"20px"}
                width={isMobile ? "100%" : "328px"}
                height={"218px"}
              ></Box>
              <Typography
                fontFamily={"Montserrat"}
                fontSize={"20px"}
                fontWeight={"bold"}
                textAlign={"left"}
              >
                {item?.title}
              </Typography>
              <Typography
                fontFamily={"Montserrat"}
                color={"#4B5563"}
                fontSize={"16px"}
              >
                {item?.description}
              </Typography>
              {/* <Button disableRipple disableFocusRipple disableTouchRipple aria-disabled sx={{ '&:hover': { bgcolor: "transparent", color: "#40499d", fontSize: '16px' }, transition: '0.24s all ease-in-out', justifyContent: "start", textTransform: 'capitalize', color: item?.title === "eStudy for Students" ? "40499d" : '#30312C', fontWeight: 700, width: "160px" }} endIcon={<ArrowIcon />}>Learn More</Button> */}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Features;
