import React, { useEffect, useState } from "react";
import { Avatar, Badge, Box, IconButton, Paper, styled } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ImageUploadDialog from "./ImageUpload/ImageUploadDialog";
import avatar1 from "../../assets/images/avatar1.png";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#78BF39",
    color: "#78BF39",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid white",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
function ProfileImageUpload({
  handleChange,
  value,
  activeId,
  isPremium,
  open,
  image,
  formValues,
  handleClickOpen,
  hovered,
  handleClose,
  handleHover,
  handleUnhover,
  updatedImgToSend,
  handleAvatarClick,
  handleUpdateProfileImage,
  isLoading,
  handleImageUpload,
  selectedImage
}) {
  const [passportImage, setPassportImage] = useState("");

  useEffect(() => {
    if (formValues?.passport !== "") {
      setPassportImage(formValues?.passport);
    }
  }, [formValues?.passport, passportImage]);

  return (
    <Paper component="label" htmlFor="image-upload">
      <StyledBadge
        //   overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
        sx={{
          width: 80,
          height: 80,
          borderRadius: "50%",
          backgroundColor: "#007bff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          position: "relative",
          overflow: "hidden",
          "&:hover .hover-overlay": {
            opacity: 0.7,
          },
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
        onClick={handleClickOpen}
      >
        <Avatar
          sx={{
            cursor: "pointer",
            height: "100%",
            width: "100%",
            bgcolor: "#40499D",
          }}
        />
        <Box
          className="hover-overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#000",
            opacity: hovered ? 0.7 : 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "opacity 0.3s ease",
            flexDirection: "column",
          }}
        >
          <CameraAltIcon fontSize="large" sx={{ color: "#fff" }} />
          <Box sx={{ color: "#fff", textAlign: "center", fontSize: "10px" }}>
            Upload Photo
          </Box>
        </Box>
        {passportImage !== "" && (
          <img
            id="image-upload"
            src={passportImage}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
              display: "block",
            }}
          />
        )}
      </StyledBadge>
      <ImageUploadDialog
        value={value}
        handleChange={handleChange}
        updatedImgToSend={updatedImgToSend}
        handleAvatarClick={handleAvatarClick}
        open={open}
        onClose={handleClose}
        formValues={formValues}
        isPremium={isPremium}
        activeId={activeId}
        handleUpdateProfileImage={handleUpdateProfileImage}
        isLoading={isLoading}
        handleImageUpload={handleImageUpload}
        selectedImage={selectedImage}
      />
    </Paper>
  );
}

export default ProfileImageUpload;
