import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as Ellipse } from "../../assets/images/ellipse1.svg";
import premiumbg from "../../assets/images/premiumbg.png";
import packageBox from "../../assets/images/packageBox.png";
import { fontWeight } from "@mui/system";

const SuperSchoolBox = ({title, text}) => {
  const theme = useTheme();

  return (
    <div className="relative">
      <img src={packageBox} alt="Premium Package" className="absolute left-0 -top-[6rem] z-[999]" />
      <div className="rounded-[16.55px] h-[214.38px] bg-no-repeat bg-cover flex flex-col justify-center items-center gap-[7px] text-center px-6"
        style={{ backgroundImage: `url(${premiumbg})` }}
      >
        <h2 className="text-white font-semibold text-[15px]">
          {title}
        </h2>
        <p className="text-[#FFFFFFCC] text-[12px]">
          {text && text}
        </p>
      </div>
    </div>

  );
};

export default SuperSchoolBox;
