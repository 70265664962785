import React, { useState } from "react";
import {
  Button,
  Popover,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Stack,
  Paper,
  colors,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Autocomplete,
  TextField,
  OutlinedInput,
} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { FilterIcon } from "../../assets/icons/Icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const FilterButton = ({ options, onClick, reset, filters }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState({
    startDate: "", 
    endDate: "",
    currency: 566,
    paymentStatus: "", 
    usertype: "",
  });
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [startDateHelperText, setStartDateHelperText] = useState("");
  const [endDateHelperText, setEndDateHelperText] = useState("");
  
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleRadioGroup = (event, name) => {
    setValue({
      ...value,
      [name]: event.target.value,
    });
  };

  const handleChange = (event, name) => {
    event.preventDefault();
    setValue({
      ...value,
      [name]: event.target.value,
    });
  };

  const handleStartDateChange = (newValue) => {

    if (value.endDate && dayjs(newValue)?.isAfter(value?.endDate)) {
      setStartDateError(true);
      setStartDateHelperText("Start Date should be lesser than End Date");
 
    } else {
      setValue({
        ...value,
        startDate: dayjs(newValue)?.format("YYYY-MM-DD"),
      });
      setStartDateError(false);
      setStartDateHelperText("");
    }
  };

  const handleEndDateChange = (newValue) => {
    if (value.startDate && dayjs(newValue)?.isBefore(value.startDate)) {
      setEndDateError(true);
      setEndDateHelperText("End Date should be greater than Start Date");

    } else {
      setValue({
        ...value,
        endDate: dayjs(newValue)?.format("YYYY-MM-DD"),
      });
      setEndDateError(false);
      setEndDateHelperText("");
    }
  };
  
 
  const handleSubmit = (event) => {
    // onClick(value);
    if (value.startDate && !value.endDate) {
      setEndDateError(true);
      setEndDateHelperText("End Date is required");
      return;
    }

    if (!value.startDate && value.endDate) {
      setStartDateError(true);
      setStartDateHelperText("Start Date is required");
      return;
    }

    const filteredValue = Object.fromEntries(
      Object.entries(value).filter(([key, val]) => val !== "")
    );
  
    onClick(filteredValue);
  };

  const handleReset = (event) => {
    reset();
    setValue({
      startDate: "", 
      endDate: "",
      currency: 566,
      paymentStatus: "", 
      usertype: "",
    });
    setStartDateError(false);
    setStartDateHelperText("");
    setEndDateError(false);
    setEndDateHelperText("");
  };
  return (
    <React.Fragment>
      <Tooltip title="Filter Transactions">
        <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  backgroundColor: "#40499D",
                  border: "none",
                  padding: "13px 40px",
                  fontSize:"16px",
                  "&:hover": {
                    // border: "none",
                    bgcolor: "#404999",

                  },
                  // border: "1px solid #40499D"
                }}
                onClick={(e) => {
                    handleClick(e);
                  }}
                startIcon={<FilterIcon color="#fff"/>}
                endIcon={Object.keys(filters)?.length > 0 ? (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "#EAECFF",
                      height: "25px",
                      width: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWe fontSize={"12px"} color={"#40499D"}>
                    {Object.keys(filters)?.length}
                    </Typography>
                  </Box>) : ""
                }
              >
                Filter
              </Button>
        {/* <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: Object.keys(filters)?.length > 0 ? "#40499D" : "#686868",
                  backgroundColor: Object.keys(filters)?.length > 0 ? "#F5F6FF" : "#fff",
                  border: "none",
                  padding: "5px 40px",
                  "&:hover": {
                    // border: "none",
                    border: Object.keys(filters)?.length > 0 ? "1.7px solid #40499D" : "1.7px solid #242424",
                    bgcolor: Object.keys(filters)?.length > 0 ? "none" : "#fff",

                  },
                  border: Object.keys(filters)?.length > 0 ? "none" : "1px solid #CFD3D4"
                }}
                onClick={(e) => {
                    handleClick(e);
                  }}
                startIcon={Object.keys(filters)?.length > 0 ? (
                    <FilterIcon  />
                  ) : (
                    <FilterIcon color="#919191"/>
                  )}
                endIcon={Object.keys(filters)?.length > 0 ? (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "#EAECFF",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWe fontSize={"10px"}>
                    {Object.keys(filters)?.length}
                    </Typography>
                  </Box>) : ""
                }
              >
                Filter
              </Button> */}
      </Tooltip>
      <Popover
        onClose={handleClose}
        sx={{ padding: "0px",
        border: "3px solid var(--Lightbg, #EAECFF)",
        // background: "#FEFEFF",
        boxShadow: "0px 6.967px 37.624px 0px rgba(128, 144, 155, 0.05)",
      }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box>
          <Stack spacing={0.3}>
            <Item sx={{ padding: "0px" }}>
              <Box sx={{ padding: "0px" }} display="flex" justifyContent="end">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Item>
            <Box sx={{ padding: "0px 15px" }}>
              <Item>
                <Box
                  display="flex"
                  gap={5}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ padding: "5px 0px" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#202020",
                      marginBottom: "0px",
                    }}
                    variant="body1"
                    gutterBottom
                  >
                    Filters
                  </Typography>
                  <Box display="flex" gap={1}>
                    <Button
                      onClick={handleReset}
                      sx={{
                        borderRadius: "7px",
                        textTransform: "initial",
                        color: colors.grey[500],
                        borderColor: colors.grey[500],
                        "&:hover": { borderColor: colors.grey[500] },
                      }}
                      size="small"
                      variant="outlined"
                    >
                      Clear Filters
                    </Button>
                    <Button
                      disableElevation
                      onClick={handleSubmit}
                      sx={{
                        borderRadius: "7px",
                        textTransform: "initial",
                      }}
                      size="small"
                      variant="contained"
                    >
                      Apply Filters
                    </Button>
                  </Box>
                </Box>
              </Item>
              <Item>
                <Box sx={{ padding: "20px 0px", width: "700px" }}>
                  <Grid container spacing={3}>
                    {options.map((option, index) => (
                      <Grid
                        key={index}
                        item
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "0px" }}
                      >
                        <div>
                          {option.type === "radio" ? (
                            <FormControl fullWidth size="small">
                              <FormLabel
                                sx={{ textAlign: "left" }}
                                id="demo-row-radio-buttons-group-label"
                              >
                                {option.group}
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={option.name}
                                value={value[option.name] || ""}
                                onChange={(e) => {
                                  handleRadioGroup(e, option.name);
                                }}
                              >
                                {option.options.map((item, index) => (
                                  <FormControlLabel
                                    sx={{
                                      "& svg": {
                                        width: "0.8em",
                                        height: "0.8em",
                                      },
                                    }}
                                    key={index}
                                    value={item.value}
                                    control={
                                      <Radio
                                        key={index}
                                        sx={{
                                          ontSize: "1px",
                                        }}
                                      />
                                    }
                                    label={item.label}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          ) : option.type === "dropdown" ? (
                            <>
                            <label
                            style={{
                              marginBottom: "10px",
                              marginTop: "20px",
                              marginLeft: "5px",
                              fontSize: "14px",
                              fontFamily: Typography.secondaryFont,
                            }}
                            for={option.group}
                          >
                            {option.group}
                          </label>
                            <FormControl
                              key={index}
                              fullWidth
                              variant="standard"
                              sx={{ m: 1, minWidth: 130 }}
                            >
                              {/* <InputLabel id="demo-simple-select-standard-label">
                                {option.group}
                              </InputLabel> */}
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                name={option.name}
                                value={value[option.name] || 0}
                                onChange={(e) => handleChange(e, option.name)}
                                input={<OutlinedInput />}
                                // label={option.label}
                              >
                                {option.name !== "currency" && 
                                  <MenuItem key="all" value="">
                                    All
                                  </MenuItem>}
                                {option.options.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            </>
                          ) : option.type === "startDate" ? (
                            <>
                            <label
                            style={{
                              marginBottom: "10px",
                              marginTop: "20px",
                              marginLeft: "5px",
                              fontSize: "14px",
                              fontFamily: Typography.secondaryFont,
                            }}
                            for="startdate"
                          >
                            Start Date
                          </label>
                            <FormControl
                              key={index}
                              fullWidth
                              variant="standard"
                              sx={{ m: 1, minWidth: 130 }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      openTo="year"
                      views={["year", "month", "day"]}
                      inputFormat="YYYY-MM-DD"
                      value={value.startDate}
                      onChange={(newValue) => handleStartDateChange(newValue)}
                        slotProps={{
                          textField: {
                            helperText: startDateHelperText,
                            error: startDateError,
                            field: { clearable: true,},
                          },
                        }}
                      />
                    </LocalizationProvider>
                            </FormControl>
                            </>
                          ) : option.type === "endDate" ? (
                            <>
                            <label
                            style={{
                              marginBottom: "10px",
                              marginTop: "20px",
                              fontSize: "14px",
                              fontFamily: Typography.secondaryFont,
                            }}
                            for="enddate"
                          >
                            End Date
                          </label>
                            <FormControl
                              key={index}
                              fullWidth
                              variant="standard"
                              sx={{ m: 1, minWidth: 130 }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      openTo="year"
                      views={["year", "month", "day"]}
                      inputFormat="YYYY-MM-DD"
                      value={value.endDate}
                      onChange={(newValue) => handleEndDateChange(newValue)}
                        slotProps={{
                          textField: {
                            helperText: endDateHelperText,
                            error: endDateError,
                            field: { clearable: true,},

                          },
                        }}
                      />
                    </LocalizationProvider>
                            </FormControl>
                            </>
                          ) : null}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Item>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default FilterButton;
