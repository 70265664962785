import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Divider,
    IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
    StyledDialogActions,
    StyledDialogTitle,
    StyledLeftBox,
    StyledRightBox, s
} from "../Modal/DialogStyles";
import BulkImportForm from "./BulkImportForm";
import LoadButton from "../buttons/LoadButton";
import bulkimportImg from "../../assets/images/bulkimport-dir.png"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BulkImportDialog({
    title,
    open,
    actions,
    handleClose,
    handleFormUpdate,
    setToastState,
    refreshParent,
    fetchOverlay,
    BusyOverlay,
}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


    return (
        <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            maxWidth={"xl"}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                style: { borderRadius: 20, overflow: "hidden", height: "100%", position: 'relative' },

            }}
        >
            <Box
                height="100%"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                sx={{
                    [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                    },
                }}
            >
                <StyledLeftBox>
                    <Box>
                        <img src={bulkimportImg} alt="Download template, fill in template and upload back to enrol many students" draggable={false} />
                    </Box>
                </StyledLeftBox>
                <div style={StyledRightBox}>
                    <StyledDialogTitle id="responsive-dialog-title">
                        {title?.right ?? ''}
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </StyledDialogTitle>
                    <DialogContent
                        sx={{ padding: "20px 10px", textTransform: "capitalize" }}
                    >
                        <BulkImportForm handleClose={handleClose} setToastState={setToastState} refreshParent={refreshParent} fetchOverlay={fetchOverlay} BusyOverlay={BusyOverlay} />

                    </DialogContent>
                    <Divider />
                    {actions.isUploaded &&
                        <StyledDialogActions style={{ position: 'absolute', bottom: 20, right: 20 }}>

                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: "capitalize",
                                    padding: "10px 30px",
                                    margin: "10px",
                                }}
                                onClick={handleClose}
                            >
                                close
                            </Button>
                            <LoadButton
                                onClick={() => handleFormUpdate()}
                                disableElevation
                                startIcon={<SaveAsIcon icon="icon-park-outline:edit" />}
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#fff",
                                    padding: "10px 30px",
                                }}
                                variant="contained"
                            >
                                Save Changes
                            </LoadButton>
                        </StyledDialogActions>
                    }
                </div>
            </Box>

        </Dialog>
    );
}
