import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  ThemeProvider,
  Avatar,
  InputAdornment,
  OutlinedInput,
  FormControl,
  IconButton,
} from "@mui/material";
import AuthContainer from "../../components/AuthReuseables/AuthContainer";
import customTheme from "../../components/AuthReuseables/customTheme";
import { Link } from "react-router-dom";
import PasswordResetMsg from "../../components/AuthReuseables/PasswordResetMsg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNotifications } from "../../components/notifications/notifications";
import { resendMail } from "../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import GlobalThemeProvider from "../../GlobalThemeProvider";


const ResendMail = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formValue, setFormValue] = useState({ email: "" });
  const [msg, setMsg] = useState("");
  const [fail, setFail] = useState("");
  const [ToastState, setToastState] = useNotifications();
  const [isEmailValid, setIsEmailValid] = useState(true);


  useEffect(() => {
    if (msg) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      setTimeout(() => {
        setShowConfirmation(true);
      }, 2000);
    }
  }, [msg]);

  useEffect(() => {
    if (fail) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: fail,
      });
    }
  }, [fail]);

  const handleSubmit = () => {
    let param = {
      email: formValue.email,
    };

    dispatch(
      resendMail(
        param.email,
        (res) => setMsg(res.data.msg),
        (res) => setFail(res.data.msg)
      )
    );
  };
  return (
    <GlobalThemeProvider>
      <AuthContainer>
        {showConfirmation ? (
          <PasswordResetMsg email={formValue.email} forgotPassword={false} />
        ) : (
          <Container component="main" maxWidth="xs">
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: "bold",
                mb: 2,
                color: "#1d1d1d",
                textAlign: "center",
              }}
            >
              Resend Verification Mail?
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                mb: 1,
                textAlign: "center",
              }}
            >
              Enter your email, and we will send you a link to get back into
              your account
            </Typography>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                name="email"
                value={formValue.email}
                helperText={
                  isEmailValid ? "" : "Please provide a valid email"
                }
                onChange={(e) => {
                  const emailValue = e.target.value;
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                  setIsEmailValid(emailRegex.test(emailValue));
                  setFormValue({ ...formValue, email: emailValue });
                }}
                id="outlined-adornment-email"
                // size="small"
                endAdornment={<InputAdornment position="end">@</InputAdornment>}
                aria-describedby="outlined-email-helper-text"
                inputProps={{
                  "aria-label": "email",
                }}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                mt: 2,
                py: 2,
                borderRadius: "4px",
                textTransform: "none",
                fontSize: "14px",
              }}
              disabled={
                user.isLoading ||
                !formValue.email ||
                !isEmailValid
              }
            >
               {!user.isLoading ? (
                  "Send Verification Link"
                ) : (
                  <DataUsageIcon size={24} className="ml-4 animate-spin" />
                )}
            </Button>
            <Typography
              sx={{
                mt: 2,
                textAlign: "center",
              }}
            >
              <Link
                to="/auth/login"
                style={{
                  color: "#40499D",
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                <KeyboardBackspaceIcon
                  style={{ color: "inherit", fontSize: "medium" }}
                />{" "}
                Back to Login
              </Link>
            </Typography>
          </Container>
        )}
      </AuthContainer>
      <ToastState />
    </GlobalThemeProvider>
  );
};

export default ResendMail;
