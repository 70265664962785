import React, { useState } from 'react'
import WeeklyCard from '../../../components/Gamification/WeeklyCard';
import AddWeeklyTrivia from './AddWeeklyTrivia';

const Gamification = () => {
  const [active, setActive] = useState(1);
  const [addWeeklyTrivia, setAddWeeklyTrivia] = useState(false);

  return (
    <>
      {!addWeeklyTrivia &&
        <div>
          <h2 className='mt-[40px] e-study-primary font-medium e-study-heading-two'>Gamification</h2>
          <div className='mt-[23px] flex justify-between'>
            <div className='flex gap-8 items-center border-b border-[#CBD5E1] font-[16px] '>
              <div className={`e-study-subtitle-one py-[16px] cursor-pointer ${active === 1 ? 'e-study-primary border-b-[3px] border-primary' : 'text-[#52565B]'}`} onClick={() => setActive(1)}>
                Weekly Trivia
              </div>
              <div className={`e-study-subtitle-one py-[16px] cursor-pointer ${active === 2 ? 'e-study-primary border-b-[3px] border-primary' : 'text-[#52565B]'}`} onClick={() => setActive(2)}>
                Weekly Mission
              </div>
            </div>
            <button className='text-white rounded-[4px] bg-primary h-[40px] px-[25px]' onClick={() => { setAddWeeklyTrivia(true) }}>Add New Trivia</button>
          </div>

          <div className='mt-[32px] bg-white rounded-[8px] w-full grid lg:grid-cols-3 gap-[56px] pt-[27px] pb-[52px] px-[18px]'>
            <div className='col-span-1'>
              <div className='flex justify-between'>
                <h3 className='text-[15px] font-semibold'>Upcoming Trivia</h3>
                <button className='rounded-[4px] bg-primary text-white px-2 h-[20px] text-[10px] font-semibold'>+ Add New</button>
              </div>
              <div className='mt-[15px] flex flex-col gap-[32px]'>
                <WeeklyCard />
                <WeeklyCard />
                <WeeklyCard />
                <WeeklyCard />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='flex justify-between'>
                <h3 className='text-[15px] font-semibold'>Active Trivia</h3>
              </div>
              <div className='mt-[15px] flex flex-col gap-[32px]'>
                <WeeklyCard />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='flex justify-between'>
                <h3 className='text-[15px] font-semibold'>Completed Trivia</h3>
              </div>
              <div className='mt-[15px] flex flex-col gap-[32px]'>
                <WeeklyCard />
                <WeeklyCard />
                <WeeklyCard />
                <WeeklyCard />
              </div>
            </div>
          </div>
        </div>
      }
      {addWeeklyTrivia && 
        <AddWeeklyTrivia goBack={()=>setAddWeeklyTrivia(false)} />
      }
    </>
  )
}

export default Gamification