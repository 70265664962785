import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DataUsageIcon from "@mui/icons-material/DataUsage";
import Typography from '@mui/material/Typography';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchSubPlans, updateAmount } from '../../redux/subscriptions';
import useAdminUser from '../../hooks/useAdminUser';
import useSubscription from '../../hooks/useSubscription';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function EditAmountDialog({ open, handleClose, selectedRowData, setToastState }) {
  const dispatch = useDispatch();
  const adminUser = useAdminUser();
  const { amountLoading } = useSubscription();
  const userInfo = adminUser?.userInfo?.data;
  const [amount, setAmount] = React.useState(selectedRowData ? selectedRowData?.Amount : 0);


  React.useEffect(() => {
    if (selectedRowData) {
      setAmount(selectedRowData?.Amount);
    }
  }, [selectedRowData]);

  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: res.msg,
    });
    dispatch(fetchSubPlans(selectedRowData?.CategoryId));
    handleClose();
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
  };

  const handleUpdate = () => {
    const payload = {
      subscriptionModelId: selectedRowData?.SubscriptionModelId,
      amount: amount,
      profileId: userInfo?.profileId
    }
    dispatch(updateAmount(payload, onSuccess, onFail));
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Edit Package Amount
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  value={selectedRowData?.PackageName ?? ''}
                  label="Package Name"
                  name="packageName"
                  // disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  value={amount}
                  label="Amount"
                  name="amount"
                  onChange={(e) => setAmount(e.target.value)}
                  id="outlined-start-adornment"
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        ₦
                      </InputAdornment>,
                    pattern: '[0-9]*',
                    onKeyPress: (event) => {
                      const charCode = event.which ? event.which : event.keyCode;
                      // Prevents input if the pressed key is not a number or the delete/backspace key
                      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        event.preventDefault();
                      }
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" disabled={amountLoading} sx={{ textTransform: 'capitalize' }} color="primary" onClick={handleUpdate}>
            {amountLoading ?
              <DataUsageIcon size={24} className="animate-spin" />
              :
              "Save Changes"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
