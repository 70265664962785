import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import {ReactComponent as FirstAward} from "../../../assets/images/FirstAward.svg"
import {ReactComponent as SecondAward} from "../../../assets/images/SecondAward.svg"
import {ReactComponent as ThirdAward} from "../../../assets/images/ThirdAward.svg"
import NoItem from "../../../assets/images/noItem.png"


const icon = {
first: <FirstAward />,
second: <SecondAward />,
third: <ThirdAward />,
}

const TopPerformingStudents = ({topStudents, userPathByCategory, path, handleChange}) => {
  


  const getPositionColor = (positionLetter) => {
    switch (positionLetter) {
      case '1':
        return "#00A76F";
      case '2':
        return "#00B8D9";
      case '3':
        return "#DEAB28";
      default:
        return '#000000'; 
    }
  }; 
  const getPositionText = (position) => {
    switch (position) {
      case '1':
        return "1st";
      case '2':
        return "2nd";
      case '3':
        return "3rd";
      default:
        return ''; 
    }
  };
  

  return (
    <Box p={2} bgcolor={"#fff"} borderRadius={"20px"} border={"1px solid #F3F4FE"} sx={{height: "100%"}}>
        <Box mb={2} display={"flex"} justifyContent={"space-between"}>
            <Typography sx={{fontSize:"16px", fontWeight:700, color:"#2B3674"}}>Top Performing Students</Typography>
        
      <FormControl variant="standard" sx={{ minWidth: 120 }}> 
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={path}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="">
            <em>Path</em>
          </MenuItem>
          {userPathByCategory && Array.isArray(userPathByCategory) && userPathByCategory?.length > 0 ? (
              userPathByCategory?.map((pathItem) => (
                <MenuItem key={pathItem.pathId} value={pathItem.pathId}>
                  {pathItem.pathName}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No paths available</MenuItem>
            )}

        </Select>
      </FormControl>
      
        </Box>
        <Box>
          {(!Array.isArray(topStudents) || topStudents?.length === 0) && 
           <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-[#A3AED0]" style={{paddingTop:"40px"}}>
           <img src={NoItem} alt="" width={"100px"} height={"auto"}/>
         No top performing student
       </p>
          }
        {topStudents && (Array.isArray(topStudents)) && topStudents?.length > 0 &&
          topStudents?.map((topStudent, index) => (
            <Box key={index} sx={{
                border:"1px solid rgba(145, 158, 171, 0.16)",
                borderRadius:"14px",
                mb:"10px",
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                padding: "10px",

            }}>
              <Box sx={{display: "flex", alignItems:"center", gap: 2}}>
            <Typography textAlign={"right"} fontStyle={"italic"} fontWeight={500} color={getPositionColor(topStudent?.position)}>{getPositionText(topStudent?.position)}</Typography>
            <Box>
              <Typography color={"#212B36"} fontSize={"14px"} fontWeight={600}>{topStudent?.name}</Typography>
              <Typography variant="subtitle2" color={"#919EAB"} fontSize={"11px"}>{topStudent?.email}</Typography>
            </Box>
            </Box>
            <Box sx={{display: "flex", alignItems:"center", gap:2}}>
            <Typography color={"#212B36"} fontSize={"14px"} fontWeight={600}>{topStudent?.percentage}%</Typography>
            {index === 0 && icon.first}
                {index === 1 && icon.second}
                {index === 2 && icon.third}
            </Box>
            </Box>
        ))}
        </Box>
    </Box>
  )
}

export default TopPerformingStudents