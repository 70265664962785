import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import {
  ChangePassword,
  ResetPassword,
} from "../../redux/settings/settingsActions";
import { useNotifications } from "../notifications/notifications";
import useSettings from "../../hooks/useSettings";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import useAdminUser from "../../hooks/useAdminUser";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "inherit",
  borderRadius: "5px",
  borderColor: "#A6A6A6",
  color: "#a6a6a6",
  "&:hover": { color: "#40499d" },
}));

const Security = () => {
  // const {
  //   userInfo: { profileId },
  // } = useUser();

  const {
    userInfo: { profileId: userProfileId } = {},
  } = useUser();

  const { userInfo: adminUserInfo } = useAdminUser();

  // Check if profileId exists in useUser or useAdminUser
  const ProfileId = userProfileId || adminUserInfo?.data?.profileId || null;


  const { passwordUpdated, isLoading, successState, successMsg } =
    useSettings();
  const [Toast, setToast] = useNotifications();
  const dispatch = useDispatch();
  const initialFormErrorStates = {
    oldPasswordErr: false,
    oldPasswordErrMsg: "",
    NewPasswordErr: false,
    NewPasswordErrMsg: "",
    ConfirmPasswordErr: false,
    ConfirmPasswordErrMsg: "",
  };
  const initialFormValues = {

    profileId: ProfileId ?? "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [formStates, setFormStates] = useState({ ...initialFormErrorStates });

  const passwordFields = [
    {
      name: "oldPassword",
      label: "Current Password",
      err: "oldPasswordErr",
      errMsg: "oldPasswordErrMsg",
    },
    {
      name: "newPassword",
      label: "New Password",
      err: "NewPasswordErr",
      errMsg: "NewPasswordErrMsg",
    },
    {
      name: "confirmPassword",
      label: "Confirm Password",
      err: "ConfirmPasswordErr",
      errMsg: "ConfirmPasswordErrMsg",
    },
  ];

  useEffect(() => {
    if (passwordUpdated) {
      setToast({
        isOpen: true,
        message: successMsg ?? "",
        severity: successState === 1 ? "success" : "error",
      });
      setFormValues({ ...initialFormValues });
    }
    return () => {
      dispatch(ResetPassword());
    };
  }, [
    passwordUpdated,
    formValues?.confirmPassword,
    formValues?.newPassword,
    formValues?.oldPassword,
  ]);

  const handleTextChange = (e, inputName) => {
    const name = inputName;
    const value = e.target.value;
    let newPasswordErr = false;
    let confirmPasswordErr = false;
    let newPasswordErrMsg = "";
    let confirmPasswordErrMsg = "";

    if (name === "newPassword") {
      if (value?.length < 5) {
        newPasswordErr = true;
        newPasswordErrMsg = "Password length must be greater than 5 characters";
      } else if (
        formValues.confirmPassword !== "" &&
        value !== formValues.confirmPassword
      ) {
        confirmPasswordErr = true;
        confirmPasswordErrMsg = "Passwords do not match";
      }
    } else if (name === "confirmPassword") {
      if (value !== formValues.newPassword) {
        confirmPasswordErr = true;
        confirmPasswordErrMsg = "Passwords do not match";
      }
    }
    setFormStates({
      ...formStates,
      NewPasswordErr: newPasswordErr,
      NewPasswordErrMsg: newPasswordErrMsg,
      ConfirmPasswordErr: confirmPasswordErr,
      ConfirmPasswordErrMsg: confirmPasswordErrMsg,
    });

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // Log the formValues to check its content


    // Create a helper function to check if a value is empty
    const isEmpty = (value) => {
      if (typeof value === 'string') {
        return value.trim() === '';
      }
      return value === undefined || value === null;
    };

    // Check if any field is empty
    const hasEmptyFields = Object.values(formValues).some(isEmpty);

    if (!hasEmptyFields) {
      if (
        !formStates?.ConfirmPasswordErr &&
        !formStates?.NewPasswordErr &&
        !formStates?.oldPasswordErr
      ) {
        dispatch(ChangePassword(formValues));
      } else {
        setToast({
          isOpen: true,
          message: "Please correct the highlighted fields",
          severity: "error",
        });
      }
    } else {
      // Log which fields are empty
      const emptyFields = Object.entries(formValues)
        .filter(([key, value]) => isEmpty(value))
        .map(([key]) => key);
      console.log('Empty fields:', emptyFields);

      setToast({
        isOpen: true,
        message: "Please fill all fields",
        severity: "error",
      });
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" fontWeight={600} color={"#313131"}>
              Security
            </Typography>
          </Box>
          <Divider />
          <Box p={3}>
            <Typography fontWeight={600}>Password</Typography>
            <Typography color="#A1A1A1">
              Please enter your current password to change your password.
            </Typography>
            <Divider sx={{ mt: 2 }} />
            {passwordFields?.map((item) => (
              <React.Fragment>
                <Box
                  key={item?.name}
                  pt={3}
                  display={"flex"}
                  gap={3}
                  alignItems={"center"}
                >
                  <Box width={"200px"}>
                    <Typography variant="body1" fontWeight={700}>
                      {item?.label}:
                    </Typography>
                  </Box>
                  <Box width={"50%"}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        type="password"
                        name={item?.name}
                        value={formValues[item?.name]}
                        size="small"
                        id="outlined-required"
                        helperText={formStates[item?.errMsg]}
                        error={formStates[item?.err]}
                        onChange={(e) => handleTextChange(e, item?.name)}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Divider sx={{ mt: 2 }} />
              </React.Fragment>
            ))}
            <Box m="15px 0px" display={"flex"} justifyContent={"end"}>
              <Button
                disabled={isLoading ? true : false}
                variant="contained"
                color="primary"
                sx={{ borderRadius: "5px", textTransform: "inherit" }}
                onClick={handleSubmit}
              >
                {isLoading && isLoading ? (
                  <DataUsageIcon size={24} className="animate-spin ml-4" />
                ) : (
                  "Update Password"
                )}
              </Button>
            </Box>
          </Box>
        </Paper>
        {/*    <Paper sx={{ mt: 3 }}>
          <Box p={2} display={"flex"} flexDirection={"column"} gap={4}>
            <Box
              display="flex"
              alignItems={"center"}
              gap={2}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography fontWeight={700}>Deactivate my account</Typography>
                <Typography color={"#A1A1A1"}>
                  This will shut down your account. Your account will reactivate
                  when you sign in again.
                </Typography>
              </Box>
              <StyledButton variant="outlined">Deactivate</StyledButton>
            </Box>
            <Divider />
            <Box
              display="flex"
              alignItems={"center"}
              gap={2}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography fontWeight={700}>Delete my account</Typography>
                <Typography color={"#A1A1A1"}>
                  This will permanently delete your account.
                </Typography>
              </Box>
              <StyledButton variant="outlined">Delete</StyledButton>
            </Box>
          </Box>
        </Paper> */}
      </Grid>
      <Toast />
    </Grid>
  );
};

export default Security;
