import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * The `useGoogleAnalytics` hook provides methods to track page views, button clicks,
 * and specific events using Google Analytics.
 * @returns The `useGoogleAnalytics` function returns an object with three properties: `trackPage`,
 * `trackButtonClick`, and `trackSpecificEvent`. These properties are functions that can be used to
 * track page views, button clicks, and specific events with their respective data in Google Analytics.
 */
export const useGoogleAnalytics = () => {
  const location = useLocation();

  /**
   * The trackPage function sends page view data to Google Analytics using the gtag event method.
   */
  const trackPage = () => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_location: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  };

  /**
   * The `trackButtonClick` function is used to track button clicks using Google Analytics.
   * @param buttonFunction - The `buttonFunction` parameter in the `trackButtonClick` function is a
   * string that represents the function associated with the button click event. It is used as the value
   * for the `event_description` property in the `gtag` event tracking call.
   */
  const trackButtonClick = (buttonFunction) => {
    window.gtag("event", "click", {
      event_category: "button",
      event_label: "button_click",
      event_description: buttonFunction,
    });
  };

  /**
   * The function `trackSpecificEvent` is used to track a specific event using Google Analytics.
   * @param eventName - The `eventName` parameter in the `trackSpecificEvent` function is a string that
   * represents the name of the specific event being tracked.
   * @param eventData - The `eventData` parameter in the `trackSpecificEvent` function is an object that
   * contains additional data related to the event being tracked. This data could include information
   * such as event category, event label, event value, or any other relevant details that you want to
   * associate with the event being tracked.
   */
  const trackSpecificEvent = (eventName, eventData) => {
    window.gtag("event", eventName, {
      ...eventData,
    });
  };

  //   const trackButtonClick = ()
  return { trackPage, trackButtonClick, trackSpecificEvent };
};

/**
 * The `googleAnalytics` function exports a method `trackSpecificEvent` that sends a specific event to
 * Google Analytics with the provided event name and data.
 * > NB: for use in code that can't use hooks
 * @returns The `googleAnalytics` function is returning an object with a `trackSpecificEvent` function.
 */
export const googleAnalytics = () => {
  /**
   * The function `trackSpecificEvent` is used to track a specific event using Google Analytics.
   * @param {String} eventName - The `eventName` parameter in the `trackSpecificEvent` function is a string that
   * represents the name of the specific event being tracked.
   * @param {Object} eventData - The `eventData` parameter in the `trackSpecificEvent` function is an object that
   * contains additional data related to the event being tracked. This data could include information
   * such as event category, event label, event value, or any other relevant details that you want to
   * associate with the event being tracked.
   */
  const trackSpecificEvent = (eventName, eventData) => {
    window.gtag("event", eventName, {
      ...eventData,
    });
  };

  return { trackSpecificEvent };
};
