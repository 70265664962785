
import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import LinearProgress, {
    linearProgressClasses,
  } from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#FFD353" : "#308fe8",
    },
  }));
const ProgressBar = () => {
  return (
    <Box>
    <Typography textAlign={"right"} fontSize={"12px"} mb={1}>
      you are among the top 30%
    </Typography>
    <BorderLinearProgress variant="determinate" value={50} />
  </Box>
  )
}

export default ProgressBar