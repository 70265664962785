import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PostSub from "../../../components/dashboards/sponsor/payment/subscription/postSub";
import Payment from "./Pay";
import AllSubscriptionCards from "../../../components/SubPlans";
import useUser from "../../../hooks/useUser";
import { fetchSchoolSubscriptions, fetchSponsorSubscriptions, fetchStudentSubscriptions, fetchSubPlans } from "../../../redux/subscriptions";
import useSubscription from "../../../hooks/useSubscription";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TransactionStatus from "../../../components/Payment/TransactionStatus";

const SponsorPayment = () => {
  const dispatch = useDispatch();
  const [paymentModal, setPaymentModal] = useState(false);
  const [showPackages, setShowPackages] = useState(false);
  // const [sub, setSub] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { allSubPlan, studentSub, schoolSub, sponsorSub, } = useSubscription();
  const { userInfo: { categoryName, categoryId, sponsorId, schoolId, profileId } } = useUser();

  const handleAddNewSubscription = () => {
    setShowPackages(true);
  };

  const handleGoBack = () => {
    setShowPackages(false);
  };

  useEffect(() => {
    dispatch(fetchSubPlans(categoryId));

    dispatch(fetchSponsorSubscriptions(sponsorId));

  }, []);

  // useEffect(()=>{
  //   sponsorSub && setSub(sponsorSub)
  // },[sponsorSub])

  return (
    <>
      <Container sx={{ padding: { xs: "0px" } }}>
        <Box
          // component={Stack} gap={3}
          padding={0} bgcolor={"#FAFAFB"}>
          <Box pb={2}>
            <Typography color={"#45464E"} fontSize={"16px"} fontWeight={600}>
              Subscription and Payment
            </Typography>
            <Typography color={"#45464E"} fontSize={"14px"}>
              Proceed with the sponsor subscription plan and make payment
              seamlessly
            </Typography>
          </Box>

          {showPackages && (
            <Button
              onClick={handleGoBack}
              startIcon={<KeyboardBackspaceIcon color="#313131" />}
              sx={{ textTransform: "inherit", color: "#313131", paddingBottom: "10px"}}
            >
              Go Back
            </Button>)}

          {(sponsorSub?.allSubsData?.length === 0 || sponsorSub?.allSubsData === null || showPackages) &&
            <AllSubscriptionCards subCards={allSubPlan}
              onClick={(subscription) => {
                setSelectedSubscription(subscription);
                setPaymentModal(true);
              }} />
          }

          {(sponsorSub?.allSubsData?.length > 0 && !showPackages) &&
            <PostSub onAddNewSubscription={handleAddNewSubscription} sub={sponsorSub} />
          }
          

        </Box>
        <Dialog
          fullScreen={true}
          open={paymentModal}
          onClose={() => setPaymentModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Payment setPaymentModal={setPaymentModal}
              selectedSubscription={selectedSubscription}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default SponsorPayment;
