import React, { useRef, useEffect } from 'react';
import badgeSound from "../../assets/audios/badge-sound.mp3"; 

function BadgeSound() {
  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.play(); 
    }
  }, []);

  return (
    <div>
      <audio ref={audioRef} controls={false}>
        <source src={badgeSound} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default BadgeSound;
