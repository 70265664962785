import { Box, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Logo, Sidmach } from "../../assets/images";
import Button from "../buttons/Button";
import {
  BookIcon,
  DashboardIcon,
  DeviceIcon,
  LeaderBoardIcon,
  ManageStudentIcon,
  PaymentIcon,
} from "../../assets/icons/Icons";
import PremiumBox from "../Premium/PremiumBox";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import useUser from "../../hooks/useUser";
import WeeklyTriviaBox from "../Premium/WeeklyTriviaBox";
import { CustomImage } from "../landingPage/CTAJumbotron";
import SuperSchoolBox from "../Box/SuperSchoolBox";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // justifyContent: "center",
}));

const SchoolSidebar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, setOpened] = useState(null);

  const [activeLink, setActiveLink] = useState(0);
  const [subActiveLink, setSubActiveLink] = useState(0);
  const { userInfo } = useUser();

  const links = [
    {
      label: "Dashboard",
      path: "/school/dashboard",
      icon: <DashboardIcon color={activeLink == 0 ? "white" : "#53545C"} />,
      subItems: [],
    },

    {
      label: "Manage Students",
      path: "/school/students",
      icon: (
        <ManageStudentIcon
          size={24}
          color={activeLink == 1 ? "white" : "#53545C"}
        />
      ),
      subItems: [],
    },
    {
      label: "Payment",
      path: "/school/payment",
      icon: <PaymentIcon color={activeLink == 2 ? "white" : "#53545C"} />,
      subItems: [],
    },
    
  ];

  if (userInfo?.isSuperSchoolAdmin) {
    links.push(
      {
        label: "Devices",
        path: "/school/settings?tab=3",
        icon: <DeviceIcon color={activeLink == 3 ? "white" : "#53545C"} />,
        subItems: [],
      },
    )
  }

  const handleListDisplay = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    if (location.pathname == "/school/dashboard") {
      setActiveLink(0);
    }
    if (location.pathname == "/school/students") {
      setActiveLink(1);
    }
    if (location.pathname == "/school/payment") {
      setActiveLink(2);
    }
    if (location.pathname == "/school/settings?tab=3") {
      setActiveLink(3);
    }
  }, [location.pathname]);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      className="shadow-xl"
    >
      <div className="flex flex-col justify-between min-h-screen px-6">
        <div>
          {" "}
          <DrawerHeader>
            <img src={Logo} alt="Logo" style={{ maxWidth: "90%" }} />
          </DrawerHeader>
          <ul style={{ zIndex: 9999 }}>
            {links.map((link, index) => (
              <li
                key={index}
                onClick={() => {
                  setActiveLink(index);
                }}
                className="flex mt-3"
              >
                <Button.Blue
                  className={`cursor-pointer e-study-black ${index === activeLink
                      ? "e-study-normal-text-three e-study-button-blue"
                      : "text-black"
                    }`}
                  onClick={() => {
                    navigate(link.path);
                    link.subItems.length < 1 || opened == index
                      ? (setOpened(false), setSubActiveLink(0))
                      : handleListDisplay();
                  }}
                  title={
                    <div className="flex space-x-3 items-center">
                      {link.icon}
                      <span>{link.label}</span>
                      <span>
                        {link.subItems.length > 0 &&
                          index == activeLink &&
                          opened ? (
                          <ExpandMore />
                        ) : (link.subItems.length > 0 && index != activeLink) ||
                          (link.subItems.length > 0 &&
                            index == activeLink &&
                            !opened) ? (
                          <ExpandLess />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  }
                />

                {link.subItems.length > 0 && index == activeLink && opened && (
                  <ul className="pl-4 mt-3 list-disc  flex flex-col justify-center self-center space-y-3">
                    {link.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`cursor-pointer ${subIndex == subActiveLink
                            ? "e-study-primary"
                            : "e-study-primary-blur"
                          }`}
                        onClick={() => setSubActiveLink(subIndex)}
                      >
                        <NavLink to={subItem.path}>{subItem.name}</NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col gap-5">
          {userInfo?.isSuperSchoolAdmin &&
            <SuperSchoolBox title='Super School Subscription' />
          }
          <Box display={"flex"} gap={0.5} alignItems={"center"}>
            <Typography
              textAlign={{ xs: "center", md: "start" }}
              fontSize={{ xs: 13, md: 16 }}
              color={"black"}
              variant="p"
            >
              Powered By
            </Typography>
            <CustomImage
              src={Sidmach}
              sx={{ display: "inline", height: "4rem" }}
            />
          </Box>
        </div>
      </div>
    </Drawer>
  );
};

export default SchoolSidebar;
