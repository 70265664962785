import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import { commercial, art, science } from "../../../assest/images/index";
import { useDispatch } from "react-redux";
import useUser from "../../../hooks/useUser";
import { getUserPathByCategory } from "../../../redux/auth/authActions";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const ColoredPaper = styled(Paper)(({ theme, selected }) => ({
  backgroundColor: selected ? "#EAECFF" : theme.palette.background.default,
  borderRadius: "20px",
  border: "1px solid #E5E5E5",
  boxShadow: "0px 4px 4px 0px #E5E5E5",
}));

export default function Path(props) {
  const { input, setInput } = props;
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = React.useState("");

  const dispatch = useDispatch();
  const user = useUser();
  const { userPathByCategory, isLoading } = user;

  useEffect(() => {
    dispatch(getUserPathByCategory(input?.categoryId));
  }, []);

  const handleChange = (event, param1) => {
    setSelectedValue(event.target.value);
    setInput({ ...input, userPathId: param1 });
  };

  const picturess = [science, commercial, art];

  const desiredgradegrid = {
    marginTop: "7rem",
    marginBottom: "2rem",

    [theme.breakpoints.up("md")]: {
      marginTop: "7rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "3rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: "1rem",
      marginBottom: "0rem",
    },
  };

  const desiredgradetext = {
    fontSize: "30px",
    flexGrow: 0,
    paddingRight: "30px",

    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      flexGrow: 0,
      paddingRight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        sx={desiredgradegrid}
      >
        <Grid item xs={12}>
          <Typography variant="h6" sx={desiredgradetext}>
            Choose Your Path, Area or Major
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "2rem",
          "& > :not(style)": {
            m: 2,
            width: 200,
            height: 200,
          },
        }}
      >
        {/* User Path By Category Card */}
        {userPathByCategory.length !== 0
          ? [...userPathByCategory]?.reverse().map((item, i) => (
              <ColoredPaper
                elevation={3}
                sx={{ mr: 2, p: 2, borderRadius: 6 }}
                selected={item.pathId == input.userPathId}
                // selected={selectedValue === item.pathName}
              >
                <Radio
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  disableRipple
                  checked={item.pathId == input.userPathId}
                  // checked={selectedValue === item.pathName}
                  onChange={(event) => handleChange(event, item.pathId)}
                  value={item.pathName}
                  color="default"
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  {...props}
                />
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={0}
                >
                  <Grid item>
                    <img
                      src={picturess[i]}
                      alt="parent"
                      width={100}
                      height={90}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">{item.pathName}</Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </ColoredPaper>
            ))
          : "No path available for chosen category, Please click on continue button to proceed."}
      </Box>
    </div>
  );
}
