import { useSelector } from "react-redux";

const useSubscription = () => {
  const subscription = useSelector((state) => state.subscription);

  const {
    loading,
    checkSub,
    allSubPlan,
    paymentSumary,
    isLoaded,
    studentSub,
    schoolSub,
    sponsorSub,
    schoolSponsorSubscriptions,
    subscriptionPackageLoader,
    amountLoading,
    amountUpdate
  } = subscription;

  const isTrial = checkSub.msg === "InActive"

  return {
    loading,
    isTrial,
    checkSub,
    allSubPlan,
    paymentSumary,
    isLoaded,
    studentSub,
    schoolSub,
    sponsorSub,
    schoolSponsorSubscriptions,
    subscriptionPackageLoader,
    amountLoading,
    amountUpdate
  };
};

export default useSubscription;
