import { useEffect, useState } from "react";
import SubscriptionCard from "./SubCard";
import {
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { ReactComponent as SchoolPaymentIcon } from "../../assets/images/SchoolPaymentIcon.svg";
import EastIcon from "@mui/icons-material/East";
import { convertDaysToMonths, formatCurrency } from "../../helpers/helper";
import useUser from "../../hooks/useUser";
import { useDispatch } from "react-redux";
import { fetchSubPlans } from "../../redux/subscriptions";
import useSubscription from "../../hooks/useSubscription";
import { Redeem } from "@mui/icons-material";
import Payment from "../dashboards/school/specialOfferPay/pay";

const AllSubscriptionCards = ({ subCards, onClick }) => {
  const { userInfo } = useUser();
  const dispatch = useDispatch();
  const { allSubPlan } = useSubscription();
  const { categoryId } = userInfo;

  useEffect(() => {
    dispatch(fetchSubPlans(categoryId));
  }, []);

  const middleIndex = Math.floor(subCards?.data?.length / 2);
  const centerRecommended = Math.floor((subCards?.data?.length - 2) / 2);
  const centerSpecial = Math.floor((subCards?.data?.length - 4) / 2);

  const [superSchoolPayment, setSuperSchoolPayment] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleGetStartedClick = (card) => {
    // if (allSubPlan && allSubPlan?.data?.length > 0) {
    //   const lastSubscriptionModelId =
    //     allSubPlan?.data[allSubPlan?.data?.length - 1];
    {
      setSelectedSubscription(card);
      setSuperSchoolPayment(true);
    }
  };

  // Define the custom order
  const customOrder = ["Super School", "Yearly", "Bi-Annual", "Monthly"];

  // Sort the array of objects based on the packageName key
  const sortedPackages = subCards?.data?.sort((a, b) => {
    return (
      customOrder?.indexOf(a?.PackageName) -
      customOrder?.indexOf(b?.PackageName)
    );
  });

  return (
    <>
      <div className="gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {subCards &&
          (userInfo?.isSchoolAdmin
            ? [...sortedPackages]?.map((card, index) => (
                <div key={card.PackageName} className="col-span-1">
                  {/* <SubscriptionCard card={card} onClick={() => onClick(card)} middleIndex={middleIndex}/> */}

                  <>
                    <Box
                      sx={{
                        bgcolor:
                          card?.PackageName?.toLowerCase() === "yearly"
                            ? "#40499D"
                            : "white",
                        borderRadius: "20px",
                        border: "1px solid var(--Lightbg, #EAECFF)",
                        boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
                        height: "100%",
                        width: "18rem",
                        padding: "30px 20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                        //   margin: "auto",
                      }}
                    >
                      <Stack>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <h6
                            style={{
                              backgroundColor:
                                card?.PackageName?.toLowerCase() === "yearly"
                                  ? "#fff"
                                  : "#EAECFF",
                              borderRadius: "6px",
                              padding: "5px 10px",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                          >
                            {card?.PackageName}
                          </h6>
                          {card?.PackageName?.toLowerCase() === "yearly" ? (
                            <h6
                              style={{
                                fontWeight: 600,
                                // padding:"5px",
                                borderRadius: "3px",
                                // backgroundColor: "#656A7F",
                                fontSize: "11px",
                                color: "#F8EF07",
                              }}
                            >
                              Recommended
                            </h6>
                          ) : card?.PackageName?.toLowerCase() ===
                            "super school" ? (
                            <div className="gap-2 flex items-center">
                              <Redeem className="text-[#FF6600]" />
                              <h6
                                style={{
                                  fontWeight: 700,
                                  // padding:"5px",
                                  borderRadius: "3px",
                                  // backgroundColor: "#656A7F",
                                  fontSize: "11px",
                                  color: "#FF6600",
                                }}
                              >
                                Special Offer
                              </h6>
                            </div>
                          ) : null}
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color:
                              card?.PackageName?.toLowerCase() === "yearly"
                                ? "white"
                                : "#919191",
                            pt: 2,
                          }}
                        >
                          Upon subscribing to our {card?.PackageName} plan, your
                          <br /> access commences instantly and extends for a
                          full {convertDaysToMonths(card?.DaysAllowed)}{" "}
                          {convertDaysToMonths(card?.DaysAllowed) === 1
                            ? "month"
                            : "months"}
                          .
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} pt={2}>
                          <Typography
                            sx={{
                              fontSize: "30px",
                              color:
                                card?.PackageName?.toLowerCase() === "yearly"
                                  ? "white"
                                  : "#40499D",
                              fontWeight: 900,
                            }}
                          >
                            {formatCurrency(card?.Amount)}
                            {/* {card?.Amount} */}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color:
                                card?.PackageName?.toLowerCase() === "yearly"
                                  ? "white"
                                  : "#919191",
                              pt: 1,
                            }}
                          >
                            / {convertDaysToMonths(card?.DaysAllowed)}{" "}
                            {convertDaysToMonths(card?.DaysAllowed) === 1
                              ? "Month"
                              : "Months"}
                            {userInfo?.isSchoolAdmin &&
                            card?.PackageName?.toLowerCase() === "super school"
                              ? " / 10 Users"
                              : "/ Student"}
                          </Typography>
                        </Box>

                        <Box color="#313131" mt={2}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "14px", fontWeight: 600 }}
                            color={
                              card?.PackageName?.toLowerCase() === "yearly"
                                ? "white"
                                : "#313131"
                            }
                            component="div"
                          >
                            What's Included:
                          </Typography>
                          <List dense sx={{ py: 0 }}>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      card?.PackageName?.toLowerCase() ===
                                      "yearly"
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  card?.PackageName?.toLowerCase() ===
                                  "super school"
                                    ? `Access to all subjects for ${convertDaysToMonths(
                                        card?.DaysAllowed
                                      )} ${
                                        convertDaysToMonths(
                                          card?.DaysAllowed
                                        ) === 1
                                          ? "month"
                                          : "months"
                                      }`
                                    : `Access to 9 subjects for ${convertDaysToMonths(
                                        card?.DaysAllowed
                                      )} ${
                                        convertDaysToMonths(
                                          card?.DaysAllowed
                                        ) === 1
                                          ? "month"
                                          : "months"
                                      }`
                                }
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    card?.PackageName?.toLowerCase() ===
                                    "yearly"
                                      ? "white"
                                      : "#313131",
                                }}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      card?.PackageName?.toLowerCase() ===
                                      "yearly"
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  card?.PackageName?.toLowerCase() ===
                                  "super school"
                                    ? `Access for up to 10 concurrent users `
                                    : `Access to Study and Mock Exams`
                                }
                                // primary="Access to all subject years"
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    card?.PackageName?.toLowerCase() ===
                                    "yearly"
                                      ? "white"
                                      : "#313131",
                                }}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      card?.PackageName?.toLowerCase() ===
                                      "yearly"
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  card?.PackageName?.toLowerCase() ===
                                  "super school"
                                    ? `Access to Study and Mock Exams`
                                    : `24/7 access`
                                }
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    card?.PackageName?.toLowerCase() ===
                                    "yearly"
                                      ? "white"
                                      : "#313131",
                                }}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      card?.PackageName?.toLowerCase() ===
                                      "yearly"
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  card?.PackageName?.toLowerCase() ===
                                  "super school"
                                    ? `Access from 8am to 6pm`
                                    : `Ad free studying`
                                }
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    card?.PackageName?.toLowerCase() ===
                                    "yearly"
                                      ? "white"
                                      : "#313131",
                                }}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      card?.PackageName?.toLowerCase() ===
                                      "yearly"
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  card?.PackageName?.toLowerCase() ===
                                  "super school"
                                    ? `Ad free studying`
                                    : `Performance tracking metrics`
                                }
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    card?.PackageName?.toLowerCase() ===
                                    "yearly"
                                      ? "white"
                                      : "#313131",
                                }}
                              />
                            </ListItem>
                            {card?.PackageName?.toLowerCase() ===
                              "super school" && (
                              <ListItem
                                alignItems="center"
                                disableGutters
                                disablePadding
                              >
                                <ListItemIcon
                                  sx={{ minWidth: "fit-content", mr: 1 }}
                                >
                                  <CheckCircleIcon
                                    sx={{
                                      color:
                                        card?.PackageName?.toLowerCase() ===
                                        "yearly"
                                          ? "white"
                                          : "#40499D",
                                    }}
                                    fontSize="14"
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={`Performance tracking metrics`}
                                  primaryTypographyProps={{
                                    fontSize: 12,
                                    color:
                                      card?.PackageName?.toLowerCase() ===
                                      "yearly"
                                        ? "white"
                                        : "#313131",
                                  }}
                                />
                              </ListItem>
                            )}
                          </List>
                        </Box>
                      </Stack>

                      <Button
                        variant="contained"
                        sx={{
                          mt: 3,
                          color:
                            card?.PackageName?.toLowerCase() === "yearly"
                              ? "#40499D"
                              : "white",
                          borderColor:
                            card?.PackageName?.toLowerCase() === "yearly"
                              ? "#40499D"
                              : "white",
                          bgcolor:
                            card?.PackageName?.toLowerCase() === "yearly"
                              ? "white"
                              : "#40499D",
                          textTransform: "capitalize",
                          padding: "10px",
                          borderRadius: "5px",
                          "&:hover": {
                            backgroundColor:
                              card?.PackageName?.toLowerCase() === "yearly"
                                ? "#ccc"
                                : "#2C3582",
                          },
                        }}
                        endIcon={<EastIcon />}
                        onClick={() => {
                          card?.PackageName?.toLowerCase() === "super school"
                            ? handleGetStartedClick(card)
                            : onClick(card);
                        }}
                      >
                        Get Started
                      </Button>
                    </Box>
                  </>
                </div>
              ))
            : subCards &&
              subCards?.data?.map((card, index) => (
                <Grid key={card.PackageName} item xs={12} sm={12} md={4}>
                  {/* <SubscriptionCard card={card} onClick={() => onClick(card)} middleIndex={middleIndex}/> */}
                  <>
                    <Box
                      sx={{
                        bgcolor: index === middleIndex ? "#40499D" : "white",
                        borderRadius: "20px",
                        border: "1px solid var(--Lightbg, #EAECFF)",
                        boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
                        height: "100%",
                        width: "18rem",
                        padding:
                          index === middleIndex
                            ? "10px 20px 30px 20px"
                            : "30px 20px",
                        //   margin: "auto",
                      }}
                    >
                      <Stack>
                        <Box display={"flex"} justifyContent={"end"}>
                          {index === middleIndex && (
                            <h6
                              style={{
                                fontWeight: 600,
                                // padding:"5px",
                                borderRadius: "3px",
                                // backgroundColor: "#656A7F",
                                fontSize: "11px",
                                color: "#F8EF07",
                              }}
                            >
                              Popular
                            </h6>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <h6
                            style={{
                              backgroundColor:
                                index === middleIndex ? "#fff" : "#EAECFF",
                              borderRadius: "6px",
                              padding: "5px 10px",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                          >
                            {card?.PackageName}
                          </h6>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: index === middleIndex ? "white" : "#919191",
                            pt: 2,
                          }}
                        >
                          Upon subscribing to our {card?.PackageName} plan, your
                          <br /> access commences instantly and extends for a
                          full {convertDaysToMonths(card?.DaysAllowed)}{" "}
                          {convertDaysToMonths(card?.DaysAllowed) === 1
                            ? "month"
                            : "months"}
                          .
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} pt={2}>
                          <Typography
                            sx={{
                              fontSize: "30px",
                              color:
                                index === middleIndex ? "white" : "#40499D",
                              fontWeight: 900,
                            }}
                          >
                            {formatCurrency(card?.Amount)}
                            {/* {card?.Amount} */}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color:
                                index === middleIndex ? "white" : "#919191",
                              pt: 1,
                            }}
                          >
                            / {convertDaysToMonths(card?.DaysAllowed)}{" "}
                            {convertDaysToMonths(card?.DaysAllowed) === 1
                              ? "Month"
                              : "Months"}
                            {userInfo?.isSponsor ? " / Student" : ""}
                          </Typography>
                        </Box>

                        <Box color="#313131" mt={2}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "14px", fontWeight: 600 }}
                            color={index === middleIndex ? "white" : "#313131"}
                            component="div"
                          >
                            What's Included:
                          </Typography>
                          <List dense sx={{ py: 0 }}>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      index === middleIndex
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={`Access to all subjects for ${convertDaysToMonths(
                                  card?.DaysAllowed
                                )} ${
                                  convertDaysToMonths(card?.DaysAllowed) === 1
                                    ? "month"
                                    : "months"
                                }`}
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    index === middleIndex ? "white" : "#313131",
                                }}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      index === middleIndex
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Access to all subject years"
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    index === middleIndex ? "white" : "#313131",
                                }}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      index === middleIndex
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Performance tracking metrics"
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    index === middleIndex ? "white" : "#313131",
                                }}
                              />
                            </ListItem>
                            <ListItem
                              alignItems="center"
                              disableGutters
                              disablePadding
                            >
                              <ListItemIcon
                                sx={{ minWidth: "fit-content", mr: 1 }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color:
                                      index === middleIndex
                                        ? "white"
                                        : "#40499D",
                                  }}
                                  fontSize="14"
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Ad free studying"
                                primaryTypographyProps={{
                                  fontSize: 12,
                                  color:
                                    index === middleIndex ? "white" : "#313131",
                                }}
                              />
                            </ListItem>
                          </List>
                        </Box>
                        <Button
                          variant="contained"
                          sx={{
                            mt: 3,
                            color: index === middleIndex ? "#40499D" : "white",
                            borderColor:
                              index === middleIndex ? "#40499D" : "white",
                            bgcolor:
                              index === middleIndex ? "white" : "#40499D",
                            textTransform: "capitalize",
                            padding: "10px",
                            borderRadius: "5px",
                            "&:hover": {
                              backgroundColor:
                                index === middleIndex ? "#ccc" : "#2C3582",
                            },
                          }}
                          endIcon={<EastIcon />}
                          onClick={() => {
                            card?.PackageName?.toLowerCase() === "super school"
                              ? handleGetStartedClick(card)
                              : onClick(card);
                          }}
                        >
                          Get Started
                        </Button>
                      </Stack>
                    </Box>
                  </>
                </Grid>
              )))}
      </div>
      <Dialog
        fullScreen={true}
        open={superSchoolPayment}
        onClose={() => setSuperSchoolPayment(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Payment
            setPaymentModal={setSuperSchoolPayment}
            selectedSubscription={selectedSubscription}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AllSubscriptionCards;
