import styled from '@emotion/styled';
import { LinearProgress, Typography, linearProgressClasses } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 18,
    borderRadius: 9,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#E5E5E5",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 9,
      backgroundColor: "#FFCF23",
    },
  }));

function LinearProgressWithLabel({ value }) {
    const containerStyle = {
      position: 'relative',
      width: '300px', 
    };
  
    const textStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 'bold', 
    fontSize: "12px",
    color: value > 0 ? "#40499D" : "#888"
    };
  
    return (
      <div style={containerStyle}>
        <BorderLinearProgress variant="determinate" value={value} />
        <Typography variant="body2" style={textStyle} color="text.secondary">
          0 / 1000
        </Typography>
      </div>
    );
  }
  
  

const LevelCard = () => {
  return (
    <>
        <Typography
              variant="h6"
              style={{
                fontWeight: 600,
                fontSize: "18px",
                color: "var(--dmv-black, #23262F)",
                margin: "0px",
                padding: "0px",
              }}
            >
              Level 0 👶
            </Typography>
              <Box sx={{mt: 0.5}}>
                <LinearProgressWithLabel value={0} />
              </Box>
    </>
  )
}

export default LevelCard