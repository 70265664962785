import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import useDashboard from "../../../hooks/useDashboard";

const SubjectStat = ({ title, icon, subject }) => {
  const {  isLoading } = useDashboard();
  return (
    <Grid item xs={12} sm={4} md>
    <Box
      sx={{
        border: "1px solid var(--Lightbg, #EAECFF)", 
        padding: "15px 15px 15px 10px",
        borderRadius: "10px",
        background: "#FFF",
        boxShadow: "0px 6.745px 36.42px 0px rgba(128, 144, 155, 0.05)",
        display: "flex",
        alignItems: "center",
        gap: 1,
        height: "80px"
      }}
    >
      {icon}
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontSize: "13px" , color: "#030229"}}>
          {title}
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" width="60%" />
        ) : (
          <Typography
            variant="h3"
            sx={{ fontSize: "15px", fontWeight: 700, color: "#030229" }}
          >
            {subject}
          </Typography>
        )}
      </Box>
    </Box>
    </Grid>
  );
};

export default SubjectStat;
