import React from 'react'

const Button = ({ children }) => <>{children}</>;

Button.Blue = ({
  title,
  type = "button",
  style,
  className,
  disabled,
  loading,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      style={{...style, cursor: disabled && "not-allowed"}}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={"py-2 px-4 flex justify-center items-center text-white text-center rounded-lg whitespace-nowrap " + className}>
      {title ? title : "Title"}
    </button>
  );
}

export default Button;