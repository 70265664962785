import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckUserSub } from '../../redux/subscriptions';
import { confirmPaymentStatus } from '../../redux/payment/paymentActions';
import TransactionStatus from './TransactionStatus';
import Loader from '../AuthReuseables/Preloader/Loader';
import usePayment from '../../hooks/usePayment';
import useUser from '../../hooks/useUser';







const ConfirmPaymentStatus = ({ TransId, onClose, actions }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useUser();
  const payment = usePayment();

  const [paymentStatus, setPaymentStatus] = useState('');
  const [loader, setLoader] = useState(true);


  const onSuccess = (res) => {
    setPaymentStatus((res?.code === '00' ? 'success' : (res?.code == "S20" || res?.code == "INP" || res?.code == "INP2" || res?.code == "SM_P" || res?.code == "SM_09") ? 'pending' : 'fail') ?? res?.status);
    setLoader(false);
    if (res?.status?.toLowerCase() === "approved" || res?.status?.toLowerCase() === "success" || res?.code ==='00') {
      setTimeout(() => {
        dispatch(CheckUserSub(user?.userInfo?.profileId));
        navigate('/dashboard/home', { state: { subjectModal: true } });
      }, 10000);     
    }
    if (actions) {
      actions();
    }
  }

  const onFailure = (res) => {
    setPaymentStatus('fail');
    setLoader(false);
  }

  function isGUID(str) {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(str);
  }


  useEffect(() => {
    const payload = {
      paymentGateway: isGUID(TransId) ? 2 : 1,
      paymentReference: TransId
    }
    dispatch(confirmPaymentStatus(payload, onSuccess, onFailure))
  }, []);



  return (
    <div className="w-full flex overflow-auto justify-center h-screen">
      {(payment?.loading || loader) ? (
        <div className=" flex flex-col text-center items-center justify-center">
          <Loader />
        </div>
      ) :
        (<TransactionStatus status={paymentStatus?.toLowerCase() == "initiated" ? "pending" : (paymentStatus?.toLowerCase() == "approved" || paymentStatus?.toLowerCase() == "success" ) ? "success" : "fail"} onClose={onClose} />)
      }
    </div>
  )
}

export default ConfirmPaymentStatus