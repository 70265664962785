export const FECTCH_CLASS_LEVEL = "FECTCH_CLASS_LEVEL";
export const FECTCH_CLASS_LEVEL_FAIL = "FECTCH_CLASS_LEVEL_FAIL";
export const ADD_NEW_STUDENT = "ADD_NEW_STUDENT";
export const ADD_NEW_STUDENTS = "ADD_NEW_STUDENTS";
export const LOAD_STUDENT = "LOAD_STUDENT";
export const GET_ALL_STUDENT = " GET_ALL_STUDENT";
export const GET_ALL_SCHOOL_BY_SPONSOR_ID = "GET_ALL_SCHOOL_BY_SPONSOR_ID";
export const GET_ALL_SCHOOL_BY_SPONSOR_ID_FAIL =
  "GET_ALL_SCHOOL_BY_SPONSOR_ID_FAIL";
export const GET_ALL_STUDENT_BY_SPONSOR_ID = "GET_ALL_STUDENT_BY_SPONSOR_ID";
export const GET_ALL_SPONSORS_BY_SCHOOL_ID_SUCCESS =
  "GET_ALL_SPONSORS_BY_SCHOOL_ID_SUCCESS";
export const GET_ALL_SPONSORS_BY_SCHOOL_ID_FAIL =
  "GET_ALL_SPONSORS_BY_SCHOOL_ID_FAIL";
export const FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS =
  "FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SCHOOL_SUBSCRIPTIONS_FAIL =
  "FETCH_SCHOOL_SUBSCRIPTIONS_FAIL";
export const GET_STUDENT_PROFILE_ID = "GET_STUDENT_PROFILE_ID";
export const GET_STUDENT_PROFILE_ID_FAIL = "GET_STUDENT_PROFILE_ID_FAIL";
export const RESEND_STUDENT_MAIL = "RESEND_STUDENT_MAIL";
export const EDIT_MANAGE_STUDENT = "EDIT_MANAGE_STUDENT";
export const GET_ALL_DEVICES = "GET_ALL_DEVICES_FAIL";
export const GET_ALL_DEVICES_FAIL = "GET_ALL_DEVICES_FAIL";
export const SUPER_SCHOOL_USER_MAIL = "SUPER_SCHOOL_USER_MAIL";
