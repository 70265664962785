import { useSelector } from "react-redux";

const useLearning = () => {
  const learning = useSelector((state) => state.learning);

  const {
    loading,
    subjectData,
    allExam,
    error,
    subjectById,
    examTypeData,
    examQuestion,
    examCount,
    loadingQuestion,
    practiceQuestions,
    practiceQuestionCount,
    subUser,
    points,
    studyTime,
    loadQuestionSolution,
    questionSolution,
    questionsViewed,
    loadPracticeQuestion,
  } = learning;

  return {
    loading,
    subjectData,
    allExam,
    error,
    subjectById,
    examTypeData,
    examCount,
    examQuestion,
    loadingQuestion,
    practiceQuestions,
    practiceQuestionCount,
    subUser,
    points,
    studyTime,
    loadQuestionSolution,
    questionSolution,
    questionsViewed,
    loadPracticeQuestion,
  };
};

export default useLearning;
