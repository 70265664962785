import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

const getAvatarBackgroundColor = (initial) => {
  const colorSeed = initial.charCodeAt(0) * 10;
  const hue = colorSeed % 360;
  const lightness = 70;
  const saturation = 50;

  return { baseColor: `hsl(${hue}, ${saturation}%, ${lightness}%)`, lighterColor: `hsl(${hue}, ${saturation}%, ${lightness + 20}%)` };
};


const OneTestPaper = ({ score, grade, examName, subjectName, total }) => {
  const subjectInitial = subjectName ? subjectName.charAt(0).toUpperCase() : "";
  const { baseColor, lighterColor } = getAvatarBackgroundColor(subjectInitial);
  const determineGradeColor = (grade) => {
    switch (grade) {
      case "F9":
        return "#FF3E1D";
      case "C":
        return "#DEAB28";
      case "B":
        return "#DEAB28";
      default:
        return '#2AAC7E'; 
    }
  };
  // const determineGrade = (percentage) => {
  //   if (percentage <= 40) {
  //     return { color: '#FF3E1D', label: 'F9' };
  //   } else if (percentage > 40 && percentage <= 60) {
  //     return { color: '#DEAB28', label: 'C' };
  //   } else {
  //     return { color: '#2AAC7E', label: 'A1' };
  //   }
  // };

  // const calculatePercentage = (passMark, totalMark) => {
  //   return (passMark / totalMark) * 100;
  // };

  // const percentage = calculatePercentage(passMark, totalMark);
  // const { color: gradeColor, label: calculatedGrade } = determineGrade(percentage);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", pb:1, pr:1 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar sx={{ backgroundColor: lighterColor, color: baseColor, marginRight: 2 }}>
          {subjectInitial}
        </Avatar>
        <Box>
          <Typography
            variant="body1"
            sx={{
              mb: 0.5,
              fontSize: "15px",
              fontWeight: 600,
              color: "#030229",
            }}
          >
            {subjectName}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#888",
              fontSize: "13px",
              fontWeight: 500,
            }}
          >
            {examName}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex"}}>
        <Box sx={{ pr: 3 }}>
          <Typography sx={{fontSize:"13px", color:"#919191"}}>Score</Typography>
          <Typography sx={{ color:"#030229", fontWeight: 600}}>{score}/{total}</Typography>
        </Box>
        <Box>
          <Typography sx={{fontSize:"13px", color:"#919191"}} >Grade</Typography>
          <Typography style={{ color: determineGradeColor(grade), fontWeight:600, }}>{grade}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OneTestPaper;
