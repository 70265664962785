import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import TotalAmountCardBg from "../../../assets/images/TotalAmountBg.png"
import { ReactComponent as UpArrow } from "../../../assets/images/UpArrow.svg"
import { ReactComponent as DownArrow } from "../../../assets/images/DownArrow.svg"
import { ReactComponent as PaymentIcon } from "../../../assets/images/PaymentIcon.svg"
import { ReactComponent as PaymentWave } from "../../../assets/images/PaymentWave.svg"
import { ReactComponent as TotalLicenses } from "../../../assets/images/TotalLicenses.svg"
import { formatCurrency } from '../../../helpers/helper'
import SchoolPayment from '../../../pages/school/payment'
import { Link } from 'react-router-dom'

const TotalAmountCard = ({ schoolSub }) => {

    return (
        <>
            <Box sx={{
                background: `url(${TotalAmountCardBg}) center/cover`,
                borderRadius: "20px",
                border: "1px solid rgba(145, 158, 171, 0.16)",
                padding: "20px",
                color: "#fff",
                height: "100%",
            }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box>
                        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>Total Amount Paid</Typography>
                        <Typography sx={{ fontWeight: 800, fontSize: "32px" }}>{formatCurrency(schoolSub?.totalSubscriptionsAmount ?? 0)}</Typography>
                    </Box>
                    <Box>
                        <PaymentIcon />
                    </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mt={4} >
                    <Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <TotalLicenses />
                            <Typography fontSize={"13px"}>{schoolSub?.totalLicenseNo ?? 0} Total Licenses</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1} mt={1}>
                            <UpArrow />
                            <Typography fontSize={"13px"}>{schoolSub?.totalAllocatedNo ?? 0} Total Allocated Licenses</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1} mt={1}>
                            <DownArrow />
                            <Typography fontSize={"13px"} >{schoolSub?.totalUnallocatedNo ?? 0} Total Unallocated Licenses</Typography>
                        </Box>
                    </Box>
                    <Box pr={1}>
                        <PaymentWave />
                    </Box>

                </Box>
                <Link to="/school/payment">
                    <Button variant='contained' fullWidth

                        sx={{
                            textTransform: "capitalize", bgcolor: "#fff", color: "#40499D", paddingY: "15px", borderRadius: "10px", marginTop: "40px", marginBottom: "0px", "&:hover": {
                                color: "#40499D",
                                bgcolor: "white"
                            },
                        }}>Add New Subscription</Button>
                </Link>
            </Box>




        </>
    )
}

export default TotalAmountCard