import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import doodles from "../../assets/images/doodles.svg";
import { Link } from "react-router-dom";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& > .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  "& .MuiDialogTitle-root": {
    padding: "90px 100px",
    // backgroundColor:'#40499d',
    backgroundImage: `url(${doodles})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(5),
    position: "relative",
  },
}));

export default function PremiumInfoDialog({ open, handleClose, setDisplayVideo, setsecondsLeft, handleTimeOut, selectedQuestionIndex }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "18px" } }}
        maxWidth={"xl"}
      >
        <DialogTitle
          sx={{ m: 0, p: 4, textAlign: "center" }}
          id="customized-dialog-title"
        >
          <Typography variant="h6" fontWeight={"bolder"} fontSize={"32px"}>
            Oops! You're on the trial plan
          </Typography>
          <Typography>
            View our premium plans to get unlimited <br /> access to more
            questions
          </Typography>
          <Link
            style={{
              position: "absolute",
              bottom: 90,
              left: "35%",
              zIndex: 9999,
            }}
            to={"/dashboard/settings?tab=3"}
          >
            <Button
              sx={{
                padding: "15px",
                textTransform: "inherit",
                bgcolor: "#E85050",
                color: "#fff",
                borderRadius: "10px",
                "&:hover": { bgcolor: "#E85050" },
                border: "2px solid #fff",
              }}
            >
              View Flexible Plans & Pricing
            </Button>
          </Link>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#33363F",
            m: 3,
            // color: (theme) => theme.palette.grey[500],
            bgcolor: "#E2E6F3",
            p: 0,
            "&:hover": {
              bgcolor: "#E2E6F3",
            },
            // MuiButtonBase-root-MuiIconButton-root
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {/* {selectedQuestionIndex >= 4 ? "" : 
          <Button
            sx={{ textTransform: "capitalize", boxSizing: "border-box" }}
            variant="text"
            color="brandPrimary"
            onClick={() => {
              setDisplayVideo(true);
              setsecondsLeft(14);
              handleTimeOut();
              document.body.style.position = "fixed";
              document.body.style.top = `-${window.scrollY}px`;
            }}
          >
            or watch a video for 5 free questions
          </Button>
          } */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
