import React from 'react';

const BusyOverlay = ({ busy }) => {
    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: busy ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999 // ensure it appears on top of other content
    };

    const spinnerStyles = {
        border: '4px solid rgba(255, 255, 255, 0.3)',
        borderTop: '4px solid #ffffff',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        animation: 'spin 1s linear infinite'
    };

    return (
        <div style={overlayStyles}>
            <div style={spinnerStyles}></div>
        </div>
    );
};

export default BusyOverlay;
