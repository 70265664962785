import React from "react";
import { BlockMath, InlineMath } from "react-katex";

const KatexRenderer = ({ expression }) => {
  const containsLaTex = (str) => {
    // Use a regular expression to check if LaTex delimiters are present
    const regex = /\\[A-Za-z]+(?:\{[^}]*\}|\[.*?\])*/;
    return regex.test(str);
  };

  const latexRegex = /\\[A-Za-z]+(?:\{[^}]*\}|\[.*?\])*/;

  const renderKaTeX = (str) => {
    if (containsLaTex(str)) {
      // Split the string based on LaTeX expressions
      // const parts = str.split(latexRegex);

      // // Render each part based on whether it matches LaTeX syntax
      // const renderedContent = parts.map((part, index) => {
      //   if (latexRegex.test(part)) {
      //     // Use BlockMath for display mode, InlineMath for inline mode
      //     return <BlockMath key={index} math={part} />;
      //   } else {
      //     return <span key={index}>{part}</span>;
      //   }
      // });

      // return renderedContent;

      return <InlineMath math={str} />;
    } else {
      return <div>{str}</div>;
    }
  };

  return <div>{renderKaTeX(expression[0]?.props?.children)}</div>;
};

export default KatexRenderer;
