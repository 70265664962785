import { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';


export const RTEViewer = ({ ...props }) => {
    // const [value, setValue] = useState('');

    const modules = useMemo(() => ({
        toolbar: false,
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }), [])

    return (
        <ReactQuill theme="bubble" readOnly modules={modules} {...props} />
    )
}