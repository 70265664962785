import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3}}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PricingTabs({ tabs, value, handleChange }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider",fontFamily: "Montserrat",  }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          {tabs?.map((item, i) => (
            <Tab sx={{textTransform:'capitalize', fontWeight:800, fontFamily: "Montserrat",}} key={item?.label} label={item?.label} {...a11yProps(i)} icon={item?.icon} iconPosition="start"/>
          ))}
        </Tabs>
      </Box>

      {tabs?.map((item, i) => (
        <CustomTabPanel key={item?.label} value={value} index={i}>
          {item.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
