import React from "react";
import Avatars from "../Avatars/Avatars";
import { Box } from "@mui/material";

const AvatarImageUpload = ({isPremium, handleAvatarClick, activeId}) => {
  return (
    <Box>
      <Avatars isPremium={isPremium} handleAvatarClick={handleAvatarClick} activeId={activeId} />
    </Box>
  );
};

export default AvatarImageUpload;
