export const ALL_EXAM_YEARS_START = "ALL_EXAM_YEARS_START";
export const ALL_EXAM_YEARS_SUCCESS = "ALL_EXAM_YEARS_SUCCESS";
export const ALL_EXAM_YEARS_FAIL = "ALL_EXAM_YEARS_FAIL";
export const ADD_EXAM_START = "ADD_EXAM_START";
export const ADD_EXAM_SUCCESS = "ADD_EXAM_SUCCESS";
export const ADD_EXAM_FAIL = "ADD_EXAM_FAIL";
export const GET_EXAM_BY_SUBJECT_ID_START = "GET_EXAM_BY_SUBJECT_ID_START";
export const GET_EXAM_BY_SUBJECT_ID_SUCCESS = "GET_EXAM_BY_SUBJECT_ID_SUCCESS";
export const GET_EXAM_BY_SUBJECT_ID_FAIL = "GET_EXAM_BY_SUBJECT_ID_FAIL";
export const ADD_EXAM_QUESTION_START = "ADD_EXAM_QUESTION_START";
export const ADD_EXAM_QUESTION_SUCCESS = "ADD_EXAM_QUESTION_SUCCESS";
export const ADD_EXAM_QUESTION_FAIL = "ADD_EXAM_QUESTION_FAIL";
export const GET_EXAM_QUESTIONS_START = "GET_EXAM_QUESTIONS_START";
export const GET_EXAM_QUESTIONS_SUCCESS = "GET_EXAM_QUESTIONS_SUCCESS";
export const GET_EXAM_QUESTIONS_FAIL = "GET_EXAM_QUESTIONS_FAIL";

export const ALL_SUBJECTS = "ALL_SUBJECTS";
export const ALL_SUBJECTS_START = "ALL_SUBJECTS_START";
export const ALL_SUBJECTS_SUCCESS = "ALL_SUBJECTS_SUCCESS";
export const ALL_SUBJECTS_FAIL = "ALL_SUBJECTS_FAIL";
export const TRADE_SUBJECTS_SUCCESS = "TRADE_SUBJECTS_SUCCESS";
export const TRADE_SUBJECTS_FAIL = "TRADE_SUBJECTS_FAIL";
export const ACADEMIC_SUBJECTS_SUCCESS = "ACADEMIC_SUBJECTS_SUCCESS";
export const ACADEMIC_SUBJECTS_FAIL = "ACADEMIC_SUBJECTS_FAIL";
export const ADD_SUBJECT_START = "ADD_SUBJECT_START";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";
export const ADD_SUBJECT_FAIL = "ADD_SUBJECT_FAIL";
export const GET_SUBJECT_BY_ID_SUCCESS = "GET_SUBJECT_BY_ID_SUCCESS";
export const GET_SUBJECT_BY_ID_START = "GET_SUBJECT_BY_ID_START";
export const GET_SUBJECT_BY_ID_FAIL = "GET_SUBJECT_BY_ID_FAIL";
export const UPDATE_SUBJECT_START = "UPDATE_SUBJECT_START";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_FAIL = "UPDATE_SUBJECT_FAIL";
export const ALL_SUBJECT_EXAMS_START = "ALL_SUBJECT_EXAMS_START";
export const ALL_SUBJECT_EXAMS_SUCCESS = "ALL_SUBJECT_EXAMS_SUCCESS";
export const ALL_SUBJECT_EXAMS_FAIL = "ALL_SUBJECT_EXAMS_FAIL";
export const GET_ALL_EXAM_TYPE = "GET_ALL_EXAM_TYPE";
export const GET_ALL_EXAM_SERIES = "GET_ALL_EXAM_SERIES";
export const GET_EXAM_BY_EXAMID = "GET_EXAM_BY_EXAMID";
export const DELETE_EXAM_SUCCESS = "DELETE_EXAM_SUCCESS";
export const DELETE_EXAM_FAIL = "DELETE_EXAM_FAIL";
export const LOAD_EXAM = "LOAD_EXAM";
export const ALL_SUBJECT_PATHS = "ALL_SUBJECT_PATHS";
export const ADD_AI_SOLUTIONS_SUCCESS = "ADD_AI_SOLUTIONS_SUCCESS";
export const ADD_AI_SOLUTIONS_FAIL = "ADD_AI_SOLUTIONS_FAIL";
export const GET_QUESTION_SECTION_BY_EXAMID = "GET_QUESTION_SECTION_BY_EXAMID";
export const GET_QUESTION_SECTION_BY_EXAMID_SUCCESS =
  "GET_QUESTION_SECTION_BY_EXAMID_SUCCESS";
export const GET_QUESTION_SECTION_BY_EXAMID_FAIL =
  "GET_QUESTION_SECTION_BY_EXAMID_FAIL";
export const ADD_NEWSECTION_SUCCESS = " ADD_NEWSECTION_SUCCESS";
export const ADD_NEWSECTION_FAIL = " ADD_NEWSECTION_FAIL";
export const UPDATE_QUESTIONSECTION_SUCCESS = "UPDATE_QUESTIONSECTION_SUCCESS";
export const UPDATE_QUESTIONSECTION_FAIL = "UPDATE_QUESTIONSECTION_FAIL";
export const DELETE_QUESTIONSECTION_SUCCESS = "DELETE_QUESTIONSECTION_SUCCESS";
export const DELETE_QUESTIONSECTION_FAIL = "DELETE_QUESTIONSECTION_FAIL";
export const GET_TOPIC_BY_SUBJECTID = "GET_TOPIC_BY_SUBJECTID";
export const GET_TOPIC_BY_SUBJECTID_FAILED = "GET_TOPIC_BY_SUBJECTID_FAILED";
export const ADD_TOPIC_LOADING = "ADD_TOPIC_LOADING";
export const ADD_TOPIC_SUCCESS = "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_FAILED = "ADD_TOPIC_FAILED";
