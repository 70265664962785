import React, { useEffect, useState } from "react";
import {
  LeaderLadder,
  LeaderboardHeader,
  LeaderboardTab,
  LeaderboardTable,
} from "../../../components/leaderboard";
import { Box, Stack } from "@mui/material";
import img from "../../../assets/images/Leaderboard.png";
import { useDispatch } from "react-redux";
import { getLeaderboardByLocation } from "../../../redux/gamification/gamificationActions";
import useGamification from "../../../hooks/useGamification";
import useUser from "../../../hooks/useUser";

const styles = {
  backgroundImage: `url(${img})`, // Replace with the actual path to your image
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  position: "relative",
  paddingTop: "paddingTopValue",
};

const Leaderboard = () => {
  const dispatch = useDispatch();
  const { gamificationData, loading } = useGamification();
  const { userInfo } = useUser();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [paddingTopValue, setPaddingTopValue] = useState(null);

  useEffect(() => {
    setPaddingTopValue(isOnline ? "0px" : "30px");
  }, [isOnline]);

  useEffect(() => {
    let payload = {};
    if (activeTab === 0) {
      payload = {
        stateId: userInfo?.stateId,
        countryId: userInfo?.countryId ?? 13,
      };
    } else if (activeTab === 1) {
      payload = {
        countryId: userInfo?.countryId ?? 13,
      };
    } else {
      payload = {};
    }
    dispatch(getLeaderboardByLocation(payload));
  }, [activeTab]);

  useEffect(() => {
    if (gamificationData) {
      setLeaderboardData(gamificationData);
    }
  }, [gamificationData]);

  return (
    <Box sx={styles}>
      <Box component={Stack} direction={"column"}>
        <LeaderboardHeader />
        <Box>
          <LeaderboardTab activeTab={activeTab} setActiveTab={setActiveTab} />
        </Box>
        <LeaderLadder gamificationData={leaderboardData} loading={loading} />
        <LeaderboardTable
          gamificationData={leaderboardData}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Leaderboard;
