import styled from "@emotion/styled";
import { DialogActions, Stepper } from "@mui/material";
import { Box, DialogTitle } from "@mui/material";

export const StyledLeftBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#F5F6F8",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "sticky",
  overflowY: "auto",
  overflowX: "hidden",
  padding: theme.spacing(4),
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  "&::-webkit-scrollbar": {
    width: 5,
    height: 5,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#DCDCDC",
    borderRadius: 2,
  },
}));

export const StyledRightBox = {
  flex: 2,
  padding: "0 20px",
  borderRadius: 20,
  height: "100%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 5,
    height: 5,
    backgroundColor: "red !important",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#DCDCDC",
    borderRadius: 2,
  },
};


export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: "0px",
    background: "#fff",
    zIndex: 1,
    padding: "16px 10px",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#DCDCDC",
      borderRadius: 2,
    },
  }));


  export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: 700,
    position: "sticky",
    bottom: "0px",
    background: "#fff",
    zIndex: 1,
    padding: 3,
  }));




  // Horizontal Tabs 


  // const spin = keyframes`
// "0%": {
//   transform: "rotate(0deg)",
// },
// "100%": {
//   transform: "rotate(360deg)",
// },
// `;

// const bounce = keyframes`
// "0%": {
//   transform: "scale(0)",
// },
// "50%": {
//   transform: "scale(1.2)",
// },
// "100%": {
//   transform: "scale(1)",
// },
// `;
export const StyledStepper = styled(Stepper)({
  ".MuiSvgIcon-root": {
    borderRadius: "50%",
  },
  ".MuiSvgIcon-root.Mui-active": {
    color: "#016c34",
    padding: "3px",
    borderRadius: "50%",
    border: "1px solid #016c34",
    marginY: "-3px",
    fontSize: "30px",
  },

  "& .MuiStepIcon-root": {
    color: "grey",
    position: "relative",
  },
  "& .MuiStepIcon-active": {
    animation: "$spin 1s ease-in-out",
  },
  "& .MuiStepIcon-completed:before": {
    content: '""',
    display: "block",
    width: 16,
    height: 16,
    border: "2px solid #1e88e5",
    borderRadius: "50%",
    position: "absolute",
    top: -4,
    left: -4,
    animation: "$bounce 1s ease-in-out",
  },

  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes bounce": {
    "0%": {
      transform: "scale(0)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
});

export const StyledStepperBox = styled(Box)({
  width: "100%",
  "&.MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": { color: "red" },
})

// MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
//   color: #016C34;
//   border-radius: 20px;
//   outline-offset: 3px;
//   outline: 2px dotted #016c34;

export const customButtonStyle = {
  textTransform: "capitalize",
  background: "#016c34",
  color: "#fff",
  padding: "10px",
  "&:hover": { background: "#016c34", color: "#fff" },
};
export const continueButtonStyle = {
  display: "flex",
  alignItems: "center",
  gap: "2px",
  padding: "2px 18px",
};