// Application types
export const LOADING_AUTH = "LOADING_AUTH";
export const LOADING_LOGOUT = "LOADING_LOGOUT";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const REGISTER_USER = "REGISTER_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const USER_CATEGORY = "USER_CATEGORY";
export const USER_PATH_BY_CATEGORY = "USER_PATH_BY_CATEGORY";
export const DESIRED_GRADE = "DESIRED_GRADE";
export const ONBOARD_USER = "ONBOARD_USER";
export const SET_DATA_FROM_LOCAL_STORAGE = "SET_DATA_FROM_LOCAL_STORAGE";
export const ABOUT_US_ONBOARDING = "ABOUT_US_ONBOARDING";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATES_BY_COUNTRY_ID = "GET_STATE_BY_COUNTRY_ID";
export const GET_SPONSOR_TYPES = "GET_SPONSOR_TYPES";
export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";

export const RESEND_MAIL = "RESEND_MAIL";
