import React, { useState, useRef } from 'react';
import { useEffect } from 'react';

const Player = ({ canProceed }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const videoRef = useRef(null);

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgress = () => {
        const duration = videoRef.current.duration;
        const currentTime = videoRef.current.currentTime;
        const progress = (currentTime / duration) * 100;
        setProgress(progress);
    };

    useEffect(() => {
        if (progress > 95) {
            canProceed(true)
        } else {
            canProceed(false)
        }
    }, [progress])

    return (
        <div className='md:w-[56rem]'>
            <video
                onTimeUpdate={handleProgress}
                ref={videoRef}
                autoPlay
                width="100%"
                height="100%"
                className='w-full'
                controls={process.env.NODE_ENV === 'development'}
            >
                {/* <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4" type="video/mp4" /> */}
                <source src="https://waecstudystore.blob.core.windows.net/video/EdustatAds.mp4" type="video/mp4" />
            </video>
            <div>
                {/* <button onClick={togglePlay}>
                    {isPlaying ? "Pause" : "Play"}
                </button> */}
                <progress value={progress} max="100" className='hidden' />
                <div className="mb-5 h-2 rounded-full bg-gray-200">
                    <div className="h-2 rounded-full bg-orange-500" style={{ width: `${progress}%` }}></div>
                </div>
            </div>
        </div>
    )
}

export default Player;