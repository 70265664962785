import { useSelector } from "react-redux";

const useContentUpload = () => {
  const contentUpload = useSelector((state) => state.contentUpload);
  const {
    loading,
    subjectData,
    tradeSubjectData,
    academicSubjectData,
    examData,
    examQuestionData,
    examYearData,
    examTypeData,
    error,
    examSeriesData,
    singleExamData,
    loadingExam,
    singleSubject,
    subjectPaths,
    questionSection,
    addSection,
    topics
  } = contentUpload;

  return {
    loading,
    subjectData,
    tradeSubjectData,
    academicSubjectData,
    error,
    examData,
    examQuestionData,
    examYearData,
    examTypeData,
    examSeriesData,
    singleExamData,
    loadingExam,
    singleSubject,
    subjectPaths,
    questionSection,
    addSection,
    topics
  };
};

export default useContentUpload;
