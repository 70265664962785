import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AdminSidebar from "../components/sidebars/AdminSidebar";
import { Avatar, Badge, Stack, SwipeableDrawer } from "@mui/material";
import AccountPopover from "../components/dashboards/AccountPopover";
import useUser from "../hooks/useUser";
import useAdminUser from "../hooks/useAdminUser";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "../redux/auth/authActions";
import Preloader from "../components/Preloader/Preloader";
import usePermissions from "../hooks/usePermissions";
import AdminAccountPopover from "../components/dashboards/AdminAccountPopover";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      backgroundColor: "#fafafb",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "white",
  color: "black",
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#E6E6E6",
    color: "#E6E6E6",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid white",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const AdminDashboardLayout = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [drawer, setDrawer] = React.useState(false);
  const adminUser = useAdminUser();
  const { isLoading } = useAdminUser();
  // const {site_page_loading} = usePermissions();

  const userInfo = adminUser?.userInfo?.data;

  const token = useUser()?.token ? useUser()?.token : useAdminUser()?.token;

  React.useEffect(() => {}, []);

  // React.useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Perform your logout or session cleanup logic here
  //     // For example, dispatch a logout action or call an API to invalidate the session
  //     console.log("Logging out user...");
  //     dispatch(logoutUserAction(token));

  //     // Optionally show a confirmation message
  //     const confirmationMessage = "Are you sure you want to leave?";
  //     event.returnValue = confirmationMessage; // Standard for most browsers
  //     return confirmationMessage; // For some older browsers
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const toggleDrawer = (value) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(value);
  };

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            className="w-full lg:w-[100% - 240px] ml-0 lg:-ml-[240px]"
          >
            <Toolbar>
              <div className="flex justify-between items-center w-full">
                {/* <Button onClick={toggleDrawer(true)}>Hello</Button> */}
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon color="#40499D" />
                </IconButton>

                <Stack
                  direction="row"
                  alignItems={"center"}
                  spacing={2}
                  className=""
                >
                  {/* <IconButton aria-label={'notification label'}>
                <Badge badgeContent={4} color="error">
                  <NotificationsNoneIcon color="" />
                </Badge>
              </IconButton> */}
                  {/* <h5 className="e-study-heading-five text-[#40499D]">Admin</h5> */}

                  {/* <div className="flex flex-col ml-3 justify-center"> */}
                  {/* <h3 className="e-study-heading-five">{`${userInfo?.firstname} ${userInfo?.surname}`}</h3> */}
                  <h4 className="text-[#313131]">Admin</h4>
                  {/* </div> */}
                  {/* <AccountPopover /> */}
                  <AdminAccountPopover />
                </Stack>
              </div>
            </Toolbar>
          </AppBar>
          <div className="hidden lg:flex">
            <AdminSidebar open={open} setOpen={setOpen} />
          </div>
          <Main
            open={open}
            style={{ backgroundColor: "#efefef", minHeight: "99.5vh" }}
          >
            <DrawerHeader />

            <section className="w-full">{children}</section>
          </Main>
          <SwipeableDrawer
            anchor={"left"}
            open={drawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <AdminSidebar open={open} setOpen={setOpen} />
          </SwipeableDrawer>
        </Box>
      )}
      ;
    </>
  );
};

export default AdminDashboardLayout;
