import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getCountries,
  getStatesByCountryId,
} from "../../../redux/settings/settingsActions";
import useSettings from "../../../hooks/useSettings";
import { useDispatch } from "react-redux";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function StateAsynchronous({
  disabled,
  updateStateValue,
  updateLgaValue,
  updateSchoolValue,
  stateHandler,
  stateValues,
  label
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { statesData, statesLoaded, statesLoading } = useSettings();
  const loading = open && options.length === 0;
  const [stateSelect, setStateSelect] = React.useState([]);
  const dispatch = useDispatch();

  // This effect populates the country select options
  React.useEffect(() => {
    const createStatesSelect = () => {
      let statesArray = [];

      statesData?.map((state) => {
        let option = { value: state?.stateId, label: state?.stateName };
        return statesArray?.push(option);
      });
      setStateSelect(statesArray);

    };
    statesData?.length !== 0 && createStatesSelect();
  }, [statesData]);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);
      if (active) {
        setOptions(stateSelect);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <Autocomplete
        onKeyDown={handleKeyDown}
        autoHighlight
        id="asynchronous-demo"
        readOnly={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={stateValues || ""}
        onChange={(event, value) => {
          if (value) {
            stateHandler(event, value);
            updateStateValue(value);
            // updateLgaValue({ label: "", value: 0 });
            // updateSchoolValue({ label: "", value: 0 });
          } else {
            updateStateValue({ label: "", value: 0 });
          }
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option?.label || ""}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ? label : "State"}
            InputProps={{
              ...params.InputProps,
              endAdornment: !disabled ? (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ) : (
                ""
              ),
            }}
          />
        )}
      />
    </>
  );
}
