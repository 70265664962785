import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React from "react";
import bullseye from "../../../assets/images/bullseye.gif";
import points from "../../../assets/images/points.gif";
import StatusGenerator from "./StatusGenerator";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  width: 120,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: "#E5E5E5",
    boxShadow: "0px 2.18182px 2.18182px 0px rgba(0, 0, 0, 0.25) inset",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.brandSecondary.yellow
        : "#308fe8",
  },
}));
const WeeklyTasks = ({ item }) => {
  return (
    <Stack
      direction={"row"}
      borderRadius="4px"
      mt={1}
      bgcolor="#40499D"
      p={2}
      boxShadow="0px 7px 10px 4px rgba(0, 0, 0, 0.25)"
      alignItems={"center"}
      gap={3}
    >
      <img
        src={bullseye}
        alt="Tasks"
        style={{
          backgroundColor: "#fff",
          width: "auto",
          height: "60px",
          borderRadius: "50%",
        }}
      />
      <Box width={"250px"}>
        <Typography>{item?.task}</Typography>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Typography>{item?.completion}%</Typography>
          <BorderLinearProgress
            variant="determinate"
            value={item?.completion}
          />
        </Stack>
      </Box>
      <Stack
        sx={{
          borderRadius: "16px",
          background: "#2B3596",
          boxShadow: "0px 0px 16px 3px rgba(255, 255, 255, 0.25)",
          alignItems: "center",
          p: 1,
        }}
      >
        <img
          src={points}
          alt="Points earned"
          style={{ width: "auto", height: "30px" }}
        />
        <Typography fontSize={"12px"}>{item?.points} points</Typography>
      </Stack>
      {item?.status === "inProgress" ? (
        <StatusGenerator statusText="In Progress" variant="yellowFilled" />
      ) : item?.status === "completed" ? (
        <StatusGenerator statusText="Completed" variant="greenFilled" />
      ) : (
        <StatusGenerator statusText="Not Started" variant="redFilled" />
      )}
    </Stack>
  );
};

export default WeeklyTasks;
