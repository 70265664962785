import * as settingsActions from "./types";
import Axios from "../../connection/defaultClient";
import { destroyLastLogin } from "../../helpers/session";

export const ChangePassword = (body) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.CHANGE_PASSWORD,
      payload: body,
    });
    Axios.post(`/Access/ChangePassword`, body)
      .then((response) => {
        dispatch({
          type: settingsActions.CHANGE_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.CHANGE_PASSWORD_FAILURE,
          payload: error.data,
        });
      });
  };
};

// reset super user password
export const ResestSuperSchoolUserPassword = (
  body,
  onSuccess = () => {},
  onFail = () => {}
) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.RESET_PASSWORD_SUPER_SCHOOL_USER,
      payload: body,
    });
    Axios.post(`/Access/ResetPasswordBySchoolAdmin`, body)
      .then((response) => {
        dispatch({
          type: settingsActions.RESET_PASSWORD_SUPER_SCHOOL_USER_SUCCESS,
          payload: response.data,
        });
        if (response?.data?.state == 1) {
          onSuccess(response?.data?.msg);
        }
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.RESET_PASSWORD_SUPER_SCHOOL_USER_FAILURE,
          payload: error.data,
        });
      });
  };
};

// sign out all devices
export const SignOutAllDevices = (
  payLoad,
  onSuccess = () => {},
  onFail = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: settingsActions.LOGGING_OUT_SUPER_USER, payLoad: true });
    Axios.post(`/Access/SignOutAllDevices?schoolId=${payLoad}`)
      .then((res) => {
        dispatch({
          type: settingsActions.LOGGING_OUT_SUPER_USER,
          payLoad: false,
        });
        dispatch({ type: settingsActions.LOGOUT_SUPER_USER, payLoad: {} });
        if (res?.data?.state == 1) {
          onSuccess(res?.data?.msg);
          destroyLastLogin();
        }
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.LOGGING_OUT_SUPER_USER,
          payLoad: false,
        });
        onFail(error);
      });
  };
};

export const ResetPassword = () => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.RESET_CHANGE_PASSWORD,
    });
  };
};

export const getCandidateSubscriptionsByProfileId = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID,
      payload: profileId,
    });
    Axios.get(`/Subscription/GetCandidateSubscriptions/${profileId}`)
      .then((response) => {
        dispatch({
          type: settingsActions.FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.FETCH_CANDIDATE_SUBSCRIPTIONS_PROFILE_ID_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const loadUserProfileDetails = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.FETCH_USER_PROFILE_DETAILS,
      payload: profileId,
    });
    Axios.get(`/Profile/LoadUserProfileDetails?profileId=${profileId}`)
      .then((response) => {
        dispatch({
          type: settingsActions.FETCH_USER_PROFILE_DETAILS_SUCCESS,
          payload: response.data[0],
        });
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.FETCH_USER_PROFILE_DETAILS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateUserProfile = (body, onSuccess) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.UPDATE_USER_PROFILE,
      payload: body,
    });
    Axios.post(`/Profile/UpdateUserProfile`, body)
      .then((response) => {
        dispatch({
          type: settingsActions.UPDATE_USER_PROFILE_SUCCESS,
          payload: response.data,
        });
        if (response?.data?.state === 1) {
          onSuccess(response?.data?.state);
        }
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.UPDATE_USER_PROFILE_FAILURE,
          //   payload: error.message,
        });
      });
  };
};

export const ResetUpdateUserProfile = () => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.RESET_UPDATE_USER_PROFILE,
    });
  };
};

export const getCountries = () => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.GET_COUNTRIES,
    });
    Axios.get(`Settings/GetCountries`)
      .then((response) => {
        dispatch({
          type: settingsActions.GET_COUNTRIES_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.GET_COUNTRIES_SUCCESS,
          payload: error.message,
        });
      });
  };
};

export const getStatesByCountryId = (countryId, stateByCountryFailure) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.GET_STATES_BY_COUNTRY_ID,
      payload: countryId,
    });
    Axios.get(`/Settings/GetStatesByCountryId?countryId=${countryId}`)
      .then((response) => {
        dispatch({
          type: settingsActions.GET_STATES_BY_COUNTRY_ID_SUCCESS,
          payload: response.data.data,
        });
        if (response?.data?.state !== 1) {
          stateByCountryFailure(response?.data?.state);
        }
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.GET_STATES_BY_COUNTRY_ID_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getLgaByStateId = (stateId, lgaByStateFailure) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.GET_LGA_BY_STATE_ID,
      payload: stateId,
    });
    Axios.get(`/Settings/GetLGAByStateId?stateId=${stateId}`)
      .then((response) => {
        dispatch({
          type: settingsActions.GET_LGA_BY_STATE_ID_SUCCESS,
          payload: response.data.data,
        });
        if (response?.data?.state !== 1) {
          lgaByStateFailure(response?.data?.state);
        }
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.GET_LGA_BY_STATE_ID_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getWaecApprovedSchools = (
  countryId,
  stateId,
  lgaId,
  schoolFailure
) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.GET_WAEC_APPROVED_SCHOOLS,
      payload: { countryId, stateId, lgaId },
    });
    Axios.get(
      `/School/GetWAECApprovedSchools?CountryId=${countryId}&StateId=${stateId}&LgaId=${lgaId}`
    )
      .then((response) => {
        dispatch({
          type: settingsActions.GET_WAEC_APPROVED_SCHOOLS_SUCCESS,
          payload: response.data.data,
        });
        if (response?.data?.state !== 1) {
          schoolFailure(response?.data?.state);
        }
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.GET_WAEC_APPROVED_SCHOOLS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const toggleTheme = () => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.UPDATE_THEME,
    });
  };
};

export const fetchProfileLearningStatistics = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: settingsActions.FETCH_PROFILE_LEARNING_STATS,
      payload: profileId,
    });
    Axios.get(`/Profile/ProfileLearningStatistics/${profileId}`)
      .then((response) => {
        dispatch({
          type: settingsActions.FETCH_PROFILE_LEARNING_STATS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: settingsActions.FETCH_PROFILE_LEARNING_STATS_FAILURE,
          payload: error.message,
        });
      });
  };
};
