
// export const TestNumber = styled(Button)(({ theme, answered, flagged, active }) => ({
//     color: answered || flagged || active ? "#fff" : "#848484",
//     padding: "4px 6px",
//     borderRadius: 0,
//     width: "auto",
//     minWidth: 4,
//     borderTopLeftRadius: "12px",
//     textAlign: "center",
//     // backgroundColor: (props) => {
//     //     if (answered) {
//     //         return theme.palette.success.main
//     //     } else {
//     //         return "#ddd"
//     //     }
//     // },
//     // backgroundColor: active ? theme.palette.brandPrimary.main : answered ? theme.palette.success.main : flagged ? theme.palette.error.main : "#ddd",
//     '&:hover': {
//         backgroundColor: "#999",
//         color: "#fff"
//     },
// }));

export const TestNumber = ({ answered, flagged, active, children, onClick, className, ...props }) => {
    const activeClasses = active ? 'bg-[#40499D] text-white' : "bg-[#ddd]";
    const flaggedClasses = flagged ? 'bg-[#aa0000] text-white' : "bg-[#ddd]";
    const answeredClasses = answered ? 'bg-[#006100] text-white' : "bg-[#ddd]";
    return (<button onClick={onClick} {...props} className={`px-2 py-1 w-8 rounded-tl-2xl ${flagged ? flaggedClasses : answered ? answeredClasses : active ? activeClasses : "bg-[#ddd]"} ${className}`}>{children}</button>)
}