import React, { useState, useEffect, useRef } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useUser from "./hooks/useUser"
import { useDispatch } from 'react-redux';
import { logoutUserAction } from './redux/auth/authActions';
import { isTimeInRange } from './helpers/helper';

const LogoutTimer = () => {
  const user = useUser()

  const dispatch = useDispatch()
  const logoutTimeout = useRef(null);
  const lastActivityTime = useRef(Date.now());

  const resetTimer = () => {
    if (logoutTimeout.current) {
      clearTimeout(logoutTimeout.current);
    }
    logoutTimeout.current = setTimeout(logoutUser, 60 * 60 * 1000); // 1 hour
  };

  const handleActivity = () => {
    lastActivityTime.current = Date.now();
    resetTimer();
  };

  const logoutUser = () => {
    // Implement logout logic here
    dispatch(logoutUserAction(user?.token))
  };

  const handleClick = () => {
    handleActivity();
  };

  const handleScroll = () => {
    handleActivity();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('scroll', handleScroll);

    resetTimer();

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('scroll', handleScroll);
      clearTimeout(logoutTimeout.current);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastActivityTime.current > 60 * 60 * 1000) { // 30 minutes
        logoutUser();
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const startHour = 8; // 8 AM
    const endHour = 18; // 6 PM
    if (user?.userInfo?.isSuperSchoolAdmin && !user?.userInfo?.isSchoolAdmin && !isTimeInRange(startHour, endHour)) {
      logoutUser();
    }
  }, [user?.userInfo?.isSuperSchoolAdmin]);

  return null; // This component doesn't render anything visible
};

const ProtectedRoute = () => {

  // Check for user login token and use that to set isAuthenticated field
  const user = useUser()

  if (user.token) {
    return (
      <>
        <Outlet />
        <LogoutTimer />
      </>
    )

  } else {
    return <Navigate to="/auth/login" />;
  }
};

export default ProtectedRoute;
