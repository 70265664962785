import { useSelector } from "react-redux";

const useTest = () => {
  const test = useSelector((state) => state.test);

  const {
    isLoading,
    testData,
    error,
    currentQuestion,
    testName,
    allocatedTime,
    questions,
    subjectsData,
    subjectLoaded,
    subjectLoading,
    subjectExams,
    subjectExamsLoaded,
    isLoaded,
    isTestActive,
    examResults,
    totalScore,
    statsLoaded,
    statsData,
    examWithHighestScoreLoaded,
    examWithHighestScoreLoading,
    examWithHighestScore,
    activeSection,
  } = test;

  return {
    isLoading,
    testData,
    error,
    currentQuestion,
    testName,
    allocatedTime,
    questions,
    subjectsData,
    subjectLoaded,
    subjectLoading,
    subjectExams,
    subjectExamsLoaded,
    isLoaded,
    isTestActive,
    examResults,
    totalScore,
    statsLoaded,
    statsData,
    examWithHighestScoreLoaded,
    examWithHighestScoreLoading,
    examWithHighestScore,
    activeSection,
  };
};

export default useTest;
