import React from "react";
import { Container, Typography, Button } from "@mui/material";
import emailGIF from "./emailCheck.gif";
import { useNavigate } from "react-router-dom";

const PasswordResetMsg = ({email, forgotPassword = true}) => {
  const navigate = useNavigate();
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emailGIF} alt="Confirmation GIF" style={{ width: "30%" }} />

      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "15px",
          mb: 1,
          color: "#1f1f1f",
          textAlign: "center",
        }}
      >
        We sent a {forgotPassword ? 'password reset link' : 'verification link'} to{" "}
        <span style={{ fontWeight: 600 }}>{email}</span> to get you
        back into your acccount.
      </Typography>

    </Container>
  );
};

export default PasswordResetMsg;
