import {
  AppBar,
  Container,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import bullseye from "../../assets/images/bullseye.svg";
import coin from "../../assets/images/coin.svg";
import { LeaderboardTimer } from "./LeaderboardTimer";

export const LeaderboardHeader = () => {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          component={Stack}
          direction={"row"}
          justifyContent={"space-between"}
          disableGutters
        >
          <Stack gap alignItems={"center"} direction={"row"}>
            <Stack gap alignItems={"center"} direction={"row"}>
              <img
                src={bullseye}
                className="bg-white rounded-full p-0 size-6"
                alt=""
              />
              <Typography
                pl={"1px"}
                pr={"15px"}
                variant="body1"
                fontWeight={"700"}
                component={"span"}
                color="white"
              >
                2 Test Completed
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              sx={{ borderColor: "white", height: "1.5rem", opacity: "50%" }}
            />
            <Stack
              alignItems={"center"}
              direction={"row"}
              position={{ left: "16px" }}
            >
              <img src={coin} className="relative bottom-px size-10" alt="" />
              <Typography
                variant="body1"
                fontWeight={"700"}
                component={"span"}
                color="white"
              >
                100 points
              </Typography>
            </Stack>
          </Stack>
          <LeaderboardTimer />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
