import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, Paper, Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import UserMetricsActionButton from "./userMetricsActionButton";
import { formatDate, simpleDate } from "../../../helpers/helper";

const UserMetricsDataTable = ({ userMetricsData, filters, setToastState }) => {
  const columns = [
    {
      name: "S/N",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "name",
      label: <p className="truncate">Name</p>,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: <p className="truncate">Email</p>,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "gsmNumber",
      label: <p className="truncate">Phone No</p>,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "state",
      label: <p className="truncate"> State</p>,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) =>
          value === undefined || value === "" || value === null ? "N/A" : value,
      },
    },
    {
      name: "dateCreated",
      label: <p className="truncate">Date Registered</p>,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) =>
          value === undefined || value === ""
            ? "not updated"
            : simpleDate(value),
      },
    },

    // {
    //   name: "takenTest",
    //   label: <p className="w-[70px] truncate">Taken Test</p>,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) =>
    //       value === undefined || value === false
    //         ? "No"
    //         : value === true
    //         ? "Yes"
    //         : value,
    //   },
    // },
    // {
    //   name: "usedStudy",
    //   label: <p className="w-[70px] truncate">Used Study</p>,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) =>
    //       value === undefined || value === false
    //         ? "No"
    //         : value === true
    //         ? "Yes"
    //         : value,
    //   },
    // },
    // {
    //   name: "studyMostOpenedSubject",
    //   label: <p className="w-[70px] truncate">Most Opened Subject (Study)</p>,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) =>
    //       value === undefined || value === "" || value === null ? "N/A" : value,
    //   },
    // },
    // {
    //   name: "testMostOpenedSubject",
    //   label: <p className="w-[70px] truncate">Most Opened Subject (Test)</p>,
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) =>
    //       value === undefined || value === "" || value === null ? "N/A" : value,
    //   },
    // },
    {
      name: "profileId",
      label: "ACTION",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta.rowData;
          const profileId = rowData[6];

          return (
            <UserMetricsActionButton
              profileId={profileId}
              filters={filters}
              setToastState={setToastState}
            />
          );
        },
      },
    },
  ];

  const options = {
    filterType: "none",
    responsive: "standard",
    selectableRows: "none",
  };

  return (
    <>
      <Grid
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: 1 }}
        xs={12}
      >
        <MUIDataTable
          title={"User Metrics"}
          data={userMetricsData}
          columns={columns}
          options={options}
        />
      </Grid>
    </>
  );
};

export default UserMetricsDataTable;
