import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Dialog, DialogContent, IconButton, Tooltip, Typography, styled } from "@mui/material";
import moment from "moment";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import RefreshIcon from '@mui/icons-material/Refresh';
import ConfirmPaymentStatus from "../../../../Payment/ConfirmPaymentStatus";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import useUser from "../../../../../hooks/useUser";
import { fetchSponsorSubscriptions } from "../../../../../redux/subscriptions";
import { formatCurrency, isPastDate } from "../../../../../helpers/helper";


function createData(sn, transId, plan, price, noOfLicenses, transactionDate, expiryDate, status) {
  return { sn, transId, plan, price, noOfLicenses, transactionDate, expiryDate, status };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& > .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  // "& .MuiDialogTitle-root": {
  //   backgroundColor: "#FFD14A",
  //   height: "1px",
  //   borderRadius: "16.9px 16.9px 0px 0px",
  //   color: "#fff",
  // },
  "& .MuiDialogContent-root": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    position: "relative",
  },
}));


export default function SponsorPricingTable({ sub }) {
  const [billingHistory, setBillingHistory] = React.useState([]);
  const [openConfirmStatusDialog, setOpenConfirmStatusDialog] = React.useState(false);
  const [transactionId, setTransactionId] = React.useState(0);
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { sponsorId } = userInfo;

  React.useEffect(() => {

    if (Array.isArray(sub) && sub?.length !== 0) {
      const rows = sub?.map((item, i) => (
        createData(++i, item?.Trans_Id, item?.SubscriptionName, formatCurrency(item?.Trans_Amt), item?.Licenses, moment(item?.trans_Date).format("MMM Do, YYYY"), moment(item?.ExpiryDate).format("MMM Do, YYYY"), `${item?.PaymentStatus}`,)))
      setBillingHistory(rows);
      // console.log(sub)
    }

  }, [sub])

  const handleActionClick = (transactionId) => {
    setTransactionId(transactionId);
  }

  const handleClose = () => {
    setOpenConfirmStatusDialog(false);
  }

  const dispatchActions = () => {
    dispatch(fetchSponsorSubscriptions(sponsorId));
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Box p={2}>
          <Typography fontWeight={700} variant="h6">
            Billing History
          </Typography>
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell align="right">Transaction ID</TableCell>
              <TableCell align="right">Subscription Plan</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">No of Licenses </TableCell>
              <TableCell align="right">Transaction Date</TableCell>
              <TableCell align="right">Expiry Date</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          {billingHistory?.length !== 0 ?
            <TableBody>
              {billingHistory?.map((row) => (
                <TableRow
                  key={row.sn}
                  sx={{ "&:last-child td, &:last-child th": { border: "none" } }}
                >
                  <TableCell component="th" scope="row">
                    {row.sn}
                  </TableCell>
                  <TableCell align="right">{row.transId}</TableCell>
                  <TableCell align="right">{row.plan}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                  <TableCell align="right">{row.noOfLicenses}</TableCell>
                  <TableCell align="right">{row.transactionDate}</TableCell>
                  <TableCell align="right" style={{ color: isPastDate(moment(row.expiryDate, "MMM Do, YYYY").format("YYYY-MM-DDTHH:mm:ss")) ? 'red' : 'black' }}>{row.expiryDate}</TableCell>
                  <TableCell align="right" sx={{
                    color: row?.status?.toLowerCase() === "approved" ? "green" : row?.status?.toLowerCase() === "declined"
                      ? "red" : "grey"
                  }}>{row?.status?.toLowerCase()}</TableCell>
                  <TableCell align="right" >
                    {(row?.status.toLowerCase() === "approved" || row?.status?.toLowerCase() === "declined") ? null :
                      <Tooltip title="Query Payment">
                        <IconButton color="primary" aria-label="request"
                          onClick={() => {
                            setOpenConfirmStatusDialog(true);
                            handleActionClick(row.transId)
                          }}
                        >
                          <RefreshIcon sx={{ color: "#ffd84d" }} />
                        </IconButton>
                      </Tooltip>
                    }
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
            : <TableBody sx={{ p: 3, justifyContent: 'center', display: 'flex', width: '100%' }}>
              <TableRow sx={{ width: '100%' }}>
                No History Available
              </TableRow>
            </TableBody>}
        </Table>
      </TableContainer>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirmStatusDialog}
        PaperProps={{ sx: { borderRadius: "18px", width: "35%" } }}
        maxWidth={"xl"}
      >
        <DialogContent>
          <ConfirmPaymentStatus
            TransId={transactionId}
            onClose={handleClose}
            actions={dispatchActions}
          />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#b4b4b4",
              m: 3,
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
