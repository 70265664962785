import React, { useEffect } from 'react'
import { getTopicsBySubjectId } from "../../../../redux/contentUpload/contentUploadActions";
import useContentUpload from "../../../../hooks/useContentUpload";
import { useDispatch } from 'react-redux';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { LockIcon } from '../../../../assets/icons/Icons';
import useSubscription from '../../../../hooks/useSubscription';

const SkeletonRow = () => (
  <Box display="flex" justifyContent={"space-between"} sx={{ mb: 2, mt: 2, maxWidth: 700, maxHeight: 3000 }}>
    <Skeleton variant="rounded" width={"40%"} height={30} animation={"wave"} />
    <Skeleton variant="rounded" width={"10%"} height={30} animation={"wave"} />
  </Box>
);

const StudySubjectTopics = ({ subjectId, handleShowTopicQuestions, setTopic }) => {
  const learning = useContentUpload();
  const dispatch = useDispatch();
  const { checkSub } = useSubscription();

  const { topics, loading } = learning;

  useEffect(() => {
    if (subjectId) {
      dispatch(getTopicsBySubjectId(subjectId));
    }
  }, [subjectId])

  // const handleShowQuestions = async (exam) => {
  //   setStartedSubjectId(exam.subjectId);
  //   setExamInfo(exam);
  //   setExamQuestionId(null);
  //   dispatch(
  //     fetchExamSubject(
  //       {
  //         ExamId: exam.examId, //  the year of the exam
  //       },
  //       (res) => setExamQuestionId(res?.testQuestion[0]?.examQuestionId)
  //     )
  //   );

  //   setInput({ ...input, examId: exam.examId });
  //   dispatch(fetchSubmitPendingStatus({ examId: exam.examId, profileId: userInfo?.profileId }, onSuccess, onFail, on500Fail, setSubjectIdforTime));
  //   dispatch(fetchGetLastViewedQuestion({ ExamId: exam.examId, ProfileId: userInfo?.profileId }, onSuccess, onFail, on500Fail));
  //   trackSpecificEvent("study_started", {
  //     subject_name: examInfo?.subjectName,
  //     exam_name: examInfo.examName,
  //     mode: isTrial ? "Trial" : "Paid",
  //   });
  // };


  return (
    <div>
      {loading ? (
        <>
          {[...Array(8)].map((_, index) => (
            <SkeletonRow key={index} />
          ))}
        </>
      ) : topics !== null && topics?.length !== 0 ? (
        topics?.map((topic, index) => (
          <div className="relative">
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

                marginBottom: "1rem", // Add margin at the bottom to create space between exam pairs
              }}
            >
              <div>
                <Typography
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      // textDecoration: "underline",
                      color: "#40499D",
                      fontWeight: 500,
                    },
                  }}
                  onClick={() => {
                    setTopic(topic);
                    handleShowTopicQuestions(topic);
                  }}
                >
                  {topic?.TopicName}
                </Typography>{" "}
              </div>
              <Button
                size="small"
                variant="contained"
                color="brandPrimary"
                sx={{
                  color: "#AFAFAF",
                  "&:hover": {
                    color: "#fff",
                  },
                }}
                onClick={() => {
                  setTopic(topic);
                  handleShowTopicQuestions(topic);
                }}
              >
                Start
              </Button>
            </div>
            {checkSub?.msg === "InActive" && index > 0 && (
              <div className="absolute bg-[#313131] opacity-80 inset-0 flex justify-center items-center">
                <LockIcon style={{ color: "#FFF", fontSize: "40px" }} />
              </div>
            )}
          </div>
        ))
      ) : (
        <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-[#A3AED0] " style={{ padding: "20px" }}>
          No Paper Available
        </p>
      )}
    </div>
  )
}

export default StudySubjectTopics