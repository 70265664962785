import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  Divider,
  Radio,
  Stack,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  RadioGroup,
  FormLabel,
  NativeSelect,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  addExamQuestionAction,
  addTheoryExamQuestionAction,
  fetchExamQuestionsAction,
  updateExamQuestionAction,
  updateTheoryExamQuestionAction,
} from "../../../redux/contentUpload/contentUploadActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../../components/notifications/notifications";

import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  createFromBlockArray,
  ContentState,
  createWithContent,
} from "draft-js";
import { RTEditor } from "../../../components/RTEditor";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useAdminUser from "../../../hooks/useAdminUser";
import { useEffect } from "react";
import useContentUpload from "../../../hooks/useContentUpload";

const ContentUploadTheoryInputEdit = ({
  examQuestion,
  setEditTheory,
  setToastState,
}) => {
  const { loadingExam, questionSection } = useContentUpload();
  const [question, setEditorStateQuestion] = React.useState(examQuestion?.question);
  const [answer1, setEditorStateAnswer1] = React.useState(examQuestion?.answer);
  const [correctAnswerScore, setCorrectAnswerScore] = React.useState(0);
  // const [inCorrectAnswerScore, setInCorrectAnswerScore] = React.useState(0);

  const [answer, setAnswer] = React.useState("0");

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();

  useEffect(() => {
    if (examQuestion) {
      setCorrectAnswerScore(parseInt(examQuestion?.scoreWeight));
    }
  }, []);


  const onSuccess = (res) => {
    // setInput({ ...input, examSetupState: 2 });
    setEditTheory(false);
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Question updated successfully",
    });
  };

  const handleSubmit = () => {
    const data = {
      question: question,
      // choices: "|||" + draftToHtml(convertToRaw(answer1.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer2.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer3.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer4.getCurrentContent())) + "|||" + draftToHtml(convertToRaw(answer5.getCurrentContent())),
      answer: answer1,
      scoreWeight: correctAnswerScore,
      examId: examQuestion?.examId,
      profileId: userInfo?.data?.profileId,
      examQuestionId: examQuestion?.examQuestionId,
      sectionId:selectedSectionId
    };
    dispatch(updateTheoryExamQuestionAction(data, (res) => onSuccess(res)));
  };

  ///
  const [selectedSectionId, setSelectedSectionId] = useState(
    examQuestion?.sectionId
  );
  const handleSelectChange = (e) => {
    setSelectedSectionId(e.target.value);
    // localStorage.setItem("selectedSectionId", selectedId);
  };

  ////

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "28px",
          }}
        >
          Questions manager
        </Typography>
        <span
          className="e-study-primary underline cursor-pointer"
          onClick={() => setEditTheory(false)}
        >
          View all questions
        </span>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Paper sx={{ minWidth: 275, borderRadius: "16px" }}>
          <CardContent>
            <Box>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "27.24px",
                }}
              >
                Question {examQuestion?.questionNumber}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, width: "95%", mx: "auto" }}>
              <Box sx={{ mt: 2, mx: "auto" }}>
                <RTEditor
                  theme={"snow"}
                  value={question}
                  key="questionAdd"
                  setValue={setEditorStateQuestion}
                />
              </Box>


              {/* Section */}

              <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    mt: 1,
                    color: "#000",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                  }}
                >
                  Question section
                </Typography>
              </Stack>
              <Stack mb={3}>
                <NativeSelect
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedSectionId}
                  onChange={handleSelectChange}
                  fullWidth
                >
                  <option value="0">Select</option>
                  {questionSection?.map((type) => (
                    <option
                      sx={{ ml: 3 }}
                      value={type.sectionId}
                      key={type?.sectionId}
                    >
                      {type?.sectionName}{" "}
                    </option>
                  ))}
                </NativeSelect>
              </Stack>


              {/* Section */}

              <Box sx={{ mt: 5, width: "90%" }}>
                <Typography>Answers</Typography>
                <Typography>
                  Enter all possible correct answers for the theory question
                </Typography>
              </Box>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <RTEditor
                  theme={"snow"}
                  key={"answer1add"}
                  value={answer1}
                  setValue={setEditorStateAnswer1}
                />
              </Stack>
              <Box sx={{ mt: 5 }}>
                <Typography>Score setting</Typography>
                <Typography>
                  Specify the points for a correct answer.
                </Typography>
              </Box>

              <Box
                sx={{ mt: 5 }}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                  <InputLabel>Points for correct answer</InputLabel>
                  <TextField
                    type="number"
                    aria-describedby="my-helper-text"
                    value={correctAnswerScore}
                    onChange={(e) => setCorrectAnswerScore(e.target.value)}
                  />
                </Stack>
                <Button
                  sx={{
                    backgroundColor: "#40499D",
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
                {/* <Grid item xs={6}>
                    <InputLabel >Points for incorrect answer</InputLabel>
                    <OutlinedInput type="number" on aria-describedby="my-helper-text"
                      //onChange={(e) => setCorrectAnswer(e.target.value)}
                      onChange={(e) => setInCorrectAnswerScore(e.target.value)}
                    />
                    <Button sx={{ textTransform: 'none', backgroundColor: '#F1F1F1', mt: 1, color: '#000' }}> <AlertCircleIcon /> Number of points can be zero or negative</Button>
                  </Grid> */}
              </Box>
            </Box>
          </CardContent>
        </Paper>
      </Box>
    </>
  );
};

export default ContentUploadTheoryInputEdit;
