import React, { useState } from "react";
import WeeklyMissionDialog from "./WeeklyMissionDialog";

const testData = [
  {
    task: "Play this week trivia",
    completion: 10,
    points: 10,
    status: "notStarted",
  },
  {
    task: "Complete 2 test examinations",
    completion: 50,
    points: 10,
    status: "inProgress",
  },
  {
    task: "Spend 30 minutes studying",
    completion: 100,
    points: 10,
    status: "completed",
  },
  {
    task: "Refer one friend to WAEC Study Hub",
    completion: 0,
    points: 10,
    status: "notStarted",
  },
  {
    task: "Complete your profile",
    completion: 30,
    points: 10,
    status: "inProgress",
  },
  {
    task: "Unlock Leaderboard",
    completion: 0,
    points: 10,
    status: "inProgress",
  },
];
const WeeklyMission = ({ open, handleClose, timeRemaining }) => {
  const [selectedTask, setSelectedTask] = useState(false);

  const handleSelectedTask = () => {
    setSelectedTask(true);
  };
  return (
    <WeeklyMissionDialog
      open={open}
      handleClose={handleClose}
      data={testData}
      timeRemaining={timeRemaining}
    />
  );
};

export default WeeklyMission;
