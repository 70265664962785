import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as TotalIcon } from "../../assets/images/TotalIcon.svg";
import { ReactComponent as UpArrowIcon } from "../../assets/images/UpArrow.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/images/DownArrow.svg";
import useUser from "../../hooks/useUser";
import { formatCurrency } from "../../helpers/helper";

import NavTabs from "./NavTabs";
import PreSub from "./Subscriptions/preSub";
import StudentPricingTable from "./Subscriptions/studentPricingPage";

const BillingHistory = ({ newSubscription, packages }) => {
  const {
    userInfo: { profileId, categoryName },
  } = useUser();
  const [value, setValue] = useState(0);
  const [studentSub, setStudentSub] = useState([]);
  const [sponsoredSub, setSponsoredSub] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (packages && Array.isArray(packages)) {
      const activeSubscriptions = packages?.filter(
        (subscription) => subscription.SubscriptionStatus === "Active"
      );

      const studentSub = activeSubscriptions.filter(
        (subscription) => subscription.isSelfSubscribed == "true"
      );
      setStudentSub(studentSub);

      const sponsoredSub = activeSubscriptions.filter(
        (subscription) => subscription.isSelfSubscribed != "true"
      );
      setSponsoredSub(sponsoredSub);
    }
  }, [packages]);

  const tabs = [
    {
      label: "My Active Subscriptions",
      content: <PreSub newSubscription={newSubscription} studentSubDetails={studentSub} />,
    },
    {
      label: "Sponsored Subscriptions",
      content: <PreSub newSubscription={newSubscription} studentSubDetails={sponsoredSub} />,
    },
  ];
  return (
    <>
      {/* <Box
        sx={{
          borderRadius: "15px",
          border: "1px solid #DFDFDF",
          bgcolor: "white",
          mb: 2,
        }}
      >
        <Box
          p={2}
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography sx={{ color: "#919191", fontSize: "14px" }}>
              Total amount paid (₦)
            </Typography>
            <Typography
              sx={{
                color: "#313131",
                fontSize: "28px",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              {packages?.Amount}
            </Typography>
          </Box>
          <Box display={"flex"} gap={3} alignItems={"center"}>
            <Button
              variant="contained"
              sx={{
                textTransform: "inherit",
                borderColor: "#656565",
                color: "#fff",
                paddingX: "20px",
              }}
              onClick={newSubscription}
            >
              Add New Subscription
            </Button>
          </Box>
        </Box>

        <Box pl={2} pb={2} display={"flex"} gap={4} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <TotalIcon />
            <Typography sx={{ fontWeight: 600 }}>
              {packages.totalLicenseNo} Total Licenses
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <UpArrowIcon />
            <Typography sx={{ fontWeight: 600 }}>
              {packages.totalAllocatedNo} Total Allocated Licenses
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <DownArrowIcon />
            <Typography sx={{ fontWeight: 600 }}>
              {packages.totalUnallocatedNo} Total Unallocated Licenses
            </Typography>
          </Box>
        </Box>
      </Box> */}
      <Box
        sx={{
          borderRadius: "15px",
          border: "1px solid #DFDFDF",
          bgcolor: "white",
          mb: 2,
        }}
      >
        <Box pt={4}>
          <NavTabs value={value} handleChange={handleChange} tabs={tabs} />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          borderRadius: "15px",
          border: "1px solid #DFDFDF",
          bgcolor: "white",
        }}
      >
        <StudentPricingTable sub={packages} newSubscription={newSubscription} />
      </Box>
    </>
  );
};

export default BillingHistory;
