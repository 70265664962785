import React, { useState, useEffect } from "react";
import { FilterIcon, PlusIcon } from "../../../assets/icons/Icons";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Badge,
  Typography,
  Fab,
} from "@mui/material";

import SearchBox from "../../../components/Test/SearchBox";
import { useDispatch } from "react-redux";
import SessionTable from "./sessionTable";
import SessionModal from "./sessionModal";
import useSession from "../../../hooks/useSession";
import { fetchManageSession, fetchUpdateSession } from "../../../redux/session";
import { useNotifications } from "../../../components/notifications/notifications";

const ManageSession = () => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const session = useSession();
  const { allSession, sessionUpdate } = session;
  const [mode, setMode] = useState("add");
  const [tableMeta, setTableMeta] = React.useState(null);
  const [ToastState, setToastState] = useNotifications();
  const [allSessions, setAllsessions] = React.useState([]);
  const [SessionId, setSessionId] = React.useState(0);

  useEffect(() => {
    dispatch(fetchManageSession());
  }, []);

  useEffect(() => {
    viewSession();
  }, []);

  const viewSession = () => {
    let tableData = [];
    allSession.map((val) => {
      let row = [
        "",
        `${val.SessionName}`,
        `${val.StartDate}`,
        `${val.EndDate}`,
        `${val.IsActive}`,
        val.SessionId,
      ];
      tableData.push(row);
    });
    setAllsessions(tableData);
  };

  const openModal = (mode, tableMeta) => {
    setOpen(true);
    setMode(mode);
    setTableMeta(tableMeta);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleSearch = (value) => {
    setSearchValue(value);

    if (value === "") {
    } else {
    }
  };
  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div>
      <div>
        <div className="flex items-center space-x-4">
          {/* <h1 className="e-study-heading-four e-study-school-black">
            Manage Session
          </h1> */}
           <Typography variant="h6" fontWeight={"bold"}>
           Manage Session
          </Typography>
        </div>
      </div>
      <div className="mt-6">
        <Grid container xs={12} spacing={1}>
          <Grid item xs={6}>
            <SearchBox
              placeholder={"session"}
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
              searchIconColor={"#1347F5"}
              bg={"rgba(244, 247, 254, 1)"}
            />
          </Grid>
          <Grid item xs={2}>
            <Box
              // m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "#40499d",
                  backgroundColor: "#F5F6FF",
                  border: "none",
                  padding: "15px 20px",
                  "&:hover": {
                    border: "none",
                  },
                }}
                startIcon={<FilterIcon />}
                endIcon={
                  <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "#EAECFF",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWe fontSize={"10px"}>
                      4
                    </Typography>
                  </Box>
                }
              >
                Filter
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#40499D",
                  borderRadius: 2,
                  textTransform: "none",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#333a7d",
                  },
                }}
                onClick={() => openModal("add")}
                startIcon={<PlusIcon />}
              >
                Add Session
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>

      <div className="mt-6 overflow-x-hidden">
        <SessionTable
          openEdit={open}
          openEditModal={openModal}
          allSessions={allSessions}
          openEdi={setOpen}
        />
        <SessionModal
          open={open}
          closeModal={handleCloseModal}
          modal={openModal}
          mode={mode}
          setToastState={setToastState}
          tableMeta={tableMeta}
          SessionId={SessionId}
          setSessionId={setSessionId}
        />
      </div>
      <ToastState />
    </div>
  );
};

export default ManageSession;
