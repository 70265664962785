import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Box,
  IconButton,
  LinearProgress,
  Skeleton,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { ReactComponent as BestSubjectIcon } from "../../../../assets/images/BestSubject.svg";
import { ReactComponent as MathematicsIcon } from "../../../../assets/images/MathematicsIcon.svg";
import { ReactComponent as BiologyIcon } from "../../../../assets/images/BiologyIcon.svg";
import { ReactComponent as EnglishIcon } from "../../../../assets/images/EnglishIcon.svg";
import { ReactComponent as PhysicsIcon } from "../../../../assets/images/PhysicsIcon.svg";
import { ReactComponent as AccountingIcon } from "../../../../assets/images/AccountingIcon.svg";
import { ReactComponent as ChemistryIcon } from "../../../../assets/images/ChemistryIcon.svg";
import { ReactComponent as EconomicsIcon } from "../../../../assets/images/EconomicsIcon.svg";
import { ReactComponent as GenericIcon } from "../../../../assets/images/GenericIcon.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { fetchStudentLearning } from "../../../../redux/dashboard/dashboardActions";
import useUser from "../../../../hooks/useUser";
import useDashboard from "../../../../hooks/useDashboard";
import { useDispatch } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E5E5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      value <= 39 ? "#FFAC9E" : value <= 69 ? "#FED402" : "#40499D",
  },
}));

const myLearningCards = [
  {
    subject: "Mathematics",
    paperRemaining: 10,
    percent: 20,
  },
  {
    subject: "English",
    paperRemaining: 10,
    percent: 90,
  },
  {
    subject: "Biology",
    paperRemaining: 10,
    percent: 43,
  },
  {
    subject: "Physics",
    paperRemaining: 10,
    percent: 20,
  },
  {
    subject: "Literature",
    paperRemaining: 10,
    percent: 20,
  },
  {
    subject: "Civic Education",
    paperRemaining: 10,
    percent: 20,
  },
];

const LearningCards = () => {
  const subjectIcons = {
    "General Mathematics": <MathematicsIcon />,
    Biology: <BiologyIcon />,
    "English Language": <EnglishIcon />,
    Physics: <PhysicsIcon />,
    Accounting: <AccountingIcon />,
    Economics: <EconomicsIcon />,
    Chemistry: <ChemistryIcon />,
  };
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { isLoadingLearning, dashboardStudentLearning } = useDashboard();
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [totalSlides, setTotalSlides] = React.useState(myLearningCards.length);
  const [isNextDisabled, setIsNextDisabled] = React.useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = React.useState(true);

  const handleNext = () => {
    if (sliderRef.current && currentSlide < totalSlides - 1) {
      sliderRef.current.slickGoTo(currentSlide + 1);
    }
  };

  const handlePrev = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickGoTo(currentSlide - 1);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: (index) => {
      setCurrentSlide(index);
      updateButtonState();
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudentLearning(userProfileId));
      console.log(dashboardStudentLearning, "learning");
    }
  }, [userInfo]);

  useEffect(() => {
    setTotalSlides(myLearningCards.length);
    updateButtonState();
  }, [myLearningCards, currentSlide, totalSlides]);

  const updateButtonState = () => {
    const slider = sliderRef.current;

    if (slider) {
      setIsNextDisabled(
        currentSlide === totalSlides - 1 ||
          slider.innerSlider.state.currentSlide +
            slider.innerSlider.state.slidesToShow >=
            totalSlides
      );
      setIsPrevDisabled(
        currentSlide === 0 || slider.innerSlider.state.currentSlide === 0
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontWeight: 500, color: "#191635", fontSize: "18px", mb: 1 }}
        >
          My Learning
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton
            aria-label="left"
            onClick={handlePrev}
            sx={{ backgroundColor: "#919191", color: "white" }}
            disabled={isPrevDisabled}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            aria-label="right"
            onClick={handleNext}
            sx={{ backgroundColor: "#919191", color: "white" }}
            disabled={isNextDisabled}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
      <Box overflowY={"hidden"} boxSizing={"border-box"}>
        {isLoadingLearning ? (
          <Box display={"flex"} justifyContent={"space-between"}>
          <Skeleton variant="text" width={"32%"} height={200} animation="wave" />
          <Skeleton variant="text" width={"32%"} height={200} animation="wave" />
          <Skeleton variant="text" width={"32%"} height={200} animation="wave" />
          </Box>
        ) : Array.isArray(dashboardStudentLearning) && dashboardStudentLearning?.length > 0 ? (
          <Slider ref={sliderRef} {...settings}>
            {dashboardStudentLearning?.map((data, index) => (
              <Box
                key={index}
                sx={{
                  mt: 1,
                  border: "1px solid var(--Lightbg, #EAECFF)",
                  padding: "15px 15px 15px 10px",
                  borderRadius: "10px",
                  background: "#FFF",
                  boxShadow: "0px 6.745px 36.42px 0px rgba(128, 144, 155, 0.05)",
                  height: "150px"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {subjectIcons[data.subjectName] ? (
                    subjectIcons[data.subjectName]
                  ) : (
                    <GenericIcon />
                  )}
                  <Box>
                    <Typography
                      variant="h3"
                      gutterBottom
                      sx={{
                        fontSize: "15px",
                        fontWeight: 700,
                        color: "#030229",
                      }}
                    >
                      {data.subjectName}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontSize: "13px", color: "#4d4d4d" }}
                    >
                      {data.totalSubject} past questions read
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ my: 2, display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mt: 2 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={data.averageScore}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    color="text.secondary"
                    pl={2}
                  >
                    {`${Math.round(data.averageScore)}%`}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        ) : (
          <div
            className="flex justify-center items-center"
            style={{ height: "100%" }}
          >
            <h4 style={{ color: "#c0c0c0" }}>No data available</h4>
          </div>
        )}
      </Box>

    </>
  );
};

export default LearningCards;
