import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNotifications } from "../../../components/notifications/notifications";
import QuestionSection from "../../../components/QuestionSection/QuestionSection";
import ContentUploadMCQOrTheory from "./ContentUploadMCQorTheory";
import ContentUploadMCQOrTheoryInput from "./ContentUploadMCQOrTheoryInput";
import ContentUploadPerformanceEvaluation from "./ContentUploadPerformanceEvaluation";
import ContentUploadQuestionManagerMCQ from "./ContentUploadQuestionManagerMCQ";
import ContentUploadQuestionManagerMCQInputs from "./ContentUploadQuestionManagerMCQInputs";
import ContentUploadStartTestPage from "./ContentUploadStartTestPage";
import ContentUploadTestConfig from "./ContentUploadTestConfig";
import ContentUploadTestSettingsLayout from "./ContentUploadTestSettingsLayout";
import { useDispatch } from "react-redux";
import { getQuestionSectionByExamId } from "../../../redux/contentUpload/contentUploadActions";
import useContentUpload from "../../../hooks/useContentUpload";

const ContentUploadExamSetUp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const exam = location?.state?.exam;
  const subjectId = location?.state?.subjectId;
  const { questionSection } = useContentUpload();

  useEffect(() => {
    dispatch(getQuestionSectionByExamId(exam?.examId));
  }, []);

  const [ToastState, setToastState] = useNotifications();

  const [input, setInput] = useState({
    progress: "test-config",
    examSetupState: 1,
    examId: "",
    testYearId: exam?.examYearId ? exam?.examYearId : "",
    testDescription: exam ? exam?.description : "",
    testCompleteHours: 0,
    testCompleteMinutes: 0,
    testCompleteSeconds: 0,
    questionAnswerHours: 0,
    questionAnswerMinutes: 0,
    questionAnswerSeconds: 0,
    examType: "",
    examSeries: "",
    useCalculator: false,
  });

  return (
    <ContentUploadTestSettingsLayout
      subjectId={subjectId}
      exam={exam}
      input={input}
      setInput={setInput}
    >
      {!exam && (
        <>
          {input?.examSetupState == 1 && (
            <ContentUploadTestConfig
              input={input}
              setInput={setInput}
              setToastState={setToastState}
            />
          )}
          {input?.examSetupState == 2 && (
            <ContentUploadMCQOrTheory
              input={input}
              setInput={setInput}
              setToastState={setToastState}
            />
          )}
          {input?.examSetupState == 3 && (
            <ContentUploadMCQOrTheoryInput
              input={input}
              setInput={setInput}
              setToastState={setToastState}
            />
          )}
          {input?.examSetupState == 6 && (
            <ContentUploadStartTestPage
              input={input}
              setInput={setInput}
              setToastState={setToastState}
            />
          )}
          {input?.examSetupState == 7 && (
            <QuestionSection
              input={input}
              setInput={setInput}
              setToastState={setToastState}
              exam={exam}
              questionSection={questionSection}
            />
          )}
        </>
      )}

      {exam && (
        <>
          {((exam?.examStatus == 1 && input?.examSetupState == 1) ||
            (exam?.examStatus == 2 && input?.examSetupState == 1)) && (
            <ContentUploadTestConfig
              input={input}
              setInput={setInput}
              setToastState={setToastState}
              exam={exam}
            />
          )}
          {((exam?.examStatus == 2 && input?.examSetupState == 2) ||
            (exam?.examStatus == 1 && input?.examSetupState == 2)) && (
            <ContentUploadMCQOrTheory
              input={input}
              setInput={setInput}
              setToastState={setToastState}
              exam={exam}
            />
          )}
          {((exam?.examStatus == 2 && input?.examSetupState == 3) ||
            (exam?.examStatus == 1 && input?.examSetupState == 3)) && (
            <ContentUploadMCQOrTheoryInput
              input={input}
              setInput={setInput}
              setToastState={setToastState}
              exam={exam}
            />
          )}
          {input?.examSetupState == 6 && (
            <ContentUploadStartTestPage
              input={input}
              setInput={setInput}
              setToastState={setToastState}
              exam={exam}
            />
          )}
          {input?.examSetupState == 7 && (
            <QuestionSection
              input={input}
              setInput={setInput}
              setToastState={setToastState}
              exam={exam}
              questionSection={questionSection}
              // onChildData={handleChildData}
              // selectedSectionId={selectedSectionId}
            />
          )}
        </>
      )}
      <ToastState />
    </ContentUploadTestSettingsLayout>
  );
};

export default ContentUploadExamSetUp;
