import React, { useState, useEffect } from "react";
import { ExportIcon, FilterIcon, PlusIcon } from "../../../assets/icons/Icons";
import {
    Box,
    Button,
    IconButton,
    Grid,
    Badge,
    Paper,
    Skeleton,
    Typography,
    Fab,
    Divider,
    Stack,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Autocomplete,
    TextField,
} from "@mui/material";
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import AuditTable from "./AuditTable";
import SearchBox from "../../../components/Test/SearchBox";
import { useDispatch } from "react-redux";
import useAuditTrail from "../../../hooks/useAuditTrail";
import { loadAdminAudit } from "../../../redux/auditTrail/auditTrailActions";
import moment from "moment";
import usePermissions from "../../../hooks/usePermissions";
import { loadAdminUsersAction } from "../../../redux/permissions/permissionsActions";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import AuditDate from "./AuditDate";

let activities = [
    {
        id: 1,
        icon: <TimelapseIcon sx={{ fontSize: { md: 54 }, backgroundColor: "#e8eaff", borderRadius: "9999px", }} />,
        text: "Activities Done Today",
        number: 12,
    },
    {
        id: 2,
        icon: <AvTimerIcon sx={{ fontSize: { md: 54 }, backgroundColor: "#f8f6d3", borderRadius: "9999px", }} />,
        text: "Activities Done Yesterday",
        color: "blue",
        number: 24,
    },
    {
        id: 3,
        icon: <TimelapseIcon sx={{ backgroundColor: "#ffefed", color: "#f15349", borderRadius: "9999px", fontSize: { md: 54 } }} />,
        text: "Activities Done Last Week",
        number: 50,
    }
]

const AuditTrail = () => {
    const dispatch = useDispatch()
    const auditTrail = useAuditTrail();
    const { allAudits, totalAudit, loading } = auditTrail;
    const permissions = usePermissions();
    const { adminUsersData } = permissions;
    // const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState([]);
    // const [activitiesType, setActivitiesType] = useState("");
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 10, selectedUser: null, activitiesType: "", startDate: moment(new Date()).format("YYYY-MM-DD"), endDate: new Date(), search: "" });
    const [filterDate, setFilterDate] = useState("");
    const [openDateModal, setOpenDateModal] = useState(false);
    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());
    // const [selectedUser, setSelectedUser] = useState();



    useEffect(() => {
        dispatch(loadAdminUsersAction())
    }, [])



    useEffect(() => {
        setFilterData(allAudits?.data?.sort(function (a, b) {
            return a.createdBy - b.createdBy
        }))
    }, [allAudits])

    useEffect(() => {
        if (pagination.search !== "") {
            setFilterData(
                allAudits
                    ?.data?.sort(function (a, b) {
                        return a.createdBy - b.createdBy;
                    })
                    ?.filter((sub) =>
                        sub.createdBy?.toLowerCase()?.includes(pagination.search.toLowerCase())
                    )
            );
        } else {
            setFilterData(
                allAudits?.data?.sort(function (a, b) {
                    return a.createdBy - b.createdBy;
                })
            );
        }
    }, [pagination.search]);


    const handleDateFilter = (newValue) => {
        const selectedValue = newValue;

        if (selectedValue === "0") {
            // Clear start and end dates
            setPagination((prev) => ({ ...prev, startDate: " " }))
            setPagination((prev) => ({ ...prev, endDate: " " }))


            setPagination((prev) => ({ ...prev, activitiesType: "" }))

            // Update state
            setFilterDate(selectedValue);
            return;
        }

        // Calculate start and end dates based on selected value from the drop down
        let newStartDate = new Date();
        let newEndDate = new Date();
        switch (selectedValue) {

            case "1":
                newStartDate.setDate(newStartDate.getDate() - 6); // Last 7 days
                break;
            case "2":
                newStartDate.setDate(1); // Last 31 days
                break;
            case "3":
                newStartDate = new Date(newStartDate.getFullYear(), 0, 1); // Start of current year
                break;
            case "4":
                // setOpenDateModal(true);
                setOpenDateModal((prev) => !prev);
                console.log("clicked")
                // newEndDate = new Date("2024-01-31");
                break;
            default:
                break;
        }



        setPagination((prev) => ({ ...prev, startDate: (moment(newStartDate).format("YYYY-MM-DD")) }))
        setPagination((prev) => ({ ...prev, endDate: (moment(newEndDate).format("YYYY-MM-DD")) }))


        setFilterDate(selectedValue);
    };

    const changeActivityType = (event) => {
        setPagination((prev) => ({ ...prev, activitiesType: event.target.value }))
    }



    useEffect(() => {
        dispatch(loadAdminAudit(pagination));
    }, [pagination]);


    //functionality that sends the next page number to the api
    const handlePageChange = (newPage) => {

        setPagination((prev) => ({ ...prev, pageNumber: newPage.page + 1 }))

    }
    //functionality for get the user profileID and sending it to the api
    const handleUserAdmin = (newValue) => {
        setPagination((prev) => ({ ...prev, selectedUser: newValue }))
    }

    return (
        <div>
            <Box display={"flex"} justifyContent={"space-between"} sx={12} md={6} sm={4}>
                <Box>
                    <Typography variant="h6" fontWeight={"bold"}>
                        Audit Trails
                    </Typography>
                </Box>
                <Box>
                    <Button
                        sx={{ backgroundColor: '#fff', color: '#40499D', borderRadius: '5px', padding: '8px 10px' }}
                    >
                        <ExportIcon />
                        Export
                    </Button>
                </Box>
            </Box>

            <Box component={"section"} width={"100%"} mt={1}>
                <Box
                    component={Paper}
                    elevation={0}
                    p={1}
                    borderRadius={"24px"}
                    bgcolor={"#fff"}
                    border={"2px solid #eee"}
                >
                    <Grid container rowGap={2} rowSpacing={2} columnSpacing={2}>
                        <Grid item xs={6} sm={6} lg={4}>
                            <Box display={"flex"} gap alignItems={"center"}>
                                <Box
                                    sx={{
                                        backgroundColor: "brandPrimary.main",
                                        padding: 1,
                                        borderRadius: "9999px",
                                    }}
                                    justifyContent={"center"}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <TimelapseIcon sx={{ fontSize: { md: 20 }, color: "white" }} />
                                </Box>
                                <Stack>
                                    <Typography variant="body2" color={"#aaa"}>
                                        Activities Done Today
                                    </Typography>
                                    <Typography variant="h6" fontWeight="bold">
                                        {loading ? (
                                            <Skeleton variant="text" width={100} animation="wave" />
                                        ) : (
                                            allAudits?.dayFiltered
                                        )}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={4}>
                            <Box display={"flex"} gap alignItems={"center"}>
                                <Box
                                    sx={{
                                        backgroundColor: "#EB6946",
                                        padding: 1,

                                        borderRadius: "100px",
                                    }}
                                    justifyContent={"center"}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <AvTimerIcon
                                        sx={{ fontSize: { md: 20 }, color: "white" }}
                                    />
                                </Box>
                                <Stack>
                                    <Typography variant="body2" color={"#aaa"}>
                                        Activities Done Yesterday
                                    </Typography>
                                    {loading ? (
                                        <Skeleton variant="text" width={100} animation="wave" />
                                    ) : (
                                        <Typography variant="h6" fontWeight={"bold"}>
                                            {allAudits?.yesterdayFiltered}
                                        </Typography>
                                    )}
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={4}>
                            <Box display={"flex"} gap alignItems={"center"}>
                                <Box
                                    sx={{
                                        backgroundColor: "#2AAC7E",
                                        padding: 1,
                                        borderRadius: "9999px",
                                    }}
                                    justifyContent={"center"}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <AvTimerIcon sx={{ fontSize: { md: 20 }, color: "white" }} />
                                </Box>
                                <Stack>
                                    <Typography variant="body2" color={"#aaa"}>
                                        Activities Done Last Week
                                    </Typography>
                                    {loading ? (
                                        <Skeleton variant="text" width={100} animation="wave" />
                                    ) : (
                                        <Typography variant="h6" fontWeight={"bold"}>
                                            {allAudits?.weekFiltered}
                                        </Typography>
                                    )}
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box component={"section"} width={"100%"} mt={2}>
                <Grid container rowGap={2} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={6} sm={6} lg={4}>
                        <FormControl sx={{ m: 1, width: 300 }} size="small">
                            <Autocomplete
                                sx={{ width: 300 }}
                                id="combo-box-demo"
                                size="small"
                                options={adminUsersData}
                                value={pagination.selectedUser}
                                onChange={(e, newValue) => { handleUserAdmin(newValue) }}
                                getOptionLabel={(option) => { return `${option.FullName}` }}
                                isOptionEqualToValue={(option, value) => {
                                    return option.ProfileId === value
                                }

                                } // Compare options based on ProfileId
                                renderInput={(params) => <TextField {...params} label="Select an Admin" className='mb-1' variant="outlined" />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={4}>
                        <FormControl sx={{ m: 1, minWidth: 270 }} size="small">
                            <InputLabel id="demo-select-small-label">Activity Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={pagination.activitiesType}
                                label="Type"
                                onChange={changeActivityType}
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="0">Create</MenuItem>
                                <MenuItem value="1">Update</MenuItem>
                                <MenuItem value="2">Delete</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={4}>
                        <FormControl sx={{ m: 1, minWidth: 270 }} size="small">
                            <InputLabel id="demo-select-small-label">Date</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={filterDate}
                                label="Date"
                                onChange={(e) => {
                                    handleDateFilter(e.target.value);
                                    if (e.target.value === "4") {
                                        setOpenDateModal(true)
                                    }
                                }}

                            >
                                <MenuItem value="0">All</MenuItem>
                                <MenuItem value="1">Last 7 days</MenuItem>
                                <MenuItem value="2">This Month</MenuItem>
                                <MenuItem value="3">This year</MenuItem>
                                <MenuItem value="4" >Custom Date</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>

            <Grid xs={12} mt={3}>
                <Typography sx={{ color: "blue" }}>All Activities </Typography>
            </Grid>
            <Divider />

            <div className="mt-6 mb-3 ">
                <Grid container item xs={12} spacing={1} display={"flex"} justifyContent={"space-between"}>
                    <Grid item xs={6}>
                        <SearchBox
                            // placeholder={"Search..."}
                            onChange={(e) => setPagination((prev) => ({ ...prev, search: e.target.value }))}
                            // onChange={(e) => setSearch(e.target.value)}
                            value={pagination.search}
                            searchIconColor={"#000000E6"}
                            bg={"rgba(255 , 255, 255)"}
                        />
                    </Grid>

                </Grid>
            </div>
            <LoadingOverlayWrapper active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(0, 0, 0, 0.15)",
                    }),
                }}
                spinner
                text="Fetching Data for You..."
            >
                <AuditTable
                    filterData={filterData}
                    handlePageChange={handlePageChange}
                    pagination={pagination}
                />
            </LoadingOverlayWrapper>

            <AuditDate
                openDateModal={openDateModal}
                setOpenDateModal={setOpenDateModal}
                pagination={pagination}
                setPagination={setPagination}
                filterDate={filterDate}
                handleDateFilter={handleDateFilter}

            />

        </div >
    );
};

export default AuditTrail;
