import React, { useState, useEffect } from "react";
import { FilterIcon, PlusIcon } from "../../../assets/icons/Icons";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Badge,
  Typography,
} from "@mui/material";

import SearchBox from "../../../components/Test/SearchBox";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import CustomizedDialogs from "../../../components/StudentManagement/Dialog";

import SponsorSchoolTable from "./sponsorSchoolTable";
import { useDispatch } from "react-redux";
import useSchool from "../../../hooks/useSchool";
import useUser from "../../../hooks/useUser";
import { fetchGetSchoolBySponsorId } from "../../../redux/school";

const SponsorManageSchools = () => {
  const dispatch = useDispatch();
  const school = useSchool();

  const { userInfo } = useUser();
  const { sponsorId } = userInfo;
  const [searchValue, setSearchValue] = useState("");
  const [showFilters, setShowFilters] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { allSchoolsBySponsor } = school;

  useEffect(() => {
    dispatch(fetchGetSchoolBySponsorId(sponsorId));
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const modalContent = {
    title: "Enrol Student",
  };
  const handleSearch = (value) => {
    setSearchValue(value);

    if (value === "") {
    } else {
    }
  };
  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const totalSchool = allSchoolsBySponsor.length;
  return (
    <div>
      <div>
        <div className="flex items-center space-x-4">
          <h2 className="e-study-heading-four e-study-school-black">
            Manage Schools
          </h2>
          <span className="e-study-button-lilac rounded-[22px] py-2 px-3">
            {totalSchool}
          </span>
        </div>
        {/* <p className="e-study-school-black e-study-normal-text-three">
          See the list of all students enrolled in the e-Study application
        </p> */}
      </div>

      {/* <div className="mt-6">
        <Grid container xs={12} spacing={1}>
          <Grid item xs={8}>
            <SearchBox
              placeholder={"students"}
              onChange={(e) => handleSearch(e.target.value)}
              value={searchValue}
              searchIconColor={"#1347F5"}
              bg={"rgba(244, 247, 254, 1)"}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "#40499d",
                  backgroundColor: "#F5F6FF",
                  border: "none",
                  padding: "15px 20px",
                  "&:hover": {
                    border: "none",
                  },
                }}
                startIcon={<FilterIcon />}
                endIcon={
                  <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "#EAECFF",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontWe fontSize={"10px"}>
                      4
                    </Typography>
                  </Box>
                }
              >
                Filter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div> */}

      <div className="overflow-x-hidden">
        <SponsorSchoolTable
          open={open}
          handleClose={handleClose}
          modalContent={modalContent}
          data={allSchoolsBySponsor}
        />
      </div>
    </div>
  );
};

export default SponsorManageSchools;
