import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

export default function DeleteUserModal(props) {
  const [openDeleteUser, setOpenDeleteUser] = React.useState(false);

  const handleClickOpenDeleteUser = () => {
    setOpenDeleteUser(true);
  };
  const handleCloseDeleteUser = () => {
    setOpenDeleteUser(false);
  };

  return (
    <>
      <MenuItem className="hover:bg-light-blue" onClick={handleClickOpenDeleteUser}>Delete User</MenuItem>
      <BootstrapDialog
        onClose={handleCloseDeleteUser}
        aria-labelledby="customized-dialog-title"
        open={openDeleteUser}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Delete User
        </DialogTitle> */}
        {/* <IconButton
          aria-label="close"
          onClick={handleCloseDeleteUser}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        {/* <DialogContent dividers> */}
        <Box>
          <WarningAmberIcon
            style={{
              height: 50,
              width: 50,
              color: "#dc2626",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "1rem",
            }}
          />
          <Typography
            variant="h5"
            fontWeight={"bold"}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            Delete Hammed Yusuff
          </Typography>

          <Typography>
            {/* Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla. */}
          </Typography>
          {/* </DialogContent> */}
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              autoFocus
              onClick={handleCloseDeleteUser}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={handleCloseDeleteUser}
              variant="contained"
              style={{ backgroundColor: "#dc2626" }}
            >
              Delete User
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
}
