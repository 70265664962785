import React from "react";
import FileUpload from "./Dropzone"
import { Paper } from "@mui/material";

const template = [{
  id: "1",
  icon: "",
  text: "Downlaod the sample template by clicking on the download button. Add students data to the template file and upload the file for submission"
}]

const BulkImportForm = ({ handleClose, setToastState, refreshParent, fetchOverlay, BusyOverlay }) => {
  return (
    <div>
      <div className="bg-white rounded-2xl flex justify-between items-center">
        <div className="flex flex-col">
          <p style={{ fontSize: '14px' }}>Transfer student information from the Excel sheet to enrol them on the e-study platform</p>
          <Paper className="dropzone mt-10" elevation={2}>
            <FileUpload setToastState={setToastState} handleCloseModal={handleClose} refreshParent={refreshParent} fetchOverlay={fetchOverlay} BusyOverlay={BusyOverlay} />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default BulkImportForm;