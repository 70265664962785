import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  ThemeProvider,
  Avatar,
  InputAdornment,
  OutlinedInput,
  FormControl,
  IconButton,
} from "@mui/material";
import AuthContainer from "../../components/AuthReuseables/AuthContainer";
import customTheme from "../../components/AuthReuseables/customTheme";
import { useNotifications } from "../../components/notifications/notifications";
import { useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { forgotPasswordAction, resetPasswordAction } from "../../redux/auth/authActions";
import GlobalThemeProvider from "../../GlobalThemeProvider";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import useUser from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ password: "", confirmPassword: "" });
  const [msg, setMsg] = useState("");
  const [fail, setFail] = useState("");
  const [ToastState, setToastState] = useNotifications();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState("");
  const user = useUser();

  const resetPasswordURL = window.location.href;
  const url = new URL(resetPasswordURL);
  const profileId = url.searchParams.get("q");
  console.log(profileId, "profileId");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const passwordHandler = (e) => {
    const passwordValue = e.target.value;
    const minLength = 6;

    if (passwordValue.length < minLength && passwordValue !== "") {
      setPasswordHelperText(`Password must be at least ${minLength} characters`);
    } else {
      setPasswordHelperText("");
    }

    setFormValues({
      ...formValues,
      password: passwordValue,
    });
  };

  const confirmPasswordHandler = (e) => {
    const confirmPasswordValue = e.target.value;
    const passwordValue = formValues.password;

    if (confirmPasswordValue === passwordValue) {
      setConfirmPasswordHelperText("Password and Confirm Password are a match");
    } else {
      setConfirmPasswordHelperText("Password and Confirm Password are not a match");
    }

    setFormValues({
      ...formValues,
      confirmPassword: confirmPasswordValue,
    });
  };

  useEffect(() => {
    if (msg) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      setTimeout(() => {
        navigate("/auth/login");
      }, 2000);
    }
  }, [msg]);

  useEffect(() => {
    if (fail) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: fail,
      });
    }
  }, [fail]);

  const handleSubmit = () => {
    let params = {
      newPassword: formValues.password,
      profileId: profileId,
    };
    console.log(params, "nice");
    dispatch(
      resetPasswordAction(
        params,
        (res) => setMsg(res.data.msg),
        (res) => setFail(res.data.msg)
      )
    );
  };
  return (
    <GlobalThemeProvider>
      <AuthContainer>
        <Container component="main" maxWidth="xs">
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: "bold",
              mb: 3,
              color: "#1d1d1d",
              textAlign: "center",
            }}
          >
            Reset Password
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                // size="small"
                name="password"
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                value={formValues.password}
                helperText={passwordHelperText}
                onChange={(e) => passwordHandler(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                // size="small"
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                autoComplete="confirm-password"
                helperText={confirmPasswordHelperText}
                value={formValues.confirmPassword}
                onChange={(e) => confirmPasswordHandler(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle confirm password visibility" onClick={toggleConfirmPasswordVisibility} edge="end">
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!formValues.password || !formValues.confirmPassword || formValues.password !== formValues.confirmPassword}
            onClick={handleSubmit}
            sx={{
              mt: 2,
              py: 2,
              borderRadius: "4px",
              textTransform: "none",
              fontSize: "14px",
            }}
          >
            {!user.isLoading ? "Update Password" : <DataUsageIcon size={24} className="ml-4 animate-spin" />}
          </Button>
        </Container>
      </AuthContainer>
      <ToastState />
    </GlobalThemeProvider>
  );
};

export default ResetPassword;
