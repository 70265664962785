import React from 'react'
import {
  AdminDashboardIcon,
  AuditTailIcon,
  BookIcon,
  ContentUploadIcon,
  DashboardIcon,
  PermissionIcon,
  TransactionIcon,
  UserMetricsIcon,
  NairaIcon,
  ReceiptIcon,
  AuditIcon,
} from "../../assets/icons/Icons";

const IconRenderer = ({ iconName, color }) => {
  switch (iconName) {
    case 'AdminDashboardIcon':
      return <AdminDashboardIcon color={color ? "white" : null} />;
    case 'AuditTailIcon':
      return <AuditTailIcon color={color ? "white" : null}/>;
    case 'ContentUploadIcon':
      return <ContentUploadIcon color={color ? "white" : null}/>;
    case 'PermissionIcon':
      return <PermissionIcon color={color ? "white" : null}/>;
    case 'TransactionIcon':
      return <TransactionIcon color={color ? "white" : null}/>;
    case 'UserMetricsIcon':
      return <UserMetricsIcon color={color ? "white" : null}/>;
    case 'NairaIcon':
      return <NairaIcon color={color ? 'white' : null} />;
      case 'ReceiptIcon':
      return <ReceiptIcon color={color ? 'white' : null} />;
      case 'AuditIcon':
        return <AuditIcon color={color? 'white': null} />;
    default:
      return null;
  }
};


export default IconRenderer