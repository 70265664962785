import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import { useDispatch } from "react-redux";
import useUser from "../../../hooks/useUser";
import { getAllCountries, getStatesByCountryId } from "../../../redux/auth/authActions";
import CustomSelect from "../../admin/usermetrics/customSelectForm";
import { FormControl } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === "dark"
            ? "0 0 0 1px rgb(16 22 26 / 40%)"
            : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
        theme.palette.mode === "dark"
            ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
            : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
    },
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background:
            theme.palette.mode === "dark"
                ? "rgba(57,75,89,.5)"
                : "rgba(206,217,224,.5)",
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: "#106ba3",
    },
});

const ColoredPaper = styled(Paper)(({ theme, selected }) => ({
    backgroundColor: selected ? "#EAECFF" : theme.palette.background.default,
    borderRadius: "20px",
    border: "1px solid #E5E5E5",
    boxShadow: "0px 4px 4px 0px #E5E5E5"
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// const datePickerStyles = {
//     "& .MuiPickersBasePicker-container": {
//         height: 'auto', // Set the height to auto or adjust as needed
//     },
// };

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(1.5),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
    "& .MuiInputBase-input::placeholder": {
        color: "transparent", // Hide the placeholder text
    },
    "& .MuiInputBase-input:focus::placeholder": {
        color: "#555", // Style the label text color when focused
    },
}));

export default function UserDetailsOnb(props) {
    const { input, setInput } = props
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = React.useState("");
    const dispatch = useDispatch()
    const user = useUser()
    const { countries, statesByCountryId, isLoading } = user;
    const [error, setError] = useState('');

    const desiredgradegrid = {
        marginTop: "7rem",
        marginBottom: "2rem",

        [theme.breakpoints.up('md')]: {
            marginTop: "5rem",
            marginBottom: "2rem",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "3rem",
            marginBottom: "2rem",
        },
        [theme.breakpoints.up('xs')]: {
            marginTop: "1rem",
            marginBottom: "0rem",
        },
    };

    const desiredgradetext = {
        fontSize: "30px",
        flexGrow: 0,
        paddingRight: "30px",

        [theme.breakpoints.up('md')]: {
            fontSize: "30px",
            flexGrow: 0,
            paddingRight: "30px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
        },
    };

    const paperCardGrid = {
        maxWidth: "60%",
        mx: "auto",

        [theme.breakpoints.down('md')]: {
            maxWidth: "80%",
            mx: "auto",
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "90%",
            mx: "auto",
        },
    };

    const paperCardBox = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "2rem",
        "& > :not(style)": {
            m: 2,
            width: 200,
            height: 200,
        },
    };

    const initialFormValues = {
        dateOfBirth: "",
    };

    const [formValues, setFormValues] = React.useState({ ...initialFormValues });

    useEffect(() => {
        dispatch(getAllCountries())
    }, []);

    const handleChangeUserAge = (event) => {
        const value = event.target.value;
        setSelectedValue(event.target.value);
        setInput({ ...input, age: value })
    };

    const handleChangeCountry = (event) => {
        const value = event.target.value;
        setInput({ ...input, countryId: value })
        dispatch(getStatesByCountryId(value))
    };

    const handleChangeState = (event) => {
        const value = event.target.value;
        setInput({ ...input, stateId: value })
    };

    const handleChangeGender = (event) => {
        const value = event.target.value;
        setInput({ ...input, gender: value })
    };

    const handler = (newValue) => {

        const selectedDate = dayjs(newValue);
        const maxDate = dayjs('2012-12-31');
        
        if (selectedDate?.isAfter(maxDate)) {
            setError('Users must be at least 12 years old');
            return;
        }

        setInput({ ...input, dateOfBirth: newValue })
        setFormValues((prevValues) => ({
            ...prevValues,
            dateOfBirth: newValue,
        }));
    };



    const AgeOptions = ["Under 18", "18 and Above"];

    const GenderOptions = ["Male", "Female"];

    return (
        <div>
            <Grid
                container
                direction="column"
                alignItems="center"
                spacing={0}
                sx={desiredgradegrid}
            >
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={desiredgradetext}
                    >
                        Add Profile Information...
                    </Typography>
                </Grid>
            </Grid>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} sx={paperCardGrid}>
                    <Grid item lg={6} sm={6} md={6} xs={12} marginTop={0}>
                        <Typography >Select Date of Birth</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker", "DatePicker"]}>
                                <DatePicker
                                    name="dateOfBirth"
                                    // label="Date of Birth"
                                    value={dayjs(input.dateOfBirth)}
                                    onChange={(newValue) => handler(newValue)}
                                    sx={{ width: '100%' }}
                                    slotProps={{ textField: { size: 'small' } }}
                                    maxDate={dayjs('2012-12-31')}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        {error && (
                <p style={{ color: "#c62828", fontSize: "13px", marginTop: "2px"  }}>
                    {error}
                </p>
            )}
                        {/* </FormControl> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} mt={1}>
                        {/* <FormControl fullWidth> */}
                        <Typography>Select Gender</Typography>
                        <CustomSelect
                            handleChange={handleChangeGender}
                            value={input.gender}
                            BootstrapInput={BootstrapInput}
                            SelDefault={"Select Gender"}
                            Seloptions={GenderOptions}

                        />
                        {/* </FormControl> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography>Select Country</Typography>
                        <CustomSelect
                            handleChange={handleChangeCountry}
                            value={input.countryId}
                            BootstrapInput={BootstrapInput}
                            SelDefault={"Select Country"}
                            Seloptions={countries}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography>Select State</Typography>
                        <CustomSelect
                            handleChange={handleChangeState}
                            value={input.stateId}
                            BootstrapInput={BootstrapInput}
                            SelDefault={"Select State"}
                            Seloptions={statesByCountryId}
                        />
                    </Grid>
                </Grid>
            </Box>

        </div >
    );
}
