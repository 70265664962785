import {
  Box,
  Container,
  Stack,
  Typography
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { BackgroundImage, Waeclogo } from "../assets/images";
import Loader from "../components/AuthReuseables/Preloader/Loader";
import LandingPageFooter from "../components/landingPage/LandingPageFooter";
import { PricingLine } from "../components/landingPage/Line";
import AuthNavbar from "../components/navbars/AuthNavbar";
import { useGoogleAnalytics } from "../hooks/usePageTracking";

const tableOfContents = [
  "Vision",
  "Mission",
  "Core Values",
  "Structure of the Council",
  "The Executive Arm of the Council",
  "Financing the Operations of WAEC",
  "",
];
const contents = [
  {
    title: "Vision",
    text: "Established in 1952, the vision of the West African Examinations Council is to be a world-class examining body, adding value to the educational goals of its stakeholders.",
  },
  {
    title: "Mission",
    text: "The West African Examinations Council is West Africa's foremost examining board established by law to determine the examinations required in the public interest in the English-speaking West African countries, to conduct the examinations and to award certificates comparable to those of equivalent examining authorities internationally.",
  },
  {
    title: "Core Values",
    text: [
      <ul className="list-disc flex flex-col gap-2 pl-8">
        <li> Excellence </li>
        <li>Integrity</li>
        <li> Professionalism</li>
        <li> Accountability</li>
        <li>Transparency</li>
        <li> Teamwork</li>
        <li> Innovativeness</li>
      </ul>,
    ],
  },
];

const WAEC = () => {
  const [loading, setLoading] = useState(true);
  const { trackPage } = useGoogleAnalytics()
  trackPage()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box className="relative overflow-hidden">
          <Box bgcolor={"#40499d"}>
            <AuthNavbar />
            <Container maxWidth="xl" disableGutters>
              <Stack
                mt={2}
                width={"100%"}
                gap={3}
                alignItems={"center"}
                color={"white"}
                style={{
                  // backgroundImage: `linear-gradient(180deg,rgb(22.42,25.57,55),rgba(64,73,157,0.23)), url(${BackgroundImage})`,
                  backgroundImage: `linear-gradient(to right, rgb(22.42,25.57,85), rgba(64,73,157,0.23)), url(${BackgroundImage})`,
                  backgroundRepeat: "no-repeat",
                  objectFit: "cover",
                  backgroundPosition: "center",
                  minHeight: "280px",
                }}
              >
                <Box className="flex flex-col-reverse lg:flex-row w-full gap-4 justify-between items-center px-40 py-4">
                  <Typography
                    fontFamily={"Montserrat"}
                    variant="h3"
                    className="flex gap-4 items-center"
                  >
                    <Typography
                      fontFamily={"Montserrat"}
                      variant="span"
                      display={"inline"}
                      fontWeight={"700"}
                      position={"relative"}
                      color={"#F8EF07"}
                    >
                      {" "}
                      <PricingLine />
                      WAEC!
                    </Typography>{" "}
                    Profile
                  </Typography>
                  <Box>
                    <img src={`${Waeclogo}`} alt="waeclogo" />
                  </Box>
                </Box>
              </Stack>
            </Container>
          </Box>

          <Box component="section" id="#waecProfile" p={4}>
            <Container maxWidth="lg" className="relative">
              <Box
                width={{ md: "100%" }}
                mx={"auto"}
                id="#waecProfile"
                component={"section"}
                display={"flex"}
                flexDirection={"colunm"}
                alignItems={"center"}
                gap={"25px"}
                className="z-10"
              >
                <Box
                  fontFamily={"Montserrat"}
                  width={{ md: "100%" }}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"start"}
                  gap={"25px"}
                >
                  <Box className="flex flex-col gap-8 bg-slate-50 p-12 rounded-xl">
                    <p className="text-base pb-2">
                      Established in 1952, the vision of the West African
                      Examinations Council is to be a world-class examining
                      body, adding value to the educational goals of its
                      stakeholders.
                    </p>
                    <p className="text-base pb-2">
                      The West African Examinations Council is West Africa's
                      foremost examining board established by law to determine
                      the examinations required in the public interest in the
                      English-speaking West African countries, to conduct the
                      examinations and to award certificates comparable to those
                      of equivalent examining authorities internationally.
                    </p>
                    <Box className="flex flex-col gap-2">
                      <h6 className="font-semibold text-base text-[#40499d] pb-2">
                        By this mandate, the Council is expected to:
                      </h6>
                      <ul className="text-base list-disc flex flex-col gap-2 pl-8">
                        <li>Assist in the development of sound education</li>
                        <li>
                          Ensure that educational standards are maintained
                        </li>
                        <li>
                          Give the people of West Africa a vision of the great
                          potentials which lie beyond examinations
                        </li>
                      </ul>
                    </Box>
                    <Box className="flex flex-col gap-4">
                      {contents.map((content, i) => (
                        <Box fontFamily={"Montserrat"}>
                          <h6 className="font-semibold text-xl text-[#40499d] pb-2">
                            {content.title}
                          </h6>
                          <p className="text-base pb-2"> {content.text}</p>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box className="flex flex-col gap-8 bg-slate-50 p-12 rounded-xl">
                    <Box>
                      <h6 className="font-semibold text-lg text-[#40499d] pb-2">
                        STRUCTURE OF THE COUNCIL
                      </h6>

                      <Box>
                        <p className="text-base pb-2">
                          The Council has the responsibility of determining
                          examinations required in the public interest in West
                          Africa and to conduct such examinations and to award
                          certificates
                        </p>
                        <p className=" text-base pb-2">
                          Nigeria is represented by 12 members, 5 of whom are
                          nominees of the Federal Government. The Director of
                          Education in the Federal Ministry of Education, Abuja
                          is statutorily the Chief Government Nominee on the
                          Council. He is also the Chairman of the Nigeria
                          National Committee of the Council and the
                          Administrative and Finance Committee.
                        </p>
                      </Box>
                    </Box>

                    <Box>
                      <h6 className="font-semibold text-base text-[#40499d] pb-2">
                        Appointed by the Government
                      </h6>
                      <ul className="list-disc flex flex-col gap-2 pl-8 text-base">
                        <li>
                          Hajiya Binta Abdulkadir, Federal Ministry of
                          Education, Abuja
                        </li>
                        <li>
                          Alhaja (Mrs.) Mulikat A.Bello,Yusuf Akinde Estate,
                          Alagbado, Lagos.
                        </li>
                        <li>
                          Prof. Faruk A. Kalgo, Uthman Danfodiyo University,
                          Sokoto.
                        </li>
                        <li>
                          Prof.Solomon A. Olorundare, University of Ilorin,
                          Ilorin.
                        </li>
                        <li>
                          Prof. Victor Kogah, Imo State University, Owerri
                        </li>
                      </ul>
                    </Box>
                    <Box>
                      <h6 className="font-semibold text-base text-[#40499d] pb-2">
                        Council functions through Committees which include:
                      </h6>
                      <ul className="list-disc flex flex-col gap-2 pl-8 text-base">
                        <li>
                          The International Administrative and Finance Committee
                        </li>
                        <li>The International Examinations Committee</li>
                        <li>The International Appointments Committee</li>
                        <li>The International Tenders Board</li>
                        <li>The National Committee(s)</li>
                      </ul>
                    </Box>
                    <Box>
                      <h6 className="font-semibold text-lg text-[#40499d] pb-2">
                        THE EXECUTIVE ARM OF THE COUNCIL
                      </h6>
                      <p className="text-base pb-2">
                        The Chief Executive is the Registrar whose office is at
                        the Headquarters Office in Accra, Ghana. Each member
                        country has a National Office which is headed by a Head
                        of National Office, who is in charge of the activities
                        of the Council in that country, while the Registrar
                        coordinates the five National Offices. In addition, the
                        Registrar supervises the Head of Research Division (the
                        Headquarters of which is in Nigeria, with branches in
                        the other countries); the Director, Human Resource
                        Management; Directors of Audit and Finance; and the
                        Director of Administration. The Council's functions in
                        each member country are carried out through two line
                        Divisions: the Test Development Division and Test
                        Administration Division. While the Test Development
                        Division produces the syllabuses and tests to be taken,
                        the Test Administration Division conducts the tests, and
                        issues results and certificates. In achieving their
                        goals, the two Divisions are actively supported by the
                        General Administration, the Information and
                        Communication Technologies and Finance Divisions. The
                        Heads of these five divisions report to the Head of
                        National Office.
                      </p>
                    </Box>
                  </Box>

                  <Box className="flex flex-col gap-8 bg-slate-50 p-12 rounded-xl">
                    <Box>
                      <h6 className="font-semibold text-base text-[#40499d] pb-2">
                        FINANCING THE OPERATIONS OF WAEC
                      </h6>
                      <Box>
                        <p className="text-base pb-2">
                          The Council has two main sources of revenue to execute
                          its operations. They are examination fees and
                          subventions from the various governments.
                        </p>
                        <p className="text-base">
                          Because education is regarded as a social service by
                          the governments of member-countries of the Council,
                          the Council is not allowed to charge economic fees for
                          conducting examinations. The understanding has been
                          that the shortfall between the operating costs and the
                          fees collected, would be provided by the governments
                          in the form of subventions. The various governments
                          have tried to fulfil their obligations but the
                          shortfalls have never been fully provided.
                        </p>
                      </Box>
                    </Box>
                    <Box>
                      <p className="text-lg pb-2 text-[#40499d]">
                        Government subventions to Council are usually in two
                        parts:
                      </p>
                      <ol className="list-disc flex flex-col gap-2 pl-8">
                        <li>Funds for the international obligations.</li>
                        <li>Funds for national operations.</li>
                      </ol>
                    </Box>
                    <Box className="pb-2">
                      <p className="text-base pb-2">
                        Funds for the Headquarters expenses which constitute the
                        International Obligations of member-countries to Council
                        are apportioned from time to time following agreed
                        parameters.{" "}
                      </p>
                      <p className="text-base pb-2 text-[#40499d]">
                        The current ratio is as follows:
                      </p>
                      <ul className="list-disc flex flex-col gap-2 pl-8 text-base">
                        <li>Nigeria 54.25%</li>
                        <li>Ghana 30.01%</li>
                        <li>Sierra-Leone 6.91%</li>
                        <li>The Gambia 6.54%</li>
                        <li>Liberia 2.29%%</li>
                      </ul>
                    </Box>
                    <Box className="pb-2">
                      <p className="text-base">
                        The apportionment is arrived at, using such factors as
                        workload, and number of candidates, among others. The
                        government of each country is expected to meet the
                        short-fall in the budget for the national operations.
                      </p>
                    </Box>
                    <Box>
                      <h6 className="font-semibold text-base pb-2 text-[#40499d]">
                        {" "}
                        THE WEST AFRICAN EXAMINATIONS COUNCIL ENDOWMENT FUND
                      </h6>
                      <ul className="list-disc flex flex-col gap-2 pl-8 text-base">
                        <li>Funds for the international obligations</li>
                        <li>Funds for national operations.</li>
                      </ul>
                    </Box>
                    <Box>
                      <p className="pb-4">
                        In 1982, the Council set up an Endowment Fund mainly for
                        collecting/receiving donations to prosecute its major
                        capital expenditure of establishing a Security Printing
                        Press; administer excellence and merit awards for
                        outstanding performances in its examinations and fund
                        other educational projects and research.
                      </p>
                      <p>
                        The apportionment is arrived at, using such factors as
                        workload, and number of candidates, among others. The
                        government of each country is expected to meet the
                        short-fall in the budget for the national operations.
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>

          <LandingPageFooter />
        </Box>
      )}
    </>
  );
};

export default WAEC;
