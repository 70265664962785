import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

// Available Reducers
import authReducer from "./auth/authReducer";
import adminAuthReducer from "./adminAuth/adminAuthReducer";
import learningReducer from "./learning/learningReducer";
import { testReducer } from "./test";
import contentUploadReducer from "./contentUpload/contentUploadReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";
import userMetricsReducer from "./userMetrics/userMetricsReducer";
import permissionsReducer from "./permissions/permissionsReducer";
import { settingsReducer } from "./settings";
import schoolReducer from "./school/schoolReducer";
import paymentReducer from "./payment/paymentReducer";
import sessionReducer from "./session/sessionReducer";
import subscriptionReducer from "./subscriptions/subscriptionReducer";
import auditTrailReducer from "./auditTrail/auditTrailReducer";
import gamificationReducer from "./gamification/gamificationReducers";
// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({ basename: baseUrl });

const rootReducer = combineReducers({
  auth: authReducer,
  adminAuth: adminAuthReducer,
  learning: learningReducer,
  contentUpload: contentUploadReducer,
  test: testReducer,
  dashboard: dashboardReducer,
  permissions: permissionsReducer,
  settings: settingsReducer,
  school: schoolReducer,
  userMetrics: userMetricsReducer,
  session: sessionReducer,
  payment: paymentReducer,
  subscription: subscriptionReducer,
  auditTrail: auditTrailReducer,
  gamification: gamificationReducer,

  //Add every Reducer here

  router: connectRouter(history),
});

const _reducerName = process.env.REACT_APP_SLUG + "_reducer";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(_reducerName);
    return serializedState === null ? {} : JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    return localStorage.setItem(_reducerName, serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

// const initialState = {};
const initialState = loadState();
const enhancers = [];
const windowIfDefined = typeof window === "undefined" ? null : window;
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

const middleware = [thunk, routerMiddleware(history)];
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware), ...enhancers)
);

store.subscribe(() => saveState(store.getState()));

export default store;
