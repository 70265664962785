import React, { useState } from 'react';
import { Button, Menu, Radio, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterButton = ({ selectedFilterOption, setSelectedFilterOption }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(' ');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionChange = (event) => {
    setSelectedFilterOption(event.target.value);
    handleClose();
  };

  const buttonText = selectedFilterOption ? selectedFilterOption : "Filter Questions";

  return (
    <div>
      <Button
        color='brandPrimary'
        sx={{ textTransform: "capitalize", fontWeight: "500", borderRadius: 2 }}
        onClick={handleClick}
        variant="outlined"
        endIcon={<FilterAltIcon color='brandPrimary' />}

      >
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            minWidth: '200px',
            maxWidth: '50%',
          }
        }}
      >
        {/* <Typography>All Questions</Typography> */}
        <RadioGroup value={selectedFilterOption} onChange={handleOptionChange}>
          <FormControlLabel value="" control={<Radio color='brandPrimary' size="small" />} label="All" />
          <FormControlLabel value="Incorrect" control={<Radio color='brandPrimary' size="small" />} label="Incorrect" />
          <FormControlLabel value="Correct" control={<Radio color='brandPrimary' size="small" />} label="Correct" />
        </RadioGroup>
      </Menu>
    </div>
  );
};

export default FilterButton;
