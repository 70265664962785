import { Box, Grid, Skeleton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import avatar1 from "../../../assets/images/Avatars/avatar1.png";
import avatar2 from "../../../assets/images/Avatars/avatar2.png";
import avatar3 from "../../../assets/images/Avatars/avatar3.png";
import avatar4 from "../../../assets/images/Avatars/avatar4.png";
import avatar5 from "../../../assets/images/Avatars/avatar5.png";
import avatar6 from "../../../assets/images/Avatars/avatar6.png";
import avatar7 from "../../../assets/images/Avatars/avatar7.png";
import avatar8 from "../../../assets/images/Avatars/avatar8.png";
import avatar9 from "../../../assets/images/Avatars/avatar9.png";
import avatar10 from "../../../assets/images/Avatars/avatar10.png";
import avatar11 from "../../../assets/images/Avatars/avatar11.png";
import avatar12 from "../../../assets/images/Avatars/avatar12.png";
import avatar13 from "../../../assets/images/Avatars/avatar13.png";
import avatar14 from "../../../assets/images/Avatars/avatar14.png";
import avatar15 from "../../../assets/images/Avatars/avatar15.png";
import avatar16 from "../../../assets/images/Avatars/avatar16.png";
import avatar17 from "../../../assets/images/Avatars/avatar17.png";
import avatar18 from "../../../assets/images/Avatars/avatar18.png";
import avatar19 from "../../../assets/images/Avatars/avatar19.png";
import avatar20 from "../../../assets/images/Avatars/avatar20.png";
import avatar21 from "../../../assets/images/Avatars/avatar21.png";
import { LockedAvatar, SelectedAvatarIcon } from "../../../assets/icons/Icons";
const images = [
  {
    id: 1,
    image: avatar1,
  },
  {
    id: 2,
    image: avatar2,
  },
  {
    id: 3,
    image: avatar3,
  },
  {
    id: 4,
    image: avatar4,
  },
  {
    id: 5,
    image: avatar5,
  },
  {
    id: 6,
    image: avatar6,
  },
  {
    id: 7,
    image: avatar7,
  },

  {
    id: 8,
    image: avatar8,
  },
  {
    id: 9,
    image: avatar9,
  },
  {
    id: 10,
    image: avatar10,
  },
  {
    id: 11,
    image: avatar11,
  },
  {
    id: 12,
    image: avatar12,
  },
  {
    id: 13,
    image: avatar13,
  },
  {
    id: 14,
    image: avatar14,
  },
  {
    id: 15,
    image: avatar15,
  },
  {
    id: 16,
    image: avatar16,
  },
  {
    id: 17,
    image: avatar17,
  },
  {
    id: 18,
    image: avatar18,
  },
  {
    id: 19,
    image: avatar19,
  },
  {
    id: 20,
    image: avatar20,
  },
  {
    id: 21,
    image: avatar21,
  },
];

const Avatars = ({ isPremium, handleAvatarClick, activeId }) => {
  const [loadTime, setLoadTime] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadTime(false);
      setShowLoader(false);
    }, [3000]);
    return () => {
      clearTimeout();
    };
  }, []);
  return (
    <Box>
      {loadTime && (
        <>
          <Skeleton
            width="500px"
            height="550px"
            sx={{
              ...(loadTime && {
                position: "absolute",
                zIndex: 999,
                top: 0,
                overflow: "hidden",
              }),
            }}
          />
          <Box
            sx={{
              ...(loadTime && {
                overflow: "hidden",
                width: "600px",
                height: "550px",
                bgcolor: "#fff",
                position: "absolute",
                zIndex: 99,
              }),
            }}
          ></Box>
        </>
      )}
      <Grid container spacing={2} sx={{ display: loadTime ? "none" : "flex" }}>
        {images?.map((item) => (
          <Grid item xs={2} key={item?.id} sx={{ cursor: "pointer" }}>
            <Box
              sx={{
                ...(item?.id !== 1 &&
                  !isPremium && {
                    borderRadius: "50%",
                    border: `2px solid ${
                      activeId === item?.id ? "green" : "#f00"
                    }`,
                  }),
                position: "relative",
                ...(item?.id === 1 &&
                  activeId === item?.id && {
                    borderRadius: "50%",
                    border: "2px solid green",
                  }),
                ...(activeId === item?.id &&
                  isPremium && {
                    borderRadius: "50%",
                    border: "2px solid green",
                  }),
              }}
            >
              {activeId === item?.id && (
                <Box>
                  <SelectedAvatarIcon
                    sx={{ color: "green", position: "absolute", zIndex: 999 }}
                  />
                </Box>
              )}
              <img
                src={item?.image}
                alt="Avatar"
                onClick={(e) => handleAvatarClick(e, item?.id)}
              />
              {!isPremium && item?.id !== 1 && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 99,
                    top: "0px",
                    right: "-1px",
                  }}
                >
                  <LockedAvatar sx={{ fontSize: "4.35rem" }} />
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Avatars;
