import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as WeeklyTriviaBanner } from "../../../assets/images/WeeklyTriviaBanner.svg";

const TitleBar = ({ weeklyTrivia }) => {

  const numberOfWeeks = weeklyTrivia?.device[0]?.Week
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      color={"white"}
      alignItems={"center"}
      gap={9}
    >
      <Stack
        sx={{ width: "175px" }}
      >
        <Typography fontSize={"22px"} fontWeight={700} lineHeight={"normal"}>
          Week {" "} {numberOfWeeks}
        </Typography>
      </Stack>
      <Box>
        <WeeklyTriviaBanner />
      </Box>
      <Stack
        sx={{ width: "175px", }}
        mt={5}
      >
        {/* <Typography
          fontSize={"14px"}
          fontWeight={600}
          sx={{
            textDecoration: "underline",
            textAlign: "right",
            color: "#F8EF07",
          }}
        >
          View leaderboard
        </Typography> */}
        <Typography fontSize={"14px"} fontWeight={400} mt={5}
          sx={{

            // textDecoration: "underline",
            textAlign: "left",
            color: "#F8EF07",
          }}
        >
          Refreshes in {" "}{weeklyTrivia?.device[0]?.DaysRemaining} days {" "}{weeklyTrivia?.device[0].HoursRemaining}Hrs {" "}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TitleBar;
