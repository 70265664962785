import Axios from "../../connection/defaultClient";

import * as actionTypes from "./types";

// api for userMetrics
export const fetchUserMetrics = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {},
  callBack = () => {}
) => {
  return (dispatch) => {
    debugger;
    const payLoad = payload?.filters;
    // dispatch({ type: ALL_SUBJECTS });
    Axios.get(
      `/AdminProfile/GetUsersMetric?TakenTest=${
        payLoad?.engagement === "Taken test" ? true : undefined
      }&NotTakenTest=${
        payLoad?.engagement === "Not taken test" ? true : undefined
      }&OpenedStudy=${
        payLoad?.engagement === "Open study" ? true : undefined
      }&NotOpenedStudy=${
        payLoad?.engagement === "Not Open Study" ? true : undefined
      }&UserTypeId=${payLoad?.usertype}&CountryId=${
        payLoad.location ? payLoad.location : undefined
      }&startDate=${
        payLoad.startDate ? payLoad.startDate.toISOString() : undefined
      }&endDate=${payLoad.endDate ? payLoad.endDate.toISOString() : undefined}`
    )
      // Axios.get(`/Exam/GetSubjectsWithAvailableExam0`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_USER_METRICS_SUCCESS,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_USER_METRICS_FAIL,
          payload: error.message,
        });
        on500Fail(error.response);
      });
  };
};

// api that displays the details of the user by profileId
export const fetchUserMetricsByProfileId = (payload) => {
  return (dispatch) => {
    Axios.get(
      `/AdminProfile/GetAdminUserProfilebyProfileId?ProfileId=${payload}`
    )
      // Axios.get(`/Exam/GetSubjectsWithAvailableExam0`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_USER_METRICS_BY_PROFILEID,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_USER_METRICS_BY_PROFILEID_FAIL,
          payload: error.message,
        });
      });
  };
};

export const GetSubscriptionModels = () => {
  return (dispatch) => {
    Axios.get(`/Subscription/GetSubscriptionModels`)
      // Axios.get(`/Exam/GetSubjectsWithAvailableExam0`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_USER_SUBSCRIPTIONS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_USER_SUBSCRIPTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const DeleteUserMetrics = (
  ProfileId,
  onSuccess = () => {},
  onFail = () => {}
) => {
  return (dispatch) => {
    Axios.delete(`/AdminProfile/DeleteUserMetrics?ProfileId=${ProfileId}`)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_USER_METRICS_SUCCESS,
          payload: false,
        });
        if (res.data.state == 1) {
          onSuccess(res);
        } else if (res.data.state == -1) {
          onFail(res.data);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_USER_METRICS_SUCCESS,
          payload: false,
        });
      });
  };
};

// api that displays user subscription type
// export const fetchSubscriptionStats = () => {
//   return (dispatch) => {
//     Axios.get(`/Subscription/GetSubscriptionStats`)
//       .then((response) => {
//         dispatch({
//           type: actionTypes.GET_SUBSCRIPTION_TYPE,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           // type: actionTypes.GET_USER_METRICS_FAIL,
//           payload: error.message,
//         });
//       });
//   };
// };
