import { useSelector } from "react-redux";

const usePayment = () => {
  const payment = useSelector((state) => state.payment);

  const { loading, transactionStatus, error, transactions, transaction, oneLoading } = payment;

  return {
    loading,
    transactionStatus,
    error,
    transactions,
    transaction,
    oneLoading
  };
};

export default usePayment;
