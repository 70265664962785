import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import schoolsdb from "../../assets/images/schoolsdb.png";
import studentsdb from "../../assets/images/studentsdb.png";
import parentsdb from "../../assets/images/parentsdb.png";
import {
  InsightsIcon,
  SimulateIcon,
  TailorIcon,
} from "../../assets/icons/Icons";
import useResponsive from "../../hooks/useMediaQuery";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: 'Student',
        imgPath: studentsdb,
        title: (
            <Typography color="#30312C" fontWeight={600} fontSize={"43.488px"}>
                An <span style={{ color: "#B9B30F" }}>intuitive learning </span> designed to help students prep for exams.
            </Typography>
        ),
        items: [
            {
                text: "Tailor your learning experience. Study at your own pace and choose subjects that matter to you. ",
                icon: <TailorIcon />,
            },
            {
                text: 'Simulate examination conditions with our interactive practice exams. Time yourself, answer questions, and receive instant feedback to enhance your examination readiness.',
                icon: <SimulateIcon />,
            },
            {
                text: "Gain insights into your strengths and areas for improvement. Our performance analytics provide detailed reports, helping you focus on the subjects that need more attention.",
                icon: <InsightsIcon />,
            },
        ]
    },
    {
        label: 'Schools',
        imgPath: schoolsdb,
        title: (
            <Typography color="#30312C" fontWeight={600} fontSize={"43.488px"}>
                An <span style={{ color: "#B9B30F" }}>intuitive learning </span> designed for the classroom.
            </Typography>
        ),
        items: [
            {
                text: "Easily track and monitor the academic progress of each student in real-time.",
                icon: <SimulateIcon />,
            },
            {
                text: 'Tailor learning plans to accommodate diverse student needs and learning styles.',
                icon: <SimulateIcon />,
            },
            {
                text: "Access detailed analytics and reports to make informed decisions based on student performance.",
                icon: <SimulateIcon />,
            },
        ]
    },
    {
        label: 'Parents',
        imgPath: parentsdb,
        title: (
            <Typography color="#30312C" fontWeight={600} fontSize={"43.488px"}>
                Strengthening Your Involvement in Your <span style={{ color: "#B9B30F" }}>Ward’s Education</span>
            </Typography>
        ),
        items: [
            {
                text: "Stay informed about your child's academic progress with real-time updates on grades, attendance, and performance.",
                icon: <SimulateIcon />,
            },
            {
                text: `Foster open communication with teachers and school staff for a collaborative approach to your child's education`,
                icon: <SimulateIcon />,
            },
            {
                text: "Gain insights into grading systems, assessment methods, and your child's overall academic performance.",
                icon: <SimulateIcon />,
            },
        ]
    },
];

const cateogries = [
  {
    category: "Students",
  },
];

function Carousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%", flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
          width: "100%",
        }}
      ></Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images?.map((step, index) => (
          <div key={step.label} style={{ width: "100%" }}>
            {Math?.abs(activeStep - index) <= 2 ? (
              <Grid container spacing={4} alignItems={"center"}>
                <Grid item md={6} xs={12}>
                  <img src={step?.imgPath} alt="" />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box>
                    {step?.title}
                    <List>
                      {step?.items?.map((item) => (
                        <ListItem key={item?.text}>
                          <ListItemIcon
                            sx={{
                              borderRadius: "50%",
                              p: 2,
                              mr: 3,
                              backgroundColor: "#FBFBFB",
                              boxShadow:
                                "0px 13.143px 38.552px rgba(13, 15, 28, 0.12)",
                            }}
                          >
                            {item?.icon}
                          </ListItemIcon>
                          <ListItemText primary={item?.text} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Grid>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{ justifyContent: "center" }}
      />
    </Box>
  );
}

export default Carousel;
