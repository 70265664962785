import React, { useEffect, useState } from 'react'
import Success from "../../assets/images/paymentSuccessImage.png"
import Fail from "../../assets/images/paymentFailedImage.png"
import Pending from "../../assets/images/paymentPendingImage.png"
import { Button, Typography } from '@mui/material'
import { ReactComponent as RetryIcon } from "../../assets/images/retryIcon.svg"


const TransactionStatus = ({ status, onClose }) => {

  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    let intervalCall = setInterval(() => {
      if (timeLeft > 0 && timeLeft) {
        setTimeLeft(timeLeft - 1);
      } else {
        onClose();
      };
    }, 1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [timeLeft]);

  return (
    <div className=" flex flex-col text-center items-center justify-center">
      <img src={status === "success" ? Success : status === "pending" ? Pending : Fail} alt="" width={"100px"} height={"auto"} />
      <Typography sx={{ fontSize: "24px", fontWeight: 600, mt: 1 }}>{status === "success" ? "Payment Successful" : status === "pending" ? "Payment Pending" : "Payment Failed"}  </Typography>
      <Typography sx={{ fontSize: "18px", mt: 0.5 }}>{status === "success" ? "We have successfully received your payment" : status === "pending" ? "Please wait while your payment is processing" : "Something went wrong, we could not process your payment"}.</Typography>
      <span>Redirecting in <span id="countdowntimer"></span>{timeLeft} seconds</span>
      <Button
        variant="contained"
        sx={{
          mt: 3,
          color: "white",
          borderColor: status === "success" ? "#22C45E" : status === "pending" ? "#FCC434" : "#FF0F00",
          bgcolor: status === "success" ? "#22C45E" : status === "pending" ? "#FCC434" : "#FF0F00",
          textTransform: "capitalize",
          padding: "7px 60px",
          borderRadius: "5px",
          '&:hover': {
            backgroundColor: status === "success" ? "#22C45E" : status === "pending" ? "#FCC434" : "#FF0F00",
          },
        }}
        startIcon={(status === "pending" || status === "fail") && <RetryIcon />}
        onClick={()=> onClose()}
      >
        {status === "success" ? "Go home" : status === "pending" ? "Check again" : "Retry Payment"}
      </Button>
    </div>
  )
}

export default TransactionStatus