import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  MenuList,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  DialogContent,
  Paper,
} from "@mui/material";
import usePermissions from "../../../hooks/usePermissions";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import { createSvgIcon } from "@mui/material/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addSingleAdminUser,
  assignAdminToRole,
  deactivateRole,
  deleteSitePages,
  getAdminSitePagesByRoleId,
  getAllAdminRoles,
  getAllSitePages,
  loadAdminUsersAction,
} from "../../../redux/permissions/permissionsActions";
import { handleExport, isEmpty, pagination } from "../../../helpers/helper";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import {
  BorderColor,
  DataUsage,
  PersonAddAlt1,
  PersonRemove,
} from "@mui/icons-material";
import { TableFooter } from "../../../components/tables/TableFooter";
import { useNotifications } from "../../../components/notifications/notifications";
import useAdminUser from "../../../hooks/useAdminUser";
import AddAdminUserDialog from "./AddAdminUserDialog";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PermissionTable from "./PermissionTable";
import EditAdminUserDialog from "./EditAdminUserDialog";
import Role from "./Role";
import EditRole from "./EditRole";
import EditPageDialog from "./EditPageDialog";
import RolePermissionDialog from "./RolePermissionDialog";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

const User = () => {
  const [value, setValue] = React.useState(0);
  const [profilePic, setProfilePic] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [openBM, setOpenBM] = React.useState(false);
  const [openBM2, setOpenBM2] = React.useState(false);
  const [showing1, setShowing1] = React.useState(true);
  const [showing2, setShowing2] = React.useState(false);
  const [showing3, setShowing3] = React.useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const open4 = Boolean(anchorEl4);
  const [selectedRowAdmin, setSelectedRowAdmin] = useState({});
  const [selectedRowRole, setSelectedRowRole] = useState({});
  const [selectedRowPage, setSelectedRowPage] = useState({});
  const [RoleID, setRoleId] = useState();
  const [open5, setOpen5] = useState(false);
  const [addRoleDisplay, setAddRoleDisplay] = useState(false);
  const [openEditRole, setOpenEditRole] = useState(false);
  const [openRolePermissions, setOpenRolePermissions] = useState(false);
  const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);
  const [openEditPageDialog, setOpenEditPageDialog] = useState(false);
  const [deletePageDialog, setDeletePageDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");

  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    role: "",
    phoneNumber: "",
  });

  const dispatch = useDispatch();
  const [ToastState, setToastState] = useNotifications();
  const { userInfo } = useAdminUser();
  const { loading, error, adminUsersData, adminRoles, sitePages } =
    usePermissions();

  useEffect(() => {
    dispatch(loadAdminUsersAction());
  }, []);

  useEffect(() => {
    dispatch(getAllAdminRoles());
  }, []);

  useEffect(() => {
    dispatch(getAllSitePages());
  }, []);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  const [currentPage2, setCurrentPage2] = React.useState(1);
  const [tableData2, setTableData2] = React.useState([]);
  const [currentPage3, setCurrentPage3] = React.useState(1);
  const [tableData3, setTableData3] = React.useState([]);
  React.useEffect(() => {
    setTableData(pagination(adminUsersData, 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, adminUsersData]);

  useEffect(() => {
    if (search != "" || search != null) {
      setTableData(
        pagination(
          adminUsersData.filter(
            (user) =>
              user.Firstname?.toLowerCase().includes(search.toLowerCase()) ||
              user.Surname?.toLowerCase().includes(search.toLowerCase()) ||
              user.Email?.toLowerCase().includes(search.toLowerCase()) ||
              user.RoleName?.toLowerCase().includes(search.toLowerCase())
          ),
          10,
          currentPage
        )
      );
    }
  }, [search, adminUsersData, currentPage]);
  React.useEffect(() => {
    setTableData2(pagination(adminRoles, 10, currentPage2));
    // eslint-disable-next-line
  }, [currentPage2, adminRoles]);

  useEffect(() => {
    if (search2 != "" && search2 != null) {
      setTableData(
        pagination(
          adminRoles.filter((role) =>
            role.roleName?.toLowerCase().includes(search2.toLowerCase())
          ),
          10,
          currentPage2
        )
      );
    }
  }, [search2, adminRoles, currentPage2]);

  React.useEffect(() => {
    setTableData3(pagination(sitePages, 10, currentPage3));
    // eslint-disable-next-line
  }, [currentPage3, sitePages]);

  const navigate = useNavigate();

  const handleClickOpenBM = () => {
    setOpenBM(true);
  };
  const handleCloseBM = () => {
    setOpenBM(false);
  };

  const handleClickOpenBM2 = () => {
    setOpenBM2(true);
  };
  const handleCloseBM2 = () => {
    setOpenBM2(false);
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClickMenu3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClickMenu4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleCloseMenu2 = () => {
    setAnchorEl2(null);
  };

  const handleCloseMenu3 = () => {
    setAnchorEl3(null);
  };

  const handleCloseMenu4 = () => {
    setAnchorEl4(null);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDisplay = () => {
    setShowing1(true);
    setShowing2(false);
    setShowing3(false);
  };

  const handleDisplay2 = () => {
    setShowing1(false);
    setShowing2(true);
    setShowing3(false);
  };

  const handleDisplay3 = () => {
    setShowing1(false);
    setShowing2(false);
    setShowing3(true);
  };

  const [uploading, setUploading] = useState(false);
  const [uploadedPicture, setUploadedPicture] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploading(true);

    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedPicture({
        file: file,
        base64String: reader.result,
      });
      setProfilePic(reader.result);
      setUploading(false);
    };
    reader.readAsDataURL(file);
  };

  const AdmindataForCSV = adminUsersData.map((item) => ({
    Surname: item.Surname,
    Firstname: item.Firstname,
    Othername: item.OtherNames ?? "-",
    Email: item.Email,
    Role: item?.RoleName ?? "-",
    ProfileId: item.ProfileId,
  }));

  const onSuccess = () => {
    dispatch(loadAdminUsersAction());
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Admin added successfully",
    });
  };

  const isEmailValid = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    debugger;
    if (!isEmailValid(input.email)) {
      setToastState({
        isOpen: true,
        message: "Email not valid",
        severity: "error",
      });
      setOpenBM(true);
      return;
    }
    if (
      !input.firstName ||
      !input.lastName ||
      !input.email ||
      !input.phoneNumber ||
      !input.role
    ) {
      setToastState({
        isOpen: true,
        message: "All fields are required",
        severity: "error",
      });
      setOpenBM(true);
      return;
    }
    const data = {
      email: input.email,
      surname: input.lastName,
      firstname: input.firstName,
      otherNames: input.middleName,
      phoneNumber: input.phoneNumber,
      roleId: input.role,
      passport: profilePic,

      profileId: userInfo?.data?.profileId,
      // adminSignUpDTOs: [
      //   {
      //     email: input.email,
      //     surname: input.lastName,
      //     firstname: input.firstName,
      //     otherNames: input.middleName,
      //     phoneNumber: input.phoneNumber,
      //     roleId: input.role,
      //     passport: profilePic
      //   }
      // ]
    };
    dispatch(addSingleAdminUser(data, onSuccess));
    setInput({
      ...input,
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      role: "",
    });
    setProfilePic("");
    setUploadedPicture(null);
  };

  const onSuccessAssignRole = () => {
    dispatch(loadAdminUsersAction());
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Role assigned successfully",
    });
  };
  const handleAssignRoleToAdmin = () => {
    if (!RoleID) return;
    const data = {
      profileId: selectedRowAdmin?.ProfileId,
      createdBy: userInfo?.data?.profileId,
      roleId: RoleID,
    };
    dispatch(assignAdminToRole(data, onSuccessAssignRole));
  };

  const handleEditAdminUser = () => {
    if (
      !input.firstName ||
      !input.lastName ||
      !input.email ||
      !input.phoneNumber
    )
      return;
    const data = {
      profileId: userInfo?.data?.profileId,
      adminSignUpDTOs: [
        {
          email: input.email,
          surname: input.lastName,
          firstname: input.firstName,
          otherNames: input.middleName,
          phoneNumber: input.phoneNumber,
          roleId: input.role,
          passport: profilePic,
        },
      ],
    };
    dispatch(addSingleAdminUser(data, onSuccess));
    setInput({
      ...input,
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      role: "",
    });
    setProfilePic("");
    setUploadedPicture(null);
  };

  const onDeleteRoleSuccess = () => {
    setDeleteRoleDialog(false);
    dispatch(getAllAdminRoles());
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Role deleted successfully",
    });
  };

  const onDeleteRoleFail = (res) => {
    setDeleteRoleDialog(false);
    setToastState({ isOpen: true, severity: "warning", message: res });
  };

  const handleDeactivateRole = () => {
    const data = {
      roleId: selectedRowRole?.roleId,
      profileId: userInfo?.data?.profileId,
    };
    dispatch(deactivateRole(data, onDeleteRoleSuccess, onDeleteRoleFail));
  };

  const onDeletePageSuccess = () => {
    setDeletePageDialog(false);
    dispatch(getAllSitePages());
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Page deleted successfully",
    });
  };

  const onDeletePageFail = (res) => {
    setDeletePageDialog(false);
    setToastState({ isOpen: true, severity: "warning", message: res });
  };

  const handleDeletePage = () => {
    const data = {
      PageId: selectedRowPage?.PageId,
      ProfileId: userInfo?.data?.profileId,
    };
    dispatch(deleteSitePages(data, onDeletePageSuccess, onDeletePageFail));
  };

  return (
    <>
      {!addRoleDisplay ? (
        <>
          <Box>
            <Grid container spacing={1} justify="space-between">
              <Grid item xs={6}>
                <Typography className="flex items-center space-x-3">
                  <h1 className="e-study-heading-one e-study-primary">
                    {showing1 ? "Users" : showing2 ? "Roles" : "Pages"}
                  </h1>{" "}
                  <Chip
                    label={
                      showing1
                        ? adminUsersData?.length
                        : showing2
                        ? adminRoles?.length
                        : sitePages?.length
                    }
                    sx={{ backgroundColor: "#FDF1D4", color: "#DEAB28" }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} className="flex items-end justify-end">
                <Box style={{ display: showing1 ? "block" : "none", mt: 5 }}>
                  {/* <button className="px-5 btn" style={{ backgroundColor: '#e6e6e6', color: '#40499D', borderRadius: '5px', padding: '8px 10px', fontSize: '15px', fontWeight: 400 }} onClick={() => handleExport(AdmindataForCSV, 'Admin_data')}><span className="mr-2"><UploadFileIcon /></span> Export</button> */}
                  <button
                    className="px-5 ml-5 btn"
                    style={{
                      backgroundColor: "#40499D",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "8px 10px",
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                    onClick={handleClickMenu}
                  >
                    <span className="mr-2">
                      <PlusIcon />
                    </span>{" "}
                    Add user
                  </button>
                </Box>
                <Box style={{ display: showing2 ? "block" : "none" }}>
                  <button
                    className="px-5 btn"
                    style={{
                      backgroundColor: "#fff",
                      color: "#40499D",
                      borderRadius: "5px",
                      padding: "8px 10px",
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                    onClick={() => handleClickOpenBM()}
                  >
                    <span className="mr-2">+</span> Assign Role
                  </button>
                  <button
                    className="px-5 ml-5 btn"
                    style={{
                      backgroundColor: "#40499D",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "8px 10px",
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                    onClick={() => {
                      setAddRoleDisplay(true);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <span className="mr-2">
                      <PlusIcon />
                    </span>{" "}
                    New Role
                  </button>
                </Box>
                <Box style={{ display: showing3 ? "block" : "none" }}>
                  <button
                    className="px-5 ml-5 btn"
                    style={{
                      backgroundColor: "#40499D",
                      color: "#fff",
                      borderRadius: "5px",
                      padding: "8px 10px",
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                    onClick={() => {
                      setAddRoleDisplay(true);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <span className="mr-2">
                      <PlusIcon />
                    </span>{" "}
                    New Page
                  </button>
                </Box>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{ width: "15%" }}
                >
                  <MenuItem>
                    <Box
                      onClick={() => {
                        handleCloseMenu();
                        handleClickOpenBM();
                      }}
                    >
                      <Grid container spacing={1} justify="space-between">
                        <Grid item xs={2}>
                          <PersonAddAlt1 />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              color: "#313131",
                              fontFamily: "Open Sans",
                              fontSize: "13px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "18.2px",
                              pl: 1,
                            }}
                          >
                            Single Upload
                          </Typography>
                        </Grid>
                        <Typography
                          sx={{
                            color: "#575757",
                            fontFamily: "Open Sans",
                            fontSize: "9px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "12.6px",
                            ml: 2,
                          }}
                        >
                          Create just one new user
                        </Typography>
                      </Grid>
                    </Box>
                  </MenuItem>
                  {/* <MenuItem>
                    <Box onClick={() => { handleCloseMenu(); handleClickOpenBM2() }}>
                      <Grid container spacing={1}
                        justify="space-between"
                      >
                        <Grid item xs={1}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2133_33683)">
                              <path d="M3.5 13.125H10.5V14H2.625V12.25H0.875V0H6.74707L9.49512 2.74805L12.25 5.50293V6.125H7.875V2.625H3.5V13.125ZM8.75 5.25H10.7529L8.75 3.24707V5.25ZM2.625 11.375V1.75H7.25293L6.37793 0.875H1.75V11.375H2.625ZM11.8125 8.57227L13.9795 10.7393L13.3643 11.3545L12.25 10.2471V14H11.375V10.2471L10.2607 11.3545L9.64551 10.7393L11.8125 8.57227ZM14 7V7.875H9.625V7H14Z" fill="black" />
                            </g>
                            <defs>
                              <clipPath id="clip0_2133_33683">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>


                        </Grid>
                        <Grid item xs={5}>
                          <Typography sx={{
                            color: '#313131',
                            fontFamily: 'Open Sans',
                            fontSize: '13px', fontStyle: 'normal', fontWeight: 600,
                            lineHeight: '18.2px',
                            pl: 1
                          }}>Bulk Upload</Typography>
                        </Grid>
                        <Typography sx={{
                          color: '#575757',
                          fontFamily: 'Open Sans',
                          fontSize: '9px', fontStyle: 'normal', fontWeight: 400,
                          lineHeight: '12.6px',
                          ml: 2
                        }}>Set up multiple new users <br /> at once</Typography>
                      </Grid>
                    </Box>
                  </MenuItem> */}
                </Menu>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="All Users"
                {...a11yProps(0)}
                sx={{ textTransform: "none" }}
                onClick={handleDisplay}
              />
              <Tab
                label="Roles"
                {...a11yProps(1)}
                sx={{ textTransform: "none" }}
                onClick={handleDisplay2}
              />
              <Tab
                label="Pages"
                {...a11yProps(1)}
                sx={{ textTransform: "none" }}
                onClick={handleDisplay3}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <PermissionTable
              tableData={tableData}
              setRoleId={setRoleId}
              setSelectedRowAdmin={setSelectedRowAdmin}
              handleClickMenu2={handleClickMenu2}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setSearch={setSearch}
              search={search}
              loading={loading}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box>
              <OutlinedInput
                id="outlined-adornment-password"
                //type={showPassword ? 'text' : 'password'}
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="filter"
                      //onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <SearchIcon sx={{ marginRight: 2 }} />
                    </IconButton>
                  </InputAdornment>
                }
                value={search2}
                onChange={(e) => setSearch2(e.target.value)}
                placeholder="Search by roles..."
                size="xs"
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <div className="relative overflow-x-auto rounded-lg shadow-md">
                <table className="w-full text-sm text-left">
                  <thead className="py-3 text-xs uppercase bg-white">
                    <tr className="border-b e-study-text-grey">
                      <th scope="col" className="px-6 py-3">
                        Role
                      </th>
                      <th scope="col" className="px-6 py-3">
                        No Of Users
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData2?.data?.map((role) => (
                      <tr className="bg-white border-b" key={role.roleId}>
                        <td
                          scope="row"
                          className="flex items-center px-6 py-4 "
                        >
                          {role.roleName}
                        </td>
                        <td className="px-6 py-4">{role.adminUserCount}</td>
                        <td className="px-6 py-4">
                          <div className="flex">
                            <div
                              className="flex items-center p-3 space-x-3 rounded-lg"
                              style={{
                                backgroundColor: `${
                                  role.isActive ? "#ECFEF5" : "#FFF2EB"
                                }`,
                              }}
                            >
                              <div
                                className={`h-2.5 w-2.5 rounded-full mr-2 ${
                                  role.isActive
                                    ? "e-study-bg-active-green"
                                    : "estudy-bg-inactive-red"
                                }`}
                              />{" "}
                              <span
                                className={`${
                                  role.isActive
                                    ? "e-study-active-green"
                                    : "estudy-inactive-red"
                                }`}
                              >
                                {role.isActive ? "Active" : "InActive"}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <MoreVertIcon
                            onClick={(e) => {
                              setSelectedRowRole(role);
                              dispatch(getAdminSitePagesByRoleId(role.roleId));
                              handleClickMenu3(e);
                            }}
                            className="cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-3">
                  <TableFooter
                    empty={isEmpty(tableData2.data)}
                    // loading={invoice.isLoading}
                    pageNumber={currentPage}
                    totalPages={tableData2.totalPages}
                    prevOnClick={() => setCurrentPage2(tableData2.previousPage)}
                    nextOnClick={() => setCurrentPage2(tableData2.nextPage)}
                    thereIsPreviousPage={tableData2.thereIsPreviousPage}
                    thereIsNextPage={tableData2.thereIsNextPage}
                  />
                </div>
              </div>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Box>
              <OutlinedInput
                id="outlined-adornment-password"
                //type={showPassword ? 'text' : 'password'}
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="filter"
                      //onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Search by roles..."
                size="xs"
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <div className="relative overflow-x-auto rounded-lg shadow-md">
                <table className="w-full text-sm text-left">
                  <thead className="py-3 text-xs uppercase bg-white">
                    <tr className="border-b e-study-text-grey">
                      <th scope="col" className="px-6 py-3">
                        S/N
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Page Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Page Icon
                      </th>

                      <th scope="col" className="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData3?.data?.map((page, i) => (
                      <tr className="bg-white border-b" key={page.PageId}>
                        <td className="px-6 py-4">{i + 1}</td>
                        <td
                          scope="row"
                          className="flex items-center px-6 py-4 "
                        >
                          {page.PageName}
                        </td>
                        <td className="px-6 py-4">{page.PageFolderIcon}</td>

                        <td className="px-6 py-4">
                          <MoreVertIcon
                            onClick={(e) => {
                              setSelectedRowPage(page);
                              handleClickMenu4(e);
                            }}
                            className="cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-3">
                  <TableFooter
                    empty={isEmpty(tableData3.data)}
                    // loading={invoice.isLoading}
                    pageNumber={currentPage3}
                    totalPages={tableData3.totalPages}
                    prevOnClick={() => setCurrentPage3(tableData3.previousPage)}
                    nextOnClick={() => setCurrentPage3(tableData3.nextPage)}
                    thereIsPreviousPage={tableData3.thereIsPreviousPage}
                    thereIsNextPage={tableData3.thereIsNextPage}
                  />
                </div>
              </div>
            </Box>
          </CustomTabPanel>

          {/* Add Admin User Dialog */}
          <AddAdminUserDialog
            openBM={openBM}
            handleCloseBM={handleCloseBM}
            uploadedPicture={uploadedPicture}
            uploading={uploading}
            handleFileChange={handleFileChange}
            input={input}
            setInput={setInput}
            handleSubmit={handleSubmit}
          />

          {/* Edit Admin User Dialog */}
          <EditAdminUserDialog
            openBM={editDialog}
            handleCloseBM={() => setEditDialog(false)}
            uploadedPicture={uploadedPicture}
            uploading={uploading}
            handleFileChange={handleFileChange}
            input={input}
            setInput={setInput}
            handleSubmit={handleEditAdminUser}
            selectedRowAdmin={selectedRowAdmin}
          />

          <Dialog
            open={openBM2}
            onClose={handleCloseBM2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography
                sx={{
                  color: "#313131",
                  fontSize: "16px",
                  fontWeight: 600,
                  fontFamily: "Open Sans",
                  lineHeight: "16px",
                }}
              >
                Add Multiple Users
              </Typography>
              <Typography
                sx={{
                  color: "#919191",
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  fontWeight: 100,
                  lineHeight: "14px",
                  mt: 1,
                }}
              >
                A default code will be sent to the user's email to reset
                password on creation of account.
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseBM2}
                style={{
                  position: "absolute",
                  top: "1px",
                  right: "1px",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <textarea
                    style={{ marginTop: ".8rem" }}
                    className="w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none btn focus:outline-none focus:shadow-outline"
                    rows="5"
                    onChange={(e) => setUsers(e.target.value)}
                  ></textarea>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => handleSubmit2()}
                style={{ backgroundColor: "#40499D" }}
                class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                Create Users
              </button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open5}
            onClose={handleClose5}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              <Typography
                sx={{
                  color: "#313131",
                  fontSize: "16px",
                  fontWeight: 600,
                  fontFamily: "Open Sans",
                  lineHeight: "16px",
                }}
              >
                Select Role
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose5}
                style={{
                  position: "absolute",
                  top: "1px",
                  right: "1px",
                  justifyContent: "flex-end",
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <select
                    name="roleId"
                    id="roleId"
                    // labelId="demo-select-small-label"
                    value={RoleID}
                    onChange={(e) => setRoleId(e.target.value)}
                    className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border mt-4"
                    disabled={loading}
                    required
                  >
                    <option value="">Select Role*</option>
                    {adminRoles?.map((option, i) => (
                      <option key={option.roleId} value={option.roleId}>
                        {option.roleName}
                      </option>
                    ))}
                  </select>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => {
                  handleClose5();
                  handleAssignRoleToAdmin();
                }}
                style={{ backgroundColor: "#40499D" }}
                class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                {!loading ? (
                  "Assign role"
                ) : (
                  <DataUsage size={24} className="ml-4 animate-spin" />
                )}
              </button>
            </DialogActions>
          </Dialog>

          {/* Edit Role Dialog */}
          <EditRole
            openEdit={openEditRole}
            handleCloseEdit={() => setOpenEditRole(false)}
            selectedRowRole={selectedRowRole}
            setToastState={setToastState}
          />

          {/* View Role Permissions Dialog */}
          <RolePermissionDialog
            openRolePermissionDialog={openRolePermissions}
            handleClosePage={() => setOpenRolePermissions(false)}
            selectedRowRole={selectedRowRole}
            setToastState={setToastState}
          />

          {/* Deactivate Admin Role Dialog */}
          <Dialog
            open={deleteRoleDialog}
            onClose={() => setDeleteRoleDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Paper sx={{ p: 1 }}>
                <h3 className="e-study-heading-three">
                  Are you sure you want to deactivate this role?
                </h3>
              </Paper>
            </DialogContent>
            <DialogActions>
              <div className="flex items-center justify-between space-x-3 lg:space-x-9">
                <span
                  className="cursor-pointer e-study-primary"
                  onClick={() => setDeleteRoleDialog(false)}
                >
                  No
                </span>
                <button
                  onClick={() => handleDeactivateRole()}
                  style={{ backgroundColor: "#FA7E77" }}
                  class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                >
                  {!loading ? (
                    "Yes"
                  ) : (
                    <DataUsage size={24} className="ml-4 animate-spin" />
                  )}
                </button>
              </div>
            </DialogActions>
          </Dialog>

          {/* Edit Page Dialog */}
          <EditPageDialog
            openEditPageDialog={openEditPageDialog}
            handleClosePage={() => setOpenEditPageDialog(false)}
            selectedRowPage={selectedRowPage}
            setToastState={setToastState}
          />

          {/* Delete Admin Pages Dialog */}
          <Dialog
            open={deletePageDialog}
            onClose={() => setDeletePageDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Paper sx={{ p: 1 }}>
                <h3 className="e-study-heading-three">
                  Are you sure you want to delete this page?
                </h3>
              </Paper>
            </DialogContent>
            <DialogActions>
              <div className="flex items-center justify-between space-x-3 lg:space-x-9">
                <span
                  className="cursor-pointer e-study-primary"
                  onClick={() => setDeletePageDialog(false)}
                >
                  No
                </span>
                <button
                  onClick={() => handleDeletePage()}
                  style={{ backgroundColor: "#FA7E77" }}
                  class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                >
                  {!loading ? (
                    "Yes"
                  ) : (
                    <DataUsage size={24} className="ml-4 animate-spin" />
                  )}
                </button>
              </div>
            </DialogActions>
          </Dialog>

          {/* Admin Users Actions */}
          <Paper
            sx={{ width: 320, maxWidth: "100%" }}
            className="shadow-lg"
            elevation={8}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleCloseMenu2}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              className="shadow-xl"
            >
              <MenuList>
                {/* <MenuItem>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText sx={{
                    color: '#313131',
                    fontFamily: 'Roboto',
                    fontSize: '12px', fontWeight: 400,
                    lineHeight: '14px'
                  }}
                    onClick={() => { handleCloseMenu2(); setEditDialog(true) }}
                  >
                    View profile</ListItemText>

                </MenuItem> */}
                <MenuItem>
                  <ListItemIcon>
                    <BorderColor />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      handleCloseMenu2();
                      setEditDialog(true);
                    }}
                  >
                    Edit user
                  </ListItemText>
                </MenuItem>

                <MenuItem>
                  <ListItemIcon>
                    <PersonRemove className="text-red-600" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    // onClick={() => { handleDeactivateSubject(subject) }}
                  >
                    Delete user
                  </ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <PowerSettingsNewIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      handleCloseMenu2();
                      setOpen5(true);
                    }}
                  >
                    Assign a role
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Paper>

          {/* Admin Roles Actions */}
          <Paper
            sx={{ width: 320, maxWidth: "100%" }}
            elevation={8}
            className="shadow-lg"
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl3}
              open={open3}
              onClose={handleCloseMenu3}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuList>
                <MenuItem>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      handleCloseMenu3();
                      setOpenRolePermissions(true);
                    }}
                  >
                    Permissions
                  </ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      handleCloseMenu3();
                      setOpenEditRole(true);
                    }}
                  >
                    Edit Role
                  </ListItemText>
                </MenuItem>

                {/* <MenuItem>
                  <ListItemText sx={{
                    color: '#313131',
                    fontFamily: 'Roboto',
                    fontSize: '12px', fontWeight: 400,
                    lineHeight: '14px'
                  }}
                    onClick={() => { handleDeactivateSubject(subject) }}

                  >Assign Users</ListItemText>
                </MenuItem> */}
                <MenuItem>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      handleCloseMenu3();
                      setDeleteRoleDialog(true);
                    }}
                  >
                    Deactivate Role
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Paper>

          {/* Admin Pages Actions */}
          <Paper
            sx={{ width: 320, maxWidth: "100%" }}
            elevation={8}
            className="shadow-lg"
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl4}
              open={open4}
              onClose={handleCloseMenu4}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuList>
                <MenuItem>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      handleCloseMenu4();
                      setOpenEditPageDialog(true);
                    }}
                  >
                    Edit Page
                  </ListItemText>
                </MenuItem>

                {/* <MenuItem>
                  <ListItemText sx={{
                    color: '#313131',
                    fontFamily: 'Roboto',
                    fontSize: '12px', fontWeight: 400,
                    lineHeight: '14px'
                  }}
                    onClick={() => { handleDeactivateSubject(subject) }}

                  >Assign Users</ListItemText>
                </MenuItem> */}
                <MenuItem>
                  <ListItemText
                    sx={{
                      color: "#313131",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                    onClick={() => {
                      handleCloseMenu4();
                      setDeletePageDialog(true);
                    }}
                  >
                    Delete Page
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Paper>
        </>
      ) : (
        /*======= Add Role Display */
        <Role setAddRoleDisplay={setAddRoleDisplay} />
      )}
      <ToastState />
    </>
  );
};

export default User;
