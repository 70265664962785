import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Gemstone from "../../assets/images/Gemstone.svg";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import PremiumInfoDialog from "./PremiumInfoDialog";
import Payment from "../../pages/student/payment/Payment";
import Subscription from "../Settings/Subscription";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& > .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  "& .MuiDialogTitle-root": {
    backgroundColor: "#FFD14A",
    height: "1px",
    borderRadius: "16.9px 16.9px 0px 0px",
    color: "#fff",
  },
  "& .MuiDialogContent-root": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    position: "relative",
  },
}));

export default function PremiumDialog({ open, handleClose }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [paymentModal, setPaymentModal] = React.useState(false)
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "18px" } }}
        maxWidth={"xl"}
      >
        <DialogTitle
          sx={{ m: 0, p: 1, textAlign: "center" }}
          id="customized-dialog-title"
        ></DialogTitle>
        <DialogContent>
          <Typography variant="h4" fontWeight={900} marginTop={5}>
            Subscribe
          </Typography>
          <Typography variant="h6" color="#777">
            Unlock a world of enhanced learning and get exclusive
            <br />
            access to all tests and study experience
            {/* Upgrade today for. */}
          </Typography>
          <Box
            sx={{
              webkitUserDrag: "none",
              userSelect: "none",
              khtmlUserDrag: "none",
              mozUserDrag: "none",
              oUserDrag: "none",
              userDrag: "none",
            }}
          >
            <img
              src={Gemstone}
              alt="Premium package"
              draggable="false"
              style={{
                webkitUserDrag: "none",
                userSelect: "none",
                khtmlUserDrag: "none",
                mozUserDrag: "none",
                oUserDrag: "none",
                userDrag: "none",
              }}
            />
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#b4b4b4",
              m: 3,
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            gap={4}
            p={3}
            bgcolor="#1A1C52"
            borderRadius="10px"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color="white">
              Pay now and enjoy access to all content on any subscription of your choice
              {/*Switch to a premium plan now and enjoy a discount of up to <br />
              10% on our highly favored annual subscription. */}
            </Typography>
            <Button
              sx={{
                color: "#fff",
                padding: "10px",
                textTransform: "inherit",
                bgcolor: "#5458C7",
                "&:hover": { bgcolor: "#5458C7" },
                cursor: "pointer"
              }}
              onClick={() => { handleClose(); navigate("/dashboard/settings?tab=3") }}
            >
              Subscribe
            </Button>
          </Box>
        </DialogActions>
      </BootstrapDialog>
      <PremiumInfoDialog open={openDialog} handleClose={handleCloseDialog} />

      <Dialog
        fullScreen={true}
        open={paymentModal}
        onClose={() => setPaymentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {/*<Payment setPaymentModal={setPaymentModal} /> */}
          <>
            <div
              className="md:w-[90%] sm:w-[100%] "
              style={{
                margin: "auto",
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Button
                  variant="none"
                  sx={{
                    mt: 3,
                    color: "black",
                    borderColor: "white",
                    textTransform: "capitalize",
                    paddingY: "10px",
                    borderRadius: "5px",
                  }}
                  endIcon={<WestIcon />}
                  onClick={() => setPaymentModal(false)}
                >
                  Back
                </Button>
                <Typography
                  display={"flex"}
                  gap={1}
                  variant="h6"
                  fontWeight={700}
                  fontSize={"20px"}
                >
                  Subscription and Payment
                </Typography>
                <Typography variant="body2" fontSize={"14px"}>
                  Choose a subscription plan and make payment seamlessly
                </Typography>
              </Box>
            </div>

            <Subscription />
          </>
        </DialogContent>

      </Dialog>
    </div>
  );
}
