import React from "react";
import { Badge, Box, Button, Grid, Stack, Typography } from "@mui/material";
import {
  HexagonIcon,
  LockIcon,
  LockedHexagonIcon,
} from "../../assets/icons/Icons";

const AchievementCards = ({ content }) => {
  return (
    <Box
      display="flex"
      gap={1}
      boxSizing={"border-box"}
      sx={{
        overflow:'hidden',
        "&:hover": {
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#DCDCDC",
            borderRadius: 2,
          },
        },
      }}
    >
      {content &&
        content?.map((item) => (
          <Box
            key={item?.id}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {item?.isLocked ? (
              <>
                <Box sx={{ position: "absolute" }}>
                  <Typography fontSize={"24px"}>{item?.emoji}</Typography>
                </Box>
                <Box
                  sx={{
                    zIndex: 99,
                    cursor: "pointer",
                    "&:hover": { ".lock": { cursor: "pointer" } },
                  }}
                >
                  <LockedHexagonIcon />
                </Box>
                <Box
                  className="lock"
                  sx={{
                    position: "absolute",
                    zIndex: 999,
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <LockIcon />
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ position: "absolute" }}>
                  <Typography fontSize={"24px"}>{item?.emoji}</Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100px",
                    height: "100px",
                  }}
                ></Box>{" "}
                <HexagonIcon />
              </>
            )}
          </Box>
        ))}
    </Box>
  );
};

export default AchievementCards;
