// material
import { styled } from "@mui/material/styles";
import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";
// utils
// import generalFunc from "../../Utils/generalFunc";
//
// import { fShortenNumber } from "../../Utils/formatNumber";
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  //   padding: theme.spacing(1),
  color: theme?.palette?.brandPrimary?.black,
  backgroundColor: "transparent",
  borderRadius: "16px",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  filter: "drop-shadow(0px 4px 14px rgba(179, 179, 179, 0.25))",
  cursor: "text",
  userSelect: "none",
  maxHeight: "80px",
  justifyContent: "flex-start",
  boxSizing: "border-box",
}));

const IconWrapperStyle = styled("div")(({ theme, type }) => ({
  display: "flex",
  borderRadius: "30%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  backgroundColor: "#fff",
  color: "yellow",
  boxShadow:
    "0px 4.6372857093811035px 12.752534866333008px -1.1593214273452759px rgba(177, 177, 177, 0.25)",
}));

// ----------------------------------------------------------------------

export default function CardsStats({ cards }) {
  return (
    <Box>
      <Grid container spacing={1} alignItems="center">
        {cards &&
          cards?.map((card) => (
            <Grid key={card.text} item xs sm md>
              <>
                {card?.loading ? (
                  <Skeleton variant="rounded" width="max-width" height="96px" />
                ) : (
                  <RootStyle>
                    <IconWrapperStyle type={card?.text}>
                      {card?.icon}
                    </IconWrapperStyle>
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight={700}
                        fontSize={"18px"}
                      >
                        {card?.total ?? 0}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          opacity: 0.72,
                          fontSize: "12px",
                          color: "#7E7E7E",
                          fontWeight: 500,
                        }}
                      >
                        {card?.text}
                      </Typography>
                    </Box>
                  </RootStyle>
                )}
              </>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
