import { Box, Container, Divider, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import AveragePerformancePerSubject from "../../components/dashboards/students/AveragePerformancePerSubject";
import { greeting } from "../../helpers/helper";
import useUser from "../../hooks/useUser";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  fetchStudentAveragePerformanceStat,
  fetchStudentDashboardStat,
  fetchStudiesInProgressStat,
  fetchStudyTime,
  fetchTestDetails,
} from "../../redux/dashboard/dashboardActions";
import useDashboard from "../../hooks/useDashboard";
import useTest from "../../hooks/useTest";
import { fetchSubjectsByProfileId } from "../../redux/test/testActions";
import SummaryCards from "../../components/dashboards/students/SummaryCards";
import CoreSubjectsCard from "../../components/dashboards/students/CoreSubjectsCard";
import SubjectStat from "../../components/dashboards/students/SubjectStat";
import NewStudiesInProgress from "../../components/dashboards/students/NewStudiesInProgress";
import UpgradeBotCard from "../../components/dashboards/students/UpgradeBotCard";
import LevelCard from "../../components/dashboards/students/LevelCard";
import StudyTimeChart from "../../components/dashboards/students/StudyTimeChart";
import ReportCard from "../../components/dashboards/students/ReportCard";
import GoPremium from "../../components/dashboards/students/GoPremium";
import TestDashboardImages from "../../assets/images/TestDashboardImages";
import { ReactComponent as CompletedSubjects } from "../../assets/images/CompletedSubjects.svg";
import { ReactComponent as CompletedStudy } from "../../assets/images/CompletedStudies.svg";
import { ReactComponent as AverageTimeTest } from "../../assets/images/AverageTimeTest.svg";
import { ReactComponent as AverageTimeStudy } from "../../assets/images/AverageTimeStudy.svg";
import CompletedStudiesBg from "../../assets/images/CompletedStudiesBg.png";
import CompletedTestsBg from "../../assets/images/CompletedTestsBg.png";
import AverageTimeTestBg from "../../assets/images/AverageTimeTestBg.png";
import AverageTimeStudyBg from "../../assets/images/AverageTimeStudyBg.png";
import { ReactComponent as BestSubjectIcon } from "../../assets/images/BestSubject.svg";
import { ReactComponent as FailedSubjectIcon } from "../../assets/images/WorstSubject.svg";
import TestScores from "../../components/dashboards/students/MyTestScores/TestScores";
import LearningCards from "../../components/dashboards/students/LearningCards/LearningCards";
import DashboardOnboarding from "../../components/dashboards/students/DashboardOnboarding/DashboardOnboarding";
import useSubscription from "../../hooks/useSubscription";
import { useLocation } from "react-router-dom";
import { CheckUserSub } from "../../redux/subscriptions";
import Preloader from "../../components/AuthReuseables/Preloader/Preloader";
import Loader from "../../components/AuthReuseables/Preloader/Loader";
import LockIcon from "@mui/icons-material/Lock";

function formatTime(seconds) {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    return `${hours}h ${minutes}m`;
  } else {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}m`;
  }
}

function addZero(value) {
  if (value > 0 && value < 10) {
    return "0" + value;
  } else {
    return value;
  }
}

function getCurrentWeekDates() {
  const today = new Date();
  const day = today.getDay();
  const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
  const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - day));

  const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
  const startDateString = startOfWeek.toLocaleDateString("en-GB", options).replace(/\//g, "/");
  const endDateString = endOfWeek.toLocaleDateString("en-GB", options).replace(/\//g, "/");

  return { start: startDateString, end: endDateString };
}

const { start, end } = getCurrentWeekDates();

function StudentHome() {
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { subjectsData } = useTest();
  const {
    dashboardStudyIPStat,
    dashboardTestStat,
    dashboardStat,
    dashboardStudyTime,
    loadingOn,
    isLoadingg,
    loadingg,
    dashboardTestDetails,
    isLoadingTest,
  } = useDashboard();
  const { checkSub, loading } = useSubscription();
  const [pathSubjects, setPathSubjects] = useState([]);
  const [studiesInProgress, setStudiesInProgress] = useState([]);
  const [averageTest, setAverageTest] = useState([]);

  const location = useLocation();
  const paymentModal = location?.state?.paymentModal;
  const subjectModal = location?.state?.subjectModal;

  useEffect(() => {
    dispatch(fetchSubjectsByProfileId(userInfo?.profileId));
  }, [userInfo]);

  useEffect(() => {
    if (subjectsData) {
      setPathSubjects(subjectsData);
    }
  }, [subjectsData]);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudentDashboardStat(userProfileId));
    }
  }, [userInfo]);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudiesInProgressStat(userProfileId));
    }
  }, [userInfo]);

  useEffect(() => {
    if (dashboardStudyIPStat) {
      setStudiesInProgress(dashboardStudyIPStat);
    }
  }, [dashboardStudyIPStat]);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudentAveragePerformanceStat(userProfileId));
    }
  }, [userInfo]);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudyTime(userProfileId));
    }
  }, [userInfo]);

  useEffect(() => {
    if (dashboardTestStat) {
      setAverageTest(dashboardTestStat);
    }
  }, [dashboardTestStat]);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchTestDetails(userProfileId));
    }
  }, [userInfo]);

  const performanceCard = [
    {
      icon: <CompletedSubjects />,
      title: "Completed Subjects",
      subject: dashboardStat?.noOfCoreSubjectStudiesCompleted ?? 0,
    },
    {
      icon: <BestSubjectIcon />,
      title: "Best performing subject",
      subject: dashboardStat?.bestPerformingSubject ?? "None",
    },
    {
      icon: <FailedSubjectIcon />,
      title: "Subject that needs work",
      subject: dashboardStat?.poorPerformingSubject ?? "None",
    },
  ];

  return (
    <>
      <Container sx={{ padding: { xs: "0px" } }}>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {/* {checkSub?.msg === "InActive" ? (
              <DashboardOnboarding paymentModalValue={paymentModal} />
            ) : ( */}
            <Box component={Stack} gap={3} padding={0} bgcolor={"#FAFAFB"}>
              {checkSub?.msg === "InActive" && <GoPremium />}

              <Typography sx={{ fontWeight: 500, color: "#191635", fontSize: "18px" }}>Overview</Typography>

              <div
                className="grid gap-[25px] xl:grid-cols-4"
              // component={"section"}
              // width={"100%"}
              // display={"flex"}
              // flexDirection={{ xs: "column", md: "row" }}
              // alignItems={"stretch"}
              // gap={3}
              // position="relative"
              >
                <div className={`${userInfo?.isSuperSchoolAdmin ? 'xl:col-span-4' : 'xl:col-span-3'}`}>
                  <Grid container spacing={2}>
                    {performanceCard?.map((card, index) => (
                      <SubjectStat key={card.title} {...card} />
                    ))}
                  </Grid>

                  <Box sx={{ mt: 3 }}>
                    <LearningCards />
                  </Box>
                </div>

                {!userInfo?.isSuperSchoolAdmin &&
                  <div className="col-span-1">
                    {checkSub?.msg === "InActive" ? (
                      <Tooltip title="Go Premium to Customize your subjects">
                        <div className="relative">
                          <CoreSubjectsCard subjectModal={subjectModal} />
                          <div className="absolute bg-[#313131] opacity-80 inset-0 flex justify-center items-center">
                            <LockIcon style={{ color: "#FFF", fontSize: "40px" }} />
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <CoreSubjectsCard subjectModal={subjectModal} />
                    )}
                  </div>
                }

              </div>

              <Box component={"section"} width={"100%"} display={"flex"} flexDirection={{ xs: "column", md: "row" }} gap={3}>
                <NewStudiesInProgress data={studiesInProgress} loading={loadingg} />
                <TestScores data={dashboardTestDetails} loading={isLoadingTest} />
              </Box>

              <Box component={"section"} width={"100%"} display={"flex"} flexDirection={{ xs: "column", md: "row" }} gap={3}>
                <Box
                  component={Paper}
                  elevation={0}
                  width={{ xs: "100%", md: "50%" }}
                  py={2}
                  px={1}
                  sx={{
                    borderRadius: "14px",
                    border: "1px solid #E8E8E8",
                    boxShadow: "0px 8.6px 25.8px 0px rgba(171, 171, 171, 0.40)",
                  }}
                >
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h6" fontWeight={500} sx={{ fontSize: { xs: "14px", sm: "18px" }, pl: 2, color: "#191635" }}>
                      Study Time
                    </Typography>
                    <Typography variant="h6" fontWeight={500} sx={{ fontSize: { sm: "12px" }, color: "#888", pr: 2 }}>
                      {`${start} `} <span style={{ color: "#000" }}>-</span> {`${end} `}
                    </Typography>
                  </Box>
                  <StudyTimeChart studyTimeData={dashboardStudyTime} loading={loadingOn} />
                  <Divider variant="middle" />
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} pt={1}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px" }, color: "#888", pl: 2 }}>Total Study Time (this week)</Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: { xs: "14px", sm: "20px" },
                        fontWeight: { xs: "600" },
                        pr: 2,
                      }}
                    >
                      {formatTime(dashboardStudyTime?.totalWeekTime ?? "")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  component={Paper}
                  maxHeight={"auto"}
                  elevation={0}
                  width={{ xs: "100%", md: "50%" }}
                  borderRadius={"14px"}
                  p={2}
                  sx={{
                    border: "1px solid #E8E8E8",
                    boxShadow: "0px 8.6px 25.8px 0px rgba(171, 171, 171, 0.40)",
                  }}
                >
                  <AveragePerformancePerSubject data={averageTest} loading={isLoadingg} />
                </Box>
              </Box>
            </Box>
            {/* )} */}
          </React.Fragment>
        )}
      </Container>
    </>
  );
}

export default StudentHome;
