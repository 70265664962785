import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetExamByExamId } from "../../../redux/contentUpload/contentUploadActions";
import useContentUpload from "../../../hooks/useContentUpload";

import ContentUploadQuestionManagerMCQInputs from "./ContentUploadQuestionManagerMCQInputs";
import ContentUploadQuestionManagerTheoryInputs from "./ContentUploadQuestionManagerTheoryInputs";

const ContentUploadMCQOrTheoryInput = ({
  input,
  setInput,
  exam,
  setToastState,
}) => {
  const dispatch = useDispatch();

  const { singleExamData, loadingExam } = useContentUpload();

  useEffect(() => {
    if (exam) {
      dispatch(GetExamByExamId(exam?.examId));
    } else {
      dispatch(GetExamByExamId(input?.examId));
    }
  }, []);

  return (
    <>
      {singleExamData?.examTypeId == 2 ? (
        exam ? (
          <ContentUploadQuestionManagerTheoryInputs
            input={input}
            setInput={setInput}
            setToastState={setToastState}
            exam={exam}
          />
        ) : (
          <ContentUploadQuestionManagerTheoryInputs
            input={input}
            setInput={setInput}
            setToastState={setToastState}
          />
        )
      ) : exam ? (
        <ContentUploadQuestionManagerMCQInputs
          input={input}
          setInput={setInput}
          setToastState={setToastState}
          exam={exam}
        />
      ) : (
        <ContentUploadQuestionManagerMCQInputs
          input={input}
          setInput={setInput}
          setToastState={setToastState}
        />
      )}
    </>
  );
};

export default ContentUploadMCQOrTheoryInput;
