import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useSettings from "../../../hooks/useSettings";
import { useDispatch } from "react-redux";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function LgaAsynchronous({
  disabled,
  updateLgaValue,
  updateSchoolValue,
  lgaHandler,
  lgaValues,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { lgaData, lgaLoaded, lgaLoading } = useSettings();
  const loading = open && options.length === 0;
  const [lgaSelect, setLgaSelect] = React.useState([]);
  const dispatch = useDispatch();

  // This effect populates the lga select options
  React.useEffect(() => {
    const createLgaSelect = () => {
      let lgaArray = [];

      lgaData?.map((lga) => {
        let option = { value: lga?.LgaID, label: lga?.LgaName };
        return lgaArray?.push(option);
      });
      setLgaSelect(lgaArray);
    };
    lgaData?.length !== 0 && createLgaSelect();
  }, [lgaData]);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);
      if (active) {
        setOptions(lgaSelect);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <>
      <Autocomplete
        onKeyDown={handleKeyDown}
        autoHighlight
        id="asynchronous-demo"
        readOnly={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={lgaValues || ""}
        onChange={(event, value) => {
          if (value) {
            lgaHandler(event, value);
            updateLgaValue(value);
            //  updateSchoolValue({ label: "", value: 0 });
          }
          else {
            updateLgaValue({ label: "", value: 0 })
          }
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option?.label || ""}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="LGA"
            InputProps={{
              ...params.InputProps,
              endAdornment: !disabled ? (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ) : (
                ""
              ),
            }}
          />
        )}
      />
    </>
  );
}
