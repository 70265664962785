import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AlertBuble } from "../../assets/icons/Icons";
import { SvgColor, SvgTitle } from "../../assets/themes/Color";
import { AlertTitle } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  const { severity } = props;
  const title = SvgTitle[severity] || "";
  return (
    <MuiAlert
      sx={{ width: "100%", borderRadius: "24px", alignItems: "center" }}
      title={{
        info: "Hi there",
        error: "Error!!!",
      }}
      elevation={6}
      ref={ref}
      variant="filled"
      // {...otherProps}
      {...props}
      //   title={SvgTitle[toastState.severity]}
    >
      <div>
        <div
          style={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </div>
        {props.children}
      </div>
    </MuiAlert>
  );
});

const useNotifications = () => {
  const [toastState, setToastState] = React.useState({
    isOpen: false,
    severity: "",
    message: "",
  });

  const toggle = () => {
    setToastState((prev) => {
      return { ...prev, isOpen: !prev.isOpen };
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastState((prev) => {
      return { ...prev, isOpen: false };
    });
  };

  const Toast = () => {
    return (
      <Snackbar
        className="relative"
        sx={{ borderRadius: 36 }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toastState.isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={toastState.severity}>
          <div style={{ display: "flex", alignItems: "end", margin: "8px" }}>
            <AlertBuble
              alt="Alert Icon"
              color={SvgColor[toastState.severity]}
              className="hidden"
              style={{
                display: "block",
                position: "absolute",
                bottom: "0",
                left: "0",
                borderRadius: 18,
              }}
            />
            {/* <AlertTitle /> */}
            {toastState.message}
          </div>
        </Alert>
      </Snackbar>
    );
  };

  return [Toast, setToastState];
};

export { useNotifications };
