import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "../../../components/DataTable/DataTable";
import { MoreIcon } from "../../../assets/icons/Icons";
import AvatarWithText from "../../../components/Avatar/AvatarWithText";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert as MoreVertIcon, Edit, Delete } from "@mui/icons-material";
import { formatDate } from "../../../helpers/helper";
import moment from "moment";

const TableData = ({ courseData, openEditModal, sessionId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Options">
        <IconButton size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            width: 250,
          },
        }}
      >
        <MenuItem
          onClick={() => openEditModal("edit", sessionId, courseData.rowData)}
        >
          <Edit color="primary" /> Edit Session
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            // Handle delete action
          }}
        >
          <Delete color="error" /> Deactivate Session
        </MenuItem>
      </Menu>
    </>
  );
};

const SessionTable = ({ student, openEditModal, allSessions }) => {
  const columns = [
    {
      name: "S/N",
      label: "S/N",
      options: {
        setCellProps: () => ({ style: { padding: "20px" } }),
        customBodyRender: (value, tableMeta) => {
          return <span>{tableMeta.rowIndex + 1} </span>;
        },
      },
    },
    {
      name: "SessionName",
      label: "Session Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          // Render your custom body here
          return value; // For example, just return the value for simplicity
        },
      },
    },
    {
      name: "StartDate",
      label: "Start Date",
      options: {
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
        customBodyRender: (value, tableMeta) =>
          value === undefined || value === ""
            ? "not updated"
            : moment(value).format("MMM DD YYYY"),
      },
    },
    {
      name: "EndDate",
      label: "End Date",
      options: {
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
        customBodyRender: (value, tableMeta) =>
          value === undefined || value === ""
            ? "not updated"
            : moment(value).format("MMM DD YYYY"),
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value, tableMeta) => {
          // Render your custom body here
          const textStyle = {
            color: value === "true" ? "green" : "red", // Set color to green for Active and red for Inactive
          };
          const displayValue = value === "true" ? "Active" : "Inactive";
          return <span style={textStyle}>{displayValue}</span>; // For example, just return the value for simplicity
        },
      },
    },
    {
      name: "SessionId",
      label: "Actions",
      options: {
        customBodyRender: (rowData, tableMeta) => {
          return (
            <TableData
              courseData={tableMeta}
              // openEditModal={openEditModal}
              openEditModal={() => openEditModal("edit", tableMeta)}
              sessionId={rowData}
            />
          );
        },
      },
    },
  ];

  return (
    <>
      <DataTable data={allSessions} columns={columns} />
    </>
  );
};

export default SessionTable;
