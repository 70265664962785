// User Metrics
export const GET_USER_METRICS = "GET_USER_METRICS";
export const GET_USER_METRICS_SUCCESS = "GET_USER_METRICS_SUCCESS";
export const GET_USER_METRICS_FAIL = "GET_USER_METRICS_FAIL";
export const GET_USER_METRICS_BY_PROFILEID = "GET_USER_METRICS_BY_PROFILEID";
export const GET_USER_METRICS_BY_PROFILEID_FAIL =
  "GET_USER_METRICS_BY_PROFILEID_FAIL";

//subscription
export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS";
export const GET_USER_SUBSCRIPTIONS_FAIL = "GET_USER_SUBSCRIPTIONS_FAIL";
