import React, { useEffect } from "react";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import SingleStudentForm from "./SingleStudentForm";
import { useDispatch } from "react-redux";
import useSchool from "../../hooks/useSchool";
import { fetchClassLevel } from "../../redux/school";

const content = [
  {
    id: "1",
    title: "Enrol a single student",
    text: "Enrol a single student to gain access to the premium content available.",
  },
  {
    id: "2",
    title: "Enrol multiple students ",
    text: "Enrol many students quickly to gain access to the premium content available.",
  },
];

const button = ["Add Student", "Bulk Import"];

const icon = [
  <PersonIcon fontSize={`large`} />,
  <GroupsIcon fontSize={`large`} />,
];

const Enroll = ({ addSingleStudent, addBulkImport, handleAddStudent, closeModal, handleBulkImport, setToastState, refreshParent }) => {

  const school = useSchool();
  const dispatch = useDispatch();

  const { classLevel } = school;

  useEffect((
  ) => {
    dispatch(fetchClassLevel())
  }, [])

  return (
    <div className="">
      {addSingleStudent ? (
        <SingleStudentForm
          level={classLevel}
          closeModal={closeModal}
          setToastState={setToastState} />
      ) : (
        <div className="flex flex-col bg-white rounded-2xl mb-12">

          <div className=" my-6 mx-12  flex items-center space-x-4">
            {content.map((content, i) => (
              <div className="bg-[#F0F2FF] py-6 px-6 rounded-2xl">
                <div className="flex items-center justify-between pb-6">
                  <h3 className="text-xl font-medium">{content.title}</h3>
                  {/* <PersonIcon fontSize={`large`} /> */}
                  <div>{icon[i]}</div>
                </div>

                <p className="text-[16px] pb-8">{content.text}</p>
                <button
                  className="rounded-[6px] bg-[#40499D] px-[16px] py-2 text-[12px] text-white"
                  onClick={() =>
                    i === 0 ? handleAddStudent() : handleBulkImport()
                  }
                >
                  {button[i]}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Enroll;