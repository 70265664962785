import { useSelector } from "react-redux";

const useAuditTrail = () => {
  const auditTrail = useSelector((state) => state.auditTrail);

  const { loading, allAudits, totalAudit } = auditTrail;

  return {
    allAudits,
    loading,
    totalAudit,
  };
};
export default useAuditTrail;
