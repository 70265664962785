
export const ALL_ADMIN_USERS_START = "ALL_ADMIN_USERS_START";
export const ALL_ADMIN_USERS_SUCCESS = "ALL_ADMIN_USERS_SUCCESS";
export const ALL_ADMIN_USERS_FAIL = "ALL_ADMIN_USERS_FAIL";

export const ALL_ADMIN_ROLES_START = "ALL_ADMIN_ROLES_START";
export const ALL_ADMIN_ROLES_SUCCESS = "ALL_ADMIN_ROLES_SUCCESS";
export const ALL_ADMIN_ROLES_FAIL = "ALL_ADMIN_ROLES_FAIL";

export const ADD_SINGLE_ADMIN_START = "ADD_SINGLE_ADMIN_START";
export const ADD_SINGLE_ADMIN_SUCCESS = "ADD_SINGLE_ADMIN_SUCCESS";
export const ADD_SINGLE_ADMIN_FAIL = "ADD_SINGLE_ADMIN_FAIL";

export const ASSIGN_ADMIN_ROLE_START = "ASSIGN_ADMIN_ROLE_START";
export const ASSIGN_ADMIN_ROLE_SUCCESS = "ASSIGN_ADMIN_ROLE_SUCCESS";
export const ASSIGN_ADMIN_ROLE_FAIL = "ASSIGN_ADMIN_ROLE_FAIL";


export const ADD_ROLE_START = "ADD_ROLE_START";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";

export const ALL_SITE_PAGES_START = "ALL_SITE_PAGES_START";
export const ALL_SITE_PAGES_SUCCESS = "ALL_SITE_PAGES_SUCCESS";
export const ALL_SITE_PAGES_FAIL = "ALL_SITE_PAGES_FAIL";

export const ALL_SITE_PAGES_ROLE_SUCCESS = "ALL_SITE_PAGES_ROLE_SUCCESS";
export const ALL_SITE_PAGES_ROLE_FAIL = "ALL_SITE_PAGES_ROLE_FAIL";

export const ADMIN_SITE_PAGES_ROLE_START = "ADMIN_SITE_PAGES_ROLE_START";
export const ADMIN_SITE_PAGES_ROLE_SUCCESS = "ADMIN_SITE_PAGES_ROLE_SUCCESS";
export const ADMIN_SITE_PAGES_ROLE_FAIL = "ADMIN_SITE_PAGES_ROLE_FAIL";

export const ADD_PAGES_START = "ADD_PAGES_START";
export const ADD_PAGES_SUCCESS = "ADD_PAGES_SUCCESS";
export const ADD_PAGES_FAIL = "ADD_PAGES_FAIL";