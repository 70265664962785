import React, { useRef, useEffect } from 'react';
import mission from "../../../assets/audios/mission.mp3";

function MissionSound() {
  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;

    // Configure audio properties, including looping
    if (audioElement) {
      audioElement.loop = false; // Disable looping to handle manually
      audioElement.play();

      // Add a delay between loops (e.g., 5 seconds)
      const delayBetweenLoops = 5000; // 5 seconds in milliseconds

      audioElement.addEventListener('ended', () => {
        // Use a setTimeout to play the audio again after the delay
        setTimeout(() => {
          audioElement.play();
        }, delayBetweenLoops);
      });
    }
  }, []);

  return (
    <div>
      {/* Hide the UI controls */}
      <audio ref={audioRef} controls={false}>
        <source src={mission} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default MissionSound;
