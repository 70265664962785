import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import wavinghand from "../../../../assets/images/wavinghand.gif";
import focusImage from "../../../../assets/images/focusImage.gif";
import flaggif from "../../../../assets/images/flaggif.gif";
import { useDispatch } from "react-redux";
import { resetQuestions } from "../../../../redux/test/testActions";
import useTest from "../../../../hooks/useTest";
import { ACTIVATE_TEST } from "../../../../redux/test/types";
import CheckIcon from "@mui/icons-material/Check";
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import AlarmIcon from '@mui/icons-material/Alarm';
import CalculateIcon from '@mui/icons-material/Calculate';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import TimerIcon from '@mui/icons-material/Timer';
import AppsIcon from '@mui/icons-material/Apps';
import StartIcon from '@mui/icons-material/Start';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useGoogleAnalytics } from "../../../../hooks/usePageTracking";

const TestStartPage = () => {
  const dispatch = useDispatch();
  const test = useTest();
  const { questions, testName, allocatedTime } = test;
  const { trackPage, trackSpecificEvent } = useGoogleAnalytics()
  trackPage()

  const instructionList = [
    {
      text: "Read the questions carefully. Please ensure you stay in a quiet environment to take this test to achieve the utmost concentration during this exercise.",
      icon: <DoNotDisturbOnTotalSilenceIcon />,
    },
    {
      text: `The test will last for ${allocatedTime / 60} mins and is made up of multiple choice. `,
      icon: <AlarmIcon />,
    },
    {
      text: "Ensure you have a calculator with you where necessary. ",
      icon: <CalculateIcon />,
    },
    {
      text: "The flag feature on the page helps you to mark a question you want to answer later.",
      icon: <EmojiFlagsIcon />,
    },
    {
      text: "You will see an overview of all questions you are answering and the flagged ones on the right side of your page.",
      icon: <AppsIcon />,
    },
    {
      text: "Click on the start test button below to immediately proceed with the test. ",
      icon: <StartIcon />,
    },
    {
      text: "The timer will start counting as soon as you see the test page. Best of luck!",
      icon: <TimerIcon />,
    },
  ];

  const activateTest = () => {
    trackSpecificEvent("mock_exam_started", {
      test_name: testName,
    })
    dispatch({
      type: ACTIVATE_TEST,
    });
  };

  return (
    <Container sx={{ padding: { xs: "0px" } }}>
      <Box
        sx={{ paddding: { xs: "0px", lg: "24px" } }}
        display={"flex"}
        flexDirection={"column"}
        gap={4}
      >
        <Box display="flex" alignItems="center">
          <Link to="/dashboard/learning/subjects">
            <IconButton onClick={() => dispatch(resetQuestions())}>
              <KeyboardBackspaceIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Link>
          <Box fontSize="20px" fontWeight="bold">
            Mock Examination
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          flexWrap={"wrap"}
        >
          {/* Practice Questions */}
          <Box>
            <Box fontSize="20px" color={"#DEAB28"}>
              Practice Examination
            </Box>
            <Box fontSize="24px" fontWeight="bold">
              {testName}
            </Box>
          </Box>
          {/* Questions and Minutes */}
          <Box display="flex" alignItems="center" mt={2}>
            <Box>
              <Box fontSize="14px" fontWeight={600} sx={{ color: '#313131' }}>QUESTIONS</Box>
              <Box fontSize="28px" fontWeight="bold" display="flex" alignItems="center" gap="5px">
                <HelpOutlineIcon fontSize="30px" />  {questions.length}
              </Box>
            </Box>
            <Box mx={2}>
              <Box fontSize="24px" fontWeight="bold">
                {/* • */}
              </Box>
            </Box>

            <Box>
              <Box fontSize="14px" fontWeight={600} sx={{ color: '#313131' }}>MINUTES</Box>

              <Box fontSize="28px" fontWeight="bold" display="flex" alignItems="center" gap="5px">
                <AccessTimeIcon fontSize="30px" />{allocatedTime / 60} mins
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          {/* Instructions Header */}
          <Typography
            variant="h6"
            gutterBottom
            fontSize={"22px"}
            fontWeight={600}
          >
            Instructions
          </Typography>
          {/* Bullet List */}
          <List>
            {instructionList?.map((item) => (
              <ListItem key={item?.text}>
                <ListItemIcon sx={{ color: 'brandPrimary.black' }}>{item?.icon}</ListItemIcon>
                <ListItemText primary={item?.text} />
              </ListItem>
            ))}

            {/* Add more list items as needed */}
          </List>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              onClick={() => activateTest()}
              color="yellowColor"
            >
              Start Test
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TestStartPage;
