import { Box, CardContent, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, NativeSelect, Paper, Radio, RadioGroup, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FlagIcon from "@mui/icons-material/Flag";
import 'katex/dist/katex.min.css';
import KatexRenderer from "../../../components/KatexRenderer";
import { TableFooter } from '../../../components/tables/TableFooter';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { fetchQuestionById } from '../../../redux/learning';
import { useDispatch } from 'react-redux';
import useLearning from '../../../hooks/useLearning';
import { isEmpty, pagination } from '../../../helpers/helper';
import { Close, DataUsage, West } from '@mui/icons-material';
import { fetchQuestionsByTopicId } from '../../../redux/learning/learningActions';
import { useNotifications } from '../../../components/notifications/notifications';
import TransparentButtton from '../../../components/buttons/TransparentButtton';
import useContentUpload from '../../../hooks/useContentUpload';
import { fetchExamQuestionsByTopicId, updateExamQuestionAction } from '../../../redux/contentUpload/contentUploadActions';
import { RTEViewer } from '../../../components/RTEViewer';
import ContentUploadMCQSolutionEdit from './ContentUploadMCQSolutionEdit';
import useAdminUser from '../../../hooks/useAdminUser';
import DataUsageIcon from "@mui/icons-material/DataUsage";
import Button from "@mui/material/Button";


const ContentUploadTopicQuestions = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const learning = useLearning();
  const navigate = useNavigate();
  const { userInfo } = useAdminUser();


  const { loadingQuestion } = learning;
  const { subjectData, topics, examQuestionData, loading, loadingExam } = useContentUpload();


  const [ToastState, setToastState] = useNotifications();

  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [editSolution, setEditSolution] = useState(false);
  const [examQuestion, setExamQuestion] = useState({});



  const { id } = params

  useEffect(() => {
    if (id) {
      dispatch(fetchExamQuestionsByTopicId(id, (res) => {
        // if (res?.length > 0) {
        //   dispatch(fetchQuestionById(res[0]?.ExamQuestionId));
        // } else {
        //   setToastState({
        //     isOpen: true,
        //     severity: 'warning',
        //     message: 'There are no questions available for this topic'
        //   })
        // }
      }));
    }
  }, [id]);

  // Pagination Function And Control
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  useEffect(() => {
    setTableData(pagination(examQuestionData, 10, currentPage));
    // eslint-disable-next-line
  }, [currentPage, examQuestionData]);

  const splitStringtoArray = (contentString) => {
    let contentArray;
    contentArray = contentString?.split("|||");
    contentArray.shift();
    return contentArray;
  };

  const [topicId, setTopicId] = useState('');


  const alphabetToNumber = (char) => {
    const code = char?.toLowerCase()?.charCodeAt(0);
    if (code >= 97 && code <= 122) { // 'a' to 'z'
      return code - 96;
    }
  }

  const onSuccess = () => {
    dispatch(fetchExamQuestionsByTopicId(id));
    setOpenEditQuestion(false);   
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Question updated successfully",
    });
  };

  const handleSubmit = () => {
    const data = {
      question: examQuestion?.question,
      choices: examQuestion?.choice,
      answer: examQuestion?.answer?.charAt(0),
      scoreWeight: examQuestion?.scoreWeight,
      examId: examQuestion?.examId,
      profileId: userInfo?.data?.profileId,
      examQuestionId: examQuestion?.examQuestionId,
      sectionId: examQuestion?.sectionId,
      isActive: examQuestion?.isActive,
      comment: examQuestion?.comment,
      topicId: topicId
    };
    dispatch(updateExamQuestionAction(data, (res) => onSuccess(res)));
  };

  return (
    <>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <IconButton
          size="large"
          onClick={() => {
            navigate(-1)
          }}
        >
          <West size="large" />{" "}
        </IconButton>
        <h3 className='font-medium e-study-heading-four capitalize text-e-study-bg-primary'>{ } ({topics?.find(t => t.TopicId === id)?.TopicName?.toLowerCase()})</h3>
      </Box>
      {loadingQuestion ?

        <div className={`bg-[#FEFEFE] rounded-[8px] mt-6 min-h-[357px] w-full p-8 flex flex-col gap-7 ${loadingQuestion && 'items-center justify-center'}`}>

          <DataUsage htmlColor="#40499D" size={30} className="animate-spin" />

        </div>
        :

        <>
          {tableData?.data?.map((examQuestion, i) => (
            <Box sx={{ mt: 2 }} key={i}>
              <Paper sx={{ minWidth: 275, borderRadius: "16px" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "28px",
                      }}
                    >
                      Question {i + 1}
                    </Typography>
                    <div className="flex space-x-3">
                      {examQuestion?.isActive === true ? (
                        <Tooltip title={examQuestion?.comment}>
                          <ToggleButtonGroup
                            color="secondary"
                            exclusive
                            // onClick={handleToggle}
                            aria-label="Platform"
                          >
                            <ToggleButton
                              value="active"
                              style={{ color: "red" }}
                            >
                              <FlagIcon />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Tooltip>
                      ) : null}


                      <TransparentButtton.Base
                        title={"Edit"}
                        className="bg-white e-study-button-black"
                        onClick={() => {
                          setExamQuestion(examQuestion);
                          setOpenEditQuestion(true);
                        }}
                      />

                      <TransparentButtton.Base
                        title={"Solution"}
                        className="bg-white e-study-button-green"
                        onClick={() => {
                          setExamQuestion(examQuestion);
                          setEditSolution(true);
                        }}
                      />

                    </div>
                  </Box>
                  <Box>
                    <Typography>
                      {/* <KatexRenderer expression={window.HTMLReactParser(
                        examQuestion?.question != null
                          ? examQuestion?.question
                          : ""
                      )} /> */}
                      <RTEViewer value={examQuestion?.question || ""} style={{ height: "fit-content" }} />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      // justifyContent: "center",
                      "& > :not(style)": {
                        width: 900,
                      },
                    }}
                  >
                    <Paper elevation={0} sx={{ px: 2, borderRadius: 3 }}>
                      <FormControl style={{ width: "100%" }}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={examQuestion?.answer?.charAt(0)}
                          // onChange={handleChange}
                          name="radio-buttons-group"
                        >
                          {splitStringtoArray(examQuestion?.choice)?.map(
                            (subject, index) => (
                              <FormControlLabel
                                value={index + 1}
                                control={<Radio />}
                                label={window.HTMLReactParser(
                                  subject != null
                                    ? subject
                                    : ""
                                )}
                                style={{
                                  backgroundColor: `${examQuestion?.answer.charAt(0) ==
                                    index + 1
                                    ? "#EAECFF"
                                    : ""
                                    }`,
                                }}
                              />
                            )
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Box>
                </CardContent>
              </Paper>
            </Box>
          ))}

          <div className="mt-3">
            <TableFooter
              empty={isEmpty(tableData.data)}
              // loading={invoice.isLoading}
              pageNumber={currentPage}
              totalPages={tableData.totalPages}
              prevOnClick={() => setCurrentPage(tableData.previousPage)}
              nextOnClick={() => setCurrentPage(tableData.nextPage)}
              thereIsPreviousPage={tableData.thereIsPreviousPage}
              thereIsNextPage={tableData.thereIsNextPage}
            />
          </div>
        </>
      }

      <ToastState />
      <Dialog
        maxWidth='xl'
        open={openEditQuestion}
        onClose={() => setOpenEditQuestion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Update Question Topic
          <Typography
            sx={{
              color: "#919191",
              fontSize: "12px",
              fontStyle: "normal",
              lineHeight: "14px",
            }}
          >
            Assign question to a different topic below
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEditQuestion(false)}
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              justifyContent: "flex-end",
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>

          <div className='mb-4'>
            <Typography
              sx={{
                mt: 1,
                color: "#40499D",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "22.4px",
              }}
            >
              Topics
            </Typography>

            <NativeSelect
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={topicId}
              onChange={(e) => { setTopicId(e.target.value) }}
              fullWidth
              className='capitalize'
            >
              <option value="" disabled>
                Select
              </option>
              {topics?.map((topic) => (
                <option sx={{ ml: 3 }} value={topic?.TopicId} className='capitalize'>
                  {topic?.TopicName?.toLowerCase()}
                </option>
              ))}
            </NativeSelect>
          </div>
          <Button
            sx={{
              backgroundColor: "#40499D",
              borderRadius: 2,
              textTransform: "none",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            {loadingExam ? (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            ) : (
              "Update"
            )}
          </Button>

        </DialogContent>
      </Dialog>


      <Dialog
        fullWidth={true}
        // fullScreen={true}
        maxWidth={'lg'}
        open={editSolution}
        onClose={() => setEditSolution(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <ContentUploadMCQSolutionEdit
            examQuestion={examQuestion}
            setEditSolution={setEditSolution}
            setToastState={setToastState}
          />
        </DialogContent>
      </Dialog>

    </>
  )
}

export default ContentUploadTopicQuestions