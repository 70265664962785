import { Box, Skeleton } from "@mui/material";
import { BookDashIcon, FinanceIcon, FreemiumIcon, TimeIcon } from "../../assets/icons/Icons";


const getDashBoardData = (adminDashboardStat, isLoad) => { 
const data = [
  {
    icon: (
      <Box sx={{ backgroundColor: "#FBFAD6", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
        <FreemiumIcon sx={{ fontSize: { md: 34 }, color: "#8E8803" }} />
      </Box>
    ),
    name: "Onboarded Users",
    value:  isLoad ? (
      <Skeleton variant="text" width={100} animation="wave" />
    ) : (adminDashboardStat?.freemiumUsers)
  },
  {
    icon: (
      <Box sx={{ backgroundColor: "#EAECFF", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
        <FreemiumIcon sx={{ fontSize: { md: 34 }, color: "brandPrimary.main" }} />
      </Box>
    ),
    name: "Subscribed Users",
    value:  isLoad ? (
      <Skeleton variant="text" width={100} animation="wave" />
    ) : (adminDashboardStat?.proPlanUsers),
  },
  {
    icon: (
      <Box sx={{ backgroundColor: "#EEFFF9", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
        <FinanceIcon sx={{ fontSize: { md: 34 }, color: "#2AAC7E" }} />
      </Box>
    ),
    name: "Gross Revenue",
    value:  isLoad ? (
      <Skeleton variant="text" width={100} animation="wave" />
    ) : (adminDashboardStat?.grossRevenue),
  },
  {
    icon: (
      <Box sx={{ backgroundColor: "#FFEEEE", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
        <FinanceIcon sx={{ fontSize: { md: 34 }, color: "#E85050" }} />
      </Box>
    ),
    name: "Net Revenue",
    value:  isLoad ? (
      <Skeleton variant="text" width={100} animation="wave" />
    ) : (adminDashboardStat?.netRevenue),
  },
  {
    icon: (
      <Box sx={{ backgroundColor: "#F9F4FF", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
        <TimeIcon sx={{ fontSize: { md: 34 }, color: "#885CBC" }} />
      </Box>
    ),
    name: "Avg. Session Period",
    value:  isLoad ? (
      <Skeleton variant="text" width={100} animation="wave" />
    ) : (adminDashboardStat?.aveSessionPeriod),
  },
  {
    icon: (
      <Box sx={{ backgroundColor: "#E8FCFF", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
        <BookDashIcon sx={{ fontSize: { md: 34 }, color: "#079AAE" }} />
      </Box>
    ),
    name: "Most Taken Test",
    value:  isLoad ? (
      <Skeleton variant="text" width={100} animation="wave" />
    ) : (adminDashboardStat?.mostTakenTest),
  },
];
return data;
};

export default getDashBoardData;