import React from "react";
import Typography from "@mui/material/Typography";
import EastIcon from "@mui/icons-material/East";
import { Box, Button, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StudiesInProgress = ({ data, loading }) => {
  const navigate = useNavigate();
  return (
    <Box
      height={"18rem"}
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: 5,
          height: 5,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#fff",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#DCDCDC",
          borderRadius: 2,
        },
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={200} />
      ) : data?.length === 0 ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "100%" }}
        >
          <h3 style={{ color: "#c0c0c0", fontStyle: "italic" }}>
            No Study is in progress
          </h3>
        </div>
      ) : Array.isArray(data) && data.length > 0 ? (
        data?.map((item) => (
          <div
            key={item.examId}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid rgba(0, 0, 0, .125)",
              padding: "10px",
            }}
          >
            <Typography variant="body1" component={"span"} sx={{fontSize: {xs: "12px", sm: "16px"}}}>
              {`${item.subjectName} (${item.examName} ${item.year})`}
            </Typography>
            <Typography component={"span"} sx={{ ml: "auto", mr: {sm: 5, xs: 0} }}>
              <Button
                onClick={() => navigate("/dashboard/learning/study")}
                sx={{
                  color: "#fff",
                  padding: {sm:"5px 20px", xs: "3px 10px"},
                  textTransform: "inherit",
                  fontSize: {xs: "12px", sm: "14px"},
                  borderRadius: "10px",
                  color: "#40499D",
                  bgcolor: "#d8dbf6",
                  "&:hover": { bgcolor: "#d8dbf6" },
                }}
              >
                Resume
                <EastIcon fontSize="small" />
              </Button>
            </Typography>
          </div>
        ))
        ) : (
          <div  className="flex justify-center items-center"
          style={{ height: "100%" }}>
            <h3 style={{ color: "#c0c0c0", fontStyle: "italic" }}>
              No data available
            </h3>
          </div>
        )
      }
    </Box>
  );
};

export { StudiesInProgress };
