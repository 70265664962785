import {
  Box,
  Button,
  Skeleton,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FreemiumIcon, InfoIcon } from "../../assets/icons/Icons";
import PricingTable from "./PricingTable";
import useUser from "../../hooks/useUser";
import moment from "moment";
import { useDispatch } from "react-redux";
import useSettings from "../../hooks/useSettings";
import { getCandidateSubscriptionsByProfileId } from "../../redux/settings/settingsActions";
import AllSubscriptionCards from "../SubPlans";
import Payment from "../../pages/student/payment/Payment";
import useSubscription from "../../hooks/useSubscription";
import { fetchSubPlans } from "../../redux/subscriptions";
import Loader from "../AuthReuseables/Preloader/Loader";
import BillingHistory from "./BillingHistory";
import { useGoogleAnalytics } from "../../hooks/usePageTracking";

const Subscription = () => {
  const { userInfo } = useUser();
  const { trackPage } = useGoogleAnalytics()
  const {
    userInfo: { profileId, categoryId },
  } = useUser();
  trackPage()

  const { candidateSub } =
    useSettings();
  const { subscriptionPackageLoader, allSubPlan } = useSubscription();

  const dispatch = useDispatch();
  const [sub, setSub] = useState({});
  // const [pricingTable, setPricingTable] = useState(candidateSub);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    dispatch(fetchSubPlans(categoryId));
  }, []);



  useEffect(() => {
    dispatch(getCandidateSubscriptionsByProfileId(profileId));
  }, []);


  return (
    <>
      {subscriptionPackageLoader && <Loader />}

      {
        (candidateSub.length == 0 ||
          candidateSub === null || showPaymentModal) && (
          <AllSubscriptionCards
            subCards={allSubPlan}
            onClick={(subscription) => {
              setSelectedSubscription(subscription);
              setPaymentModal(true);
            }}
          />
        )
      }

      {(candidateSub.length > 0 && !showPaymentModal) && (
        <BillingHistory
          newSubscription={() => setShowPaymentModal(true)}
          packages={candidateSub}
        />
      )
      }

      <Dialog
        fullScreen={true}
        open={paymentModal}
        onClose={() => setPaymentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Payment
            setPaymentModal={setPaymentModal}
            selectedSubscription={selectedSubscription}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Subscription;
