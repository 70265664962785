import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { ReactComponent as ActiveLevel } from "../../assets/images/Level1Active.svg";
import { ReactComponent as LockedLevel } from "../../assets/images/UnlockedLevel.svg";
import { ReactComponent as LastLockedLevel } from "../../assets/images/LastLevel.svg";
import CompletedLevel from "../../assets/images/CompletedLevel1.png";
import { Button } from "@mui/material";

function LevelProgress({ level,status, x, y, value }) {
  return (
    <>
    {/* <Button 
       color="brandPrimary"
       variant="outlined"
       sx={{
         borderRadius: "10px",
        //  border: "#E5E5E5",
         fontSize: "16px",
        //  textTransform: "inherit",
         p: "10px 20px",
         position: "absolute",
        //  zindex: 9999,
       }}
      > Start </Button> */}
    <Box
      sx={{
        position: "absolute",
        left: x,
        top: y,
      }}
    >
      
      <Button
        sx={{ position: "absolute", left: 7, top: 12, cursor: "pointer" }}
      >
         {status === "active" ? (
      <ActiveLevel />
    ) : status === "completed" ? (
      <img src={CompletedLevel} alt={`Level ${level}`} />
    ) : level === 10 ? (
      <LastLockedLevel /> 
    ) : (
      <LockedLevel />
    )}
      </Button>
      {status === "active" && (
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#E5E5E5",
          }}
          size={90}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          sx={{
            color: "#40499D",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={90}
          thickness={4}
          value={value}
        />
      </Box>
      )}
    </Box>
    </>
  );
}

function LevelCircularProgress({ points }) {
  const [levels, setLevels] = useState([
      {
        level: 1,
        value: 0,
        status: "inactive",
        x: 60,
        y: 5,
        pathProps: {
          d: "M0,50 C25,0 75,0 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "8 6",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 70,
          height: 70,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: "60",
        top: "20",
        transform: 'rotate(90deg)',
        }
      },
      {
        level: 2,
        status: "inactive",
        value: 0,
        x: 20,
        y: 130,
        pathProps: {
          d: "M0,50 C25,20 75,70 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "12 9",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 50,
        top: 205,
        transform: 'rotate(90deg)',
        }
      },
      {
        level: 3,
        status: "inactive",
        value: 0,
        x: 70,
        y: 280,
        pathProps: {
          d: "M0,50 C25,20 75,70 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "12 10",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 80,
        top: 350,
        transform: 'rotate(-120deg)',
        }
      },
      {
        level: 4,
        status: "inactive",
        value: 0,
        x: 140,
        y: 400,
        pathProps: {
          d: "M0,50 C25,0 75,0 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "12 9",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 225,
        top: 360,
        transform: 'rotate(-25deg)',
        }
      },
      {
        level: 5,
        status: "inactive",
        value: 0,
        x: 300,
        y: 340,
        pathProps: {
          d: "M0,50 C25,0 75,0 100,50 ",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "12 10",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 390,
        top: 300,
        transform: 'rotate(160deg)',
        }
      },
      {
        level: 6,
        status: "inactive",
        value: 0,
        x: 450,
        y: 250,
        pathProps: {
          d: "M0,0 C25,20 75,70 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "17 14",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 400,
        top: 200,
        transform: 'rotate(30deg)',
        }
      },
      {
        level: 7,
        status: "inactive",
        value: 0,
        x: 350,
        y: 130,
        pathProps: {
          d: "M0,50 C25,0 75,0 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "15 12",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 380,
        top: 57,
        transform: 'rotate(-40deg)',
        }
      },
      {
        level: 8,
        status: "inactive",
        value: 0,
        x: 450,
        y: 20,
        pathProps: {
          d: "M0,20 C25,0 75,70 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "12 11",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 540,
        top: 30,
        transform: 'rotate(0deg)',
        }
      },
      {
        level: 9,
        status: "inactive",
        value: 0,
        x: 620,
        y: 40,
        pathProps: {
          d: "M0,50 C25,0 75,70 100,30",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "12 9",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 680,
        top: 100,
        transform: 'rotate(60deg)',
        }
      },
      {
        level: 10,
        status: "inactive",
        value: 0,
        x: 750,
        y: 150,
        pathProps: {
          d: "M0,50 C25,0 75,0 100,50",
          fill: "none",
          stroke: "#AFAFAF",
          strokeWidth: "4",
          strokeDasharray: "12 10",
          strokeDashoffset: "0",
        },
        svgProps: {
          width: 80,
          height: 100,
          viewBox: "0 0 100 100",
        },
        svgStyle: {
          position: 'absolute',
        left: 10,
        top: 50,
        transform: 'rotate(-70deg)',
        }
      },
  ]);

  useEffect(() => {
    // Calculate the active level and progress based on points
    let remainingPoints = points;
    const updatedLevels = levels.map((level, i) => {
      const pointsForThisLevel = (i + 1) * 1000;
      if (remainingPoints >= pointsForThisLevel) {
        remainingPoints -= pointsForThisLevel;
        return { ...level, status: "completed", value: 100 };
      } else if (remainingPoints > 0) {
        const progress = (remainingPoints / pointsForThisLevel) * 100;
        remainingPoints = 0;
        return { ...level, status: "active", value: progress };
      } else {
        return { ...level, status: "inactive", value: 0 };
      }
    });
  
    setLevels([...updatedLevels]);
  }, [points, levels]);
  
  
  
  
  
  

 

  return (
    <Box
      sx={{
        borderRadius: "15px",
        overflowX: "auto",
        overflowY: "auto",
        padding: "16px",
        height: "100vh",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      
      {levels.map((level, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <svg
            width={level.svgProps.width} 
            height={level.svgProps.height}
            viewBox={level.svgProps.viewBox}
              style={{
              ...level.svgStyle
              }}
            >
              <path
               {...level.pathProps}
              />
            </svg>
          )}
          <LevelProgress
            level={level.level}
            value={level.value}
            status={level.status}
            x={level.x}
            y={level.y}
            pathProps={level.pathProps}
            svgProps={level.svgProps}
            svgStyle={level.svgStyle}
          />
        </React.Fragment>
      ))}
    </Box>
  );
}

export default LevelCircularProgress;
