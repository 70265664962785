import React, { useState, useEffect } from "react";
import { Box, Card, Button, Typography, IconButton, Skeleton, Tab, Tabs } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  fetchExamBySubjectID,
  fetchExamSubject,
  fetchGetLastViewedQuestion,
  fetchQuestionById,
  fetchSubjectByProfileID,
  fetchSubjectbyId,
  fetchSubmitPendingStatus,
} from "../../../../redux/learning";
import { useDispatch } from "react-redux";
import useLearning from "../../../../hooks/useLearning";
import { LockIcon, SubjectBookIcon, SubjectIcon } from "../../../../assets/icons/Icons";
import { Color } from "../../../../assets/themes/Color";
import bgImg from "../../../../assets/images/studybackground.svg";
import { useNotifications } from "../../../../components/notifications/notifications";
import ExamType from "./examType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WestIcon from "@mui/icons-material/West";
import { fetchExamType } from "../../../../redux/learning";
import useUser from "../../../../hooks/useUser";
import StudyQuestion from "./studyQuestion";
import useSubscription from "../../../../hooks/useSubscription";
import { useGoogleAnalytics } from "../../../../hooks/usePageTracking";
import PropTypes from 'prop-types';
import StudySubjectTopics from "./StudySubjectTopics";
import { fetchQuestionsByTopicId } from "../../../../redux/learning/learningActions";
import StudySubjectTopicQuestion from "./StudySubjectTopicQuestion";
import { fetchSubjectsAction } from "../../../../redux/contentUpload/contentUploadActions";



const SkeletonRow = () => (
  <Box display="flex" justifyContent={"space-between"} sx={{ mb: 2, mt: 2, maxWidth: 700, maxHeight: 3000 }}>
    <Skeleton variant="rounded" width={"40%"} height={30} animation={"wave"} />
    <Skeleton variant="rounded" width={"10%"} height={30} animation={"wave"} />
  </Box>
);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Study = () => {
  const dispatch = useDispatch();
  const learning = useLearning();
  const { userInfo } = useUser();
  const { profileId, isSuperSchoolAdmin } = userInfo;
  const [ToastState, setToastState] = useNotifications();
  const { checkSub, isTrial } = useSubscription();
  const { trackPage, trackSpecificEvent } = useGoogleAnalytics();

  const { subjectData = [], subjectById, loading, error, examTypeData, allExam, examCount } = learning;

  const [showOtherPage, setShowOtherPage] = useState(false);
  const [showTopicQuestion, setShowTopicQuestion] = useState(false);

  const [showPreviousPage, setShowPreviousPage] = useState(false);

  const [subjectId, setSubjectId] = useState("");
  const [subjectName, setSubjectName] = useState("");

  const [showSecondCard, setShowSecondCard] = useState(null);
  const [examYearIds, setExamYearIds] = useState("");
  const [examQuestionId, setExamQuestionId] = useState(0);
  const [examinationType, setExaminationType] = useState("");
  const [allItems, setAllItems] = useState("");
  const [startedSubjectId, setStartedSubjectId] = useState("");
  const [subjectIdforTime, setSubjectIdforTime] = useState("");
  const [showCalculatorIcon, setShowCalculatorIcon] = useState(false);
  const [examInfo, setExamInfo] = useState({});
  const [topic, setTopic] = useState({});


  const [value, setValue] = useState(0);

  trackPage();

  const [input, setInput] = useState({
    profileId: "",
    examId: "",
  });

  // start function that shows the options to choose between MCQ and Theory
  const handleShowOtherPage = (param, param2, param3) => {
    setShowOtherPage(true);
    setExamYearIds(param);
    setInput({
      ...input,
      subjectId: param2,
      examYearId: param,
      examId: param3,
    });
    dispatch(fetchExamType());
  };

  // start function that the previous page "My study"
  const handleShowPreviousPage = () => {
    setShowOtherPage(false); // Switch back to the Study page
  };

  // function that shows the child card with subject id
  const toggleSecondCard = (clickedSubjectId) => {
    dispatch(fetchExamBySubjectID(clickedSubjectId));
    setShowSecondCard((prevShowSecondCard) => (prevShowSecondCard === clickedSubjectId ? null : clickedSubjectId));
  };

  useEffect(() => {
    setInput({ ...input, profileId: userInfo?.profileId });
    if (isSuperSchoolAdmin) {
      dispatch(fetchSubjectsAction())
    } else {
      dispatch(fetchSubjectByProfileID(profileId));
    }
  }, []);


  const getSubject = isSuperSchoolAdmin ? subjectData?.find((d) => d.subjectId === allItems.subjectId)?.subjectName : subjectData?.find((d) => d.SubjectId === allItems.subjectId)?.SubjectName;

  const getExamYear = allExam?.find((d) => d.examYearId === allItems.examYearId)?.year;

  // for questions that are flagged from the admin will not be displayed
  const nonFlaggedQuestions = examCount?.testQuestion?.filter((question) => question?.isFlagged === false) || [];

  useEffect(() => {
    if (examQuestionId) {
      dispatch(fetchQuestionById(nonFlaggedQuestions[0]?.examQuestionId));
      setShowOtherPage(true);
    } 
    setShowCalculatorIcon(examCount?.useCalculator);
  }, [examQuestionId]);


  // Topic Questions Section

  const [questions, setQuestions] = useState([]);
 
  

  const handleShowTopicQuestions = async (topic) => {
    setStartedSubjectId(topic.subjectId);
    setExamQuestionId(null);
    dispatch(fetchQuestionsByTopicId({ TopicId: topic.TopicId }, (res) => {
      if (res?.length > 0) {
        setQuestions(res);
      dispatch(fetchQuestionById(res[0]?.ExamQuestionId));
      setShowTopicQuestion(true);
      } else {
        setToastState({
          isOpen: true,
          severity: 'warning',
          message: 'There are no questions available for this topic'
        })
      }
      
    }));
  }

  const handleShowQuestions = async (exam) => {
    setStartedSubjectId(exam.subjectId);
    setExamInfo(exam);
    setExamQuestionId(null);
    dispatch(
      fetchExamSubject(
        {
          ExamId: exam.examId, //  the year of the exam
        },
        (res) => setExamQuestionId(res?.testQuestion[0]?.examQuestionId)
      )
    );

    setInput({ ...input, examId: exam.examId });
    dispatch(fetchSubmitPendingStatus({ examId: exam.examId, profileId: userInfo?.profileId }, onSuccess, onFail, on500Fail, setSubjectIdforTime));
    dispatch(fetchGetLastViewedQuestion({ ExamId: exam.examId, ProfileId: userInfo?.profileId }, onSuccess, onFail, on500Fail));
    trackSpecificEvent("study_started", {
      subject_name: examInfo?.subjectName,
      exam_name: examInfo?.examName,
      mode: isTrial ? "Trial" : "Paid",
    });
  };

  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: res.msg,
    });
    setTimeout(() => { }, 2000);
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res?.msg,
    });
  };

  const on500Fail = (error) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: error?.statusText,
    });
  };

  const handleChange = (event, newValue) => {
    event.stopPropagation();
    setValue(newValue);
  };


  return (
    <>
      <Box
        className="bg-cover"
        sx={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "contain",
          padding: 4,
          boxSizing: "border-box",
        }}
        style={{ minHeight: "94.5vh" }}
      >
        <p class="text-2xl font-extrabold ...">
          {" "}
          {showOtherPage ? (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <IconButton
                size="large"
                // disableRipple
                style={
                  {
                    // color:"#40499D"
                    // display: "flex",
                    // justifyContent: "flex-end",
                  }
                }
                onClick={() => {
                  handleShowPreviousPage();
                  dispatch(isSuperSchoolAdmin ? fetchSubjectsAction() : fetchSubjectByProfileID(profileId));
                }}
              >
                <WestIcon size="large" />{" "}
              </IconButton>
              <Typography sx={{ color: "#40499D", fontWeight: 600, fontSize: "18px" }}>
                {examInfo?.subjectName} {examInfo?.examName}
              </Typography>
            </Box>
          ) : showTopicQuestion ? (
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <IconButton
                size="large"
                onClick={() => {
                  setShowTopicQuestion(false);
                  dispatch(isSuperSchoolAdmin ? fetchSubjectsAction() : fetchSubjectByProfileID(profileId));
                }}
              >
                <WestIcon size="large" />{" "}
              </IconButton>
              <h3 className='font-medium e-study-heading-four capitalize text-e-study-bg-primary'>{subjectName} ({topic?.TopicName?.toLowerCase()})</h3>
            </Box>
          )
            : (
              "My Study"
            )}
        </p>

        {showOtherPage ? (
          <StudyQuestion
            examInfo={examInfo}
            examCount={examCount}
            showCalculatorIcon={showCalculatorIcon}
            examType={allItems.examType}
            examinationType={examinationType}
            input={input}
            setInput={setInput}
            SubjectID={subjectId}
            YearofExam={examYearIds}
            startedSubjectId={startedSubjectId}
            subjectIdforTime={subjectIdforTime}
          />
        ) :
          showTopicQuestion ? (
            <StudySubjectTopicQuestion questions={questions} />
          ) :
            (
              subjectData !== null &&
              subjectData.map(
                (
                  subject,
                  index // eslint-disable-line @typescript-eslint/no-explicit-any
                ) => (
                  // return (
                  <div
                    key={index}
                    className="cursor-pointer"
                    onClick={() => {
                      setSubjectId(subject.subjectId);
                      setSubjectName(isSuperSchoolAdmin ? subject?.subjectName : subject?.SubjectName)
                      toggleSecondCard(isSuperSchoolAdmin ? subject?.subjectId : subject.SubjectId);
                    }}
                  >



                    <Card
                      sx={{
                        maxWidth: 700,
                        maxHeight: 500,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "2rem",
                        borderRadius: 2,
                        p: 1,
                      }}
                    >
                      <div className="flex items-center space-x-3">
                        <SubjectBookIcon color={Color[index] ? Color[index] : Color[0]} />
                        <div>
                          {" "}
                          <strong> {isSuperSchoolAdmin ? subject?.subjectName : subject?.SubjectName}</strong> <br />{" "}
                        </div>
                      </div>

                      <IconButton
                        size="large"
                        disableRipple
                        style={{
                          // display: "flex",
                          // justifyContent: "flex-end",
                          color: "#40499D",
                        }}
                      // onClick={() => {
                      //   setSubjectId(subject.subjectId);
                      //   toggleSecondCard(subject.SubjectId);
                      // }}
                      >
                        {showSecondCard === (isSuperSchoolAdmin ? subject?.subjectId : subject.SubjectId) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </IconButton>
                    </Card>
                    {showSecondCard === (isSuperSchoolAdmin ? subject?.subjectId : subject.SubjectId) && (
                      <Card
                        sx={{
                          maxWidth: 700,
                          maxHeight: 3000,
                          marginTop: "1rem",
                          borderRadius: 2,
                          p: 1,
                        }}
                      >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Papers" {...a11yProps(0)} sx={{ textTransform: 'none' }} />
                            <Tab label="Topics" {...a11yProps(1)} sx={{ textTransform: 'none' }} />
                            {/* <Tab label="Pages" {...a11yProps(1)} sx={{ textTransform: 'none' }} onClick={handleDisplay3} /> */}
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                          {/* Content of the card */}
                          {loading ? (
                            <>
                              {[...Array(8)].map((_, index) => (
                                <SkeletonRow key={index} />
                              ))}
                            </>
                          ) : allExam !== null && allExam?.length !== 0 ? (
                            allExam?.map((exam, index) => (
                              <div className="relative">
                                <div
                                  key={index.examId}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",

                                    marginBottom: "1rem", // Add margin at the bottom to create space between exam pairs
                                  }}
                                >
                                  <div>
                                    <Typography
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          // textDecoration: "underline",
                                          color: "#40499D",
                                          fontWeight: 500,
                                        },
                                      }}
                                      onClick={() => {
                                        setAllItems(exam);
                                        handleShowQuestions(exam);
                                      }}
                                    >
                                      {exam.examName}
                                    </Typography>{" "}
                                  </div>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="brandPrimary"
                                    sx={{
                                      color: "#AFAFAF",
                                      "&:hover": {
                                        color: "#fff",
                                      },
                                    }}
                                    onClick={() => {
                                      setAllItems(exam);
                                      handleShowQuestions(exam);
                                    }}
                                  >
                                    Start
                                  </Button>
                                </div>
                                {checkSub?.msg === "InActive" && index > 0 && (
                                  <div className="absolute bg-[#313131] opacity-80 inset-0 flex justify-center items-center">
                                    <LockIcon style={{ color: "#FFF", fontSize: "40px" }} />
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-[#A3AED0] " style={{ padding: "20px" }}>
                              No Paper Available
                            </p>
                          )}
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                          <StudySubjectTopics subjectId={showSecondCard} handleShowTopicQuestions={handleShowTopicQuestions} setTopic={setTopic} />
                        </CustomTabPanel>

                      </Card>
                    )}
                  </div>
                )
              )
            )}
        <ToastState />
      </Box>
    </>
  );
};

export default Study;
