import {
  CHECK_USER_SUB,
  CHECK_USER_SUB_FAILURE,
  CHECK_USER_SUB_SUCCESS,
  ALL_SUBSCRIPTION_PLAN,
  ALL_SUBSCRIPTION_PLAN_FAILURE,
  ALL_PAYMENT_SUMMARY,
  ALL_PAYMENT_SUMMARY_FAILURE,
  FETCH_STUDENT_SUBSCRIPTIONS,
  FETCH_STUDENT_SUBSCRIPTIONS_FAIL,
  FETCH_SCHOOL_SUBSCRIPTIONS_FAIL,
  FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS,
  FETCH_SCHOOL_SUBSCRIPTIONS,
  FETCH_STUDENT_SUBSCRIPTIONS_SUCCESS,
  FETCH_SPONSOR_SUBSCRIPTIONS_SUCCESS,
  FETCH_SPONSOR_SUBSCRIPTIONS_FAIL,
  FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_SUCCESS,
  FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_FAIL,
  ALL_SUBSCRIPTION_PLAN_LOADING,
  FETCH_SPONSOR_SUBSCRIPTIONS,
  UPDATE_AMOUNT,
  UPDATE_AMOUNT_SUCCESS,
  UPDATE_AMOUNT_FAILURE,
} from "./types";

const initialState = {
  loading: false,
  classLevel: null,
  classLoaded: false,
  studentsLoaded: false,
  error: null,
  newStudent: null,
  studentSub: [],
  schoolSub: {},
  sponsorSub: {},
  checkSub: {},
  allSubPlan: [],
  isLoaded: false,
  paymentSumary: [],
  sponsorSubscriptions: [],
  schoolSubscriptions: [],
  schoolSponsorSubscriptions: [],
  subscriptionPackageLoader: false,
  amountLoading: false,
  amountUpdate: {},
};
const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_USER_SUB:
      return {
        ...state,
        loading: true,
        checkSub: {},
        isLoaded: false,
      };
    case CHECK_USER_SUB_SUCCESS:
      return {
        ...state,
        loading: false,
        checkSub: action.payload,
        isLoaded: true,
      };
    case CHECK_USER_SUB_FAILURE:
      return {
        ...state,
        loading: false,
        checkSub: action.payload,
        isLoaded: false,
      };
    case ALL_SUBSCRIPTION_PLAN_LOADING:
      return {
        ...state,
        subscriptionPackageLoader: action.payload,
      };
    case ALL_SUBSCRIPTION_PLAN:
      return {
        ...state,
        loading: false,
        allSubPlan: action.payload,
        isLoaded: false,
        subscriptionPackageLoader: false,
      };
    case ALL_SUBSCRIPTION_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        allSubPlan: null,
        isLoaded: false,
        subscriptionPackageLoader: false,
      };
    case ALL_PAYMENT_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        paymentSumary: null,
        // isLoaded: false,
      };
    case ALL_PAYMENT_SUMMARY:
      return {
        ...state,
        loading: false,
        paymentSumary: action.payload.data,
        // isLoaded: false,
      };
    // case FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS:
    //   return {
    //     ...state,
    //     laoding: false,
    //     schoolSubscriptions: action.payload,
    //   };
    // case FETCH_SCHOOL_SUBSCRIPTIONS_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     schoolSubscriptions: null,
    //     isLoaded: false,
    //   };

    case FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        laoding: false,
        schoolSponsorSubscriptions: action.payload,
      };
    case FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        schoolSponsorSubscriptions: null,
        isLoaded: false,
      };
    case FETCH_SPONSOR_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SPONSOR_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        sponsorSub: action.payload.data,
      };
    case FETCH_SPONSOR_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        sponsorSub: null,
      };
    case FETCH_STUDENT_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
        studentSub: [],
      };
    case FETCH_STUDENT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        studentSub: action.payload,
      };
    case FETCH_STUDENT_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        studentSub: action.payload,
      };
    case FETCH_SCHOOL_SUBSCRIPTIONS:
      return {
        ...state,
        loading: action.payload,
        // schoolSub: {},
      };
    case FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        schoolSub: action.payload,
      };
    case FETCH_SCHOOL_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        schoolSub: null,
      };
      case UPDATE_AMOUNT:
        return {
          ...state,
          amountLoading: true,
        };
      case UPDATE_AMOUNT_SUCCESS:
        return {
          ...state,
          amountLoading: false,
          amountUpdate: action.payload,
        };
      case UPDATE_AMOUNT_FAILURE:
        return {
          ...state,
          amountLoading: false,
          amountUpdate: {},
        };
    default:
      return state;
  }
};
export default subscriptionReducer;
