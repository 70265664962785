import React, { useEffect, useState } from "react";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import { Box, Typography } from "@mui/material";

const NetworkPanel = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOnlineBar, setShowOnlineBar] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (isOnline && showOnlineBar) {
      const timeoutId = setTimeout(() => {
        setShowOnlineBar(false);
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOnline, showOnlineBar]);

  useEffect(() => {
    if (!isOnline) {
      setShowOnlineBar(true);
    }
  }, [isOnline]);

  return !isOnline ? (
    <Box
      sx={{
        width: "100%",
        height: "30px",
        bgcolor: "#ffd2df",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <CloudOffIcon />
      <Typography>
        You are offline. Please check your internet connection
      </Typography>
    </Box>
  ) : showOnlineBar ? (
    <Box
      sx={{
        width: "100%",
        height: "30px",
        bgcolor: "#d2f9ff",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <CloudDoneIcon sx={{ color: "#098497" }} />
      <Typography>You're back online</Typography>
    </Box>
  ) : null;
};

export default NetworkPanel;
