import React from "react";
import Typography from "@mui/material/Typography";
import EastIcon from "@mui/icons-material/East";
import { Box, Button, Paper, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ResumeSvgIcon } from "../../../assets/icons/Icons";
import {ReactComponent as NoStudiesInProgressIcon} from "../../../assets/images/NoStudyInProgressIcon.svg"

export default function NewStudiesInProgress({ data, loading }) {
  const navigate = useNavigate();
  return (
    <Box
      component={Paper}
      maxHeight={"auto"}
      elevation={0}
      p={2}
      width={{ xs: "100%", md: "50%" }}
      borderRadius={"14px"}
      sx={{
        border: "1px solid #E8E8E8",
        boxShadow: "0px 8.6px 25.8px 0px rgba(171, 171, 171, 0.40)",
      }}
    >
      <Typography
        variant="h6"
        fontWeight={500}
        pb={1.5}
        ml={1}
        sx={{ fontSize: { xs: "14px", sm: "18px" }, color: "#191635" }}
      >
        Studies in Progress
      </Typography>
      <Box
        height={"12rem"}
        sx={{
          overflow: "auto",
          // "&::-webkit-scrollbar": {
          //   width: 5,
          //   height: 5,
          // },
          // "&::-webkit-scrollbar-track": {
          //   backgroundColor: "#fff",
          // },
          // "&::-webkit-scrollbar-thumb": {
          //   backgroundColor: "#DCDCDC",
          //   borderRadius: 2,
          // },
        }}
      >
      
        { data?.length === 0 ? (
            <Box sx={{display:"flex", flexDirection:"column", justifyContent: "center", alignItems: "center", height:"100%"}}>
<NoStudiesInProgressIcon />
            <h4 style={{ color: "#c0c0c0", fontSize: "14px" }}>
            Currently not studying!
            </h4>
            </Box>
         ) : Array.isArray(data) && data?.length > 0 ? (
           data?.map((item) => (
            <Box
              key={item?.examId}
              sx={{
                border: "1px solid #D4D4D4",
                borderRadius: "10px",
                margin: "0px 3px 10px 3px",
                padding: "5px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box pl={1}>
              {loading ? (
          <Skeleton variant="text" width={100} animation="wave" />
        ) : (
                <Typography
                  variant="body1"
                  sx={{
                    mb: 0.5,
                    fontSize: "15px",
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  {`${item?.subjectName}`}
                </Typography>
        )}
         {loading ? (
          <Skeleton variant="text" width={100} animation="wave" />
        ) : (
                <Typography
                  variant="body1"
                  sx={{
                    color: "#888",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {`${item?.examName} ${item?.year}`}
                </Typography>
        )}
              </Box>
              {loading ? (
          <Skeleton variant="text" width={100} animation="wave" />
        ) : (
              <Typography
                component={"span"}
                sx={{ ml: "auto", mr: { sm: 1, xs: 0 } }}
              >
                <Button
                  onClick={() => navigate("/dashboard/learning/study")}
                  sx={{
                    color: "#fff",
                    padding: { sm: "3px 10px", xs: "3px 10px" },
                    textTransform: "inherit",
                    fontSize: { xs: "11px", sm: "12px" },
                    borderRadius: "10px",
                    color: "#40499D",
                    bgcolor: "#d8dbf6",
                    "&:hover": { bgcolor: "#d8dbf6" },
                  }}
                >
                  resume
                  <ResumeSvgIcon width={16} height={15} />
                </Button>
              </Typography>
        )}
            </Box>
          ))
          ) : (
            <div  className="flex justify-center items-center"
            style={{ height: "100%" }}>
              <h4 style={{ color: "#c0c0c0" }}>
                No data available
              </h4>
            </div>
          )
    }
      </Box>
    </Box>
  );
}
