import React from 'react';
import {
  Grid, Typography, Box, Paper, Menu, MenuItem, MenuList, ListItemIcon,
  ListItemText, Divider, Radio, Stack, FormControlLabel, FormControl, InputLabel, OutlinedInput, TextField
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useEffect } from 'react';
import useContentUpload from '../../../hooks/useContentUpload';
import { addExamAction, fetchExamYearsAction, fetchSubjectsAction, GetAllExamSeries, getALlExamType, GetExamByExamId, updateExamAction } from "../../../redux/contentUpload/contentUploadActions";
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  createFromBlockArray,
  ContentState,
  createWithContent,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import useAdminUser from '../../../hooks/useAdminUser';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const ContentUploadStartTestPage = (props) => {
  const { input, setInput, setToastState, exam } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [content, setContent] = useState('');

  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();
  const location = useLocation();
  const subjectId = location?.state?.subjectId;

  const { testYearId, testDescription, testCompleteHours, testCompleteMinutes, testCompleteSeconds, questionAnswerHours, questionAnswerMinutes, questionAnswerSeconds, examType, examSeries } = input;



  const { subjectData, examYearData, examTypeData, examSeriesData, singleExamData, loading, error } = useContentUpload();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());


  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    dispatch(fetchExamYearsAction());
    dispatch(getALlExamType());
    dispatch(GetAllExamSeries());
  }, []);

  useEffect(() => {
    dispatch(fetchSubjectsAction());
  }, []);

  useEffect(() => {
    if (exam) {
      dispatch(GetExamByExamId(exam.examId))
    }
  }, [])

  useEffect(() => {
    if (exam && singleExamData) {
      if (singleExamData.examInstruction) {

        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(singleExamData?.examInstruction)
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    }
  }, [singleExamData])

  const handleImageUpload = (file) => {
    // Simulate image upload logic
    const imageUrl = URL.createObjectURL(file);

    const contentStateWithImage = ContentState.createFromBlockArray([
      {
        type: 'atomic',
        text: ' ',
        entityRanges: [
          {
            key: 'image',
            length: 1,
            offset: 0,
          },
        ],
      },
    ], {
      image: {
        src: imageUrl,
      },
    });

    setEditorState(EditorState.createWithContent(contentStateWithImage));
  };

  const onSuccess = (res) => {
    setInput({ ...input, examSetupState: 7 });
    setToastState({ isOpen: true, severity: "success", message: "Test start instruction saved successfully" });
  }


  const handleSubmit = () => {
    if (!input.examSeries || !input.examType || !input.testYearId || !testDescription || !subjectId) return false;
    const data = {
      examName: subjectData?.find(subject => subject.subjectId === subjectId)?.subjectName + ' ' + examSeriesData?.find(series => series.SeriesTypeId == examSeries)?.SeriesType,
      examYearId: testYearId,
      description: testDescription,
      timeAllowed: ((testCompleteHours * 3600) + (testCompleteMinutes * 60) + testCompleteSeconds),
      subjectId: subjectId,
      profileId: userInfo?.data?.profileId,
      noOfQuestions: 100,
      seriesTypeId: examSeries,
      examTypeId: examType,
      examId: exam ? exam.examId : '',
      examInstruction: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    };

    dispatch(updateExamAction(data, (res) => onSuccess(res)));
  }

  return (
    <>
      <Box>
        <Typography sx={{
          color: '#000', fontFamily: 'Open Sans',
          fontSize: '16px', fontStyle: 'normal', fontWeight: 700,
          lineHeight: '28px'
        }}>Test start page</Typography>
      </Box>

      <Box sx={{ mt: 2 }}>

        <Paper sx={{ minWidth: 275, borderRadius: '16px' }}>
          <CardContent>


            <Box sx={{ mt: 2, width: '95%', mx: 'auto' }}>

              <Box>
                <Typography sx={{
                  color: '#000', fontFamily: 'Open Sans',
                  fontSize: '16px', fontWeight: 600,
                  lineHeight: '27.24px'
                }}>Instructions for candidates</Typography>
                <Typography sx={{
                  color: '#000', fontFamily: 'Open Sans',
                  fontSize: '12px', fontWeight: 400,
                  lineHeight: '27.24px'
                }}>Provide instruction to be displayed to candidates before commencing the test</Typography>
              </Box>

              <Box sx={{ mt: 2, mx: 'auto' }}>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  toolbar={{
                    image: {
                      uploadCallback: handleImageUpload,
                      alt: { present: true, mandatory: false },
                    },
                  }}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />

              </Box>


              <Box m={1} sx={{ mt: 2 }}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >

                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>

                  <Button sx={{ backgroundColor: '#40499D', borderRadius: 2, textTransform: 'none' }} variant="contained" onClick={handleSubmit}>Save and Continue</Button>
                </Stack>
              </Box>

            </Box>
          </CardContent>
        </Paper>

      </Box>

    </>
  )
}

export default ContentUploadStartTestPage;