import { Box, Link } from "@mui/material";
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

import { SubjectIcon } from "../../../../assets/icons/Icons";
import { Color } from "../../../../assets/themes/Color";
import { Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StudyQuestion from "./studyQuestion";
import { useDispatch } from "react-redux";
import {
  fetchExamSubject,
  fetchQuestionById,
} from "../../../../redux/learning";
import useLearning from "../../../../hooks/useLearning";
import { useNotifications } from "../../../../components/notifications/notifications";

function ExamType(props) {
  const [showQuestion, setShowQuestion] = useState(false);
  const learning = useLearning();
  const dispatch = useDispatch();
  const { examCount, examQuestion, loadingQuestion } = learning;
  const [examQuestionId, setExamQuestionId] = useState(0);
  const [ToastState, setToastState] = useNotifications();
  const [examinationType, setExaminationType] = useState();
  const { input, setInput } = props;

  useEffect(() => {
    if (examQuestionId) {
      dispatch(fetchQuestionById(examCount?.testQuestion[0]?.examQuestionId));
      setShowQuestion(true);
    } else {
      setToastState({
        isOpen: true,
        severity: "info",
        message: "No questions yet",
      });
    }
  }, [examQuestionId]);

  // start function that shows the options to choose between MCQ and Theory
  const handleShowQuestions = async (subject) => {
    dispatch(
      fetchExamSubject(
        {
          ExamTypeId: subject.ExamTypeId, // object or theory
          ExamYear: props.examYearIds, //  the year of the exam
          SubjectId: props.subjectId, // subject id
        },
        (res) => setExamQuestionId(res?.testQuestion[0]?.examQuestionId)
      )
    );
    setExaminationType(subject.ExamType);
    setInput({ ...input, examType: subject.ExamTypeId });
  };

  return (
    <>
      {showQuestion ? (
        <StudyQuestion
          examCount={examCount}
          examType={props.examYearIds}
          examinationType={examinationType}
          input={input}
          setInput={setInput}
          SubjectID={props.subjectId}
          YearofExam={props.examYearIds}
        />
      ) : (
        <div>
          {props.examTypeData?.map((subject, index) => (
            <Card
              key={index}
              sx={{
                maxWidth: 900,
                maxHeight: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "2rem",
                borderRadius: 2,
                p: 1,
              }}
            >
              <div className="flex space-x-3 items-center">
                <SubjectIcon
                //color={Color[index] ? Color[index] : Color[0]}
                />
                <div>
                  {" "}
                  <strong>
                    {" "}
                    {"Study "} {subject?.ExamType} {" Question for  "}
                    {"  "}
                    {props.getSubject} {props.getExamYear}
                  </strong>{" "}
                  <br />{" "}
                </div>
              </div>

              <Button
                size="small"
                disableRipple
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "#40499D",
                }}
                onClick={() => {
                  handleShowQuestions(subject);
                }}
              >
                <Link> click here to start</Link>
              </Button>
            </Card>
          ))}
        </div>
      )}
      {/* <ToastState /> */}
    </>
  );
}
export default ExamType;
