import Axios from "../../connection/defaultClient";

import * as actionTypes from "./types";

export const fetchManageSession = () => {
  return (dispatch) => {
    Axios.post(`/ManageAdmin/GetAllSessions`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_SESSIONS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        // dispatch({
        //   types: actionTypes.ALL_SESSIONS_FAIL,
        //   payload: error.message,
        // });
      });
  };
};

export const fetchCreateSession = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_SESSION, payload: true });
    Axios.post(`/ManageAdmin/AddNewSession`, { ...payload })
      .then((response) => {
        // dispatch({ type: actionTypes.LOAD_SESSION, payload: false });
        dispatch({
          type: actionTypes.ALL_NEW_SESSIONS,
          payload: response.data,
        });
        dispatch({ type: actionTypes.LOAD_SESSION, payload: false });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_SESSION, payload: false });
        console.log(error);
      });
  };
};
export const fetchUpdateSession = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    disptach({ type: actionTypes.LOAD_SESSION, payload: true });
    Axios.post(
      `/ManageAdmin/UpdateSession?sessionId=${payload.sessionId}`,
      payload
    )
      .then((response) => {
        disptach({ type: actionTypes.LOAD_SESSION, payload: false });
        dispatch({
          type: actionTypes.UPDATE_SESSIONS,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
