import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Button,
  Grid,
  Link,
  PaginationItem,
  Typography,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  Drawer,
  Skeleton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PremiumVideo from "./PremiumVideo";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useLearning from "../../../../hooks/useLearning";

import { useNotifications } from "../../../../components/notifications/notifications";
import {
  fetchCandidateActiveSubscription,
  fetchSendLastViewedQuestion,
  fetchPracticeQuestion,
  fetchPracticeQuestionsByQuestionId,
  fetchQuestionById,
  fetchSubmitStudies,
  fetchSubmitStudyTime,
} from "../../../../redux/learning";

import { styled, useTheme } from "@mui/material/styles";
import ExamAnswer from "./studyAnswer";
import ExamChoices from "./studyChoices";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import IconButton from "@mui/material/IconButton";
import PracticeModal from "./practiceModal";
import PracticeQuestion from "./practiceQuestion";
import PremiumInfoDialog from "../../../../components/Modal/PremiumInfoDialog";
import FreeFiveQuestions from "./freeQuestionModal";
import CountdownTimer from "../../../../components/Test/CountdownTimer";
import CalculateIcon from "@mui/icons-material/Calculate";
import { CloseRounded, DataUsage } from "@mui/icons-material";
import LadyWaving from "../../../../assets/images/ladyStudy.gif";
import StudyAvatar from "../../../../assets/images/avatarstudy.png";
import useUser from "../../../../hooks/useUser";
import QuickTip from "../../../../assets/images/quicktip.svg";
import handleInput from "../../../../components/logic/handleInput";
import Calculator from "../../../../components/calculator";
import KatexRenderer from "../../../../components/KatexRenderer";
import useSubscription from "../../../../hooks/useSubscription";
import { useGoogleAnalytics } from "../../../../hooks/usePageTracking";
import { RTEViewer } from "../../../../components/RTEViewer";
//  import { prependOnceListener } from "process";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={"right"}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.brandPrimary.light,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#525FB8",
    color: "#fff",
    // color: theme.palette.brandPrimary.main,
    // boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export default function ExamQuestion(props) {
  const theme = useTheme();
  const learning = useLearning();
  const { userInfo } = useUser();
  const { trackSpecificEvent } = useGoogleAnalytics();

  const {
    examQuestion,
    practiceQuestionCount,
    subUser,
    loadingQuestion,
    questionsViewed,
  } = learning;
  const dispatch = useDispatch();
  const {
    examCount,
    input,
    startedSubjectId,
    subjectIdforTime,
    showCalculatorIcon,
    examInfo,
  } = props;
  const [examQuestionId, setExamQuestionId] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [ToastState, setToastState] = useNotifications();
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [viewedQuestions, setViewedQuestions] = useState([]);
  const [examPractQuestionId, setExamPractQuestionId] = useState(null);
  const [selectedPracticeQuestionIndex, setSelectedPracticeQuestionIndex] =
    useState(0);

  const { isTrial } = useSubscription();

  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [showPracticeQuestion, setShowPracticeQuestion] = useState(false);
  const [userpaid, setUserpaid] = useState(false);
  const [showPremiumDialog, setShowPremiumDialog] = useState(false);
  const [displayVideo, setDisplayVideo] = useState(false);
  const [secondsLeft, setsecondsLeft] = useState(15);
  const [freeQuestion, setFreeQuestion] = useState(false);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [calculatorModal, setCalculatorModal] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [studyTime, setStudyTime] = useState(0);
  const [drawerState, setDrawerState] = useState({
    left: false,
    right: false,
  });
  const [calculatorInput, setCalculatorInput] = useState({
    displayValue: "0",
    expression: "",
    isResult: false,
    error: "",
  });

  const toggleDrawer = (side, open) => () => {
    setDrawerState({
      ...drawerState,
      [side]: open,
    });
  };

  const lastViewedQuestionId = questionsViewed?.lastViewedQuestionId;
  const lastViewed = questionsViewed?.lastViewedQuestionNo;
  const filter = examCount?.testQuestion?.find(
    (e) => e?.examQuestionId === lastViewedQuestionId
  )?.questionNumber;

  useEffect(() => {
    setSelectedQuestionIndex(selectedQuestionIndex);
    // setSelectedQuestionIndex(lastViewed);
  }, []);

  //function that checks for the last number viewed and the creates an array of all the numbers viewed before the last viewed
  useEffect(() => {
    if (lastViewedQuestionId !== null) {
      const newQuestions = Array.from(
        { length: lastViewed + 1 },
        (_, index) => index + 1
      );

      // Update the state with the new sequence
      setViewedQuestions(newQuestions);

      // Set the selected question index to lastViewed
    }
  }, []);

  // timer
  // useEffect(() => {
  //   const timerInterval = setInterval(() => {
  //     setSeconds((prevSeconds) => prevSeconds + 1);

  //     if (seconds === 59) {
  //       setSeconds(0);
  //       setMinutes((prevMinutes) => prevMinutes + 1);
  //     }

  //     if (minutes === 59) {
  //       setMinutes(0);
  //       setHours((prevHours) => prevHours + 1);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(timerInterval);
  //     setStudyTime(timerInterval);
  //   };
  // }, [seconds, minutes, hours]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newSeconds = prevSeconds + 1;
        if (newSeconds === 60) {
          setMinutes((prevMinutes) => {
            const newMinutes = prevMinutes + 1;
            if (newMinutes === 60) {
              setHours((prevHours) => prevHours + 1);
              return 0;
            }
            return newMinutes;
          });
          return 0;
        }
        return newSeconds;
      });

      // Update total study time in seconds
      setStudyTime((prevStudyTime) => prevStudyTime + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  // useEffect(() => {
  //
  //   // Function to be executed when the user leaves the page
  //   const handleUnload = (e) => {
  //     // You can make an API request or perform any cleanup here
  //     dispatch(fetchSubmitStudyTime({ input: input, startedSubjectId, studyTime }, onSuccess, onFail, on500Fail))
  //     // For demonstration purposes, we'll display a confirmation message
  //     e.preventDefault();
  //     e.returnValue = '';
  //   };

  //   // Attach the event listener
  //   window.addEventListener('beforeunload', handleUnload);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleUnload);
  //   };
  // }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };

  const onSuccess = (res) => {
    // setToastState({
    //   isOpen: true,
    //   severity: "success",
    //   message: res.msg,
    // });
    // setTimeout(() => {
    //   setToastState((prev) => ({
    //     ...prev,
    //     isOpen: false,
    //   }));
    // }, 1000);
    // Reset other state variables here if needed
    f(0);
    setMinutes(0);
    setHours(0);
  };

  // The rest of your code...

  // const onFail = (res) => {
  //   setToastState({
  //     isOpen: true,
  //     severity: "error",
  //     message: res.msg,
  //   });
  //   setTimeout(() => {
  //     setToastState((prev) => ({
  //       ...prev,
  //       isOpen: false,
  //     }));
  //   }, 1000);
  // };

  // const on500Fail = (error) => {
  //   setToastState({
  //     isOpen: true,
  //     severity: "error",
  //     message: error?.statusText,
  //   });
  //   setTimeout(() => {
  //     setToastState((prev) => ({
  //       ...prev,
  //       isOpen: false,
  //     }));
  //   }, 1000);
  // };

  const handleOpenModal = () => {
    //am using the subjectIdforTime from the pending study api then I removed the lastSavedQuestionId
    const { lastSavedQuestionId, ...subjectIdforTimeWithoutLastViewed } =
      subjectIdforTime;
    setOpen(false);
    setOpenModal((prev) => !prev);
    trackSpecificEvent("study_completed", {
      subject_name: examInfo?.subjectName,
      exam_name: examInfo?.examName,
    });
    dispatch(fetchSubmitStudies({ input: input }));

    // i added the study time to the subjectIdforTimeWithoutLastViewed object
    const studentStudiesWithTime = {
      ...subjectIdforTimeWithoutLastViewed,
      studyTime: studyTime,
    };

    dispatch(fetchSubmitStudyTime(studentStudiesWithTime));
  };

  const pagesViewed = {
    viewedPage: {
      backgroundColor: "#40499D",
      color: "#FFF", // Customize the color for viewed pages
      cursor: "pointer",
      pointerEvents: "auto", // Enable interaction
      "&:hover": {
        backgroundColor: "darkgreen", // Customize the hover color for viewed pages
      },
    },
    regularPage: {
      backgroundColor: "#40499D",
      color: "#FFF",
      cursor: "pointer",
      pointerEvents: "auto", // Enable interaction
    },
    disabledStyle: {
      pointerEvents: "none", // Disable interaction
      color: "gray", // Customize the color for disabled pages
    },
    boxWidthStyle: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      "& > :not(style)": {
        m: 1,
        minWidth: 900,
        // height: 200,
      },
      [theme.breakpoints.down("md")]: {
        "& > :not(style)": {
          m: 1,
          minWidth: 600,
          // height: 200,
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& > :not(style)": {
          m: 1,
          minWidth: 200,
          // height: 200,
        },
      },
    },
  };

  // function that handles the previous question for study
  const handlePrevious = () => {
    setOpenAnswer(false);
    const newQuestionId =
      nonFlaggedQuestions?.[selectedQuestionIndex - 1]?.examQuestionId;
    dispatch(fetchQuestionById(newQuestionId));
    setSelectedQuestionIndex(selectedQuestionIndex - 1);
    setViewedQuestions((prevViewedQuestions) => [
      ...prevViewedQuestions,
      selectedQuestionIndex - 1,
    ]);
  };

  // function that handles the next question for study

  const handleNext = () => {
    setOpenAnswer(false);
    const newIndex = selectedQuestionIndex + 1;

    // Check if the new index is within the free questions range for trial users
    if (isTrial && !freeQuestionsIndex.includes(newIndex)) {
      setDisplayVideo(true);
      setShowPremiumDialog(true);
      return;
    }

    // Continue to next question if it exists
    if (newIndex < nonFlaggedQuestions?.length) {
      const newQuestionId = nonFlaggedQuestions[newIndex]?.examQuestionId;

      // Update viewedQuestions
      setViewedQuestions((prevViewedQuestions) => [
        ...prevViewedQuestions,
        newIndex + 1,
      ]);

      // Set the selected question index
      setSelectedQuestionIndex(newIndex);

      // Fetch the new question
      dispatch(fetchQuestionById(newQuestionId));

      // Send the last viewed question
      dispatch(
        fetchSendLastViewedQuestion({
          examId: input?.examId,
          profileId: userInfo?.profileId,
          lastSavedQuestionId: newQuestionId,
        })
      );

      setNextButtonClicked(true);
    }
    // else {

    //   console.log("Reached the end of all questions");
    //   // You might want to show a different dialog or message here
    // }
  };
  // const handleNext = () => {
  //   debugger;
  //   setOpenAnswer(false);

  //   const newIndex = selectedQuestionIndex + 1;

  //   if (
  //     newIndex < nonFlaggedQuestions?.length &&
  //     (!isTrial || freeQuestionsIndex.includes(newIndex))
  //   ) {
  //     const newQuestionId = nonFlaggedQuestions?.[newIndex]?.examQuestionId;

  //     // Update viewedQuestions before selectedQuestionIndex
  //     setViewedQuestions((prevViewedQuestions) => [
  //       ...prevViewedQuestions,
  //       newIndex + 1,
  //     ]);

  //     // Set the selected question index
  //     setSelectedQuestionIndex(newIndex);

  //     // Fetch the new question
  //     dispatch(fetchQuestionById(newQuestionId));

  //     // Send the last viewed question
  //     dispatch(
  //       fetchSendLastViewedQuestion({
  //         examId: input?.examId,
  //         profileId: userInfo?.profileId,
  //         lastSavedQuestionId: newQuestionId,
  //       })
  //     );

  //     setNextButtonClicked(true);

  //     // if (selectedQuestionIndex === 4) {
  //     //   if (subUser?.isTrial === true) {
  //     //     setShowPremiumDialog(true);
  //     //   } else if (subUser?.isTrial === false) {
  //     //     setShowPremiumDialog(false);
  //     //   }
  //     // }

  //     // if (selectedQuestionIndex === 9) {
  //     //   if (subUser?.isTrial === true) {
  //     //     setShowPremiumDialog(true);
  //     //   } else if (subUser?.isTrial === false) {
  //     //     setShowPremiumDialog(false);
  //     //   }
  //     // }
  //   } else {
  //     setDisplayVideo(true);
  //     setShowPremiumDialog(true);
  //   }
  // };

  // using keyboard navigation key
  const handleKeyPress = (event) => {
    if (event.key === "ArrowRight") {
      handleNext();
    } else if (event.key === "ArrowLeft") {
      handlePrevious();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleKeyPress(event);
    };
    document.addEventListener("keydown", handleKeyDown);
    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNext, handlePrevious]);

  //functionality to view previously view questions using the buttons
  const handleChange = (newIndex) => {
    setOpenAnswer(false);

    if (newIndex >= 0 && newIndex < nonFlaggedQuestions?.length) {
      const newQuestionId = nonFlaggedQuestions[newIndex]?.examQuestionId;
      if (!isTrial || freeQuestionsIndex.includes(newIndex)) {
        dispatch(fetchQuestionById(newQuestionId));
        setSelectedQuestionIndex(newIndex);
      } else {
        setShowPremiumDialog(true);
      }
    }
  };

  // useEffect(() => {
  //   dispatch(fetchCandidateActiveSubscription(input.profileId));
  //   setViewedQuestions((prevViewedQuestions) => [
  //     ...prevViewedQuestions,
  //     selectedQuestionIndex + 1,
  //   ]);
  // }, []);

  const handleTimeOut = () => {
    let intervalCall = setInterval(() => {
      if (secondsLeft > 0) setsecondsLeft(secondsLeft - 1);
      if (secondsLeft === 0) {
        setDisplayVideo(false);
        setShowPremiumDialog(false);
      }
    }, 1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  };

  // Function to check if a page is viewed
  const isViewed = (page) => viewedQuestions.includes(page);
  const maxCount = examCount?.testQuestion?.length - 1;

  const [practiceQuestions, setPracticeQuestions] = useState(null);

  // modal that displays the congratulations on completion of study
  const handleShowPracQuest = async () => {
    try {
      await dispatch(
        fetchPracticeQuestion({ ExamId: input.examId }, (res) => {
          if (res?.data?.testQuestion?.length > 0) {
            dispatch(
              fetchPracticeQuestionsByQuestionId(
                res?.data?.testQuestion[0]?.examQuestionId
              )
            );
            setExamPractQuestionId(res?.data?.testQuestion[0]?.examQuestionId);
            setShowPracticeQuestion(true);
            handleClose(true);

            trackSpecificEvent("practice_question_started", {
              subject_name: examInfo?.subjectName,
              exam_name: examInfo?.examName,
            });
          } else {
            console.error("No practice questions available");
          }
        })
      );
    } catch (error) {
      console.error("Failed to fetch practice questions:", error);
    }
  };

  // const handleShowPracQuest = async () => {
  //   debugger
  //   try {
  //     dispatch(fetchPracticeQuestion({ ExamId: input.examId }));
  //     if (practiceQuestionCount?.data?.testQuestion?.length > 0) {
  //       // the first id the array is stored in firsQuestionId
  //       const firstQuestionId =
  //         practiceQuestionCount?.data?.testQuestion[0].examQuestionId;
  //       dispatch(fetchPracticeQuestionsByQuestionId(firstQuestionId));
  //       setExamPractQuestionId(firstQuestionId);
  //     }
  //     setShowPracticeQuestion(true);
  //     handleClose(true);

  //     trackSpecificEvent("practice_question_started", {
  //       subject_name: examInfo?.subjectName,
  //       exam_name: examInfo?.examName,
  //     });
  //   } catch (error) {
  //     console.error("Failed to fetch practice questions:", error);
  //   }
  // };

  // function that handles the next button for practice question

  const getPracticeQuestion = () => {
    if (
      selectedPracticeQuestionIndex <
      practiceQuestionCount?.data.testQuestion?.length
    ) {
      const newQuestionId =
        practiceQuestionCount?.data.testQuestion[
          selectedPracticeQuestionIndex + 1
        ]?.examQuestionId;
      dispatch(fetchPracticeQuestionsByQuestionId(newQuestionId));
      setSelectedPracticeQuestionIndex(selectedPracticeQuestionIndex + 1);
    }
  };
  const maxCountPractice = practiceQuestionCount?.data.testQuestion?.length - 1;

  function removeHtmlTags(input) {
    // Use a regular expression to match and remove HTML tags
    return input.replace(/<[^>]*>/g, "");
  }

  const showWorkings = () => {};

  const handleCheck = () => {
    setOpenAnswer(!openAnswer);
    showWorkings();
  };

  // console.log(showWorkings());
  const handleClick = (label) => {
    setCalculatorInput((prevState) => handleInput(label, { ...prevState }));
  };
  // const allocatedTime = 45000;
  const index = selectedQuestionIndex + 1;
  const { firstName } = userInfo;

  const totalQuestions = examCount?.testQuestion?.length || 0; // Total number of questions

  const flaggedQuestionsCount =
    examCount?.testQuestion?.filter((question) => question?.isFlagged === true)
      ?.length || 0; // Number of flagged questions

  const nonFlaggedQuestions =
    examCount?.testQuestion?.filter(
      (question) => question?.isFlagged === false
    ) || [];

  const flaggedQuestions =
    examCount?.testQuestion?.filter(
      (question) => question?.isFlagged === true
    ) || [];

  // const remainingQuestions = checkSub?.msg === "InActive" ? 5 : nonFlaggedQuestions.length;
  const remainingQuestions = nonFlaggedQuestions?.length;

  const maxCounts = remainingQuestions - 1;

  const freeQuestionsIndex = [...Array(5).keys()];

  return (
    <React.Fragment>
      {showPracticeQuestion ? (
        <PracticeQuestion
          examInfo={examInfo}
          setShowPracticeQuestion={setShowPracticeQuestion}
          handleShowPracQuestion={handleShowPracQuest}
          getPracticeQuestion={getPracticeQuestion}
          SubjectIDS={props.SubjectID}
          YearExam={props.YearofExam}
          examTypeId={input.examType}
          maxCountPractice={maxCountPractice}
          selectedPracticeQuestionIndex={selectedPracticeQuestionIndex}
          examType={props.examType}
          examPractQuestionId={examPractQuestionId}
        />
      ) : (
        <>
          <Grid item xs={12}>
            <Box
              color={"#848484"}
              py={2}
              px={5}
              justifyContent={"flex-end"}
              display={{ xs: "flex" }}
            >
              <Stack textAlign={"center"}>
                <Typography
                  fontWeight={"bolder"}
                  color={"#111"}
                  variant="h6"
                >{`${hours.toString().padStart(2, "0")}:`}</Typography>
                <Typography variant="body1" sx={{ mr: 1 }}>
                  hours
                </Typography>
              </Stack>
              <Stack textAlign={"center"}>
                <Typography
                  fontWeight={"bolder"}
                  color={"#111"}
                  variant="h6"
                >{`${minutes.toString().padStart(2, "0")}:`}</Typography>
                <Typography variant="body1" sx={{ mr: 1 }}>
                  minutes
                </Typography>
              </Stack>
              <Stack textAlign={"center"}>
                <Typography
                  fontWeight={"bolder"}
                  color={"#111"}
                  variant="h6"
                >{`${seconds.toString().padStart(2, "0")}`}</Typography>
                <Typography variant="body1">seconds</Typography>
              </Stack>

              <Stack
                textAlign={"center"}
                sx={{ display: showCalculatorIcon ? "block" : "none" }}
              >
                <Tooltip title="Show calculator">
                  <IconButton
                    onClick={() => setCalculatorModal(true)}
                    aria-label="Show calculator"
                    color={"info"}
                    size="large"
                  >
                    <CalculateIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
          </Grid>
          <Grid sx={pagesViewed.boxWidthStyle}>
            <Paper elevation={0} sx={{ px: 6, py: 4, borderRadius: 3 }}>
              {loadingQuestion ? (
                <div className="flex items-center justify-center w-full">
                  <DataUsage
                    htmlColor="#40499D"
                    size={24}
                    className="animate-spin"
                  />
                </div>
              ) : (
                <div className="flex flex-col">
                  {/* ... Other components ... */}
                  {window.HTMLReactParser(
                    examQuestion?.sectionInstruction || "",
                    {
                      replace: (domNode) => {
                        if (domNode.type === "tag" && domNode.name === "img") {
                          return (
                            <img
                              src={domNode.attribs.src}
                              alt={domNode.attribs.alt}
                              onLoad={() => {}}
                              onError={() => {}}
                            />
                          );
                        }
                        return undefined;
                      },
                    }
                  ) ? (
                    <Paper
                      sx={{
                        mt: 2,
                        p: 2,
                        border: 1,
                        borderColor: "primary.main",
                        display: examQuestion?.sectionInstruction
                          ? "block"
                          : "none",
                      }}
                    >
                      <Typography
                        fontWeight={"bolder"}
                        color={"#111"}
                        variant="h6"
                      >
                        Instruction
                      </Typography>

                      {window?.HTMLReactParser(
                        examQuestion?.sectionInstruction || ""
                      )}
                    </Paper>
                  ) : (
                    block
                  )}
                  {examQuestion?.choices?.length === 0 && (
                    <Paper>
                      <Box pt={4}>
                        <Typography
                          fontWeight={"bolder"}
                          color={"#111"}
                          variant="h6"
                        >
                          Question
                        </Typography>
                      </Box>

                      {/* {window.HTMLReactParser(examQuestion?.question || "")} */}
                      <RTEViewer
                        value={examQuestion?.question || ""}
                        style={{ height: "fit-content" }}
                      />
                    </Paper>
                  )}
                  {/* this extra for section instruction not to show number for Theory */}
                  {examQuestion?.choices?.length !== 0 && (
                    <p>
                      <Typography
                        fontWeight={"bolder"}
                        color={"#111"}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "10",
                          marginRight: "10px",
                          marginTop: "10px",
                          padding: "5px 0",
                        }}
                      >
                        {selectedQuestionIndex + 1}
                        {"."} &nbsp; &nbsp;{"  "}
                        {/* Adding a space here */}
                        <div className="flex flex-col">
                          {/*<KatexRenderer expression= */}
                          {/* {window.HTMLReactParser(examQuestion?.question || "")} */}
                          <RTEViewer value={examQuestion?.question || ""} />
                          {/* /> */}
                        </div>
                      </Typography>{" "}
                    </p>
                  )}
                  {/* {window.HTMLReactParser(examQuestion?.question || "")} */}
                  {/* {window.HTMLReactParser(examQuestion?.question || "")} */}
                  {props.examType !== 2 ? (
                    <ExamChoices
                      examQuestion={examQuestion}
                      examCount={examCount?.testQuestion}
                    />
                  ) : (
                    ""
                  )}
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mr: 1.5,
                      mb: 2,
                      mt: 2,
                    }}
                  >
                    {openAnswer === true ? (
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "green" }}
                        onClick={() => {
                          handleCheck();
                        }}
                      >
                        Hide Solution
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleCheck();
                        }}
                      >
                        Check Solution
                      </Button>
                    )}
                  </Grid>
                </div>
              )}

              {openAnswer === true ? (
                <ExamAnswer
                  examQuestion={examQuestion}
                  examCount={examCount?.testQuestion}
                  open={openAnswer}
                  setOpenAnswer={setOpenAnswer}
                  examType={props.examType}
                  //added this extra for fontWeight (Theory)
                  isTheory={examQuestion?.choices?.length === 0}
                />
              ) : (
                ""
              )}
            </Paper>
          </Grid>
          {/* <Grid item xs={12}> */}
          {/* Commented out Avatar till a better quality is provided */}
          {/* <Box
            sx={{
              position: "absolute",
              display: index % 7 === 0 && index <= 50 ? "block" : "none",
            }}
          >
            <img src={StudyAvatar} alt="Study Well" style={{ height: 170 }} />
            <Stack>
              <img
                src={QuickTip}
                alt=""
                style={{
                  marginLeft: "8rem",
                  marginTop: "-10rem",
                  // position: "relative",
                  width: "50%",
                }}
              />
              <Typography
                style={{
                  marginLeft: "9rem",
                  marginTop: "-9.5rem",
                  position: "absolute",
                  display: "flex",
                  // flexWrap: "wrap",
                  justifyItems: "center",
                }}
              >
                {" "}
                Well done {firstName}
              </Typography>
            </Stack>
          </Box> */}
          {/* Commented out Avatar till a better quality is provided */}
          {/* </Grid> */}

          <Grid
            sx={{
              position: "relative",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "4rem",
              "& > :not(style)": {
                m: 1,
              },
            }}
          >
            <Paper
              elevation={0}
              sx={{ mr: 2, p: 2, borderRadius: 3, marginTop: 1 }}
            >
              <Stack spacing={2}>
                <Pagination
                  count={remainingQuestions}
                  // onChange={handleChange}
                  variant="outlined"
                  onClick={() => selectedQuestionIndex}
                  shape="rounded"
                  type="next"
                  selected={true}
                  disabled
                  color="primary"
                  boundaryCount={remainingQuestions}
                  page={selectedQuestionIndex + 1}
                  hidePrevButton
                  hideNextButton
                  renderItem={(item) => (
                    <PaginationItem
                      component={Button}
                      // disabled={item.page !== selectedQuestionIndex + 1}
                      {...item}
                      onClick={() => handleChange(item.page - 1)}
                      style={
                        isViewed(item.page)
                          ? pagesViewed.regularPage
                          : item.selected // Check if the item is the active page
                          ? pagesViewed.viewedPage
                          : pagesViewed.disabledStyle
                      }
                    />
                  )}
                />
              </Stack>
            </Paper>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: 4,
              "& > *": {
                m: 1,
              },
            }}
          >
            <Stack spacing={1} direction="row">
              {maxCounts?.length == 1 ? (
                <Button
                  onClick={handleOpenModal}
                  variant="contained"
                  // disabled={selectedQuestionIndex === 0}
                  style={{ backgroundColor: "#05765F" }}
                >
                  Complete Study
                </Button>
              ) : !nextButtonClicked ? (
                <Button
                  onClick={handleNext}
                  variant="contained"
                  // disabled={selectedQuestionIndex === 0}
                >
                  Next <EastOutlinedIcon />
                </Button>
              ) : (
                <>
                  <Button
                    onClick={handlePrevious}
                    variant="outlined"
                    disabled={selectedQuestionIndex === 0}
                  >
                    <KeyboardBackspaceOutlinedIcon />
                    Previous{" "}
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant={
                      selectedQuestionIndex === maxCounts
                        ? "outlined"
                        : "contained"
                    }
                    hidden={selectedQuestionIndex === maxCounts}
                    style={{
                      backgroundColor:
                        selectedQuestionIndex === maxCounts
                          ? "transparent"
                          : "#40499D",
                      display:
                        selectedQuestionIndex === maxCounts ? "none" : "block",
                    }}
                  >
                    Next
                    <EastOutlinedIcon />
                  </Button>
                  {selectedQuestionIndex === maxCounts ? (
                    <Button
                      onClick={handleOpenModal}
                      variant="contained"
                      // disabled={selectedQuestionIndex === 0}
                      style={{ backgroundColor: "#05765F" }}
                    >
                      Complete Study
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Stack>
          </Grid>

          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <DialogContentText>
                <ErrorOutlineOutlinedIcon
                  style={{
                    height: 50,
                    // width: 0,
                    color: "#ef7c21",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: ".7em",
                  }}
                />
                {/* </IconButton> */}
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", color: "#1e1e1e" }}
                >
                  Are you ready to complete your study?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                color: "#ef7c21",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1em",
                // width: "100%",
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{ borderColor: "#ef7c21", color: "#ef7c21" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleOpenModal}
                variant="contained"
                style={{ backgroundColor: "#ef7c21" }}
              >
                {" "}
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {/* Calculator Dialog */}
          <Drawer
            anchor="right"
            // onClose={toggleDrawer("addNonAcademicBulkDrawer", false)}
            // onClose={() => setCalculatorModal(false)}
            open={calculatorModal}
            style={{ backgroundColor: "transparent" }}
          >
            <div
              tabIndex={0}
              role="button"
              style={{ background: "transparent" }}
            >
              <DialogTitle
                display={"flex"}
                className="items-center justify-between"
              >
                <h5 className="font-bold">Calculator</h5>
                <IconButton
                  className="mb-2"
                  onClick={() => setCalculatorModal(false)}
                >
                  <CloseRounded color="error" />
                </IconButton>
              </DialogTitle>
              <Calculator
                result={calculatorInput.displayValue}
                expression={calculatorInput.expression}
                error={calculatorInput.error}
                handleClick={handleClick}
              />
              {/* <Suspense
                    fallback={
                      <div
                        className="loader-view"
                        style={{
                          height:
                            width >= 1200
                              ? "calc(100vh - 265px)"
                              : "calc(100vh - 245px)",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    <EditAcademic
                      handleEdit={this.handleStaffEdit}
                      staff={staff}
                      departments={departments}
                    />
                  </Suspense> */}
            </div>
            {/* <DialogTitle display={"flex"}>
              <Typography component={"p"} variant="h5" fontWeight={"bold"}>
                Calculator
              </Typography>
              <IconButton
                sx={{ justifySelf: "end", ml: "auto" }}
                onClick={() => setCalculatorModal(false)}
              >
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <Calculator /> */}
          </Drawer>

          {/* modal that shows the student to proceed to take practice */}
          <PracticeModal
            openModal={openModal}
            handleClose={handleClose}
            input={input}
            SubjectIDS={props.SubjectID}
            YearExam={props.YearofExam}
            examTypeId={input.examType}
            setShowPracticeQuestion={setShowPracticeQuestion}
            handleShowPracQuestion={handleShowPracQuest}
            // getPracticeQuestion={getPracticeQuestion}
            examType={props.examType}
            examPractQuestionId={examPractQuestionId}
          />
          {/* blocker modal for free user */}
          <PremiumInfoDialog
            open={showPremiumDialog}
            setDisplayVideo={setDisplayVideo}
            setsecondsLeft={setsecondsLeft}
            handleTimeOut={handleTimeOut}
            handleClose={() => setShowPremiumDialog(false)}
            selectedQuestionIndex={selectedQuestionIndex}
          />
          {/* {displayVideo && (
            <PremiumVideo
              setDisplayVideo={setDisplayVideo}
              setShowPremiumDialog={setShowPremiumDialog}
              setFreeQuestion={setFreeQuestion}
            />
          )} */}
          <FreeFiveQuestions open={freeQuestion} close={setFreeQuestion} />
        </>
      )}

      <ToastState />
    </React.Fragment>
  );
}
