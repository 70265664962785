import Axios from "../../connection/defaultClient";
import * as gamificationActions from "./types";
// {
//   GET_LEADERBOARD_FAIL,
//   GET_LEADERBOARD_LOADING,
//   GET_LEADERBOARD_SUCCESS,
//   GET_USER_POINT_DIAMOND
// }

// GetLeaderboard
export const getLeaderboardByLocation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: gamificationActions.GET_LEADERBOARD_LOADING,
      payload: true,
    });
    Axios.get(`/Gamification/Leaderboard`, { params: payload })
      .then((response) => {
        dispatch({
          type: gamificationActions.GET_LEADERBOARD_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LEADERBOARD_FAIL,
          payload: error.message,
        });
      });
  };
};

export const SubmitTriviaPoints = (payload) => {
  return (dispatch) => {
    // dispatch({
    //   type: GET_POINTS,  payload: true,});
    Axios.post(`/Gamification/SubmitTriviaPoints`, payload)
      .then((response) => {
        dispatch({
          type: gamificationActions.GET_TRIVIA_POINTS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: gamificationActions.GET_TRIVIA_POINTS_FAIL,
          payload: error.msg,
        });
      });
  };
};

export const FetchUserPointandDiamonds = (profileId, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: gamificationActions.GET_USER_POINT_DIAMOND_LOADING,
      payload: true,
    });
    Axios.get(
      `/Gamification/GetPointsAndDiamondsByProfileId?ProfileId=${profileId}`
    )
      .then((response) => {
        dispatch({
          type: gamificationActions.GET_USER_POINT_DIAMOND,
          payload: response.data.data,
        });
        // if (response.data.state == 1) {
        //   onSuccess(response.data);
        // } else if (response.data.state == -1) {
        //   onFail(response.data);
        // }
      })
      .catch((error) => {
        dispatch({
          type: gamificationActions.GET_USER_POINT_DIAMOND_FAIL,
          payload: error.msg,
        });
      });
  };
};
export const useDiamonds = (profileId, Hint) => {
  return (dispatch) => {
    dispatch({
      type: gamificationActions.USE_USER_DIAMOND_LOADING,
      payload: true,
    });
    Axios.get(`/Gamification/UseDiamonds?profileId=${profileId}&Hint=${Hint}`)
      .then((response) => {
        dispatch({
          type: gamificationActions.USE_USER_DIAMOND,
          payload: response.data.state,
        });
        // if (response.data.state == 1) {
        //   onSuccess(response.data);
        // } else if (response.data.state == -1) {
        //   onFail(response.data);
        // }
      })
      .catch((error) => {
        dispatch({
          type: gamificationActions.USE_USER_DIAMOND_FAIL,
          payload: error.msg,
        });
      });
  };
};
