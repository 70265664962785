import React from "react";
import AuthNavbar from "../components/navbars/AuthNavbar";
import { Box, Typography } from "@mui/material";

const AuthLayout = ({ children }) => {
  return (
    <React.Fragment>
      <main className="relative min-h-screen">
        <AuthNavbar />

        <section>{children}</section>

        <Box
          padding={"20px 0px 0px 0px"}
          sx={{ padding: { xs: "50px 0px 0px 0px", md: "20px 0px 0px 0px" } }}
          className="bg-e-study-bg-primary text-white"
        >
          <Typography
            fontFamily={"Montserrat"}
            variant="body1"
            textAlign={"center"}
            sx={{ fontSize: { xs: "12px", md: "14px" } }}
            className="pb-12"
          >
            &copy; {new Date().getFullYear()} Sidmach Technologies Nigeria
            Limited. ALL RIGHTS RESERVED.
          </Typography>
        </Box>
      </main>
    </React.Fragment>
  );
};

export default AuthLayout;
