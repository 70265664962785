import React, { useRef, useState, useEffect } from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import {
    Avatar,
    Button,
    Box,
    Divider,
    MenuItem,
    Typography,
    IconButton,
    ListItemAvatar,
    Tooltip,
    ThemeProvider,
    Badge,
    Stack,
} from "@mui/material";
import MenuPopover from "./MenuPopover";
import SettingsIcon from "@mui/icons-material/Settings";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import InputOutlinedIcon from "@mui/icons-material/InputOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import customTheme from "../AuthReuseables/customTheme";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "../../redux/auth/authActions";
import useUser from "../../hooks/useUser";
import useAdminUser from "../../hooks/useAdminUser";
import useSettings from "../../hooks/useSettings";
import { toggleTheme } from "../../redux/settings/settingsActions";
import { logoutAdminUserAction } from "../../redux/adminAuth/adminAuthActions";

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#78BF39",
        color: "#78BF39",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid white",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#40499D",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));

export default function AdminAccountPopover() {
    const dispatch = useDispatch();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const user = useUser();
    const { darkMode } = useSettings();
    const adminUser = useAdminUser();
    const userInfo = user.token ? user?.userInfo : adminUser?.userInfo?.data;

    const userToken = user?.token ? user?.token : adminUser?.token;

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        if (adminUser?.token) {
            dispatch(logoutAdminUserAction(userToken))
        } else {
            dispatch(logoutUserAction(userToken));
        }
    };
    const MENU_OPTIONS = [
        {
            label: "Account",
            icon: <PermIdentityIcon />,
            linkTo: "/admin/settings",
        },
        {
            label: "Settings",
            icon: <SettingsIcon />,
            linkTo: "/admin/settings",
        },
        {
            label: "Help Centre",
            icon: <HelpOutlineIcon />,
            linkTo: "#",
        },
    ];

    return (
        <>
            <Tooltip title="Profile">
                <IconButton ref={anchorRef} onClick={handleOpen} sx={{ ml: 0, pl: 0 }}>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        variant="dot"
                    >
                        {userInfo?.sponsorProfilePic !== null ?
                            <Avatar alt="sponsorPic" src={userInfo?.sponsorProfilePic} sx={{ cursor: "pointer" }} /> :
                            <Avatar
                                sx={{
                                    cursor: "pointer",
                                    height: 40,
                                    width: 40,
                                    bgcolor: "#40499D",
                                }}
                            />
                        }
                    </StyledBadge>
                </IconButton>
            </Tooltip>
            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 370 }}
            >
                <List sx={{ width: "100%", maxWidth: 360 }}>
                    <ListItem>
                        <ListItemAvatar>
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                variant="dot"
                            >
                                {userInfo?.sponsorProfilePic !== null ?
                                    <Avatar alt="sponsorPic" src={userInfo?.sponsorProfilePic} sx={{ cursor: "pointer" }} /> :
                                    <Avatar
                                        sx={{
                                            cursor: "pointer",
                                            height: 40,
                                            width: 40,
                                            bgcolor: "#40499D",
                                        }}
                                    />
                                }
                            </StyledBadge>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        color: "#262527",
                                    }}
                                >
                                    {userInfo?.firstName
                                        ? userInfo?.firstName
                                        : userInfo?.firstname + " " + userInfo?.surname}
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: "15px",
                                        color: "#5A5D6C",
                                    }}
                                >
                                    {userInfo?.email ? userInfo?.email : "useremail@gmail.com"}
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>

                <Divider />

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{ typography: "body2", py: 1, px: 2.5 }}
                    >
                        <Typography sx={{ pr: 1 }}>
                            {React.cloneElement(option.icon, {
                                sx: {
                                    fontSize: "26px",
                                    color: "#5A5D6C",
                                },
                            })}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: "15px",
                                color: "#262527",
                                fontWeight: "500",
                            }}
                        >

                            {option.label}
                        </Typography>
                    </MenuItem>
                ))}

                <Divider />

                <MenuItem
                    onClick={handleLogout}
                    sx={{ typography: "body2", my: 1, px: 2.5 }}
                >
                    <Typography sx={{ pr: 1 }}>
                        {React.cloneElement(<InputOutlinedIcon />, {
                            sx: {
                                fontSize: "26px",
                                color: "#5A5D6C",
                            },
                        })}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: "15px",
                            color: "#262527",
                            fontWeight: "500",
                        }}
                    >
                        Logout
                    </Typography>
                </MenuItem>
            </MenuPopover>
        </>
    );
}
