import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const TextBox = ({ children }) => {
  return (
    <Box
      borderRadius={"5px"}
      px={"3px"}
      py={"4px"}
      bgcolor={"white"}
      color={"brandPrimary.main"}
    >
      <Typography variant="h4" component={"span"}>
        {children}
      </Typography>
    </Box>
  );
};

export const LeaderboardTimer = ({ dd, hh, mm }) => {
  return (
    <>
      {/* <Stack gap alignItems={"flex-start"} direction="row">
        <Stack>
          <Stack gap direction={"row"}>
            <TextBox>0</TextBox>
            <TextBox>2</TextBox>
          </Stack>
          <Typography textAlign={"end"}>Days</Typography>
        </Stack>
        <Typography
          component={"span"}
          variant="h2"
          color={"brandSecondary.main"}
        >
          :
        </Typography>
        <Stack>
          <Stack gap direction={"row"}>
            <TextBox>3</TextBox>
            <TextBox>4</TextBox>
          </Stack>
          <Typography textAlign={"end"}>Hours</Typography>
        </Stack>
        <Typography
          component={"span"}
          variant="h2"
          color={"brandSecondary.main"}
        >
          :
        </Typography>
        <Stack>
          <Stack gap direction={"row"}>
            <TextBox>5</TextBox>
            <TextBox>9</TextBox>
          </Stack>
          <Typography textAlign={"end"}>Minutes</Typography>
        </Stack>
      </Stack> */}
      <div className="flex gap-4 items-center">
        <div className="flex flex-col items-center">
          <div className="flex gap-2">
            <div className="flex items-center justify-center h-9 w-7 bg-white text-e-study-bg-primary text-base font-semibold rounded-[5px]">
              0
            </div>
            <div className="flex items-center justify-center h-9 w-7 bg-white text-e-study-bg-primary text-base font-semibold rounded-[5px]">
              1
            </div>
          </div>
          <span className="text-xs self-end">Days</span>
        </div>

        <div className="flex flex-col gap-2 relative bottom-1.5">
          <div className="bg-yellow-600 w-1.5 h-1.5"></div>
          <div className="bg-yellow-600 w-1.5 h-1.5"></div>
        </div>

        <div className="flex flex-col items-center">
          <div className="flex gap-2">
            <div className="flex items-center justify-center h-9 w-7 bg-white text-e-study-bg-primary text-base font-semibold rounded-[5px]">
              0
            </div>
            <div className="flex items-center justify-center h-9 w-7 bg-white text-e-study-bg-primary text-base font-semibold rounded-[5px]">
              1
            </div>
          </div>
          <span className="text-xs self-end">Hours</span>
        </div>

        <div className="flex flex-col gap-2 relative bottom-1.5">
          <div className="bg-yellow-600 w-1.5 h-1.5"></div>
          <div className="bg-yellow-600 w-1.5 h-1.5"></div>
        </div>

        <div className="flex flex-col items-center">
          <div className="flex gap-2">
            <div className="flex items-center justify-center h-9 w-7 bg-white text-e-study-bg-primary text-base font-semibold rounded-[5px]">
              0
            </div>
            <div className="flex items-center justify-center h-9 w-7 bg-white text-e-study-bg-primary text-base font-semibold rounded-[5px]">
              1
            </div>
          </div>
          <span className="text-xs self-end">Minutes</span>
        </div>
      </div>
    </>
  );
};
