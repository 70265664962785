import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";
import { verifyAccountAction } from "../../redux/auth/authActions";
// import Preloader from "../../components/AuthReuseables/Preloader/Preloader";
import { useNotifications } from "../../components/notifications/notifications";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/Preloader/Preloader";
import { useGoogleAnalytics } from "../../hooks/usePageTracking";

const VerifyNewUser = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const [fail, setFail] = useState("");

  const verifyEmailURL = window.location.href;
  const url = new URL(verifyEmailURL);
  const clientId = url.searchParams.get("q");
  const { trackSpecificEvent } = useGoogleAnalytics();

  const [ToastState, setToastState] = useNotifications();

  useEffect(() => {
    if (msg) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: msg,
      });
      setTimeout(() => {
        navigate("/auth/login");
      }, 2000);
    }
  }, [msg]);

  useEffect(() => {
    if (fail) {
      setToastState({
        isOpen: true,
        severity: "error",
        message: fail,
      });
      setTimeout(() => {
        navigate("/auth/signup");
      }, 2000);
    }
  }, [fail]);

  useEffect(() => {
    if (clientId) {
      trackSpecificEvent("Verify account", "User tried to verify account");
      dispatch(
        verifyAccountAction(
          clientId,
          (res) => setMsg(res.data.msg),
          (res) => setFail(res.data.msg)
        )
      );
    }
  }, [clientId]);

  const onSuccess = () => {
    setToastState({
      isOpen: true,
      severity: "Success",
      message: "Email Verified",
    });
    // setTimeout(() => {
    //   window.location.href = "/auth/login";
    // }, 5000);
  };

  return (
    <>
      <div style={{ paddingTop: "30px" }}>{user?.isLoading ? <Preloader /> : ""}</div>
      <ToastState />
    </>
  );
};

export default VerifyNewUser;
