import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import React from "react";
import Asynchronous from "../Autocomplete/CountryAsynchronous";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CountryAsynchronous from "../Autocomplete/CountryAsynchronous";
import StateAsynchronous from "../Autocomplete/StateAsynchronous";

const AdminUpdateProfile = ({ handleChange, nav, formValues, allCountries, handler, countryHandler, updateValue, values, updateStateValue, stateHandler, stateValues }) => {
    return (
        <Grid container spacing={3}>
            {nav === "name" ? (
                <>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Surname"
                                variant="outlined"
                                name="surname"
                                value={formValues?.surname}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                name="firstname"
                                value={formValues?.firstname}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Other Name"
                                variant="outlined"
                                name="otherNames"
                                value={formValues?.otherNames}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Email Address"
                                variant="outlined"
                                name="email"
                                disabled
                                value={formValues?.email}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                name="gsmNumber"
                                type="tel"
                                value={formValues?.gsmNumber}
                                onChange={(e) => {
                                    if (/\d{4}[-\s]?\d{3}[-\s]?\d{4}|\+234[-\s]?\d{3}[-\s]?\d{3}[-\s]?\d{4}/g.test(e.target.value)) {
                                        handleChange(e)
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker", "DatePicker"]}>
                                    <DatePicker
                                        name="dateOfBirth"
                                        label="Date of Birth"
                                        value={dayjs(formValues?.dateOfBirth)}
                                        onChange={(newValue) => handler(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid> */}
                    {/* <Grid item xs={6}>
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                                Gender
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="gender"
                                value={formValues?.gender}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="Female"
                                    control={<Radio />}
                                    label="Female"
                                />
                                <FormControlLabel
                                    value="Male"
                                    control={<Radio />}
                                    label="Male"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid> */}

                    {/* <Grid item xs={6}>
                        <FormControl fullWidth>
                            <CountryAsynchronous label={"Country"} updateValue={updateValue} countryHandler={countryHandler} values={values} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <StateAsynchronous label={"State"} updateStateValue={updateStateValue} stateHandler={stateHandler} stateValues={stateValues} />
                        </FormControl>
                    </Grid> */}
                </>
            )}
        </Grid>
    );
};

export default AdminUpdateProfile;
