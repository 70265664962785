import * as React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "primary",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "primary",

    "&:hover": {
      color: "primary",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "primary",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function CustomizedTabs({ Item, handleChange, value, label }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ bgcolor: "#fff" }}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          {label?.map((item) => (
            <AntTab key={item} label={item} />
          ))}
        </AntTabs>
      </Box>
      <Box>
        {Item?.map(
          (
            item //Item is an array of objects
          ) => (
            <TabPanel key={item?.value} value={value} index={item?.value}>
              {item?.item}
            </TabPanel>
          )
        )}
        {/* <Box sx={{ p: 1 }} /> */}
      </Box>
    </Box>
  );
}
