import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StarRateTwoToneIcon from "@mui/icons-material/StarRateTwoTone";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import AveragePerformancePerSubject from "../../components/dashboards/students/AveragePerformancePerSubject"
import {
  OverviewStats,
  StudiesInProgress,
  StudyChart,
  SubjectsChart,
} from "../../components/dashboards";
import useUser from "../../hooks/useUser";
import styled from "@emotion/styled";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddCoreSubjectsDialog from "../../components/dashboards/students/AddCoreSubjectsDialog";
import { useState } from "react";
import {
  BrainIcon,
  CompletedTestStatsIcon,
  FreemiumIcon,
  HighestScoreIcon,
  NumberofBadgesIcon,
  PointsIcon,
  ProdigyIcon,
  QuestionMarkIcon,
  CalendarSvgIcon,
} from "../../assets/icons/Icons";
import CoreBackground from "../../assets/images/CoreBackground.png";
import RedCard from "../../assets/images/RedCard.png";
import GreenCard from "../../assets/images/GreenCard.png";
import HornImage from "../../assets/images/hornIcon.png";
import DashboardCards from "../../components/Test/DashboardCards";
import ProgressBar from "../../components/dashboards/students/ProgressBar";
import AchievementCards from "../../components/Test/AchievementCards";
import { ReactComponent as UpgradeBot } from "../../assets/images/upgradeBot.svg";
import zIndex from "@mui/material/styles/zIndex";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  fetchStudentAveragePerformanceStat,
  fetchStudentDashboardStat,
  fetchStudiesInProgressStat,
} from "../../redux/dashboard/dashboardActions";
import useDashboard from "../../hooks/useDashboard";
import StudyTimeChart from "../../components/dashboards/students/StudyTimeChart";
import { useNavigate } from "react-router-dom";
import useTest from "../../hooks/useTest";
import { fetchSubjectsByProfileId } from "../../redux/test/testActions";
import { useTheme } from "@emotion/react";

const ElevatedButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "13px",
  padding: theme.spacing(1),
  margin: "10px 10px 10px 30px",
  backgroundColor: "rgba(64, 73, 157, 1)",
  color: "#fff",
  "&:hover": { backgroundColor: "#40499D", boxShadow: "none" },
}));

function TempDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const { subjectsData, subjectLoaded } = useTest();
  const {
    dashboardStat,
    dashboardStudyIPStat,
    dashboardTestStat,
    isLoading,
    isLoadingg,
    loadingg,
  } = useDashboard();
  const [openAddCoreSubjectsDialog, setOpenAddCoreSubjectsDialog] =
    useState(false);
  const [pathSubjects, setPathSubjects] = useState([]);
  const [usersChart, setUsersChart] = useState("study");
  const [studiesInProgress, setStudiesInProgress] = useState([]);
  const [averageTest, setAverageTest] = useState([]);

  const theme = useTheme();
  const isXsScreen = theme.breakpoints.down("xs");
  const botSize = isXsScreen ? 180 : 140;

  const handleChartChange = (e) => {
    setUsersChart(e.target.value);
  };

  const handleOpenCoreSubjectsDialog = () => {
    setOpenAddCoreSubjectsDialog(true);
  };

  const handleCloseCoreSubjectsDialog = () => {
    setOpenAddCoreSubjectsDialog(false);
  };

  const achievementCardItems = [
    {
      id: 1,
      emoji: "💪",
      color: "",
      isLocked: false,
    },
  ];

  useEffect(() => {
    dispatch(fetchSubjectsByProfileId(userInfo?.profileId));
  }, [userInfo]);

  useEffect(() => {
    if (subjectsData) {
      setPathSubjects(subjectsData);
    }
  }, [subjectsData]);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudentDashboardStat(userProfileId));
    }
  }, [userInfo]);

  const isNotDefaultCoreSubjects = pathSubjects?.some(
    (subject) => !subject.IsDefaultCore
  );
  const isDefaultCoreSubjects = !isNotDefaultCoreSubjects;

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudiesInProgressStat(userProfileId));
    }
  }, [userInfo]);

  useEffect(() => {
    if (dashboardStudyIPStat) {
      setStudiesInProgress(dashboardStudyIPStat);
    }
  }, [dashboardStudyIPStat]);

  useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchStudentAveragePerformanceStat(userProfileId));
    }
  }, [userInfo]);

  useEffect(() => {
    if (dashboardTestStat) {
      setAverageTest(dashboardTestStat);
    }
  }, [dashboardTestStat]);

  return (
    <>
      <Container sx={{ padding: { xs: "0px" } }}>
        <Box component={Stack} gap={5} padding={0} bgcolor={"#FAFAFB"}>
          <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={3}
            // alignItems={"stretch"}
          >
            <Box width={{ xs: "100%", md: "60%" }} height={"auto"}>
              <Typography
                variant="h5"
                fontWeight={"medium"}
                mb={{ xs: 1, sm: 2 }}
                sx={{ fontSize: { xs: "14px", sm: "24px" } }}
              >
                My Overview
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm: "space-around" },
                  flexDirection: { xs: "column", sm: "row" },
                  borderRadius: "14px",
                  border: "1px solid #E8E8E8",
                  boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Stack alignItems={"center"} gap pb={{ xs: 1, sm: 0 }}>
                  <Avatar
                    sx={{
                      height: { xs: 35, sm: 55 },
                      width: { xs: 35, sm: 55 },
                      bgcolor: "#40499D",
                    }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    textAlign={"center"}
                    sx={{ fontSize: { xs: "13px", sm: "18px" } }}
                  >
                    {userInfo?.firstName}
                  </Typography>
                  {/* <Chip
                    icon={<PointsIcon />}
                    pt={1}
                    size="small"
                    label="0 Point"
                  /> */}
                </Stack>
                <OverviewStats />
              </Paper>
            </Box>

            <Box width={{ xs: "100%", md: "40%" }} height={"auto"}>
              <Typography
                variant="h5"
                fontWeight={"medium"}
                mb={{ xs: 1, sm: 2 }}
                sx={{ fontSize: { xs: "14px", sm: "24px" } }}
              >
                My Core Subjects
              </Typography>
              {!subjectLoaded ? (
                <Skeleton variant="rectangular" width="100%" height={200} />
              ) : isNotDefaultCoreSubjects ? (
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    display: "flex",
                    borderRadius: "14px",
                    height: "85%",
                    flexDirection: "column",
                    border: "1px solid #E8E8E8",
                    boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Box display="flex" justifyContent={"flex-end"}>
                    <Button
                      color="brandPrimary"
                      variant="outlined"
                      onClick={handleOpenCoreSubjectsDialog}
                      sx={{
                        borderRadius: "5px",
                        fontSize: "11px",
                        textTransform: "inherit",
                        p: "5px 10px",
                      }}
                    >
                      Update Core Subjects
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      justifyItems: "space-between",
                      alignItems: "center",
                      display: "flex",
                      pt: 2,
                    }}
                  >
                    <Box width={"40%"}>
                      <SubjectsChart />
                    </Box>
                    <Box
                      // component={Stack}

                      gap={2}
                      alignItems={"center"}
                      width={"60%"}
                    >
                      <Box
                        display={"flex"}
                        height={"45%"}
                        alignItems={"center"}
                        gap
                      >
                        {/* <Box component={"div"} height={"100%"} borderRadius={"9999px"} width={"7px"} sx={{ bgcolor: "brandPrimary.main" }}></Box> */}
                        <div
                          style={{
                            borderLeft: "7px #40499D solid",
                            paddingLeft: "10px",
                            borderRadius: "2px",
                          }}
                        >
                          <Stack>
                            <span>
                              <Typography
                                variant="h6"
                                component={"span"}
                                fontWeight={"bold"}
                                sx={{
                                  color: "brandPrimary.main",
                                  fontSize: { xs: "15px", sm: "20px" },
                                }}
                              >
                                {
                                  dashboardStat?.noOfCoreSubjectStudiesInprogress
                                }
                              </Typography>
                              <Typography
                                variant="h6"
                                component={"span"}
                                fontWeight={"bold"}
                                color={"#888"}
                                sx={{ fontSize: { xs: "15px", sm: "20px" } }}
                              >
                                /{dashboardStat?.numberOfCoreSubjects}
                              </Typography>
                            </span>
                            <Typography
                              fontSize={"15px"}
                              color={"#888"}
                              sx={{ fontSize: { xs: "15px" } }}
                            >
                              Core subjects started
                            </Typography>
                          </Stack>
                        </div>
                      </Box>
                      <Box height={"10%"}>
                        <br />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap
                        height={"45%"}
                      >
                        {/* <Box component={"div"} height={"100%"} borderRadius={"9999px"} width={"7px"} sx={{ bgcolor: "#FFD353" }}></Box> */}
                        <div
                          style={{
                            borderLeft: "7px #FFD353 solid",
                            paddingLeft: "10px",
                            borderRadius: "2px",
                          }}
                        >
                          <Stack>
                            <span>
                              <Typography
                                variant="h6"
                                component={"span"}
                                fontWeight={"bold"}
                                sx={{
                                  color: "#FFD353",
                                  fontSize: { xs: "15px", sm: "20px" },
                                }}
                              >
                                {dashboardStat?.noOfCoreSubjectStudiesCompleted}
                              </Typography>
                              <Typography
                                variant="h6"
                                component={"span"}
                                fontWeight={"bold"}
                                color={"#888"}
                                sx={{ fontSize: { xs: "15px", sm: "20px" } }}
                              >
                                /{dashboardStat?.numberOfCoreSubjects}
                              </Typography>
                            </span>
                            <Typography
                              fontSize={"15px"}
                              color={"#888"}
                              sx={{ fontSize: { xs: "14px", sm: "15px" } }}
                            >
                              Core subjects completed per year
                            </Typography>
                          </Stack>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              ) : isDefaultCoreSubjects ? (
                <Paper
                  elevation={0}
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "14px",
                    height: "85%",
                    backgroundImage: `url(${CoreBackground})`,
                    backgroundSize: "cover",
                    border: "1px solid #E8E8E8",
                    boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <ElevatedButton
                    startIcon={<AddOutlinedIcon />}
                    onClick={handleOpenCoreSubjectsDialog}
                  >
                    {" "}
                    Add Core Subjects{" "}
                  </ElevatedButton>
                </Paper>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={3}
            position="relative"
          >
            <Box
              width={{ xs: "100%", md: "60%" }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "14px",
                padding: { sm: "0px 30px", xs: "20px 5px" },
                border: "0.686px solid #E8E8E8",
                background: "linear-gradient(44deg, #D9A92D 0%, #FCC536 100%)",
                boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Box padding={{ xs: "10px", sm: "20px", md: "0px" }}>
                <Typography
                  variant="h6"
                  fontWeight={900}
                  color="#313131"
                  sx={{ fontSize: { xs: "14px", sm: "20px" } }}
                >
                  Get an unlimited experience
                </Typography>
                <Typography
                  variant="body2"
                  maxWidth={{ sm: "75%", xs: "65%" }}
                  sx={{ fontSize: { xs: "12px", sm: "15px" } }}
                >
                  Access to all questions without ads when you subscribe to a
                  premium plan
                </Typography>
                <Button
                  sx={{
                    fontWeight: 900,
                    bgcolor: "#fff",
                    color: "#313131",
                    textTransform: "inherit",
                    borderRadius: "100px",
                    fontSize: { xs: "12px", sm: "14px" },
                    mt: "10px",
                    "&:hover": { bgcolor: "#fff" },
                  }}
                  onClick={() => navigate("/pricing")}
                >
                  Subscribe
                </Button>
              </Box>
            </Box>
            <Box
              position="absolute"
              top={{ xs: "20px", sm: "0px", md: "0px" }}
              right={{ xs: "0%", sm: "5%", md: "42%" }}
            >
              <UpgradeBot width={botSize} height={botSize} />
            </Box>
            <Box
              width={{ xs: "100%", md: "40%" }}
              height={"100%"}
              sx={{
                border: "1.5px solid #DEAB28",
                boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
                borderRadius: "14px",
              }}
            >
              <DashboardCards>
                <Box display={"flex"} flexDirection={"column"} gap={1.5}>
                  <Box
                    display={"flex"}
                    justifyContent="flex-end"
                    alignItems={"center"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#FFF3CE",
                        padding: 1,
                        borderRadius: "9999px",
                      }}
                      justifyContent={"center"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <FreemiumIcon
                        sx={{ fontSize: { md: 14 }, color: "#DEAB28" }}
                      />
                    </Box>
                    <Typography
                      fontSize={"12px"}
                      fontWeight={500}
                      pl={1}
                      color={"#313131"}
                    >
                      Free User
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <AchievementCards content={achievementCardItems} />
                    <ProgressBar />
                  </Box>
                </Box>
              </DashboardCards>
            </Box>
          </Box>

          <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={3}
          >
            <Box
              component={Paper}
              elevation={0}
              width={{ xs: "100%", md: "60%" }}
              p={2}
              sx={{
                borderRadius: "14px",
                border: "1px solid #E8E8E8",
                boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                sx={{ fontSize: { xs: "14px", sm: "22px" } }}
              >
                Study Time
              </Typography>
              <StudyTimeChart />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={{ fontSize: { xs: "14px", sm: "18px" } }}>
                  {" "}
                  Total Study Time (per week)
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: "14px", sm: "20px" },
                    fontWeight: { xs: "600" },
                  }}
                >
                  47h 01m
                </Typography>
              </Box>
            </Box>

            {/* <Box width={{ xs: "100%", md: "60%" }}>
      <Box
        sx={{
          background:  `url(${GreenCard}) center/cover`,
          marginBottom: '16px',
          borderRadius: "10px",
          padding: "20px",
          color: "#fff",
         }}
      >
        <Box sx={{display: "flex", justifyContent: "space-between",alignItems: "center", marginBottom: "20px"}}>
        <Typography variant="h5" gutterBottom sx={{fontSize: "20px"}}>
        Best performing subject
        </Typography>
        <CalendarSvgIcon width={25} height={25}/>
        </Box>
        {isLoading ? (
              <Skeleton variant="rectangular" width="100%" />
            ) : (
        <Typography variant="h3" sx={{fontSize: "28px", fontWeight: 600}}>
        {dashboardStat?.bestPerformingSubject ?? "None"}
        </Typography>
            )}
      </Box>
      <Box
        sx={{
          background: `url(${RedCard}) center/cover`,
          borderRadius: "10px",
          padding: "20px",
          color: "#fff"
        }}
      >
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px",}}>
        <Typography variant="h5" gutterBottom sx={{fontSize: "20px"}}>
        Subject that needs work
        </Typography>
        <CalendarSvgIcon width={25} height={25}/>
        </Box>
        {isLoading ? (
              <Skeleton variant="rectangular" width="100%" />
            ) : (
        <Typography variant="h3" sx={{fontSize: "28px", fontWeight: 600}}>
         {dashboardStat?.poorPerformingSubject ?? "None"}
        </Typography>
            )}
      </Box>
    </Box> */}

            <Box
              component={Paper}
              elevation={0}
              width={{ xs: "100%", md: "40%" }}
              p={2}
              sx={{
                borderRadius: "14px",
                border: "1px solid #E8E8E8",
                boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Stack height={"100%"} justifyContent={"space-evenly"}>
                <Stack>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography
                        variant="subtitile1"
                        color={"#aaa"}
                        pr={1}
                        sx={{ fontSize: { xs: "13px", sm: "16px" } }}
                      >
                        Daily Average (Study)
                      </Typography>
                      {/* <FormControl sx={{ minWidth: 100 }}>
                        <Select
                          size="small"
                          value={usersChart}
                          onChange={handleChartChange}
                          sx={{
                            borderRadius: "12px",
                            borderColor: "transparent",
                            "& .MuiSelect-icon": {
                              color: "red",
                            },
                          }}
                        >
                          <MenuItem value={"study"}>Study</MenuItem>
                          <MenuItem value={"test"}>Test</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Stack>
                    <Typography
                      display={"flex"}
                      alignItems={"center"}
                      color={"#bbb"}
                      fontSize={{ sm: "10px", xs: "9px" }}
                    >
                      16% from last week
                    </Typography>
                  </Box>
                  <Typography
                    variant="h3"
                    fontSize={25}
                    textAlign={"right"}
                    fontWeight={"bold"}
                    sx={{ fontSize: { xs: "18px", sm: "25px" } }}
                  >
                    5h 45m
                  </Typography>
                </Stack>
                <Divider />
                <Stack>
                  <Typography
                    variant="subtitile1"
                    color={"#aaa"}
                    sx={{
                      textAlign: "right",
                      fontSize: { xs: "13px", sm: "16px" },
                    }}
                  >
                    Best Performing Subject
                  </Typography>
                  {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" />
                  ) : (
                    <Typography
                      variant="h3"
                      fontSize={25}
                      textAlign={"right"}
                      fontWeight={"bold"}
                      sx={{ fontSize: { xs: "18px", sm: "25px" } }}
                    >
                      {dashboardStat?.bestPerformingSubject ?? "None"}
                    </Typography>
                  )}
                </Stack>
                <Divider />
                <Stack>
                  <Typography
                    variant="subtitle1"
                    color={"#aaa"}
                    sx={{
                      textAlign: "right",
                      fontSize: { xs: "13px", sm: "16px" },
                    }}
                  >
                    Subject that needs work
                  </Typography>
                  {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" />
                  ) : (
                    <Typography
                      variant="h3"
                      fontSize={25}
                      textAlign={"right"}
                      fontWeight={"bold"}
                      sx={{ fontSize: { xs: "18px", sm: "25px" } }}
                    >
                      {dashboardStat?.poorPerformingSubject ?? "None"}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Box>

          <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={3}
          >
            <Box
              component={Paper}
              maxHeight={"auto"}
              elevation={0}
              p={2}
              width={{ xs: "100%", md: "60%" }}
              borderRadius={"14px"}
              sx={{
                border: "1px solid #E8E8E8",
                boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                pb={1}
                sx={{ fontSize: { xs: "14px", sm: "22px" } }}
              >
                Studies in Progress
              </Typography>

              <StudiesInProgress data={studiesInProgress} loading={loadingg} />
            </Box>
            <Box
              component={Paper}
              maxHeight={"auto"}
              elevation={0}
              width={{ xs: "100%", md: "40%" }}
              borderRadius={"14px"}
              p={2}
              sx={{
                border: "1px solid #E8E8E8",
                boxShadow: "0px -1px 15px -8px rgba(0, 0, 0, 0.25)",
              }}
            >
              <AveragePerformancePerSubject
                data={averageTest}
                loading={isLoadingg}
              />
            </Box>
          </Box>
        </Box>
        <AddCoreSubjectsDialog
          openAddCoreSubjectsDialog={openAddCoreSubjectsDialog}
          handleCloseCoreSubjectsDialog={handleCloseCoreSubjectsDialog}
        />
      </Container>
    </>
  );
}

export default TempDashboard;
