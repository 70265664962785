import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as Ellipse } from "../../assets/images/ellipse1.svg";
import premiumbg from "../../assets/images/premiumbg.png";
import packageBox from "../../assets/images/packageBox.png";
import { fontWeight } from "@mui/system";
import PremiumInfoDialog from "../Modal/PremiumInfoDialog";
import PremiumDialog from "../Modal/PremiumDialog";
import useUser from "../../hooks/useUser";

const SponsorBox = () => {
  const { userInfo } = useUser();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ padding: '0px', position: 'relative', width: '100%' }}>
      {/* <Box sx={{position:'absolute', zIndex:999, left: '0px', bottom:"127px"}}>
            <img src={packageBox} alt="Premium Package" />
        </Box> */}
      <Box
        sx={{
          // backgroundColor: "#5D5FEF",
          position: 'relative',
          backgroundSize: 'cover',
          backgroundImage: `url(${premiumbg})`,
          backgroundRepeat: 'no-repeat',
          borderRadius: "17px",
          mb: 3,
          boxSizing: "border-box",
          padding: '30px 20px',
          textAlign: "center",
        }}
      >

        <Box sx={{ position: 'absolute', zIndex: 99, bottom: 0, left: 0, width: '0px', height: 'auto' }}>
          <Ellipse />
        </Box>
        <Box
          sx={{
            display: "flex",
            //   justifyContent: "space-evenly",
            flexDirection: "column",
            gap: '5px',
            alignItems: "center",
            //   padding:'0px 10px'
          }}
        >
          <Avatar sx={{ width: '70px', height: "70px" }} alt="Sponsor" src={`${userInfo?.studentSponsorProfilePic ?? ""}`} />
          <Typography color="#FFFFFFCC" fontSize={'12px'}>
            sponsored by
          </Typography>
          <Box>
            <Typography color="white" fontWeight={800} fontSize={"18px"}>
              {userInfo?.studentSponsorTitle ?? 'Senator'}
            </Typography>
            <Typography color="white" mt={-1} fontWeight={800} fontSize={"18px"}>
              {userInfo?.studentSponsorName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>

  );
};

export default SponsorBox;
