// import React, { useState } from 'react';
// //import styled from 'styled-components/macro';
// import { styled } from "@mui/material/styles";
// import { Label } from './constants/CalculatorButtons';
// import { heightPx, widthPx } from './constants/dimensions';
// import Display from './Display';
// import BtnPanel from './BtnPanel';
// import handleInput from './logic/handleInput';

// const StyledCalculator = styled("div")({
//     height: (props) => `${props.heightPx}px`,
//     width: (props) => `${props.widthPx}px`,
//     backgroundColor: "#0b0c11",
//     padding: "5px",
//     display: "flex",
//     flexDirection: "column",
//     boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.5)",
//     borderRadius: "5px",
// });
// const Calculator = ({
//     // result,
//     // expression,
//     // error,
//     // handleClick,
// }) => {
//     const [calculatorInput, setCalculatorInput] = useState({
//         displayValue: "0",
//         expression: "",
//         isResult: false,
//         error: "",
//     });

//     const handleClick = (label) => {
//         setCalculatorInput((prevState) => handleInput(label, { ...prevState }));
//     };

//     return (
//         <StyledCalculator id="calculator" widthPx={widthPx} heightPx={heightPx}>
//             <Display result={calculatorInput?.displayValue ?? '0'} expression={calculatorInput?.expression} error={calculatorInput?.error} />
//             <BtnPanel handleClick={handleClick} />
//         </StyledCalculator>
//     )
// };

// export default Calculator;


import React, { useState } from 'react';
import CalculatorButton from './buttons/CalculatorButton';
// import { CalculatorButton } from '@/components/ui/CalculatorButton';
// import { Input } from '@/components/ui/input';

const ScientificCalculator = () => {
  const [display, setDisplay] = useState('');

  const handleClick = (value) => {
    setDisplay(display + value);
  };

  const calculateResult = () => {
    try {
      // Use a safer alternative to eval
      const result = Function(`'use strict'; return (${display})`)();
      setDisplay(result.toString());
    } catch (error) {
      setDisplay('Error');
    }
  };

  const clearDisplay = () => {
    setDisplay('');
  };

  const calculateSpecialFunction = (func) => {
    try {
      const x = parseFloat(display);
      let result;
      switch (func) {
        case 'sin':
          result = Math.sin(x);
          break;
        case 'cos':
          result = Math.cos(x);
          break;
        case 'tan':
          result = Math.tan(x);
          break;
        case 'log':
          result = Math.log10(x);
          break;
        case 'ln':
          result = Math.log(x);
          break;
        case 'sqrt':
          result = Math.sqrt(x);
          break;
        default:
          throw new Error('Invalid function');
      }
      setDisplay(result.toString());
    } catch (error) {
      setDisplay('Error');
    }
  };

  return (
    <div className="w-64 p-4 bg-gray-100 rounded-lg shadow-md">
      <input
        type="text"
        value={display}
        readOnly
        className="w-full mb-2 text-right flex h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50;
"
      />
      <div className="grid grid-cols-4 gap-2">
        {['7', '8', '9', '/'].map((btn) => (
          <CalculatorButton key={btn} onClick={() => handleClick(btn)}>{btn}</CalculatorButton>
        ))}
        {['4', '5', '6', '*'].map((btn) => (
          <CalculatorButton key={btn} onClick={() => handleClick(btn)}>{btn}</CalculatorButton>
        ))}
        {['1', '2', '3', '-'].map((btn) => (
          <CalculatorButton key={btn} onClick={() => handleClick(btn)}>{btn}</CalculatorButton>
        ))}
        {['0', '.', '=', '+'].map((btn) => (
          <CalculatorButton key={btn} onClick={btn === '=' ? calculateResult : () => handleClick(btn)}>{btn}</CalculatorButton>
        ))}
        <CalculatorButton onClick={() => handleClick('(')}>(</CalculatorButton>
        <CalculatorButton onClick={() => handleClick(')')}>)</CalculatorButton>
        <CalculatorButton onClick={() => handleClick('^')}>^</CalculatorButton>
        <CalculatorButton onClick={clearDisplay}>C</CalculatorButton>
        <CalculatorButton onClick={() => calculateSpecialFunction('sin')}>sin</CalculatorButton>
        <CalculatorButton onClick={() => calculateSpecialFunction('cos')}>cos</CalculatorButton>
        <CalculatorButton onClick={() => calculateSpecialFunction('tan')}>tan</CalculatorButton>
        <CalculatorButton onClick={() => calculateSpecialFunction('log')}>log</CalculatorButton>
        <CalculatorButton onClick={() => calculateSpecialFunction('ln')}>ln</CalculatorButton>
        <CalculatorButton onClick={() => calculateSpecialFunction('sqrt')}>√</CalculatorButton>
        <CalculatorButton onClick={() => handleClick('Math.PI')}>π</CalculatorButton>
        <CalculatorButton onClick={() => handleClick('Math.E')}>e</CalculatorButton>
      </div>
    </div>
  );
};

export default ScientificCalculator;