import React, { useState, useEffect } from "react";
import Chart from 'react-apexcharts';

const StudyChart = ({ adminDashboardChart, adminDashboardRegUsersChart }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        width: 600, // Specify the desired width
        height: 400, // Specify the desired height
      },
      plotOptions: {
        group: true,
        bar: {
          // borderRadius: 10,
          endingShape: 'roundedTop',
          columnWidth: '40%', // Specify width for the bars
          horizontal: false,
          barWidth: '60%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ""

      },
      chart: {
        zoom: {
          enabled: false,
          type: 'x',
          autoScaleYaxis: false,

        },
        toolbar: {
          // show: true,
          // offsetX: 1,
          offsetY: 10,
          tools: {
            download: true,
            selection: false,
            download: '<img src="/images/downLoadIcon.svg""  width="20">',
            // reset: true | '<img src="/assets/images/AllStudentsIcon.svg"  width="20">',
            // customIcons: [{
            //   icon: '<img src="/images/A+.png"  width="20">',
            //   // index: 4,
            //   title: 'tooltip of the icon',
            //   class: 'custom-icon',
            //   click: function (chart, options, e) {
            //     console.log("clicked custom-icon")
            //   }
            // }]
          },

        },
      },
      yaxis: {
        title: {
          text: ' ',
        },
      },
      legend: { position: 'top' },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          // formatter: function (val) {
          //   return "$ " + val + " thousands";
          // },
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              // height: "auto"
            },
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (adminDashboardRegUsersChart && adminDashboardRegUsersChart.registeredUsers) {
      const allYears = adminDashboardRegUsersChart.registeredUsers.map(data => data.month);
      const allmonths = allYears.map(month => month);

      const student = adminDashboardRegUsersChart.registeredUsers.map(data => data.students);
      const allStudent = student.map(students => students);

      const school = adminDashboardRegUsersChart.registeredUsers.map(data => data.schools);
      const allSchool = school.map(schools => schools);

      const sponsors = adminDashboardRegUsersChart.registeredUsers.map(data => data.sponsors);
      const allSponsors = sponsors.map(sponsors => sponsors);

      setChartData({
        series: [
          { name: 'Student', color: "#93aafd", data: allStudent },
          { name: 'School', color: "#4a3aff", data: allSchool },
          { name: 'Sponsor', color: "#e0c6fd", data: allSponsors },
        ],
        options: {
          ...chartData.options,
          xaxis: {
            categories: allmonths
          },
        },
      });
    }
  }, [adminDashboardRegUsersChart]);

  // Check if adminDashboardRegUsersChart is not yet available
  if (!adminDashboardRegUsersChart || !adminDashboardRegUsersChart.registeredUsers) {
    return <div>Loading...</div>;
  }

  return (
    <div id="chart">
      <Chart type='bar' series={chartData.series} options={chartData.options} />
    </div>
  );
};

export default StudyChart;

