import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, Paper, Stack } from "@mui/material";
import { DashBoardData } from "../dashboardData";
import { styled } from "@mui/material/styles";
import {
  BookDashIcon,
  ChemistryIcon,
  EducationBlocks,
  FinanceIcon,
  FreemiumIcon,
  TimeIcon,
} from "../../../assets/icons/Icons";
import educationblocks from "../../../assets/images/educationblocks.png";
import accountingadmin from "../../../assets/images/accountingadmin.png";
import hourglassusermet from "../../../assets/images/hourglassusermet.png";
import carbon_calculation from "../../../assets/images/carbon_calculation.png";
import timer from "../../../assets/images/timer.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserMetricsStat = (props) => {
  const UserMetricsData = [
    {
      icon: (
        <Box
          sx={{
            backgroundColor: "#E8EAFF",
            padding: 2,
            borderRadius: "9999px",
          }}
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
        >
          <ChemistryIcon sx={{ fontSize: { md: 34 }, color: "#8E8803" }} />
        </Box>
      ),
      name: props.userMetricsData?.lowestPassRateSubject,
      value: "Lowest Pass Rate Subject",
    },
    {
      icon: (
        <Box
          sx={{
            backgroundColor: "#FFF9D7",
            padding: 2,
            borderRadius: "9999px",
          }}
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
        >
          <img src={educationblocks} alt="educationblock" />
        </Box>
      ),
      name: props.userMetricsData?.highestPassRateSubject,
      value: "Highest Pass Rate Subject",
    },
    {
      icon: (
        <Box
          sx={{
            backgroundColor: "#F3EAFF",
            padding: 2,
            borderRadius: "9999px",
          }}
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
        >
          <img src={accountingadmin} alt="accountingadmin" />
        </Box>
      ),
      name: props.userMetricsData?.mostPracticedSubject,
      value: "Most Practiced Subject",
    },
    // {
    //   icon: (
    //     <Box sx={{ backgroundColor: "#FFEEEE", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
    //       <img src={hourglassusermet} alt="hourglassusermet" />
    //     </Box>
    //   ),
    //   name: "4h35m",
    //   value: "Time spent per session",
    // },
    {
      icon: (
        <Box
          sx={{
            backgroundColor: "#FFDEE1",
            padding: 2,
            borderRadius: "9999px",
          }}
          justifyContent={"center"}
          display={"flex"}
          alignItems={"center"}
        >
          <img src={carbon_calculation} alt="carbon_calculation" />
        </Box>
      ),
      name: props.userMetricsData?.mostTakenTest,
      value: "Most Taken Test",
    },
    // {
    //   icon: (
    //     <Box sx={{ backgroundColor: "#E8FCFF", padding: 2, borderRadius: "9999px" }} justifyContent={"center"} display={"flex"} alignItems={"center"}>
    //       <img src={timer} alt="timer" />
    //     </Box>
    //   ),
    //   name: "2:35 PM",
    //   value: "Most Active time",
    // },
  ];

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1 }}
        columns={12}
        sx={{ my: 2 }}
        alignItems={"stretch"}
      >
        {UserMetricsData.map((data, i) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={i}>
            <Box
              elevation={0}
              component={Paper}
              p={2}
              borderRadius={"12px"}
              border={"2px solid #eee"}
            >
              <Box
                display={"flex"}
                gap
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack justifyContent={"space-between"} direction={"column"}>
                  <Typography
                    fontSize={"14px"}
                    style={{ color: "#919191" }}
                    textAlign={"start"}
                  >
                    {data?.value}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontSize={"18px"}
                    textAlign={"start"}
                    fontWeight={"bold"}
                  >
                    {data?.name}
                  </Typography>
                </Stack>
                {data?.icon}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UserMetricsStat;
