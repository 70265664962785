import React, { useRef, useEffect } from "react";
import tickingbuzzer from "../../../assets/audios/tickingbuzzer.mp3";

function TickingSound({ audioRef, timeLeft, isSubmitted }) {
  useEffect(() => {
    const audioElement = audioRef.current;
    /*   console.log(isSubmitted,"here") */
    if (timeLeft < 8 && !isSubmitted) {
      if (audioElement) {
        audioElement.play();
      }
    } else {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  }, [timeLeft]);

  return (
    <div>
      {/* Hide the UI controls */}
      <audio ref={audioRef} controls={false}>
        <source src={tickingbuzzer} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default TickingSound;
