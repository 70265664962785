import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 8,
  borderRadius: 5,
  width: 170,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E5E5",
    boxShadow: "0px 2.18182px 2.18182px 0px rgba(0, 0, 0, 0.25) inset",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.brandSecondary.yellow
        : "#308fe8",
  },
}));

function LinearProgressWithLabel({ value }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="body2"
        fontWeight={"bold"}
        fontSize={"13px"}
        color="#000"
      >
        {`${Math.round(value)}%`}
      </Typography>
      <Box sx={{ ml: 1 }}>
        <BorderLinearProgress variant="determinate" value={value} />
      </Box>
    </Box>
  );
}

const weeklyMissions = [
  {
    mission: "Play this week trivia",
    value: 50,
  },
  {
    mission: "Complete 2 test examinations",
    value: 50,
  },
  {
    mission: "Spend 30 minutes studying",
    value: 20,
  },
  {
    mission: "Refer one friend to StudyHub",
    value: 100,
  },
];

const WeeklyMissionCard = () => {
  return (
    <div>
      <Paper sx={{ backgroundColor: "#40499D", p: 2, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1.5,
          }}
        >
          <Typography sx={{ color: "#fff" }}>Weekly Mission</Typography>
          <Button
            sx={{ color: "#58CC02", fontSize: "12px", fontWeight: "600" }}
          >
            View All
          </Button>
        </Box>

        {Array.isArray(weeklyMissions) && weeklyMissions.length > 0
          ? weeklyMissions.map((weeklyMission) => (
              <Paper sx={{ mb: 2, p: 1 }}>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{
                    fontSize: { xs: "13px", sm: "16px" },
                    color: "#1F1F1F",
                  }}
                >
                  {weeklyMission.mission}
                </Typography>
                <LinearProgressWithLabel value={weeklyMission.value} />
              </Paper>
            ))
          : ""}
        <Paper></Paper>
      </Paper>
    </div>
  );
};

export default WeeklyMissionCard;
