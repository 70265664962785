import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import ConfirmDialog from "../../../../Modal/ConfirmDialog";
import ConfirmPaymentStatus from "../../../../Payment/ConfirmPaymentStatus";
import { useDispatch } from "react-redux";
import { fetchSchoolSubscriptions } from "../../../../../redux/subscriptions";
import useUser from "../../../../../hooks/useUser";
import { formatDateString, formatIfMatch } from "../../../../../helpers/helper";

function createData(
  sn,
  Trans_Id,
  SubscriptionName,
  Licenses,
  Amount,
  trans_Date,
  expiryDate,
  PaymentStatus
) {
  return {
    sn,
    Trans_Id,
    SubscriptionName,
    Licenses,
    Amount,
    trans_Date,
    expiryDate,
    PaymentStatus,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& > .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  // "& .MuiDialogTitle-root": {
  //   backgroundColor: "#FFD14A",
  //   height: "1px",
  //   borderRadius: "16.9px 16.9px 0px 0px",
  //   color: "#fff",
  // },
  "& .MuiDialogContent-root": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
    position: "relative",
  },
}));

export default function SchoolPricingTable({ sub }) {
  const [billingHistory, setBillingHistory] = React.useState([]);
  const [openConfirmStatusDialog, setOpenConfirmStatusDialog] =
    React.useState(false);
  const [transactionId, setTransactionId] = React.useState(0);
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { schoolId } = userInfo;

  React.useEffect(() => {
    if (Array.isArray(sub?.allSubsData) && sub?.allSubsData?.length !== 0) {
      const rows = sub?.allSubsData?.map((item, i) =>
        createData(
          ++i,
          item?.Trans_Id,
          `${item?.SubscriptionName}`,
          `${item?.Licenses}`,
          `₦${item?.Amount}`,
          formatDateString(item?.trans_Date),
          moment(item?.expiryDate).format("MMM Do, YYYY"),
          `${item?.PaymentStatus}`
        )
      );
      setBillingHistory(rows);
    }
  }, [sub]);

  const handleActionClick = (transactionId) => {
    setTransactionId(transactionId);
  };

  const handleClose = () => {
    setOpenConfirmStatusDialog(false);
  };

  const dispatchActions = () => {
    dispatch(fetchSchoolSubscriptions(schoolId));
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Box p={2}>
          <Typography fontWeight={700} variant="h6">
            Billing History
          </Typography>
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 900 }}>S/N</TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                Transaction ID
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                Plan
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                No of Students{" "}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                Price
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                Transaction Date
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                Expiry Date
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                Status
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 900 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {billingHistory?.length !== 0 ? (
            <TableBody>
              {billingHistory?.map((row) => (
                <TableRow
                  key={row.sn}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.sn}
                  </TableCell>
                  <TableCell align="center">{row.Trans_Id}</TableCell>
                  <TableCell align="left">{row.SubscriptionName}</TableCell>
                  <TableCell align="center">{row.Licenses}</TableCell>
                  <TableCell align="center"> {row.Amount}</TableCell>
                  <TableCell align="center">{row.trans_Date}</TableCell>
                  <TableCell align="center">{row.expiryDate}</TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color:
                        row?.PaymentStatus.toLowerCase() === "approved"
                          ? "green"
                          : row?.PaymentStatus.toLowerCase() === "declined"
                          ? "red"
                          : "#ffd84d",
                    }}
                  >
                    {row.PaymentStatus.toLowerCase()}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color:
                        row?.PaymentStatus.toLowerCase() === "approved"
                          ? "green"
                          : "#ffd84d",
                    }}
                  >
                    {(row.PaymentStatus.toLowerCase() === "approved" || row.PaymentStatus.toLowerCase() === "declined") ? null : (
                      <Tooltip title="Query Payment">
                        <IconButton
                          color="primary"
                          aria-label="request"
                          onClick={() => {
                            setOpenConfirmStatusDialog(true);
                            handleActionClick(row.Trans_Id);
                          }}
                        >
                          <RefreshIcon sx={{ color: "#ffd84d" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody
              sx={{
                p: 3,
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <TableRow sx={{ width: "100%" }}>No History Available</TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirmStatusDialog}
        PaperProps={{ sx: { borderRadius: "18px", width: "35%" } }}
        maxWidth={"xl"}
      >
        <DialogContent>
          <ConfirmPaymentStatus
            TransId={transactionId}
            onClose={handleClose}
            actions={dispatchActions}
          />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#b4b4b4",
              m: 3,
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
