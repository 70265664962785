import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import { ArrowDropdownIcon } from '../../Assets/Icons/Icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox, Stack } from '@mui/material';
import CustomButton from '../../../buttons/CustomButton';
import AvatarWithText from '../../../Avatar/AvatarWithText';
import { ReactComponent as SubjectPack } from "../../../../assets/images/SubjectsPack.svg";

 
 
export default function AccordionExpand({ studentName, email, additionalSubjects, content, subjects }) {
 
  return (
    <Accordion elevation={0} sx={{mb:"10px", borderRadius: '12px',
    border: '1px solid rgba(27, 37, 89, 0.10)',
    background: '#FFF','&::before':{backgroundColor:'#fff', width:0}, '&.Mui-expanded':{border:'1px solid rgba(47, 84, 235, 0.40)'}}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"98%"}>
          <Box>
            <AvatarWithText text={studentName ?? ""} email={email ?? ""} />
       </Box>
  
       {/* <Box sx={{position:"relative"}}>
       <SubjectPack />
       <Typography sx={{position:"absolute", top: 4, right: 5, color:"#fff", fontSize:"13px"}}>{additionalSubjects}</Typography>
       </Box> */}
         <Box
                    sx={{
                      borderRadius: "50%",
                      bgcolor: "#EAECFF",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={"10px"}>
                    {content?.length > 0 ? content?.length : 0}
                    </Typography>
                  </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <ul style={{listStyleType: "disc", fontSize:"12px", marginLeft:"20px"}}>
        {content && content?.map((subject, index)=> (
          <li key={index} style={{}}>
          {subject?.subjectName} 
          </li>
        ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}