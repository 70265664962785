import React, { useState, useEffect } from "react";
import { Grid, Paper, Box, Button, Typography, Chip } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import useLearning from "../../../../hooks/useLearning";
import PracticeChoices from "./practiceChoices";
import practicetestmark from "../../../../assets/images/practicetestmark.png";
import Erick from "../../../../assets/images/Erick.png";
import congratulationsPointBadge from "../../../../assets/images/congratulationsPointBadge.gif";
import WrongIcon from "../../../../assets/images/WrongIcon.png";
import { convertAnswerToAlphabet } from "../../../../helpers/helper";
import Stack from "@mui/material/Stack";
import { PointsEarnIcon } from "../../../../assets/icons/Icons";
import useUser from "../../../../hooks/useUser";
import {
  fetchPracticeQuestionsByQuestionId,
  fetchSubmitPoints,
} from "../../../../redux/learning";
import { useNotifications } from "../../../../components/notifications/notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import KatexRenderer from "../../../../components/KatexRenderer";
import { useGoogleAnalytics } from "../../../../hooks/usePageTracking";
import { DataUsage } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function PracticeQuestion(props) {
  const learning = useLearning();
  const { examInfo, examPractQuestionId } = props;
  const { trackSpecificEvent } = useGoogleAnalytics();
  const { userInfo } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [ToastState, setToastState] = useNotifications();
  const [open, setOpen] = React.useState(false);
  const {
    practiceQuestions,
    practiceQuestionCount,
    loadingQuestion,
    loadPracticeQuestion,
  } = learning;
  const [selectedOption, setSelectedOption] = useState("");
  const [openFinishPracticeModal, setOpenFinishPracticeModal] = useState(false);
  // const [openFirstPointModal, setOpenFirstPointModal] = useState(false);
  const [earnedPoints, setEarnedPoints] = useState(0);
  // const [openPracticeAnswer, setOpenPracticeAnswer] = useState(false);

  // Function to handle a correct answer
  const handleCorrectAnswer = () => {
    // Increment the score by 1 when the answer is correct
    if (
      practiceQuestions?.data.answer?.replace(/\s/g, "") ===
      selectedOption?.replace(/\s/g, "")
    ) {
      setEarnedPoints(earnedPoints + 1);
    } else {
      ("");
    }
  };

  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: res.msg,
    });
    setTimeout(() => {
      // navigate('/dashboard/home')
    }, 2000);
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
  };

  const on500Fail = (error) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: error?.statusText,
    });
  };

  const handleShowNextQuestion = () => {
    setOpen(false);
    setSelectedOption("");
    if (props.maxCountPractice === props.selectedPracticeQuestionIndex) {
      handleCorrectAnswer();
      setOpenFinishPracticeModal(true);
    } else {
      handleCorrectAnswer();
      props.getPracticeQuestion();
    }
  };

  const handleSubmitPoints = () => {
    //
    dispatch(
      fetchSubmitPoints(
        { points: earnedPoints, profileId: userInfo?.profileId },
        onSuccess,
        onFail,
        on500Fail
      )
    );
    trackSpecificEvent("practice_question_completed", {
      points_earned: earnedPoints,
      subject_name: examInfo?.subjectName,
      exam_name: examInfo.examName,
    });
    navigate("/dashboard/home");
  };

  useEffect(() => {
    setSelectedOption("");
    // props.getPracticeQuestion();
  }, [practiceQuestionCount]);

  // useEffect(() => {
  //   if (earnedPoints === 1) {
  //     setOpenFirstPointModal(true);
  //   }
  // }, [earnedPoints]);

  // const handleClose = () => {
  //   setOpenFirstPointModal(false);
  // };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const practiceButton = {
    borderRadius: "8px",
    border: "1px solid #013228",
    // background: "#05765F",
    boxShadow: "0px 4px 20px 0px rgba(45, 45, 45, 0.25)",
    color: "#FFF",
    // marginLeft: "20em",
  };

  const boxWidthStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& > :not(style)": {
      m: 1,
      minWidth: 900,
      // height: 200,
    },
    [theme.breakpoints.down("md")]: {
      "& > :not(style)": {
        m: 1,
        minWidth: 600,
        // height: 200,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& > :not(style)": {
        m: 1,
        minWidth: 200,
        // height: 200,
      },
    },
  };

  const handleCheck = () => {
    setOpen(!open);
  };

  return (
    <>
      <Stack spacing={3} alignItems="end" paddingX={5} marginTop={-3}>
        <Stack direction="row" spacing={1}>
          <Chip
            label={`Question ${props.selectedPracticeQuestionIndex + 1}  of ${
              practiceQuestionCount?.data.testQuestion.length
            }`}
            color="primary"
          />
          <Chip
            icon={<PointsEarnIcon />}
            pt={2}
            size="small"
            label={earnedPoints}
            style={{ backgroundColor: "transparent", color: "#E26500" }}
          />
        </Stack>
      </Stack>
      <Grid sx={boxWidthStyle}>
        <Paper elevation={0} sx={{ px: 6, py: 4, borderRadius: 3 }}>
          {loadPracticeQuestion ? (
            <div className="w-full flex justify-center items-center">
              <DataUsage
                htmlColor="#40499D"
                size={24}
                className="animate-spin"
              />
            </div>
          ) : (
            <div className="flex flex-col">
              {window.HTMLReactParser(
                practiceQuestions?.data.sectionInstruction || ""
              ) ? (
                <Paper
                  sx={{
                    mt: 2,
                    p: 2,
                    border: 1,
                    borderColor: "primary.main",
                    display: practiceQuestions?.data.sectionInstruction
                      ? "block"
                      : "none",
                  }}
                >
                  <Typography fontWeight={"bolder"} color={"#111"} variant="h6">
                    Instruction
                  </Typography>
                  <KatexRenderer
                    expression={window.HTMLReactParser(
                      practiceQuestions?.data.sectionInstruction || ""
                    )}
                  />
                  {/* {window.HTMLReactParser(examQuestion?.question || "")} */}
                </Paper>
              ) : (
                block
              )}
              <>
                <p>
                  <Typography
                    fontWeight={"bolder"}
                    color={"#111"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "10",
                      marginRight: "10px",
                      marginTop: "15px",
                      padding: "5px 0",
                    }}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{"  "}
                    {/* Adding a space here */}
                    <KatexRenderer
                      expression={window.HTMLReactParser(
                        practiceQuestions?.data.question || ""
                      )}
                    />
                  </Typography>
                </p>
              </>
              <PracticeChoices
                practiceQuestions={practiceQuestions}
                open={open}
                handleOptionChange={handleOptionChange}
                selectedOption={selectedOption}
              />

              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: 1.5,
                  mb: 2,
                }}
              >
                <Button
                  disabled={selectedOption === "" ? true : open}
                  variant="contained"
                  // sx={{ mr: 4 }}
                  onClick={() => {
                    handleCheck();
                  }}
                >
                  Check Solution
                </Button>
              </Grid>
            </div>
          )}
        </Paper>
      </Grid>
      <Grid>
        <Box>
          {open === true ? (
            <Grid
              container
              spacing={2}
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                backgroundColor:
                  practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                  selectedOption?.replace(/\s/g, "")
                    ? "#FFCCCC"
                    : "#DBFFF2",
                height: "20vh",
                p: "10ox 40px",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "1em",
                }}
              >
                {/* <Item>xs=6 md=8</Item> */}
                {practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                selectedOption?.replace(/\s/g, "") ? (
                  <img
                    src={WrongIcon}
                    alt="Wrong"
                    style={{ height: 40, width: 40 }}
                  />
                ) : practiceQuestions?.data.answer?.replace(/\s/g, "") ===
                  selectedOption?.replace(/\s/g, "") ? (
                  <img
                    src={practicetestmark}
                    alt="Correct"
                    style={{ height: 40, width: 40 }}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={10} sm={8} md={4}>
                {/* <Item>xs=6 md=4</Item> */}
                {practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                selectedOption?.replace(/\s/g, "") ? (
                  <Grid sx={{ ml: 4 }}>
                    <Typography sx={{ fontWeight: "bold", color: "#F92F60" }}>
                      That's Wrong
                    </Typography>
                    <Typography style={{ color: "#000" }}>
                      The correct answer is{" "}
                      {convertAnswerToAlphabet(
                        practiceQuestions?.data.answer?.replace(/\s/g, "")
                      )}
                    </Typography>
                  </Grid>
                ) : (
                  <Typography sx={{ ml: 4 }}>Good Job!</Typography>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={4}>
                <Grid
                  sx={{
                    marginBottom: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    sx={practiceButton}
                    endIcon={<ArrowForwardIcon />}
                    style={{
                      background:
                        practiceQuestions?.data.answer?.replace(/\s/g, "") !==
                        selectedOption?.replace(/\s/g, "")
                          ? "#F92F60"
                          : "#05765F",
                    }}
                    onClick={() => {
                      handleShowNextQuestion();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Box>
      </Grid>

      <Dialog
        open={openFinishPracticeModal}
        PaperProps={{
          style: {
            backgroundColor: "#40499D",
          },
        }}
      >
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          component={DialogContent}
        >
          <img
            src={Erick}
            alt="Image"
            style={{
              height: 150,
              width: 150,
              display: "block", // Change display to "block"
              margin: "0 auto",
              marginTop: "1rem",
            }}
          />
          <Typography
            class="text-3xl font-extrabold ..."
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Congratulations! You Finished Your Study Practice!
            {/* Let's test your knowledge with 5 practice */}
          </Typography>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2em",
            }}
          >
            <Button
              variant="outlined"
              style={{
                display: "flex",
                display: "block", // Change display to "block"
                margin: "0 auto",
                color: "white",
                backgroundColor: "#deab28",
              }}
              onClick={handleSubmitPoints}
            >
              {/* You have earned {earnedPoints} Point
              <br></br> */}
              Click to go to dashbaord
            </Button>
          </Grid>
        </Box>
      </Dialog>

      {/* {earnedPoints === 1 ? (
        <Dialog
          open={openFirstPointModal}
          PaperProps={{
            style: {
              backgroundColor: "#FFF",
            },
          }}
        >
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            component={DialogContent}
          >
            <img
              src={congratulationsPointBadge}
              alt="congratulationsPointBadge"
              style={{
                height: 150,
                width: 150,
                display: "block", // Change display to "block"
                margin: "0 auto",
                marginTop: "1rem",
              }}
            />
            <Typography
              class="text-3xl font-extrabold ..."
              style={{
                color: "primary",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              You earn 1 point for every correct answer!
            </Typography>
            <Typography
              style={{
                color: "#919191",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "1em",
              }}
            >
              Stay focused, each correct answer brings you one step closer to
              victory!
            </Typography>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2em",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                style={{
                  display: "flex",
                  display: "block", // Change display to "block"
                  margin: "0 auto",
                  color: "white",
                  borderRadius: "10px",
                  borderBottom: "3px solid #2D3684",
                  background: "var(--Primary, #40499D)",
                }}
                onClick={handleClose}
              >
                Keep going
              </Button>
            </Grid>
          </Box>
        </Dialog>
      ) : (
        ""
      )} */}
      <ToastState />
    </>
  );
}
export default PracticeQuestion;
