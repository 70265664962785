export const GET_LEADERBOARD_LOADING = "GET_LEADERBOARD_LOADING";
export const GET_LEADERBOARD_SUCCESS = "GET_LEADERBOARD_SUCCESS";
export const GET_LEADERBOARD_FAIL = "GET_LEADERBOARD_FAIL";
export const GET_TRIVIA_POINTS = "GET_TRIVIA_POINTS";
export const GET_TRIVIA_POINTS_FAIL = "GET_TRIVIA_POINTS_FAIL";
export const GET_USER_POINT_DIAMOND_LOADING = "GET_USER_POINT_DIAMOND_LOADING";
export const GET_USER_POINT_DIAMOND = "GET_USER_POINT_DIAMOND";
export const GET_USER_POINT_DIAMOND_FAIL = "GET_USER_POINT_DIAMOND_FAIL";
export const USE_USER_DIAMOND_LOADING = "USE_USER_DIAMOND_LOADING";
export const USE_USER_DIAMOND = "USE_USER_DIAMOND";
export const USE_USER_DIAMOND_FAIL = "USE_USER_DIAMOND_FAIL";
