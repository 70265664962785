import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DialogActions, Divider, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import ReminderWoman from "../../assets/images/ReminderWoman.png"
import CloseIcon from "@mui/icons-material/Close";

const AdvicePackDialog = ({ open, onClose, showImage }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const hubTasksStatus = {
    'Try to study for not less than 2 hours a day': true,
    'Maintain daily, weekly and monthly study and test streaks ': false,
    'Take tests regularly and try to time yourself': true,
    'Join study groups': true,
    'Never miss weekly trivias': false,
    "Get consistent A's in your tests ": true,
    'Never miss weekly missions': false,
  };

  const userTasks = [
    'Eat and exercise well',
  ];

  // Initialize userTasksStatus with hubTasksStatus and default values (unchecked)
  const [userTasksStatus, setUserTasksStatus] = useState({ ...hubTasksStatus });

  useEffect(() => {
    // Initialize userTasksStatus with default values (unchecked) for tasks not present in hubTasksStatus
    const initialUserTasksStatus = {};
    for (const task of userTasks) {
      initialUserTasksStatus[task] = userTasksStatus[task] || false;
    }
    setUserTasksStatus(initialUserTasksStatus);
  }, []);

  // Function to toggle task completion
  const toggleTaskCompletion = (task) => {
    setUserTasksStatus((prevUserTasksStatus) => ({
      ...prevUserTasksStatus,
      [task]: !prevUserTasksStatus[task],
    }));
  };


  const handleClose = () => {
    const mergedTasksStatus = { ...hubTasksStatus, ...userTasksStatus };
    // onSave(mergedTasksStatus);
    onClose();
  };

  const totalTasks = Object.keys(hubTasksStatus).length + userTasks.length;
  const completedTasks = Object.values(hubTasksStatus).filter((completed) => completed).length +
    Object.values(userTasksStatus).filter((completed) => completed).length;

  return (
    <>
      <Dialog
        fullWidth={true}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='advice-pack-dialog-title'
        PaperProps={{
          style: {
            borderTop: "10px solid #40499D",
            borderRadius: "10px",
            position: 'relative',
          },
        }}
      >
        <DialogTitle
          style={{
            fontWeight: 600,
            fontSize: "24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          A1 Parallel Hack
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </DialogTitle>
        <Divider />

        <DialogContent>
    
          {Object.entries(hubTasksStatus).map(([task, completed], index) => (
            <div key={index} >
             <Tooltip title={completed ? "Task completed" : "Task not completed"} 
             placement="right" 
             componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: completed ? "brandPrimary.main" : "#FBC946" ,
                },
              },
            }}
             >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={completed}
                    disabled={true}
                    sx={{
                      "&.Mui-checked": {
                        color: 'brandPrimary.main', 
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    style={{
                      textDecoration: completed ? 'line-through' : 'none',
                      color: completed ? 'gray' : 'inherit',
                    }}
                  >
                    {task}
                  </Typography>
                }
              />
</Tooltip>
            </div>
          ))}

          {userTasks.map((task, index) => (
            <div key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userTasksStatus[task]}
                    onChange={() => toggleTaskCompletion(task)}
                  />
                }
                label={
                  <Typography
                    style={{
                      textDecoration: userTasksStatus[task] ? 'line-through' : 'none',
                      color: userTasksStatus[task] ? 'gray' : 'inherit',
                    }}
                  >
                    {task}
                  </Typography>
                }
              />
            </div>
          ))}
        </DialogContent>
        <Divider />
        <DialogActions style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography sx={{ color: "#888", fontSize: "14px", padding: "10px 15px" }}>
            Completed {completedTasks} out of {totalTasks}
          </Typography>
        </DialogActions>
      </Dialog>
      {/* {showImage && (
        <img
          src={ReminderWoman}
          alt="Reminder Woman"
          style={{
            top: '150px',
            left: '266px',
            position: 'absolute',
            zIndex: 9999,
          }}
        />
      )} */}
    </>
  );
};

export default AdvicePackDialog;





