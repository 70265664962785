import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleDown from "@mui/icons-material/ArrowCircleDown";
import { ArrowCircleUp } from "@mui/icons-material";

const PrivacyModal = ({ open, handleClose, accepted, setAccepted }) => {
  const acceptRef = useRef();
  const topRef = useRef();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80%",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        className="relative"
      >
        <Box className="flex justify-between" ref={topRef} id="to">
          <Typography variant="h6" id="terms-and-conditions-modal">
            {" "}
            Terms and Conditions
          </Typography>
          <Button
            variant="text"
            onClick={() => {
              acceptRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Scroll Down
            <ArrowCircleDown fontSize="medium" />
          </Button>
        </Box>

        <Box
          fontFamily={"Montserrat"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
        >
          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">WAEC DATA PRIVACY POLICY</h1>
            <p className="text-base pb-2">
              This Privacy Policy between The WEST AFRICAN EXAMINATIONS COUNCIL
              of 21 Hussey Street, Lagos (hereinafter referred to as WAEC) and
              you, constitutes our commitment to your privacy on our
              administrative records, websites, social media platforms and
              premises.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              1.0 YOUR RIGHT TO PRIVACY
            </h1>
            <p className="text-base pb-2">
              Your privacy is important to you and to WAEC. Therefore, we will
              protect the information you share with us. To protect your
              privacy, WAEC follows different principles in accordance with
              applicable data protection laws and regulations in relation to the
              handling of Personal Data. This Privacy Policy describes your
              privacy rights regarding our collection, use, storage, sharing and
              protection of your personal information. It applies to the WAEC
              website and all database applications, services, tools and
              physical contact with us regardless of how you access or use them.
            </p>
            <p className="text-base pb-2">
              If you have created a username, identification code, password or
              any other piece of information as part of our access security
              measures, you must treat such information as confidential, and you
              must not disclose it to any third party. We reserve the right to
              disable any user identification code or password, whether chosen
              by you or allocated by us, at any time, if in our opinion you have
              failed to comply with any of the provisions of these Conditions.
              If you know or suspect that anyone other than you know your
              security details, you must promptly notify us at
              hnowaeclagos@yahoo.co.uk, hnowaeclagos@waec.org.ng.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">2.0 Consent</h1>
            <p className="text-base pb-2">
              You accept this Privacy Policy when you sign up for, access, or
              use the WAEC products and services offered on this platform and
              all related sites and applications or visit any of our offices for
              official or non-official purposes ((collectively referred to as
              “Services”)
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              3.0 Changes to our Privacy Policy
            </h1>
            <p className="text-base pb-2">
              We reserve the right to modify or amend this Privacy Policy at any
              time. All changes to this Privacy Policy will be effective
              immediately upon its posting on this website, portals and
              softwares. Ensure you check this Policy regularly to stay updated
              with any amendment
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              4.0 Information We May Collect When you Use this Platform
            </h1>
            <p className="text-base pb-2">
              We collect personally identifiable information such as
            </p>
            <ol>
              <li>age</li>
              <li>gender</li>
              <li>date of birth, and</li>
              <li>
                any other information you choose to provide us, subject to
                applicable laws and the terms of this Privacy Policy.
              </li>
            </ol>
            <p className="text-base pb-2">
              We may use third party service providers to assist us in
              collecting and maintaining this personally identifiable
              information. However, we require such service providers to
              maintain the confidentiality of such information.
            </p>
            <p className="text-base pb-2">
              We also collect non- personally identifiable information such as:
            </p>
            <ol>
              <li>IP address</li>
              <li>cookies and web beacons</li>
              <li>
                information regarding the type of browser and operating system
                that you use, along with other similar analytic data.Your "IP
                address" is usually associated with the network location and
                physical location from which you enter the Internet.
              </li>
            </ol>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              5.0 How do we collect your data?
            </h1>
            <p className="text-base pb-2">
              We collect data and process data when you:
            </p>
            <p className="text-base pb-2">
              Register online for any of our examination or place an order for
              any of our products or services.
            </p>
            <p className="text-base pb-2">
              Voluntarily complete a customer survey or provide feedback on any
              of our message boards or via email.
            </p>
            <p className="text-base pb-2">
              Use or view our websites or portals via your browser and the
              offline registration application (offline module).
            </p>
            <p className="text-base pb-2">
              Indirect sources of data collection include: JAMB registration and
              data collected from third parties on whose behalf we conduct
              aptitude test for their staff/candidates.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              6.0 Your data protection rights
            </h1>
            <p>Every user is entitled to the following:</p>
            <ol>
              <li>
                The right to access: You have the right to request from the
                council for copies of your personal data. We may charge you a
                small fee for this service.
              </li>
              <li>
                The right to erasure – You have the right to request that the
                Council erase your personal data, under certain conditions
              </li>
              <li>
                The right to restrict processing – You have the right to request
                that the Council restrict the processing of your personal data,
                under certain conditions.
              </li>
              <li>
                The right to data portability – You have the right to request
                that the Council transfer the data that we have collected to
                another organization, or directly to you, under certain
                conditions.
              </li>
            </ol>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              7.0 How We Use the Information We Collec
            </h1>
            <p className="text-base pb-2">
              We use your information as supplied which may be personally
              identifiable and non-personally identifiable in accordance with
              the extant governing principles for data processing according to
              the data protection regulations in force.
            </p>
            <p className="text-base pb-2">
              We may use it to uniquely identify you to enable us provide the
              services for which you provided the data. We may also disclose
              same to third party suppliers and service providers specifically
              involved in the processing of your information. We may further use
              it in a non-identifiable way in order to better understand the
              services provided, how to improve these services and also improve
              the platform.
            </p>
            <p className="text-base pb-2">
              The information may also be used to review the status or activity
              of multiple users to resolve disputes, investigate problems,
              resolve problems/data breaches and enforce the terms of use or
              comply with extant regulations.
            </p>
            <p className="text-base pb-2">
              We may also use information provided by third parties like social
              media sites. Information about you provided by other sites are not
              controlled by WAEC and we are therefore not liable for how they
              use it.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              8.0 Handling of Electronic Records and Backup
            </h1>
            <p className="text-base pb-2">
              All data received are encrypted by SSL (secure socket layers) to
              help protect them during transmission and such data are stored on
              our servers located in various secured locations.
            </p>
            <p className="text-base pb-2">
              We ensure that the servers are protected with the best security
              practices and technologies. Backup data containing your records
              are always encrypted. However, we will delete any personally
              identifiable information in our database upon your request or as
              otherwise required by law. We may, however, retain non-personally
              identifiable information indefinitely.
            </p>
            <p className="text-base pb-2">
              We maintain backup files as a protection against natural
              disasters, equipment failures, or other disruptions. Backup files
              protect you and us because they lower the risk of losing valuable
              data. Backup files may contain records with your personal
              information. Removing a record from our active files and databases
              does not remove that record from any backup systems. Such backup
              data will eventually be passively deleted as backup records are
              erased through the normal recycling of backup files. In the
              meantime, as long as backup records exist, they receive the same
              security protections as our other records
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">9.0 Cookies</h1>
            <p className="text-base pb-2">
              The Council uses cookies for session management and
              personalization, we typically use session cookies and persistent
              cookies. You may elect to manage your cookies by setting your
              browser not to accept cookies or delete them from your computer.
              However, some of our website’s features may not function as
              intended on browsers that do not accept cookies.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">10.0 Marketing</h1>
            <p className="text-base pb-2">
              The Council would like to send you information about our products
              and services including that of service providers. If you have
              agreed to receive marketing information, you may always opt out
              later. You have the right at any time to stop the Council from
              contacting you for marketing purposes or giving your data to other
              members of the Council.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">11.0 Security</h1>
            <p className="text-base pb-2">
              Communications between your browser and portions of the WAEC
              Integrated platform containing personally identifiable information
              are protected with Secure Socket Layer (SSL) encryption. This
              encryption is to help protect your information while it is being
              transmitted. Once we receive your information, we strive to
              maintain the physical and electronic security of your personal
              information using commercially reasonable efforts.
            </p>
            <p>
              No data transmission over the internet or any wireless network can
              be guaranteed to be perfectly secured. As a result, while we
              strive to protect your personal information using commercially
              available and standard encryption technology, we cannot ensure or
              guarantee the security of any information you transmit to us, and
              you do so at your own risk
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              {" "}
              12.0 In the Event of a Security Breach of Your Personal
              Informationt
            </h1>
            <p className="text-base pb-2">
              If we determine that, your personal information has or may
              reasonably have been disclosed due to a security breach of our
              systems, we will notify you, at the contact information provided
              to us, in accordance with and to the extent required by applicable
              state and federal law
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              13.0 Data Confidentiality Rights
            </h1>
            <p className="text-base pb-2">
              Your information is regarded as confidential and will not be
              divulged to any third party except under legal and/or regulatory
              conditions. You have the right to request sight of, and copies of
              any and all information we keep on you, if such requests are made
              in compliance with the Freedom of Information Act and other
              relevant enactments. While NITDA is responsible for safeguarding
              the information entrusted to us, your role in fulfilling
              confidentiality duties includes, but is not limited to, adopting
              and enforcing appropriate security measures such as non-sharing of
              passwords and other platform login details, adherence with
              physical security protocols on our premises, dealing with only
              authorized officers of the Agency.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              14.0 Third Party Privacy Practices
            </h1>
            <p className="text-base pb-2">
              During your interaction with our platform, we may provide other
              governmental bodies and third parties, with personal information
              such as your name, contact details, or other details submitted on
              our platform in compliance with statutory duties to you or third
              parties.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">
              15.0 Links to Other Websites
            </h1>
            <p className="text-base pb-2">
              Certain transaction processing channels may require links to other
              websites. Please note that WAEC is not responsible for and has no
              control over such third-party websites. We do not monitor or
              review the content of other party’s websites, which are linked
              from this platform. Opinions expressed or materials appearing on
              such websites are not necessarily shared or endorsed by us, and
              WAEC should not be regarded as the publisher of such opinions or
              materials. Please be aware that we are not responsible for the
              privacy practices, or content of any third-party sites and we
              encourage our users to read the privacy statements of these sites.
              You should evaluate the security and trustworthiness of any other
              site connected to this platform or accessed through this platform
              yourself, before disclosing any personal information to it. WAEC
              will not accept any responsibility for any loss or damage in
              whatever manner, howsoever caused, resulting from your disclosure
              to third parties of personal information.
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">16.0 Contact Information</h1>
            <p className="text-base pb-2">
              If you have any questions or concerns about the Privacy Policy or
              privacy practice, please contact us at: To contact our Data
              Protection Officer, kindly address your request to “The Data
              Protection Officer” Email us at: hnowaeclagos@yahoo.co.uk,
              hnowaeclagos@waec.org.ng Call us:+234-811891883, 811918840 Or
              write us at: The West African Examinations Council, 21 Hussey
              Street, Yaba, Lagos, P.M.B. 1022, Yaba, Lagos
            </p>
          </Box>

          <Box className="pb-8">
            <h1 className="text-lg font-bold pb-4">17.0 Contact Information</h1>
            <p className="text-base pb-2">
              This Privacy Policy is made pursuant to the Nigeria Data
              Protection Regulation (2019) and other relevant Nigerian laws,
              regulations or international conventions applicable to Nigeria.
              Where any provision of this Policy is deemed inconsistent with a
              law, regulation or convention, such provision shall be subject to
              the overriding law, regulation or convention.
            </p>
          </Box>
        </Box>

        <Box ref={acceptRef} id="accept" className="flex justify-between">
          <Button
            variant="contained"
            className="fixed bottom-0"
            onClick={() => {
              setAccepted(true);
              handleClose();
            }}
          >
            Accept
          </Button>

          <Button
            variant="text"
            onClick={() => {
              topRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <ArrowCircleUp fontSize="large" />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrivacyModal;
