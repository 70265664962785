import CloseIcon from "@mui/icons-material/Close";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import avatar2 from "../../assets/images/avatar2.png";
import useUser from "../../hooks/useUser";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),

    borderRadius: "30px",
  },

  "& .MuiDialogActions-root": {
    paddingBottom: theme.spacing(4),

    paddingRight: theme.spacing(4),
  },

  "&.Mui-Paper": {
    border: `1px solid ${theme.palette.brandPrimary.main}`,
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",

            right: 8,

            top: 8,

            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,

  onClose: PropTypes.func.isRequired,
};



export default function TestScoreModal({ open, handleClose, examResult }) {
  const { userInfo: { firstName } } = useUser()

  const remarks = [
    {
      minPercentage: 0,
      maxPercentage: 40,
      remark: <><img
        src={avatar2}
        alt="Avatar"
        style={{ width: "auto", height: "180px" }}
      /><Typography>
          Sorry {firstName}! <span style={{ fontWeight: 500 }}>{examResult.totalScore}/{examResult?.examResults?.length}</span> is a poor score. Keep studying hard. Better luck next time!
        </Typography></>
    },
    {
      minPercentage: 41,
      maxPercentage: 60,
      remark: <><img
        src={avatar2}
        alt="Avatar"
        style={{ width: "auto", height: "180px" }}
      /><Typography>
          Hey {firstName}! <span style={{ fontWeight: 500 }}>{examResult.totalScore}/{examResult?.examResults?.length}</span> is not too bad, it shows you have been studying but I was expecting much better from you. You do not earn a badge for this assessment. I only give badges to those with outstanding scores. Checkout the questions you failed and the answers. Do better next time!
        </Typography></>
    },
    {
      minPercentage: 61,
      maxPercentage: 80,
      remark: <><img
        src={avatar2}
        alt="Avatar"
        style={{ width: "auto", height: "180px" }}
      /><Typography>
          Good job {firstName}! <span style={{ fontWeight: 500 }}>{examResult.totalScore}/{examResult?.examResults?.length}</span> is a good score and this earns you a badge. I only give badges to those with outstanding scores and you're one of them. Keep up the good work!
        </Typography></>
    },
    {
      minPercentage: 81,
      maxPercentage: 100,
      remark: <><img
        src={avatar2}
        alt="Avatar"
        style={{ width: "auto", height: "180px" }}
      /><Typography>
          Bravo {firstName}! <span style={{ fontWeight: 500 }}>{examResult.totalScore}/{examResult?.examResults?.length}</span> is a great score and for this You get a badge for this assessment. I only give badges to those with outstanding scores and you're one of them. Keep up the good work!
        </Typography></>
    }
  ];

  const calculateRemark = (userTotalScore) => {
    const totalScore = examResult?.examResults?.length;
    const userPercentage = (userTotalScore / totalScore) * 100;

    for (const { minPercentage, maxPercentage, remark } of remarks) {
      if (userPercentage >= minPercentage && userPercentage <= maxPercentage) {
        return remark;
      }
    }
    return <CircularProgress sx={{ mx: "auto" }} color="brandPrimary" size={34} className="animate-spin ml-4" />;
  }


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            border: "2px solid #40499D",
            borderRadius: "30px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        />

        <DialogContent>
          <Box display={"flex"} alignItems={"center"}>
            {calculateRemark(examResult.totalScore)}
          </Box>
        </DialogContent>

        <DialogActions>
          {/* <Link to="/test"> */}
          <Button
            color="brandPrimary"
            variant="contained"
            autoFocus
            onClick={handleClose}
            sx={{ textTransform: "capitalize" }}
          >
            Continue
          </Button>
          {/* </Link> */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
