import React, { useEffect, useRef, useState } from 'react';
import {
  Grid, Typography, Box, Tabs, Tab, OutlinedInput,
  InputAdornment, IconButton, TableContainer, Table, TableBody,
  TableRow, TableCell, Checkbox, TablePagination, Menu, MenuItem,
  Toolbar, TableHead, TableSortLabel, Button, Card, CardHeader, Dialog, CardContent, ListItem, FormControl,
  MenuList, ListItemIcon, ListItemText, DialogTitle, TextField, DialogContent, Paper, Step, Stepper,
  StepButton, Switch, FormControlLabel, FormGroup
} from '@mui/material';
import { DataUsage, KeyboardBackspace } from '@mui/icons-material';
import { arrayEquals } from '../../../helpers/helper';
import { styled } from "@mui/material/styles";
import { useDispatch } from 'react-redux';
import usePermissions from '../../../hooks/usePermissions';
import { addRole, getAllAdminRoles, updateRole, updateRolePermissions } from '../../../redux/permissions/permissionsActions';
import useAdminUser from '../../../hooks/useAdminUser';
import { useNotifications } from '../../../components/notifications/notifications';


const steps = ['Basic Info', 'Set permissions'];

const pages = [
  {
    name: 'Dashboard',
    id: 1
  },
  {
    name: 'User Metrics',
    id: 2
  },
  {
    name: 'Content Upload',
    id: 3
  },
  {
    name: 'Transaction Logs',
    id: 4
  },
  {
    name: 'Permissions',
    id: 5
  },
  {
    name: 'Audit Trails',
    id: 6
  },
  {
    name: 'Manage Amount',
    id: 7
  },
  {
    name: 'Manage Session',
    id: 8
  },

]

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      left: '6px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#40499d',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: 9,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const Step1 = ({ setRoleName, setRoleDescription, roleName, roleDescription }) => {
  return (
    <div className='mt-5 md:w-2/3 lg:w-1/2 mx-auto'>
      <Card>

        <CardContent>

          <h3>Basic Details</h3>
          <h4 className='mt-2'>Add basic information about this role you are creating</h4>

          <hr />


          <Grid sx={{ mt: 3 }} container spacing={1} className='flex justify-center'>
            <Grid item xs={6}>
              <label>Role Name</label>
              <input className='input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder="Enter Role Name" onChange={(e) => setRoleName(e.target.value)} value={roleName} />
            </Grid>
          </Grid>

          <Grid sx={{ mt: 3 }} container spacing={1} className='flex justify-center'>
            <Grid item xs={6}>
              <label>Role Description</label>
              <textarea className='input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' rows={8} placeholder="Enter Role Description" onChange={(e) => setRoleDescription(e.target.value)} value={roleDescription} />
            </Grid>
          </Grid>

        </CardContent>
      </Card>
    </div>
  )
};
const Step2 = ({ selectAllCheckbox, selectedCheckbox, setSelectAllCheckbox, setSelectedCheckbox, handleSelectAllCheckbox, sitePages }) => {

  const updateSelectedCheckbox = (event, item) => {
    if (event.target.checked) {
      setSelectedCheckbox((prevSelectedItems) => [...prevSelectedItems, item?.PageId]);
      const oldList = [...selectedCheckbox];
      oldList.push(item?.PageId);
      oldList.sort(function (a, b) { return b - a })

      let pages_ids = sitePages?.map((page) => page?.PageId).sort(function (a, b) { return b - a })
      if (arrayEquals(pages_ids, oldList)) {
        setSelectAllCheckbox(true)
      } else {
        setSelectAllCheckbox(false)
      }
    } else {
      setSelectedCheckbox((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item?.PageId)
      );
      setSelectAllCheckbox(false)
    }
  };

  return (
    <div className='mt-5'>
      <Card>

        <CardContent className='border border-solid border-gray-50'>

          <div className='p-3'>
            <h3 className='e-study-heading-four'>Set Permissions</h3>
            <p className='e-study-text-grey'>Modify what individuals on this role can do</p>
            <hr />
          </div>

          <Paper sx={{ mt: 2 }} variant="outlined">

            <Grid sx={{ mt: 3 }} container spacing={1}>
              <Grid item xs={6} className='pl-3'>
                <h3 className='pl-4'> Admin Access</h3>
              </Grid>
              <Grid item xs={6} className='flex justify-end'>
                <FormControlLabel
                  className='space-x-3'
                  control={
                    <AntSwitch name="enable_all"
                      onChange={handleSelectAllCheckbox}
                      checked={selectAllCheckbox}
                      className='pl-2'
                    />
                  }
                  label="Enable All"
                />
              </Grid>
            </Grid>

            <Grid sx={{ mt: 3 }} container spacing={1}>
              {sitePages.map((page) => (
                <Grid item xs={6} key={page.PageId}>

                  <Grid container spacing={1} className='px-6 mb-3'>
                    <Grid item xs={8} className='mx-auto'>
                      <FormGroup>
                        <FormControlLabel className='space-x-3 pb-6 border-b border-gray-200' control={<AntSwitch
                          onChange={(event) => updateSelectedCheckbox(event, page)}
                          checked={selectedCheckbox.includes(page?.PageId)}
                        />} label={`Access to ${page.PageName}`} />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

          </Paper>
        </CardContent>
      </Card>
    </div>
  )
};

const Role = ({ setAddRoleDisplay }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [roleId, setRoleId] = useState(null);

  const dispatch = useDispatch();
  const { loading, sitePages } = usePermissions();
  const { userInfo } = useAdminUser();
  const [ToastState, setToastState] = useNotifications();



  const handleSelectAllCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedCheckbox(sitePages?.map((page) => page?.PageId));
      setSelectAllCheckbox(true);
    } else {
      setSelectedCheckbox([]);
      setSelectAllCheckbox(false);
    }
  };

  const onAddSuccess = (res) => {
    setRoleId(res);
    setActiveStep(prevStep => Math.min(prevStep + 1, 2))
    dispatch(getAllAdminRoles())
    setToastState({ isOpen: true, severity: "success", message: "Role added successfully" });
  }

  const onUpdateSuccess = (res) => {
    setActiveStep(prevStep => Math.min(prevStep + 1, 2))
    dispatch(getAllAdminRoles())
    setToastState({ isOpen: true, severity: "success", message: "Role updated successfully" });
  }

  const onFail = (res) => {
    setToastState({ isOpen: true, severity: "warning", message: res });
  }

  const handleAddRole = () => {
    if (!roleName || !roleDescription) return;
    const data = {
      userId: userInfo?.data?.profileId,
      roleName: roleName,
      roleDescription: roleDescription,
      isActive: true
    }
    dispatch(addRole(data, onAddSuccess, onFail))
  }

  const handleUpdateRole = () => {
    if (!roleName || !roleDescription) return;
    const data = {
      userId: userInfo?.data?.profileId,
      roleName: roleName,
      roleDescription: roleDescription,
      isActive: true,
      roleId: roleId
    }
    dispatch(updateRole(data, onUpdateSuccess, onFail))
  }

  const onUpdatePermissionsSuccess = (res) => {
    dispatch(getAllAdminRoles())
    setAddRoleDisplay(false);
    setToastState({ isOpen: true, severity: "success", message: "Permission assigned to role successfully" });
  }

  const handleUpdateRolePermissions = () => {
    const data = {
      adminUserId: userInfo?.data?.profileId,
      roleId: roleId,
      pageIds: selectedCheckbox.map((sel)=> ({pageId: sel}))
    }
    dispatch(updateRolePermissions(data, onUpdatePermissionsSuccess, onFail ))
  }


  const renderStepContent = () => {
    switch (activeStep) {
      case 1:
        return <Step1 setRoleDescription={setRoleDescription} setRoleName={setRoleName} roleName={roleName} roleDescription={roleDescription} />;
      case 2:
        return <Step2 selectAllCheckbox={selectAllCheckbox} setSelectAllCheckbox={setSelectAllCheckbox} selectedCheckbox={selectedCheckbox} setSelectedCheckbox={setSelectedCheckbox} handleSelectAllCheckbox={handleSelectAllCheckbox} sitePages={sitePages} />;
      default:
        return null;
    }
  };

  const handleStep = (value) => {
    setActiveStep(value)
  }

  return (
    <Box padding={'5px'}>
      <div className='mt-5 e-study-text-grey flex space-x-3 cursor-pointer' onClick={() => setAddRoleDisplay(false)}>
        <KeyboardBackspace /><h3>Back to Roles</h3>
      </div>
      <div className='my-6'>
        <h3 className='e-study-heading-two'>Create New Role</h3>
      </div>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={6} className='mx-auto'>

            <Box sx={{ width: '100%' }}>
              <Stepper nonLinear activeStep={activeStep - 1}>
                {steps.map((label, index) => (
                  <Step key={label} completed={((activeStep - 1) > index) || completed}>
                    <StepButton color="inherit" onClick={() => handleStep(index + 1)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>

        </Grid>
      </Box>

      {renderStepContent()}

      <div className='mt-5'>
        <Grid container spacing={1}>
          <Grid item xs={12} className='flex justify-center mx-auto'>
            {activeStep !== 1 && <button className="btn px-5" style={{ backgroundColor: '#fff', color: '#40499D', borderRadius: '5px', padding: '8px 10px', fontSize: '15px', fontWeight: 400 }} onClick={() => setActiveStep(prevStep => Math.max(prevStep - 1, 1))} disabled={activeStep === 1}> Back</button>}
            {activeStep < 2 ? <button className="btn px-5 ml-5 e-study-button-blue disabled:bg-e-study-grey disabled:text-e-study-bg-primary" style={{ borderRadius: '5px', padding: '8px 10px', fontSize: '15px', fontWeight: 400, cursor: (!roleName || !roleDescription) && "not-allowed" }}
              onClick={() => { (roleId ? handleUpdateRole() : handleAddRole()) }}
              disabled={activeStep === 2 || !roleName || !roleDescription || loading}
              sx={{ cursor: 'pointer' }}>{!loading ? 'Save and Continue' : <DataUsage size={24} className="animate-spin ml-4" />
              }</button>
              : <button className="btn px-5 ml-5" style={{ backgroundColor: '#40499D', color: '#fff', borderRadius: '5px', padding: '8px 10px', fontSize: '15px', fontWeight: 400 }} onClick={() => { setCompleted(true); handleUpdateRolePermissions() }} sx={{ cursor: 'pointer' }} > Save</button>}
          </Grid>
        </Grid>
      </div>
      <ToastState />
    </Box>
  )
}

export default Role;