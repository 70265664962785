import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import LinearDeterminate from "./StudyTimeLinearProgress";

const colors = {
  lessThan30Mins: "#F2C8ED",
  lessThan1Hour: "#DEAB28",
  lessThan2Hours: "#28AEF3",
  moreThan2Hours: "#40499D",
};

const bgcolors = {
  lessThan30Mins: "#F2C8ED1F",
  lessThan1Hour: "#DEAB281F",
  lessThan2Hours: "#28AEF31F",
  moreThan2Hours: "#40499D1F",
};

export default function BasicTable({ schoolStudyTime }) {
  
  if (!Array.isArray(schoolStudyTime)) {
    return <div>No data available</div>;
  }


  const convertToHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  // Map schoolStudyTime to rows if it's an array
  const rows = schoolStudyTime?.map((student, index) => ({
    sn: (index + 1).toString().padStart(2, "0"),
    student: <Box>
      <Typography sx={{fontSize:"16px", fontWeight: 700, color:"#212B36"}}>{student?.name}</Typography>
      <Typography sx={{fontSize:"13px", fontWeight: 500, color:"#919EAB"}}>{student?.email}</Typography>
    </Box>,
    progress: convertToHours(student?.studyTime),
  }));

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S/N</TableCell>
            <TableCell align="left">Student</TableCell>
            {/* <TableCell align="left">Progress</TableCell> */}
            <TableCell align="left">Time Spent&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.sn}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.sn}
              </TableCell>
              <TableCell align="left">{row?.student}</TableCell>
              {/* <TableCell sx={{ width: "200px" }} align="left">
                <LinearDeterminate
                  progress={row?.progress}
                  colors={
                    row?.progress >= 50
                      ? "success"
                      : row?.progress < 30
                      ? "error"
                      : "warning"
                  }
                />
              </TableCell> */}
              <TableCell align="left">
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "5px",
                    backgroundColor: bgcolors?.moreThan2Hours,
                    // backgroundColor: `${
                    //   row?.progress <= "30m"
                    //     ? bgcolors?.lessThan30Mins
                    //     : row?.progress <= "1h"
                    //     ? bgcolors?.lessThan1Hour
                    //     : row?.progress <= "2h"
                    //     ? bgcolors?.lessThan2Hours
                    //     : bgcolors?.moreThan2Hours
                    // }`,
                    color: colors?.moreThan2Hours,
                    // color:`${
                    //   row?.progress <= "30m"
                    //     ? colors?.lessThan30Mins
                    //     : row?.progress <= "1h"
                    //     ? colors?.lessThan1Hour
                    //     : row?.progress <= "2h"
                    //     ? colors?.lessThan2Hours
                    //     : colors?.moreThan2Hours
                    // }`,
                    border: `1px solid ${colors.moreThan2Hours}`,
                    // border: `1px solid ${
                    //   row?.progress <= "30m"
                    //     ? colors?.lessThan30Mins
                    //     : row?.progress <= "1h"
                    //     ? colors?.lessThan1Hour
                    //     : row?.progress <= "2h"
                    //     ? colors?.lessThan2Hours
                    //     : colors?.moreThan2Hours
                    // }`,
                    borderRadius: "10px",
                  }}
                >
                  {row.progress}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
