import { Box, Container, Stack, Typography } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import "./Home.css";

import NavMenu from "../components/NavMenu";
import CTAJumbotron, {
  CustomImage,
} from "../components/landingPage/CTAJumbotron";
import Features from "../components/landingPage/Features";

import blob from "../assets/images/blob.svg";
import downloadAndroid from "../assets/images/downloadAndroid.svg";
import downloadIOS from "../assets/images/downloadIOS.svg";
import qrcode from "../assets/images/qrcode.svg";
import landingpagebg from "../assets/images/landingpagebg.png";
import LandingPageFooter from "../components/landingPage/LandingPageFooter";
import Community from "../components/landingPage/Community";
import HelpSection from "../components/landingPage/HelpSection";
import Packages from "../components/landingPage/Packages";
import Sponsors from "../components/landingPage/Sponsors";
import Loader from "../components/AuthReuseables/Preloader/Loader";
import { useGoogleAnalytics } from "../hooks/usePageTracking"

const Home = () => {
  const [loading, setLoading] = React.useState(true);
  const { trackPage } = useGoogleAnalytics();
  const refs = {
    featureRef: React.useRef(null),
    faqRef: React.useRef(null),
  };
  const ScrollIntoView = (refId) => {
    const targetRef = refs[refId];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.href = "/home";
    }
  };
  trackPage()
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          className="landingPageFont"
          bgcolor={"#40499D"}
          sx={{
            backgroundImage: `url('${landingpagebg}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <NavMenu ScrollIntoView={ScrollIntoView} />
          <Container maxWidth="xl">
            <CTAJumbotron />
          </Container>
          <Features />
          {/* <Sponsors /> */}
          <Packages refs={refs.featureRef} />
          <Community />
          <HelpSection refs={refs.faqRef} />

          {/* Footer */}
          <LandingPageFooter />
        </Box>
      )}
    </>
  );
};

export default connect()(Home);
