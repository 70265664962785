import React, {useState} from 'react'
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled, useTheme } from "@mui/material/styles";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    useMediaQuery,
    Paper,
    Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from 'moment';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));


const BootstrapDialog = styled(Dialog)(({ theme, width, fullScreen }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
    "& > .MuiBackdrop-root": {
        backdropFilter: "blur(4px)",
    },
    "& .MuiDialog-paper": {
        borderRadius: fullScreen ? "0px" : "12px",
        ...(width &&
            !fullScreen && {
            width: width,
        }),
        boxShadow: "rgba(62, 80, 96, 0.2) 0px 4px 20px",
    },
}));

function AuditDate({ openDateModal,setOpenDateModal, setPagination, pagination, filterDate , handleDateFilter}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [endDateHelperText, setEndDateHelperText] = useState("");
    const [startDateHelperText, setStartDateHelperText] = useState("");
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [startDate, setStartDate] = useState(" ");
    const [endDate, setEndDate] = useState(" ");


    const handleStartDateChange = (newValue) => {

        if (endDate ) {
          setStartDateError(true);
          setStartDateHelperText("Start Date should be lesser than End Date");
     
        } else {
        startDate(newValue);
          setStartDateError(false);
          setStartDateHelperText("");
        }
      };
    
      const handleEndDateChange = (newValue) => {
     setEndDate(newValue);
        if (newValue < startDate) {
            setEndDateError(true);
            setEndDateHelperText("End Date should be greater than Start Date");
      
          } else {
           
            setEndDate(newValue);
            setEndDateError(false);
            setEndDateHelperText("");

            setPagination((prev) => ({ ...prev, startDate: (moment(startDate).format("YYYY-MM-DD")) }))
    setPagination((prev) => ({ ...prev, endDate: (moment(newValue).format("YYYY-MM-DD")) }))
     setOpenDateModal(false);
     setEndDate(" ");
     setStartDate(" ")
          }

        
        
   


      };
      

    return (


        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={openDateModal}
            width={"md"}
            PaperProps={{
                elevation: 0,
            }}
        >
     

            <DialogContent sx={{mt: 0}}>
            <Grid
              container
              rowSpacing={1}
              marginTop={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                //   onChange={(e) => {
                //     handleStartDateChange(e.target.value);
                //   }}
                 
                    helperText= {startDateHelperText}
                    error= {startDateError}
                //   }}
                  label="Start Date"
                  type="Date"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  value={endDate}
                  onChange={(e) => {
                    handleEndDateChange(e.target.value)
                  }}
               
                    helperText= {endDateHelperText}
                    error= {endDateError}
                                
                  label="End Date"
                  type="Date"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
                          
            </DialogContent>

            {/* </Dialog> */}
        </BootstrapDialog>


    )
}

export default AuditDate;




// import React, { useState } from "react";
// import {
//   Button,
//   Popover,
//   Typography,
//   Box,
//   IconButton,
//   Tooltip,
//   Stack,
//   Paper,
//   colors,
//   FormControl,
//   FormLabel,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   Grid,
//   InputLabel,
//   Select,
//   MenuItem,
//   CircularProgress,
//   Autocomplete,
//   TextField,
//   OutlinedInput,
// } from "@mui/material";
// import styled from "@emotion/styled";
// import CloseIcon from "@mui/icons-material/Close";
// import { FilterIcon } from "../../assets/icons/Icons";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";


// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   boxShadow: "none",
// }));

// const Filter = ({ options, onClick, reset, filters }) => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [value, setValue] = React.useState({
//     startDate: "", 
//     endDate: "",
//     currency: 566,
//     paymentStatus: "all", 
//     usertype: "all",
//   });
//   const [startDateError, setStartDateError] = useState(false);
//   const [endDateError, setEndDateError] = useState(false);
//   const [startDateHelperText, setStartDateHelperText] = useState("");
//   const [endDateHelperText, setEndDateHelperText] = useState("");
  
//   const handleClick = (event) => {
//     event.preventDefault();
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = (e) => {
//     e.preventDefault();
//     setAnchorEl(null);
//   };

//   const handleRadioGroup = (event, name) => {
//     setValue({
//       ...value,
//       [name]: event.target.value,
//     });
//   };

//   const handleChange = (event, name) => {
//     event.preventDefault();
//     setValue({
//       ...value,
//       [name]: event.target.value,
//     });
//   };

//   const handleStartDateChange = (newValue) => {

//     if (value.endDate && dayjs(newValue)?.isAfter(value?.endDate)) {
//       setStartDateError(true);
//       setStartDateHelperText("Start Date should be lesser than End Date");
 
//     } else {
//       setValue({
//         ...value,
//         startDate: dayjs(newValue)?.format("YYYY-MM-DD"),
//       });
//       setStartDateError(false);
//       setStartDateHelperText("");
//     }
//   };

//   const handleEndDateChange = (newValue) => {
//     if (value.startDate && dayjs(newValue)?.isBefore(value.startDate)) {
//       setEndDateError(true);
//       setEndDateHelperText("End Date should be greater than Start Date");

//     } else {
//       setValue({
//         ...value,
//         endDate: dayjs(newValue)?.format("YYYY-MM-DD"),
//       });
//       setEndDateError(false);
//       setEndDateHelperText("");
//     }
//   };
  
 
//   const handleSubmit = (event) => {
//     // onClick(value);
//     if (value.startDate && !value.endDate) {
//       setEndDateError(true);
//       setEndDateHelperText("End Date is required");
//       return;
//     }

//     if (!value.startDate && value.endDate) {
//       setStartDateError(true);
//       setStartDateHelperText("Start Date is required");
//       return;
//     }

//     const filteredValue = Object.fromEntries(
//       Object.entries(value).filter(([key, val]) => val !== "" && val != "all")
//     );
  
//     onClick(filteredValue);
//   };

//   const handleReset = (event) => {
//     reset();
//     setValue({
//       startDate: "", 
//       endDate: "",
//       currency: 566,
//       paymentStatus: "all", 
//       usertype: "all",
//     });
//     setStartDateError(false);
//     setStartDateHelperText("");
//     setEndDateError(false);
//     setEndDateHelperText("");
//   };
 
         
           
     
//   );
// };

// export default Filter;
