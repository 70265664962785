import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import useSettings from "./hooks/useSettings";
import { CssBaseline } from "@mui/material";

const GlobalThemeProvider = ({ children }) => {
  const [mode, setMode] = useState("light");
  const { darkMode } = useSettings();

  const getMode = (mode) => ({
    palette: {
      mode: mode,
      primary: {
        main: "#40499D",
        light: "#525FB8",
        dark: "#1a1d3f",
      },
      brandPrimary: {
        main: "#40499D",
        light: "#525FB8",
        dark: "#1a1d3f",
        contrastText: "#fff",
        black: "#313131",
      },
      brandSecondary: {
        main: "#F8EF07",
        light: "#fbf56a",
        dark: "#7c7804",
        contrastText: "#000",
        customColor: "#FFD049",
        custombgColor: "#FFFEF0",
        custom: "#FF9500",
        yellow: "#DEAB28",
      },
      badgeBg: {
        main: "#E9E9E9",
      },
      achievementIcon: {
        newbie: "#00A3FF",
        eager: "#479D40",
        intermediate: "#FF7A00",
        boss: "#FFD14A",
        scholar: "#FF0000",
        prodigy: "#40499D",
      },
      black: {
        main: "#313131",
      },
      skeletonColor: {
        main: "#DCDEE3",
      },
      yellowColor: {
        main: "#DEAB28",
        contrastText: "#fff",
      },
      redColor: {
        main: "#FA7E77",
      },
    },
    typography: { fontFamily: ["DM Sans", "sans-serif"].join(",") },
    shadows: Array(25).fill("none"),
  });

  useMemo(() => {
    if (darkMode) {
      setMode("dark");
    } else {
      setMode("light");
    }
  }, [darkMode]);

  const theme = useMemo(() => createTheme(getMode(mode)), [mode]);

  return (
    // <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}><CssBaseline/>{children}</ThemeProvider>
    // </StyledEngineProvider>
  );
};

export default GlobalThemeProvider;
