import React from 'react'
import { RTEditor } from '../../../components/RTEditor'
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'

const AddWeeklyTrivia = ({ goBack }) => {
  const [question, setEditorStateQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("0");
  const [answer1, setEditorStateAnswer1] = React.useState("");
  const [answer2, setEditorStateAnswer2] = React.useState("");
  const [answer3, setEditorStateAnswer3] = React.useState("");
  const [answer4, setEditorStateAnswer4] = React.useState("");

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  return (
    <div>
      <div className='mt-[40px] text-primary font-medium text-[16px] cursor-pointer' onClick={goBack}>
        Go back
      </div>
      <div className='mt-[24px]'>
        <h3 className='text-[20px] text-[#1E293B] font-medium'>Date Details</h3>
        <div className='w-full bg-white p-[24px] grid grid-cols-1 lg:grid-cols-3 gap-[24px] mt-[16px] rounded-[8px]'>
          <div className='flex flex-col gap-[8px]'>
            <h3>Start Date</h3>
            <input className='bg-white rounded-[8px] border border-[#92C4FC] px-[16px] h-[48px] text-[14px]' type='date' />
          </div>
          <div className='flex flex-col gap-[8px]'>
            <h3>End Date</h3>
            <input className='bg-white rounded-[8px] border border-[#92C4FC] px-[16px] h-[48px] text-[14px]' type='date' />
          </div>
          <div className='flex flex-col gap-[8px]'>
            <h3>Days</h3>
            <input className='bg-white rounded-[8px] border border-[#92C4FC] px-[16px] h-[48px] text-[14px]' type='text' />
          </div>
        </div>
      </div>
      <div className='mt-[20px]'>
        <h3 className='text-[20px] text-[#1E293B] font-medium'>Question Section</h3>
        <div className='bg-white p-[24px] w-full rounded-[8px] mt-[20px]'>
          <h3 className='font-medium text-[16px] text-[#1E293B]'>Question 1</h3>
          <div className='mt-4'>
            <RTEditor
              theme={"snow"}
              value={question}
              key={"question"}
              setValue={setEditorStateQuestion}
            />
            <div className='mt-3' />
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={answer}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="1" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer1}
                    value={answer1}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="2" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer2}
                    value={answer2}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="3" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer3}
                    value={answer3}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="4" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer4}
                    value={answer4}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className='bg-white p-[24px] w-full rounded-[8px] mt-[20px]'>
          <h3 className='font-medium text-[16px] text-[#1E293B]'>Question 2</h3>
          <div className='mt-4'>
            <RTEditor
              theme={"snow"}
              value={question}
              key={"question"}
              setValue={setEditorStateQuestion}
            />
            <div className='mt-3' />
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={answer}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="1" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer1}
                    value={answer1}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="2" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer2}
                    value={answer2}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="3" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer3}
                    value={answer3}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="4" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer4}
                    value={answer4}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className='bg-white p-[24px] w-full rounded-[8px] mt-[20px]'>
          <h3 className='font-medium text-[16px] text-[#1E293B]'>Question 3</h3>
          <div className='mt-4'>
            <RTEditor
              theme={"snow"}
              value={question}
              key={"question"}
              setValue={setEditorStateQuestion}
            />
            <div className='mt-3' />
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={answer}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="1" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer1}
                    value={answer1}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="2" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer2}
                    value={answer2}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="3" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer3}
                    value={answer3}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="4" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer4}
                    value={answer4}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className='bg-white p-[24px] w-full rounded-[8px] mt-[20px]'>
          <h3 className='font-medium text-[16px] text-[#1E293B]'>Question 4</h3>
          <div className='mt-4'>
            <RTEditor
              theme={"snow"}
              value={question}
              key={"question"}
              setValue={setEditorStateQuestion}
            />
            <div className='mt-3' />
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={answer}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="1" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer1}
                    value={answer1}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="2" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer2}
                    value={answer2}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="3" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer3}
                    value={answer3}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="4" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer4}
                    value={answer4}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className='bg-white p-[24px] w-full rounded-[8px] mt-[20px]'>
          <h3 className='font-medium text-[16px] text-[#1E293B]'>Question 5</h3>
          <div className='mt-4'>
            <RTEditor
              theme={"snow"}
              value={question}
              key={"question"}
              setValue={setEditorStateQuestion}
            />
            <div className='mt-3' />
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={answer}
                onChange={handleChange}
                name="radio-buttons-group"
              >
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="1" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer1}
                    value={answer1}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="2" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer2}
                    value={answer2}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="3" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer3}
                    value={answer3}
                  />
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <FormControlLabel label="" value="4" control={<Radio />} />
                  <RTEditor
                    theme={"snow"}
                    setValue={setEditorStateAnswer4}
                    value={answer4}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>

      <div className='gap-[20px] flex items-center mt-[28px]'>
        <button className='bg-white text-primary py-[12px] px-8 text-[16px] font-medium rounded-[8px]'>Cancel</button>
        <button className='bg-primary text-white py-[12px] px-8 text-[16px] font-medium rounded-[8px]'>Save</button>
      </div>
    </div>
  )
}

export default AddWeeklyTrivia