import { Drawer } from '@mui/material'
import React from 'react'

export const QuestionListDrawer = ({ children, active, setDrawer }) => {
    return (
        <Drawer
            anchor={"right"}
            open={active}
            onClose={setDrawer}
            sx={{ display: { md: "none" } }}
        >
            {/* {list(anchor)} */}
            {children}
        </Drawer>
    )
}
