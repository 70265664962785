import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmPaymentStatus, confirmUPPayment } from '../../../redux/payment/paymentActions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import useUser from "../../../hooks/useUser";
import usePayment from "../../../hooks/usePayment";
import TransactionStatus from '../../../components/Payment/TransactionStatus';
import { Skeleton } from '@mui/material';
import { CheckUserSub } from "../../../redux/subscriptions";
import Loader from '../../../components/AuthReuseables/Preloader/Loader';



const PaymentStatus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const [transId, setTransId] = useState(null);
  const user = useUser();
  const payment = usePayment();

  const [paymentStatus, setPaymentStatus] = useState('pending')
  const [loading, setLoading] = useState(true);

  const onClose = () => {
    if (user?.userInfo?.isSponsor) {
      navigate('/sponsor/payment')
    } else if (user?.userInfo?.isSchoolAdmin) {
      navigate('/school/payment')
    } else {
      if (paymentStatus?.toLowerCase() == "approved" || paymentStatus?.toLowerCase() == "success") {
        dispatch(CheckUserSub(user?.userInfo?.profileId));
        navigate('/dashboard/home', { state: { subjectModal: true } });
      } else {
        navigate('/dashboard/settings?tab=3')
      }
    }
  }

  const onSuccess = (res) => {
    setLoading(false);
    setPaymentStatus((res?.code === '00' ? 'success' : (res?.code == "S20" || res?.code == "INP" || res?.code == "INP2" || res?.code == "SM_P" || res?.code == "SM_09") ? 'pending' : 'fail') ?? res?.status);
  }

  const onFailure = (res) => {
    setLoading(false);
    setPaymentStatus('fail')
  }

  function isGUID(str) {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(str);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryString = location.search?.split('?')[1];
    if (queryString) {
      // Split the query string based on '&' to get individual key-value pairs
      const queryParams = queryString.split('&');

      // Find the parameter with the key 'transactionId'
      let transactionId;
      for (const param of queryParams) {
        const [key, value] = param.split('=');
        if (key === 'transactionId') {
          transactionId = value;
          break;
        }
      }
      setTransId(transactionId);
      const payload = {
        paymentGateway: isGUID(transactionId) ? 2 : 1,
        paymentReference: transactionId
      }
      if (transactionId) {
        dispatch(confirmPaymentStatus(payload, onSuccess, onFailure))
      }
    }


  }, [location.search]);

  // useEffect(() => {
  //   setLoading(payment.loading);
  // }, [payment.loading]);



  return (
    <div className="w-full flex overflow-auto justify-center h-screen">
      {(payment.loading || loading) ? (
        <div className=" flex flex-col text-center items-center justify-center">
          <Loader />
        </div>
      ) :
        (<TransactionStatus status={paymentStatus?.toLowerCase() == "initiated" ? "pending" : (paymentStatus?.toLowerCase() == "approved" || paymentStatus?.toLowerCase() == "success") ? "success" : "fail"} onClose={onClose} />)
      }
    </div>
  )
}

export default PaymentStatus