import Axios from "../../connection/defaultClient";
import * as actionTypes from "./types";

export const fetchSponsorSubscriptions = (sponsorId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SPONSOR_SUBSCRIPTIONS,
      payload: true,
    });
    Axios.get(`/Subscription/GetAllSponsorSubscriptions/${sponsorId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_SPONSOR_SUBSCRIPTIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_SPONSOR_SUBSCRIPTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchSchoolSubscriptions = (schoolId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SCHOOL_SUBSCRIPTIONS,
      payload: true,
    });
    Axios.get(`/Subscription/GetSchoolSubscriptions/${schoolId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_SCHOOL_SUBSCRIPTIONS_SUCCESS,
          payload: response.data.data,
        });
        dispatch({
          type: actionTypes.FETCH_SCHOOL_SUBSCRIPTIONS,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_SCHOOL_SUBSCRIPTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchStudentSubscriptions = (profileId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_STUDENT_SUBSCRIPTIONS,
      payload: true,
    });
    Axios.get(`/Subscription/GetCandidateSubscriptions/${profileId}`)
      .then((reponse) => {
        dispatch({
          type: actionTypes.FETCH_STUDENT_SUBSCRIPTIONS_SUCCESS,
          payload: reponse.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_STUDENT_SUBSCRIPTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchSchoolSubscriptionsBySponsorId = (schoolId, sponsorId) => {
  //debugger
  return (dispatch) => {
    Axios.get(
      `/Subscription/GetSchoolSubscriptionsBySponsorId/${schoolId}/${sponsorId}`
    )
      .then((response) => {
        //console.log(response.data, "school sponsor subscriptions o");
        dispatch({
          type: actionTypes.FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_SCHOOL_SPONSOR_SUBSCRIPTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const CheckUserSub = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHECK_USER_SUB,
      payload: true,
    });
    Axios.post(`/Subscription/CheckStudentSub/${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.CHECK_USER_SUB_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CHECK_USER_SUB_FAILURE,
          payload: error.message,
        });
      });
  };
};
export const fetchSubPlans = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ALL_SUBSCRIPTION_PLAN_LOADING,
      payload: true,
    });
    Axios.get(`/Subscription/GetSubscriptionPackages?categoryId=${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_SUBSCRIPTION_PLAN,
          payload: response.data,
        });
        // onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_SUBSCRIPTION_PLAN_FAILURE,
          payload: error.message,
        });
      });
  };
};
export const fetchPaymentSummary = (subscriptionModelId, quantity) => {
  return (dispatch) => {
    Axios.get(
      `/Subscription/GetSubscriptionPaymentSummary/${subscriptionModelId}/${quantity}`
    )
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_PAYMENT_SUMMARY,
          payload: response.data,
        });
        // onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_PAYMENT_SUMMARY_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateAmount = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_AMOUNT, payload: true });
    Axios.post( `/Subscription/UpdateSubscriptionAmount`, payload)
      .then((response) => {
                dispatch({
          type: actionTypes.UPDATE_AMOUNT_SUCCESS,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state !== 1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_AMOUNT_FAILURE,
          payload: error.message,
        });
        console.log(error);
      });
  };
};