import { Box, Container, Dialog, Grid, ListItem, styled } from "@mui/material";
import React, { useState } from "react";
import ApplogoDark from "../../../assets/images/ApplogoDark.png";
import PaymentDetails from "../../../components/Payment/PaymentDetails";
import PlanPackageBox from "../../../components/Payment/PlanPackageBox";
import useResponsive from "../../../hooks/useMediaQuery";
import PackageName from "../../../components/Payment/PackageName";
import { useEffect } from "react";
import useUser from "../../../hooks/useUser";
import { useDispatch } from "react-redux";
import { initiatePaymentGateway, initiateUPPayment } from "../../../redux/payment/paymentActions";
import useAdminUser from "../../../hooks/useAdminUser";
import { useNavigate } from "react-router-dom";
import usePayment from "../../../hooks/usePayment";
import Loader from '../../../components/AuthReuseables/Preloader/Loader';
import { useGoogleAnalytics } from "../../../hooks/usePageTracking";



const Payment = ({ setPaymentModal, selectedSubscription }) => {
  const [value, setValue] = React.useState("gatewayPayment");

  const user = useUser();
  const adminUser = useAdminUser();
  const userInfo = user.token ? user?.userInfo : adminUser?.userInfo?.data;
  const payment = usePayment();
  const { trackSpecificEvent } = useGoogleAnalytics()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [trxId, setTransId] = useState("");
  const [UpPage, setUpPage] = useState(null);
  const [open, setOpen] = useState(false);

  const [input, setInput] = useState({
    currency: "NGN",
    currencyValue: "566",
    amount: 10000,
    fee: 0,
    slots: 1,
    paymentGateway: 2,
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = useResponsive("down", "sm");
  // const isTablet = useResponsive("between", "sm", "md");
  const isTablet = useResponsive("down", "md");
  /* #region  Create remita script tags and initiate the script */
  useEffect(() => {
    const script = document.createElement("script");

    // REMITA
    // script.src = "https://remitademo.net/payment/v1/remita-pay-inline.bundle.js";
    // script.src = "https://login.remita.net/payment/v1/remita-pay-inline.bundle.js";

    // INTERSWITCH
    // Test URL
    // script.src = "https://webpay-ui.k8.isw.la/inline-checkout.js";

    // Live Url
    // script.src = "https://newwebpay.interswitchng.com/inline-checkout.js"

    // PAYSTACK
    // TEST URL Paystack
    script.src = "https://js.paystack.co/v1/inline.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  /* #endregion */

  useEffect(() => {
    if (UpPage) {
      window.location.href = UpPage; // Redirect using window.location.href
    }
  }, [UpPage]);

  const paystackPay = (e) => {
    e.preventDefault();
    function generateRandomString(length) {
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      const charsetLength = charset.length;

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charsetLength);
        result += charset.charAt(randomIndex);
      }

      return result;
    }

    const paymentCallback = async (response) => {
      console.log(response);
      // const acceptedCodes = ['00', '10', "11"];
      // if (acceptedCodes.includes(response.resp)) {
      // try {
      //   const confirm = await fetchJSON(`api/CheckTransactionByTansRef?transRef=${response.txnref}&split=${true}`, null, "GET", token)
      //   if (confirm.Data.status === "Success") {

      //   } else {
      //   }
      // } catch (err) {

      // }
    };
    let handler = PaystackPop.setup({
      key: "pk_test_c164e43d259f7ff5f1eb72dd91cd7475cd27996b", // Replace with your public key
      email: userInfo?.email,
      amount: 8900 * 100,
      ref: generateRandomString(15),
      // label: "Optional string that replaces customer email"
      onClose: function () {
        alert("Window closed.");
      },
      callback: function (response) {
        let message = "Payment complete! Reference: " + response.reference;
        console.log(response);
      },
    });

    handler.openIframe();
  };

  const interswitchPay = () => {
    try {
      const redirectUrl = location.href;

      const paymentCallback = async (response) => {
        // const acceptedCodes = ['00', '10', "11"];
        // if (acceptedCodes.includes(response.resp)) {
        // try {
        //   const confirm = await fetchJSON(`api/CheckTransactionByTansRef?transRef=${response.txnref}&split=${true}`, null, "GET", token)
        //   if (confirm.Data.status === "Success") {

        //   } else {
        //   }
        // } catch (err) {

        // }
      };

      function generateRandomString(length) {
        const charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let result = "";
        const charsetLength = charset.length;

        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charsetLength);
          result += charset.charAt(randomIndex);
        }

        return result;
      }

      const payNow = {
        merchant_code: "MX15510",
        pay_item_id: "Default_Payable_MX15510",
        txn_ref: generateRandomString(15),
        amount: (5000 * 100).toString(),
        currency: 566,
        site_redirect_url: redirectUrl,
        mode: "TEST",
        // split_accounts: `[
        //   {
        //     alias: ${payeeInfo.SplitAccount[0].alias1},
        //     amount: ${(payeeInfo.AmountToPay * 100) - payeeInfo.SplitAccount[1].amount2 - payeeInfo.SplitAccount[2].amount3},
        //     description: "Acceptance Fees",
        //     isPrimary: true,
        //   },
        //   {
        //     alias: ${payeeInfo.SplitAccount[1].alias2},
        //     amount: ${payeeInfo.SplitAccount[1].amount2},
        //     description: "Acceptance Fees UMCATC",
        //   },
        //   {
        //     alias: ${payeeInfo.SplitAccount[2].alias3},
        //     amount: ${payeeInfo.SplitAccount[2].amount3},
        //     description: "Acceptance Fees UMCATC",
        //   }]`,
        onComplete: paymentCallback,
      };

      window.webpayCheckout(payNow);
    } catch (error) {
      console.log("caught error", error);
    }
  };

  const onSuccess = (res) => {
    debugger
    const parts = res.split("/");
    const lastId = parts[parts.length - 1];
    setTransId(lastId);
    setUpPage(res);
    window.location.href = res;
    // setOpen(true);
  };
  const onFailure = () => {
    navigate('/payment/paymentstatus')
  }

  const UPPayment = () => {
    const isLocalhost = window.location.hostname === "localhost";
    const isDev = window.location.hostname === "estudyapp-dev.azurewebsites.net";

    const apiUrl = isLocalhost
      ? "http://localhost:5108/api"
      : isDev ? 'https://estudyapp-dev.azurewebsites.net/api' : 'https://estudy.ng/api';
    const baseUrl = isLocalhost ? "http://localhost:44412" : `https://${window.location.hostname}`

    const data = {
      amount: input.amount,
      currency: input.currency,
      description: `Payment for eStudy Application by ${userInfo.firstName + " " + userInfo.surname
        }`,
      returnUrl: input.paymentGateway == 1 ? `${apiUrl}/Payment/UPProcessPaymentTransaction` : `${baseUrl}/payment/paymentstatus`,
      fee: input.fee,
      MerchantRefData: "",
      profileId: userInfo?.profileId,
      subscriptionModeId: selectedSubscription?.SubscriptionModelId,
      slots: input.slots,
      Email: userInfo?.email,
      CustomerName: userInfo.firstName + " " + userInfo.surname,
      paymentGateway: input.paymentGateway
    };
    trackSpecificEvent("Initiate Payment", "User initiated Payment")
    dispatch(initiatePaymentGateway(data, onSuccess, onFailure));
  };

  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        bgcolor={"#F3F5F8"}
        sx={{ padding: "20px" }}
      >
        <img
          src={ApplogoDark}
          alt="WAEC Study Up"
          style={{ width: "auto", height: "30px" }}
        />
        <button onClick={() => setPaymentModal(false)}>Cancel</button>
      </Box>
      <Container sx={{ p: 3 }}>
        {payment.loading ? <Loader /> : (
          <Grid
            container
            spacing={isMobile ? 0 : isTablet ? 4 : 20}
            sx={{ padding: { lg: "20px 40px 0px 20px" } }}
          >
            <Grid
              item
              lg={6}
              xs={12}
              md={6}
              sm={6}
              sx={{ ...(isMobile && { order: 2 }) }}
            >
              <PaymentDetails
                interswitchPay={UPPayment}
                input={input}
                setInput={setInput}
                selectedSubscription={selectedSubscription}
              />
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              md={6}
              sm={6}
              sx={{ ...(isMobile && { order: 1 }) }}
            >
              {isMobile ? (
                <PackageName
                  isMobile={isMobile}
                  input={input}
                  selectedSubscription={selectedSubscriptiom}
                />
              ) : (
                <PlanPackageBox
                  input={input}
                  selectedSubscription={selectedSubscription}
                />
              )}
            </Grid>
          </Grid>
        )}

      </Container>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "18px" } }}
        maxWidth={"xl"}
      >
        <div className="flex flex-col h-screen justify-center items-center mx-2">
          <iframe id="myFrame" width={800} height={800} src={UpPage}></iframe>
        </div>
      </Dialog>
    </Box>
  );
};

export default Payment;
