// import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Button } from "@mui/material";
import React from "react";

const LoadButton = (props) => {
  return (
    <Button
      {...props}
      disableElevation
      variant={props?.variant ?? "contained"}
      sx={{
        ...props.style,
        textTransform: "capitalize",
        borderRadius: "5px",
        ...(props?.backgroundcolor && {
          backgroundColor: props?.backgroundcolor,
        }),
        "&:hover": {
          backgroundColor: props?.backgroundcolor ?? props?.color,
        },
        ".Mui-disabled": {
          backgroundColor: props?.backgroundcolor ?? "primary.main",
          color: props.disabledcolor ?? "#fff",
        },
        ...(props?.variant === "outlined" && {
          borderWidth: "1.5px",
          ":hover": {
            borderWidth: "1.5px",
          },
        }),
      }}
    >
      <span>{props?.text}</span>
    </Button>
  );
};

export default LoadButton;
