import React, { useEffect } from "react";
import { MainTestView } from "../../../../components/Test";
import TestStartPage from "./TestStartPage";
import useTest from "../../../../hooks/useTest";
import { useDispatch } from "react-redux";
import { fetchTestQuestionByQuestionId } from "../../../../redux/test/testActions";
import { DEACTIVATE_TEST } from "../../../../redux/test/types";
import { Navigate } from "react-router-dom";


const TestHome = () => {
  const dispatch = useDispatch()
  const { questions, isTestActive } = useTest()


  // (function () {
  // questions.map(quest => {
  //   dispatch(fetchTestQuestionByQuestionId(quest.questionNumber))
  // })
  // })()

  useEffect(() => {
    dispatch({ type: DEACTIVATE_TEST })
    const fetchQuestionsWithInterval = async () => {
      for (const question of questions) {
        // const questionData = await fetchQuestionById(id);
        if (question.question === "" || question.question === undefined) {
          dispatch(fetchTestQuestionByQuestionId(question.id)) 
          // console.log(`Fetched question with ID ${id}:`, questionData);

          // Introduce an interval before the next API request
          const timer = await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }
    };

    fetchQuestionsWithInterval();

    return () => {
      // clearTimeout(timer)
    }
  }, []);

  return (
    <>
      {/* isTestActive ? <MainTestView /> : <TestStartPage /> */}
      {
        isTestActive ? <Navigate to={"/test/start"} /> : <TestStartPage />
      }
    </>
  );
};

export default TestHome;
