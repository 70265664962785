import {
  ALL_ADMIN_AUDIT_TRAIL,
  ALL_ADMIN_AUDIT_TRAIL_FAIL,
  ALL_ADMIN_AUDIT_TRAIL_STATUS,
 
} from "./types";

const initialState = {
  loading: false,
  allAudits: [],
  totalAudit: [],
  error: null,
};

const auditTrailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_ADMIN_AUDIT_TRAIL_STATUS:
      return {
        ...state,
        loading: true,
      };
    case ALL_ADMIN_AUDIT_TRAIL:
      return {
        ...state,
        loading: false,
        // totalAudit: action.payload,
        allAudits: action.payload,
        // allAudits: action.payload.data,
        error: null,
      };

    case ALL_ADMIN_AUDIT_TRAIL_FAIL:
      return {
        ...state,
        loading: false,
        allAudits: null,
        totalAudit: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default auditTrailReducer;
