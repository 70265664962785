import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl, Typography, TextField, Box } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import { RTEditor } from "../RTEditor";
import useAdminUser from "../../hooks/useAdminUser";
import { useDispatch } from "react-redux";
import { AddNewSection, UpdateQuestionSection, getQuestionSectionByExamId } from "../../redux/contentUpload/contentUploadActions";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { useNotifications } from "../notifications/notifications";
import useContentUpload from "../../hooks/useContentUpload";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function QuestionModal({ open, handleClose, ExamId, isEditMode, questionSection, selectedSectionId, exam, setIsEditMode, setOpen, input }) {

  const [question, setEditorStateQuestion] = React.useState("");
  const [ToastState, setToastState] = useNotifications();

  const [sectionName, setSectionName] = React.useState("");
  const { userInfo } = useAdminUser();
  const dispatch = useDispatch();
  const { loadingExam } = useContentUpload()



  const onSuccess = () => {
    setToastState({ isOpen: true, severity: "success", message: isEditMode ? "Section Edited Successfully" : "Section Added Successfully" });
  }
  React.useEffect(() => {
    dispatch(getQuestionSectionByExamId(exam ? exam?.examId : input?.examId));
  }, [exam])


  // Set the sectionName when the component mounts or when the selectedSectionId changes
  React.useEffect(() => {

    if (isEditMode && !open) {
      const QuestionName =
        questionSection?.find((s) => s.sectionId === selectedSectionId)?.sectionName;
      setSectionName(QuestionName || "");
      const InstructionName =
        questionSection?.find((s) => s.sectionId === selectedSectionId)?.sectionInstruction;
      setEditorStateQuestion(InstructionName || "");
    } else {
      setSectionName("");
      setEditorStateQuestion("")
    }

  }, [selectedSectionId, questionSection, open, isEditMode]);



  const handleModalClose = () => {
    handleClose();
    // Reset the sectionName when the modal is closed
    setSectionName("");
    setEditorStateQuestion("")
  };


  const handleQuestionSection = () => {
    const data = {
      profileId: userInfo?.data?.profileId,
      sectionName: sectionName,
      examId: exam?.examId,
      sectionInstruction: question,
    }
    const data2 = {
      profileId: userInfo?.data?.profileId,
      examId: exam?.examId,
      sectionName: sectionName,
      sectionInstruction: question,
      sectionId: selectedSectionId,
    }
    if (isEditMode) {
      dispatch(UpdateQuestionSection(data2, onSuccess));
      setIsEditMode(false);
      dispatch(getQuestionSectionByExamId(exam?.examId))
    } else {
      dispatch(AddNewSection(data, onSuccess));
      setOpen(false);
      dispatch(getQuestionSectionByExamId(exam?.examId))
    }
    dispatch(getQuestionSectionByExamId(exam?.examId))
  }
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={isEditMode ? isEditMode : open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isEditMode ? "Edit Section" : "Add New Section"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              id="sectionName"
              label="Section Title"
              // defaultValue="Hello World"
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
            />
          </FormControl>
          <Box sx={{ mt: 2, mx: "auto" }}>
            <RTEditor theme={"snow"} value={question} key="questionAdd" setValue={setEditorStateQuestion} />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleQuestionSection}>
            {loadingExam ? (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            ) : (
              <span>{isEditMode ? "Update changes" : "Save changes"}</span>
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <ToastState />
    </React.Fragment>
  );
}
