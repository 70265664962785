import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#40499D",
    },
    secondary: {
      main: "#222",
    },
  },
});

export default customTheme;
