import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PathChart = ({ scienceCount, artCount, commercialCount }) => {
  const [chartData, setChartData] = useState({
    series: [scienceCount, artCount, commercialCount],
    options: {
      chart: {
        width: 220,
        type: 'donut', 
      },
      labels: ['Science', 'Art', 'Commercial'],
      fill: {
        opacity: 1,
        colors: ["#40499D", "#CFE7FC", "#FEBD38"],
        type: 'gradient', 
        gradient: { 
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#40499D', '#CFE7FC', '#FEBD38'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
        }
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="donut" width={220} />
      </div>
    </div>
  );
};

export default PathChart;
