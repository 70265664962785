import React, { useState, useEffect } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import useUser from "../../../../../hooks/useUser";
import PremiumDialog from "../../../../Modal/PremiumDialog";
import ConfirmPayment from "./ConfirmPaymentDialog";
import { useDispatch } from "react-redux";
import useSubscription from "../../../../../hooks/useSubscription";
import { fetchPaymentSummary } from "../../../../../redux/subscriptions";
import { formatCurrency } from "../../../../../helpers/helper";
import { Seerbitlogo, UPlogo } from "../../../../../assets/images";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});


function createData(name, protein) {
  return { name, protein };
}

export default function PaymentDetails({ interswitchPay, selectedSubscription, setInput, input }) {
  const {
    userInfo: { firstName, surname, email, schoolName },
  } = useUser();
  const subscriptionId = selectedSubscription.SubscriptionModelId

  const dispatch = useDispatch();
  //const subscription = useSubscription();
  // const { paymentSumary } = subscription;
  const [open, setOpen] = useState(false);
  const [studentsCount, setStudentsCount] = useState(1);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState(false);

  const subAmount = selectedSubscription?.Amount;

  const subtotal = parseInt(subAmount) * studentsCount;

  useEffect(() => {
    setInput({ ...input, amount: subtotal, slots: studentsCount })
  }, [studentsCount])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // to be removed

  const handleIncrement = () => {
    setStudentsCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (studentsCount > 1) {
      setStudentsCount((prevCount) => prevCount - 1);
    }
  }


  React.useEffect(() => {
    dispatch(fetchPaymentSummary(subscriptionId, studentsCount));
  }, [subscriptionId, studentsCount])

  const handleProceedClick = () => {
    if (studentsCount > 0) {
      setOpenConfirmationDialog(true);
      dispatch(fetchPaymentSummary(subscriptionId, studentsCount));
    }
  };

  const handlePaymentGatewayChange = (value) => {
    setInput({
      ...input,
      paymentGateway: value
    })
  }



  const handleConfirmProceed = () => {
    setOpenConfirmationDialog(false);
    interswitchPay();
  };

  const handleCancelProceed = () => {
    setOpenConfirmationDialog(false);
  };


  const rows = [
    createData("Subtotal:", formatCurrency(subtotal)),
    // createData("Platform fee:", studentsCount > 0 ? "₦422.00" : "₦0.00"),
    // createData(
    //   "Total Amount:",
    //   formatCurrency(subtotal + (studentsCount > 0 ? 422 : 0))
    // ),
  ];

  return (
    <Box
      component="form"
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        /*     display: 'grid',
          gridTemplateColumns: { sm: '1fr 1fr' },
        gap: 2, */
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "#000",
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Payment Details
        </Typography>
        <Typography
          sx={{
            color: "var(--Card-subheader, #919191)",
            fontSize: "14px",
            fontWeight: 400,
            pt: 0,
            mt: 0,
          }}
        >
          Please ensure you double-check the number of students inputed before
          proceeding with the payment.
        </Typography>
      </Box>

      <Box>
        <Box
          sx={{
            padding: "20px",
            borderRadius: "5px",
            bgcolor: "rgba(203, 213, 225, 0.50)",
            border: "1px solid rgba(51, 150, 2000), 0.3",
            // boxShadow:"0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
            display: "flex",
            alignItems: "center",
            gap: 3,
            marginBottom: 2,
          }}
        >
          <Box>
            <Box pb={2}>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Subscription Plan
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {selectedSubscription.PackageName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                School Email
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {email}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Box pb={2}>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                School Name
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {schoolName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Total Amount Paid Previously{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                ₦ 0.00
              </Typography>
            </Box>
          </Box>
        </Box>

        <FormControl variant="standard" fullWidth>
          <InputLabel
            htmlFor="bootstrap-input"
            sx={{ fontWeight: 800, fontSize: "18px", color: "#313131" }}
          >
            How many students do you want to enroll?
          </InputLabel>
          <Input
            id="bootstrap-input"
            type="number"
            value={studentsCount}
            onChange={(e) => setStudentsCount(e.target.valueAsNumber || 0)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="add" onClick={handleDecrement}>
                  <RemoveIcon />
                </IconButton>
                <IconButton aria-label="add" onClick={handleIncrement}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <div className="mt-4 grid grid-cols-2 gap-2 place-items-center">    
        <div className={`py-4 px-3 col-span-1 w-full flex items-center justify-between rounded-md cursor-pointer border ${input.paymentGateway == 2 ? 'border-[#40499D]' : 'border-[#CBD5E1]'}`} onClick={() => handlePaymentGatewayChange(2)}>
          <div className="flex space-x-2 items-center">
            <img src={Seerbitlogo} alt="Seerbit logo" />
            <span>Seerbit</span>
          </div>
          {
            input.paymentGateway == 2 ? (
              <CheckCircleIcon style={{ color: "#40499D" }} />
            ) : (
              <RadioButtonUncheckedIcon style={{ color: "#40499D" }} />
            )
          }

        </div>
        <div className={`py-4 px-3 col-span-1 w-full flex items-center justify-between rounded-md cursor-pointer border ${input.paymentGateway == 1 ? 'border-[#40499D]' : 'border-[#CBD5E1]'} `} onClick={() => handlePaymentGatewayChange(1)}>
          <div className="flex space-x-2 items-center">
            <img src={UPlogo} alt="Unified Payments logo" />
            <span>Unified Payments</span>
          </div>
          {
            input.paymentGateway == 1 ? (
              <CheckCircleIcon style={{ color: "#40499D" }} />
            ) : (
              <RadioButtonUncheckedIcon style={{ color: "#40499D" }} />
            )
          }
        </div>
      </div>
      <FormControl fullWidth>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{ color: "#313131", fontWeight: 900 }}
                    align="right"
                  >
                    {row.protein}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>
      <FormControl fullWidth>
        <Button
          onClick={handleProceedClick}
          sx={{
            borderRadius: "10px",
            p: 2,
            textTransform: "inherit",
          }}
          variant="contained"
          color="primary"
        >
          Proceed
        </Button>
      </FormControl>
      <ConfirmPayment
        open={openConfirmationDialog}
        handleClose={handleCancelProceed}
        handleConfirmProceed={handleConfirmProceed}
        studentsCount={studentsCount}
        subtotal={subtotal}
        selectedSubscription={selectedSubscription}
      />
    </Box>
  );
}
