import { useSelector } from "react-redux";

const usePermissions = () => {
  const permissions = useSelector((state) => state.permissions);

  const { loading, adminUsersData, error, adminRoles, sitePages,roleSitePages, pagesLoading, roleSitePages2, site_page_loading } = permissions;

  return {
    loading,
    adminUsersData,
    error,
    adminRoles,
    sitePages,
    roleSitePages,
    pagesLoading,
    roleSitePages2,
    site_page_loading
  };
};

export default usePermissions;

