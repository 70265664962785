import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as WeeklyMissionBanner } from "../../../assets/images/WeeklyMissionBanner.svg";
import { calculateTimeRemaining } from "../../../helpers/helper";

const TitleBar = ({timeRemaining}) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-evenly"}
      color={"white"}
      alignItems={"flex-start"}
    >
      <Stack
        sx={{ width: "175px", height: "100px", justifyContent: "flex-end" }}
      >
        <Typography fontSize={"22px"} fontWeight={700} lineHeight={"normal"}>
          Week 2
        </Typography>
        <Typography fontSize={"18px"} lineHeight={"normal"}>
          1/5 completed
        </Typography>
      </Stack>
      <Box>
        <WeeklyMissionBanner />
      </Box>
      <Stack
        sx={{ width: "175px", height: "100px", justifyContent: "flex-end" }}
      >
        <Typography
          fontSize={"14px"}
          fontWeight={600}
          sx={{
            textDecoration: "underline",
            textAlign: "right",
            color: "#F8EF07", 
          }}
        >
          View leaderboard
        </Typography>
        <Typography fontSize={"14px"} fontWeight={400}>
          Refreshes in {timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m 
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TitleBar;
