import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@mui/material";

const CustomSelect = (props) => {
  const { BootstrapInput, value, handleChange, SelDefault, Seloptions } = props;

  // console.log(Seloptions)

  return (
    <FormControl fullWidth variant="standard">
      {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel>d */}
      <NativeSelect
        id="demo-customized-select-native"
        value={value}
        onChange={handleChange}
        input={<BootstrapInput placeholder="Enter age" />}
      >
        <option aria-label="None" value="">
          {SelDefault}
        </option>
        {SelDefault === "Select User Type"
          ? Seloptions?.map((item, i) => (
              <option value={item.CategoryID} key={i}>
                {item.CategoryName}
              </option>
            ))
          : SelDefault === "Select Subscription Status"
          ? Seloptions?.map((item, i) => (
              <option value={item.subscriptionModelId} key={i}>
                {item.name}
              </option>
            ))
          : SelDefault === "Select Country"
          ? Seloptions?.map((item, i) => (
              <option value={item.countryId} key={i}>
                {item.countryName}
              </option>
            ))
          : SelDefault === "Select State"
          ? Seloptions?.map((item, i) => (
              <option value={item.stateId} key={i}>
                {item.stateName}
              </option>
            ))
          : SelDefault === "Select Sponsor Type"
          ? Seloptions?.map((item, i) => (
              <option value={item.SponsorTypeId} key={i}>
                {item.SponsorTypeName}
              </option>
            ))
          : SelDefault === "Select Currency"
          ? Seloptions?.map((item, i) => (
              <option value={item.value} key={i}>
                {item.label}
              </option>
            ))
          : Seloptions?.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}

        {/* <option value={30}>Thirty</option> */}
      </NativeSelect>
    </FormControl>
  );
};

export default CustomSelect;
