import { Box, Collapse, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import useTest from '../../hooks/useTest';
import { UPDATE_SECTION_ID } from '../../redux/test/types';

export const SectionDialog = ({ hidden }) => {
    const [open, setOpen] = React.useState(false);
    const [collapseIn, setCollapseIn] = React.useState(false)
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const { currentQuestion, activeSection, questions } = useTest()
    const dispatch = useDispatch()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    React.useEffect(() => {
        if (questions[currentQuestion].sectionId !== activeSection) {
            if (questions[currentQuestion].sectionId) {
                // handleClickOpen();
            }
            dispatch({
                type: UPDATE_SECTION_ID,
                payload: questions[currentQuestion].sectionId
            })
        }
    }, [currentQuestion])

    return (
        <Box display={"contents"}>
            {/* <Button variant="contained" sx={{ alignSelf: "end", borderRadius: "9999px", display: hidden ? "none" : "initial", justifySelf: "end" }} size='small' onClick={() => setCollapseIn((prev) => !prev)}>
                View section instructions
            </Button> */}
            <Collapse in={true}>
                <DialogTitle>
                    {questions[currentQuestion].sectionTitle}
                    <Typography display={"none"} color={"#ccc"}>Question 1-10</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText fontWeight={"600"} width={"100%"}>
                       {questions[currentQuestion]?.sectionInstruction != null ? 'Section Instruction' : '' } 
                    </DialogContentText>
                    {window.HTMLReactParser(questions[currentQuestion]?.sectionInstruction || "")}
                </DialogContent>
            </Collapse>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {questions[currentQuestion].sectionTitle}
                    <Typography display={"none"} color={"#ccc"}>Question 1-10</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText fontWeight={"600"} width={"100%"}>
                        Section Instruction
                    </DialogContentText>
                    {window.HTMLReactParser(questions[currentQuestion]?.sectionInstruction || "")}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button variant='contained' onClick={handleClose}>Proceed to questions</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
