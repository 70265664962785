import {
  Avatar,
  Box,
  Paper,
  Stack,
  Typography,
  Skeleton,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  FirstMedal,
  LeaderDiamondIcon,
  SecondMedal,
  ThirdMedal,
} from "../../assets/icons/Icons";
import StarRateIcon from "@mui/icons-material/StarRate";
import PersonIcon from "@mui/icons-material/Person";
import useGamification from "../../hooks/useGamification";
import useFitText from "../../hooks/useFitText";

const KiteContainer = styled(Box)({
  width: "27px",
  height: "27px",
  position: "relative",
  backgroundColor: "gold",
  // clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
  borderRadius: "9.41px",
  transform: "rotate(-45deg)",
});

const NumberText = styled("div")({
  transform: "translate(-50%, -50%)",
  color: "#ffffff",
  fontSize: "12px",
});

const ResponsiveTypography = ({
  children,
  maxFontSize,
  minFontSize,
  ...props
}) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const fontSize = useFitText(maxFontSize, minFontSize, containerRef, textRef);

  return (
    <Typography
      component="span"
      ref={containerRef}
      noWrap
      style={{
        width: "100%",
        fontSize: `${fontSize}px`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
      }}
      {...props}
    >
      <span ref={textRef} style={{ display: "inline-block" }}>
        {children}
      </span>
    </Typography>
  );
};

const FirstPosition = ({ row, loading }) => {
  return loading ? (
    <Stack
      sx={{ borderTopLeftRadius: "47px", borderTopRightRadius: "41px" }}
      height={{ md: "289px" }}
      width={"179px"}
      direction={"column"}
      bgcolor={"#2A306D"}
      position={"relative"}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 1,
          borderTopLeftRadius: "47px",
          borderTopRightRadius: "41px",
        }}
      />
      <Stack
        gap
        position={"absolute"}
        alignItems={"center"}
        sx={{ top: "-62%", left: "14%" }}
      >
        <FirstMedal sx={{ fontSize: 54, width: "103px", height: "98px" }} />
        <Box
          sx={{ border: "4px solid gold", width: "130px", height: "130px" }}
          borderRadius={"9999px"}
          position={"relative"}
        >
          <Skeleton
            variant="circular"
            animation="wave"
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 3,
            }}
          />
          <Avatar sx={{ width: "100%", height: "100%", zIndex: 2 }} />
          <Box position={"absolute"} bottom={"-14%"} left={"40%"}>
            <KiteContainer sx={{ bottom: "5px", zIndex: 3 }}>
              <NumberText
                sx={{
                  position: "absolute",
                  top: "18%",
                  left: "40%",
                  transform: "rotate(45deg)",
                }}
              >
                1
              </NumberText>
            </KiteContainer>
          </Box>
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Stack
      sx={{ borderTopLeftRadius: "47px", borderTopRightRadius: "41px" }}
      height={{ md: "289px" }}
      width={"179px"}
      gap={"3px"}
      direction={"column"}
      bgcolor={"#2A306D"}
      position={"relative"}
    >
      <Stack
        gap
        position={"absolute"}
        alignItems={"center"}
        sx={{ top: "-61%", left: "14%" }}
      >
        {/* <Avatar></Avatar> */}
        <FirstMedal sx={{ fontSize: 54, width: "103px", height: "98px" }} />
        <Box
          sx={{ border: "4px solid gold", width: "130px", height: "130px" }}
          borderRadius={"9999px"}
          position={"relative"}
        >
          <Avatar
            src="https://github.com/4x3l3r8.png"
            sx={{
              height: "100%",
              width: "100%",
            }}
          />
          <Box position={"absolute"} bottom={"-14%"} left={"40%"}>
            <KiteContainer sx={{ bottom: "5px" }}>
              <NumberText
                sx={{
                  position: "absolute",
                  top: "18%",
                  left: "40%",
                  transform: "rotate(45deg)",
                }}
              >
                1
              </NumberText>
            </KiteContainer>
          </Box>
        </Box>
      </Stack>
      <br />
      <br />
      <br />
      <Stack>
        <ResponsiveTypography
          maxFontSize={20}
          minFontSize={14}
          // You can pass other Typography props here if needed
        >
          {row?.name.split(" ").slice(0, 2).join(" ")}
        </ResponsiveTypography>
        <Typography
          fontSize={"1rem"}
          color="brandSecondary.main"
          component={"span"}
        >
          ({row?.path})
        </Typography>
      </Stack>
      <Stack>
        <Typography
          fontSize={"1.5rem"}
          fontWeight={"bold"}
          color={"#FCC200"}
          component={"span"}
        >
          {row?.points}
        </Typography>
        <Typography fontSize={"1rem"} color="#ccc" component={"span"}>
          Points earned
        </Typography>
      </Stack>
      <Stack>
        <Typography
          component={"span"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <LeaderDiamondIcon fontSize="13" /> &nbsp;
          <Typography component={"span"} fontSize={"1rem"}>
            2
          </Typography>
        </Typography>
        <Typography fontSize={"1rem"} color="#ccc" component={"span"}>
          Reward
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent={"center"}>
        <StarRateIcon sx={{ color: "brandSecondary.main" }} />
        <StarRateIcon sx={{ color: "brandSecondary.main" }} />
        <StarRateIcon sx={{ color: "brandSecondary.main" }} />
        <StarRateIcon sx={{ color: "brandSecondary.main" }} />
        <StarRateIcon sx={{ color: "brandSecondary.main" }} />
      </Stack>
    </Stack>
  );
};

const SecondPosition = ({ row, loading }) => {
  return loading ? (
    <Stack
      sx={{ borderTopLeftRadius: "19px" }}
      height={"208px"}
      width={"159px"}
      direction={"column"}
      bgcolor={"#171B47"}
      position={"relative"}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 1,
          borderTopLeftRadius: "19px",
        }}
      />
      <Stack
        gap
        position={"absolute"}
        alignItems={"center"}
        sx={{ top: "-71%", left: "16%" }}
      >
        <SecondMedal sx={{ fontSize: 54, width: "73px", height: "70px" }} />
        <Box
          sx={{ border: "4px solid #578AE0", width: "108px", height: "108px" }}
          borderRadius={"9999px"}
          position={"relative"}
        >
          <Skeleton
            variant="circular"
            animation="wave"
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 3,
            }}
          />
          <Avatar sx={{ width: "100%", height: "100%", zIndex: 2 }} />
          <Box position={"absolute"} bottom={"-14%"} left={"40%"}>
            <KiteContainer
              sx={{ backgroundColor: "#578AE0", right: "3px", zIndex: 3 }}
            >
              <NumberText
                sx={{
                  position: "absolute",
                  top: "18%",
                  left: "38%",
                  transform: "rotate(45deg)",
                  fontSize: "12px",
                }}
              >
                2
              </NumberText>
            </KiteContainer>
          </Box>
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Stack
      sx={{ borderTopLeftRadius: "19px" }}
      height={"208px"}
      width={"159px"}
      gap={"7px"}
      direction={"column"}
      bgcolor={"#171B47"}
      position={"relative"}
    >
      <Stack
        gap
        position={"absolute"}
        alignItems={"center"}
        sx={{ top: "-71%", left: "16%" }}
      >
        <SecondMedal sx={{ fontSize: 54, width: "73px", height: "70px" }} />
        <Box
          sx={{ border: "4px solid #578AE0", width: "108px", height: "108px" }}
          borderRadius={"9999px"}
          position={"relative"}
        >
          <Avatar
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
          <Box position={"absolute"} bottom={"-14%"} left={"40%"}>
            <KiteContainer
              sx={{ backgroundColor: "#578AE0", bottom: "2px", right: "3px" }}
            >
              <NumberText
                sx={{
                  position: "absolute",
                  top: "18%",
                  left: "38%",
                  transform: "rotate(45deg)",
                  fontSize: "12px",
                }}
              >
                2
              </NumberText>
            </KiteContainer>
          </Box>
        </Box>
      </Stack>
      <br />
      <br />
      <Stack>
        <ResponsiveTypography
          maxFontSize={14}
          minFontSize={12}
          // You can pass other Typography props here if needed
        >
          {row?.name.split(" ").slice(0, 2).join(" ")}
        </ResponsiveTypography>

        <Typography
          fontSize={"12px"}
          color="brandSecondary.main"
          component={"span"}
        >
          ({row?.path})
        </Typography>
      </Stack>
      <Stack>
        <Typography
          fontWeight={"bold"}
          fontSize={"1rem"}
          color={"#E8F0FF"}
          component={"span"}
        >
          {row?.points}
        </Typography>
        <Typography fontSize={"12px"} color="#ccc" component={"span"}>
          Points earned
        </Typography>
      </Stack>
      <Stack>
        <Typography
          component={"span"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          variant="body"
        >
          <LeaderDiamondIcon fontSize="13" /> &nbsp;
          <Typography component={"span"} fontSize={"12px"}>
            2
          </Typography>
        </Typography>
        <Typography fontSize={"12px"} color="#ccc" component={"span"}>
          Reward
        </Typography>
      </Stack>
    </Stack>
  );
};

const ThirdPosition = ({ row, loading }) => {
  return loading ? (
    <Stack
      sx={{ borderTopRightRadius: "19px" }}
      height={"208px"}
      width={"159px"}
      direction={"column"}
      bgcolor={"#171B47"}
      position={"relative"}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          borderTopRightRadius: "19px",
        }}
      />
      <Stack
        gap
        position={"absolute"}
        alignItems={"center"}
        sx={{ top: "-71%", left: "16%" }}
      >
        <ThirdMedal sx={{ fontSize: 54, width: "73px", height: "70px" }} />
        <Box
          sx={{ border: "4px solid #C7824B", width: "108px", height: "108px" }}
          borderRadius={"9999px"}
          position={"relative"}
        >
          <Skeleton
            variant="circular"
            animation="wave"
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 3,
            }}
          />
          <Avatar sx={{ width: "100%", height: "100%", zIndex: 2 }} />
          <Box position={"absolute"} bottom={"-14%"} left={"40%"}>
            <KiteContainer
              sx={{ backgroundColor: "#C7824B", right: "3px", zIndex: 3 }}
            >
              <NumberText
                sx={{
                  position: "absolute",
                  top: "18%",
                  left: "38%",
                  transform: "rotate(45deg)",
                  fontSize: "12px",
                }}
              >
                3
              </NumberText>
            </KiteContainer>
          </Box>
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Stack
      sx={{ borderTopRightRadius: "19px" }}
      height={"208px"}
      width={"159px"}
      gap={"7px"}
      direction={"column"}
      bgcolor={"#171B47"}
      position={"relative"}
    >
      <Stack
        gap
        position={"absolute"}
        alignItems={"center"}
        sx={{ top: "-71%", left: "16%" }}
      >
        <ThirdMedal sx={{ fontSize: 54, width: "73px", height: "70px" }} />
        <Box
          sx={{ border: "4px solid #C7824B", width: "108px", height: "108px" }}
          borderRadius={"9999px"}
          position={"relative"}
        >
          <Avatar
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
          <Box position={"absolute"} bottom={"-14%"} left={"40%"}>
            <KiteContainer sx={{ backgroundColor: "#C7824B", right: "3px" }}>
              <NumberText
                sx={{
                  position: "absolute",
                  top: "18%",
                  left: "38%",
                  transform: "rotate(45deg)",
                  fontSize: "12px",
                }}
              >
                3
              </NumberText>
            </KiteContainer>
          </Box>
        </Box>
      </Stack>
      <br />
      <br />
      <Stack>
        <ResponsiveTypography
          maxFontSize={14}
          minFontSize={12}
          // You can pass other Typography props here if needed
        >
          {row?.name.split(" ").slice(0, 2).join(" ")}
        </ResponsiveTypography>
        <Typography
          fontSize={"12px"}
          color="brandSecondary.main"
          component={"span"}
        >
          ({row?.path})
        </Typography>
      </Stack>
      <Stack>
        <Typography
          fontWeight={"bold"}
          fontSize={"1rem"}
          color={"#F08D21"}
          component={"span"}
        >
          {row?.points}
        </Typography>
        <Typography fontSize={"12px"} color="#ccc" component={"span"}>
          Points earned
        </Typography>
      </Stack>
      <Stack>
        <Typography
          component={"span"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          variant="body"
        >
          <LeaderDiamondIcon fontSize="13" /> &nbsp;
          <Typography component={"span"} fontSize={"12px"}>
            2
          </Typography>
        </Typography>
        <Typography fontSize={"12px"} color="#ccc" component={"span"}>
          Reward
        </Typography>
      </Stack>
    </Stack>
  );
};

export function LeaderLadder({ gamificationData, loading }) {
  // useEffect(() => {
  //   const userProfileId = userInfo?.profileId;
  //   if (userProfileId) {
  //     dispatch(fetchStudiesInProgressStat(userProfileId));
  //   }
  // }, [userInfo]);

  return (
    <div className="mt-52">
      <Box
        textAlign={"center"}
        alignItems={"end"}
        color={"white"}
        justifyContent={"center"}
        display={"flex"}
      >
        <SecondPosition row={gamificationData[1]} loading={loading} />
        <FirstPosition row={gamificationData[0]} loading={loading} />
        <ThirdPosition row={gamificationData[2]} loading={loading} />
      </Box>
    </div>
  );
}
