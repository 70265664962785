import React, { useEffect, useRef, useState } from 'react';
import {
  Grid, Typography, Box, Tabs, Tab, OutlinedInput,
  InputAdornment, IconButton, Chip, Menu, MenuItem,
  Button, Card, Dialog, FormControl, CardContent,
  MenuList, ListItemIcon, ListItemText, DialogTitle, InputLabel, DialogContent, Paper, Avatar, DialogActions,
} from '@mui/material';
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import usePermissions from '../../../hooks/usePermissions';
import { DataUsage } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { getAllAdminRoles, updateRole } from '../../../redux/permissions/permissionsActions';
import useAdminUser from '../../../hooks/useAdminUser';

const EditRole = (props) => {

  const { openEdit, handleCloseEdit, selectedRowRole, setToastState } = props

  const dispatch = useDispatch();
  const { loading } = usePermissions();
  const { userInfo } = useAdminUser();

  const [roleName, setRoleName] = useState('');
  const [roleId, setRoleId] = useState('');
  const [roleDescription, setRoleDescription] = useState('');


  useEffect(() => {
    setRoleName(selectedRowRole.roleName);
    setRoleDescription(selectedRowRole?.roleDescription);
    setRoleId(selectedRowRole.roleId);

  }, [selectedRowRole])

  const onUpdateSuccess = (res) => {
    handleCloseEdit();
    dispatch(getAllAdminRoles())
    setToastState({ isOpen: true, severity: "success", message: "Role updated successfully" });
  }

  const onFail = (res) => {
    handleCloseEdit();
    setToastState({ isOpen: true, severity: "warning", message: res });
  }


  const handleUpdateRole = () => {
    if (!roleName || !roleDescription) return;
    const data = {
      userId: userInfo?.data?.profileId,
      roleName: roleName,
      roleDescription: roleDescription,
      isActive: true,
      roleId: roleId
    }
    dispatch(updateRole(data, onUpdateSuccess, onFail))
  }

  return (
    <Dialog
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="alert-dialog-title"
      >
        <Typography sx={{
          color: '#313131',
          fontSize: '16px', fontWeight: 600,
          lineHeight: '16px'
        }}>Edit Role</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseEdit}
          style={{
            position: "absolute",
            top: "1px",
            right: "1px",
            justifyContent: "flex-end"
          }}

        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>


            <Grid sx={{ mt: 3 }} container spacing={1} className='flex justify-center'>
              <Grid item xs={12}>
                <label>Role Name</label>
                <input className='input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder="Enter Role Name" onChange={(e) => setRoleName(e.target.value)} value={roleName} />
              </Grid>
            </Grid>

            <Grid sx={{ mt: 3 }} container spacing={1} className='flex justify-center'>
              <Grid item xs={12}>
                <label>Role Description</label>
                <textarea className='input_tag btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' rows={8} placeholder="Enter Role Description" onChange={(e) => setRoleDescription(e.target.value)} value={roleDescription} />
              </Grid>
            </Grid>

      </DialogContent>
      <DialogActions>
        <button onClick={() => { handleUpdateRole() }} style={{ backgroundColor: '#40499D' }} className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full" disabled={loading}>
          {!loading ? (
            <Typography sx={{
              color: '#fff',
              fontSize: '12px', fontWeight: 600,
              lineHeight: '16.8px',
              fontFamily: 'Open Sans'
            }}>Edit Role</Typography>
          ) : (
            <DataUsage size={24} className="animate-spin ml-4" />
          )}

        </button>
      </DialogActions>
    </Dialog>
  )
}

export default EditRole