import styled from '@emotion/styled';
import { DialogContent, Dialog, DialogTitle, FormControl, Grid, IconButton, TextField, Skeleton } from '@mui/material';
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  
const ViewTransactionDialog = ({handleClose, open, transaction, loading}) => {

    const data = [
        { label: 'Full Name', value: transaction[0]?.FullName, name: 'fullName' },
        { label: 'Email', value: transaction[0]?.Email, name: 'email' },
        { label: 'User Type', value: transaction[0]?.CategoryName, name: 'userType' },
        { label: 'Subscription Plan', value: transaction[0]?.Name, name: 'plan' },
        { label: 'No of Licenses', value: transaction[0]?.Slots, name: 'slot' },
        { label: 'Amount Paid', value: transaction[0]?.Trans_Amt, name: 'amount' },
        { label: 'Transaction Id', value: transaction[0]?.UPTransId, name: 'transId' },
        { label: 'Date & Time', value: transaction[0]?.UPTransDateTime, name: 'dateTime' },
        { label: 'Payment Status', value: transaction[0]?.UPStatus, name: 'status' },
      ];

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          View Transaction Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            <Grid container spacing={2}>
                {data.map((field, index) => (
            <Grid item xs={12} md={6} key={index}>
                {loading ? 
                <Skeleton variant="rectangular" width={250} height={50} /> :
              <FormControl fullWidth>
                <TextField
                  value={field.value}
                  label={field.label}
                  name={field.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
      }
            </Grid>
          ))}
            </Grid>

        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default ViewTransactionDialog