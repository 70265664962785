import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import { graduate, checklistform, list } from "../../../assest/images/index";
import { useDispatch } from "react-redux";
import useUser from "../../../hooks/useUser";
import { getDesiredGrades } from "../../../redux/auth/authActions";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const ColoredPaper = styled(Paper)(({ theme, selected }) => ({
  backgroundColor: selected ? "#EAECFF" : theme.palette.background.default,
  borderRadius: "20px",
  border:"1px solid #E5E5E5",
  boxShadow: "0px 4px 4px 0px #E5E5E5"
}));

export default function DesiredGrades(props) {
  const { input, setInput } = props
  const theme = useTheme();

  const desiredgradegrid = {
    marginTop: "7rem",
    marginBottom: "2rem",

    [theme.breakpoints.up('md')]: {
      marginTop: "7rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: "3rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: "1rem",
      marginBottom: "0rem",
    },
  };

  const desiredgradetext = {
    fontSize: "30px",
    flexGrow: 0,
    paddingRight: "30px",

    [theme.breakpoints.up('md')]: {
      fontSize: "30px",
      flexGrow: 0,
      paddingRight: "30px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "18px",
    },
  };

  const paperCardBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "2rem",
    "& > :not(style)": {
      m: 2,
      width: 200,
      height: 200,
    },
  };

  const colouredPaper = {
    mr: 2,
    p: 2,
    borderRadius: 6,
  };

  const paperImg = {
    width: 100,
    height: 100,
    // [theme.breakpoints.up('md')]: {
    //   width: 20,
    //   height: 20,
    // },
  };

  const [selectedValue, setSelectedValue] = React.useState("");

  const dispatch = useDispatch()
  const user = useUser()
  const { desiredGrade, isLoading } = user;

  useEffect(() => {
    dispatch(getDesiredGrades())
  }, []);

  const handleChange = (event, param1) => {
    setSelectedValue(event.target.value);
    setInput({ ...input, desiredGradeId: param1 })
  };

  const picturess = [
    graduate,
    checklistform,
    list
  ]

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        sx={desiredgradegrid}
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={desiredgradetext}
          >
            What is your desired grade?
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={paperCardBox}
      >
        {desiredGrade
          // .filter((item, i) => i < 3)
          .map((item, i) => (
            <ColoredPaper
              elevation={3}
              selected={selectedValue === item.DesiredGradeName}
              sx={colouredPaper}
            >
              {" "}
              <Radio
                sx={{
                  display: "flex",

                  justifyContent: "flex-end",
                }}
                disableRipple
                checked={selectedValue === item.DesiredGradeName}
                value={item.DesiredGradeName}
                onChange={event => handleChange(event, item.DesiredGradeId)}
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
              />
              <Grid container direction="column" alignItems="center" spacing={0}>
                <Grid item>
                  <img src={picturess[i]} alt="graduate" style={paperImg} />
                </Grid>
                <Grid item>
                  <Typography variant="h6">{item.DesiredGradeName}</Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
            </ColoredPaper>
          ))}
      </Box>
    </div>
  );
}
