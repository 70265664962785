

import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";

import Axios from "../../connection/defaultClient";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { PlusIcon } from "../../assets/icons/Icons";
import useUser from "../../hooks/useUser";
import useSubscription from "../../hooks/useSubscription";
import useSchool from "../../hooks/useSchool";
import { EditManageStudent, fetchAddStudent, fetchClassLevel, fetchGetSchoolSponsorsBySchoolId, fetchGetStudent, fetchGetStudentByProfileId, fetchGetStudentBySponsorId } from "../../redux/school";
import { fetchSchoolSubscriptions, fetchSchoolSubscriptionsBySponsorId } from "../../redux/subscriptions";
import { Dialog, IconButton, Typography } from "@mui/material";



const EditStudentForm = ({ closeModal, setToastState, rowData, openEditDialog, }) => {
    const dispatch = useDispatch();
    const { userInfo } = useUser();
    const { schoolId, sponsorId } = userInfo;
    const subscription = useSubscription();
    const school = useSchool()


    const { allSponsorsBySchool, studentById, classLevel } = school;
    const { schoolSub, schoolSponsorSubscriptions, sponsorSub } = subscription;
    const [selectedLevel, setSelectedLevel] = useState('');
    const [selectedSponsor, setSelectedSponsor] = useState('');
    const [selectedSubscriptionOption, setSelectedSubscriptionOption] = useState('school');
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
    const [sponsorSubscriptions, setSponsorSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [formValues, setFormValues] = useState({
        firstName: "",      // Access first name at index 0
        surName: "",       // Access surname at index 1
        otherName: "",      // Access other name at index 2
        emailAddress: "",// Access email address at index 3
        gender: "",
        phoneNumber: '',
        userPathId: "",
        level: "",
        isSponsored: false,
        sponsor: '',
        studentClassLevelId: "",
    });

    useEffect(() => {
        dispatch(fetchClassLevel())
    }, [])

    console.log(studentById)
    useEffect(() => {

        if (rowData) {

            const profileId = rowData?.ProfileId ?? rowData?.ProfileId;
            dispatch(fetchGetStudentByProfileId(profileId));
        }
    }, [dispatch, rowData]);


    // Update form values when studentById changes
    useEffect(() => {
        if (studentById?.SchoolSponsorName !== null) {
            setSelectedSubscriptionOption('sponsor')
        } else {
            setSelectedSubscriptionOption('school')
        }

        if (studentById?.length > 0) {
            const studentData = studentById[0];

            setFormValues({
                firstName: studentData?.FirstName || '',
                surName: studentData?.Surname || '',
                otherName: studentData?.OtherName || '',
                emailAddress: studentData?.Email || '',
                gender: studentData?.Gender || '',
                level: studentData?.StudentClasslevelId || '',
            });
            setSelectedSubscriptionId(studentData?.subscriptionId),
                setSelectedSponsor(studentData?.sponsorId || '')

        }
    }, [studentById]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const onSuccess = (res) => {
        closeModal(true);
        if (sponsorId) {
            dispatch(fetchGetStudentBySponsorId(sponsorId));
        }
        setToastState({
            isOpen: true,
            severity: "success",
            message: res.msg,
        });
        setTimeout(() => {

        }, 1000);
    };

    const onFail = (res) => {
        setToastState({
            isOpen: true,
            severity: "error",
            message: res.msg,
        });
        setTimeout(() => {
            // navigate('/dashboard/home')
        }, 3000);
        closeModal(true);
        window.location.reload();
    };

    const on500Fail = (error) => {
        setToastState({
            isOpen: true,
            severity: "error",
            message: error?.statusText,
        });
        closeModal(true);
        window.location.reload();
    };


    useEffect(() => {
        dispatch(fetchGetStudent(schoolId));
    }, []);

    useEffect(() => {
        dispatch(fetchSchoolSubscriptions(schoolId));
        dispatch(fetchGetSchoolSponsorsBySchoolId(schoolId));
    }, []);

    useEffect(() => {
        dispatch(fetchGetStudentBySponsorId(sponsorId));
    }, [])

    // useEffect(() => {
    //     dispatch(fetchGetSchoolSponsorsBySchoolId(schoolId));
    // }, []);

    const onSelectChange = async (event) => {
        const selectedValue = event.target.value;
        setSelectedSponsor(selectedValue);
        const sponsorId = selectedValue;

        dispatch(fetchSchoolSubscriptionsBySponsorId(schoolId, sponsorId));
    };



    //funciton to add student by school and sponsor 
    const handleAddSingleStudent = () => {

        const studentData = studentById[0];
        const payload = {
            firstName: formValues.firstName,
            otherName: formValues.otherName,
            surname: formValues.surName,
            email: formValues.emailAddress,
            // gender: formValues.gender,
            // phoneNumber: formValues.phoneNumber,
            // isVerified: false,
            studentClassLevelId: formValues.level,
            profileId: studentData?.ProfileId
        };
        if (userInfo?.schoolId !== null) {
            dispatch(EditManageStudent(userInfo?.schoolId, selectedSponsor, selectedSubscriptionId, payload, onSuccess, onFail));
            dispatch(fetchGetStudent(schoolId));
        }
        else {
            dispatch(EditManageStudent(userInfo?.schoolId, userInfo?.sponsorId, selectedSubscriptionId, payload, onSuccess, onFail));
            dispatch(fetchGetStudentBySponsorId(sponsorId));
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleRadioChange = (event) => {
        setSelectedSubscriptionOption(event.target.value);
        dispatch(fetchGetSchoolSponsorsBySchoolId(userInfo?.schoolId));
    };

    const handleSubscriptionChange = (event) => {
        setSelectedSubscriptionId(event.target.value);
    }



    const reset = () => {
        setFormValues(" ");
    }


    return (
        <>
            <Dialog
                open={openEditDialog}
            >
                <div className="bg-white rounded-2xl ">

                    <Box display={"flex"} justifyContent={"space-between"} sx={{ py: 4, px: 6 }}>
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                }}>
                                Edit Student's Details
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    reset();
                                    closeModal();
                                }}
                            >
                                <CloseIcon sx={{ fontSize: "1.2rem" }} />
                            </IconButton>
                        </Box>

                    </Box>

                    <div className="flex flex-col gap-[24px] px-12 mb-8">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="First Name"
                            size="small"
                            name="firstName"
                            value={formValues.firstName}
                            onChange={handleChange}
                            disabled={isLoading}
                            required
                        />

                    </div>
                    <div className="flex flex-col gap-[24px] px-12 mb-8">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Surname"
                            size="small"
                            name="surName"
                            value={formValues.surName}
                            onChange={handleChange}
                            disabled={isLoading}
                            required
                        />

                    </div>
                    <div className="flex flex-col gap-[24px] px-12 mb-8">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Other Name"
                            size="small"
                            name="otherName"
                            value={formValues.otherName}
                            onChange={handleChange}
                            disabled={isLoading}
                        />

                    </div>
                    <div className="flex flex-col gap-[24px] px-12 mb-8">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Email Address"
                            size="small"
                            name="emailAddress"
                            value={formValues.emailAddress}
                            onChange={handleChange}
                            disabled={studentById[0]?.Status === "Verified" ? true : false}
                            type="email"
                            required
                        />

                    </div>

                    <div className="flex items-center w-full gap-8 px-12 pb-6">

                        <select
                            name="level"
                            id="level"
                            // labelId="demo-select-small-label"
                            value={formValues.level}
                            onChange={handleInputChange}
                            className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
                            disabled={isLoading}
                            required
                        >
                            <option value="">Select Class*</option>
                            {classLevel?.map((option, i) => (

                                <option key={i.StudentClassLevelId} value={option.StudentClassLevelId}>
                                    {option.LevelName}
                                </option>
                            ))}
                        </select>
                    </div>

                    {userInfo?.schoolId == null ? (
                        <div className="flex items-center w-full gap-8 px-12 pb-6">
                            <select
                                name="subscription"
                                id="subscription"
                                className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border"
                                onChange={handleSubscriptionChange}
                                value={selectedSubscriptionId}
                                disabled={isLoading}
                            >
                                <option value="">Active Subscription Packages*</option>
                                {sponsorSub?.allSubsData && sponsorSub?.allSubsData.length > 0 ? (
                                    sponsorSub?.allSubsData
                                        ?.filter(subscription => subscription.SubscriptionStatus === 'Active' && subscription.UnAllocatedLicenses > 0)
                                        .map((subscription, index) => (
                                            <option key={index} value={subscription.SubscriptionId}>
                                                {`${subscription.SubscriptionName} (${subscription.UnAllocatedLicenses} Slots Left)`}
                                            </option>
                                        ))
                                ) : (
                                    <option value="">No Active Subscriptions</option>
                                )}
                            </select>

                        </div>
                    ) : (
                        <>
                            <div>
                                <div className="mb-5">
                                    <RadioGroup
                                        name="options"
                                        value={selectedSubscriptionOption}
                                        onChange={handleRadioChange}
                                        row
                                        sx={{ paddingLeft: '1.7rem' }}
                                    >
                                        <FormControlLabel
                                            value="school"
                                            control={<Radio size="medium" disabled={isLoading} />}
                                            label="School Subscriptions"
                                            style={{ marginLeft: 8 }}

                                        />
                                        <FormControlLabel
                                            value="sponsor"
                                            control={<Radio size="medium" disabled={isLoading} />}
                                            label="Sponsors' Subscriptions"
                                            style={{ marginLeft: 8 }}

                                        />
                                    </RadioGroup>
                                </div>
                                {selectedSubscriptionOption === "sponsor" && (
                                    <>
                                        <div className="flex items-center w-full gap-8 px-12 pb-6">
                                            <select
                                                name="sponsor"
                                                id="sponsor"
                                                className="min-w-full h-[46px] bg-[#ffff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
                                                value={selectedSponsor}
                                                onChange={onSelectChange}
                                                disabled={isLoading}
                                            >
                                                <option value="">Select Sponsor</option>
                                                {allSponsorsBySchool?.length === 0 ? (
                                                    <option value="" disabled>No sponsors</option>
                                                ) : (
                                                    allSponsorsBySchool?.map((sponsor, index) => (
                                                        <option key={index} value={sponsor.SponsorId}>{sponsor.SponsorFullName}</option>
                                                    ))
                                                )}
                                            </select>
                                        </div>
                                        {selectedSponsor && (
                                            <div className="flex items-center w-full gap-8 px-12 pb-6">
                                                <select
                                                    name="subscription"
                                                    id="subscription"
                                                    className="min-w-full h-[46px] bg-[#ffff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
                                                    onChange={handleSubscriptionChange}
                                                    value={selectedSubscriptionId}
                                                    disabled={isLoading}
                                                >

                                                    <option value="">Active Subscription Packages*</option>
                                                    {schoolSponsorSubscriptions && schoolSponsorSubscriptions.length >= 0 ? (
                                                        schoolSponsorSubscriptions
                                                            ?.filter(subscription => subscription.SubscriptionStatus === 'Active' && subscription.SlotsLeft >= 0)
                                                            .map((subscription, index) => (
                                                                <option key={index} value={subscription.subscriptionId}>
                                                                    {subscription.SlotsLeft < 1 ? `${subscription.SubscriptionName} (${subscription.SlotsLeft} Slot Left)` : `${subscription.SubscriptionName} (${subscription.SlotsLeft} Slots Left)`}
                                                                </option>
                                                            ))
                                                    ) : (
                                                        <option value="" disabled>No Active Subscriptions</option>
                                                    )}

                                                    {/* <option value="">Active Subscription Packages*</option>
                                                    {schoolSponsorSubscriptions && schoolSponsorSubscriptions.length > 0 ? (
                                                        schoolSponsorSubscriptions
                                                            ?.filter(subscription => subscription.SubscriptionStatus === 'Active' && subscription.SlotsLeft > 0)
                                                            .map((subscription, index) => (
                                                                <option key={index} value={subscription.SlotsLeft < 1 ? "" : subscription.subscriptionId} disabled={subscription.SlotsLeft === 0}>
                                                                    {subscription.SlotsLeft < 1 ? "0 Slots Left" : `${subscription.SubscriptionName} (${subscription.SlotsLeft} Slots Left)`}
                                                                </option>
                                                            ))
                                                    ) : (
                                                        <option value="" disabled>No Active Subscriptions</option>
                                                    )} */}
                                                </select>

                                            </div>
                                        )}

                                    </>
                                )}
                                {selectedSubscriptionOption === "school" && (
                                    <div className="flex items-center w-full gap-8 px-12 pb-6">
                                        {/* <select
    name="subscription"
    id="subscription"
    className="min-w-full h-[46px] bg-[#f4f7fe] rounded-[10px] font-normal text-[#8e9ab9] text-base px-4"
    onChange={handleSubscriptionChange}
    value={selectedSubscriptionId}
    disabled={isLoading}
  >
    <option value="">Active Subscription Packages*</option>
    {schoolSub?.allSubsData?.IsSchoolSponsored === false && schoolSub?.allSubsData?.length > 0 ? (
      schoolSub?.allSubsData
        .filter(
          subscription =>
            subscription.IsSchoolSponsored === false &&
            subscription.SubscriptionStatus === "Active" &&
            subscription.UnAllocatedLicenses > 0

        )
        .map((subscription, index) => (
          <option key={index} value={subscription.SubscriptionId}>
            {`${subscription.SubscriptionName} (${subscription.UnAllocatedLicenses} Slots Left)`}
          </option>
        ))
    ) : (
      <option value="">No Active Subscriptions</option>
    )}
  </select> */}
                                        <select
                                            name="subscription"
                                            id="subscription"
                                            className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border"
                                            onChange={handleSubscriptionChange}
                                            value={selectedSubscriptionId}
                                            disabled={isLoading}
                                        >
                                            <option value="">Active Subscription Packages*</option>
                                            {schoolSub?.allSubsData && schoolSub?.allSubsData.length > 0 ? (
                                                schoolSub?.allSubsData.map((subscription, index) => {
                                                    if (
                                                        !subscription.IsSchoolSponsored && // Check if not school sponsored
                                                        subscription.SubscriptionStatus === "Active" && // Check if subscription is active
                                                        subscription.UnAllocatedLicenses > 0 // Check if there are available licenses
                                                    ) {
                                                        return (
                                                            <option key={index} value={subscription.SubscriptionId}>
                                                                {`${subscription.SubscriptionName} (${subscription.UnAllocatedLicenses} Slots Left)`}
                                                            </option>
                                                        );
                                                    }
                                                    return null; // Render nothing if conditions are not met
                                                })
                                            ) : (
                                                <option value="">No Active Subscriptions</option>
                                            )}
                                        </select>

                                    </div>
                                )}
                            </div>

                        </>
                    )}


                    <div className="grid w-full px-12 mb-10 justify-items-end">
                        <button
                            className={`rounded-[6px] bg-[#40499D] px-[26px] py-4 text-[12px] text-white text-sm ${!formValues.emailAddress || !selectedSubscriptionId || isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            onClick={handleAddSingleStudent}
                            disabled={!formValues.firstName || !formValues.surName || !formValues.emailAddress || !formValues.level || !selectedSubscriptionId || isLoading}
                        >
                            {!isLoading ? (
                                <>
                                    <PlusIcon size={15} className="mr-2" /> {/* Adjust the margin as needed */}
                                    Update
                                </>
                            ) : (
                                <DataUsageIcon size={24} className="ml-4 animate-spin" />
                            )}
                        </button>

                    </div>

                </div>
            </Dialog>



        </>

    );
};

export default EditStudentForm;
