import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import AccordionExpand from '../../students/DashboardOnboarding/AccordionExpand'
import NoItem from "../../../../assets/images/noItem.png"
import EmptyGroup from "../../../../assets/images/emptyGroup.png"

const StudentSubjects = ({userPathByCategory, handleChangePath, selectedPath, studentsSubjects}) => {
 
  return (
    <Box paddingY={2} bgcolor={"#fff"} borderRadius={"20px"} border={"1px solid #F3F4FE"} sx={{height: "100%"}}>
        <Box mb={2} px={2} display={"flex"} justifyContent={"space-between"}>
            <Typography sx={{fontSize:"16px", fontWeight:700, color:"#2B3674",}}>Students' Subjects</Typography>
            <FormControl variant="standard" sx={{ minWidth: 120 }}> 
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selectedPath}
          onChange={handleChangePath}
          displayEmpty
        >
          <MenuItem value="">
            <em>Path</em>
          </MenuItem>
          {userPathByCategory && Array.isArray(userPathByCategory) && userPathByCategory?.length > 0 ? (
              userPathByCategory?.map((pathItem) => (
                <MenuItem key={pathItem.pathId} value={pathItem.pathId}>
                  {pathItem.pathName}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No paths available</MenuItem>
            )}

        </Select>
      </FormControl>
        </Box>
        {(!studentsSubjects || studentsSubjects?.length === 0 || !Array.isArray(studentsSubjects)) ?
        <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-[#A3AED0] " style={{paddingTop:"80px"}}>
                <img src={NoItem} alt="" width={"100px"} height={"auto"}/>
              No student available
            </p>
          :
        <Box px={2} height={"25rem"}
        sx={{
          overflow: "auto",
        }}>
        {studentsSubjects?.map((item, index) => (
          <AccordionExpand key={index} studentName={item?.name} email={item?.email} content={item?.subjects}
          />
        ))}
        </Box>
}
    </Box>
  )
}

export default StudentSubjects