import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import TestSideBar from '../../../components/sidebars/TestSideBar'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';

const ContentUploadTestSettingsLayout = ({ input, setInput, exam, subjectId, children }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(`/admin/contentupload/exam/${exam?.subjectId ? exam?.subjectId : subjectId}`)
  }

  return (
    <Box>
      <div className='flex items-center'>
        {(exam || subjectId) &&
          <span className="my-2 cursor-pointer" style={{ fontSize: '15px' }} onClick={() => goBack()}><KeyboardBackspaceIcon /></span>
        }

        {/* <button className="btn" style={{ color: '#40499D', borderRadius: '5px', padding: '5px 0', fontSize: '15px', fontWeight: 400 }} onClick={() => goBack()}><span className="my-2"><KeyboardBackspaceIcon /></span></button> */}

        <Typography sx={{
          color: '#000',
          fontFamily: 'Open Sans',
          fontSize: '36px', fontWeight: 700,
          lineHeight: '33.6px',
          paddingLeft: '5px'
        }}>
          Content Upload
        </Typography>
      </div>
      {/* <Typography sx={{
        color: '#9c9c9c',
        fontFamily: 'Open Sans',
        fontSize: '16px', fontWeight: 400,
        lineHeight: '18.4px'
      }}
      >Welcome to the admin dashboard - Your overview awaits!</Typography> */}
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={3} sx={{ pr: 2 }}>
          <TestSideBar input={input} setInput={setInput} exam={exam} />
        </Grid>
        <Grid item xs={12} lg={9}>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContentUploadTestSettingsLayout