import { useTheme } from '@emotion/react';
import { ArrowBackIos, DataUsage } from '@mui/icons-material';
import { Box, Grid, IconButton, Pagination, div, Stack, Tooltip, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { RTEViewer } from '../../../../components/RTEViewer';
import useLearning from '../../../../hooks/useLearning';
import ExamChoices from './studyChoices';
import ExamAnswer from './studyAnswer';
import QuestionButtons from '../../../../components/buttons/QuestionButtons';
import { useDispatch } from 'react-redux';
import { fetchQuestionById } from '../../../../redux/learning';

const StudySubjectTopicQuestion = ({ questions }) => {
  const theme = useTheme();

  const learning = useLearning();
  const dispatch = useDispatch();

  const { examQuestion, loadingQuestion, examCount } = learning;

  const [openAnswer, setOpenAnswer] = useState(false);

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);

  const [clickedQuestions, setClickedQuestions] = useState([]);

  useEffect(() => {
    setClickedQuestions([...clickedQuestions, questions[selectedQuestionIndex]?.ExamQuestionId]);
  }, [])

  // Handle click on a question button
  const handleQuestionClick = (examQuestionId) => {

    dispatch(fetchQuestionById(examQuestionId));

    // Update clickedQuestions to track which questions have been clicked
    setClickedQuestions(prevClickedQuestions => {
      if (!prevClickedQuestions.includes(examQuestionId)) {
        return [...prevClickedQuestions, examQuestionId];
      }
      return prevClickedQuestions;
    });

    // Update selectedQuestionIndex to mark the clicked question as active
    const clickedIndex = questions?.findIndex(q => q.ExamQuestionId === examQuestionId);
    setSelectedQuestionIndex(clickedIndex);
  };

  // Navigate to previous question
  const handlePrev = () => {
    if (selectedQuestionIndex > 0) {
      const newIndex = questions[selectedQuestionIndex - 1]?.ExamQuestionId;
      setSelectedQuestionIndex(selectedQuestionIndex - 1);
      if (!clickedQuestions?.includes(newIndex)) {
        setClickedQuestions([...clickedQuestions, newIndex]);
      }
      dispatch(fetchQuestionById(newIndex));
    }
  };

  // Navigate to next question
  const handleNext = () => {
    if (selectedQuestionIndex < questions?.length - 1) {
      const newIndex = questions[selectedQuestionIndex + 1]?.ExamQuestionId;
      setSelectedQuestionIndex(selectedQuestionIndex + 1);
      if (!clickedQuestions?.includes(newIndex)) {
        setClickedQuestions([...clickedQuestions, newIndex]);
      }
      dispatch(fetchQuestionById(newIndex));
    }
    
  };

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [studyTime, setStudyTime] = useState(0);

  const [calculatorInput, setCalculatorInput] = useState({
    displayValue: "0",
    expression: "",
    isResult: false,
    error: "",
  });


  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);

      if (seconds === 59) {
        setSeconds(0);
        setMinutes((prevMinutes) => prevMinutes + 1);
      }

      if (minutes === 59) {
        setMinutes(0);
        setHours((prevHours) => prevHours + 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
      setStudyTime(timerInterval);
    };
  }, [seconds, minutes, hours]);

  const handleCheck = () => {
    setOpenAnswer(!openAnswer);
  };


  return (
    <>
      <div className={`bg-[#FEFEFE] rounded-[8px] mt-6 min-h-[357px] w-full p-8 flex flex-col gap-7 ${loadingQuestion && 'items-center justify-center'}`}>
        {loadingQuestion ?
          (
              <DataUsage htmlColor="#40499D" size={24} className="animate-spin" />
          ) :
          <>
            <h3 className='font-medium e-study-heading-two'>

              {window?.HTMLReactParser(examQuestion?.sectionInstruction || "", {
                replace: (domNode) => {
                  if (domNode.type === "tag" && domNode.name === "img") {
                    return <img src={domNode.attribs.src} alt={domNode.attribs.alt} onLoad={() => { }} onError={() => { }} />;
                  }
                  return undefined;
                },
              }) ? (
                examQuestion?.sectionInstruction &&
                <div
                  className='border border-e-study-bg-primary mt-2 p-2'

                >
                  <Typography fontWeight={"bolder"} color={"#111"} variant="h6">
                    Instruction
                  </Typography>

                  {window.HTMLReactParser(examQuestion?.sectionInstruction || "")}
                </div>


              ) : ""}
            </h3>
            {examQuestion?.choices?.length === 0 && (
              <div>
                <div className='pt-4'>
                  <h6 className='font-bold text-[#111111]'>
                    Question
                  </h6>
                </div>

                {/* {window.HTMLReactParser(examQuestion?.question || "")} */}
                <RTEViewer value={examQuestion?.question || ""} style={{ height: "fit-content" }} />
              </div>
            )}
            {/* this extra for section instruction not to show number for Theory */}
            {examQuestion?.choices?.length !== 0 && (
              <div className='font-medium text-[24px] flex items-center'>

                <span>{selectedQuestionIndex + 1}{"."} &nbsp; &nbsp;{"  "}</span>
                {/* Adding a space here */}
                <div className="flex flex-col font-medium text-[24px]">
                  {/* {window.HTMLReactParser(examQuestion?.question || "")} */}
                  <RTEViewer value={examQuestion?.question || ""} />
                </div>
              </div>

            )}
            {examQuestion?.choices?.length !== 0 ? <ExamChoices examQuestion={examQuestion} examCount={examCount?.testQuestion} /> : ""}
            <div className='flex justify-end mr-2 my-2'
            >
              {openAnswer ? (
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                  onClick={() => {
                    handleCheck();
                  }}
                >
                  Hide Solution
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleCheck();
                  }}
                >
                  Check Solution
                </Button>
              )}
            </div>

            {openAnswer ? (
              <ExamAnswer
                examQuestion={examQuestion}
                examCount={examCount?.testQuestion}
                open={openAnswer}
                setOpenAnswer={setOpenAnswer}
                // examType={props.examType}
                //added this extra for fontWeight (Theory)
                isTheory={examQuestion?.choices?.length === 0}
              />
            ) : (
              ""
            )}

          </>
        }

      </div>

      <div className='mt-8 bg-[#FFFFFF] rounded-[8px] w-full p-8'>
        <QuestionButtons active={selectedQuestionIndex} questions={questions} onClick={handleQuestionClick} clickedQuestions={clickedQuestions} />

        <div className="mt-4 flex justify-center items-center">
          <button className="px-4 py-2 rounded m-2 bg-e-study-bg-primary text-white disabled:cursor-not-allowed disabled:bg-[#606AAD]" onClick={()=> handlePrev()}  disabled={selectedQuestionIndex === 0}>
            Prev
          </button>
          <button className="px-4 py-2 rounded m-2 bg-e-study-bg-primary text-white disabled:cursor-not-allowed disabled:bg-[#606AAD]" onClick={()=>handleNext()} disabled={selectedQuestionIndex === questions?.length - 1}>
            Next
          </button>
        </div>
      </div>
    </>

  )
}

export default StudySubjectTopicQuestion