import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    ThemeProvider,
    Avatar,
    InputAdornment,
    OutlinedInput,
    FormControl,
    IconButton,
} from "@mui/material";
import AuthContainer from "../../components/AuthReuseables/AuthContainer";
import customTheme from "../../components/AuthReuseables/customTheme";
import { Link } from "react-router-dom";
import PasswordResetMsg from "../../components/AuthReuseables/PasswordResetMsg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNotifications } from "../../components/notifications/notifications";
import { forgotPasswordAction } from "../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import GlobalThemeProvider from "../../GlobalThemeProvider";


const AdminForgotPasswordForm = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formValue, setFormValue] = useState({ email: "" });
    const [msg, setMsg] = useState("");
    const [fail, setFail] = useState("");
    const [ToastState, setToastState] = useNotifications();
    const [isEmailValid, setIsEmailValid] = useState(true);


    useEffect(() => {
        if (msg) {
            setToastState({
                isOpen: true,
                severity: "success",
                message: msg,
            });
            setTimeout(() => {
                setShowConfirmation(true);
            }, 2000);
        }
    }, [msg]);

    useEffect(() => {
        if (fail) {
            setToastState({
                isOpen: true,
                severity: "error",
                message: fail,
            });
        }
    }, [fail]);

    const handleSubmit = () => {
        let param = {
            email: formValue.email,
            isAdmin: true
        };

        dispatch(
            forgotPasswordAction(
                param,
                (res) => setMsg(res.data.msg),
                (res) => setFail(res.data.msg)
            )
        );
    };
    return (
        <GlobalThemeProvider>
            <form>
                {showConfirmation ? (
                    <PasswordResetMsg email={formValue.email} />
                ) : (
                    <Container component="main" maxWidth="xs">
                        <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                                name="email"
                                value={formValue.email}
                                helperText={
                                    isEmailValid ? "" : "Please provide a valid email"
                                }
                                onChange={(e) => {
                                    const emailValue = e.target.value;
                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                    setIsEmailValid(emailRegex.test(emailValue));
                                    setFormValue({ ...formValue, email: emailValue });
                                }}
                                id="outlined-adornment-email"
                                // size="small"
                                endAdornment={<InputAdornment position="end">@</InputAdornment>}
                                aria-describedby="outlined-email-helper-text"
                                inputProps={{
                                    "aria-label": "email",
                                }}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{
                                mt: 2,
                                py: 2,
                                borderRadius: "4px",
                                textTransform: "none",
                                fontSize: "14px",
                            }}
                            disabled={
                                user.isLoading ||
                                !formValue.email ||
                                !isEmailValid
                            }
                        >
                            {!user.isLoading ? (
                                "Send Reset Link"
                            ) : (
                                <DataUsageIcon size={24} className="ml-4 animate-spin" />
                            )}
                        </Button>
                        <Typography
                            sx={{
                                mt: 2,
                                textAlign: "center",
                            }}
                        >
                            <Link
                                to="/adminLogin"
                                style={{
                                    color: "#40499D",
                                    fontSize: "14px",
                                    textDecoration: "none",
                                }}
                            >
                                <KeyboardBackspaceIcon
                                    style={{ color: "inherit", fontSize: "medium" }}
                                />{" "}
                                Back to Login
                            </Link>
                        </Typography>
                    </Container>
                )}
            </form>
            <ToastState />
        </GlobalThemeProvider>
    );
};

export default AdminForgotPasswordForm;
