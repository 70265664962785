import styled from '@emotion/styled';
import { DialogContent, Dialog, DialogTitle, FormControl, Grid, IconButton, TextField, Skeleton } from '@mui/material';
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ConfirmPaymentStatus from '../Payment/ConfirmPaymentStatus';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    // "& > .MuiBackdrop-root": {
    //   backdropFilter: "blur(2px)",
    // },

    "& .MuiDialogContent-root": {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: 15,
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(2),
      position: "relative",
    },
  }));
  
  

  
const TransactionStatusDialog = ({handleClose, open, dispatchActions, selectedId}) => {

   
  return (
    <React.Fragment>
       <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "18px", width: "35%" } }}
        maxWidth={"xl"}
      >
        <DialogContent>
          <ConfirmPaymentStatus
            TransId={selectedId}
            onClose={handleClose}
            actions={dispatchActions}
          />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#b4b4b4",
              m: 3,
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default TransactionStatusDialog