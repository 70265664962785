// material
import { styled } from "@mui/material/styles";
import { Box, Card, Grid, Skeleton, Typography } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(3),
  color: theme.palette.warning.darker,
  backgroundColor: "#fff",
  borderRadius: "16px",
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  gap: theme.spacing(3),
  filter: "drop-shadow(0px 4px 14px rgba(179, 179, 179, 0.25))",
  border: `1px solid ${theme.palette.grey[200]}`,
  cursor: "text",
  userSelect: "none",
  maxHeight:'98px',
}));

// ----------------------------------------------------------------------

export default function Cards({ cards }) {
  return (
    <Box sx={{ pb: 2 }}>
      <Grid container spacing={3}>
        {cards &&
          cards?.map((card) => (
            <Grid key={card.text} item xs={12} sm={6} md={4}>
              <>
                {card?.loading ? (
                  <Skeleton variant="rounded" width="max-width" height="96px" />
                ) : (
                  <RootStyle>
                      {card?.icon}
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight={700}
                        fontSize={"18px"}
                      >
                        {card?.amount ?? 0}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ opacity: 0.72, fontSize: "12px" }}
                      >
                        {card?.text}
                      </Typography>
                    </Box>
                  </RootStyle>
                )}
              </>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
