import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Container, Grid, Stack, TextField } from "@mui/material";
import { ExpandPlusIcon, ExpandLessIcon } from "../../assets/icons/Icons";
import useResponsive from "../../hooks/useMediaQuery";
import "../../pages/Home.css";

const FAQ = [
  {
    question: "What is eStudy?",
    answer:
      "eStudy is a comprehensive study solution designed to help you ace your WASSCE exams. It provides a variety of resources to personalize your study experience and boost your results.",
  },
  {
    question:
      "Who is eStudy for?",
    answer:
      "eStudy is for students and schools who want to improve their studying, regardless of age, grade level, or subject. It's perfect for students who are: visual learners, struggling with a particular subject, and looking for a more efficient and engaging way to study.",
  },
  {
    question:
      "What are the benefits of using eStudy?",
    answer:<Box>
<Typography color="#4E4E4E" fontWeight={400} fontFamily={"Montserrat"} mb={2}>There are many benefits to using eStudy, including:</Typography>
 
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
    <li>Improved WASSEC grades and performance</li>
    <li>More efficient and effective studying</li>
    <li>Increased motivation and engagement</li>
    <li>Personalized learning experience</li>
    <li>Access to WAEC mock test, answers and theories</li>
    </ul>
  
    </Box>,
  },
  {
    question: "Does eStudy have all WAEC past questions and answers?",
    answer:
      "eStudy offers a comprehensive collection of WAEC past questions across various subjects to help you prepare for your exams effectively. You can utilize these past papers to identify exam trends, practice answering questions under timed conditions, and solidify your understanding of key concepts.",
  },
  {
    question:
      "How many subjects are on eStudy?",
    answer:
      " eStudy covers a vast range of subjects to cater to diverse needs. To see the full list of subjects available, visit our website or contact our support team. ",
  },
  {
    question: "Does eStudy offer any support?",
    answer:
      "Yes, eStudy offers a variety of support options, including a knowledge base, FAQs, and email support.",
  },
  {
    question: "What subjects does eStudy cover?",
    answer:
      "eStudy covers a wide range of subjects, depending on the department/class you choose. We profile the subjects with the class/department filled.",
  },
  {
    question: "As a Parent/Guardian, can I see my child/ward's study progress on eStudy?",
    answer:
      "We understand the importance of staying involved in your child's academic journey. You can access their dashboard to monitor their study progress and activities. This allows you to see areas where they excel and identify subjects that might need additional support.",
  },
 
  {
    question: "What features does eStudy offer?",
    answer:<Box>
    <Typography color="#4E4E4E" fontWeight={400} fontFamily={"Montserrat"} mb={2}>
    eStudy offers a variety of features to help you succeed in your studies, including: 
      </Typography>
     
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li><span style={{fontWeight: 500}}>Practice tests:</span>&nbsp;Test your knowledge and identify areas for improvement. </li>
        <li><span style={{fontWeight: 500}}>Study guides and summaries:</span>&nbsp;Access clear and concise summaries of question answers and theories. </li>
        <li><span style={{fontWeight: 500}}>Gamification elements:</span>&nbsp;Earn points and badges, to stay motivated. </li>
        <li><span style={{fontWeight: 500}}>Progress tracking:</span>&nbsp;Monitor your progress and see how you're improving. </li>
        </ul>
      
        </Box>,
  },
  {
    question: "What are the payment methods and plans for eStudy? ",
    answer:"eStudy offers a variety of pricing plans to fit your needs. You can make payment using your credit or debit card, after successful payments, you have access to onboard.  "
  },
  {
    question: "How do I access and sign up on eStudy?  ",
    answer:"Getting started with eStudy is easy! Visit our website and sign up."
  },
  {
    question: "Can I access eStudy on my Laptop or Tablet?  ",
    answer:" Absolutely! eStudy is designed to be accessible on a variety of devices to fit your studying needs. You can access eStudy through our website. We are working on developing a mobile app to provide an even more convenient way to study on the go."
  },
  {
    question: "Is my data safe with eStudy? ",
    answer:"Yes, your data is safe with eStudy. We take data security and privacy very seriously and employ industry-standard security measures to protect your information."
  },
  
  {
    question: "Do I have to log in every time I need to use eStudy? ",
    answer:"To ensure your account security, eStudy will automatically log you out after a period of inactivity. However, when you log back in, you'll be conveniently directed back to where you left off in your study session, so you can pick up right where you stopped! "
  },
 
  {
    question: "Will my plan expire even if I don’t use it for the complete duration of time I paid for?  ",
    answer:" Yes, as soon as you subscribe, your plan becomes active and expires once the subscription time elapses. "
  },
 
];
const AccordionSection = () => {
  const [expandedIndex, setExpandedIndex] = React.useState(-1);

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : -1);
  };

  return (
    <Box
      className="landingPageFont"
      border={"1px 0px 1px 0px solid #00000033 "}
    >
       <Grid container spacing={4}>
      {FAQ?.map((item, index) => (
        <Grid item xs={12} md={6} key={item.question}>
        <Accordion
          key={item?.question}
          expanded={expandedIndex === index}
          onChange={handleAccordionChange(index)}
          sx={{
            "&::before": { backgroundColor: "#00000033" },
            padding: "10px 0px",
            borderBottom: index !== FAQ.length - 1 && "1px solid rgba(0, 0, 0, 0.20)",
          }}
        >
          <AccordionSummary
            // expandIcon={<ExpandPlusIcon />}
            expandIcon={expandedIndex === index ? <ExpandLessIcon /> : <ExpandPlusIcon />}
            aria-controls="panel1-content"
            id={item?.question}
          >
            <Typography
              fontWeight={500}
              fontFamily={"Montserrat"}
              color="#1a1a1a"
            >
              {item?.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography color="#4E4E4E" fontFamily={"Montserrat"}>
              {item?.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
        </Grid>
      ))}
      </Grid>
    </Box>
  );
};
export default function HelpSection({ refs }) {
  const isMobile = useResponsive("down", "md");

  return (
    <Box
      className="landingPageFont"
      ref={refs}
      component={"div"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      bgcolor={"#fff"}
      padding={isMobile ? "40px 20px" : "100px 40px"}
      justifyContent={"center"}
      boxSizing={"border-box"}
      width={"100%"}
    >
      <Container className="landingPageFont" maxWidth="xl">
        <Stack direction={"row"} justifyContent={"center"} mb={3}>
          {" "}
          <Typography
            variant="h5"
            fontWeight={"bold"}
            fontFamily={"Montserrat"}
            fontSize={isMobile ? "28px" : "40px"}
          >
            Frequently Asked Questions
          </Typography>
        </Stack>
        <Grid container spacing={isMobile ? 5 : 10} alignItems={"center"}>
          <Grid item md={12} lg={12} xs={12} sx={{ boxSizing: "border-box" }}>
            <AccordionSection />
          </Grid>
          <Grid item md={12} lg={12} xs={12} sx={{ boxSizing: "border-box" }}>
          <Box
                width={"100%"}
                component={"section"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                >
            <Stack width={isMobile ? "100%" : "60%"} gap={3} >
              <Box 
                 display={"flex"}
                 flexDirection={"column"}
                 justifyContent={"center"}
                 alignItems={"center"}
              >
                <Typography
                  fontFamily={"Montserrat"}
                  fontSize={"28px"}
                  color="#1a1a1a"
                  fontWeight={600}
                  color="#1d1d1d"
                >
                  {" "}
                  Need more inquiries?
                </Typography>
                <Typography
                  fontFamily={"Montserrat"}
                  fontSize={"18px"}
                  color={"rgba(0, 0, 0, 0.60)"}
                  lineHeight={"36px"}
                >
                  Join the Study Smart Newsletter or Contact Us.
                </Typography>
              </Box>
              <Stack direction={isMobile ? "column" : "row"} gap={3}>
                <TextField
                  placeholder="Enter your Email"
                  sx={{
                    flex: 0.8,
                    bgcolor: "#FAFAFA",
                    borderRadius: "37px",
                    "& fieldset": { border: "none" },
                    "& .MuiFilledInput-root": {
                      background: "rgb(232, 241, 250)",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "70px",
                    fontFamily: "Montserrat",
                    border: "2px solid #313131",
                    textTransform: "capitalize",
                    padding: "15px 30px",
                    flex: 0.2,
                  }}
                >
                  Subscribe
                </Button>
              </Stack>
            </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
