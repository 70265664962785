import {
  GET_LEADERBOARD_LOADING,
  GET_LEADERBOARD_FAIL,
  GET_LEADERBOARD_SUCCESS,
  GET_TRIVIA_POINTS,
  GET_TRIVIA_POINTS_FAIL,
  GET_USER_POINT_DIAMOND_LOADING,
  GET_USER_POINT_DIAMOND,
  GET_USER_POINT_DIAMOND_FAIL,
  USE_USER_DIAMOND_FAIL,
  USE_USER_DIAMOND_LOADING,
  USE_USER_DIAMOND,
} from "./types";

const initialState = {
  loading: false,
  gamificationData: [],
  error: null,
  weeklyTrivia: null,
  userPointDiamond: [],
  triviaLoading: false,
  diamondLoading: false,
  useDiamond: [],
};

const gamificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        gamificationData: action.payload,
        error: null,
      };
    case GET_LEADERBOARD_FAIL:
      return {
        ...state,
        loading: false,
        gamificationData: null,
        error: action.payload,
      };
    case GET_TRIVIA_POINTS:
      return {
        ...state,
        weeklyTrivia: action.payload,
        error: null,
      };
    case GET_TRIVIA_POINTS_FAIL:
      return {
        ...state,
        // weeklyTrivia: null,
        error: null,
      };
    case GET_USER_POINT_DIAMOND_LOADING:
      return {
        ...state,
        triviaLoading: true,
        error: null,
      };
    case GET_USER_POINT_DIAMOND:
      return {
        ...state,
        triviaLoading: false,
        userPointDiamond: action.payload,
        error: null,
      };
    case GET_USER_POINT_DIAMOND_FAIL:
      return {
        ...state,
        triviaLoading: false,
        userPointDiamond: null,
        error: action.payload,
      };
    case USE_USER_DIAMOND_LOADING:
      return {
        ...state,
        diamondLoading: true,
        error: action.payload,
      };
    case USE_USER_DIAMOND:
      return {
        ...state,
        diamondLoading: false,
        useDiamond: action.payload,
        error: null,
      };
    case USE_USER_DIAMOND_FAIL:
      return {
        ...state,
        diamondLoading: false,
        useDiamond: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default gamificationReducer;
