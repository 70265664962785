import { 
  CONFIRM_TRANSACTION_STATUS, CONFIRM_TRANSACTION_STATUS_SUCCESS, CONFIRM_TRANSACTION_STATUS_ERROR, INITIATE_TRANSACTION_FAILURE, INITIATE_TRANSACTION_START, INITIATE_TRANSACTION_SUCCESS, GET_TRANSACTIONS, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAILURE, GET_TRANSACTION_BY_ID, GET_TRANSACTION_BY_ID_SUCCESS, GET_TRANSACTION_BY_ID_FAILURE
} from "./types";

const initialState = {
  loading: false,
  oneLoading: false,
  transactionStatus: null,
  error: null,
  transactions: {},
  transaction: [],
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case CONFIRM_TRANSACTION_STATUS:
        return { ...state, loading: true };

    case CONFIRM_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionStatus: action.payload.data,
        error: null,
      };
     
    case CONFIRM_TRANSACTION_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        transactionStatus: null,
        error: action.payload,
      };
    case INITIATE_TRANSACTION_START:
      return {
        ...state,
        loading: true,
      };

    case INITIATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
     
    case INITIATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

      case GET_TRANSACTIONS:
        return {
          ...state,
          loading: true,
          // error: action.payload
      };
    
      case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload
      };
    
      case GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        transactions: {},
      };
      case GET_TRANSACTION_BY_ID:
        return {
          ...state,
          oneLoading: action.payload,
      };
    
      case GET_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state,
        oneLoading: false,
        transaction: action.payload
      };
    
      case GET_TRANSACTION_BY_ID_FAILURE:
      return {
        ...state,
        oneLoading: false,
        error: action.payload,
        transaction: [],
      };

    default:
      return state;
  }
};
export default paymentReducer;


