import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Divider, Popover } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import CustomSelect from "./customSelectForm";
import useUser from "../../../hooks/useUser";
import { useDispatch } from "react-redux";
import { getUserCategory } from "../../../redux/auth/authActions";
import {
  GetSubscriptionModels,
  //   fetchSubscriptionStats,
} from "../../../redux/userMetrics";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1.5),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "transparent", // Hide the placeholder text
  },
  "& .MuiInputBase-input:focus::placeholder": {
    color: "#555", // Style the label text color when focused
  },
}));

export default function FilterPopover(props) {
  // const [open, setOpen] = React.useState(false);
  const { filters, setFilters, subscriptionType, handleReset } = props;
  const [age, setAge] = React.useState("");
  const dispatch = useDispatch();
  const [userAge, setUserAge] = React.useState("");
  const user = useUser();
  const { userCategory } = user;
  const [gender, setGender] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [usageMetrics, setUsageMetrics] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeUserAge = (event) => {
    setUserAge(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleChangeUserType = (event) => {
    const value = event.target.value;
    setFilters({ ...filters, UserTypeId: value });
  };

  const handleChangeUserPlan = (event) => {
    const value = event.target.value;
    setFilters({ ...filters, UserSubId: value });
  };

  const handleCheckTakenTest = (event) => {
    const value = event.target.checked;
    setFilters({ ...filters, TakenTest: value });
  };

  const handleCheckNotTakenTest = (event) => {
    const value = event.target.checked;
    setFilters({ ...filters, NotTakenTest: value });
  };

  const handleCheckOpenedStudy = (event) => {
    const value = event.target.checked;
    setFilters({ ...filters, OpenedStudy: value });
  };

  const handleChecNotOpenedStudy = (event) => {
    const value = event.target.checked;
    setFilters({ ...filters, NotOpenedStudy: value });
  };

  // const handleChangeUsageMetrics = (event) => {
  //     setUsageMetrics(event.target.value);
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(getUserCategory());
    dispatch(GetSubscriptionModels());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const AgeOptions = ["Under 18", "18 and Above"];

  const GenderOptions = ["Male", "Female"];

  const LocationOption = ["Nigeria", "Others"];

  // console.log(subscriptionType)
  const handleFilterResets = () => {
    handleReset();
    handleClose();
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        startIcon={<FilterListIcon />}
      >
        Filters
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 250, left: 600 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ m: 0, p: 2 }}>Filters</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ px: 4, py: 2 }}
        >
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <p>Date Registered</p>
            <Box>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  htmlFor="demo-customized-textbox"
                  style={{ visibility: "hidden" }}
                >
                  Start Date
                </InputLabel>
                <BootstrapInput
                  id="demo-customized-textbox"
                  placeholder="Start Date"
                />
              </FormControl>
            </Box>

            <Box sx={{ mt: 4 }}>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  htmlFor="demo-customized-textbox"
                  style={{ visibility: "hidden" }}
                >
                  End Date
                </InputLabel>
                <BootstrapInput
                  id="demo-customized-textbox"
                  placeholder="End Date"
                />
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <p>Demographics</p>
            <Box sx={{ mb: 2 }}>
              <CustomSelect
                handleChange={handleChangeUserAge}
                value={userAge}
                BootstrapInput={BootstrapInput}
                SelDefault={"Age"}
                Seloptions={AgeOptions}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <CustomSelect
                handleChange={handleChangeGender}
                value={gender}
                BootstrapInput={BootstrapInput}
                SelDefault={"Gender"}
                Seloptions={GenderOptions}
              />
            </Box>

            <Box>
              <CustomSelect
                handleChange={handleChangeLocation}
                value={location}
                BootstrapInput={BootstrapInput}
                SelDefault={"Location"}
                Seloptions={LocationOption}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <p>User Type</p>
            <Box sx={{ mb: 2 }}>
              <CustomSelect
                handleChange={handleChangeUserType}
                value={filters.UserTypeId}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select User Type"}
                Seloptions={userCategory}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography>User Plan</Typography>
              <CustomSelect
                handleChange={handleChangeUserPlan}
                value={filters.UserSubId}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select Subscription Status"}
                Seloptions={subscriptionType}
              />
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={6} lg={4}>
                        <p>Usage Metrics</p>
                        <Box>
                            <CustomSelect handleChange={handleChangeUsageMetrics} value={usageMetrics} BootstrapInput={BootstrapInput} />
                        </Box>

                        <Box>
                            <FormControl fullWidth variant="standard">
                                <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel>
                                <NativeSelect
                                    id="demo-customized-select-native"
                                    value={age}
                                    onChange={handleChange}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    <option value={10}>Ten</option>
                                    <option value={20}>Twenty</option>
                                    <option value={30}>Thirty</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid> */}

          <Grid item xs={8}>
            <Typography>Engagements</Typography>
            <Grid sx={{ display: "flex" }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleCheckTakenTest}
                  label="Taken Test"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Not taken test"
                  onChange={handleCheckNotTakenTest}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  inputProps={{ "aria-label": "controlled" }}
                  label="opened study"
                  onChange={handleCheckOpenedStudy}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Not opened study"
                  onChange={handleChecNotOpenedStudy}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        {/* </DialogContent> */}
        <Grid
          sx={{ px: 3, py: 2, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            autoFocus
            variant="outlined"
            onClick={handleFilterResets}
            sx={{ mr: 2 }}
          >
            Reset Filters
          </Button>
          <Button autoFocus variant="contained" onClick={handleClose}>
            Submit
          </Button>
        </Grid>
      </Popover>
    </div>
  );
}
