import React from "react";
import { Divider, Paper, Skeleton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { ReactComponent as NoAvgPerf } from "../../../assets/images/NoAvgPerf.svg";
import { ReactComponent as AvgPerfLine } from "../../../assets/images/AvgPerfLine.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E5E5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 6,
    backgroundColor:
      value <= 39 ? "#FA7E77" : value <= 69 ? "#F8EF07" : "#40499D",
  },
}));

const AveragePerformancePerSubject = ({ data, loading }) => {
  return (
    <div>
      <Typography
        variant="h6"
        fontWeight={500}
        sx={{ fontSize: { xs: "14px", sm: "18px" }, color: "#191635" }}
      >
        Average performance on Tests
      </Typography>
      <Box mt={1} overflow="auto">
        {data?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              mt: 14,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NoAvgPerf />
            <h4 style={{ color: "#c0c0c0", fontSize: "14px" }}>
              You haven't taken any test yet!
            </h4>
          </Box>
        ) : Array.isArray(data) && data.length > 0 ? (
          data.map((item, index) => (
            <React.Fragment key={item.subjectName}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                mt={1.5}
                mb={1.5}
                mr={2}
                ml={0.5}
              >
                <Box width={"40%"}>
                  {loading ? (
                    <Skeleton variant="text" width={100} animation="wave" />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                    >
                      {item.subjectName}
                    </Typography>
                  )}
                </Box>
                {loading ? (
                  <Skeleton variant="text" width={100} animation="wave" />
                ) : (
                  <Box sx={{ width: "40%", position: "relative" }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0px",
                        right: "30%",
                        zIndex: 1,
                      }}
                    >
                      <AvgPerfLine />
                    </Box>
                    <BorderLinearProgress
                      variant="determinate"
                      value={item.averageScore}
                    />
                  </Box>
                )}
                <Box>
                  {loading ? (
                    <Skeleton variant="text" width={100} animation="wave" />
                  ) : (
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      color="text.secondary"
                    >
                      {`${Math.round(item.averageScore)}%`}
                    </Typography>
                  )}
                </Box>
              </Box>

              {index < data.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <div
            className="flex justify-center items-center"
            style={{ height: "100%", marginTop: "130px" }}
          >
            <h3 style={{ color: "#c0c0c0", fontStyle: "italic" }}>
              No data available
            </h3>
          </div>
        )}
      </Box>
    </div>
  );
};

export default AveragePerformancePerSubject;
