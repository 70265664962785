import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';

const Support = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, mt: 5 }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              backgroundColor: "rgba(232, 234, 255, 0.1)",
              padding: "16px",
              flex: 1
            }}>
              <Box sx={{ marginBottom: 2 }}>
                <LocalPhoneIcon fontSize="medium" sx={{ color: "#40499D" }} />
              </Box>
              <Typography variant="h6" fontWeight={600} color={"#313131"} textAlign="center">
                09087197603
              </Typography>
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              backgroundColor: "rgba(232, 234, 255, 0.1)",
              padding: "16px",
              flex: 1
            }}>
              <Box sx={{ marginBottom: 2 }}>
                <MailIcon fontSize="medium" sx={{ color: "#40499D" }} />
              </Box>
              <Typography variant="h6" fontWeight={600} color={"#313131"} textAlign="center">
                support@estudy.ng
              </Typography>
            </Box>
          </Box>


        </Paper>

      </Grid>

    </Grid>
  )
}

export default Support