import * as dashboardActions from "./types";
import Axios from "../../connection/defaultClient";
import { googleAnalytics } from "../../hooks/usePageTracking";

export const addSubjectsByProfileId = (payload, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.ADD_SUBJECT_BY_PROFILE_ID,
      payload: true,
    });
    Axios.post(`/Subject/AddSubjectbyProfile`, { ...payload })
      .then((response) => {
        dispatch({
          type: dashboardActions.ADD_SUBJECT_BY_PROFILE_ID_SUCCESS,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.ADD_SUBJECT_BY_PROFILE_ID_ERROR,
          payload: error.message,
        });
      });
  };
};

export const updateSubjectsByProfileId = (payload, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.UPDATE_SUBJECT_BY_PROFILE_ID,
      payload: true,
    });
    Axios.post(`/Subject/AddSubjects`, { ...payload })
      .then((response) => {
        dispatch({
          type: dashboardActions.UPDATE_SUBJECT_BY_PROFILE_ID_SUCCESS,
          payload: response.data,
        });
        googleAnalytics().trackSpecificEvent("updated_study_subjects", {});
        onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.UPDATE_SUBJECT_BY_PROFILE_ID_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchStudentDashboardStat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_STUDENT_DASHBOARD_STAT,
      payload: true,
    });
    Axios.get(`/Profile/GetStudentDashboardStat/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENT_DASHBOARD_STAT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENT_DASHBOARD_STAT_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchStudentAveragePerformanceStat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT,
      payload: true,
    });
    Axios.get(`/Profile/GetStudentAveragePerformanceStat/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENT_AVERAGE_PERFORMANCE_STAT_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchStudiesInProgressStat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_STUDIES_IN_PROGRESS_STAT,
      payload: true,
    });
    Axios.get(`/Profile/GetStudiesInProgressStat/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_STUDIES_IN_PROGRESS_STAT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_STUDIES_IN_PROGRESS_STAT_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchStudyTime = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_STUDY_TIME,
      payload: true,
    });
    Axios.get(`/Studies/GetStudyTime/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_STUDY_TIME_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_STUDY_TIME_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchAdminDashboardStat = () => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_ADMIN_DASHBOARD_STAT,
    });
    Axios.get(`/AdminProfile/AdminDashboardStatistics`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_ADMIN_DASHBOARD_STAT_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_ADMIN_DASHBOARD_STAT_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchAdminDashboardChart = (selectedYear, currency) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_ADMIN_DASHBOARD_CHART,
    });
    Axios.get(
      // `/AdminProfile/AdminDashboardCharts?year=${selectedYear}`
      `/AdminProfile/AdminDashboardCharts?year=${selectedYear}&currency=${currency}`
    )
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_ADMIN_DASHBOARD_CHART_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_ADMIN_DASHBOARD_CHART_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchAdminDashboardRegisterUserChart = (selectedYear) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_ADMIN_DASHBOARD_REG_USER_CHART,
    });
    Axios.get(`/AdminProfile/GetRegisteredUsers?year=${selectedYear}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_ADMIN_DASHBOARD_REG_USER_CHART_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_ADMIN_DASHBOARD_REG_USER_CHART_ERROR,
          payload: error.message,
        });
      });
  };
};

export const loadAdmittedYear = () => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.ALL_AMITTED_YEAR,
    });
    Axios.get(`/AdminProfile/GetYear`)
      .then((response) => {
        dispatch({
          type: dashboardActions.ALL_AMITTED_YEAR_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.ALL_AMITTED_YEAR_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchStudentLearning = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_STUDENT_LEARNING,
      payload: true,
    });
    Axios.get(`/Profile/StudentLearning/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENT_LEARNING_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENT_LEARNING_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchTestDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_TEST_DETAILS,
      payload: true,
    });
    Axios.get(`/Profile/GetTestDetails/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_TEST_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_TEST_DETAILS_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchSchoolDashboardStat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_SCHOOL_DASHBOARD_STAT,
      payload: true,
    });
    Axios.get(`/School/GetSchoolDashboardStat/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_SCHOOL_DASHBOARD_STAT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_SCHOOL_DASHBOARD_STAT_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchTopPerformingStudents = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_TOP_STUDENTS,
      payload: true,
    });
    let url = `/School/GetTopPerformingStudents/${payload.schoolId}`;
    if (payload.path) {
      url += `?pathId=${payload.path}`;
    }
    Axios.get(url)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_TOP_STUDENTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_TOP_STUDENTS_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchStudentsPath = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_STUDENTS_PATH,
      payload: true,
    });
    Axios.get(`/School/StudentsPath/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENTS_PATH_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENTS_PATH_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchSchoolStudyTime = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_SCHOOL_STUDY_TIME,
      payload: true,
    });
    Axios.get(`/School/GetSchoolStudyTime/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_SCHOOL_STUDY_TIME_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_SCHOOL_STUDY_TIME_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchSponsoredStudentStudyTime = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_SPONSOR_STUDENT_STUDY_TIME,
      payload: true,
    });
    Axios.get(`/api/School/GetSponsorStudyTime/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_STUDENT_STUDY_TIME_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_STUDENT_STUDY_TIME_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchStudentsSubjects = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_STUDENTS_SUBJECTS,
      payload: true,
    });
    Axios.get(`/School/GetUserSelectedSubjectsByPath?schoolId=${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENTS_SUBJECTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_STUDENTS_SUBJECTS_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchSponsorBeneficiaries = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_SPONSOR_BENEFICIARIES,
      payload: true,
    });
    Axios.get(`/School/GetSponsoredStudents/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_BENEFICIARIES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_BENEFICIARIES_ERROR,
          payload: error.message,
        });
      });
  };
};
export const fetchAmountDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_AMOUNT_DETAILS,
      payload: true,
    });
    Axios.get(`/School/StudentsPath/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_AMOUNT_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_AMOUNT_DETAILS_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchSponsorDashboardStat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_SPONSOR_DASHBOARD_STAT,
      payload: true,
    });
    Axios.get(`/School/GetSponsorDashboardStat/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_DASHBOARD_STAT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_DASHBOARD_STAT_ERROR,
          payload: error.message,
        });
      });
  };
};

export const fetchSponsorStudentsPath = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_SPONSOR_STUDENTS_PATH,
      payload: true,
    });
    Axios.get(`/School/SponsorStudentsPath/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_STUDENTS_PATH_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_SPONSOR_STUDENTS_PATH_ERROR,
          payload: error.message,
        });
      });
  };
};

//Leaderboard Actions
export const fetchLeaderboardLadder = (payload) => {
  return (dispatch) => {
    dispatch({
      type: dashboardActions.FETCH_LEADERBOARD_LADDER,
      payload: true,
    });
    Axios.get(`/Gamification/Leaderboard/${payload}`)
      .then((response) => {
        dispatch({
          type: dashboardActions.FETCH_LEADERBOARD_LADDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: dashboardActions.FETCH_LEADERBOARD_LADDER_ERROR,
          payload: error.message,
        });
      });
  };
};

// api for weeekly trivia
export const fetchWeeklyTrivia = (
  onSucces = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    Axios.get(`/Gamification/UsersGetWeeklyTrivia`)
      .then((response) => {
        dispatch({
          type: dashboardActions.GET_WEEKLY_TRIVIA,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        on500Fail(error.response);
      });
  };
};
