import { useSelector } from "react-redux";

const useUser = () => {
  const user = useSelector((state) => state.auth);
  // const userCategory = useSelector((state) => state.auth);

  const {
    isLoading,
    loadingLogout,
    isProfileLoading,
    user: userData,
    userCategory,
    userPathByCategory,
    desiredGrade,
    token,
    refresh_token,
    aboutUs,
    onboardUserData,
    onboardLocalUserData,
    countries,
    statesByCountryId,
    sponsorTypes,
  } = user;

  return {
    isLoading,
    loadingLogout,
    isProfileLoading,
    token,
    refresh_token,
    userInfo: userData,
    userCategory,
    userPathByCategory,
    desiredGrade,
    aboutUs,
    onboardUserData,
    onboardLocalUserData,
    countries,
    statesByCountryId,
    sponsorTypes,
  };
};

export default useUser;
