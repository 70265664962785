import { Box, Typography } from '@mui/material'
import React from 'react'
import AccordionExpand from '../students/DashboardOnboarding/AccordionExpand'
import NoItem from "../../../assets/images/noItem.png"
import AvatarWithText from '../../Avatar/AvatarWithText'


const StudentBeneficiaries = ({sponsorBeneficiaries}) => {
  const students= [
    {
      studentName: 'Gold Elizabeth',
      email: "goldabidex@gmail.com",
     
    },
    {
      studentName: 'Ibrahim Doyin',
      email: "ibrahindoyin@gmail.com",
      
    },
    {
      studentName: 'Kunle Abigail',
      email: "kunleabi@gmail.com",
      
    },
    {
      studentName: 'Ibrahim Doyin',
      email: "ibrahindoyin@gmail.com",
     
    },
    {
      studentName: 'Gold Elizabeth',
      email: "goldabidex@gmail.com",
      
    },
  ]
  return (
    <Box bgcolor={"#fff"} borderRadius={"20px"} border={"1px solid #F3F4FE"} sx={{height: "100%", padding:"20px 0px 20px 15px"}}>
        <Box mb={2}>
            <Typography sx={{fontSize:"16px", fontWeight:700, color:"#2B3674"}}>Students Sponsored</Typography>
        </Box>
{(!sponsorBeneficiaries || sponsorBeneficiaries?.length === 0 || !Array.isArray(sponsorBeneficiaries)) ?
        <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-[#A3AED0]" style={{paddingTop:"80px"}}>
                <img src={NoItem} alt="" width={"100px"} height={"auto"}/>
              No student available
            </p>
:
        <Box  height={"25rem"} pr={1.5}
        sx={{
          overflow: "auto",
        }}>
          {sponsorBeneficiaries && Array.isArray(sponsorBeneficiaries) &&
        (sponsorBeneficiaries?.map((item, index) => (
          <Box key={index} sx={{border:"1px solid rgba(145, 158, 171, 0.16)", borderRadius:"14px", padding:"5px 10px", mb: 1}}>
            <AvatarWithText text={item?.FullName} email={item?.Email} />
          </Box>
        )))}
        </Box>
}
    </Box>
  )
}

export default StudentBeneficiaries
