import { useTheme } from "@emotion/react";
import { Box, styled, useMediaQuery } from "@mui/material";
import proceedToTakeTestBg from "../../assets/images/proceedToTakeTestBg.svg";

import React from "react";
const CardStyle = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  background: "#fff",
  boxShadow:
    "0px 4.800000190734863px 13.199999809265137px -1.2000000476837158px rgba(177, 177, 177, 0.25)",
  width: "m%",
  padding: theme.spacing(2.5),
  boxSizing: "border-box",
  maxHeight: "250px",
}));

const DashboardCards = ({ children, customColor }) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.only("md"));
  return (
    <CardStyle
      sx={{
        ...(customColor && {
          bgcolor: (theme) => theme.palette.primary.main,
          backgroundImage: `url('${proceedToTakeTestBg}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center 70px",
        }),
        ...(mediumScreen && {
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }),
      }}
    >
      {children}
    </CardStyle>
  );
};

export default DashboardCards;
