import React, { useMemo, useState, useEffect } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EastIcon from "@mui/icons-material/East";
import { styled, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LogoutIcon from "@mui/icons-material/Logout";
import DailyReminderonb from "./dailyReminder";
import DesiredGrades from "./desiredGrades";
import { Womanonb } from "../../../assest/images/index";
import NewAboutUsOnb from "./NewAboutUsOnb";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import {
  fetchProfileDetailsAction,
  getAboutUs,
  getAllCountries,
  getSponsorTypes,
  getUserPathByCategory,
  loginUserAction,
  logoutUserAction,
  onboardUserAction,
  setOnbDataFromLocalStorage,
} from "../../../redux/auth/authActions";
import OnboardingUser from "./onboardingUser";
import SchoolOnBoarding from "./schoolOnBoarding";
import Path from "./path";
import { useNotifications } from "../../../components/notifications/notifications";
import UserDetailsOnb from "./userDetailsOnb";
import SchoolAboutUs from "./schoolAboutUs";
import SchoolMoreDetials from "./schoolMoreDetials";
import SponsorOnboarding from "./sponsorOnboarding";
import Loader from "../../../components/AuthReuseables/Preloader/Loader";
import { IconButton } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import Preloader from "../../../components/Preloader/Preloader";
import {
  useGoogleAnalytics,
  usePageTracking,
} from "../../../hooks/usePageTracking";
import { getStatesByCountryId } from "../../../redux/settings/settingsActions";
import { getAgeBracket } from "../../../helpers/helper";

const Onboarding = ({}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo, loadingLogout } = useUser();
  const user = useUser();
  const location = useLocation();
  const [continuation, setContinuation] = useState([{ content: <></> }]);
  const { trackPage, trackSpecificEvent } = useGoogleAnalytics();
  const {
    userPathByCategory,
    isLoading,
    countries,
    statesByCountryId,
    sponsorTypes,
    aboutUs,
  } = user;

  const [input, setInput] = useState({
    categoryId: null,
    userPathId: null,
    desiredGradeId: null,
    isNotification: false,
    awarenessId: null,
    countryId: null,
    lgaId: null,
    stateId: null,
    gender: null,
    age: null,
    others: null,
    dateOfBirth: null,
    schoolName: null,
    WAECSchoolID: null,
    schoolAddress: null,
    sponsorTypeId: null,
    title: null,
    firstName: null,
    lastName: null,
    organisationName: null,
    isGiftAnonymous: false,
    profilePic: null,
  });

  useEffect(() => {
    dispatch(getAboutUs());
    dispatch(getAllCountries());
    dispatch(getSponsorTypes());
  }, []);

  useEffect(() => {
    if (input.categoryId) dispatch(getUserPathByCategory(input.categoryId));
  }, [input.categoryId]);

  useEffect(() => {
    if (input.countryId) getStatesByCountryId(input.countryId);
  }, [input.countryId]);

  trackPage();

  const womanOnbgrid = {
    position: "absolute",
    top: 300,
    right: 0,
    border: "0px",
    [theme.breakpoints.up("md")]: {
      right: -20,
      display: "block",
    },

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    // border: 'none',
    color: theme.palette.text.secondary,
  }));

  const [ToastState, setToastState] = useNotifications();

  const [selectedStepper, setSelectedStepper] = React.useState({
    0: null, //userType: "",
    1: null, //userPath, school onboarding
    2: null, //profile us  // about us
    3: null, // knowabout us  // more detials
    4: null, //more detials
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAwareness, setSelectedAwareness] = useState("");
  const userToken = user?.token;

  const email = location?.state?.email;
  const password = location?.state?.password;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const previousStepLabel = steps[activeStep - 1].label;
    updateSelectedStepperValue(
      selectedStepper[previousStepLabel],
      previousStepLabel
    );
  };

  const updateSelectedStepperValue = (value, key) => {
    setSelectedStepper((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const computeDataForAnalytics = useMemo(() => {
    const data = {
      user_Type: selectedUser?.trim(),
      study_Path:
        userPathByCategory?.find((paths) => paths.pathId === input.userPathId)
          ?.pathName ?? "NA",
      gender: input.gender ?? "NA",
      age: input.dateOfBirth
        ? getAgeBracket(input?.dateOfBirth.format("YYYY-MM-DD"))
        : "NA",
      country:
        countries?.find(
          (country) => country?.countryId === Number(input?.countryId)
        )?.countryName ?? "NA",
      state:
        statesByCountryId?.find(
          (state) => state?.stateId === Number(input?.stateId)
        )?.stateName ?? "NA",
      sponsor_Type:
        selectedUser?.trim() !== "Sponsor"
          ? "NA"
          : sponsorTypes?.find(
              (sponsorType) =>
                sponsorType?.SponsorTypeId === input?.sponsorTypeId
            )?.SponsorTypeName,
      gift_anonymously: input?.isGiftAnonymous,
      about_us_channel:
        aboutUs?.find(
          (awareness) => awareness?.AwarenessId === input?.awarenessId
        )?.AwarenessName ?? "NA",
    };
    return data;
  }, [input]);

  const onSuccess = (res) => {
    if (
      user.token &&
      // user?.userInfo?.isOnboardComplete &&
      // user?.userInfo?.isSchoolAdmin
      selectedUser?.replace(/\s/g, "")?.toLowerCase() === "school"
    ) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: res.msg,
      });
      trackSpecificEvent("complete onboarding", computeDataForAnalytics);
      dispatch(fetchProfileDetailsAction(userInfo?.profileId));
      setTimeout(() => {
        navigate("/school/dashboard");
      }, 2000);
    }

    if (
      user.token &&
      // user?.userInfo?.isOnboardComplete &&
      // user?.userInfo?.isSponsor
      selectedUser?.replace(/\s/g, "").toLowerCase() === "sponsor"
    ) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: res.msg,
      });
      dispatch(fetchProfileDetailsAction(userInfo?.profileId));
      setTimeout(() => {
        navigate("/sponsor/dashboard");
      }, 2000);
    }

    if (
      user.token &&
      // user?.userInfo?.isOnboardComplete &&
      // !user?.userInfo?.isSchoolAdmin &&
      // !user?.userInfo?.isSponsor
      selectedUser?.replace(/\s/g, "").toLowerCase() === "student"
    ) {
      setToastState({
        isOpen: true,
        severity: "success",
        message: res.msg,
      });
      // dispatch(loginUserAction({email, password}));
      dispatch(fetchProfileDetailsAction(userInfo?.profileId));
      setTimeout(() => {
        navigate("/dashboard/home");
      }, 2000);
    }
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
  };

  const on500Fail = (error) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: error.statusText,
    });
  };

  useEffect(() => {
    shouldDisableContinueButton();
  }, [input]);

  // api for sending the data for creating student/school
  const handleUserOnboarding = () => {
    dispatch(
      onboardUserAction(
        { input: input, profileId: userInfo?.profileId },
        onSuccess,
        onFail,
        on500Fail
      )
    );
  };

  const handleLogout = () => {
    if (userToken) {
      dispatch(logoutUserAction(userToken));
    }
  };

  useEffect(() => {
    if (!userToken) {
      navigate("/auth/login");
    } else {
      navigate("/onboarding");
    }
  }, [!userToken]);

  const steps = [
    {
      id: 0,
      label: "You are registering as a ...",
      content: (
        <OnboardingUser
          selectedStepper={selectedStepper}
          updateSelectedStepperValue={updateSelectedStepperValue}
          selectedValue={selectedStepper["You are registering as a ..."]}
          input={input}
          setInput={setInput}
          setSelectedUser={setSelectedUser}
        />
      ),
    },
    {
      id: 1,
      label: "Choose your path, area or major",
      content:
        selectedStepper[0]?.replace(/\s/g, "").toLowerCase() == "student" ? (
          <Path input={input} setInput={setInput} />
        ) : selectedStepper[0]?.replace(/\s/g, "").toLowerCase() == "school" ? (
          <SchoolOnBoarding input={input} setInput={setInput} />
        ) : (
          <SponsorOnboarding input={input} setInput={setInput} />
        ),
    },
    {
      id: 2,
      label: "User Details...",
      content:
        selectedStepper[0]?.replace(/\s/g, "").toLowerCase() == "student" ? (
          <UserDetailsOnb
            handleNext={handleNext}
            input={input}
            setInput={setInput}
          />
        ) : (
          <SchoolAboutUs input={input} setInput={setInput} />
        ),
    },
    {
      id: 3,
      label: "How did you hear about us",
      content:
        selectedStepper[0]?.replace(/\s/g, "").toLowerCase() == "student" ? (
          <NewAboutUsOnb
            input={input}
            setInput={setInput}
            handleNext={handleNext}
            setSelectedAwareness={setSelectedAwareness}
          />
        ) : input.awarenessId == "acc7f01a-120c-4e11-ba89-60f9eba3a031" ? (
          <SchoolMoreDetials input={input} setInput={setInput} />
        ) : null,
    },
    {
      id: 4,
      label: "More Details",
      content:
        selectedStepper[0]?.replace(/\s/g, "").toLowerCase() == "student" &&
        input.awarenessId == "acc7f01a-120c-4e11-ba89-60f9eba3a031" ? (
          <SchoolMoreDetials input={input} setInput={setInput} />
        ) : null,
    },
  ];

  //condition to ensure all fields are inputed before proceeding to the next phase
  const shouldDisableContinueButton = () => {
    switch (activeStep) {
      case 0:
        // Check if the user type is selected
        return selectedStepper[0] === null || selectedStepper[0] === undefined;
      case 1:
        // if (
        //   selectedStepper[0]?.replace(/\s/g, "").toLowerCase() === "student"
        // ) {
        //   // For student, check if the required path is selected
        //   return input.userPathId;
        // } else
        if (selectedStepper[0]?.replace(/\s/g, "").toLowerCase() === "school") {
          // For school, check if the required fields are filled (modify as needed)
          if (input.countryId === 13) {
            // For Nigeria (country ID 13)
            return (
              !input.countryId ||
              !input.stateId ||
              !input.lgaId ||
              !input.WAECSchoolID ||
              !input.schoolAddress ||
              !input.countryId === 0 ||
              input.stateId === 0 ||
              input.WAECSchoolID === 0 ||
              input.lgaId === 0
            );
          } else {
            return (
              !input.countryId ||
              !input.stateId ||
              !input.schoolName ||
              !input.schoolAddress ||
              input.stateId === 0 ||
              input.countryId === 0
            );
          }
        } else if (
          selectedStepper[0]?.replace(/\s/g, "").toLowerCase() === "sponsor" &&
          (input.sponsorTypeId == "" || input.sponsorTypeId == null)
        ) {
          return true;
        } else if (
          selectedStepper[0]?.replace(/\s/g, "").toLowerCase() === "sponsor" &&
          input.sponsorTypeId == "1"
        ) {
          // For sponsors, check if the required fields are filled
          return (
            !input.sponsorTypeId,
            !input.title,
            !input.firstName,
            !input.lastName
          );
        } else if (
          selectedStepper[0]?.replace(/\s/g, "").toLowerCase() === "sponsor" &&
          input.sponsorTypeId == "2"
        ) {
          return !input.sponsorTypeId, !input.organisationName;
        }
        return false;
      case 2:
        if (
          selectedStepper[0]?.replace(/\s/g, "").toLowerCase() === "student"
        ) {
          // For student, check if the required path is selected
          return (
            !input.dateOfBirth, !input.countryId, !input.gender, !input.stateId
          );
        } else if (
          selectedStepper[0]?.replace(/\s/g, "").toLowerCase() === "school"
        ) {
          // For school ans student, check if the awareness field is empty
          return !input.awarenessId;
        }
        // Adjust these conditions based on your requirements
        return false;
      // Add more cases for other steps if needed
      default:
        return false;
    }
  };

  return (
    <>
      {loadingLogout ? (
        <Preloader />
      ) : (
        <>
          <div className="mx-auto">
            <div className="mx-auto flex justify-center items-center max-w-[60%] mt-8">
              {activeStep === 0 ? null : (
                <IconButton
                  size="small"
                  onClick={handleBack}
                  sx={{ mr: 2 }}
                  // disabled={activeStep === 0}
                >
                  <WestIcon />
                </IconButton>
              )}
              {selectedStepper[0]?.replace(/\s/g, "").toLowerCase() ==
              "student" ? (
                <div className="w-full h-4 rounded-full e-study-bg-rectangle-gray">
                  <div
                    className="h-full rounded-full e-study-bg-primary"
                    style={{
                      width: `${
                        input.awarenessId ==
                        "acc7f01a-120c-4e11-ba89-60f9eba3a031"
                          ? activeStep * 25
                          : activeStep * 33.33
                      }%`,
                    }}
                  ></div>
                </div>
              ) : (
                <div className="w-full h-4 rounded-full e-study-bg-rectangle-gray">
                  <div
                    className="h-full rounded-full e-study-bg-primary"
                    style={{
                      width: `${
                        // activestepp 33.33  is when the user selects other then the bar reduces else it becomes full
                        input.awarenessId ==
                        "acc7f01a-120c-4e11-ba89-60f9eba3a031"
                          ? activeStep * 33.33
                          : activeStep * 50
                      }%`,
                    }}
                  ></div>
                </div>
              )}
              <Button
                endIcon={<LogoutIcon />}
                sx={{
                  textTransform: "capitalize",
                  fontSize: "18px",
                  ml: 2,
                  paddingX: "30px",
                  "&:hover": { color: "#E94848" },
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
          {/* <BordPage /> */}
          <Box>
            {steps[activeStep]?.content === null
              ? ""
              : steps[activeStep].content}
          </Box>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            {activeStep === 0 ? (
              <Button
                size="small"
                variant="contained"
                color="brandPrimary"
                style={{
                  width: "900px",
                  height: "52px",
                  // backgroundColor: "#E5E5E5",
                  // fontColor: "#AFAFAF",
                }}
                onClick={handleNext}
                // disabled={activeStep === 5 || buttonDisable}
                disabled={
                  selectedStepper[activeStep] === null ||
                  selectedStepper[activeStep] === undefined
                }
              >
                Continue
                {theme.direction === "rtl" ? <ArrowBackIcon /> : <EastIcon />}
              </Button>
            ) : selectedStepper[0]?.replace(/\s/g, "").toLowerCase() ==
                "student" &&
              input.awarenessId !== "acc7f01a-120c-4e11-ba89-60f9eba3a031" &&
              steps[activeStep].id === 3 ? (
              <Button
                size="small"
                variant="contained"
                style={{
                  width: "660px",
                  height: "52px",
                  backgroundColor: shouldDisableContinueButton()
                    ? "#e0e0e0"
                    : "#40499D",
                  fontColor: "#AFAFAF",
                }}
                onClick={handleUserOnboarding}
                // disabled={activeStep === 5}
                disabled={shouldDisableContinueButton()}
              >
                Continue
                {theme.direction === "rtl" ? <ArrowBackIcon /> : <EastIcon />}
              </Button>
            ) : (selectedStepper[0]?.replace(/\s/g, "").toLowerCase() ==
                "school" ||
                selectedStepper[0]?.replace(/\s/g, "").toLowerCase() ==
                  "sponsor") &&
              input.awarenessId !== "acc7f01a-120c-4e11-ba89-60f9eba3a031" &&
              steps[activeStep].id === 2 ? (
              <Button
                size="small"
                variant="contained"
                style={{
                  width: "660px",
                  height: "52px",
                  backgroundColor: shouldDisableContinueButton()
                    ? "#e0e0e0"
                    : "#40499D",
                  fontColor: "#AFAFAF",
                }}
                onClick={handleUserOnboarding}
                // disabled={activeStep === 5}
                disabled={shouldDisableContinueButton()}
              >
                Continue
                {theme.direction === "rtl" ? <ArrowBackIcon /> : <EastIcon />}
              </Button>
            ) : (selectedStepper[0]?.replace(/\s/g, "").toLowerCase() ==
                "school" ||
                selectedStepper[0]?.replace(/\s/g, "").toLowerCase() ==
                  "sponsor") &&
              steps[activeStep].id === 3 ? (
              <Button
                size="small"
                variant="contained"
                style={{
                  width: "660px",
                  height: "52px",
                  // backgroundColor: "#40499D",
                  backgroundColor: shouldDisableContinueButton()
                    ? "#e0e0e0"
                    : "#40499D",
                  fontColor: "#AFAFAF",
                }}
                onClick={handleUserOnboarding}
                disabled={shouldDisableContinueButton()}
                // disabled={activeStep === 5}
              >
                Continue
                {theme.direction === "rtl" ? <ArrowBackIcon /> : <EastIcon />}
              </Button>
            ) : selectedStepper[0]?.replace(/\s/g, "").toLowerCase() ==
                "student" &&
              input.awarenessId == "acc7f01a-120c-4e11-ba89-60f9eba3a031" &&
              steps[activeStep].id === 4 ? (
              <Button
                size="small"
                variant="contained"
                style={{
                  width: "660px",
                  height: "52px",
                  backgroundColor: shouldDisableContinueButton()
                    ? "#e0e0e0"
                    : "#40499D",
                  fontColor: "#AFAFAF",
                }}
                onClick={handleUserOnboarding}
                // disabled={activeStep === 5}
                disabled={shouldDisableContinueButton()}
              >
                Continue
                {theme.direction === "rtl" ? <ArrowBackIcon /> : <EastIcon />}
              </Button>
            ) : (
              // ) : steps[activeStep].id === 5 ? (
              //   <Button
              //     size="small"
              //     variant="contained"
              //     style={{
              //       width: "660px",
              //       height: "52px",
              //       backgroundColor: "#40499D",
              //       fontColor: "#AFAFAF",
              //     }}
              //     onClick={handleUserOnboarding}
              //     // disabled={activeStep === 5}
              //   >
              //     Continue
              //     {theme.direction === "rtl" ? <ArrowBackIcon /> : <EastIcon />}
              //   </Button>
              <Button
                size="small"
                variant="contained"
                style={{
                  width: "660px",
                  height: "52px",
                  backgroundColor: shouldDisableContinueButton()
                    ? "#e0e0e0"
                    : "#40499D",
                  fontColor: "#AFAFAF",
                }}
                onClick={handleNext}
                disabled={shouldDisableContinueButton()}
                // disabled={activeStep === 5}
              >
                Continue
                {theme.direction === "rtl" ? <ArrowBackIcon /> : <EastIcon />}
              </Button>
            )}
          </div>
          {/* {steps[activeStep].id === 2 ? (
        <Grid
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={womanOnbgrid}
        >
        
          <img src={Womanonb} alt="Womanonb" />
         
        </Grid>
      ) : (
        ""
      )} */}
          <ToastState />
        </>
      )}
    </>
  );
};

export default Onboarding;
