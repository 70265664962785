import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../hooks/useUser";
import {
  fetchAddStudent,
  fetchAddStudentBySponsorId,
  fetchGetStudent,
  fetchGetStudentBySponsorId,
  fetchGetSchoolSponsorsBySchoolId,
} from "../../redux/school";
import {
  fetchSchoolSubscriptions,
  fetchSchoolSubscriptionsBySponsorId,
  fetchSponsorSubscriptions,
} from "../../redux/subscriptions";
import Axios from "../../connection/defaultClient";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import { PlusIcon } from "../../assets/icons/Icons";
import useSubscription from "../../hooks/useSubscription";
import useSchool from "../../hooks/useSchool";
import { formatDate2 } from "../../helpers/helper";

const inputField = ["First Name", "Middle Name", "Last Name", "Email Address"];

const path = ["Science", "Art", "Commercial"];

const gender = ["Male", "Female"];

const SingleStudentForm = ({ level, closeModal, setToastState }) => {
  const dispatch = useDispatch();
  const { userInfo } = useUser();
  const { schoolId, sponsorId } = userInfo;
  const subscription = useSubscription();
  const school = useSchool();
  const { allSponsorsBySchool } = school;
  const { schoolSub, schoolSponsorSubscriptions, sponsorSub } = subscription;
  const [selectedSponsor, setSelectedSponsor] = useState("");
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] =
    useState("school");
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initialFormValues = {
    firstName: "",
    surName: "",
    emailAddress: "",
    gender: " ",
    phoneNumber: "",
    userPathId: "",
    level: "",
    isSponsored: false,
    sponsor: "",
    studentClassLevelId: "",
  };

  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [isEmailValid, setIsEmailValid] = useState(true);


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == 'emailAddress') {
      setIsEmailValid(emailRegex.test(value));
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const onSuccess = (res) => {
    closeModal(true);
    if (sponsorId) {
      dispatch(fetchGetStudentBySponsorId(sponsorId));
    }
    setToastState({
      isOpen: true,
      severity: "success",
      message: res.msg,
    });
    setTimeout(() => {}, 1000);
  };

  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
    setTimeout(() => {
      // navigate('/dashboard/home')
    }, 3000);
    closeModal(true);
    // window.location.reload();
  };

  useEffect(() => {
    dispatch(fetchSponsorSubscriptions(sponsorId));
  }, []);

  const onSelectChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedSponsor(selectedValue);
    const sponsorId = selectedValue;

    dispatch(fetchSchoolSubscriptionsBySponsorId(schoolId, sponsorId));
  };

  //funciton to add student by school and sponsor
  const handleAddSingleStudent = () => {
    setIsLoading(true);
    const payload = {
      firstName: formValues.firstName,
      surname: formValues.surName,
      email: formValues.emailAddress,
      gender: formValues.gender,
      phoneNumber: formValues.phoneNumber,
      isVerified: false,
    };
    if (userInfo?.schoolId !== null) {
      dispatch(
        fetchAddStudent(
          userInfo?.schoolId,
          selectedSponsor,
          selectedSubscriptionId,
          payload,
          onSuccess,
          onFail
        )
      );
      dispatch(fetchGetStudent(schoolId));
    } else {
      dispatch(
        fetchAddStudent(
          userInfo?.schoolId,
          userInfo?.sponsorId,
          selectedSubscriptionId,
          payload,
          onSuccess,
          onFail
        )
      );
      dispatch(fetchGetStudentBySponsorId(sponsorId));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChange = (event) => {
    setSelectedSubscriptionOption(event.target.value);
    dispatch(fetchGetSchoolSponsorsBySchoolId(userInfo?.schoolId));
  };

  const handleSubscriptionChange = (event) => {
    setSelectedSubscriptionId(event.target.value);
  };

  return (
    <>
      <div className="bg-white rounded-2xl ">
        <div className="pb-6 px-12">
          <p className="text-lg text-slate-500">Student's Details</p>
          <p>All fields marked (*) are required</p>
        </div>

        <div className="flex flex-col gap-[24px] px-12 mb-8">
          <TextField
            id="outlined-basic"
            variant="outlined"
            //  placeholder="First Name"
            label="First Name"
            size="small"
            name="firstName"
            value={formValues.firstName}
            onChange={handleChange}
            disabled={isLoading}
            type="text"
            required
          />
        </div>
        <div className="flex flex-col gap-[24px] px-12 mb-8">
          <TextField
            id="outlined-basic"
            variant="outlined"
            // placeholder="Surname"
            label="Surname"
            size="small"
            name="surName"
            value={formValues.surName}
            onChange={handleChange}
            disabled={isLoading}
            type="text"
            required
          />
        </div>
        {/* <div className="flex flex-col gap-[24px] px-12 mb-8">
          <TextField
            id="outlined-basic"
            variant="outlined"
            // placeholder="OtherName"
            label="Other Name"
            size="small"
            name="otherName"
            value={formValues.otherName}
            onChange={handleChange}
            disabled={isLoading}
          />

        </div> */}
        <div className="flex flex-col gap-[24px] px-12 mb-8">
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Email Address"
            size="small"
            name="emailAddress"
            value={formValues.emailAddress}
            onChange={handleChange}
            disabled={isLoading}
            type="email"
            required
            helperText={isEmailValid ? "" : "Please provide a valid email"}
            error={!isEmailValid}
          />
        </div>

        {/* <div className="flex items-center gap-8 pb-6 px-12 w-full">

          <div className="flex flex-row gap-12">
            {gender.map((value, index) => (
              <div key={index} className="flex items-center gap-4">
                <input
                  name="gender"
                  id={`gender-${index}`}
                  onChange={handleInputChange}
                  checked={formValues.gender === value}
                  className="peer w-[24px] h-[24px]"
                  type="radio"
                  value={value} // Set the value of the radio button to the gender value
                  disabled={isLoading}
                />
                <label htmlFor={`gender-${index}`} className="peer-checked/draft:text-[#40499d]">
                  {value}
                </label>
              </div>
            ))}
          </div>
        </div> */}

        {/* <div className="flex items-center gap-8 pb-6 px-12 w-full">
          <TextField
            type="number"
            variant="outlined"
            placeholder="Phone Number"
            id="phoneNumber"
            size="small"
            value={formValues.phoneNumber}
            onChange={handleChange}
            name="phoneNumber"
            className="w-1/2 h-[46px]  rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
            disabled={isLoading}
          />

          <select
            name="level"
            id="level"
            variant="outlined"
            value={formValues.level}
            onChange={handleInputChange}
            className="w-1/2 h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
            disabled={isLoading}
            required
          >
            <option value="">Select Class*</option>
            {level?.map((option, i) => (

              <option key={i.StudentClassLevelId} value={option.StudentClassLevelId}>
                {option.LevelName}
              </option>
            ))}
          </select>
        </div> */}

        {userInfo?.schoolId == null ? (
          <div className="flex items-center gap-8 pb-6 px-12 w-full">
            <select
              name="subscription"
              id="subscription"
              className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4  border border-black"
              onChange={handleSubscriptionChange}
              value={selectedSubscriptionId}
              disabled={isLoading}
            >
              <option value="">Active Subscription Packages*</option>
              {sponsorSub?.allSubsData && sponsorSub?.allSubsData.length > 0 ? (
                sponsorSub?.allSubsData
                  ?.filter(
                    (subscription) =>
                      subscription.SubscriptionStatus === "Active" &&
                      subscription.UnAllocatedLicenses > 0
                  )
                  .map((subscription, index) => (
                    <option key={index} value={subscription.SubscriptionId}>
                      {`${subscription.SubscriptionName} (${subscription.UnAllocatedLicenses} Slots Left)`}
                    </option>
                  ))
              ) : (
                <option value="">No Active Subscriptions</option>
              )}
            </select>
          </div>
        ) : (
          <>
            <div>
              <div className="mb-5">
                <RadioGroup
                  name="options"
                  value={selectedSubscriptionOption}
                  onChange={handleRadioChange}
                  row
                  sx={{ paddingLeft: "1.7rem" }}
                >
                  <FormControlLabel
                    value="school"
                    control={<Radio size="medium" disabled={isLoading} />}
                    label="School Subscriptions"
                    style={{ marginLeft: 8 }}
                  />
                  <FormControlLabel
                    value="sponsor"
                    control={<Radio size="medium" disabled={isLoading} />}
                    label="Sponsors' Subscriptions"
                    style={{ marginLeft: 8 }}
                  />
                </RadioGroup>
              </div>
              {selectedSubscriptionOption === "sponsor" && (
                <>
                  <div className="flex items-center gap-8 pb-6 px-12 w-full">
                    <select
                      name="sponsor"
                      id="sponsor"
                      className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
                      value={selectedSponsor}
                      onChange={onSelectChange}
                      disabled={isLoading}
                    >
                      <option value="">Select Sponsor</option>
                      {allSponsorsBySchool?.length === 0 ? (
                        <option value="" disabled>
                          No sponsors
                        </option>
                      ) : (
                        allSponsorsBySchool?.map((sponsor, index) => (
                          <option key={index} value={sponsor.SponsorId}>
                            {sponsor.SponsorFullName}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  {selectedSponsor && (
                    <div className="flex items-center gap-8 pb-6 px-12 w-full">
                      <select
                        name="subscription"
                        id="subscription"
                        className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
                        value={selectedSubscriptionId}
                        onChange={handleSubscriptionChange}
                        disabled={isLoading}
                      >
                        <option value="">Active Subscription Packages*</option>
                        {schoolSponsorSubscriptions &&
                        schoolSponsorSubscriptions.length > 0 ? (
                          schoolSponsorSubscriptions
                            ?.filter(
                              (subscription) =>
                                subscription.SubscriptionStatus === "Active" &&
                                subscription.SlotsLeft > 0
                            )
                            .map((subscription, index) => (
                              <option
                                key={index}
                                value={
                                  subscription.SlotsLeft < 1
                                    ? ""
                                    : subscription.subscriptionId
                                }
                                disabled={subscription.SlotsLeft === 0}
                              >
                                {subscription.SlotsLeft < 1
                                  ? "0 Slots Left"
                                  : `${subscription.SubscriptionName} (${subscription.SlotsLeft} Slots Left)`}
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Active Subscriptions
                          </option>
                        )}
                      </select>
                    </div>
                  )}
                </>
              )}
              {selectedSubscriptionOption === "school" && (
                <div className="flex items-center gap-8 pb-6 px-12 w-full">
                  {/* <select
                    name="subscription"
                    id="subscription"
                    className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
                    onChange={handleSubscriptionChange}
                    value={selectedSubscriptionId}
                    disabled={isLoading}
                  >
                    <option value="">Active Subscription Packages*</option>
                    {schoolSub?.allSubsData?.IsSchoolSponsored === false && schoolSub?.allSubsData?.length > 0 ? (
                      schoolSub?.allSubsData
                        .filter(
                          subscription =>
                            subscription.IsSchoolSponsored === false &&
                            subscription.SubscriptionStatus === "Active" &&
                            subscription.UnAllocatedLicenses > 0

                        )
                        .map((subscription, index) => (
                          <option key={index} value={subscription.SubscriptionId}>
                            {`${subscription.SubscriptionName} (${subscription.UnAllocatedLicenses} Slots Left)`}
                          </option>
                        ))
                    ) : (
                      <option value="">No Active Subscriptions</option>
                    )}
                  </select> */}
                  <select
                    name="subscription"
                    id="subscription"
                    className="min-w-full h-[46px] bg-[#fff] rounded-[10px] font-normal text-[#3d3d3d] text-base px-4 border border-black"
                    onChange={handleSubscriptionChange}
                    value={selectedSubscriptionId}
                    disabled={isLoading}
                  >
                    <option value="">Active Subscription Packages*</option>
                    {schoolSub?.allSubsData?.length > 0 ? (
                      schoolSub?.allSubsData
                        .filter(
                          (subscription) =>
                            subscription.IsSchoolSponsored === false &&
                            subscription.SubscriptionStatus === "Active" &&
                            subscription.UnAllocatedLicenses > 0
                        )
                        .map((subscription, index) => (
                          <option
                            key={index}
                            value={subscription.SubscriptionId}
                          >
                            {`${
                              subscription.SubscriptionName +
                              " " +
                              formatDate2(subscription?.trans_Date) +
                              " - " +
                              formatDate2(subscription?.expiryDate)
                            } (${subscription.UnAllocatedLicenses} Slots Left)`}
                          </option>
                        ))
                    ) : (
                      <option value="">No Active Subscriptions</option>
                    )}
                  </select>
                </div>
              )}
            </div>
          </>
        )}

        <div className="grid justify-items-end px-12 w-full mb-10">
          <button
            className={`rounded-[6px] bg-[#40499D] px-[26px] py-4 text-[12px] text-white text-sm ${
              !formValues.emailAddress || !isEmailValid || isLoading
                || !selectedSubscriptionId 
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={handleAddSingleStudent}
            disabled={
              !formValues.firstName ||
              !formValues.surName ||
              !formValues.emailAddress ||
              !isEmailValid ||
              // !formValues.level ||
              !selectedSubscriptionId ||
              isLoading
            }
          >
            {!isLoading ? (
              <>
                <PlusIcon size={15} className="mr-2" />{" "}
                {/* Adjust the margin as needed */}
                Enrol
              </>
            ) : (
              <DataUsageIcon size={24} className="animate-spin ml-4" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default SingleStudentForm;
