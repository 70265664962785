import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Grid, Typography, Box, Tabs, Tab, OutlinedInput,
  InputAdornment, IconButton, TableContainer, Table, TableBody,
  TableRow, TableCell, Checkbox, TablePagination, Menu, MenuItem,
  Toolbar, TableHead, TableSortLabel, Button, Card, CardHeader, Dialog, CardContent, ListItem, FormControl,
  MenuList, ListItemIcon, ListItemText, DialogTitle, TextField, DialogContent, Paper, Step, Stepper,
  StepButton, Switch, FormControlLabel, FormGroup, DialogActions
} from '@mui/material';
import { Close, DataUsage, KeyboardBackspace } from '@mui/icons-material';
import { arrayEquals } from '../../../helpers/helper';
import { styled } from "@mui/material/styles";
import { useDispatch } from 'react-redux';
import usePermissions from '../../../hooks/usePermissions';
import { addRole, getAdminSitePagesByRoleId, getAllAdminRoles, getAllSitePagesByRoleId, updateRole, updateRolePermissions } from '../../../redux/permissions/permissionsActions';
import useAdminUser from '../../../hooks/useAdminUser';
import { useNotifications } from '../../../components/notifications/notifications';
import Loader from '../../../components/AuthReuseables/Preloader/Loader';


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      left: '6px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#40499d',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 14,
    height: 14,
    borderRadius: 9,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const Step2 = ({ selectAllCheckbox, selectedCheckbox, setSelectAllCheckbox, setSelectedCheckbox, handleSelectAllCheckbox, sitePages }) => {

  const updateSelectedCheckbox = (event, item) => {
    if (event.target.checked) {
      setSelectedCheckbox((prevSelectedItems) => [...prevSelectedItems, item?.PageId]);
      const oldList = [...selectedCheckbox];
      oldList.push(item?.PageId);
      oldList.sort()

      let pages_ids = sitePages?.map((page) => page?.PageId).sort()
      if (arrayEquals(pages_ids, oldList)) {
        setSelectAllCheckbox(true)
      } else {
        setSelectAllCheckbox(false)
      }
    } else {
      setSelectedCheckbox((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item?.PageId)
      );
      setSelectAllCheckbox(false)
    }
  };

  return (
    <div className='mt-1'>
      <Card>

        <CardContent className='border border-solid border-gray-50'>

          <div className='py-3'>
            <h3 className='e-study-heading-four'>Set Permissions</h3>
            <p className='e-study-text-grey'>Modify what individuals on this role can do</p>
            <hr />
          </div>

          <Paper sx={{ mt: 2 }} variant="outlined">

            <Grid sx={{ mt: 3 }} container spacing={1}>
              <Grid item xs={6} className='pl-3'>
                <h3 className='pl-4'> Admin Access</h3>
              </Grid>
              <Grid item xs={6} className='flex justify-end'>
                <FormControlLabel
                  className='space-x-3'
                  control={
                    <AntSwitch name="enable_all"
                      onChange={handleSelectAllCheckbox}
                      checked={selectAllCheckbox}
                      className='pl-2'
                    />
                  }
                  label="Enable All"
                />
              </Grid>
            </Grid>

            <Grid sx={{ mt: 3 }} container spacing={1}>
              {sitePages.map((page) => (
                <Grid item xs={6} key={page.PageId}>

                  <Grid container spacing={1} className='px-6 mb-3'>
                    <Grid item xs={8} className='mx-auto'>
                      <FormGroup>
                        <FormControlLabel className='space-x-3 pb-6 border-b border-gray-200' control={<AntSwitch
                          onChange={(event) => updateSelectedCheckbox(event, page)}
                          checked={selectedCheckbox.includes(page?.PageId)}
                        />} label={`Access to ${page.PageName}`} />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

          </Paper>
        </CardContent>
      </Card>
    </div>
  )
};

const RolePermissionDialog = ({ openRolePermissionDialog, handleClosePage, selectedRowRole, setToastState }) => {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const dispatch = useDispatch();
  const { pagesLoading, sitePages, roleSitePages2, loading } = usePermissions();
  const { userInfo } = useAdminUser();


  useEffect(() => {
    dispatch(getAdminSitePagesByRoleId(selectedRowRole.roleId))
  }, []);

  useEffect(() => {
    if (roleSitePages2?.length > 0) {
      setSelectedCheckbox(roleSitePages2.filter((page) => page.Status === true).map((pages) => pages?.PageId))
      let pages_ids = sitePages?.map((page) => page?.PageId).sort()
      let activePage_ids = roleSitePages2?.filter((page) => page.Status === true)?.map((pages) => pages?.PageId).sort()
      if (arrayEquals(pages_ids, activePage_ids)) {
        setSelectAllCheckbox(true)
      } else {
        setSelectAllCheckbox(false)
      }
    }

  }, [roleSitePages2, sitePages]);


  const handleSelectAllCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedCheckbox(sitePages?.map((page) => page?.PageId));
      setSelectAllCheckbox(true);
    } else {
      setSelectedCheckbox([]);
      setSelectAllCheckbox(false);
    }
  };


  const onFail = (res) => {
    setToastState({ isOpen: true, severity: "warning", message: res });
  }


  const onUpdatePermissionsSuccess = (res) => {
    dispatch(getAllAdminRoles())
    dispatch(getAllSitePagesByRoleId(userInfo?.data?.roleId))
    setSelectAllCheckbox(false);
    handleClosePage();
    setToastState({ isOpen: true, severity: "success", message: "Permission assigned to role successfully" });
  }

  const handleUpdateRolePermissions = () => {
    const data = {
      adminUserId: userInfo?.data?.profileId,
      roleId: selectedRowRole.roleId,
      pageIds: selectedCheckbox.map((sel) => ({ pageId: sel }))
    }
    dispatch(updateRolePermissions(data, onUpdatePermissionsSuccess, onFail))
  }


  return (
    <Dialog
      open={openRolePermissionDialog}
      onClose={handleClosePage}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle
        id="alert-dialog-title"
      >
        <Typography sx={{
          color: '#313131',
          fontSize: '24px', fontWeight: 600,
          lineHeight: '16px'
        }}>Update Permission for {selectedRowRole.roleName}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClosePage}
          style={{
            position: "absolute",
            top: "1px",
            right: "1px",
            justifyContent: "flex-end"
          }}

        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {pagesLoading ? <Loader /> : (
          <>
            <Box padding={'5px'}>
              <Step2 selectAllCheckbox={selectAllCheckbox} setSelectAllCheckbox={setSelectAllCheckbox} selectedCheckbox={selectedCheckbox} setSelectedCheckbox={setSelectedCheckbox} handleSelectAllCheckbox={handleSelectAllCheckbox} sitePages={sitePages} />
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12} className='flex justify-center mx-auto'>
                {!loading ? (
                  <button className="btn px-8" style={{ backgroundColor: '#40499D', color: '#fff', borderRadius: '5px', padding: '8px 10px', fontSize: '15px', fontWeight: 400 }} onClick={() => { handleUpdateRolePermissions() }} sx={{ cursor: 'pointer' }} > Update</button>
                ) : (
                  <DataUsage size={24} className="animate-spin ml-4" />
                )}
              </Grid>
            </Grid>
          </>
        )}

      </DialogContent>
      {/* <DialogActions>
        <div className='mt-5'>
          <Grid container spacing={1}>
            <Grid item xs={12} className='flex justify-center mx-auto'>
              <button className="btn px-5 ml-5" style={{ backgroundColor: '#40499D', color: '#fff', borderRadius: '5px', padding: '8px 10px', fontSize: '15px', fontWeight: 400 }} onClick={() => { setCompleted(true); handleUpdateRolePermissions() }} sx={{ cursor: 'pointer' }} > Save</button>
            </Grid>
          </Grid>
        </div>
      </DialogActions> */}
    </Dialog>

  )
}

export default RolePermissionDialog;