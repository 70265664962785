import * as testActions from "./types";
import { TestData } from "../../TestDummy";

const InitialState = {
  subjectsData: [],
  subjectLoaded: false,
  subjectLoading: false,
  subjectExams: [],
  subjectExamsLoaded: false,
  testData: {}, // temp - maybe for the questions from the api
  currentQuestion: 0,
  testName: "",
  allocatedTime: TestData().timeAllocated, // either in seconds or in minutes
  questions: [
    {
      id: 0,
      questionNumber: "",
      question: "",
      image: null,
      flagged: false,
      options: [
        // to be renamed choices
        {
          id: 0,
          optionValue: "",
          selected: false,
        },
      ],
    },
  ],
  // questions: TestData().questions,
  isLoading: false,
  isLoaded: false,
  error: null,
  isTestActive: false,
  submitData: {},
  totalScore: 0,
  examResults: [],
  statsLoaded: false,
  statsData: {},
  examWithHighestLoaded: false,
  examWithHighestLoading: false,
  examWithHighestScore: [],
  activeSection: null,
};

export function testReducer(state = InitialState, action) {
  switch (action.type) {
    case testActions.FETCH_SUBJECT_BY_PROFILE_ID:
      return {
        ...state,
        subjectLoading: true,
        subjectsData: [],
        subjectLoaded: false,
      };
    case testActions.FETCH_SUBJECT_BY_PROFILE_ID_SUCCESS:
      return {
        ...state,
        subjectLoading: false,
        subjectsData: action?.payload,
        subjectLoaded: true,
      };
    case testActions.FETCH_SUBJECT_BY_PROFILE_ID_ERROR:
      return {
        ...state,
        subjectLoading: false,
        subjectsData: action?.payload,
        subjectLoaded: false,
      };
    case testActions.FETCH_EXAM_BY_SUBJECT_ID:
      return {
        ...state,
        subjectExams: [],
        subjectExamsLoaded: false,
      };
    case testActions.FETCH_EXAM_BY_SUBJECT_ID_SUCCESS:
      return {
        ...state,
        subjectExams: action.payload,
        subjectExamsLoaded: true,
      };
    case testActions.FETCH_EXAM_BY_SUBJECT_ID_ERROR:
      return {
        ...state,
        subjectExams: action.payload,
        subjectExamsLoaded: false,
      };
    case testActions.RESET_EXAM_BY_SUBJECT_ID:
      return {
        ...state,
        subjectExams: [],
        subjectExamsLoaded: false,
      };
    case testActions.LOADING_EXAM:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case testActions.LOADING_EXAM_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isLoaded: false,
      };
    case testActions.SET_ALL_EXAM_QUESTION_ID:
      return {
        ...state,
        isLoading: false,
        allocatedTime: action.payload[0].data.examTime,
        isLoaded: true,
        testName: action.payload[1].testname, //testname property is a placeholder for the actual name
        questions: [
          ...action.payload[0].data.testQuestion.map((question) => {
            return {
              id: question.examQuestionId,
              questionNumber: question.questionNumber,
            };
          }),
        ],
      };

    case testActions.UPDATE_EXAM_COUNTER:
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case testActions.UPDATE_TIMER:
      return {
        ...state,
        allocatedTime: action.payload,
      };
    case testActions.FLAG_QUESTION:
      return {
        ...state,
        questions: [
          ...state.questions.map((ques) => {
            // payload is question id
            if (action.payload !== ques.id) {
              return { ...ques };
            } else {
              return { ...ques, flagged: true };
            }
          }),
        ],
      };
    case testActions.UNFLAG_QUESTION:
      return {
        ...state,
        questions: [
          ...state.questions.map((ques) => {
            // payload is question ID
            if (action.payload !== ques.id) {
              return { ...ques };
            } else {
              return { ...ques, flagged: false };
            }
          }),
        ],
      };
    case testActions.UPDATE_SECTION_ID:
      return { ...state, activeSection: action.payload };
    case testActions.SET_EACH_EXAM_QUESTIONS:
      return {
        ...state,
        questions: [
          ...state.questions.map((ques) => {
            if (action.payload.examQuestionId !== ques.id) {
              return { ...ques };
            } else {
              return { ...ques, ...action.payload, flagged: false }; // set this to map correct api properties to the right state properties
            }
          }),
        ],
      };
    case testActions.ACTIVATE_TEST:
      return {
        ...state,
        isTestActive: true,
        currentQuestion: 0,
      };
    case testActions.DEACTIVATE_TEST:
      return {
        ...state,
        isTestActive: false,
        // questions: [],
        // ...InitialState,
      };
    case testActions.SET_EACH_EXAM_QUESTIONS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case testActions.SET_SELECTED_OPTION:
      return {
        ...state,
        questions: (function () {
          const existingQuestions = [...state.questions];
          // find the question whose option was selected using the questionId from the payload
          const activeQuestion = state.questions.find((ques) => ques.examQuestionId === action.payload.questionId);

          // find the selected option
          const newOptionArray = activeQuestion.options.map((option) => {
            if (option.optionNo === action.payload.optionId) {
              return {
                ...option,
                selected: true,
              };
            } else {
              return {
                ...option,
                selected: false,
              };
            }
          });
          return [
            ...existingQuestions.map((question) => {
              if (question.examQuestionId === action.payload.questionId) {
                return {
                  ...question,
                  options: [...newOptionArray],
                };
              } else {
                return {
                  ...question,
                };
              }
            }),
          ];
        })(),
      };
    case testActions.STORE_SUBMIT_DATA:
      return {
        ...state,
        submitData: action.payload,
      };
    case testActions.STORE_EXAM_RESULT:
      return {
        ...state,
        totalScore: action.payload.totalScore,
        examResults: action.payload.questionAnswer,
      };
    case testActions.RESET_QUESTION_ANSWERED:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        testName: "", //testname property is a placeholder for the actual name
        questions: [],
      };
    case testActions.FETCH_TEST_STATISTICS_BY_PROFILE_ID:
      return {
        ...state,
        statsLoaded: false,
        statsData: {},
      };
    case testActions.FETCH_TEST_STATISTICS_BY_PROFILE_ID_SUCCESS:
      return {
        ...state,
        statsLoaded: true,
        statsData: action.payload,
      };
    case testActions.FETCH_TEST_STATISTICS_BY_PROFILE_ID_FAILURE:
      return {
        ...state,
        statsLoaded: false,
        statsData: action.payload,
      };
    case testActions.FETCH_EXAM_WITH_HIGHEST_SCORE:
      return {
        ...state,
        examWithHighestScoreLoaded: false,
        examWithHighestScoreLoading: true,
        examWithHighestScore: [],
      };
    case testActions.FETCH_EXAM_WITH_HIGHEST_SCORE_SUCCESS:
      return {
        ...state,
        examWithHighestScoreLoaded: true,
        examWithHighestScoreLoading: false,
        examWithHighestScore: action.payload,
      };
    case testActions.FETCH_EXAM_WITH_HIGHEST_SCORE_FAILURE:
      return {
        ...state,
        examWithHighestScoreLoaded: false,
        examWithHighestScoreLoading: false,
        examWithHighestScore: [],
      };
    case testActions.FETCH_PREVIOUS_SCORE:
      return {
        ...state,
        subjectExams: [],
        subjectExamsLoaded: false,
      };
    case testActions.FETCH_PREVIOUS_SCORE_SUCCESS:
      return {
        ...state,
        subjectExams: action.payload,
        subjectExamsLoaded: true,
      };
    case testActions.FETCH_PREVIOUS_SCORE_FAILURE:
      return {
        ...state,
        subjectExams: action.payload,
        subjectExamsLoaded: false,
      };

    case testActions.RESET_TEST:
      return { ...InitialState };
    default:
      return state;
  }
}
