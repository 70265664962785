import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import {
  DeleteUserMetrics,
  GetSubscriptionModels,
  fetchUserMetrics,
  fetchUserMetricsByProfileId,
} from "../../../redux/userMetrics";
import useUserMetrics from "../../../hooks/useUserMetrics";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ManageUserModal({ profileId, filters, setToastState }) {
  const dispatch = useDispatch();
  const [openManageUser, setOpenManageUser] = React.useState(false);
  const userMetrics = useUserMetrics();
  const { userMetricById, subscription } = userMetrics;
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [gSMNumber, setGSMNumber] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [subType, setSubType] = useState("");
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  const handleClickOpenManageUser = () => {
    setOpenManageUser(true);
    dispatch(fetchUserMetricsByProfileId(profileId));
    dispatch(GetSubscriptionModels());
  };

  const onSuccess = (res) => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: res?.msg ?? "User deleted successfully",
    });
    dispatch(fetchUserMetrics({ filters }));
  };
  const onFail = (res) => {
    setToastState({
      isOpen: true,
      severity: "error",
      message: res.msg,
    });
  };

  const handleDeleteManageUser = () => {
    setOpenManageUser(false);
    dispatch(DeleteUserMetrics(profileId, onSuccess, onFail));
  };

  React.useEffect(() => {
    if (userMetricById) {
      setFirstName(userMetricById.firstName || "");
      setSurname(userMetricById.surname || "");
      setEmail(userMetricById.email || "");
      setGSMNumber(userMetricById.gsmNumber || "");
      setDateCreated(userMetricById.dateCreated || "");
      setSubType(userMetricById.Name || "");
    }
  }, [userMetricById, profileId]);

  const handleCloseManageUser = () => {
    setOpenManageUser(false);
    handleReset("");
  };

  const handleReset = () => {
    setFirstName(" ");
    setSurname(" ");
    setEmail(" ");
    setGSMNumber(" ");
    setSubType("00000000-0000-0000-0000-000000000000");
    setDateCreated(" ");
  };

  return (
    <>
      <MenuItem
        onClick={handleClickOpenManageUser}
        className="hover:bg-light-blue"
      >
        Edit user
      </MenuItem>
      <BootstrapDialog
        onClose={handleCloseManageUser}
        aria-labelledby="customized-dialog-title"
        open={openManageUser}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Edit user
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseManageUser}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <p>First Name</p>
              <TextField
                type="text"
                fullWidth
                id="FirstName"
                value={firstName}
                variant="outlined"
                size="small"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <p>SurName</p>
              <TextField
                fullWidth
                label="Surname"
                value={surname}
                variant="outlined"
                size="small"
                onChange={(e) => setSurname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <p>Email Address</p>
              <TextField
                fullWidth
                id="Email"
                value={email}
                variant="outlined"
                size="small"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <p>Phone Number</p>
              <TextField
                fullWidth
                id="GSMNumber"
                value={gSMNumber}
                variant="outlined"
                size="small"
                onChange={(e) => setGSMNumber(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <p>State</p>
              <TextField
                fullWidth
                //value={}
                variant="outlined"
                size="small"
              />
            </Grid> */}
            {/* <Grid item xs={6}>
              <p>Date registered</p>
              <TextField
                fullWidth
                value={dateCreated}
                variant="outlined"
                size="small"
                onChange={(e) => setDateCreated(e.target.value)}
              />
            </Grid> */}
            {/* <Grid item xs={6}> */}
            {/* <FormControl sx={{ m: 1, minWidth: 270 }} size="small">
              <InputLabel id="demo-select-small-label">Subscription</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={subType}
                // label="Age"
                onChange={(e) => setSubType(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                {subscription?.map((sub, i) => (
                  <MenuItem key={i} value={sub.subscriptionModelId}>
                    {" "}
                    {sub.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            {/* </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDeleteUserModal(true)}
            variant="contained"
            style={{ backgroundColor: "#fa7e77" }}
          >
            Delete User
          </Button>
          <Button autoFocus onClick={handleCloseManageUser} variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Dialog
        open={deleteUserModal}
        onClose={() => setDeleteUserModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Paper sx={{ p: 1 }}>
            <h3 className="e-study-heading-three">
              Are you sure you want to delete this user
            </h3>
          </Paper>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between items-center space-x-3 lg:space-x-9">
            <span
              className="e-study-primary cursor-pointer"
              onClick={() => setDeleteUserModal(false)}
            >
              No
            </span>
            <button
              onClick={handleDeleteManageUser}
              style={{ backgroundColor: "#FA7E77" }}
              class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            >
              Yes
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
