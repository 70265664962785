import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import {
  teacher,
  school,
  student,
  parent,
  sponsor,
} from "../../../assest/images/index";
import useUser from "../../../hooks/useUser";
import { getUserCategory } from "../../../redux/auth/authActions";
import { useNotifications } from "../../../components/notifications/notifications";
import { ButtonBase, FormControlLabel } from "@mui/material";
import { OptionRadio } from "./Options";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const selected = styled(Paper)(({ theme, selected }) => ({
  backgroundColor: selected ? "#EAECFF" : theme.palette.background.default,
  borderRadius: "20px",
  border: "1px solid #E5E5E5",
  boxShadow: "0px 4px 4px 0px #E5E5E5",
  cursor: "pointer", // Ensure the cursor changes to indicate clickable
  "&:hover": {
    backgroundColor: selected ? "#EAECFF" : theme.palette.background.paper,
  },
}));

export default function OnboardingUser(props) {
  const {
    input,
    setInput,
    updateSelectedStepperValue,
    selectedValue,
    setSelectedUser,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useUser();
  const { userCategory, isLoading, userInfo } = user;

  console.log(userInfo?.studentSchoolId);
  const handleChange = (event, param1, param2) => {
    const value = event.target.value;
    updateSelectedStepperValue(value, "You are registering as a ...");
    setInput({ ...input, categoryId: param1 });
    setSelectedUser(param2);
    console.log(input);
  };

  useEffect(() => {
    if (selectedValue !== "") {
      updateSelectedStepperValue(selectedValue, 0);
    }
  }, [selectedValue]);

  useEffect(() => {
    dispatch(getUserCategory());
  }, []);

  const picturess = [student, school, sponsor];
  const StudentPicturess = [student];

  const desiredgradegrid = {
    marginTop: "7rem",
    marginBottom: "2rem",

    [theme.breakpoints.up("md")]: {
      marginTop: "7rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "3rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: "1rem",
      marginBottom: "0rem",
    },
  };

  const desiredgradetext = {
    fontSize: "30px",
    flexGrow: 0,
    paddingRight: "30px",

    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      flexGrow: 0,
      paddingRight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  };

  const handleImageClick = (event) => {
    // Prevent event propagation to parent elements
    event.stopPropagation();

    // Handle image click logic here
    // For example, change the image's style, update state, etc.
  };

  const filterUserCategoryforSponsoredUser = userCategory?.filter(
    (category) =>
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() != "school" &&
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() != "sponsor" &&
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() !== "teacher" &&
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() != "parent"
  );
  const filterUserCategory = userCategory?.filter(
    (category) =>
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() !== "teacher" &&
      category?.CategoryName?.replace(/\s/g, "").toLowerCase() != "parent"
  );

  return (
    <>
      {userInfo?.studentSchoolId && userInfo?.studentSponsorId !== "" ? (
        <div>
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={0}
            sx={desiredgradegrid}
          >
            <Grid item xs={12}>
              <Typography variant="h6" sx={desiredgradetext}>
                You are registering as a ...
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "2rem",
              "& > :not(style)": {
                m: 2,
                width: 200,
                height: 200,
              },
            }}
          >
            {filterUserCategoryforSponsoredUser.map((item, i) => {
              debugger;
              return (
                <OptionRadio
                  key={i}
                  value={item.CategoryName}
                  selected={selectedValue === item.CategoryName}
                  // checked={selectedValue === item.CategoryName}
                  onClick={(event) =>
                    handleChange(event, item.CategoryID, item.CategoryName)
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={0}
                    mt={6}
                  >
                    <Grid>
                      <img
                        src={StudentPicturess}
                        alt={`OnboardingImage ${item.CategoryName}`}
                        width={100}
                        height={100}
                        key={i}
                        onClick={handleImageClick}
                      />
                    </Grid>
                    <Grid>
                      <Typography variant="h6">{item.CategoryName}</Typography>
                    </Grid>
                    <Grid></Grid>
                  </Grid>
                  {/* {item.CategoryName} */}
                </OptionRadio>
                // <FormControlLabel
                //   elevation={3}
                //   sx={{
                //     mr: 2,
                //     p: 2,
                //     borderRadius: 6,
                //     width: { xs: "100%", md: "50%" },
                //     height: { xs: "100%" },
                //   }}

                //    selected={selectedValue === item.CategoryName}
                //   value={item.CategoryName}
                // >
                //   {" "}
                //   <Radio
                //     sx={{
                //       display: "flex",
                //       justifyContent: "flex-end",
                //     }}
                //     disableRipple
                //     color="default"
                //     checked={selectedValue === item.CategoryName}
                //     onChange={(event) =>
                //       handleChange(event, item.CategoryID, item.CategoryName)
                //     }
                //     value={item.CategoryName}
                //     checkedIcon={<BpCheckedIcon />}
                //     icon={<BpIcon />}
                //     {...props}
                //   />
                //   <Grid container direction="column" alignItems="center" spacing={0}>
                //     <Grid>
                //       <img
                //         src={picturess[i]}
                //         alt={`OnboardingImage ${item.CategoryName}`}
                //         width={100}
                //         height={100}
                //         key={i}
                //         onClick={handleImageClick}
                //       />
                //     </Grid>
                //     <Grid>
                //       <Typography variant="h6">{item.CategoryName}</Typography>
                //     </Grid>
                //     <Grid></Grid>
                //   </Grid>
                // </FormControlLabel>
              );
            })}
            {/* {filterUserCategory.map((item, i) => (
      
          <ColoredPaper
            elevation={3}
            sx={{ mr: 2, p: 2, borderRadius: 6 }}
            selected={selectedValue === item.CategoryName}
            onClick={(event) =>
                handleChange(event, item.CategoryID, item.CategoryName)}
          >
            {" "}
            <Radio
              checked={selectedValue === item.CategoryName}
              onChange={(event) =>
                handleChange(event, item.CategoryID, item.CategoryName)
              }
              value={item.CategoryName}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              {...props}
            />
            <Grid container direction="column" alignItems="center" spacing={0}>
              <Grid>
                <img
                  src={picturess[i]}
                  alt={`OnboardingImage ${item.CategoryName}`}
                  width={100}
                  height={100}
                  key={i}
                  onClick={handleImageClick}
                />
              </Grid>
              <Grid>
                <Typography variant="h6">{item.CategoryName}</Typography>
              </Grid>
              <Grid></Grid>
            </Grid>
          </ColoredPaper>
          // </div>
        ))} */}
          </Box>
        </div>
      ) : (
        <div>
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={0}
            sx={desiredgradegrid}
          >
            <Grid item xs={12}>
              <Typography variant="h6" sx={desiredgradetext}>
                You are registering as a ...
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "2rem",
              "& > :not(style)": {
                m: 2,
                width: 200,
                height: 200,
              },
            }}
          >
            {filterUserCategory.map((item, i) => (
              <OptionRadio
                key={i}
                value={item.CategoryName}
                selected={selectedValue === item.CategoryName}
                // checked={selectedValue === item.CategoryName}
                onClick={(event) =>
                  handleChange(event, item.CategoryID, item.CategoryName)
                }
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={0}
                  mt={6}
                >
                  <Grid>
                    <img
                      src={picturess[i]}
                      alt={`OnboardingImage ${item.CategoryName}`}
                      width={100}
                      height={100}
                      key={i}
                      onClick={handleImageClick}
                    />
                  </Grid>
                  <Grid>
                    <Typography variant="h6">{item.CategoryName}</Typography>
                  </Grid>
                  <Grid></Grid>
                </Grid>
                {/* {item.CategoryName} */}
              </OptionRadio>
              // <FormControlLabel
              //   elevation={3}
              //   sx={{
              //     mr: 2,
              //     p: 2,
              //     borderRadius: 6,
              //     width: { xs: "100%", md: "50%" },
              //     height: { xs: "100%" },
              //   }}

              //    selected={selectedValue === item.CategoryName}
              //   value={item.CategoryName}
              // >
              //   {" "}
              //   <Radio
              //     sx={{
              //       display: "flex",
              //       justifyContent: "flex-end",
              //     }}
              //     disableRipple
              //     color="default"
              //     checked={selectedValue === item.CategoryName}
              //     onChange={(event) =>
              //       handleChange(event, item.CategoryID, item.CategoryName)
              //     }
              //     value={item.CategoryName}
              //     checkedIcon={<BpCheckedIcon />}
              //     icon={<BpIcon />}
              //     {...props}
              //   />
              //   <Grid container direction="column" alignItems="center" spacing={0}>
              //     <Grid>
              //       <img
              //         src={picturess[i]}
              //         alt={`OnboardingImage ${item.CategoryName}`}
              //         width={100}
              //         height={100}
              //         key={i}
              //         onClick={handleImageClick}
              //       />
              //     </Grid>
              //     <Grid>
              //       <Typography variant="h6">{item.CategoryName}</Typography>
              //     </Grid>
              //     <Grid></Grid>
              //   </Grid>
              // </FormControlLabel>
            ))}
            {/* {filterUserCategory.map((item, i) => (
      
          <ColoredPaper
            elevation={3}
            sx={{ mr: 2, p: 2, borderRadius: 6 }}
            selected={selectedValue === item.CategoryName}
            onClick={(event) =>
                handleChange(event, item.CategoryID, item.CategoryName)}
          >
            {" "}
            <Radio
              checked={selectedValue === item.CategoryName}
              onChange={(event) =>
                handleChange(event, item.CategoryID, item.CategoryName)
              }
              value={item.CategoryName}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              {...props}
            />
            <Grid container direction="column" alignItems="center" spacing={0}>
              <Grid>
                <img
                  src={picturess[i]}
                  alt={`OnboardingImage ${item.CategoryName}`}
                  width={100}
                  height={100}
                  key={i}
                  onClick={handleImageClick}
                />
              </Grid>
              <Grid>
                <Typography variant="h6">{item.CategoryName}</Typography>
              </Grid>
              <Grid></Grid>
            </Grid>
          </ColoredPaper>
          // </div>
        ))} */}
          </Box>
        </div>
      )}
    </>
  );
}
