import React, { useEffect, useState } from 'react'
import slide1 from "../../assets/images/slide1.png"
import slide2 from "../../assets/images/slide2.png"
import slide3 from "../../assets/images/slide3.png"
import slide4 from "../../assets/images/slide4.png"
import './LandingPage.css';
import { Box, Stack } from '@mui/material'
const images = [slide1, slide2, slide3, slide4];


const Carousel2 = () => {
    const [imageIndex, setImageIndex] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFadeOut(true);
            setTimeout(() => {
                setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
                setFadeOut(false);
            }, 500);
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [imageIndex]);

    const currentImage = images[imageIndex];
    return (
        <Stack alignItems={"center"}>
            <img src={currentImage} alt="" className={`fade ${fadeOut ? 'fade-out' : ''}`} />
            <Stack direction={"row"} gap={1}>
                {images.map((item, index) => (
                    <Box bgcolor={index === imageIndex ? "#40499d" : "#E7E7E7"} width={index === imageIndex ? "40px" : "20px"} height={"10px"} borderRadius={"52.06px"}></Box>
                ))}
            </Stack>

        </Stack>
    )
}

export default Carousel2
