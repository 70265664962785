import * as paymentActions from "./types";
import Axios from "../../connection/defaultClient";
import { googleAnalytics } from "../../hooks/usePageTracking";

export const initiateUPPayment = (payload, onSuccess, onFail) => {
  //
  return (dispatch) => {
    dispatch({
      type: paymentActions.INITIATE_TRANSACTION_START,
      payload: true,
    });
    Axios.post(`/Payment/UPInitiatePaymentTransaction`, { ...payload })
      .then((response) => {
        dispatch({
          type: paymentActions.INITIATE_TRANSACTION_SUCCESS,
          payload: response.data,
        });
        onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: paymentActions.INITIATE_TRANSACTION_FAILURE,
          payload: error.message,
        });
        onFail();
      });
  };
};

export const confirmUPPayment = (
  payload,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({
      type: paymentActions.CONFIRM_TRANSACTION_STATUS,
      payload: true,
    });
    Axios.post(`/Payment/UPConfirmPaymentTransaction?transactionId=${payload}`)
      .then((response) => {
        dispatch({
          type: paymentActions.CONFIRM_TRANSACTION_STATUS_SUCCESS,
          payload: response.data,
        });
        onSuccess(response.data.data);
      })
      .catch((error) => {
        dispatch({
          type: paymentActions.CONFIRM_TRANSACTION_STATUS_ERROR,
          payload: error.message,
        });
        onFailure();
      });
  };
};

export const initiatePaymentGateway = (payload, onSuccess, onFail) => {
  //
  return (dispatch) => {
    dispatch({
      type: paymentActions.INITIATE_TRANSACTION_START,
      payload: true,
    });
    Axios.post(
      `/Payment/InitiatePaymentGateway?Gateway=${payload.paymentGateway}&Amount=${payload.amount}&Currency=${payload.currency}&Email=${payload.Email}&ProfileId=${payload.profileId}&Description=${payload.description}&ReturnUrl=${payload.returnUrl}&SubscriptionModeId=${payload.subscriptionModeId}&Slots=${payload.slots}&SuperUserEmail=${payload.superUserEmail}`
    )
      .then((response) => {
        dispatch({
          type: paymentActions.INITIATE_TRANSACTION_SUCCESS,
          payload: response.data,
        });
        googleAnalytics().trackSpecificEvent("payment_completed", {
          amount: payload.amount,
          slots: payload.slots,
          currency: payload.currency,
          paymentGateway: payload.paymentGateway,
          subscriptionMode: payload.subscriptionModeId,
        });
        onSuccess(response.data);
      })
      .catch((error) => {
        dispatch({
          type: paymentActions.INITIATE_TRANSACTION_FAILURE,
          payload: error.message,
        });
        onFail();
      });
  };
};

export const confirmPaymentStatus = (
  payload,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({
      type: paymentActions.CONFIRM_TRANSACTION_STATUS,
      payload: true,
    });
    Axios.get(
      `/Payment/PaymentGatewayConfirmPaymentStatus?Gateway=${payload.paymentGateway}&PaymentReference=${payload.paymentReference}`
    )
      .then((response) => {
        dispatch({
          type: paymentActions.CONFIRM_TRANSACTION_STATUS_SUCCESS,
          payload: response.data,
        });
        if (response.data?.amount || response.data?.code == '00') {
          onSuccess(response.data);
        } else {
          onFailure(response.data);
        }
      })
      .catch((error) => {
        dispatch({
          type: paymentActions.CONFIRM_TRANSACTION_STATUS_ERROR,
          payload: error.message,
        });
        onFailure(error);
      });
  };
};

export const getAllTransactions = (
  payload
  // onSuccess=()=>{}, onFailure=()=> {}
) => {
  return (dispatch) => {
    dispatch({
      type: paymentActions.GET_TRANSACTIONS,
      payload: true,
    });
    let queryString = "";
    if (payload && Object.keys(payload).length > 0) {
      const params = new URLSearchParams(payload);
      queryString = `?${params.toString()}`;
    }
    Axios.get(`/Payment/GetAllTransactions${queryString}`)
      .then((response) => {
        dispatch({
          type: paymentActions.GET_TRANSACTIONS_SUCCESS,
          payload: response.data.data,
        });
        // onSuccess(response.data.data)
      })
      .catch((error) => {
        dispatch({
          type: paymentActions.GET_TRANSACTIONS_FAILURE,
          payload: error.message,
        });
        // onFailure()
      });
  };
};

export const getTransactionById = (
  payload,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({
      type: paymentActions.GET_TRANSACTION_BY_ID,
      payload: true,
    });
    Axios.get(`/Payment/GetTransactionByTransId?TransId=${payload}`)
      .then((response) => {
        dispatch({
          type: paymentActions.GET_TRANSACTION_BY_ID_SUCCESS,
          payload: response.data,
        });
        onSuccess(response.data.data);
      })
      .catch((error) => {
        dispatch({
          type: paymentActions.GET_TRANSACTION_BY_ID_FAILURE,
          payload: error.message,
        });
        onFailure();
      });
  };
};
