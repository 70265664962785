import * as React from "react";
import { useState, useEffect } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Stack from "@mui/material/Stack";
import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import useUser from "../../../../../hooks/useUser";
import PremiumDialog from "../../../../Modal/PremiumDialog";
import ConfirmPayment from "./ConfirmPaymentDialog";
import CustomSelect from "../../../../../pages/admin/usermetrics/customSelectForm";
import {

  getAllCountries,
} from "../../../../../redux/auth/authActions";
import { useDispatch } from "react-redux";
import { useNotifications } from "../../../../notifications/notifications";
import CountryAsynchronous from "../../../../Settings/Autocomplete/CountryAsynchronous";
import {
  getLgaByStateId,
  getStatesByCountryId,
  getWaecApprovedSchools,
} from "../../../../../redux/settings/settingsActions";
import StateAsynchronous from "../../../../Settings/Autocomplete/StateAsynchronous";
import LgaAsynchronous from "../../../../Settings/Autocomplete/LgaAsynchronous";
import SchoolAsynchronous from "../../../../Settings/Autocomplete/SchoolAsynchronous";
import { formatCurrency } from "../../../../../helpers/helper";
import { fetchPaymentSummary } from "../../../../../redux/subscriptions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Seerbitlogo, UPlogo } from "../../../../../assets/images";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#b4b4b4" : "#2D3843",
    fontSize: 16,
    // width: 'auto',
    // marginBottom: "20px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      //   borderColor: theme.palette.primary.main,
    },
    cursor: "auto",
  },
}));

function createData(name, protein) {
  return { name, protein };
}

const pricePerStudent = 10000;

export default function PaymentDetails({ interswitchPay, selectedSubscription, ...props }) {
  const {
    userInfo: { firstName, surname, email, sponsorName },
  } = useUser();

  const [open, setOpen] = React.useState(false);
  const [studentsCount, setStudentsCount] = React.useState(1);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);

  const subscriptionId = selectedSubscription.SubscriptionModelId


  const subAmount = selectedSubscription?.Amount;

  const subtotal = subAmount * studentsCount;

  useEffect(() => {
    setInput({ ...input, amount: subtotal, slots: studentsCount })
  }, [studentsCount])

  useEffect(() => {
    dispatch(fetchPaymentSummary(subscriptionId, studentsCount));
  }, [subscriptionId, studentsCount])

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // to be removed

  const handleIncrement = () => {
    setStudentsCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (studentsCount > 1) {
      setStudentsCount((prevCount) => prevCount - 1);
    }
  };

  const handleProceedClick = () => {
    if (studentsCount > 0) {
      setOpenConfirmationDialog(true);
    }
  };

  const handleConfirmProceed = () => {
    setOpenConfirmationDialog(false);
    interswitchPay();
  };

  const handleCancelProceed = () => {
    setOpenConfirmationDialog(false);
  };



  const rows = [
    createData("Subtotal:", formatCurrency(subtotal)),
    // createData("Platform fee:", studentsCount > 0 ? "₦422.00" : "₦0.00"),
    // createData(
    //   "Total Amount:",
    //   formatCurrency(subtotal + (studentsCount > 0 ? 422 : 0))
    // ),
  ];

  // Starts here
  const { input, setInput } = props;
  const initialFormValues = {
    schoolName: "",
    schoolAddress: "",
    countryId: 0,
    stateId: 0,
  };

  const [formValues, setFormValues] = React.useState({ ...initialFormValues });
  const dispatch = useDispatch();
  const [values, setValues] = useState({ label: "", value: 0 });
  const [stateValues, setStateValues] = useState({ label: "", value: 0 });
  const [lgaValues, setLgaValues] = useState({ label: "", value: 0 });
  const [schoolValues, setSchoolValues] = useState({ label: "", value: 0 });
  const [Toast, setToast] = useNotifications();

  const user = useUser();
  const { countries, statesByCountryId, isLoading } = user;

  const stateByCountryFailure = (res) => {
    if (res !== 1) {
      setToast({
        isOpen: true,
        message: "An error occured!",
        severity: "error",
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, schoolName: value });
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleChanges = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, schoolAddress: value });
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handlePaymentGatewayChange = (value) => {
    setInput({
      ...input,
      paymentGateway: value
    })
  }


  //functionality for country
  const countryHandler = (event, val) => {
    if (event) {
      setStateValues({ label: "", value: 0 });
      // setInput({ ...input, countryId: val.value });
      setFormValues({
        ...formValues,
        countryId: val.value,
      });

      dispatch(getStatesByCountryId(val?.value, stateByCountryFailure));
    }
  };

  const updateValue = (value) => {
    setFormValues({
      ...formValues,
      countryId: value.value,
    });
    setInput({ ...input, countryId: value.value });
    setValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };
  const stateHandler = (event, val) => {
    if (event) {
      setLgaValues({ label: "", value: 0 });
      setFormValues({
        ...formValues,
        stateId: val.value,
      });

      dispatch(getLgaByStateId(val?.value, lgaByStateFailure));
    }
  };

  const updateStateValue = (value) => {
    setFormValues({
      ...formValues,
      stateId: value.value,
    });
    // setInput({ ...input, stateId: value.value });
    setStateValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };

  const lgaHandler = (event, val) => {
    if (event) {
      setSchoolValues({ label: "", value: 0 });
      setFormValues({
        ...formValues,
        lgaId: val.value,
      });

      dispatch(
        getWaecApprovedSchools(
          formValues?.countryId,
          formValues?.stateId,
          val?.value,
          schoolFailure
        )
      );
    }
  };

  const updateLgaValue = (value) => {
    setFormValues({
      ...formValues,
      lgaId: value.value,
    });
    // setInput({ ...input, lgaId: value.value });
    setLgaValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };

  const schoolHandler = (event, val) => {
    if (event) {
      setFormValues({
        ...formValues,
        schoolId: val.value,
      });
    }
  };

  const updateSchoolValue = (value) => {
    setFormValues({
      ...formValues,
      WAECSchoolID: value.value,
    });
    // setInput({ ...input, WAECSchoolID: value.value });
    setSchoolValues({
      ...values,
      label: value.label,
      value: value.value,
    });
  };

  const lgaByStateFailure = (res) => {
    if (res !== 1) {
      setToast({
        isOpen: true,
        message: "An error occured!",
        severity: "error",
      });
    }
  };

  const schoolFailure = (res) => {
    if (res !== 1) {
      setToast({
        isOpen: true,
        message: "An error occured!",
        severity: "error",
      });
    }
  };




  const handleChangeCountry = (event) => {
    const value = event.target.value;
    setInput({ ...input, countryId: value });
    dispatch(getStatesByCountryId(value));
  };

  const handleChangeState = (event) => {
    const value = event.target.value;
    setInput({ ...input, stateId: value });
  };

  const [sponsorStudents, setSponsorStudents] = useState();
  const [sponsorSchool, setSponsorSchool] = useState(true);

  const SelOptions = [
    { value: "Nigeria", label: "Naira" },
    { value: "USA", label: "Dollar" },
  ];
  const [selectedCurrency, setSelectedCurrency] = useState("Nigeria");

  function handleSponsorSchool() {
    setSponsorSchool(true);
    setSponsorStudents(false);
  }

  function handleSponsorStudents() {
    setSponsorSchool(false);
    setSponsorStudents(true);
  }

  const handleCurrency = (event) => {
    setSelectedCurrency(event.target.value);
  };

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  return (
    <Box
      component="form"
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        /*     display: 'grid',
          gridTemplateColumns: { sm: '1fr 1fr' },
        gap: 2, */
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "#000",
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Payment Details
        </Typography>
        <Typography
          sx={{
            color: "var(--Card-subheader, #919191)",
            fontSize: "14px",
            fontWeight: 400,
            pt: 0,
            mt: 0,
          }}
        >
          Please ensure you double-check the number of students inputed before
          proceeding with the payment.
        </Typography>
      </Box>

      <Box>
        <Box
          sx={{
            padding: "20px",
            borderRadius: "5px",
            bgcolor: "rgba(203, 213, 225, 0.50)",
            border: "1px solid rgba(51, 150, 2000), 0.3",
            // boxShadow:"0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
            display: "flex",
            alignItems: "center",
            gap: 3,
            marginBottom: 4,
          }}
        >
          <Box>
            <Box pb={2}>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Subscription Plan
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {selectedSubscription?.PackageName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Sponsor Email
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {email}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Box pb={2}>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Sponsor Name
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {sponsorName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "var(--Card-subheader, #919191)",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Total Amount Paid Previously{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#373737",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                ₦ 0.00
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid container direction="row" alignItems="center" spacing={3} pb={4}>
          <Grid item xs={6}>
            <Typography></Typography>
            <Button
              variant={sponsorSchool ? "contained" : "outlined"}
              onClick={() => handleSponsorSchool()}
              sx={{
                width: "221px",
                height: "48px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Sponsor School
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={sponsorStudents ? "contained" : "outlined"}
              onClick={() => handleSponsorStudents()}
              sx={{
                width: "221px",
                height: "48px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Sponsor Students
            </Button>
          </Grid>
        </Grid>

        {sponsorSchool && input.countryId === 13 ? (
          <Box pb={3}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              pb={3}
            >
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <CountryAsynchronous
                    updateValue={updateValue}
                    countryHandler={countryHandler}
                    BootstrapInput={BootstrapInput}
                    values={values}
                  // values={input.value}
                  />
                </FormControl>
                {/* <Typography>Select Country</Typography>
                <CustomSelect
                  handleChange={handleChangeCountry}
                  value={input.countryId}
                  BootstrapInput={BootstrapInput}
                  SelDefault={"Select Country"}
                  Seloptions={countries}
                /> */}
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <StateAsynchronous
                    updateStateValue={updateStateValue}
                    stateHandler={stateHandler}
                    stateValues={stateValues}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography>Select State</Typography>
                <CustomSelect
                  handleChange={handleChangeState}
                  value={input.stateId}
                  BootstrapInput={BootstrapInput}
                  SelDefault={"Select State"}
                  Seloptions={statesByCountryId}
                />
              </Grid> */}
            </Grid>

            <Grid item xs={12} pb={4}>
              <FormControl fullWidth>
                <LgaAsynchronous
                  updateLgaValue={updateLgaValue}
                  lgaHandler={lgaHandler}
                  lgaValues={lgaValues}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} pb={4}>
              <Typography>Select LGA</Typography>
              <CustomSelect
                handleChange={handleChangeState}
                // value={input.stateId}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select LGA"}
              // Seloptions={statesByCountryId}
              />
            </Grid> */}

            <Grid item xs={12} pb={4}>
              <FormControl fullWidth>
                <SchoolAsynchronous
                  updateSchoolValue={updateSchoolValue}
                  schoolHandler={schoolHandler}
                  schoolValues={schoolValues}
                  schOnboardedOptions={true}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} pb={4}>
              <Typography>Select School</Typography>
              <CustomSelect
                handleChange={handleChangeState}
                // value={input.stateId}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select School"}
              // Seloptions={statesByCountryId}
              />
            </Grid> */}

            <FormControl variant="standard" fullWidth>
              <InputLabel
                htmlFor="bootstrap-input"
                sx={{ fontWeight: 800, fontSize: "18px", color: "#313131" }}
              >
                How many students do you want to sponsor?
              </InputLabel>
              <Input
                id="bootstrap-input"
                type="number"
                value={studentsCount}
                onChange={(e) => setStudentsCount(e.target.valueAsNumber || 0)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="add" onClick={handleDecrement}>
                      <RemoveIcon />
                    </IconButton>
                    <IconButton aria-label="add" onClick={handleIncrement}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {/* <Grid item xs={12} pb={4}>
              <Typography>Select Currency</Typography>
              <FormControl variant="standard" fullWidth>
                <Select
                  value={selectedCurrency}
                  onChange={handleCurrency}
                  input={<BootstrapInput />}
                  SelDefault={"Naira"}
                >
                  {SelOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12} pb={4}>
              <Typography>Select Currency</Typography>
              <CustomSelect
                handleChange={handleCurrency}
                value={selectedCurrency}
                BootstrapInput={BootstrapInput}
                SelDefault={"Select Currency"}
                Seloptions={SelOptions}
              />
            </Grid> */}
          </Box>
        ) : !sponsorSchool ? (
          <Box pb={3}>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                htmlFor="bootstrap-input"
                sx={{ fontWeight: 800, fontSize: "18px", color: "#313131" }}
              >
                How many students do you want to sponsor?
              </InputLabel>
              <Input
                id="bootstrap-input"
                type="number"
                value={studentsCount}
                onChange={(e) => setStudentsCount(e.target.valueAsNumber || 0)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="add" onClick={handleDecrement}>
                      <RemoveIcon />
                    </IconButton>
                    <IconButton aria-label="add" onClick={handleIncrement}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        ) : <Box pb={3}>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={3}
            pb={3}
          >
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl fullWidth>
                <CountryAsynchronous
                  updateValue={updateValue}
                  countryHandler={countryHandler}
                  BootstrapInput={BootstrapInput}
                  values={values}
                // values={input.value}
                />
              </FormControl>

            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <FormControl fullWidth>
                <StateAsynchronous
                  updateStateValue={updateStateValue}
                  stateHandler={stateHandler}
                  stateValues={stateValues}
                />
              </FormControl>
            </Grid>

          </Grid>

          <Grid item xs={12} pb={4}>
            <FormControl fullWidth>
              <TextField
                label="School Name"
                id="outlined-basic"
                placeholder="E.g HoneyLand International Schools"
                variant="outlined"
                name="schoolName"
                // size="small"
                value={input.schoolName}
                onChange={handleChanges}
              />
            </FormControl>
          </Grid>


          <Grid item xs={12} pb={4}>
            <FormControl fullWidth>
              <TextField
                label="School Address"
                id="outlined-basic"
                placeholder="E.g Street or Block"
                variant="outlined"
                name="schoolAddress"
                // size="small"
                value={input.schoolAddress}
                onChange={handleChanges}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} pb={4}>
          <Typography>Select School</Typography>
          <CustomSelect
            handleChange={handleChangeState}
            // value={input.stateId}
            BootstrapInput={BootstrapInput}
            SelDefault={"Select School"}
          // Seloptions={statesByCountryId}
          />
        </Grid> */}

          <FormControl variant="standard" fullWidth>
            <InputLabel
              htmlFor="bootstrap-input"
              sx={{ fontWeight: 800, fontSize: "18px", color: "#313131" }}
            >
              How many students do you want to sponsor?
            </InputLabel>
            <Input
              id="bootstrap-input"
              type="number"
              value={studentsCount}
              onChange={(e) => setStudentsCount(e.target.valueAsNumber || 0)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="add" onClick={handleDecrement}>
                    <RemoveIcon />
                  </IconButton>
                  <IconButton aria-label="add" onClick={handleIncrement}>
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>


        </Box>}
      </Box>

      <div className="mt-4 grid grid-cols-2 gap-2 place-items-center">
        
        <div className={`py-4 px-3 col-span-1 w-full flex items-center justify-between rounded-md cursor-pointer border ${input.paymentGateway == 2 ? 'border-[#40499D]' : 'border-[#CBD5E1]'}`} onClick={() => handlePaymentGatewayChange(2)}>
          <div className="flex space-x-2 items-center">
            <img src={Seerbitlogo} alt="Seerbit logo" />
            <span>Seerbit</span>
          </div>
          {
            input.paymentGateway == 2 ? (
              <CheckCircleIcon style={{ color: "#40499D" }} />
            ) : (
              <RadioButtonUncheckedIcon style={{ color: "#40499D" }} />
            )
          }

        </div>
        <div className={`py-4 px-3 col-span-1 w-full flex items-center justify-between rounded-md cursor-pointer border ${input.paymentGateway == 1 ? 'border-[#40499D]' : 'border-[#CBD5E1]'} `} onClick={() => handlePaymentGatewayChange(1)}>
          <div className="flex space-x-2 items-center">
            <img src={UPlogo} alt="Unified Payments logo" />
            <span>Unified Payments</span>
          </div>
          {
            input.paymentGateway == 1 ? (
              <CheckCircleIcon style={{ color: "#40499D" }} />
            ) : (
              <RadioButtonUncheckedIcon style={{ color: "#40499D" }} />
            )
          }
        </div>
      </div>
      <FormControl fullWidth>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{ color: "#313131", fontWeight: 900 }}
                    align="right"
                  >
                    {row.protein}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormControl>


      <FormControl fullWidth>
        <Button
          onClick={handleProceedClick}
          sx={{
            borderRadius: "10px",
            p: 2,
            textTransform: "inherit",
          }}
          variant="contained"
          color="primary"
        >
          Proceed
        </Button>
      </FormControl>
      <ConfirmPayment
        open={openConfirmationDialog}
        handleClose={handleCancelProceed}
        handleConfirmProceed={handleConfirmProceed}
        studentsCount={studentsCount}
        subtotal={subtotal}
        selectedSubscription={selectedSubscription}
      />
    </Box>
  );
}
