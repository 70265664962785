import Axios from "../../connection/defaultClient";
import { persistLastLogin, destroyLastLogin } from "../../helpers/session";
import { googleAnalytics } from "../../hooks/usePageTracking";
import useUser from "../../hooks/useUser";
import { FETCH_USER_PROFILE_DETAILS_SUCCESS } from "../settings/types";
import {
  LOADING_AUTH,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  USER_CATEGORY,
  USER_PATH_BY_CATEGORY,
  DESIRED_GRADE,
  ABOUT_US_ONBOARDING,
  ONBOARD_USER,
  SET_DATA_FROM_LOCAL_STORAGE,
  REGISTER_USER_SUCCESS,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_SUCCESS,
  FORGOT_PASSWORD,
  GET_COUNTRIES,
  GET_STATES_BY_COUNTRY_ID,
  GET_SPONSOR_TYPES,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESEND_MAIL,
  LOADING_LOGOUT,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS,
} from "./types";

/**
 * Login user
 *
 * @param {object} payLoad {"email": "test@test.com","password": "password"}
 * @returns void
 */
export const loginUserAction = (payLoad, onSuccess = () => {}, onFail = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`/Access/PublicLoginWithDeviceInfo`, { ...payLoad })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: LOGIN_USER, payLoad: res });
        if (res.data.state == 1) {
          Axios.post(`/Subscription/CheckStudentSub/${res.data.data.profileId}`).then((sub) => {
            googleAnalytics().trackSpecificEvent("Sign In", {
              userType: res.data.data.categoryName ? res.data.data.categoryName.trim() : "Not onboarded",
              mode: sub.data.msg === "InActive" ? "Trial" : "Paid",
              method: "email",
              country: res.data.data.countryName ?? "Not onboarded",
              sponsorAwardee: res.data.data.isSponsorAwardee ?? "Not onboarded",
              gender: res.data.data.gender ?? "Not onboarded",
            });
            onSuccess(res);
          });
        } else {
          onFail(res);
        }
        persistLastLogin(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        // onFail(error);
      });
  };
};

export const fetchProfileDetailsAction = (payLoad, onSuccess = () => {}, onFail = () => {}) => {
  return (dispatch) => {
    dispatch({ type: GET_PROFILE_DETAILS, payLoad: true });
    Axios.post(`/Access/GetProfileDetails?profileId=${payLoad}`)
      .then((res) => {
        dispatch({ type: GET_PROFILE_DETAILS, payLoad: false });
        dispatch({ type: GET_PROFILE_DETAILS_SUCCESS, payLoad: res });
        dispatch({
          type: FETCH_USER_PROFILE_DETAILS_SUCCESS,
          payload: res.data.data,
        });
        if (res.data.state == 1) {
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PROFILE_DETAILS, payLoad: false });
        // onFail(error);
      });
  };
};

export const clearLocalStorageAction = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: false });
    destroyLastLogin();
  };
};

export const logoutUserAction = (payLoad, onFail = () => {}, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_LOGOUT, payLoad: true });
    Axios.post(`/Access/SignOut?Token=${payLoad}`)
      .then((res) => {
        dispatch({ type: LOADING_LOGOUT, payLoad: false });
        dispatch({ type: LOGOUT_USER, payLoad: res });
        onSuccess(res);
        destroyLastLogin();
        // window.location.reload();
      })
      .catch((error) => {
        dispatch({ type: LOADING_LOGOUT, payLoad: false });
        onFail(error);
      });
  };
};

export const checkToken = (payLoad, onSuccess = () => {}, onFail = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`/Access/verifySession?email=${payLoad}`)
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        onSuccess(res);
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        onFail(error);
      });
  };
};

/**
 * Register user
 *
 * @param {object} payLoad {"email": "nowjqdeji@gmail.com", "password": "qwerty"}
 * @param {func} callBack
 * @returns void
 */

export const registerUserAction = (params, onSuccess, onFail) => {
  const firstname = params.Firstname;
  const surname = params.Surname;
  const email = params.Email;
  const phone = params.Phone;
  const password = params.Password;
  const confirmPassword = params.ConfirmPassword;
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    dispatch({ type: REGISTER_USER, payLoad: {} });

    const queryParams = `Email=${email}&Password=${password}&ConfirmPassword=${confirmPassword}&Firstname=${firstname}&Surname=${surname}&ReferralCode=${params.Referral}&PhoneNumber=${phone}`;
    Axios.post(`/Profile/CreateProfile?${queryParams}`)
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: REGISTER_USER_SUCCESS, payLoad: res });
        if (res.data.state == 1) {
          googleAnalytics().trackSpecificEvent("Initiated Sign up", {
            referral: params.referral,
          });
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.log("An error occurred", error);
      });
  };
};
export const verifyAccountAction = (payLoad, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    dispatch({ type: VERIFY_ACCOUNT, payLoad: {} });

    Axios.post(`/Access/VerifyAccount/${payLoad}`)
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: VERIFY_ACCOUNT_SUCCESS, payLoad: res });
        if (res.data.state >= 1) {
          googleAnalytics().trackSpecificEvent("Verified Account");
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.log("An error occurred", error);
      });
  };
};
export const verifyAdminAccountAction = (payLoad, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    dispatch({ type: VERIFY_ACCOUNT, payLoad: {} });

    Axios.post(`/ManageAdmin/VerifyAccountEmail/${payLoad}`)
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: VERIFY_ACCOUNT_SUCCESS, payLoad: res });
        if (res.data.state >= 1) {
          googleAnalytics().trackSpecificEvent("Verified Account");
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.log("An error occurred", error);
      });
  };
};
export const resetPasswordAction = (params, onSuccess, onFail) => {
  const profileId = params.profileId;
  const newPassword = params.newPassword;
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    dispatch({ type: RESET_PASSWORD, payLoad: {} });

    const queryParams = `profileId=${profileId}&newPassword=${newPassword}`;
    Axios.post(`/Access/ResetPassword?${queryParams}`)
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: RESET_PASSWORD_SUCCESS, payLoad: res });
        if (res.data.state == 1) {
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.log("An error occurred", error);
      });
  };
};
export const forgotPasswordAction = (param, onSuccess, onFail) => {
  const email = param.email;
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    const queryParams = `email=${email}`;
    Axios.post(`/Access/ForgotPassword?${queryParams}`)
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        dispatch({ type: FORGOT_PASSWORD, payLoad: res });
        if (res.data.state == 1) {
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.log("An error occurred", error);
      });
  };
};

export const onboardUserAction = (payLoad, onSuccess = () => {}, onFail = () => {}, on500Fail = () => {}, callBack = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.post(`/Onboarding/PostOnboard?profileId=${payLoad.profileId}`, {
      ...payLoad.input,
    })
      .then((res) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res.data);
        } else if (res.data.state == -1) {
          onFail(res.data);
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        on500Fail(error.response);
        console.error(error);
      });
  };
};
/**
 * User Category
 *
 * @param {object} payLoad {true}
 * @param {func} callBack
 * @returns void
 */
export const getUserCategory = (onFail = () => {}, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.get(`/UserCategories/GetAllUserCategories`)
      .then((res) => {
        dispatch({ type: USER_CATEGORY, payLoad: res });
        dispatch({ type: LOADING_AUTH, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.error(error);
      });
  };
};

/**
 * User Category By Id
 *
 * @param {object} payLoad {"categoryID": "categoryID"}
 * @param {func} callBack
 * @returns void
 */

export const getUserPathByCategory = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.get(`/Onboarding/GetUserPathsByCategory?categoryId=${categoryId}`)
      .then((res) => {
        dispatch({ type: USER_PATH_BY_CATEGORY, payLoad: res });
        dispatch({ type: LOADING_AUTH, payLoad: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.error(error);
      });
  };
};

/**
 * Desired Grades
 *
 * @param {object} payLoad {true}
 * @param {func} callBack
 * @returns void
 */
export const getDesiredGrades = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.get(`/Onboarding/GetStudentDesiredGrades`)
      .then((res) => {
        dispatch({ type: DESIRED_GRADE, payLoad: res });
        dispatch({ type: LOADING_AUTH, payLoad: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.error(error);
      });
  };
};

/**
 * Get Awareness Details
 *
 * @param {object} payLoad {true}
 * @param {func} callBack
 * @returns void
 */
export const getAboutUs = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.get(`/Onboarding/GetAwarenessDetails`)
      .then((res) => {
        dispatch({ type: ABOUT_US_ONBOARDING, payLoad: res });
        dispatch({ type: LOADING_AUTH, payLoad: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.error(error);
      });
  };
};

/**
* Onboarding User Final Stage
* 
* @param {object} payLoad {
* "categoryId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"userPathId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"desiredGradeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
"isNotification": true,
"awarenessId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
}
* @returns void
*/

export const setOnbDataFromLocalStorage = (onboardLocalUserData) => {
  return {
    type: SET_DATA_FROM_LOCAL_STORAGE,
    payLoad: onboardLocalUserData,
  };
};

export const getAllCountries = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.get(`/Settings/GetCountries`)
      .then((res) => {
        dispatch({ type: GET_COUNTRIES, payLoad: res });
        dispatch({ type: LOADING_AUTH, payLoad: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.error(error);
      });
  };
};

export const getStatesByCountryId = (countryId) => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.get(`/Settings/GetStatesByCountryId?countryId=${countryId}`)
      .then((res) => {
        dispatch({ type: GET_STATES_BY_COUNTRY_ID, payLoad: res });
        dispatch({ type: LOADING_AUTH, payLoad: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.error(error);
      });
  };
};

export const getSponsorTypes = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_AUTH, payLoad: true });
    Axios.get(`/Onboarding/GetSponsorTypes`)
      .then((res) => {
        dispatch({ type: GET_SPONSOR_TYPES, payLoad: res });
        dispatch({ type: LOADING_AUTH, payLoad: false });
      })
      .catch((error) => {
        dispatch({ type: LOADING_AUTH, payLoad: false });
        console.error(error);
      });
  };
};

/**
 * Resend Mail
 *
 * @param {object} payload {"emailAddress": "test@test.com"}
 * @returns void
 */
export const resendMail = (payload, onSuccess, onFail) => {
  return (dispatch) => {
    Axios.post(`/Profile/ResendMail?emailAddress=${payload}`)
      .then((res) => {
        if (res.data.state == 1) {
          onSuccess(res);
        } else {
          onFail(res);
        }
      })
      .catch((error) => {
        console.log(error);
        onFail(error?.message);
      });
  };
};
