import Axios from "../../connection/defaultClient";
import * as actionTypes from "./types";

export const addExamAction = (
  payLoad,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    Axios.post(`/ManageExam/AddExam`, payLoad)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_EXAM_SUCCESS, payLoad: res.data });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        } else {
          onFailure(res.data);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.ADD_EXAM_FAIL, payLoad: error });
      });
  };
};

export const updateExamAction = (
  payLoad,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.post(`/ManageExam/UpdateExam`, payLoad)
      .then((res) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        dispatch({ type: actionTypes.ADD_EXAM_SUCCESS, payLoad: res.data });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        } else {
          onFailure(res.data);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
        dispatch({ type: actionTypes.ADD_EXAM_FAIL, payLoad: error });
      });
  };
};

export const fetchExamYearsAction = () => {
  return (dispatch) => {
    Axios.get(`/Exam/GetExaminationYears`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_EXAM_YEARS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_EXAM_YEARS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchExamsAction = (id) => {
  return (dispatch) => {
    Axios.get(`/Subject/GetStudyExamBySubjectId?subjectId=${id}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_EXAM_BY_SUBJECT_ID_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_EXAM_BY_SUBJECT_ID_FAIL,
          payload: error.message,
        });
      });
  };
};

export const addExamQuestionAction = (payLoad, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.post(`/ManageExam/AddExamQuestion`, payLoad)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_EXAM_QUESTION_SUCCESS,
          payLoad: res.data,
        });
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        dispatch({ type: actionTypes.ADD_EXAM_QUESTION_FAIL, payLoad: error });
      });
  };
};

export const addExamQuestionSolutionsAction = (
  payLoad,
  onSuccess = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.post(`/ManageExam/SaveAISolution`, payLoad)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_AI_SOLUTIONS_SUCCESS,
          payLoad: res.data,
        });
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        dispatch({ type: actionTypes.ADD_AI_SOLUTIONS_FAIL, payLoad: error });
      });
  };
};

export const addTheoryExamQuestionAction = (payLoad, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.post(`/ManageExam/AddTheoryExamQuestion`, payLoad)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_EXAM_QUESTION_SUCCESS,
          payLoad: res.data,
        });
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        dispatch({ type: actionTypes.ADD_EXAM_QUESTION_FAIL, payLoad: error });
      });
  };
};

export const updateExamQuestionAction = (payLoad, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.post(`/ManageExam/UpdateExamQuestion`, payLoad)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_EXAM_QUESTION_SUCCESS,
          payLoad: res.data,
        });
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        dispatch({ type: actionTypes.ADD_EXAM_QUESTION_FAIL, payLoad: error });
      });
  };
};

export const updateTheoryExamQuestionAction = (
  payLoad,
  onSuccess = () => {}
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.post(`/ManageExam/UpdateTheoryExamQuestion`, payLoad)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_EXAM_QUESTION_SUCCESS,
          payLoad: res.data,
        });
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        dispatch({ type: actionTypes.ADD_EXAM_QUESTION_FAIL, payLoad: error });
      });
  };
};

export const deleteExamQuestionAction = (payLoad, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.post(`/ManageExam/DeleteExamQuestion`, payLoad)
      .then((res) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        if (res.data.state == 1) {
          onSuccess(res);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
        console.error(error);
      });
  };
};

export const fetchExamQuestionsAction = (id) => {
  return (dispatch) => {
    Axios.post(`/Exam/GetAllExamQuestions?examId=${id}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_EXAM_QUESTIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_EXAM_QUESTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchExamQuestionsByTopicId = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_EXAM_QUESTIONS_START,
    });
    Axios.post(`/Exam/GetAllExamQuestionsByTopicId?topicId=${id}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_EXAM_QUESTIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_EXAM_QUESTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};
export const fetchTheoryExamQuestions = (id) => {
  return (dispatch) => {
    Axios.post(`/ManageExam/GetExamQuestionsAndAnswersByExamId/${id}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_EXAM_QUESTIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_EXAM_QUESTIONS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const addSubjectAction = (payLoad, onSuccess) => {
  return (dispatch) => {
    Axios.post(`/Subject/AddSubject`, payLoad)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_SUBJECT_SUCCESS, payLoad: res.data });
        onSuccess();
      })
      .catch((error) => {
        dispatch({ type: actionTypes.ADD_SUBJECT_FAIL, payLoad: error });
        console.error(error);
      });
  };
};

export const fetchSubjectsAction = () => {
  return (dispatch) => {
    Axios.get(`/Subject/LoadSubjects`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_SUBJECTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_SUBJECTS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchTradeSubjectsAction = () => {
  return (dispatch) => {
    Axios.post(`/Subject/GetTradeSubjects`)
      .then((response) => {
        dispatch({
          type: actionTypes.TRADE_SUBJECTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.TRADE_SUBJECTS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchAcademicSubjectsAction = (payload) => {
  return (dispatch) => {
    Axios.post(`/Subject/GetAcademicSubjects?ProfileId=${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.ACADEMIC_SUBJECTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ACADEMIC_SUBJECTS_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchSubjectAction = (payload) => {
  return (dispatch) => {
    Axios.get(`/Subject/LoadSubject/${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_SUBJECT_BY_ID_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_SUBJECT_BY_ID_FAIL,
          payload: error.message,
        });
      });
  };
};

export const updateSubjectAction = (payload, onSuccess) => {
  return (dispatch) => {
    Axios.put(`/Subject/UpdateSubject`, payload)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_SUBJECT_SUCCESS, payLoad: res.data });
        if (res.data.state == 1) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_SUBJECT_BY_ID_FAIL,
          payload: error.message,
        });
      });
  };
};

export const deactivateSubjectAction = (payload, onSuccess) => {
  return (dispatch) => {
    Axios.post(
      `/Subject/DeactivateSubject?subjectId=${payload.subjectId}&profileId=${payload.profileId}`
    )
      .then((res) => {
        dispatch({ type: actionTypes.ADD_SUBJECT_SUCCESS, payLoad: res.data });
        if (res.data.state == 1) {
          onSuccess(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getALlExamType = () => {
  return (dispatch) => {
    Axios.get(`/Exam/GetAllExamType`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_EXAM_TYPE,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const GetAllExamSeries = () => {
  return (dispatch) => {
    Axios.get(`/Exam/GetAllExamSeries`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_EXAM_SERIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const GetExamByExamId = (examId) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_EXAM, payLoad: true });
    Axios.get(`/Exam/GetExam/${examId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_EXAM_BY_EXAMID,
          payLoad: response.data,
        });
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
      });
  };
};

/*==== Delete Exam
{
  "id": "string",
  "adminProfileId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
}
*/
export const deleteExamAction = (payLoad, onSuccess = () => {}) => {
  return (dispatch) => {
    Axios.post(`/ManageExam/DeleteExam`, payLoad)
      .then((res) => {
        dispatch({ type: actionTypes.DELETE_EXAM_SUCCESS, payLoad: res.data });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.DELETE_EXAM_FAIL, payLoad: error });
      });
  };
};

export const getAllSubjectPaths = () => {
  return (dispatch) => {
    Axios.get(`/Subject/AllSubjectPaths`)
      .then((res) => {
        dispatch({ type: actionTypes.ALL_SUBJECT_PATHS, payLoad: res.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getQuestionSectionByExamId = (examId) => {
  return (dispatch) => {
    Axios.get(`/ManageExam/GetSectionByExamId/${examId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_QUESTION_SECTION_BY_EXAMID,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_QUESTION_SECTION_BY_EXAMID_FAIL,
          payload: error.message,
        });
      });
  };
};

export const AddNewSection = (payload, onSuccess = () => {}) => {
  return (dispatch) => {
    Axios.post(`/ManageExam/AddSection`, payload)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_NEWSECTION_SUCCESS,
          payload: res.data,
        });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        }
        dispatch({ type: actionTypes.LOAD_EXAM, payload: false });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.ADD_NEWSECTION_FAIL, payload: error });
        console.error(error);
      });
  };
};
export const UpdateQuestionSection = (payload, onSuccess = () => {}) => {
  return (dispatch) => {
    Axios.post(`/ManageExam/UpdateSection`, payload)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_QUESTIONSECTION_SUCCESS,
          payload: res.data,
        });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        }
        dispatch({ type: actionTypes.LOAD_EXAM, payLoad: false });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_QUESTIONSECTION_FAIL,
          payload: error,
        });
        console.error(error);
      });
  };
};
export const DeleteQuestionSection = (payload, onSuccess = () => {}) => {
  return (dispatch) => {
    Axios.post(
      `/ManageExam/DeleteSection?ProfileId=${payload.ProfileId}&SectionId=${payload.SectionId}`,
      payload
    )
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_QUESTIONSECTION_SUCCESS,
          payload: res.data,
        });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        }
        dispatch({ type: actionTypes.LOAD_EXAM, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_QUESTIONSECTION_FAIL,
          payload: error,
        });
        console.error(error);
      });
  };
};

export const getTopicsBySubjectId = (subjectId) => {
  return (dispatch) => {
    Axios.get(`/Subject/AdminGetTopicsBySubjectId?subjectId=${subjectId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_TOPIC_BY_SUBJECTID,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_TOPIC_BY_SUBJECTID_FAILED,
          payload: error.message,
        });
      });
  };
};

export const AddSubjectTopics = (payload, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_TOPIC_LOADING, payload: true });
    Axios.post(`/Subject/AddTopics`, payload)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_TOPIC_SUCCESS,
          payload: res.data,
        });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        }
      })
      .catch((error) => {
        dispatch({ type: actionTypes.ADD_TOPIC_FAILED, payload: error });
        console.error(error);
      });
  };
};
export const UpdateSubjectTopics = (payload, onSuccess = () => {}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_TOPIC_LOADING, payload: true });
    Axios.put(`/Subject/UpdateTopics`, payload)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_TOPIC_SUCCESS,
          payload: res.data,
        });
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_TOPIC_FAILED,
          payload: error,
        });
        console.error(error);
      });
  };
};
export const DeleteSubjectTopics = (
  payload,
  onSuccess = () => {},
  onFail = () => {}
) => {
  return (dispatch) => {
    Axios.post(`/Subject/DeleteTopics?topicId=${payload.topicId}`, payload)
      .then((res) => {
        if (res.data?.state == 1) {
          onSuccess(res.data?.data);
        } else {
          onFail(res?.data?.msg);
        }
      })
      .catch((error) => {
        onFail(error?.message);
        console.error(error);
      });
  };
};
