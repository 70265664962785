import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SchoolSidebar from "../components/sidebars/SchoolSidebar";
import {
  Avatar,
  Badge,
  Button,
  Stack,
  SwipeableDrawer,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import AccountPopover from "../components/dashboards/AccountPopover";
import NotificationsPopover from "../components/dashboards/NotificationsPopover";
import { NigeriaSvgIcon } from "../assets/icons/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import TotalPointsBox from "../components/dashboards/students/TotalPoints";
import TotalBadgesBox from "../components/dashboards/students/TotalBadges";
import TotalStreaksBox from "../components/dashboards/students/TotalStreaks";
import TotalDiamondsBox from "../components/dashboards/students/TotalDiamonds";
import missionbox from "../assets/images/missionbox2.gif";
import { ReactComponent as SchoolIcon } from "../assets/images/SchoolIcon.svg";
import WeeklyMission from "../components/Modal/WeeklyMissions/WeeklyMission";
import WeeklyTrivia from "../components/Modal/WeeklyTrivia/WeeklyTrivia";
import { calculateTimeRemaining } from "../helpers/helper";
import QuizIcon from "@mui/icons-material/Quiz";
import Preloader from "../components/Preloader/Preloader";
import NetworkPanel from "../components/Network/NetworkPanel";
import useDashboard from "../hooks/useDashboard";
import useUser from "../hooks/useUser";
import { fetchProfileDetailsAction } from "../redux/auth/authActions";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

const Main = styled("main", {
  shouldForwardProp: (prop) => !["open", "noPadding"].includes(prop),
})(
  // const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, noPadding }) => ({
    flexGrow: 1,
    padding: noPadding ? 0 : theme.spacing(3),
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#1a1a1a",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxShadow: "none",
  position: "fixed",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // background: 'white',
  color: "black",
  border: "1px solid #F2F2F2",
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const noPaddingRoutes = ["/dashboard/leaderboard", "/dashboard/learning/study"];

const SchoolDashboardLayout = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [drawer, setDrawer] = React.useState(false);
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [openPostTriviaDialog, setOpenPostTriviaDialog] = React.useState(false);
  const [goBack, setGoBack] = React.useState(false);
  const { schoolDashboardStat, isLoadingSchStat } = useDashboard();
  const { userInfo } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  React.useEffect(() => {
    const userProfileId = userInfo?.profileId;
    if (userProfileId) {
      dispatch(fetchProfileDetailsAction(userProfileId));
    }
  }, [userInfo?.profileId]);

  React.useEffect(() => {
    if (userInfo?.isOnboardComplete && userInfo?.isSchoolAdmin) {
      navigate("/school/dashboard");
    }

    if (userInfo?.isOnboardComplete && userInfo?.isSponsor) {
      navigate("/sponsor/dashboard");
    }
    if (
      userInfo?.isOnboardComplete &&
      !userInfo?.isSchoolAdmin &&
      !userInfo?.isSponsor
    ) {
      navigate("/dashboard/home");
    }
  }, [
    userInfo?.isOnboardComplete,
    userInfo?.isSchoolAdmin,
    userInfo?.isSponsor,
  ]);

  const [openMissions, setOpenMissions] = React.useState(false);

  const handleClickOpenMissions = () => {
    setOpenMissions(true);
  };
  const handleClickOpenTrivia = () => {
    setOpenTrivia(true);
  };
  const handleCloseMissions = () => {
    setOpenMissions(false);
  };

  const handleCloseTrivia = () => {
    setOpenTrivia(false);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(value);
  };

  const [timeRemaining, setTimeRemaining] = React.useState(
    calculateTimeRemaining()
  );

  React.useEffect(() => {
    const targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 5);
    targetDate.setHours(targetDate.getHours() + 8);
    targetDate.setMinutes(targetDate.getMinutes() + 23);

    const intervalId = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining(targetDate);
      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining.total <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <div className="hidden lg:flex">
            <SchoolSidebar open={open} setOpen={setOpen} />
          </div>

          <AppBar
            position="fixed"
            open={open}
            className={`lg:ml-${open ? "0" : "240"} transition-all ease-in-out`}
          >
            <NetworkPanel />
            <Toolbar>
              <div className="flex justify-between items-center w-full">
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon color="#40499D" />
                </IconButton>

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems={"center"}
                  sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    justifyContent: "space-between",
                  }}
                >
                  {!isMobile && (
                    <div className="">
                      <h3 style={{ fontWeight: 600, color: "#45464E" }}>
                        Welcome, {userInfo ? userInfo?.firstName : "School"}
                      </h3>
                    </div>
                  )}

                  <div className="flex items-center">
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        boxShadow:
                          "0px 6.745px 36.42px 0px rgba(128, 144, 155, 0.05)",
                        border: "1px solid var(--Lightbg, #EAECFF)",
                        padding: "7px",
                        borderRadius: "10px",
                      }}
                    >
                      <SchoolIcon />
                      {!isMobile && (
                        <h3 style={{ fontWeight: 600, color: "#40499D" }}>
                          {userInfo.schoolName}
                        </h3>
                      )}
                    </Box>
                    <AccountPopover />
                  </div>
                </Stack>
              </div>
            </Toolbar>
          </AppBar>

          <Main
            noPadding={location.pathname === "/dashboard/learning/study"}
            open={open}
            className={`transition-all ease-in-out ml-${open ? "0" : "240"}`}
            style={{
              overflowX: "hidden",
              backgroundColor: "#FAFAFB",
              minHeight: "98.8vh",
            }}
          >
            <DrawerHeader />
            <section className="w-full">{children}</section>
          </Main>

          <WeeklyMission
            open={openMissions}
            handleClose={handleCloseMissions}
            timeRemaining={timeRemaining}
          />

          <SwipeableDrawer
            anchor="left"
            open={drawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <SchoolSidebar open={open} setOpen={setOpen} />
          </SwipeableDrawer>
        </Box>
      )}
    </>
  );
};

export default SchoolDashboardLayout;
