import React from "react";

const Line = () => {
  return (
    <svg
      width="176"
      style={{
        display: "inline",
        position: "absolute",
        bottom: "-20",
        right: "0",
      }}
      height="20"
      viewBox="0 0 176 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17C29.38 8.66777 100.253 -4.66947 172.707 8.63933"
        stroke="#F8EF07"
        strokeWidth="5.46341"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const PricingLine = () => {
  return (
    <svg
      width="176"
      style={{
        display: "inline",
        position: "absolute",
        bottom: "-20",
        right: "0",
      }}
      height="20"
      viewBox="0 0 176 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17C29.38 8.66777 100.253 -4.66947 172.707 8.63933"
        stroke="#F8EF07"
        strokeWidth="5.46341"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Line;
