import {
  FECTCH_CLASS_LEVEL,
  FECTCH_CLASS_LEVEL_FAIL,
  ADD_NEW_STUDENT,
  ADD_NEW_STUDENTS,
  LOAD_STUDENT,
  GET_ALL_STUDENT,
  GET_ALL_SCHOOL_BY_SPONSOR_ID,
  GET_ALL_SCHOOL_BY_SPONSOR_ID_FAIL,
  GET_ALL_STUDENT_BY_SPONSOR_ID,
  GET_ALL_SPONSORS_BY_SCHOOL_ID_SUCCESS,
  GET_ALL_SPONSORS_BY_SCHOOL_ID_FAIL,
  GET_STUDENT_PROFILE_ID,
  GET_STUDENT_PROFILE_ID_FAIL,
  RESEND_STUDENT_MAIL,
  EDIT_MANAGE_STUDENT,
  GET_ALL_DEVICES,
  GET_ALL_DEVICES_FAIL,
  SUPER_SCHOOL_USER_MAIL,
} from "./types";

const initialState = {
  loading: false,
  classLevel: null,
  classLoaded: false,
  studentsLoaded: false,
  error: null,
  newStudent: null,
  // newStudents: null,
  allStudent: [],
  allStudentBySponorById: [],
  allSchoolsBySponsor: [],
  allSponsorsBySchool: [],
  studentById: {},
  resendStudMail: null,
  editStudent: null,
  getAllDevices: [],
  sendSuperSchoolUserEmail: null,
};
const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case FECTCH_CLASS_LEVEL:
      return {
        ...state,
        laoding: false,
        classLevel: action.payload.data,
      };
    case FECTCH_CLASS_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        classLevel: null,
        error: action.payload,
      };
    case ADD_NEW_STUDENT:
      return {
        ...state,
        loading: false,
        newStudent: action.payload.data,
      };
    case ADD_NEW_STUDENTS:
      return {
        ...state,
        loading: false,
        newStudents: action.payload.data,
      };
    case EDIT_MANAGE_STUDENT:
      return {
        ...state,
        loading: false,
        editStudent: action.payload.data,
      };
    case GET_ALL_STUDENT:
      return {
        ...state,
        loading: false,
        allStudent: action.payload.data,
      };
    case GET_STUDENT_PROFILE_ID:
      return {
        ...state,
        loading: false,
        studentById: action.payload.data,
      };
    case GET_STUDENT_PROFILE_ID_FAIL:
      return {
        ...state,
        loading: false,
        studentById: null,
        error: action.payload,
      };
    // for  both sponsor and school
    case RESEND_STUDENT_MAIL:
      return {
        ...state,
        loading: false,
        resendStudMail: action.payload,
      };
    case SUPER_SCHOOL_USER_MAIL:
      return {
        ...state,
        loading: false,
        sendSuperSchoolUserEmail: action.payload,
      };

    //*********************************Reducer for Sponor starts here */
    case GET_ALL_SCHOOL_BY_SPONSOR_ID:
      return {
        ...state,
        loading: false,
        allSchoolsBySponsor: action.payload.data,
      };
    case GET_ALL_SCHOOL_BY_SPONSOR_ID_FAIL:
      return {
        ...state,
        loading: false,
        allSchoolsBySponsor: null,
      };

    case GET_ALL_SPONSORS_BY_SCHOOL_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        allSponsorsBySchool: action.payload.data,
      };
    case GET_ALL_SPONSORS_BY_SCHOOL_ID_FAIL:
      return {
        ...state,
        loading: false,
        allSponsorsBySchool: null,
      };

    case GET_ALL_STUDENT_BY_SPONSOR_ID:
      return {
        ...state,
        loading: false,
        allStudentBySponorById: action.payload.data,
      };
    //*********************************Reducer for Sponor ends here */
    case LOAD_STUDENT:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_DEVICES:
      return {
        ...state,
        loading: false,
        getAllDevices: action.payload.data,
      };
    case GET_ALL_DEVICES_FAIL:
      return {
        ...state,
        loading: false,
        getAllDevices: null,
      };

    default:
      return state;
  }
};
export default schoolReducer;
