import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";

const RevenueChart = ({ adminDashboardChart, usersCurrency }) => {

  const usersCurrencyRef = useRef(usersCurrency);
  const formatCurrency = (value, currency) => {
    if (currency === "NG") {
      return `${value} Naira`;
    } else if (currency === "USD") {
      return `$${value} Dollar`;
    }
    return value;
  };



  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      colors: ['#40499d', '#e0c6fd'],
      stroke: {
        curve: "smooth",
        width: 3,
        colors: ['#4A3AFF'],
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 0.2,
          stops: [0, 49, 100], // Adjust gradient stops
          colorStops: [
            {
              offset: 0,
              color: '#40499d', // Start color
              opacity: 0.3
            },
            {
              offset: 49,
              color: '#cfcaff', // Middle color
              opacity: 0.2
            },
            {
              offset: 100,
              color: '#ffffff', // End color
              opacity: 0.1
            }
          ]
        }
      },
      labels: [],
      grid: {
        padding: {
          left: 30,
          right: 30,
          top: 30
        }
      },
      chart: {
        zoom: {
          enabled: false,
          type: 'x',
          autoScaleYaxis: false,

        },
        toolbar: {
          // show: true,
          // offsetX: 0,
          offsetY: 10,
          tools: {
            download: true,
            selection: false,
            download: '<img src="/images/downLoadIcon.svg""  width="20">',
            // reset: true | '<img src="/assets/images/AllStudentsIcon.svg"  width="20">',
            // customIcons: [{
            //   icon: '<img src="/images/A+.png"  width="20">',
            //   // index: 4,
            //   title: 'tooltip of the icon',
            //   class: 'custom-icon',
            //   click: function (chart, options, e) {
            //     console.log("clicked custom-icon")
            //   }
            // }]
          },

        },
      },
      markers: {
        size: 0,
      },
      yaxis: [
        {
          opposite: true,
          title: {
            text: "Revenue",
          },
        },
      ],

      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const yValue = w.globals.series[seriesIndex][dataPointIndex];
          const formattedValue = formatCurrency(yValue, usersCurrencyRef.current);
          return `<div class="arrow_box">
                    <span>${"Revenue"} ${formattedValue}</span>
                  </div>`;

        }
      }
    },
  });

  useEffect(() => {
    usersCurrencyRef.current = usersCurrency;
    if (adminDashboardChart && adminDashboardChart.sales) {
      const allYears = adminDashboardChart.sales.map(data => data.month);
      const allMonths = allYears.map(month => month?.toUpperCase()?.substring(0,3));
      const allMonthlySales = adminDashboardChart.sales.map(data => data.sales);
      const allSales = allMonthlySales.map(sales => sales);

      setChartData(prevState => ({
        series: [
          {
            name: "Sales",
            type: "area",
            // color: "#e0c6fd",
            data: allSales
          },
        ],
        options: {
          ...prevState.options,
          labels: allMonths,

          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              const yValue = w.globals.series[seriesIndex][dataPointIndex];
              const formattedValue = formatCurrency(yValue, usersCurrencyRef.current);
              return `<div class="arrow_box">
                    <span>${"Revenue"} ${formattedValue}</span>
                  </div>`;
            }
          }

        },
      }));
    }
  }, [adminDashboardChart, usersCurrency]);

  if (!adminDashboardChart || !adminDashboardChart.sales) {
    return <div>Loading...</div>;
  }
  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default RevenueChart;
