import { 
  ALL_ADMIN_USERS_START, ALL_ADMIN_USERS_SUCCESS, ALL_ADMIN_USERS_FAIL,
  ALL_ADMIN_ROLES_START,
  ALL_ADMIN_ROLES_SUCCESS,
  ALL_ADMIN_ROLES_FAIL,
  ADD_SINGLE_ADMIN_START,
  ADD_SINGLE_ADMIN_SUCCESS,
  ADD_SINGLE_ADMIN_FAIL,
  ASSIGN_ADMIN_ROLE_START,
  ASSIGN_ADMIN_ROLE_SUCCESS,
  ASSIGN_ADMIN_ROLE_FAIL,
  ADD_ROLE_START,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  ALL_SITE_PAGES_START,
  ALL_SITE_PAGES_SUCCESS,
  ALL_SITE_PAGES_FAIL,
  ADD_PAGES_START,
  ADD_PAGES_SUCCESS,
  ADD_PAGES_FAIL,
  ALL_SITE_PAGES_ROLE_SUCCESS,
  ALL_SITE_PAGES_ROLE_FAIL,
  ADMIN_SITE_PAGES_ROLE_SUCCESS,
  ADMIN_SITE_PAGES_ROLE_FAIL,
  ADMIN_SITE_PAGES_ROLE_START,
} from "./types";

const initialState = {
  loading: false,
  adminUsersData: [],
  error: null,
  adminRoles: [],
  sitePages: [],
  roleSitePages: [],
  roleSitePages2: [],
  pagesLoading: false,
  site_page_loading: false,
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case ALL_ADMIN_USERS_START:
        return { ...state, loading: true };

    case ALL_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminUsersData: action.payload.data,
        error: null,
      };
     
    case ALL_ADMIN_USERS_FAIL:
      return {
        ...state,
        loading: false,
        adminUsersData: [],
        error: action.payload,
      };
    
    case ALL_ADMIN_ROLES_START: 
      return { ...state, loading: true };
    
      case ALL_ADMIN_ROLES_SUCCESS:
        return {
          ...state,
          loading: false,
          adminRoles: action.payload.data,
          error: null,
        };
       
      case ALL_ADMIN_ROLES_FAIL:
        return {
          ...state,
          loading: false,
          adminRoles: [],
          error: action.payload,
        };
        case ADD_SINGLE_ADMIN_START: 
        return { ...state, loading: true };
      
        case ADD_SINGLE_ADMIN_SUCCESS:
          return {
            ...state,
            loading: false,
            error: null,
          };
         
        case ADD_SINGLE_ADMIN_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
      };
    
      case ASSIGN_ADMIN_ROLE_START: 
      return { ...state, loading: true };
    
      case ASSIGN_ADMIN_ROLE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
       
      case ASSIGN_ADMIN_ROLE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
      };
    
      case ADD_ROLE_START: 
      return { ...state, loading: true };
    
      case ADD_ROLE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
       
      case ADD_ROLE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
      };
    
      case ALL_SITE_PAGES_START: 
      return { ...state, site_page_loading: true };
    
      case ALL_SITE_PAGES_SUCCESS:
        return {
          ...state,
          site_page_loading: false,
          error: null,
          sitePages: action.payload.data,
        };
       
      case ALL_SITE_PAGES_FAIL:
        return {
          ...state,
          site_page_loading: false,
          error: action.payload,
          sitePages: [],
      };
    
      case ALL_SITE_PAGES_ROLE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          roleSitePages: action.payload.data,
        };
       
      case ALL_SITE_PAGES_ROLE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          roleSitePages: [],
      };
    
      case ADD_PAGES_START: 
      return { ...state, loading: true };
    
      case ADD_PAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
       
      case ADD_PAGES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
      };
    
      case ADMIN_SITE_PAGES_ROLE_START:
        return {
          ...state,
          pagesLoading: true,
          error: null,
        };
       
    
      case ADMIN_SITE_PAGES_ROLE_SUCCESS:
        return {
          ...state,
          pagesLoading: false,
          error: null,
          roleSitePages2: action.payload.data
        };
       
    case ADMIN_SITE_PAGES_ROLE_FAIL:
      return {
        ...state,
        pagesLoading: false,
        error: action.payload,
        roleSitePages2: []
      };
  
    default:
      return state;
  }
};
export default permissionsReducer;


