import Axios from "../../connection/defaultClient";
import * as actionTypes from "./types";

export const loadAdminUsersAction = () => {
  return (dispatch) => {

    Axios.get(`/ManageAdmin/LoadAdmins`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_ADMIN_USERS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_ADMIN_USERS_FAIL,
          payload: error.message,
        });
      });
  };
};


export const getAllAdminRoles = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ALL_ADMIN_ROLES_START });
    Axios.get(`/Role/LoadRoles`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_ADMIN_ROLES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_ADMIN_ROLES_FAIL,
          payload: error.message,
        });
      });
  };
};


export const addSingleAdminUser = (data, onSuccess=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_SINGLE_ADMIN_START });
    Axios.post(`/ManageAdmin/CreateNewAdminOfficer`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_SINGLE_ADMIN_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_SINGLE_ADMIN_FAIL,
          payload: error.message,
        });
      });
  };
};

export const deactivateAdminUser = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.ADD_SINGLE_ADMIN_START });
    Axios.post(`/ManageAdmin/ModifyAdmin`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_SINGLE_ADMIN_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess(response.data?.data);
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_SINGLE_ADMIN_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};


export const  assignAdminToRole = (data, onSuccess=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ASSIGN_ADMIN_ROLE_START });
    Axios.post(`/Role/AssignRoleToAdmin`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ASSIGN_ADMIN_ROLE_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ASSIGN_ADMIN_ROLE_FAIL,
          payload: error.message,
        });
      });
  };
};

export const  addRole = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_ROLE_START });
    Axios.post(`/Role/AddRole`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_ROLE_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess(response.data?.data);
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_ROLE_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};


export const updateRole = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_ROLE_START });
    Axios.put(`/Role/UpdateRole`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_ROLE_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess(response.data?.data);
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_ROLE_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};

export const updateRolePermissions = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_ROLE_START });
    Axios.post(`/Role/UpdateRolePermissions`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_ROLE_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess(response.data?.data);
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_ROLE_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};

export const deleteRole = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_ROLE_START });
    Axios.delete(`/Role/DeleteRole`, {data: data})
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_ROLE_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess();
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_ROLE_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};


export const deactivateRole = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_ROLE_START });
    Axios.post(`/Role/DeactivateRole`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_ROLE_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess();
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_ROLE_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};


export const getAllSitePages = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ALL_SITE_PAGES_START });
    Axios.get(`/SitePage/LoadPages`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_SITE_PAGES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_SITE_PAGES_FAIL,
          payload: error.message,
        });
      });
  };
};

export const getAllSitePagesByRoleId = (roleId) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ALL_SITE_PAGES_START });
    Axios.get(`/Role/LoadRolePermission/${roleId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_SITE_PAGES_ROLE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ALL_SITE_PAGES_ROLE_FAIL,
          payload: error.message,
        });
      });
  };
};

export const getAdminSitePagesByRoleId = (roleId, onSuccess = () => { }, onFail = () => { }) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SITE_PAGES_ROLE_START });
    Axios.get(`/Role/LoadRolePermission/${roleId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.ADMIN_SITE_PAGES_ROLE_SUCCESS,
          payload: response.data
        });
        if (response.data.state == 1) {
         onSuccess(response.data.data)
        } else {
          dispatch({
            type: actionTypes.ADMIN_SITE_PAGES_ROLE_FAIL,
            payload: response.data.msg
          });
          onFail(response.data.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADMIN_SITE_PAGES_ROLE_FAIL,
          payload: error.message
        });
        onFail(error.message)
      });
  };
};

export const addSitePages = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.ADD_PAGES_START });
    Axios.post(`/SitePage/AddSitePage`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_PAGES_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess(response.data?.data);
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_PAGES_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};

export const updateSitePages = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
      dispatch({ type: actionTypes.ADD_PAGES_START });
    Axios.post(`/SitePage/UpdateSitePage`, data)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_PAGES_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess(response.data?.data);
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_PAGES_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};



export const deleteSitePages = (data, onSuccess=()=>{}, onFail=()=>{}) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_PAGES_START });
    Axios.delete(`/SitePage/DeleteSitePage?PageId=${data.PageId}&ProfileId=${data.ProfileId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_PAGES_SUCCESS,
          payload: response.data,
        });
        if (response.data?.state == 1) {
          onSuccess();
        } else {
          onFail(response.data?.msg)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_PAGES_FAIL,
          payload: error.message,
        });
        onFail(error.message);
      });
  };
};