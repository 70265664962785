import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import MUIDataTable from "mui-datatables";
import LoadingOverlay from "react-loading-overlay-ts";
import AddIcon from "@mui/icons-material/Add";
import { DownloadIcon, EmptyRowIcon } from "../../assets/icons/Icons";
import { handleExport } from "../../helpers/helper";

const DataTable = ({
  tableName,
  data,
  columns,
  action,
  download,
  search,
  searchPlaceHolder,
  loading,
  serverSide,
  count,
  icon,
  changePage,
  page,
  tableOptions,
  topMargin,
  formatData,
  fileName
}) => {
  const paginationOptions = {
    ...tableOptions,
    sort: false,
    filter: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    search: true,
    print: false,
    download: download ?? false,
    viewColumns: false,
    serverSide: serverSide ?? true,
    count: count ?? 0,
    ...(page && { page: page }),
    jumpToPage: Boolean(true),
    responsive: "standard",
    disableRipple: true,
    elevation: 0,
    filterType: "dropdown",
    fixedHeader: true,
    tableBodyHeight: 'auto',
    tableBodyMinHeight: '600px',
    tableBodyMaxHeight: '1000px',
    selectableRows: "none",
    checkbox: false,
    textLabels: {
      body: {
        // noMatch: showNoDataMessage ? <EmptyRowIcon /> : "",
        noMatch: icon ?? <EmptyRowIcon />,
        toolTip: "Sort",
        // columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download sheet",
        print: "Print page",
        filterTable: "Filter table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    customToolbar: () => {
      return (
        <>
          {/*     <CustomButton
            boxshadow="0px 4px 21px rgba(85, 101, 255, 0.20)"
            color="primary"
            backgroundcolor="#535ca4"
            text="Add Category"
            startIcon={<AddIcon />}
          /> */}
          <Box display="flex" alignItems="center" justifyContent={'end'}>
            {/* Custom download button */}
            <IconButton
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => {
                // Handle download logic here
                handleExport(formatData ?? data, fileName?? '')
              }}

            >
              <DownloadIcon
              />
            </IconButton>
          </Box>
        </>
      );
    },
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        changePage && changePage(tableState);
      }
    },
  };
  return (
    <Box sx={{ mt: topMargin ? 0 : 4, position: 'relative' }}>
      {action && (
        <Box display="flex" justifyContent="flex-end" mb={3}>
          {action}
        </Box>
      )}
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0.15)",
          }),
        }}
        spinner
        text="Fetching Data for You..."
      >
        <Box sx={{
          overflowX: 'auto',
          '& .MuiPaper-root': {
            overflowX: 'auto',
            '& .MUIDataTable-tableContainer': {
              maxWidth: '100%',
            }
          }
        }}>
          <MUIDataTable
            // className='whitespace-nowrap'
            title={
              <Box
                display="flex"
                alignItems="center"
                gap={10}
                justifyContent="space-between"
              >
                {tableName ? (
                  <Typography sx={{ color: "#121926", fontWeight: 600 }}>
                    {tableName ?? ""}
                  </Typography>
                ) : (
                  ""
                )}
                {/* <TableSearch
                placeholder={searchPlaceHolder ?? ""}
                onChange={handleChange}
                value={searchText}
              /> */}
              </Box>
            }
            data={data ?? []}
            columns={columns ?? []}
            options={paginationOptions}
            style={{
              transition: "0.7s",
              ".MuiToolbar-root": { minHeight: "0px !important" },
            }}
          />
        </Box>
      </LoadingOverlay>
    </Box>
  );
};

export default DataTable;
