import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import DataTable from "../../../components/DataTable/DataTable";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { MoreIcon } from "../../../assets/icons/Icons";
import AvatarWithText from "../../../components/Avatar/AvatarWithText";
import CustomizedDialogs from "../../../components/StudentManagement/Dialog";

export default function TemplateStudentsTable({
  open,
  handleClose,
  handleOpen,
  modalContent,
  excelData,
  apiMsg,
  failedexcelData,
}) {
  const columns = [
    {
      name: "S/N",
    },
    {
      name: "First Name",
    },
    // {
    //     name: "Middle Name",
    //     options: {
    //         setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
    //     },
    // },
    {
      name: "Last Name",
      options: {
        setCellProps: () => ({ style: { minWidth: "130px", padding: "20px" } }),
      },
    },
    {
      name: "Email",
    },
    // {
    //     name: "Class",
    // },
  ];
  const column2 = [
    {
      label: "Row Number",
      name: "rowNums",
      options: {
        setCellProps: () => ({ style: { color: "red" } }),
      },
    },
    {
      label: "First Name",
      name: "firstName",
      options: {
        setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
      },
    },
    // {
    //     label: "Middle Name",
    //     name: "otherName",
    //     options: {
    //         setCellProps: () => ({ style: { minWidth: "180px", padding: "20px" } }),
    //     },
    // },
    {
      lable: "Last Name",
      name: "lastName",
      options: {
        setCellProps: () => ({ style: { minWidth: "130px", padding: "20px" } }),
      },
    },
    {
      name: "studentEmail",
      label: "Email",
      options: {
        setCellProps: () => ({
          style: { minWidth: "100px", padding: "20px", color: "red" },
        }),
      },
    },
    // {
    //     label: "Class",
    //     name: "classLevel",
    //     options: {
    //         setCellProps: () => ({ style: { minWidth: "100px", padding: "20px" } }),
    //     },
    // },
  ];
  return (
    <>
      {apiMsg && (
        // <TableRow>
        <TableCell colSpan={columns.length} style={{ color: "red", mb: 0 }}>
          {apiMsg}
        </TableCell>
        // </TableRow>
      )}
      {apiMsg ? ( // Check if there's a failure
        <DataTable
          data={failedexcelData} // Display failed data when there's a failure
          columns={column2}
          selectableRowsVisible={false}
          sx={{ mt: 0 }}
        />
      ) : (
        <DataTable
          data={excelData} // Display uploaded data when there's no failure
          columns={columns}
          selectableRowsVisible={false}
          sx={{ mt: 0 }}
        />
      )}
    </>
  );
}
