import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { ReactComponent as Coin } from "../../assets/images/coin.svg";
import useGamification from "../../hooks/useGamification";

export function LeaderboardTable({ gamificationData, loading }) {
  return (
    <TableContainer
      sx={{
        width: "94%",
        mx: "auto",
        minHeight: "25rem",
        maxHeight: "50rem",
        overflowY: "scroll",
        scrollbarWidth: "none",
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
      }}
      component={Paper}
    >
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        className="relative"
      >
        <TableHead className="bg-gradient-to-b from-white via-[#40499D] via-10% rounded-t-lg to-[#40499D] sticky top-0 ">
          <TableRow>
            <TableCell sx={{ color: "white" }}>Rank</TableCell>
            <TableCell sx={{ color: "white" }}>Username</TableCell>
            <TableCell sx={{ color: "white" }}>Department</TableCell>
            <TableCell sx={{ color: "white" }} align="center">
              Completed Tests
            </TableCell>
            <TableCell sx={{ color: "white" }} align="right">
              Points earned
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? Array.from(new Array(7)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" animation="wave" />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton variant="text" animation="wave" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" animation="wave" />
                  </TableCell>
                </TableRow>
              ))
            : gamificationData?.length > 0 &&
              gamificationData.map((row, index) => (
                <TableRow
                  key={row?.profileId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {row?.name?.split(" ").slice(0, 2).join(" ")}
                  </TableCell>
                  <TableCell>{row?.path}</TableCell>
                  <TableCell align="center">{row?.completedTest}</TableCell>
                  <TableCell
                    sx={{ display: "flex", alignItems: "center" }}
                    align="right"
                  >
                    <span style={{ marginLeft: "5px" }}>{row?.points}</span>
                    <Coin />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
