import { Box, Button, Card, CardContent, CardHeader, Paper } from '@mui/material'
import React from 'react'
import BasicTable from './StudyTimeTable'
import NoItem from "../../../../assets/images/noItem.png"
import EmptyGroup from "../../../../assets/images/emptyGroup.png"

const StudentStudyTime = ({schoolStudyTime}) => {
  return (
    <Box>
                <Paper
                  sx={{
                    border: "1px solid rgba(145, 158, 171, 0.16)",
                    borderRadius: "20px",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      padding: "0px !important",
                      
                    }}
                  >
                    <CardHeader
                      sx={{ fontSize: "1px" }}
                      titleTypographyProps={{
                        variant: "body1",
                        color: "#05004E",
                        fontWeight: 600,
                      }}
                      title="Student's Study Time"
                    //   action={
                    //     <Button
                    //       to="#"
                    //     //   component={RouterLink}
                    //       size="small"
                    //       disableElevation
                    //       variant="contained"
                    //       sx={{
                    //         background: "#016c34",
                    //         textTransform: "capitalize",
                    //         fontSize: "12px",
                    //       }}
                    //     >
                    //       View
                    //     </Button>
                    //   }
                    />
                    <CardContent sx={{ padding: "0px !important", maxHeight: "240px",overflowY: "hidden",
                      "&:hover": {
                        overflowY: "auto",
                      }, }}>
                       { (!Array.isArray(schoolStudyTime) || schoolStudyTime?.length === 0) ? 
 <p className=" flex flex-col text-center text-[14px]  items-center justify-center text-[#A3AED0]" style={{paddingTop:"40px", paddingBottom:"40px"}}>
 <img src={NoItem} alt="" width={"100px"} height={"auto"}/>
No student available
</p> : 
                      <BasicTable schoolStudyTime={schoolStudyTime}/>
}
                    </CardContent>
                  </Card>
                </Paper>
              </Box>
  )
}

export default StudentStudyTime