import React from "react";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EastIcon from "@mui/icons-material/East";
import { convertDaysToMonths, formatCurrency } from "../../helpers/helper";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Box)(({ theme, middle }) => ({
  backgroundColor: middle ? "#40499D" : "white",
  color: middle ? "white" : "#313131",
  borderRadius: "20px",
  border: "1px solid var(--Lightbg, #EAECFF)",
  boxShadow: "0px 2px 12px 0px rgba(20, 20, 43, 0.08)",
  height: "100%",
  width: "19rem",
  padding: "30px 20px",
  fontFamily: "Montserrat",
}));

const StyledButton = styled(Button)(({ theme, middle }) => ({
  fontFamily: "Montserrat",
  marginTop: "30px",
  color: middle ? "#40499D" : "white",
  borderColor: middle ? "#40499D" : "white",
  backgroundColor: middle ? "white" : "#40499D",
  textTransform: "capitalize",
  padding: "10px",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: middle ? "#ccc" : "#2C3582",
    // color: middle ? "white" : "#40499D",
  },
}));

const NewPricingCards = ({ cards, onClick }) => {
  const navigate = useNavigate();
  const middleIndex = Math.floor(cards.length / 2);

  return (
    <>
      <Grid container spacing={4}>
        {cards.map((card, index) => (
          <Grid key={card.PackageName} item xs={12} sm={12} md={4}>
            <StyledCard key={index} middle={index === middleIndex}>
              <Stack>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      fontSize: "16px",
                      borderRadius: "6px",
                      padding: "5px 10px",
                      bgcolor: index === middleIndex ? "white" : "#EAECFF",
                      color: "#313131",
                    }}
                  >
                    {card?.PackageName}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: index === middleIndex ? "white" : "#919191",
                    pt: 2,
                  }}
                >
                  Upon subscribing to our {card?.PackageName} plan, your access commences
                  instantly and extends for a full{" "}
                  {convertDaysToMonths(card?.DaysAllowed)}{" "}
                  {convertDaysToMonths(card?.DaysAllowed) === 1
                    ? "month"
                    : "months"}
                  .
                </Typography>
                <Box display="flex" alignItems="center" pt={2}>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "30px",
                      fontWeight: 800,
                      color: index === middleIndex ? "white" : "#40499D",
                    }}
                  >
                    {formatCurrency(card?.Amount)}
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "Montserrat", fontSize: "12px", pt: 1 }}
                  >
                    / {convertDaysToMonths(card?.DaysAllowed)}{" "}
                    {convertDaysToMonths(card?.DaysAllowed) === 1
                      ? "Month"
                      : "Months"}{" "}
                    / Student
                  </Typography>
                </Box>
                <Box color="#313131" mt={2}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: 600,
                      color: index === middleIndex ? "white" : "#313131",
                    }}
                    component="div"
                  >
                    What's Included:
                  </Typography>
                  <List dense sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters disablePadding>
                      <ListItemIcon sx={{ minWidth: "fit-content", mr: 1 }}>
                        <CheckCircleIcon
                          sx={{
                            color: index === middleIndex ? "white" : "#40499D",
                          }}
                          fontSize="14"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Access to all subjects for ${convertDaysToMonths(
                          card?.DaysAllowed
                        )} ${
                          convertDaysToMonths(card?.DaysAllowed) === 1
                            ? "month"
                            : "months"
                        }`}
                        primaryTypographyProps={{
                          fontFamily: "Montserrat",
                          fontSize: 12,
                          color: index === middleIndex ? "white" : "#313131",
                        }}
                      />
                    </ListItem>
                    <ListItem alignItems="center" disableGutters disablePadding>
                      <ListItemIcon sx={{ minWidth: "fit-content", mr: 1 }}>
                        <CheckCircleIcon
                          sx={{
                            color: index === middleIndex ? "white" : "#40499D",
                          }}
                          fontSize="14"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Access to all subject years"
                        primaryTypographyProps={{
                          fontFamily: "Montserrat",
                          fontSize: 12,
                          color: index === middleIndex ? "white" : "#313131",
                        }}
                      />
                    </ListItem>
                    <ListItem alignItems="center" disableGutters disablePadding>
                      <ListItemIcon sx={{ minWidth: "fit-content", mr: 1 }}>
                        <CheckCircleIcon
                          sx={{
                            color: index === middleIndex ? "white" : "#40499D",
                          }}
                          fontSize="14"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Performance tracking metrics"
                        primaryTypographyProps={{
                          fontFamily: "Montserrat",
                          fontSize: 12,
                          color: index === middleIndex ? "white" : "#313131",
                        }}
                      />
                    </ListItem>
                    <ListItem alignItems="center" disableGutters disablePadding>
                      <ListItemIcon sx={{ minWidth: "fit-content", mr: 1 }}>
                        <CheckCircleIcon
                          sx={{
                            color: index === middleIndex ? "white" : "#40499D",
                          }}
                          fontSize="14"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Ad free studying"
                        primaryTypographyProps={{
                          fontFamily: "Montserrat",
                          fontSize: 12,
                          color: index === middleIndex ? "white" : "#313131",
                        }}
                      />
                    </ListItem>
                  </List>
                </Box>
                <StyledButton
                  endIcon={<EastIcon />}
                  onClick={() => navigate("/auth/signup")}
                  middle={index === middleIndex}
                >
                  Get Started
                </StyledButton>
              </Stack>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NewPricingCards;
