import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { ReactComponent as AllStudentsIcon } from "../../assets/images/AllStudentsIcon.svg";
import { ReactComponent as VerifiedStudents } from "../../assets/images/VerifiedStudentsIcon.svg";
import { ReactComponent as UnverifiedStudents } from "../../assets/images/UnverifiedStudentsIcon.svg";
import { ReactComponent as StudentsStudying } from "../../assets/images/StudentsStudying.svg";
import Cards from "../../components/dashboards/school/schoolDashboardCards";
import TopPerformingStudents from "../../components/dashboards/school/topPerformingStudents";
import TotalAmountCard from "../../components/dashboards/school/totalAmountCard";
import StudentStudyTime from "../../components/dashboards/school/studentStudyTime/studentStudyTime";
import SchoolPath from "../../components/dashboards/school/schoolPaths/schoolPath";
import StudentSubjects from "../../components/dashboards/school/studentsCoreSubjects/StudentsCoreSubjects";
import { useDispatch } from "react-redux";
import useUser from "../../hooks/useUser";
import useDashboard from "../../hooks/useDashboard";
import bgImg from "../../assets/images/schoolBackground.png";
import special_offer from "../../assets/images/Special_Offer.png";

import {
  fetchSchoolDashboardStat,
  fetchSchoolStudyTime,
  fetchStudentsPath,
  fetchStudentsSubjects,
  fetchTopPerformingStudents,
} from "../../redux/dashboard/dashboardActions";
import { getUserPathByCategory } from "../../redux/auth/authActions";
import useSubscription from "../../hooks/useSubscription";
import {
  fetchSchoolSubscriptions,
  fetchSubPlans,
} from "../../redux/subscriptions";
import Payment from "../../components/dashboards/school/specialOfferPay/pay";
import InfoIcon from "@mui/icons-material/Info";
import { AutoAwesome, Verified } from "@mui/icons-material";
import { Book_Icon, Caveat } from "../../assets/images";
import { SpecialOffercardBonusCard } from "../../components/dashboards/school/speicalOffercard";
import { useNotifications } from "../../components/notifications/notifications";

function SchoolDashboard() {
  const dispatch = useDispatch();
  const { userInfo, userPathByCategory } = useUser();
  const {
    schoolDashboardStat,
    isLoadingSchStat,
    topPerformingStudents,
    isLoadingTopStudents,
    studentsPath,
    isLoadingStudentsPath,
    schoolStudyTime,
    isLoadingSchStudyTime,
    studentsSubjects,
  } = useDashboard();
  const subscription = useSubscription();
  const { schoolSub, allSubPlan } = subscription;
  const [currentDate, setCurrentDate] = useState(moment());
  const [path, setPath] = useState("");
  const [selectedPath, setSelectedPath] = useState("");

  const schoolId = userInfo?.schoolId;
  const catId = userInfo?.categoryId;
  const [superSchoolPayment, setSuperSchoolPayment] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [hover, setHover] = useState(false);
  const [ToastState, setToastState] = useNotifications();
  const handleChange = (event) => {
    setPath(event.target.value);
    const topStudentsPayload = {
      schoolId: schoolId,
    };
    if (path) {
      topStudentsPayload.path = path;
    }
    dispatch(fetchTopPerformingStudents(topStudentsPayload));
  };

  const handleChangePath = (event) => {
    setSelectedPath(event.target.value);
  };

  useEffect(() => {
    const topStudentsPayload = {
      schoolId: schoolId,
    };
    if (schoolId) {
      dispatch(fetchSchoolDashboardStat(schoolId));
      dispatch(fetchStudentsPath(schoolId));
      dispatch(fetchTopPerformingStudents(topStudentsPayload));
      dispatch(fetchSchoolStudyTime(schoolId));
      dispatch(fetchStudentsSubjects(schoolId));
      dispatch(getUserPathByCategory(catId));
      dispatch(fetchSchoolSubscriptions(schoolId));
      dispatch(fetchSubPlans(catId));
    }

    const intervalId = setInterval(() => {
      setCurrentDate(moment());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [userInfo]);

  // trying to get the super school subscription details from the array of data from allSubPla and stored in selectedSubscription
  const handleGetStartedClick = () => {
    if (allSubPlan && allSubPlan?.data?.length > 0) {
      const lastSubscriptionModelId =
        allSubPlan?.data[allSubPlan?.data?.length - 1];
      setSelectedSubscription(lastSubscriptionModelId);
      setSuperSchoolPayment(true);
    }
  };

  let cards = [
    {
      icon: <AllStudentsIcon />,
      text: "All Students Enrolled",
      total: schoolDashboardStat?.studentsEnrolled ?? 0,
      loading: isLoadingSchStat,
    },
    {
      icon: <VerifiedStudents />,
      text: "Verified Students",
      total: schoolDashboardStat?.studentsVerified ?? 0,
      loading: isLoadingSchStat,
    },
    {
      icon: <UnverifiedStudents />,
      text: "Unverified Students",
      total: schoolDashboardStat?.unverifyStudents ?? 0,
      loading: isLoadingSchStat,
    },
    {
      icon: <StudentsStudying />,
      text: "Students Studying",
      total: schoolDashboardStat?.studentStudying ?? 0,
      loading: isLoadingSchStat,
    },
  ];

  // const TotalLicencesAndActivePayment = schoolSub?.allSubsData?.filter(
  //   (sub) => sub.PaymentStatus === "APPROVED".toLowerCase() && sub.Licenses >= 1 && sub.SubscriptionName === "Yearly".toLowerCase()
  // );

  const TotalLicencesAndActivePayment = schoolSub?.allSubsData
    ?.filter(
      (sub) =>
        sub.PaymentStatus === "APPROVED" &&
        sub.SubscriptionName === "Yearly" &&
        sub.Licenses >= 1
    )
    ?.reduce((sum, sub) => sum + sub.Licenses, 0);

  return (
    <>
      <Container sx={{ padding: { xs: "0px" } }}>
        <Box component={Stack} gap={3} padding={0} bgcolor={"#FAFAFB"}>
          <Box>
            <Typography color={"#45464E"} fontSize={"16px"} fontWeight={600}>
              Dashboard
            </Typography>
            <Typography
              color={"#45464E"}
              fontSize={"14px"}
            >{`Today is ${currentDate.format(
              "dddd, D MMMM YYYY"
            )}`}</Typography>
          </Box>

          {(!userInfo?.isSuperSchoolAdmin && TotalLicencesAndActivePayment) ||
          (userInfo?.isSuperSchoolAdmin && TotalLicencesAndActivePayment) ? (
            <SpecialOffercardBonusCard setToastState={setToastState} />
          ) : !userInfo?.isSuperSchoolAdmin &&
            !TotalLicencesAndActivePayment ? (
            <div
              className="flex flex-col lg:flex-row gap-5 justify-between lg:items-center rounded-[10px] bg-cover bg-no-repeat text-white py-3 px-6"
              style={{ backgroundImage: `url(${bgImg})` }}
            >
              <div className="flex gap-[27px] items-center">
                <img
                  src={special_offer}
                  alt="offer pics"
                  className="object-cover w-[50px] h-[50px]"
                />
                <div className="flex gap-[9px] flex-col relative">
                  <div className="flex gap-3 items-center">
                    <h2 className="font-medium text-[18px]">Special Offer</h2>
                    <div
                      className="cursor-pointer"
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      <InfoIcon className="w-[16px] h-[16px]" />
                    </div>

                    <div className="absolute left-[10rem] top-0">
                      {hover && (
                        <div className="relative z-40">
                          <div className="rounded-[10px] bg-white shadow-md px-10 py-5 max-w-[440px]">
                            <div className="flex gap-3 items-center pl-[9px]">
                              <h3 className="text-[#313131]">Special Offer</h3>
                              <Verified className="text-[#3BA863]" />
                            </div>
                            <div className="mt-[9px] mb-8">
                              <div className="flex">
                                <AutoAwesome className="text-[#DEAB28]" />
                                <div className="pl-[9.5px] inline-flex flex-col">
                                  <h2 className="text-[#706E6E] font-bold text-[12px]">
                                    Subscribe to the Super School Plan with
                                    ₦200,000 only
                                  </h2>
                                  <p className="pt-[5px] text-[12px] text-[#706E6E]">
                                    The benefits of this plan includes:
                                  </p>
                                  <ul className="mt-[10px] text-[12px] text-[#706E6E] list-disc pl-6">
                                    <li className="">
                                      Access to all subjects for 12 months
                                    </li>
                                    <li className="">
                                      Access for up to 10 concurrent users
                                    </li>
                                    <li className="">Access to Study only</li>
                                    <li className="">Access from 8am to 6pm</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex mt-[18px]">
                                <AutoAwesome className="text-[#DEAB28]" />
                                <div className="pl-[9.5px] inline-flex flex-col">
                                  <h2 className="text-[#706E6E] font-bold text-[12px]">
                                    Alternatively, subscribe 50 students to the
                                    Yearly Plan and get the Super School access
                                    for free!
                                  </h2>
                                  <p className="pt-[5px] text-[12px] text-[#706E6E]">
                                    You currently have 34 students enrolled for
                                    the yearly plan. Enrol 16 more to get the
                                    Super School access for free!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="absolute -left-1 top-3">
                            <img
                              src={Caveat}
                              alt="caveat angle"
                              className="w-[12px] h-[7px] object-cover"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-[1px] flex-col text-[14px] font-medium">
                    <h3>
                      For <span className="font-bold">N200,000</span> per year,
                      you can access all subjects and log in with up to 10
                      devices simultaneously on the Super School Plan.
                    </h3>
                    <h3>
                      Alternatively, you can subscribe 50 students to a Yearly
                      Plan and enjoy the Super School access for free!
                    </h3>
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  bgcolor: "#fff",
                  color: "#40499D",
                  borderRadius: "5px",
                  whiteSpace: "nowrap",
                  // display: "flex",
                  // justifyItems: "center",
                  // // height: "30px",
                  // marginTop: "11px",
                  "&:hover": {
                    color: "#40499D",
                    bgcolor: "white",
                  },
                }}
                onClick={() => {
                  handleGetStartedClick();
                }}
              >
                Get Started
              </Button>
            </div>
          ) : null}

          <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={2}
            position="relative"
          >
            <Box width={{ xs: "100%", md: "70%" }} height={"auto"}>
              <Cards cards={cards} />

              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} sm={12}>
                    {isLoadingStudentsPath ? (
                      <Skeleton
                        variant="rounded"
                        width="max-width"
                        height="300px"
                      />
                    ) : (
                      <SchoolPath studentsPath={studentsPath} />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12} sm={12}>
                    {isLoadingTopStudents ? (
                      <Skeleton
                        variant="rounded"
                        width="max-width"
                        height="300px"
                      />
                    ) : (
                      <TopPerformingStudents
                        topStudents={topPerformingStudents}
                        userPathByCategory={userPathByCategory}
                        path={path}
                        handleChange={handleChange}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box width={{ xs: "100%", md: "30%" }} maxHeight={"auto"}>
              <StudentSubjects
                studentsSubjects={studentsSubjects}
                userPathByCategory={userPathByCategory}
                handleChangePath={handleChangePath}
                selectedPath={selectedPath}
              />
              {/* <StudentStudyTime /> */}
            </Box>
          </Box>
          <Box
            component={"section"}
            width={"100%"}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={3}
            position="relative"
          >
            <Box width={{ xs: "100%", md: "70%" }} height={"auto"}>
              {isLoadingSchStudyTime ? (
                <Skeleton variant="rounded" width="max-width" height="300px" />
              ) : (
                <StudentStudyTime schoolStudyTime={schoolStudyTime} />
              )}
              {/* <StudentSubjects /> */}
            </Box>
            <Box width={{ xs: "100%", md: "30%" }} height={"auto"}>
              <TotalAmountCard schoolSub={schoolSub} />
              {/* <TotalAmountCard schoolDashboardStat={schoolDashboardStat}/> */}
            </Box>
          </Box>
        </Box>
        <Dialog
          fullScreen={true}
          open={superSchoolPayment}
          onClose={() => setSuperSchoolPayment(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Payment
              setPaymentModal={setSuperSchoolPayment}
              selectedSubscription={selectedSubscription}
            />
          </DialogContent>
        </Dialog>
      </Container>
      <ToastState />
    </>
  );
}

export default SchoolDashboard;
