import React from "react";
import Box from "@mui/material/Box";
import { DiamondSvgIcon } from "../../../assets/icons/Icons";
import { Tooltip } from "@mui/material";

const TotalDiamondsBox = ({ Diamond }) => {
  return (
    <Tooltip title="Diamonds">
      <Box
        position="relative"
        border="1px solid #F0F0F0"
        borderRadius={8}
        textAlign="center"
        color="#000"
        sx={{
          fontSize: "14px",
          padding: "4px 30px",
          backgroundColor: "#fff",
          fontWeight: 600,
        }}
      >
        {Diamond}
        <Box position="absolute" left={-7} bottom={-3}>
          <DiamondSvgIcon />
        </Box>
      </Box>
    </Tooltip>
  );
};

export default TotalDiamondsBox;
