import React, { useRef } from 'react';
import wronganswer from "../../../assets/audios/wronganswer.mp3";

function WrongSound({ audioRef }) {
  return (
    <div>
      {/* Hide the UI controls */}
      <audio ref={audioRef} controls={false}>
        <source src={wronganswer} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

    </div>
  );
}

export default WrongSound;
