import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetExamByExamId } from "../../../redux/contentUpload/contentUploadActions";
import useContentUpload from "../../../hooks/useContentUpload";

import ContentUploadQuestionManagerTheory from "./ContentUploadQuestionManagerTheory";
import ContentUploadQuestionManagerMCQ from "./ContentUploadQuestionManagerMCQ";

const ContentUploadMCQOrTheory = ({ input, setInput, exam, setToastState }) => {
  const dispatch = useDispatch();

  const { singleExamData, loadingExam } = useContentUpload();

  useEffect(() => {
    if (exam) {
      dispatch(GetExamByExamId(exam?.examId));
    } else {
      dispatch(GetExamByExamId(input?.examId));
    }
  }, []);

  return (
    <>
      {singleExamData?.examTypeId == 2 ? (
        exam ? (
          <ContentUploadQuestionManagerTheory
            input={input}
            setInput={setInput}
            setToastState={setToastState}
            exam={exam}
          />
        ) : (
          <ContentUploadQuestionManagerTheory
            input={input}
            setInput={setInput}
            setToastState={setToastState}
          />
        )
      ) : exam ? (
        <ContentUploadQuestionManagerMCQ
          input={input}
          setInput={setInput}
          setToastState={setToastState}
          exam={exam}
        />
      ) : (
        <ContentUploadQuestionManagerMCQ
          input={input}
          setInput={setInput}
          setToastState={setToastState}
        />
      )}
    </>
  );
};

export default ContentUploadMCQOrTheory;
