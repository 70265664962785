import { Box, Button, Typography } from "@mui/material";
import Section from "../../assets/images/Section.png";
import React from "react";

const CommunityMobile = () => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <img
        src={Section}
        alt="Join Community"
        height={"150px"}
        style={{
          minHeight: " 300px",
          width: " 100%",
          height: " auto",
          objectFit: " cover",
          position: " relative",
          /* padding:' 20px', */
          boxSizing: " border-box",
          borderRadius: " 10px",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        <Box color="#fff">
          <Typography fontWaeight={"800"} fontSize={"20px"} textAlign="center">
            Join a world of learning
          </Typography>
          <Typography
            fontSize={"12px"}
            fontWeight={400}
            lineHeight={"150%"}
            textAlign="center"
            mt={2}
          >
            Want to ace your WASSCE(SC/PC) examination? Join thousands of
            scholars today to start learning
          </Typography>
          <Button
            variant="contained"
            color="brandSecondary"
            sx={{
              textTransform: "capitalize",
              color: "#40499d",
              fontWeight: "bold",
              fontSize: "12px",
              mt: 3,
              display: "block",
              margin: "0 auto",
            }}
          >
            Sign Up Now
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default CommunityMobile;
