// MathQuillEditor.js
import React, { useRef, useEffect } from "react";
import { addStyles, EditableMathField } from "react-mathquill";
// import "react-mathquill/build/mathquill.css";

addStyles(); // Import MathQuill styles

const MathQuillEditor = ({ latex, onChange }) => {
  const mathQuillRef = useRef(null);

  useEffect(() => {
    const mathField = mathQuillRef.current;
    if (mathField) {
      mathField.latex(latex);
    }
  }, [latex]);

  return (
    <EditableMathField
      style={{minWidth: '200px', minHeight: '100px'}}
      latex={latex}
      onChange={(mathField) => {
        onChange(mathField.latex());
      }}
      mathquillDidMount={(mathField) => {
        mathQuillRef.current = mathField;
      }}
    />
  );
};

export default MathQuillEditor;
