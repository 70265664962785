import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  Dialog,
  CardContent,
  ListItem,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  TextField,
  DialogContent,
  Paper,
  IconButton,
  NativeSelect,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { createSvgIcon } from "@mui/material/utils";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import useContentUpload from "../../../hooks/useContentUpload";
import {
  addSubjectAction,
  deactivateSubjectAction,
  fetchSubjectsAction,
  getAllSubjectPaths,
  updateSubjectAction,
} from "../../../redux/contentUpload/contentUploadActions";
import { SubjectBookIcon, SubjectIcon } from "../../../assets/icons/Icons";
import { Color } from "../../../assets/themes/Color";
import { useNotifications } from "../../../components/notifications/notifications";
import { isArray } from "../../../helpers/helper";
import useAdminUser from "../../../hooks/useAdminUser";

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

const ContentUpload = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ToastState, setToastState] = useNotifications();

  const [subjects, setSubjects] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEditMenu, setOpenEditMenu] = React.useState(false);
  const [subjectId, setSubjectId] = React.useState("");
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectPath, setSubjectPath] = useState("");
  const [isCoreSubject, setIsCoreSubject] = useState(false);
  const [isTradeSubject, setIsTradeSubject] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [subjectState, setSubjectState] = React.useState("");
  const [subjectIdEdit, setSubjectIdEditMenu] = React.useState("");
  const [subjectNameEdit, setSubjectNameEditMenu] = React.useState("");
  const [subjectStateEdit, setSubjectStateEditMenu] = React.useState("");
  const [isCoreSubjectEdit, setIsCoreSubjectEdit] = useState(false);
  const [isTradeSubjectEdit, setIsTradeSubjectEdit] = useState(false);
  const [subjectPathId, setSubjectPathId] = React.useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [loadSubject, setLoadSubject] = useState(false);

  const { subjectData, subjectPaths, loading, error } = useContentUpload();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { userInfo } = useAdminUser();

  const [openBM, setOpenBM] = React.useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(fetchSubjectsAction());
  }, [loadSubject]);

  useEffect(() => {
    dispatch(getAllSubjectPaths());
  }, []);

  useEffect(() => {
    setLoadSubject(false);
    setFilteredData(
      subjectData?.sort(function (a, b) {
        return a.subjectName - b.subjectName;
      })
    );
  }, [loadSubject, subjectData]);

  useEffect(() => {
    if (search != "") {
      setFilteredData(
        subjectData
          ?.sort(function (a, b) {
            return a.subjectName - b.subjectName;
          })
          ?.filter((sub) =>
            sub.subjectName.toLowerCase()?.includes(search.toLowerCase())
          )
      );
    } else {
      setFilteredData(
        subjectData?.sort(function (a, b) {
          return a.subjectName - b.subjectName;
        })
      );
    }
  }, [search]);

  const handleClickOpenBM = () => {
    setOpenBM(true);
  };
  const handleCloseBM = () => {
    setOpenBM(false);
  };

  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (e, subjectObj) => {
    setAnchorEl(e.currentTarget);
    setSubjectNameEditMenu(subjectObj.subjectName);
    setSubjectIdEditMenu(subjectObj.subjectId);
    setSubjectStateEditMenu(subjectObj.isActive);
    setSubjectPathId(subjectObj.pathId);
    setIsCoreSubjectEdit(subjectObj?.isCore);
    setIsTradeSubjectEdit(subjectObj?.isTrade);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onSuccess = () => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Subject added successfully",
    });
    setLoadSubject(true);
  };

  const onSuccessEdit = () => {
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Subject updated successfully",
    });
    setLoadSubject(true);
  };

  const onSuccessDeactivate = () => {
    setDeleteModal(false);
    setToastState({
      isOpen: true,
      severity: "success",
      message: "Subject deactivated successfully",
    });
    setLoadSubject(true);
  };

  const handleSubmit = () => {
    handleCloseBM();
    dispatch(
      addSubjectAction(
        {
          userId: userInfo?.data?.profileId,
          subjectName: subjectName,
          pathId: subjectPath,
          isActive: true,
          isCore: isCoreSubject,
          isTrade: isTradeSubject,
        },
        onSuccess
      )
    );
  };

  const handleEdit = () => {
    dispatch(
      updateSubjectAction(
        {
          subjectId: subjectIdEdit,
          subjectName: subjectNameEdit,
          pathId: subjectPathId,
          isActive: true,
          isCore: isCoreSubjectEdit,
          isTrade: isTradeSubjectEdit,
        },
        onSuccessEdit
      )
    );
    setAnchorEl(null);
    setOpenEditMenu(false);
  };

  const handleDeactivate = () => {
    dispatch(
      deactivateSubjectAction(
        { subjectId: subjectIdEdit, profileId: userInfo?.data?.profileId },
        onSuccessDeactivate
      )
    );
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleEditQuestion = (subjectObj) => {
    setAnchorEl(null);
    setOpenEditMenu(true);
  };

  return (
    <>
      <Box>
        {/* <Typography
          sx={{
            color: "#000",
            fontFamily: "Open Sans",
            fontSize: "26px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "50.4px",
          }}
        >
          Content Upload
        </Typography> */}
        <Typography variant="h6" fontWeight={"bold"}>
          Content Upload
        </Typography>
        <Typography
          sx={{
            color: "#313131",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22.4px",
          }}
        >
          Select subject to edit or create new questions
        </Typography>
      </Box>

      <Box sx={{ width: "90%" }}>
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <button
            className="btn px-5"
            style={{
              backgroundColor: "#40499D",
              color: "#fff",
              borderRadius: "5px",
              padding: "10px 13px",
              fontSize: "15px",
              fontWeight: 400,
            }}
            onClick={() => handleClickOpenBM()}
          >
            <span className="mr-2">
              <PlusIcon />
            </span>{" "}
            Add new subject
          </button>
        </Box>

        <Box sx={{ my: 2 }}>
          <Card variant="outlined">
            <CardContent sx={{ pb: 1 }}>
              <Grid container xs={12} spacing={1}>
                <Grid item xs={8}>
                  <FormControl sx={{ mt: 1 }}>
                    <OutlinedInput
                      id="outlined-adornment-search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      size="small"
                      placeholder="Search for subjects..."
                      sx={{ width: "100%" }}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        color: "#000",
                        borderColor: "#000",
                        backgroundColor: "#F6F6F6",
                      }}
                      startIcon={<FilterListIcon />}
                    >
                      Filters
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box>
          {Array.isArray(filteredData) &&
            filteredData
              ?.sort((a, b) => a.subjectName?.localeCompare(b.subjectName))
              .map((subjectObj, i) => (
                <Card variant="outlined" sx={{ mt: 1 }} key={i}>
                  <CardContent>
                    <div
                      className="flex items-center"
                      container
                      spacing={1}
                      xs={{ alignItems: "center" }}
                    >
                      <div className="basis-1/12">
                        <SubjectBookIcon
                          color={Color[i] ? Color[i] : Color[0]}
                        />
                      </div>
                      <div sx={{ pl: 5 }} className="basis-10/12">
                        <Box sx={{ pl: 3 }}>
                          <Typography
                            sx={{
                              color: "#000",
                              fontFamily: "DM Sans",
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: 700,
                              lineHeight: "26.04px",
                            }}
                          >
                            {subjectObj?.subjectName}
                          </Typography>
                        </Box>
                      </div>
                      <div className="basis-1/12">
                        <Box
                          m={1}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <ArrowRightAltIcon
                            onClick={() => {
                              navigate(
                                `/admin/contentupload/exam/${subjectObj?.subjectId}`
                              ),
                                setSubjectId(subjectObj?.subjectId);
                            }}
                            sx={{ cursor: "pointer" }}
                          />
                          <MoreVertIcon
                            onClick={(e) => handleClickMenu(e, subjectObj)}
                            sx={{ cursor: "pointer" }}
                          />

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuList>
                              <MenuItem
                                onClick={() => handleEditQuestion(subjectObj)}
                              >
                                <ListItemIcon>
                                  <BorderColorOutlinedIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    color: "#313131",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "14px",
                                  }}
                                >
                                  Edit subject
                                </ListItemText>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setSubjectId(subjectObj?.subjectId);
                                  setDeleteModal(true);
                                }}
                              >
                                <ListItemIcon>
                                  <PowerSettingsNewIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                  sx={{
                                    color: "#313131",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "14px",
                                  }}
                                >
                                  Deactivate subject
                                </ListItemText>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
        </Box>
      </Box>

      <Dialog
        open={openEditMenu}
        onClose={() => setOpenEditMenu(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit Subject
          <Typography
            sx={{
              color: "#919191",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "14px",
            }}
          >
            Edit subject below
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEditMenu(false)}
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <Grid container spacing={1}>
              {/* <Grid item xs={3}>
                <svg width="100%" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="32.625" cy="32.625" r="32.0107" fill="#F4F4F4" stroke="#B4B4B4" strokeWidth="0.771341" stroke-dasharray="1.54 1.54" />
                  <path d="M30.311 25.8757L28.8995 27.4184H26.4543C26.0452 27.4184 25.6528 27.5809 25.3635 27.8703C25.0742 28.1596 24.9116 28.552 24.9116 28.9611V38.2172C24.9116 38.6263 25.0742 39.0187 25.3635 39.308C25.6528 39.5973 26.0452 39.7599 26.4543 39.7599H38.7958C39.2049 39.7599 39.5973 39.5973 39.8866 39.308C40.1759 39.0187 40.3384 38.6263 40.3384 38.2172V28.9611C40.3384 28.552 40.1759 28.1596 39.8866 27.8703C39.5973 27.5809 39.2049 27.4184 38.7958 27.4184H36.3506L34.9391 25.8757M32.625 37.4459C31.6022 37.4459 30.6212 37.0395 29.8979 36.3163C29.1747 35.593 28.7683 34.612 28.7683 33.5891C28.7683 32.5663 29.1747 31.5853 29.8979 30.862C30.6212 30.1388 31.6022 29.7324 32.625 29.7324C33.6479 29.7324 34.6289 30.1388 35.3521 30.862C36.0754 31.5853 36.4817 32.5663 36.4817 33.5891C36.4817 34.612 36.0754 35.593 35.3521 36.3163C34.6289 37.0395 33.6479 37.4459 32.625 37.4459ZM32.625 36.6745L33.5892 34.5533L35.7104 33.5891L33.5892 32.625L32.625 30.5038L31.6609 32.625L29.5397 33.5891L31.6609 34.5533" fill="#B4B4B4" />
                </svg>

              </Grid> */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#40499D",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                  }}
                >
                  Subject Path
                </Typography>

                <NativeSelect
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={subjectPathId}
                  onChange={(e) => setSubjectPathId(e.target.value)}
                  fullWidth
                >
                  <option value="00000000-0000-0000-0000-000000000000" disabled>
                    Select
                  </option>
                  {subjectPaths?.map((path) => (
                    <option sx={{ ml: 3 }} value={path?.pathId}>
                      {path?.pathName}
                    </option>
                  ))}
                </NativeSelect>
                <input
                  style={{ marginTop: ".8rem" }}
                  className="btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={subjectNameEdit}
                  onChange={(e) => setSubjectNameEditMenu(e.target.value)}
                ></input>
                <FormControl component="fieldset" sx={{ mt: 3 }}>
                  <FormLabel component="legend">Subject Type</FormLabel>
                  <RadioGroup
                    row
                    aria-label="subjectTypeEdit"
                    name="subjectTypeEdit"
                    value={
                      isCoreSubjectEdit
                        ? "core"
                        : isTradeSubjectEdit
                        ? "trade"
                        : "academic"
                    }
                    onChange={(e) => {
                      if (e.target.value === "academic") {
                        setIsCoreSubjectEdit(false);
                        setIsTradeSubjectEdit(false);
                      } else if (e.target.value === "core") {
                        setIsCoreSubjectEdit(true);
                        setIsTradeSubjectEdit(false);
                      } else {
                        setIsCoreSubjectEdit(false);
                        setIsTradeSubjectEdit(true);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="core"
                      control={<Radio />}
                      label="Core Subject"
                    />
                    <FormControlLabel
                      value="trade"
                      control={<Radio />}
                      label="Trade Subject"
                    />
                    <FormControlLabel
                      value="academic"
                      control={<Radio />}
                      label="Academic Subject"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleEdit}
            style={{ backgroundColor: "#40499D" }}
            class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
          >
            Update Subject
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBM}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add New Subject
          <Typography
            sx={{
              color: "#919191",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "14px",
            }}
          >
            Set up subject below
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseBM}
            style={{
              position: "absolute",
              top: "1px",
              right: "1px",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <Grid container spacing={1}>
              {/* <Grid item xs={3}>
                <svg width="100%" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="32.625" cy="32.625" r="32.0107" fill="#F4F4F4" stroke="#B4B4B4" strokeWidth="0.771341" stroke-dasharray="1.54 1.54" />
                  <path d="M30.311 25.8757L28.8995 27.4184H26.4543C26.0452 27.4184 25.6528 27.5809 25.3635 27.8703C25.0742 28.1596 24.9116 28.552 24.9116 28.9611V38.2172C24.9116 38.6263 25.0742 39.0187 25.3635 39.308C25.6528 39.5973 26.0452 39.7599 26.4543 39.7599H38.7958C39.2049 39.7599 39.5973 39.5973 39.8866 39.308C40.1759 39.0187 40.3384 38.6263 40.3384 38.2172V28.9611C40.3384 28.552 40.1759 28.1596 39.8866 27.8703C39.5973 27.5809 39.2049 27.4184 38.7958 27.4184H36.3506L34.9391 25.8757M32.625 37.4459C31.6022 37.4459 30.6212 37.0395 29.8979 36.3163C29.1747 35.593 28.7683 34.612 28.7683 33.5891C28.7683 32.5663 29.1747 31.5853 29.8979 30.862C30.6212 30.1388 31.6022 29.7324 32.625 29.7324C33.6479 29.7324 34.6289 30.1388 35.3521 30.862C36.0754 31.5853 36.4817 32.5663 36.4817 33.5891C36.4817 34.612 36.0754 35.593 35.3521 36.3163C34.6289 37.0395 33.6479 37.4459 32.625 37.4459ZM32.625 36.6745L33.5892 34.5533L35.7104 33.5891L33.5892 32.625L32.625 30.5038L31.6609 32.625L29.5397 33.5891L31.6609 34.5533" fill="#B4B4B4" />
                </svg>

              </Grid> */}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#40499D",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "22.4px",
                  }}
                >
                  Subject Path
                </Typography>

                <NativeSelect
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={subjectPath}
                  onChange={(e) => setSubjectPath(e.target.value)}
                  fullWidth
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {subjectPaths?.map((path) => (
                    <option sx={{ ml: 3 }} value={path?.pathId}>
                      {path?.pathName}
                    </option>
                  ))}
                </NativeSelect>
                <input
                  style={{ marginTop: ".8rem" }}
                  className="btn appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Subject Name"
                  onChange={(e) => setSubjectName(e.target.value)}
                ></input>
                <FormControl component="fieldset" sx={{ mt: 3 }}>
                  <FormLabel component="legend">Subject Type</FormLabel>
                  <RadioGroup
                    row
                    aria-label="subjectType"
                    name="subjectType"
                    value={
                      isCoreSubject
                        ? "core"
                        : isTradeSubject
                        ? "trade"
                        : "academic"
                    }
                    onChange={(e) => {
                      if (e.target.value === "academic") {
                        setIsCoreSubject(false);
                        setIsTradeSubject(false);
                      } else if (e.target.value === "core") {
                        setIsCoreSubject(true);
                        setIsTradeSubject(false);
                      } else {
                        setIsCoreSubject(false);
                        setIsTradeSubject(true);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="core"
                      control={<Radio />}
                      label="Core Subject"
                    />
                    <FormControlLabel
                      value="trade"
                      control={<Radio />}
                      label="Trade Subject"
                    />
                    <FormControlLabel
                      value="academic"
                      control={<Radio />}
                      label="Academic Subject"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => handleSubmit()}
            style={{ backgroundColor: "#40499D" }}
            class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
          >
            Create New Subject
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteModal}
        onClose={() => setDeleteModal(true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Paper sx={{ p: 1 }}>
            <h3 className="e-study-heading-three">
              Are you sure you want to delete this subject?
            </h3>
          </Paper>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between items-center space-x-3 lg:space-x-9">
            <span
              className="e-study-primary cursor-pointer"
              onClick={() => setDeleteModal(false)}
            >
              No
            </span>
            <button
              onClick={() => handleDeactivate()}
              style={{ backgroundColor: "#FA7E77" }}
              class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            >
              Yes
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <ToastState />
    </>
  );
};

export default ContentUpload;
