import { Box, Container, Grid, Dialog } from "@mui/material";
import React from "react";
import ApplogoDark from "../../../assets/images/ApplogoDark.png";
import useResponsive from "../../../hooks/useMediaQuery";
import { useState, useEffect } from "react";
import useUser from "../../../hooks/useUser";
import PackageName from "../../../components/dashboards/sponsor/payment/paymentDetails/PackageName";
import PlanPackageBox from "../../../components/dashboards/sponsor/payment/paymentDetails/PlanPackageBox";
import PaymentDetails from "../../../components/dashboards/sponsor/payment/paymentDetails/PaymentDetails";
import { initiatePaymentGateway, initiateUPPayment } from "../../../redux/payment/paymentActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/AuthReuseables/Preloader/Loader";
import usePayment from "../../../hooks/usePayment";


const Payment = ({ setPaymentModal, selectedSubscription }) => {
  const [value, setValue] = React.useState("gatewayPayment");
  const [UpPage, setUpPage] = useState("");
  const [open, setOpen] = useState(false);
  const userInfo = useUser()?.userInfo;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payment = usePayment();


  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = useResponsive("down", "sm");
  // const isTablet = useResponsive("between", "sm", "md");
  const isTablet = useResponsive("down", "md");
  /* #region  Create remita script tags and initiate the script */
  useEffect(() => {
    const script = document.createElement("script");

    // REMITA
    // script.src = "https://remitademo.net/payment/v1/remita-pay-inline.bundle.js";
    // script.src = "https://login.remita.net/payment/v1/remita-pay-inline.bundle.js";

    // INTERSWITCH
    // Test URL
    // script.src = "https://webpay-ui.k8.isw.la/inline-checkout.js";

    // Live Url
    // script.src = "https://newwebpay.interswitchng.com/inline-checkout.js"

    // PAYSTACK
    // TEST URL Paystack
    script.src = "https://js.paystack.co/v1/inline.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  /* #endregion */

  const [input, setInput] = useState({
    countryId: null,
    stateId: null,
    currency: "NGN",
    currencyValue: "566",
    amount: 10000,
    fee: 0,
    slots: 1,
    paymentGateway: 2,
  });

  const paystackPay = (e) => {
    if (e) {
      e.preventDefault();
    } else {
      console.error("Event is undefined");
    }
    function generateRandomString(length) {
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      const charsetLength = charset.length;

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charsetLength);
        result += charset.charAt(randomIndex);
      }

      return result;
    }

    const paymentCallback = async (response) => {
      console.log(response);
      // const acceptedCodes = ['00', '10', "11"];
      // if (acceptedCodes.includes(response.resp)) {
      // try {
      //   const confirm = await fetchJSON(`api/CheckTransactionByTansRef?transRef=${response.txnref}&split=${true}`, null, "GET", token)
      //   if (confirm.Data.status === "Success") {

      //   } else {
      //   }
      // } catch (err) {

      // }
    };
    let handler = PaystackPop.setup({
      key: "pk_test_c164e43d259f7ff5f1eb72dd91cd7475cd27996b", // Replace with your public key
      email: email,
      amount: 8900 * 100,
      ref: generateRandomString(15),
      // label: "Optional string that replaces customer email"
      onClose: function () {
        alert("Window closed.");
      },
      callback: function (response) {
        let message = "Payment complete! Reference: " + response.reference;
        console.log(response);
      },
    });

    handler.openIframe();
  };

  const onSuccess = (res) => {
    const parts = res.split("/");
    const lastId = parts[parts.length - 1];
    setUpPage(res);
    window.location.href = res;
    // setOpen(true);
  };

  const onFailure = () => {
    navigate('/payment/paymentstatus')
  }

  const UPPayment = () => {
    const isLocalhost = window.location.hostname === "localhost";
    const isDev = window.location.hostname === "estudyapp-dev.azurewebsites.net";

    const apiUrl = isLocalhost
      ? "http://localhost:5108/api"
      : isDev ? 'https://estudyapp-dev.azurewebsites.net/api' : 'https://estudy.ng/api';
    
    const baseUrl = isLocalhost ? "http://localhost:44412" : `https://${window.location.hostname}`


    const data = {
      amount: input.amount,
      currency: input.currency,
      description: `Payment for eStudy Application by ${
        userInfo.firstName + " " + userInfo.surname
      }`,
      returnUrl: input.paymentGateway == 1 ? `${apiUrl}/Payment/UPProcessPaymentTransaction` : `${baseUrl}/payment/paymentstatus`,
      fee: input.fee,
      MerchantRefData: "",
      profileId: userInfo?.profileId,
      subscriptionModeId: selectedSubscription?.SubscriptionModelId,
      slots: input.slots,
      Email: userInfo?.email,
      CustomerName: userInfo.firstName + " " + userInfo.surname,
      paymentGateway: input.paymentGateway
    };

    dispatch(initiatePaymentGateway(data, onSuccess, onFailure));
  };


  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        bgcolor={"#F3F5F8"}
        sx={{ padding: "20px" }}
      >
        <img
          src={ApplogoDark}
          alt="WAEC eStudy"
          style={{ width: "auto", height: "30px" }}
        />
        <button onClick={() => setPaymentModal(false)}>Cancel</button>
      </Box>
      <Container sx={{ p: 3 }}>
        {payment.loading ? <Loader /> : (
          <Grid
            container
            spacing={isMobile ? 0 : isTablet ? 4 : 20}
            sx={{ padding: { lg: "20px 40px 0px 20px" } }}
          >
            <Grid
              item
              lg={6}
              xs={12}
              md={6}
              sm={6}
              sx={{ ...(isMobile && { order: 2 }) }}
            >
              <PaymentDetails
                input={input}
                setInput={setInput}
                interswitchPay={UPPayment}
                selectedSubscription={selectedSubscription}
              />
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              md={6}
              sm={6}
              sx={{ ...(isMobile && { order: 1 }) }}
            >
              {isMobile ? (
                <PackageName isMobile={isMobile} selectedSubscription={selectedSubscription} />
              ) : (
                <PlanPackageBox selectedSubscription={selectedSubscription} />
              )}
            </Grid>
          </Grid>
        )}
       
      </Container>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "18px" } }}
        maxWidth={"xl"}
      >
        <div className="flex flex-col h-screen justify-center items-center mx-2 ">
          <iframe id="myFrame" width={800} height={800} src={UpPage}></iframe>
        </div>
      </Dialog>
    </Box>
  );
};

export default Payment;
