import ReactApexChart from "react-apexcharts";
import { useState } from "react";

export default function StudyTimeChart({ studyTimeData, loading }) {
  if (!studyTimeData || !studyTimeData.studyTime) {
    return <div>Loading...</div>;
  }
  // Extract unique subjects
  const subjects = studyTimeData.studyTime.reduce((uniqueSubjects, item) => {
    if (!uniqueSubjects.includes(item.subjectName)) {
      uniqueSubjects.push(item.subjectName);
    }
    return uniqueSubjects;
  }, []);

  // Initialize series data
  const initialSeries = subjects.map((subject) => ({
    name: subject,
    data: [0, 0, 0, 0, 0, 0, 0],
  }));

  studyTimeData.studyTime.forEach((item) => {
    const index = subjects.indexOf(item.subjectName);
    if (index !== -1) {
      const dayIndex = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ].indexOf(item.dayOfWeek);
      if (dayIndex !== -1) {
        initialSeries[index].data[dayIndex] = item.studyTime;
      }
    }
  });

  const [series, setSeries] = useState(initialSeries);

  const options = {
    chart: {
      type: "bar",
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "top",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        // borderRadius: 5,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          fontFamily: "DM Sans, sans-serif",
        },
      },
      categories: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          const hours = Math.floor(value / 3600);
          const minutes = Math.floor((value % 3600) / 60);
          if (hours === 0) {
            return `${minutes}m`;
          } else if (minutes === 0) {
            return `${hours}h`;
          } else {
            return `${hours}h ${minutes}m`;
          }
        },

        style: {
          fontFamily: "DM Sans, sans-serif",
        },
      },
      tickAmount: 5,
      min: 0,
      max: 5 * 3600,
      tooltip: {
        y: {
          formatter: function (value) {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            if (hours === 0) {
              return `${minutes}m`;
            } else if (minutes === 0) {
              return `${hours}h`;
            } else {
              return `${hours}h ${minutes}m`;
            }
          },
        },
      },
    },

    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 10,
      fontFamily: "DM Sans, sans-serif",
      fontSize: "12px",
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "#4CB64C",
      "#0D88E6",
      "#F2C8ED",
      "#8146FF",
      "#FA7E77",
      "#DB48FF",
      "#4B55AF",
      "#EBDC78",
      "#00B7C7",
      "#3AB8FF",
    ],
    theme: {
      fontFamily: "DM Sans, sans-serif",
    },
  };
  return (
    <div style={{ fontFamily: "DM Sans, sans-serif" }}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={300}
      />
    </div>
  );
}
