import Axios from "../../connection/defaultClient";
import * as actionTypes from "./types";

export const fetchClassLevel = () => {
  return (dispatch) => {
    Axios.get(`/School/GetAllClassLevel`)
      .then((reponse) => {
        dispatch({
          type: actionTypes.FECTCH_CLASS_LEVEL,
          payload: reponse.data,
        });
        // console.log(payload);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FECTCH_CLASS_LEVEL_FAIL,
          payload: error.message,
        });
      });
  };
};

// get alldevices
export const fetchAllDevices = (schoolId) => {
  return (dispatch) => {
    Axios.get(`/Access/GetDeviceDetails?SchoolId=${schoolId}`)
      .then((reponse) => {
        dispatch({
          type: actionTypes.GET_ALL_DEVICES,
          payload: reponse.data,
        });
        // console.log(payload);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ALL_DEVICES_FAIL,
          payload: error.message,
        });
      });
  };
};

export const fetchAddStudent = (
  schoolId,
  sponsorId = null,
  subscriptionId,
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    //debugger
    let url = `/School/AddStudents/${subscriptionId}`;

    if (schoolId) {
      url += `?schoolId=${schoolId}`;
    }

    if (sponsorId) {
      url += (schoolId ? "&" : "?") + `sponsorId=${sponsorId}`;
    }

    Axios.post(url, payload)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_NEW_STUDENT,
          payload: response.data,
        });
        if (response.data.state === 1) {
          onSuccess(response.data);
        } else if (response.data.state === -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const EditManageStudent = (
  schoolId,
  sponsorId = null,
  subscriptionId,
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    //debugger
    let url = `/School/UpdateStudent/${subscriptionId}`;

    if (schoolId) {
      url += `?schoolId=${schoolId}`;
    }

    if (sponsorId) {
      url += (schoolId ? "&" : "?") + `sponsorId=${sponsorId}`;
    }

    Axios.post(url, payload)
      .then((response) => {
        dispatch({
          type: actionTypes.EDIT_MANAGE_STUDENT,
          payload: response.data,
        });
        if (response.data.state === 1) {
          onSuccess(response.data);
        } else if (response.data.state === -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const addBulkStudents = (
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    Axios.post(`/Enrollment/AddStudents`, payload)
      .then((response) => {
        // debugger;
        dispatch({
          type: actionTypes.ADD_NEW_STUDENTS,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        // on500Fail(error.response);
        // onFail(error.response);
        console.error(error);
      });
  };
};
export const fetchGetStudent = (payload) => {
  return (dispatch) => {
    // disptach({ type: actionTypes.LOAD_STUDENT, payload: true });
    Axios.get(`/School/GetStudents/${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_STUDENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        // on500Fail(error.response);
        console.error(error);
      });
  };
};

//api to get student by profileId
export const fetchGetStudentByProfileId = (payload) => {
  return (dispatch) => {
    Axios.get(`/School/GetStudentsByProfileId/${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_STUDENT_PROFILE_ID,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_STUDENT_PROFILE_ID_FAIL,
          payload: error.message,
        });
        console.error(error);
      });
  };
};

export const managestudentResendMail = (
  email,
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    Axios.post(
      `/profile/ResendSchoolOrsponsorRegStudentMail?emailAddress=${email}&SchoolId=${payload.SchoolId}&SponsorId=${payload.SponsorId}`
    )
      .then((response) => {
        // debugger;
        dispatch({
          type: actionTypes.RESEND_STUDENT_MAIL,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        // on500Fail(error.response);
        // onFail(error.response);
        console.error(error);
      });
  };
};

//******************************************//
//sponser action

export const fetchGetSchoolBySponsorId = (payload) => {
  return (dispatch) => {
    // disptach({ type: actionTypes.LOAD_STUDENT, payload: true });
    Axios.get(`/School/GetSchoolsSponsor?sponsorId=${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_SCHOOL_BY_SPONSOR_ID,
          payload: response.data,
        });
        // if (response.data.state == 1) {
        //   onSuccess(response.data);
        // } else if (response.data.state == -1) {
        //   onFail(response.data);
        // }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ALL_SCHOOL_BY_SPONSOR_ID_FAIL,
          payload: error.message,
        });
        // on500Fail(error.response);
        console.error(error);
      });
  };
};

export const fetchGetStudentBySponsorId = (payload) => {
  return (dispatch) => {
    // debugger;
    Axios.get(`/School/GetSponsoredStudents/${payload}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_STUDENT_BY_SPONSOR_ID,
          payload: response.data,
        });
      })
      .catch((error) => {
        // on500Fail(error.response);
        console.error(error);
      });
  };
};

export const fetchAddStudentBySponsorId = (
  userLoggedIn,
  payload,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    // disptach({ type: actionTypes.LOAD_STUDENT, payload: true });
    Axios.post(`/School/AddStudents?SponsorId=${userLoggedIn}`, payload)
      .then((response) => {
        // disptach({ type: actionTypes.LOAD_STUDENT, payload: false });
        dispatch({
          type: actionTypes.ADD_NEW_STUDENT,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        // on500Fail(error.response);
        // onFail(error.response);
        console.error(error);
      });
  };
};

export const fetchGetSchoolSponsorsBySchoolId = (schoolId) => {
  return (dispatch) => {
    Axios.get(`/School/GetAllSchoolSponsors/${schoolId}`, {
      headers: {
        Accept: "application/json", // Set Accept header to request JSON response
      },
    })
      .then((response) => {
        dispatch({
          type: actionTypes.GET_ALL_SPONSORS_BY_SCHOOL_ID_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ALL_SPONSORS_BY_SCHOOL_ID_FAIL,
          payload: error.message,
        });
      });
  };
};

export const SuperSchoolClaimBonus = (
  profileId,
  onSuccess = () => {},
  onFail = () => {},
  on500Fail = () => {}
) => {
  return (dispatch) => {
    Axios.post(`/Gamification/ClaimBonus?profileId=${profileId}`)
      .then((response) => {
        // debugger;
        dispatch({
          type: actionTypes.SUPER_SCHOOL_USER_MAIL,
          payload: response.data,
        });
        if (response.data.state == 1) {
          onSuccess(response.data);
        } else if (response.data.state == -1) {
          onFail(response.data);
        }
      })
      .catch((error) => {
        // on500Fail(error.response);
        // onFail(error.response);
        console.error(error);
      });
  };
};
